<template>
    <!-- 图层 -->
    <div class="mapChangePartTree">
        <!-- 地图 -->
        <img
            src="@image/bigScreen/icon/reset.png"
            class="resetIcon pointer"
            @click="layerState = !layerState"
            title="行政区划"
        />
        <!-- <div
            class="layer pointer flex-between-y-center"
            @click="layerState = !layerState"
        >
            <div class="desc">{{ activeName }}</div>
            <img
                :src="
                    layerState
                        ? require('@image/bigScreen/monitor_all/xzqh/arrowTop.png')
                        : require('@image/bigScreen/monitor_all/xzqh/arrowBottom.png')
                "
            />
        </div> -->
        <el-collapse-transition>
            <div v-show="layerState" class="layerCtn">
                <img
                    src="@image/bigScreen/monitor_all/xzqh/tree.png"
                    class="tree"
                />
                <img
                    src="@image/bigScreen/monitor_all/xzqh/leaf.png"
                    class="leaf"
                />
                <div class="listPart" @mousewheel="changeXzqhWheel">
                    <div
                        v-for="(item, index) in listAry"
                        :key="index"
                        class="listItem flex-end"
                        @click="changeXzqh(item, index)"
                    >
                        <div class="circle" v-show="index == activeNum"></div>
                        <div
                            class="name pointer"
                            :class="[index == activeNum ? 'nameActive' : '']"
                        >
                            {{ item.name }}
                        </div>
                    </div>
                </div>
            </div>
        </el-collapse-transition>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    components: {},
    name: "",
    data() {
        return {
            layerState: false,
            activeNum: 4,
            activeName: "景迈山",
            listAry: [
                {
                    name: "中国",
                    jd: 105.95092161523064,
                    wd: 42.403175604178315,
                    level: 4,
                    layerId: "all",
                },
                {
                    name: "云南省",
                    jd: 101.5603312078986,
                    wd: 25.052051920960434,
                    level: 6.9,
                    layerId: "2",
                },
                {
                    name: "普洱市",
                    jd: 100.80484913837168,
                    wd: 23.265977015329458,
                    level: 8.7,
                    layerId: "3",
                },
                {
                    name: "澜沧县",
                    jd: 99.91740083875186,
                    wd: 22.714528954689264,
                    level: 9.8,
                    layerId: "4",
                },
                {
                    name: "景迈山",
                    jd: 100.00174215469247,
                    wd: 22.18115145028871,
                    level: 12.6,
                    layerId: "29",
                },
            ],
            xzqhGroup: null, //行政区划图层组
            mapbox: null,
            layerStyle: null,
            jmsGroup: null,
            markerPopup: null,
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {
        myMap: Object,
    },
    watch: {
        myMap: {
            immediate: true,
            handler() {
                if (this.myMap) {
                    this.xzqhGroup = window.L.layerGroup().addTo(this.myMap);
                    this.jmsGroup = window.L.layerGroup().addTo(this.myMap);
                    this.drawXzqh(this.xzqhGroup); //行政区划
                }
            },
        },
    },
    mounted() {
        this.markerPopup = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, 0],
            className: "markerPopup",
        });
    },
    methods: {
        ...mapActions(["getVectorStyle"]),
        ...mapMutations({}),
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        // 选择行政区划
        changeXzqh(item, index) {
            this.activeNum = index;
            this.activeName = item.name;
            this.myMap.flyTo([item.wd, item.jd], item.level);

            this.drawXzqhItem(this.xzqhGroup, item); //行政区划
            if (item.name != "景迈山") {
                this.drawMarkerJms();
            } else {
                this.clearLayersGroup(this.jmsGroup);
            }
            this.$emit("xzqhChange", item);
        },
        // 鼠标滚动
        changeXzqhWheel(e) {
            if (e.deltaY > 0) {
                // 下滚动
                this.activeNum++;
            } else {
                // 上滚动
                this.activeNum--;
            }
            if (this.activeNum >= this.listAry.length) {
                this.activeNum = 0;
            } else if (this.activeNum < 0) {
                this.activeNum = this.listAry.length - 1;
            }
            this.changeXzqh(this.listAry[this.activeNum], this.activeNum);
        },
        // 行政区划图
        async drawXzqh(group) {
            this.layerStyle = await this.getVectorStyle({
                url: window.MAP_XZQH,
            });
            this.drawXzqhItem(group, this.listAry[4]);
            // let obj = JSON.parse(JSON.stringify(this.layerStyle));
            // let list = [];
            // this.layerStyle.layers.map((item) => {
            //     if (item.type != "background") {
            //         list.push(item);
            //     }
            // });
            // obj.layers = Array.from(list);
            // let allProvince1 = window.L.mapboxGL({
            //     accessToken: window.MAPBOX_TOKEN,
            //     style: obj,
            // }).addTo(group);
            // this.common.showVectorLayers();
            // this.mapbox = allProvince1.getMapboxMap();
            // this.mapbox.on("load", () => {
            //     this.$nextTick(() => {
            //         let myContainer = this.mapbox.getContainer();
            //         myContainer.className = `leaflet-gl-layer mapboxgl-map xzqhMap`;
            //         // myContainer.style.zIndex = 4;
            //         // let newFilter = [
            //         //     "in",
            //         //     "name",
            //         //     ["普洱景迈山古茶林文化景观"],
            //         // ];
            //         // this.mapboxMap.setFilter("3", newFilter);
            //         // this.mapboxMap.setFilter("4", newFilter);
            //         // this.mapboxMap.setFilter("5", newFilter);
            //     });
            // });
        },
        // 画某一片 行政区划
        drawXzqhItem(group, item) {
            this.clearLayersGroup(group);
            let obj = JSON.parse(JSON.stringify(this.layerStyle));
            if (item.layerId != "all") {
                let list = [];
                this.layerStyle.layers.map((itemInner) => {
                    if (itemInner.id == item.layerId) {
                        list.push(itemInner);
                    }
                });
                obj.layers = Array.from(list);
            }
            let allProvince1 = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: obj,
            }).addTo(group);
            this.common.showVectorLayers();
            this.mapbox = allProvince1.getMapboxMap();
            this.mapbox.on("load", () => {
                this.$nextTick(() => {
                    let myContainer = this.mapbox.getContainer();
                    myContainer.className = `leaflet-gl-layer mapboxgl-map xzqhMap`;
                });
            });
        },
        // 点击公共地图上的复位
        reSet() {
            this.changeXzqh(this.listAry[4], 4);
        },
        // 景迈山
        drawMarkerJms() {
            let list = [
                {
                    JD: "99.99763169922672",
                    WD: "22.18948891619289",
                    LABEL: "景迈山",
                },
            ];
            let group = this.jmsGroup;
            let src = require("@image/bigScreen/monitor_all/jms.png");
            this.clearLayersGroup(group);
            list.map((item) => {
                let icon = window.L.divIcon({
                    html: `<img style="width:33px;height:22px; filter: drop-shadow(2px 2px 5px rgba(7,29,41,0.5));" src=${src} />`,
                    iconSize: [33, 22],
                    iconAnchor: [16, 11],
                });
                let layer = window.L.marker([item.WD, item.JD], {
                    icon: icon,
                    data: item,
                }).addTo(group);
                layer.on("click", () => {
                    this.reSet();
                    this.markerPopup.removeFrom(this.myMap);
                });
                layer.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([item.WD, item.JD])
                        .setContent(`<div class="tip">${item.LABEL}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
            });
        },
    },
};
</script>
<style scoped lang="scss">
.mapChangePartTree {
    width: 100%;
    border-radius: 9px;
    position: relative;
    .resetIcon {
        position: absolute;
        right: 37px;
        top: -61px;
    }
    .layer {
        width: 130px;
        height: 40px;
        background: #1a3059;
        border: 1px solid #35507c;
        border-radius: 10px;
        box-shadow: 5px 9px 30px 0px rgba(0, 44, 75, 0.2);
        padding: 0 20px;

        .desc {
            color: #fff;
            font-size: 18px;
        }
    }

    .layerCtn {
        padding: 10px 10px 15px 10px;
        background: rgba(26, 48, 89, 0.7);
        border-radius: 5px;
        position: relative;
        text-align: center;

        .tree {
            width: 10px;
            height: 181px;
            position: absolute;
            left: 10px;
            top: 10px;
        }
        .leaf {
            position: absolute;
            left: 10px;
            top: 20px;
            z-index: 10;
        }
        .listPart {
            width: 100%;
            height: 100%;
            .listItem {
                width: 100%;
                height: 30px;
                margin-bottom: 6px;
                position: relative;
                .circle {
                    width: 20px;
                    height: 20px;
                    background: rgba(247, 122, 0, 1);
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: -4px;
                    transform: translateY(-50%);
                }
                .name {
                    width: 100px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 14px;
                    color: rgba(255, 255, 255, 0.9);
                }
                .nameActive {
                    color: rgba(247, 122, 0, 1);
                    background: rgba(255, 255, 255, 1);
                    border-radius: 9px;
                }
            }
        }
    }
}
</style>
