// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@image/bigScreen/ontological_disease/bhkzBg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".bhkzBox[data-v-f80e2942]{height:100%;display:flex}.bhkzBox .chartPart[data-v-f80e2942]{width:calc(100% - 100px);position:relative;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%}.bhkzBox .chartPart .echart[data-v-f80e2942]{width:100%;height:100%;position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}.bhkzBox .namePart[data-v-f80e2942]{width:100px;display:flex;flex-direction:column;justify-content:space-around}.bhkzBox .namePart i[data-v-f80e2942]{width:10px;height:10px;display:inline-block;margin:0 9px}.bhkzBox .namePart span[data-v-f80e2942]{color:#d7eaf5}", ""]);
// Exports
module.exports = exports;
