// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@image/bigScreen/icon/titleBg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".screenItem[data-v-155048e6],.screenItem .screenCtn[data-v-155048e6]{width:100%;height:100%}.screenItem .screenCtn .tableList[data-v-155048e6]{width:100%;height:40px}.screenItem .screenCtn .tableList .tableItem[data-v-155048e6]{width:calc(50% - 5px);height:100%}.screenItem .screenCtn .tableList .tableItem .titlePart[data-v-155048e6]{width:calc(50% - 5px);height:100%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%}.screenItem .screenCtn .tableList .tableItem .titlePart .name[data-v-155048e6]{font-size:18px;color:#a8c8ff}.screenItem .screenCtn .tableList .tableItem .numPart[data-v-155048e6]{width:calc(50% - 5px);height:100%;border:1px solid #35507c;margin-left:5px}.screenItem .screenCtn .tableList .tableItem .numPart .num[data-v-155048e6]{font-size:34px;color:#ffcd80;font-family:DBPM}.screenItem .screenCtn .tableWrap[data-v-155048e6]{margin-top:10px;width:100%;height:calc(100% - 50px)}", ""]);
// Exports
module.exports = exports;
