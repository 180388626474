<template>
    <div class="screenItem">
        <!-- 自然环境 -->
        <BasicMonitor :text="common.getFyName(fany.zrhj)">
            <div slot="basic-module" class="screenCtn scrollbar-hidden">
                <div class="condition">
                    <SelectTimeTab :tabData="tabData" @getTabItem="getTabItem">
                    </SelectTimeTab>
                </div>
                <div class="zrhjPart" v-if="activeNum == 0">
                    <Zrhj></Zrhj>
                </div>
                <div class="zrhjPart" v-if="activeNum == 1">
                    <ZrhjMonitor></ZrhjMonitor>
                </div>
            </div>
        </BasicMonitor>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import BasicMonitor from "@views/big_screen/monitor_all/comp/BasicModule";
import SelectTimeTab from "@views/big_screen/comp/SelectTimeTab";
import Zrhj from "./comp/zrhj.vue"; //日常巡查 部分
import ZrhjMonitor from "./comp/zrhjMonitor_new.vue"; //日常巡查 部分
export default {
    components: {
        BasicMonitor,
        SelectTimeTab,
        Zrhj,
        ZrhjMonitor,
    },
    name: "",
    data() {
        return {
            activeNum: 0,
            tabData: {
                list: [
                    {
                        name: "实时监测",
                    },
                    {
                        name: "月度统计",
                    },
                ],
                value: "name",
            },
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {},
    watch: {},
    mounted() {},
    methods: {
        ...mapActions([""]),
        ...mapMutations({}),
        // 年月切换
        getTabItem(item, index) {
            this.activeNum = index;
        },
    },
};
</script>
<style scoped lang="scss">
.screenItem {
    position: relative;
    width: 100%;
    height: 100%;
    .screenCtn {
        width: 100%;
        height: 100%;
        padding: 12px 0 17px 0;
        .condition {
            position: absolute;
            right: 0;
            top: -20px;
            z-index: 1;
        }
        .zrhjPart {
            width: 100%;
            height: 389px;
        }
    }
}
</style>
