<template>
    <!-- 报警弹框 -->
    <div class="warnDialog">
        <div class="titlePart">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="flex-between-y-center">
                <div>
                    <img
                        class="bell"
                        src="@image/bigScreen/monitor_all/yj/bell.png"
                    />
                    <span class="name">报警提示</span>
                </div>
                <img
                    src="@image/bigScreen/monitor_all/yj/close.png"
                    class="close pointer"
                    @click="closeWarnDialog()"
                />
            </div>
        </div>

        <div class="warnTablePart">
            <div class="warnTable scrollbar-translucent">
                <div
                    v-for="(item, index) in listAry"
                    :key="index"
                    class="warnTableItem flex-y-center"
                >
                    <!-- 火警 -->
                    <img :src="hjSrc" class="img" v-if="item.type == 0" />
                    <!-- 其他预警 -->
                    <img :src="yjIconList[item.YJDJ].src" class="img" v-else />
                    <div class="desc" :title="item.name">
                        {{ item.name }}
                    </div>
                    <div class="desc" style="margin-left: 10px">
                        {{ item.time }}
                    </div>
                </div>
            </div>
            <div class="stopBtn pointer" @click="stopWarn()">停止警报</div>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    components: {},
    name: "",
    data() {
        return {
            hjSrc: require("@image/bigScreen/monitor_all/marker/hjIcon.gif"),
            yjIconList: [
                {
                    src: require("@image/bigScreen/monitor_all/yj/alerm1.gif"),
                },
                {
                    src: require("@image/bigScreen/monitor_all/yj/alerm2.gif"),
                },
                {
                    src: require("@image/bigScreen/monitor_all/yj/alerm3.gif"),
                },
                {
                    src: require("@image/bigScreen/monitor_all/yj/alerm4.gif"),
                },
            ],
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {
        listAry: {
            Type: Array,
            default: () => {
                return [];
            },
        },
    },
    watch: {},
    mounted() {},
    methods: {
        ...mapActions([]),
        ...mapMutations({}),
        closeWarnDialog() {
            this.$emit("closeDialog");
        },
        stopWarn() {
            this.$emit("stopWarn");
        },
    },
};
</script>
<style scoped lang="scss">
.warnDialog {
    width: 100%;
    height: 100%;
    .titlePart {
        padding: 10px 20px;
        width: 100%;
        border-bottom: 1px solid rgba(168, 200, 255, 0.2);
        padding-bottom: 10px;
        background: rgba(45, 69, 109, 1);
        position: relative;
        .line1 {
            width: 209px;
            border-bottom: 1px solid rgba(168, 200, 255, 0.4);
            position: absolute;
            left: 4px;
            bottom: 0;
        }
        .line2 {
            width: 126px;
            border-bottom: 1px solid rgba(168, 200, 255, 1);
            position: absolute;
            left: 10px;
            bottom: 0;
        }
        .bell {
            width: 30px;
            height: 30px;
        }
        .name {
            color: rgba(168, 200, 255, 1);
            font-size: 18px;
            margin-left: 10px;
        }
    }

    .warnTablePart {
        padding: 10px 20px;
        background: rgba(45, 69, 109, 0.8);
        height: 240px;
        margin-bottom: 20px;
        position: relative;
        .warnTable {
            height: calc(100% - 50px);
            .warnTableItem {
                height: 40px;
                border-bottom: 1px solid rgba(168, 200, 255, 0.8);
                .img {
                    width: 25px;
                    height: 25px;
                    margin-right: 10px;
                }
                .desc {
                    color: rgba(189, 215, 236, 1);
                    font-size: 16px;
                }
            }
        }

        .stopBtn {
            width: 102px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: rgba(168, 200, 255, 1);
            font-size: 16px;
            border: 1px solid rgba(168, 200, 255, 0.4);
            position: absolute;
            right: 10px;
            bottom: 14px;
        }
    }
}
</style>
