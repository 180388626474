<template>
    <div class="sthj-bar-chart">
        <div class="echart" :id="chartId"></div>
        <!-- <div class="noData" v-if="!listAry.length">
            <SnNoData :noDataState="true" noDataText="暂无数据"></SnNoData>
        </div> -->
    </div>
</template>
<script>
export default {
    name: "",
    data() {
        return {
            chartId: "sthjBarChart" + Math.random(),
            myChart: null,
        };
    },
    props: {
        chartData: {
            type: Object,
            default: () => {},
        },
        listAry: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    watch: {
        chartData: {
            handler(newVal, oldVal) {
                this.initChart();
            },
            deep: true,
            // immediate: true
        },
    },
    mounted() {
        $(window).resize(() => {
            this.initChart();
        });
    },
    methods: {
        initChart() {
            // 基于准备好的dom，初始化echarts实例
            this.myChart = this.$echarts.dispose(
                document.getElementById(this.chartId)
            );
            this.myChart = this.$echarts.init(
                document.getElementById(this.chartId)
            );

            // 指定图表的配置项和数据
            let option = {
                grid: {
                    top: 10,
                    right: 5,
                    bottom: 30,
                    left: 40,
                },
                tooltip: {
                    show: true,
                    trigger: "axis",
                    axisPointer: {
                        // 坐标轴指示器，坐标轴触发有效
                        type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
                    },
                },
                xAxis: [
                    {
                        data: this.chartData.xAxisData,
                        axisLabel: {
                            color: "#A8C8FF",
                            formatter: function (value) {
                                var ret = "";
                                var maxLength = 5;
                                var rowNum = Math.ceil(
                                    value.length / maxLength
                                );
                                if (rowNum > 1) {
                                    for (var i = 0; i < rowNum; i++) {
                                        var temp = "";
                                        var start = i * maxLength;
                                        var end = start + maxLength;
                                        temp =
                                            value.substring(start, end) + "\n";
                                        ret += temp;
                                    }
                                    return ret;
                                } else {
                                    return value;
                                }
                            },
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#35507C",
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    {
                        position: "top",
                        data: [],
                        axisLabel: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#35507C",
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                ],
                yAxis: {
                    axisLabel: {
                        color: "#A8C8FF",
                    },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                },
                series: [
                    {
                        name: "",
                        type: "bar",
                        barWidth: 12,
                        color: ["#5AD9A6"],
                        showBackground: false,
                        data: this.chartData.seriesData,
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "rgba(90,217,166,1)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(90,217,166,0)",
                                        },
                                    ],
                                    false
                                ),
                            },
                        },
                    },
                    {
                        name: "",
                        type: "bar",
                        barGap: "-160%",
                        barWidth: 30,
                        color: ["rgba(53,80,124,.2)"],
                        showBackground: false,
                        data: this.chartData.maxData,
                        tooltip: {
                            show: false,
                        },
                    },
                ],
            };

            // 使用刚指定的配置项和数据显示图表。
            this.myChart.setOption(option);
        },
    },
};
</script>
<style lang="scss" scoped>
.sthj-bar-chart {
    width: 100%;
    height: 100%;
    position: relative;
    .echart {
        width: 100%;
        height: 100%;
    }
    .noData {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
    }
}
</style>