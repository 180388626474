<template>
    <div class="gcflPart">
        <!-- 工程分类 -->
        <basic-module :text="common.getFyName(fany.gcfl)" fontSize="12px">
            <div slot="basic-module" class="gcflCtn">
                <div :id="id" class="chart_box"></div>
            </div>
        </basic-module>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapGetters } from "vuex";
export default {
    components: {},
    data() {
        return {
            year: "",
            dataConfig: null,
            chart: null,
            id: "gcfl" + Math.random(),
        };
    },
    computed: {
        ...mapGetters({}),
        options: function () {
            return [];
        },
    },
    mounted() {
        this.getData();
        $(window).resize(() => {
            setTimeout(() => {
                this.chart && this.chart.resize();
            }, 300);
        });
    },
    methods: {
        ...mapActions(["BhgcFljfStatistics"]),
        selected(value) {
            this.year = value;
            this.getData();
        },
        async getData() {
            const year = this.year;
            const res = await this.BhgcFljfStatistics({ year });
            const xAxisData = [];
            const yAxisData1 = [];
            const yAxisData2 = [];
            if (Array.isArray(res) && res.length > 0) {
                res.forEach((ele) => {
                    xAxisData[ele.GCFL] = ele.GCFLBM;
                    yAxisData1[ele.GCFL] = ele.GJTRZJF;
                    yAxisData2[ele.GCFL] = ele.COUNT;
                });
            }
            this.dealData({ xAxisData, yAxisData1, yAxisData2 });
        },
        dealData({ xAxisData, yAxisData1, yAxisData2 }) {
            const main_color = "#679FC0";
            const colorList = ["#E76452", "#5AD9A6"];
            this.dataConfig = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                    formatter: (params) => {
                        let str = `${params[0]["axisValueLabel"]}：<br/>`;
                        let nameList = ["万元", "个"];
                        params.map((item, index) => {
                            str += `${item.marker}${item.seriesName}：${item.value}${nameList[index]}<br/>`;
                        });
                        return str;
                    },
                },
                legend: {
                    // y: 13,
                    // right: 80,
                    icon: "rect",
                    itemWidth: 14,
                    itemHeight: 5,
                    textStyle: {
                        color: main_color,
                    },
                },
                grid: {
                    top: 40,
                    bottom: 40,
                    left: 40,
                    right: 40,
                },
                xAxis: {
                    type: "category",
                    data: xAxisData,
                    axisLine: {
                        lineStyle: {
                            color: main_color,
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        textStyle: {
                            color: main_color,
                            fontSize: 12,
                        },
                        interval: 0,
                        formatter: function (params) {
                            var newParamsName = ""; // 最终拼接成的字符串
                            var paramsNameNumber = params.length; // 实际标签的个数
                            var provideNumber = 4; // 每行能显示的字的个数
                            var rowNumber = Math.ceil(
                                paramsNameNumber / provideNumber
                            ); // 换行的话，需要显示几行，向上取整
                            /**
                             * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                             */
                            // 条件等同于rowNumber>1
                            if (paramsNameNumber > provideNumber) {
                                /** 循环每一行,p表示行 */
                                for (var p = 0; p < rowNumber; p++) {
                                    var tempStr = ""; // 表示每一次截取的字符串
                                    var start = p * provideNumber; // 开始截取的位置
                                    var end = start + provideNumber; // 结束截取的位置
                                    // 此处特殊处理最后一行的索引值
                                    if (p == rowNumber - 1) {
                                        // 最后一次不换行
                                        tempStr = params.substring(
                                            start,
                                            paramsNameNumber
                                        );
                                    } else {
                                        // 每一次拼接字符串并换行
                                        tempStr =
                                            params.substring(start, end) + "\n";
                                    }
                                    newParamsName += tempStr; // 最终拼成的字符串
                                }
                            } else {
                                // 将旧标签的值赋给新标签
                                newParamsName = params;
                            }
                            //将最终的字符串返回
                            return newParamsName;
                        },
                    },
                },
                yAxis: [
                    {
                        type: "value",
                        name: "经费总额(万元)",
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: main_color,
                            },
                        },
                    },
                    {
                        type: "value",
                        minInterval: 1,
                        name: "工程数量(个)",
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: main_color,
                            },
                        },
                    },
                ],
                series: [
                    {
                        type: "line",
                        name: "经费总额",
                        barWidth: 20,
                        itemStyle: {
                            color: colorList[0],
                        },
                        data: yAxisData1,
                    },
                    {
                        type: "bar",
                        name: "工程数量",
                        barWidth: 20,
                        itemStyle: {
                            color: colorList[1],
                        },
                        yAxisIndex: 1,
                        data: yAxisData2,
                    },
                ],
            };
            this.draw();
        },
        draw() {
            this.chart = this.$echarts.init(document.getElementById(this.id));
            this.chart.setOption(this.dataConfig);
        },
    },
};
</script>

<style lang="scss" scoped>
.gcflPart {
    height: 100%;
    width: 100%;
    .gcflCtn {
        height: 100%;
        width: 100%;
        .chart_box {
            height: 100%;
            width: 100%;
        }
    }
}
</style>
