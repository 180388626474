<template>
    <div class="headerPart flex-y-center flex-end">
        <div class="timePart">{{ time }}</div>
        <div class="line"></div>
        <div class="weatherPart flex-y-center">
            <span
                class="weather-info"
                :style="{
                    backgroundPosition:
                        backgroundPosition[weatherState] || '1000px 1000px',
                }"
            ></span>
            <span>{{ weatherDesc }}</span>
        </div>
        <div class="line"></div>
        <div class="actionPart">
            <SnActionBtn
                :showNameList="['翻译', '全屏', '主页', '退出']"
                :srcState="false"
            ></SnActionBtn>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    name: "",
    data() {
        return {
            activeNum: null,
            time: "",
            weatherDesc: "",
            weatherState: "",
            backgroundPosition: {
                晴: "0 5px",
                多云: "-80px 2px",
                阴: "-160px 2px",
                雨: "-795px 5px",
            },
        };
    },
    components: {},
    props: {},
    watch: {},
    mounted() {
        this.getTime();
        this.getWeather();
    },
    methods: {
        ...mapActions(["GetQxjcData"]),
        ...mapMutations({}),
        navTo(item, index) {
            this.activeNum = index;
            this.$router.push({
                path: item.url,
                query: {
                    scale: this.$route.query.scale,
                },
            });
        },

        getTime() {
            setInterval(() => {
                let str = this.common.getTimeYmdhms();
                this.time =
                    str.slice(0, 4) +
                    "." +
                    str.slice(5, 7) +
                    "." +
                    str.slice(8, 10) +
                    " " +
                    str.slice(11);
            }, 1000);
        },
        async getWeather() {
            let result = await this.GetQxjcData({});
            let obj = result;
            if (obj.WD == "999" || obj.WD == "9999") {
                obj.WD = "";
            }
            if (obj.FX == "999" || obj.FX == "9999") {
                obj.FX = "";
            }

            if (obj.WD) {
                this.weatherDesc = `${obj.DQTQ}/${obj.WD}℃/${obj.FX}${obj.FL}`;
            } else {
                this.weatherDesc = `${obj.DQTQ}/${obj.FX}${obj.FL}`;
            }
            this.weatherState = obj.DQTQ;
        },
    },
};
</script>
<style scoped lang="scss">
.headerPart {
    .timePart {
        color: rgba(204, 223, 255, 1);
        font-size: 36px;
        font-family: DBPM;
        margin-right: 10px;
    }
    .line {
        width: 2px;
        height: 30px;
        background: rgba(168, 200, 255, 1);
        margin-right: 10px;
    }
    .weatherPart {
        color: rgba(204, 223, 255, 1);
        font-size: 16px;
        margin-right: 10px;
        .weather-info {
            display: inline-block;
            width: 40px;
            height: 40px;
            background: url("~@image/bigScreen/icon/weather_icon.png") no-repeat
                2px 0;
            transform: scale(0.6);
        }
    }
    // 操作
    .actionPart {
        width: 183px;
        margin-left: 10px;
        margin-right: 53px;
    }
}
</style>
