<template>
    <!-- 今年数据 -->
    <div class="topPart">
        <basic-module :text="common.getFyName(fany.jnsj)">
            <div slot="basic-module" class="jgtjCtn">
                <div class="flex-between">
                    <div
                        v-for="(item, index) in jgtjList"
                        :key="index"
                        class="listItem animated bounceIn"
                        :style="{ animationDelay: 0.2 + index * 0.2 + 's' }"
                    >
                        <div class="title flex-both-center">
                            {{ item.name }}
                        </div>
                        <div class="numPart flex-both-center">
                            <span class="num">{{ item.num }}</span>
                            <span class="dw">{{ item.dw }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </basic-module>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    name: "",
    components: {
        
    },
    data() {
        return {
            jgtjList: [
                {
                    name: "古茶树产量",
                    num: 0,
                    dw: "kg",
                },
                {
                    name: "生态茶产量",
                    num: 0,
                    dw: "kg",
                },
                {
                    name: "古茶树出售量",
                    num: 0,
                    dw: "kg",
                },
                {
                    name: "生态茶出售量",
                    num: 0,
                    dw: "kg",
                },
                {
                    name: "茶企数量",
                    num: 0,
                    dw: "家",
                },
            ],
            allNum: 0,
        };
    },
    props: {},
    computed: {
        ...mapGetters({
            
        }),
    },
    mounted() {
        this.getJnsjData();
    },
    methods: {
        ...mapActions([
            "GetNewYearData", // 获取今年数据
        ]),
        ...mapMutations({
            
        }),
        // 获取结果统计数据
        async getJnsjData() {
            let result = await this.GetNewYearData();
            if(result.ISSUCCESS) {
                let obj = result.RESULTVALUE;
                this.jgtjList[0].num = obj.GSCCL; //古茶出产量
                this.jgtjList[1].num = obj.STCCL; //生态茶产量
                this.jgtjList[2].num = obj.GSCCSL; //生态茶出售量
                this.jgtjList[3].num = obj.STCCSL; //生态茶出售量
                this.jgtjList[4].num = obj.CQ; //茶企数量
            }
        },
    },
};
</script>
<style scoped lang="scss">
.topPart {
    width: 100%;
    height: 100%;
    .jgtjCtn {
        width: 100%;
        height: 100%;
        .listItem {
            width: 162px;
            padding-left: 10px;
            .title {
                width: 100%;
                height: 40px;
                background: url("~@image/bigScreen/icon/titleBg.png")
                    no-repeat;
                background-size: 100% 100%;
                font-size: 18px;
                color: rgba(168, 200, 255, 1);
            }
            .numPart {
                margin-top: 10px;
                height: 65px;
                border: 1px solid rgba(53, 80, 124, 0.4);
                .num {
                    font-size: 50px;
                    color: rgba(189, 215, 236, 1);
                    font-family: DBPM;
                }
                .dw {
                    color: rgba(189, 215, 236, 1);
                    position: relative;
                    left: 4px;
                    top: 4px;
                    font-size: 18px;
                }
            }
        }
    }
}
</style>
