<template>
    <div class="rightPart">
        <BoxTitle
            :title="jskzJgsxItem.MC"
            @handleClose="handleClose"
        ></BoxTitle>
        <div class="baseInfoPart">
            <!-- 基准图 -->
            <basic-module :text="common.getFyName(fany.jzt)">
                <div slot="basic-module" class="baseInfoCtn">
                    <el-image
                        :src="basicSrc"
                        fit="cover"
                        style="width: 100%; height: 100%"
                        class="pointer"
                        v-viewer
                    >
                        <div
                            slot="placeholder"
                            style="width: 100%; height: 100%"
                        >
                            加载中
                            <span class="dot">...</span>
                        </div>
                        <div slot="error" style="width: 100%; height: 100%">
                            <SnNoData></SnNoData>
                        </div>
                    </el-image>
                </div>
            </basic-module>
        </div>

        <div class="followInfoPart">
            <!-- 监测信息 -->
            <basic-module :text="common.getFyName(fany.jcxx)">
                <div slot="basic-module" class="followInfoCtn">
                    <div class="condition">
                        <SelectTimeTab
                            :selectData="selectData"
                            @getSelectItem="getSelectItem"
                        >
                        </SelectTimeTab>
                    </div>
                    <el-image
                        :src="trackSrc"
                        fit="cover"
                        style="width: 100%; height: 100%"
                        class="pointer"
                        v-viewer
                    >
                        <div
                            slot="placeholder"
                            style="width: 100%; height: 100%"
                        >
                            加载中
                            <span class="dot">...</span>
                        </div>
                        <div slot="error" style="width: 100%; height: 100%">
                            <SnNoData></SnNoData>
                        </div>
                    </el-image>
                </div>
            </basic-module>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import SelectTimeTab from "@views/big_screen/comp/SelectTimeTab";
import BoxTitle from "@views/big_screen/comp/BoxTitle.vue"; //标题

export default {
    components: {
        SelectTimeTab,
        BoxTitle,
    },
    name: "",
    data() {
        return {
            basicSrc: "",
            selectData: {
                state: true,
                key: "",
                clearable: false,
                width: "140px",
                list: [],
                label: "time",
                value: "time",
            },
            trackSrc: "",
        };
    },
    computed: {
        ...mapGetters(["jskzJgsxItem"]),
    },
    props: {},
    watch: {
        jskzJgsxItem: {
            immediate: true,
            handler() {
                this.getBaseInfo();
                this.getFollowInfo();
            },
            deep: true,
        },
    },
    mounted() {},
    methods: {
        ...mapActions(["GetSightLineBasic", "GetSightLineMonitorList"]),
        ...mapMutations({
            setStateJskz: "setStateJskz",
        }),
        // 获取 基准图
        async getBaseInfo() {
            let result = await this.GetSightLineBasic({
                id: this.jskzJgsxItem.ID,
            });
            if (result.FILE.length) {
                this.basicSrc = result.FILE[0].CCLJ;
            } else {
                this.basicSrc = "";
            }
        },
        // 获取 监测信息
        async getFollowInfo() {
            let result = await this.GetSightLineMonitorList({
                id: this.jskzJgsxItem.ID,
            });
            result.map((item) => {
                item.time = item.RKSJ.split(" ")[0];
            });
            if (result.length) {
                this.trackSrc = result[0].FILE.length
                    ? result[0].FILE[0].CCLJ
                    : "";
                this.selectData.key = result[0].time;
                this.selectData.list = result;
            } else {
                this.trackSrc = "";
                this.selectData.key = "";
                this.selectData.list = [];
            }
        },
        getSelectItem(item, index) {
            let obj = this.selectData.list[index];
            if (obj.FILE.length) {
                this.trackSrc = obj.FILE[0].CCLJ;
            } else {
                this.basicSrc = "";
            }
        },
        handleClose() {
            this.setStateJskz({
                jskzPanelNum: 0,
                jskzJgsxItem: null,
            });
        },
    },
};
</script>
<style scoped lang="scss">
.rightPart {
    width: 100%;
    height: 100%;
    background: rgba(26, 56, 89, 0.3);
    padding-bottom: 12px;
    // 基准图
    .baseInfoPart {
        width: 100%;
        height: calc(50% - 18px);
        // border: 1px solid #fff;
        .baseInfoCtn {
            width: 100%;
            height: 100%;
        }
    }
    // 监测信息
    .followInfoPart {
        width: 100%;
        height: calc(50% - 18px);

        .followInfoCtn {
            width: 100%;
            height: 100%;
            position: relative;

            .condition {
                position: absolute;
                right: 2px;
                top: -44px;
            }
        }
    }
}
</style>
