<template>
    <div class="rightPart scrollbar-hidden">
        <Jgtj class="jgtjPart"></Jgtj>
        <GcslYJf class="gcslyjf"></GcslYJf>
        <Gcjzqktj class="gcjzzqktj"></Gcjzqktj>
        <Gcfltj class="gcfltj"></Gcfltj>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import Jgtj from "./comp/1_bhgctj.vue"; //保护工程统计
import GcslYJf from "./comp/2_gcslyjf"; //工程数量与经费
import Gcjzqktj from "./comp/3_gcjzqktj"; //工程进展情况统计
import Gcfltj from "./comp/4_gcfltj"; //工程分类
export default {
    components: {
        Jgtj,
        GcslYJf,
        Gcjzqktj,
        Gcfltj,
    },
    name: "",
    data() {
        return {};
    },
    computed: {
        ...mapGetters({}),
    },
    props: {},
    watch: {},
    mounted() {},
    methods: {
        ...mapActions([]),
        ...mapMutations({}),
    },
};
</script>
<style scoped lang="scss">
.rightPart {
    width: 100%;
    height: 100%;
    // 结果统计
    .jgtjPart {
        width: 100%;
        height: 180px;
    }
    .gcslyjf {
        width: 100%;
        height: 280px;
    }
    .gcjzzqktj {
        width: 100%;
        height: 140px;
    }
    .gcfltj {
        width: 100%;
        height: calc(100% - 600px);
        min-height: 300px;
    }
}
</style>
