<template>
    <div class="scanPart flex-both-center">
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <span>
            <div class="desc">{{ text }}</div>
            <div class="num" v-if="num">{{ num }}</div>
        </span>
    </div>
</template>
<script>
export default {
    name: "",
    data() {
        return {};
    },
    components: {},
    computed: {},
    props: {
        text: {
            type: String,
            default: "扫描中",
        },
        num: {
            type: Number,
            default: "",
        },
    },
    mounted() {},
    methods: {},
};
</script>
<style scoped lang="scss">
.scanPart {
    width: 100%;
    height: 100%;
    position: relative;
    // 扫描动画
    .desc,
    .num {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        color: #fff;
        font-weight: 700;
        text-align: center;
        font-size: 18px;
    }
    i {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: 50% 50%;

        &:nth-of-type(1) {
            background: url("~@image/bigScreen/monitor_all/scanning/01.svg")
                no-repeat;
            animation: nszScan 8s linear infinite;
        }
        &:nth-of-type(2) {
            background: url("~@image/bigScreen/monitor_all/scanning/02.svg")
                no-repeat;
            animation: sszScan 8s linear infinite;
        }
        &:nth-of-type(3) {
            background: url("~@image/bigScreen/monitor_all/scanning/03.svg")
                no-repeat;
            animation: nszScan 8s linear infinite;
        }
        &:nth-of-type(4) {
            background: url("~@image/bigScreen/monitor_all/scanning/04.svg")
                no-repeat;
            animation: sszScan 8s linear infinite;
        }
    }
}

@keyframes sszScan {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes nszScan {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
    }
}
@media screen and (max-width: 1500px) {
}
</style>
