<template>
    <div v-infinite-scroll="loadData">
        <div
            v-for="(item, index) in listAry"
            @click="handleSearchItem(item, index)"
            :style="{
                color: item.color ? item.color : '',
                fontSize: fontSize,
                height: height,
                lineHeight: height,
            }"
            class="searchItem pointer"
            :class="[activeNum === index ? 'searchActive' : 'searchDefault']"
            :key="index"
        >
            <div
                class="triangle"
                :class="[
                    activeNum === index ? 'triangleActive' : 'triangleDefault',
                ]"
            ></div>
            <span>{{ item[labelValue] }}</span>
            <span class="diseaseCircle" v-show="item.diseaseState"></span>
        </div>
    </div>
</template>
<script>
export default {
    name: "",
    data() {
        return {
            activeNum: null,
        };
    },
    components: {},
    computed: {},
    props: {
        activeNumOut: {
            type: Number,
            default: null,
        },
        listAry: {
            type: Array,
            default: () => [],
        },
        fontSize: {
            type: String,
            default: "24px",
        },
        height: {
            type: String,
            default: "40px",
        },
        searchState: {
            type: Boolean,
            default: true,
        },
        labelValue: {
            type: String,
            default: "name",
        },
    },
    watch: {
        searchState: {
            immediate: false,
            handler(newVal, oldVal) {
                // 关闭选中效果
                if (this.searchState == false) {
                    this.activeNum = null;
                }
            },
            deep: true,
        },
        activeNumOut: {
            immediate: false,
            handler(newVal, oldVal) {
                this.activeNum = this.activeNumOut;
            },
            deep: true,
        },
    },
    methods: {
        // 查看清单详情
        handleSearchItem(item, index) {
            if (this.activeNum === index) {
                this.activeNum = null;
                this.$emit("handleSearchItem", null, index);
            } else {
                this.activeNum = index;
                this.$emit("handleSearchItem", item, index);
            }
        },
        loadData() {
            this.$emit("loadData");
        },
    },
};
</script>
<style lang="scss" scoped>
.searchItem {
    margin-top: 10px;
    width: 100%;
    position: relative;
    text-align: center;
    span {
        font-size: 14px;
    }
    &:nth-child(1) {
        margin-top: 0;
    }
}

.searchDefault {
    background: rgba(26, 48, 89, 0.4);
    background-size: 100% 100%;
    color: rgba(154, 180, 227, 1);
    &:hover {
        background: rgba(26, 48, 89, 1);
        color: rgb(215, 234, 245);
        .triangleDefault {
            border-top: 10px solid rgb(255, 230, 0);
        }
    }
}

.searchActive {
    background: rgba(26, 48, 89, 1);
    background-size: 100% 100%;
    color: rgb(215, 234, 245);
}

.triangle {
    position: absolute;
    left: 0;
    top: 0;
}

.triangleDefault {
    width: 0;
    height: 0;
    border-top: 10px solid rgba(154, 180, 227, 1);
    border-right: 10px solid transparent;
}

.triangleActive {
    width: 0;
    height: 0;
    border-top: 10px solid rgb(255, 230, 0);
    border-right: 10px solid transparent;
}

.diseaseCircle {
    margin-left: 10px;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgb(248, 98, 68);
}
</style>
