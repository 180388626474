<template>
    <div class="dialogCtn flex flex-column scrollbar-translucent">
        <div class="lineItem flex">
            <div style="width: 200px" class="borderRight flex-both-center">
                <div class="label">挂牌号:</div>
                <div class="num">{{ tableData.BH }}</div>
            </div>
            <div
                class="label borderRight flex-both-center"
                style="width: 100px; height: 132px"
            >
                树种
            </div>
            <div class="flex-1">
                <div class="flex-y-center boderBottom">
                    <div class="label">中文名:</div>
                    <div class="num" style="margin-right: 10px">
                        {{ tableData.SZZWM }}
                    </div>
                    <div class="label">别名:</div>
                    <div class="num">{{ tableData.SZBM }}</div>
                </div>
                <div class="flex boderBottom">
                    <div class="label">拉丁名:</div>
                    <div class="num">{{ tableData.SZLDM }}</div>
                </div>
                <div class="flex">
                    <div class="label">科:</div>
                    <div class="num" style="margin-right: 10px">
                        {{ tableData.SZKM }}
                    </div>
                    <div class="label">属:</div>
                    <div class="num">{{ tableData.SZSM }}</div>
                </div>
            </div>
        </div>
        <div class="lineItem flex">
            <div style="width: 100px" class="borderRight flex-both-center">
                <div class="label">位置</div>
            </div>
            <div class="flex-1">
                <div class="flex-y-center boderBottom">
                    <div class="label">乡镇:</div>
                    <div class="num" style="margin-right: 20px">
                        {{ tableData.XZ }}
                    </div>
                    <div class="label">行政村:</div>
                    <div class="num" style="margin-right: 20px">
                        {{ tableData.CM }}
                    </div>
                    <div class="label">自然村:</div>
                    <div class="num">{{ tableData.ZM }}</div>
                </div>
                <div class="flex-y-center boderBottom">
                    <div class="label">小地名:</div>
                    <div class="num">{{ tableData.XDM }}</div>
                </div>
                <div class="flex-y-center">
                    <div class="flex-1 flex borderRight">
                        <div
                            class="label borderRight center"
                            style="width: 100px"
                        >
                            经度
                        </div>
                        <div class="flex-1 num center">
                            {{ tableData.DJ }}
                        </div>
                    </div>
                    <div class="flex-1 flex">
                        <div
                            class="label borderRight center"
                            style="width: 100px"
                        >
                            纬度
                        </div>
                        <div class="flex-1 num center">
                            {{ tableData.BW }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="lineItem flex">
            <div class="flex-both-center borderRight" style="width: 50%">
                <div class="label borderRight center" style="width: 100px">
                    树龄
                </div>
                <div class="flex-1 flex-both-center">
                    <div class="num">{{ tableData.SMXZSL }}</div>
                    <div class="label">年</div>
                </div>
            </div>
            <div class="flex-y-center" style="width: 50%">
                <div class="label borderRight center" style="width: 100px">
                    海拔
                </div>
                <div class="flex-1 flex-both-center">
                    <div class="num">{{ tableData.HB }}</div>
                    <div class="label">m</div>
                </div>
            </div>
        </div>

        <div class="lineItem flex">
            <div class="flex-both-center borderRight" style="width: 50%">
                <div class="label borderRight center" style="width: 100px">
                    树高
                </div>
                <div class="flex-1 flex-both-center">
                    <div class="num">{{ tableData.SMXZSG }}</div>
                    <div class="label">m</div>
                </div>
            </div>
            <div class="flex-y-center" style="width: 50%">
                <div class="label borderRight center" style="width: 100px">
                    胸径
                </div>
                <div class="flex-1 flex-both-center">
                    <div class="num">{{ tableData.SMXZXJ }}</div>
                    <div class="label">cm</div>
                </div>
            </div>
        </div>

        <div class="lineItem flex">
            <div class="flex-both-center borderRight" style="width: 50%">
                <div class="label borderRight center" style="width: 100px">
                    权属
                </div>
                <div class="flex-1 flex-both-center">
                    <div class="num">{{ tableData.QS }}</div>
                </div>
            </div>
            <div class="flex-y-center" style="width: 50%">
                <div class="label borderRight center" style="width: 140px">
                    管护单位或个人
                </div>
                <div class="flex-1 flex-both-center">
                    <div class="num">{{ tableData.GHDW }}</div>
                </div>
            </div>
        </div>
        <div class="lineItem flex">
            <div style="width: 100px" class="borderRight flex-both-center">
                <div class="label">冠幅</div>
            </div>
            <div class="flex-1 flex">
                <div class="flex-1 flex-y-center borderRight">
                    <div class="label">平均冠幅:</div>
                    <div class="num">{{ tableData.SMXZPJGF }}</div>
                    <div class="label">m</div>
                </div>
                <div class="flex-1 flex-y-center borderRight">
                    <div class="label">东西冠幅:</div>
                    <div class="num">{{ tableData.SMXZDXGF }}</div>
                    <div class="label">m</div>
                </div>
                <div class="flex-1 flex-y-center">
                    <div class="label">南北冠幅:</div>
                    <div class="num">{{ tableData.SMXZNBGF }}</div>
                    <div class="label">m</div>
                </div>
            </div>
        </div>
        <div class="lineItem flex">
            <div class="label borderRight center" style="width: 100px">
                生长势
            </div>
            <div class="flex-1 num flex-both-center">
                {{ tableData.SZS }}
            </div>
        </div>
        <div class="lineItem flex">
            <div
                class="label borderRight flex-both-center"
                style="width: 100px; height: 60px; line-height: 20px"
            >
                茶树特殊状况描述
            </div>
            <div class="flex-1 num flex-both-center" style="height: 60px">
                {{ tableData.SMTSZKMS }}
            </div>
        </div>
        <div class="lineItem flex">
            <div
                class="label borderRight flex-both-center"
                style="width: 100px; height: 60px; line-height: 20px"
            >
                保护现状及建议
            </div>
            <div class="flex-1 num flex-both-center" style="height: 60px">
                {{ tableData.BHXZ }}
            </div>
        </div>
        <div class="lineItem flex">
            <div class="label borderRight center" style="width: 100px">
                国内分布
            </div>
            <div class="flex-1 num flex-both-center">
                {{ tableData.GNFB }}
            </div>
        </div>
        <div class="lineItem flex">
            <div class="flex-both-center borderRight" style="width: 50%">
                <div class="label borderRight center" style="width: 100px">
                    调查者
                </div>
                <div class="flex-1 num flex-both-center">
                    {{ tableData.DCR }}
                </div>
            </div>
            <div class="flex-y-center" style="width: 50%">
                <div class="label borderRight center" style="width: 100px">
                    日期
                </div>
                <div class="flex-1 num flex-both-center">
                    {{ tableData.DCRQ }}
                </div>
            </div>
        </div>
        <div class="lineItem flex flex-1" style="border-bottom: none">
            <div
                class="label borderRight flex-both-center"
                style="width: 100px; height: 100%; line-height: 20px"
            >
                古树名木生长状态
            </div>
            <div
                v-viewer
                class="flex-1 flex flex-wrap scrollbar"
                style="height: 100%; padding: 10px"
                :style="{ maxHeight: screenState ? '337px' : '201px' }"
            >
                <el-image
                    v-for="(item, index) in tableData.PICTUREPATHS"
                    :key="index"
                    :src="item"
                    fit="cover"
                    class="pointer"
                    style="
                        width: 140px;
                        height: 140px;
                        margin-right: 10px;
                        margin-bottom: 10px;
                    "
                >
                    <div slot="error" style="width: 100%; height: 100%">
                        <SnNoData width="80px"></SnNoData>
                    </div>
                </el-image>
            </div>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import TableList from "@views/big_screen/comp/TableList";
export default {
    components: {
        TableList,
    },
    name: "",
    data() {
        return {
            screenState: false,
            tableData: {
                BH: "", //挂牌号
                BHXZ: "",
                GNFB: "",
                BW: "",
                CM: "",
                DCR: "",
                DCRQ: "",
                DJ: "",
                GHDW: "",
                HB: "",
                PICTUREPATHS: [],
                QS: "",
                SMTSZKMS: "",
                SMXZDXGF: "",
                SMXZJBGW: "",
                SMXZNBGF: "",
                SMXZPJGF: "",
                SMXZSG: "",
                SMXZSL: "",
                SZBM: "",
                SZKM: "",
                SZLDM: "",
                SZS: "",
                SZSM: "",
                SZZWM: "",
                XDM: "",
                XZ: "",
                ZM: "",
            },
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {
        detail: {
            Type: Object,
            default: () => {
                let obj = {};
                return obj;
            },
        },
    },
    watch: {
        detail: {
            immediate: true,
            handler() {
                this.getMainData();
            },
        },
    },
    mounted() {
        this.reset();
    },
    methods: {
        ...mapActions(["GetAncientTreesDetail"]),
        ...mapMutations({}),
        reset() {
            this.screenState = this.common.screenReset().height1920;
            window.onresize = () => {
                this.reset();
            };
        },
        async getMainData() {
            let result = await this.GetAncientTreesDetail({
                id: this.detail.ID,
            });
            this.tableData = result;
        },
    },
};
</script>
<style scoped lang="scss">
.dialogCtn {
    width: 100%;
    height: calc(100% - 1px);
    border: 1px solid #9ab4e3;
    .lineItem {
        border-bottom: 1px solid #9ab4e3;
        width: 100%;
        .num {
            color: #9ab4e3;
            font-size: 14px;
            height: 43px;
            line-height: 43px;
            padding: 0 4px;
        }
        .label {
            color: #9ab4e3;
            font-size: 14px;
            height: 43px;
            line-height: 43px;
            padding: 0 4px;
        }
        .center {
            text-align: center;
        }
        .borderRight {
            border-right: 1px solid #9ab4e3;
        }
        .boderBottom {
            border-bottom: 1px solid #9ab4e3;
        }
    }
}
</style>
