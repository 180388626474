// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@image/bigScreen/protect_project/errorBg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".basicInfo[data-v-5e134712]{width:100%;height:560px}.basicInfo .basicCtn[data-v-5e134712]{width:100%;height:100%;position:relative}.basicInfo .basicCtn .basicItem[data-v-5e134712]{width:100%;flex:1}.basicInfo .basicCtn .basicItem .name[data-v-5e134712]{height:35px;line-height:35px;text-align:left;color:#5673a2;font-size:16px}.basicInfo .basicCtn .basicItem .desc[data-v-5e134712]{min-height:35px;line-height:35px;text-align:left;background:rgba(26,48,89,.3);color:#d7eaf5;padding-left:10px;font-size:16px}.basicInfo .basicCtn .basicItem .empty[data-v-5e134712]{color:#a2a8ab;font-size:14px}.basicInfo .basicCtn .basicItem .check[data-v-5e134712]{overflow:hidden;line-height:30px;font-size:14px;height:113px}.basicInfo .basicCtn .smallBox[data-v-5e134712]{display:flex;justify-content:space-between}.basicInfo .basicCtn .smallBox .smallInfoItem[data-v-5e134712]{width:50%}.basicInfo .basicCtn .errorBtn[data-v-5e134712]{position:absolute;right:0;top:-52px;font-weight:400;font-style:normal;font-size:14px;color:#a9c9ff;line-height:28px;background-color:#35507c;padding:0 10px;border-radius:5px;cursor:pointer}.basicInfo .basicCtn .errorPartWrap[data-v-5e134712]{position:absolute;left:-1px;top:-26px;z-index:1;width:663px;height:358px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;padding:20px 20px;color:#a9c9ff;font-size:14px}.basicInfo .basicCtn .errorPartWrap .errorItem[data-v-5e134712]{margin-bottom:10px}.basicInfo .basicCtn .errorPartWrap .carouselPart .imgPart .imgItem[data-v-5e134712]{height:112px}", ""]);
// Exports
module.exports = exports;
