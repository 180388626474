<template>
    <div class="screenItem">
        <!-- 旅游游客 -->
        <BasicMonitor :text="common.getFyName(fany.lyyk)">
            <div slot="basic-module" class="screenCtn">
                <div class="listPart flex-between">
                    <div
                        v-for="(item, index) in listAry"
                        :key="index"
                        class="listItem animated bounceIn"
                        :style="{ animationDelay: 0.2 + index * 0.2 + 's' }"
                    >
                        <div class="title flex-both-center">
                            {{ item.name }}
                        </div>
                        <div class="num flex-both-center">
                            {{ item.num }}
                        </div>
                    </div>
                </div>
                <div class="decoratePart flex-between">
                    <div class="decorate_1"></div>
                    <div class="decorate_2"></div>
                    <div class="decorate_3"></div>
                    <div class="decorate_4 flex-1"></div>
                    <div class="condition flex-y-center">
                        <SelectTimeTab
                            :selectData="selectData"
                            :tabData="tabData"
                            @getTabItem="getTabItem"
                            @getSelectItem="getSelectItem"
                        >
                        </SelectTimeTab>
                    </div>
                </div>

                <div class="echartPart">
                    <div class="bgPart">
                        <div class="bg1"></div>
                        <div class="bg2"></div>
                        <div class="bg3"></div>
                        <div class="bg4"></div>
                        <div class="bg5"></div>
                        <div class="bg6"></div>
                    </div>

                    <div class="echart" id="lyykEchart"></div>
                </div>
            </div>
        </BasicMonitor>
    </div>
</template>
<script>
// import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import BasicMonitor from "@views/big_screen/monitor_all/comp/BasicModule";
import SelectTimeTab from "@views/big_screen/comp/SelectTimeTab";
export default {
    components: {
        BasicMonitor,
        SelectTimeTab,
    },
    name: "",
    data() {
        return {
            tabData: {
                list: [
                    {
                        name: "年",
                    },
                    {
                        name: "月",
                    },
                ],
                value: "name",
            },
            selectData: {
                state: false,
                defaultValue: "", //默认值 年
                clearable: false,
                width: "100px",
                list: [],
                label: "NF",
                value: "NF",
            },
            listAry: [
                {
                    name: "瞬时游客量",
                    num: 0,
                },
                {
                    name: "今日游客量",
                    num: 0,
                },
                {
                    name: "日游客承载量",
                    num: 0,
                },
                // {
                //     name: "本周游客数",
                //     num: 0,
                // },
                {
                    name: "年度游客数",
                    num: 0,
                },
            ],
            echartList: [],
            echart: null,
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {},
    watch: {},
    created() {
        this.getTimeList();
    },
    mounted() {
        this.getLyykInfo();
        this.monitorLyykInfo();
        this.getEchartData();
        // window.onresize = () => {
        //
        // };
    },
    methods: {
        ...mapActions([
            "GetTouristYearList",
            "GetTouristTotal",
            "GetTouristEcharts",
        ]),
        ...mapMutations({}),
        // 获取时间
        async getTimeList() {
            let result = await this.GetTouristYearList({});
            if (result.length) {
                this.selectData.defaultValue = result[0].NF; //默认值 年
                this.selectData.list = result;
            }
        },
        // 年月切换
        getTabItem(item) {
            if (item.name == "月") {
                this.selectData.defaultValue = this.selectData.list[0].NF;
                this.selectData.state = true;
            } else {
                this.selectData.state = false;
            }
            this.getEchartData();
        },
        // 年份切换
        getSelectItem(item) {
            this.selectData.defaultValue = item.NF;
            this.getEchartData();
        },
        // 获取 旅游游客
        async getLyykInfo() {
            let result = await this.GetTouristTotal({});
            this.listAry[0].num = result.HOUR; //瞬时游客量
            this.listAry[1].num = result.DAY; //今日游客量
            // this.listAry[2].num = result.WEEK; //本周游客数
            this.listAry[3].num = result.YEAR; //年度游客数
            // this.listAry[4].num = result.TOTAL; //总游客数
            // this.listAry[0].num = 1135; //瞬时游客量
            // this.listAry[1].num = 3460; //今日游客量
            this.listAry[2].num = 3350; //日游客承载量
            // this.listAry[3].num = 25818; //本周游客数
        },
        // 消息推送 监听
        monitorLyykInfo() {
            // window.signalr_connection.on("MonitoringOverview", (data) => {
            //     let result = JSON.parse(data).TOURIST;
            // this.listAry[0].num = result.DAY; //今日游客量
            // this.listAry[1].num = result.HOUR; //瞬时游客量
            // this.listAry[2].num = result.WEEK; //本周游客数
            // this.listAry[3].num = result.YEAR; //年度游客数
            // this.listAry[4].num = result.TOTAL; //总游客数
            // });
        },
        // 获取 echart 数据
        async getEchartData() {
            let result = await this.GetTouristEcharts({
                type: this.selectData.state ? 1 : 0, //类型(0年、1月)
                year: this.selectData.state ? this.selectData.defaultValue : "",
            });
            this.echartList = result;
            this.initEchartLyyk();
        },
        initEchartLyyk() {
            let numColor = "rgba(188,254,161,1)";
            let circleColor = "rgba(168,200,255,1)";
            let centerCircleColr = "rgba(188,254,161,1)";
            let xAxisName = this.selectData.state ? "月" : "年";
            // let list = [
            //     {
            //         TIME: "1",
            //         CON: 402,
            //     },
            // ];
            let xAxisData = [];
            let seriesData = [];
            let maxList = [];
            this.echartList.map((item) => {
                xAxisData.push(item.TIME);
                seriesData.push(item.CON);
            });
            let max = Math.max(...seriesData) + 5;
            this.echartList.map(() => {
                maxList.push(max);
            });

            let option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        lineStyle: {
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "rgba(255, 255, 255,1)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                ],
                                global: false,
                            },
                        },
                    },
                    formatter: () => {},
                },
                grid: {
                    top: 50,
                    left: 60,
                    right: 30,
                    bottom: 30,
                },
                xAxis: {
                    name: xAxisName,
                    nameTextStyle: {
                        padding: [0, 0, -52, 0],
                        color: "rgba(168,200,255,1)",
                    },
                    data: xAxisData,
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: "rgba(16,85,120,1)",
                            width: "2",
                        },
                    },
                    axisTick: {
                        alignWithLabel: true,
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: "#ccc",
                        },
                    },
                    axisLabel: {
                        // rotate: 30,
                        margin: 20,
                        textStyle: {
                            fontWeight: "600",
                            color: "rgba(215,234,245,1)",
                            fontSize: "12px",
                        },
                    },
                },
                yAxis: {
                    name: "人",
                    nameTextStyle: {
                        color: "rgba(168,200,255,1)",
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: "rgba(86,122,147,0.08)",
                        },
                    },
                    axisTick: {
                        show: false,
                        lineStyle: {
                            color: "rgba(16,85,120,1)",
                        },
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {},
                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: "rgba(168,200,255,1)",
                            fontSize: "14px",
                        },
                    },
                },
                series: [
                    // {
                    //     type: "bar",
                    //     z: 1,
                    //     itemStyle: {
                    //         normal: {
                    //             color: {
                    //                 type: "linear",
                    //                 x: 0,
                    //                 y: 0,
                    //                 x2: 0,
                    //                 y2: 1,
                    //                 colorStops: [
                    //                     {
                    //                         offset: 0,
                    //                         color: "rgba(49, 80, 99, 0.5)",
                    //                     },
                    //                     {
                    //                         offset: 0.2,
                    //                         color: "rgba(49, 80, 99, 0.5)",
                    //                     },
                    //                     {
                    //                         offset: 1,
                    //                         color: "rgba(49, 80, 99, 0.1)",
                    //                     },
                    //                 ],
                    //                 globalCoord: false,
                    //             },
                    //         },
                    //     },
                    //     barWidth: 24,
                    //     barGap: 10,
                    //     data: maxList,
                    // },
                    {
                        data: seriesData,
                        type: "line",
                        symbol: "circle", //设定为实心点
                        symbolSize: 20, //圆点大小
                        showSymbol: true,
                        hoverAnimation: true,
                        animation: true,
                        label: {
                            show: true,
                            position: "top",
                            textStyle: {
                                color: numColor,
                                fontSize: 14,
                            },
                        },
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.RadialGradient(
                                    0.5,
                                    0.5,
                                    1.0,
                                    [
                                        {
                                            offset: 0,
                                            color: centerCircleColr,
                                        },
                                        {
                                            offset: 0.2,
                                            color: centerCircleColr,
                                        },
                                        {
                                            offset: 0.21,
                                            color: "rgba(0,0,0,0)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(0,0,0,1)",
                                        },
                                    ]
                                ), //圆圈颜色
                                borderColor: circleColor,
                                borderWidth: 2,
                                lineStyle: {
                                    //折线颜色大小
                                    type: "solid", //'dotted'虚线 'solid'实线
                                    color: numColor,
                                    width: 1,
                                    borderColor: "transparent", //拐点边框颜色
                                },
                            },
                        },
                    },
                ],
            };

            this.echart = this.$echarts.dispose(
                document.getElementById("lyykEchart")
            );
            this.echart = this.$echarts.init(
                document.getElementById("lyykEchart")
            );
            this.echart.setOption(option);
        },
    },
};
</script>
<style scoped lang="scss">
.screenItem {
    position: relative;
    width: 100%;
    height: 100%;
    .screenCtn {
        width: 100%;
        height: 100%;
        padding: 12px 0;
        .listPart {
            width: 100%;
            height: 108px;
            .listItem {
                width: 162px;
                height: 100%;
                .title {
                    width: 100%;
                    height: 40px;
                    background: url("~@image/bigScreen/icon/titleBg.png")
                        no-repeat;
                    background-size: 100% 100%;
                    font-size: 18px;
                    color: rgba(168, 200, 255, 1);
                }
                .num {
                    margin-top: 10px;
                    height: calc(100% - 50px);
                    border: 1px solid rgba(53, 80, 124, 0.4);
                    font-size: 48px;
                    color: rgba(189, 215, 236, 1);
                    font-family: DBPM;
                }
            }
        }
        .decoratePart {
            height: 3px;
            margin-top: 15px;
            position: relative;
            .decorate_1 {
                width: 76px;
                height: 100%;
                background: rgba(154, 180, 227, 1);
            }
            .decorate_2 {
                width: 28px;
                height: 100%;
                background: rgba(53, 80, 124, 0.5);
                border: 1px solid rgba(154, 180, 227, 1);
                margin: 0 4px;
            }
            .decorate_3 {
                width: 30px;
                height: 100%;
                background: rgba(154, 180, 227, 1);
            }

            .decorate_4 {
                height: 2px;
                background: rgba(53, 80, 124, 0.5);
            }
            .condition {
                position: absolute;
                right: 0;
                top: -10px;
            }
        }
        .echartPart {
            width: 100%;
            height: calc(100% - 140px);
            margin-top: 10px;
            position: relative;
            .bgPart {
                width: calc(100% - 50px);
                height: calc(100% - 27px);
                margin-left: 50px;
                margin-bottom: 27px;
                position: relative;
                .bg1 {
                    width: 304px;
                    height: 100%;
                    background: rgba(53, 80, 124, 0.15);
                }
                .bg2 {
                    position: absolute;
                    left: 154px;
                    top: 0;
                    width: 74px;
                    height: 100%;
                    background: rgba(53, 80, 124, 0.15);
                }
                .bg3 {
                    position: absolute;
                    left: 376px;
                    top: 0;
                    width: 74px;
                    height: 100%;
                    background: rgba(53, 80, 124, 0.15);
                }
                .bg4 {
                    position: absolute;
                    left: 450px;
                    top: 0;
                    width: 74px;
                    height: 100%;
                    background: rgba(53, 80, 124, 0.3);
                }
                .bg5 {
                    position: absolute;
                    right: 74px;
                    top: 0;
                    width: 74px;
                    height: 100%;
                    background: rgba(53, 80, 124, 0.15);
                }
                .bg6 {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 74px;
                    height: 100%;
                    background: rgba(53, 80, 124, 0.3);
                }
            }

            .echart {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
}
</style>
