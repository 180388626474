<template>
    <div class="imgContrastItem">
        <div class="lhlSjfxSelect common-element-select">
            <el-select v-model="selectValue1" style="width: 120px" popper-class="common-element-select-panel" @change="selectChange">
                <el-option v-for="(item, index) in selectList" :key="index" :label="item.label" :value="item.value"> </el-option>
            </el-select>
        </div>
        <!-- 基准照 -->
        <div class="contrastImg">
            <PicMap v-if="currentData && currentData[LJName]" :imgUrl="currentData[LJName]" @changeMap="changeMap"></PicMap>
            <NoMonitor v-else :text="!currentData || currentData.length === 0 ? '暂未监测' : selectValue === 0 ? '暂无基准照' : '暂无监测照'"></NoMonitor>
        </div>
        <div class="contrastCjr" v-if="currentData">
            <p v-if="selectValue === 0">采集时间：{{ currentData.CJSJ }}</p>
            <p v-if="selectValue === 1">采集人：{{ currentData.CJRMC }}</p>
            <p v-if="selectValue === 1">评估结果：{{ currentData.PGMC }}</p>
        </div>

        <div style="height: 50px" v-show="selectValue == 1">
            <TimeLine ref="timeLine" :listAry="monitorData" @handleTimeLine="handleTimeLine"></TimeLine>
        </div>
        <div class="pageNoBg timeLinePage" v-show="selectValue == 1 && pageTotal != 0">
            <el-pagination
                small
                class="pages"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="pageTotal"
                layout="prev, pager, next"
            >
            </el-pagination>
        </div>
    </div>
</template>
<script>
import TimeLine from "@views/big_screen/comp/TimeLine.vue"; //时间线
import { mapActions, mapGetters } from "vuex";
import PicMap from "./PicMap";
import NoMonitor from "@views/big_screen/comp/NoMonitor";
export default {
    name: "bhkzPicBox",
    props: {
        selectValue: Number,
        basicData: {
            type: Object,
            default() {
                return {}
            }
        },
        type: String
    },
    components: {
        TimeLine,
        PicMap,
        NoMonitor
    },
    computed: {
        ...mapGetters(["btbhJcwz"]),
        searchParams() {
            return [JSON.stringify(this.basicData), this.selectValue].join(',');
        }
    },
    data() {
        return {
            selectValue1: this.selectValue,
            selectList: [
                {
                    value: 0,
                    label: "基础数据",
                },
                {
                    value: 1,
                    label: "监测数据",
                },
            ],
            monitorData: [],
            pageIndex: 1,
            pageSize: 5,
            pageTotal: 0,
            currentData: null,
            LJName: "JZTLJ"
        };
    },
    watch: {
        searchParams: {
            immediate: true,
            handler() {
                if (this.basicData && this.selectValue === 1) {
                    this.getMonitorData();
                }
                this.LJName = this.selectValue === 1 ? 'TPLJ' : 'JZTLJ';
                this.currentData = this.selectValue === 0 ? this.basicData : this.currentData;  
            }
        },
    },
    methods: {
        ...mapActions([
            "getBhJcsjData"
        ]),
        changeMap(val) {
            this.$emit('changeMap', val, this.type);
        },
        selectChange(val) {
            this.$emit("update:selectValue", val);
        },
        async getMonitorData() {
            let res = await this.getBhJcsjData({
                jcwzid: this.basicData.JCWZID,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            });
            this.monitorData = res.RESULT;
            this.pageTotal = res.COUNT;
            this.currentData = this.monitorData[0];
        },
        handleCurrentChange(val) {
            this.pageIndex = val;
            this.getMonitorData();
        },
        // 点击圆圈
        handleTimeLine(item) {
            this.currentData = item;
        },
    },
};
</script>
<style lang="scss" scoped>
.imgContrastItem {
    width: 100%;
    height: 100%;
    position: relative;
    .lhlSjfxSelect {
        position: absolute;
        left: 10px;
        top: 10px;
        z-index: 100;
    }
    .contrastImg {
        width: 100%;
        height: calc(100% - 100px);
        position: relative;
        z-index: 10;
    }
    .contrastCjr {
        position: absolute;
        left: 14px;
        bottom: 130px;
        z-index: 10;
        p {
            color: #fff;
        }
    }
    .timeLinePage {
        text-align: right;
    }
}
</style>
