<template>
    <div class="leftMenu"
         :class="[jczlFyNum == 0 ? '' : 'leftEnglish']">
        <img src="@image/bigScreen/icon/sideBarBg.png"
             class="bg" />
        <div class="topDesc">
            <img src="@image/bigScreen/icon/title.png"
                 class="title animated bounceInLeft"
                 :style="{ animationDelay: 0.2 + 0 * 0.2 + 's' }" />
            <div class="desc animated bounceInLeft pointer"
                 @click="changeScale()">
                <div v-if="jczlFyNum == 0">遗产监测预警平台</div>
                <div v-else
                     class="en">
                    <div class="en-item">Heritage monitoring</div>
                    <div class="en-item">and early warning platform</div>
                </div>
            </div>

            <img src="@image/bigScreen/icon/logo.png"
                 class="logo animated bounceInLeft"
                 :style="{ animationDelay: 0.2 + 0 * 0.2 + 's' }" />
        </div>
        <div class="menuPart">
            <div v-for="(item, index) in menuList"
                 :key="`menu${index}`"
                 class="menuItem pointer animated bounceInLeft"
                 :style="{ animationDelay: 0.2 + index * 0.2 + 's' }"
                 :class="[
                    leftActiveNum === index || rightActiveNum === index
                        ? 'menuItemActive'
                        : '',
                ]"
                 @mouseenter="hoverActiveNum = index"
                 @mouseleave="hoverActiveNum = null"
                 @click="nameActiveItem(index)">
                <div class="pageBack"
                     :class="[`menuItem_${index}`]">
                    <img src="@image/bigScreen/icon/menuActive.png"
                         class="bgImg" />
                    <div class="name">
                        {{ common.getFyName(item) }}
                    </div>
                </div>
                <div class="pageFront"
                     :class="[`menuItem_${index}`]">
                    <img src="@image/bigScreen/icon/menuDefault.png"
                         class="bgImg" />
                    <div class="name">
                        {{ common.getFyName(item) }}
                    </div>
                </div>
                <!--  item.name != '监测总览' &&
                        item.name != '综合管理平台' && -->
                <div class="rectangle flex-y-center"
                     v-if="
                        (hoverActiveNum === index ||
                            leftActiveNum === index ||
                            rightActiveNum === index) &&
                        index != 0 &&
                        index != menuList.length - 1
                    ">
                    <div class="rectItem flex-both-center"
                         :class="[
                            leftActiveNum === index
                                ? 'rectItemActive'
                                : 'rectItemDefault',
                        ]"
                         @click.stop="getLeftActiveItem(index)">
                        L
                    </div>
                    <div class="right rectItem flex-both-center"
                         :class="[
                            rightActiveNum === index
                                ? 'rectItemActive'
                                : 'rectItemDefault',
                        ]"
                         @click.stop="getRightActiveItem(index)">
                        R
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    name: "",
    components: {},
    data () {
        return {
            init_num: 0,
            num: 0,
            leftActiveNum: null,
            rightActiveNum: null,
            hoverActiveNum: null,
            menuList: [
                {
                    name: "监测总览",
                },
                {
                    name: "日常巡查",
                },
                {
                    name: "生态环境",
                },
                {
                    name: "建设控制",
                },
                {
                    name: "本体病害",
                },
                {
                    name: "保护工程",
                },
                // {
                //     name: "茶文化",
                // },
                {
                    name: "综合管理平台",
                },
            ],
        };
    },
    watch: {
        isScale: {
            immediate: false,
            handler () {
                if (this.isScale) {
                    let scaleXValue = 1920 / 5760;
                    $(".screenMain").css("transform", `scaleX(${scaleXValue})`);
                } else {
                    $(".screenMain").css("transform", `scaleX(1)`);
                }
            },
        },
    },
    computed: {
        ...mapGetters({
            isScale: TYPES.isScale,
            jczlFyNum: TYPES.jczlFyNum,
        }),
    },
    mounted () {
        this.menuList = this.fany.menuList;
        // let scaleValue = this.$route.query.scale;
        // if (!scaleValue || scaleValue == 1) {
        //     this.SET_ISSCALE(true);
        // } else {
        //     this.SET_ISSCALE(false);
        // }
        // if (this.isScale) {
        //     let scaleXValue = 1920 / 5760;
        //     $(".screenCtn").css("transform", `scaleX(${scaleXValue})`);
        // } else {
        //     $(".screenCtn").css("transform", `scaleX(1)`);
        // }

        if (!this.leftActiveNum) {
            // 初始化默认展示 哪一个大屏
            this.leftActiveNum = 0;
            this.rightActiveNum = 0;
            this.$emit("allActiveItem", this.leftActiveNum);
        }
    },
    methods: {
        ...mapMutations({
            SET_ISSCALE: TYPES.SET_ISSCALE,
        }),
        getLeftActiveItem (index) {
            // 第一次点击
            if (this.init_num == 0) {
                this.leftActiveNum = index;
                if (index + 1 == 6) {
                    this.rightActiveNum = 1;
                } else {
                    this.rightActiveNum = index + 1;
                }
                this.$emit("leftActiveItem", this.leftActiveNum);
                this.$emit("rightActiveItem", this.rightActiveNum);
                this.init_num++;
                this.num++;
                return;
            }
            this.leftActiveNum = index;
            this.$emit("leftActiveItem", index);
        },
        getRightActiveItem (index) {
            // 第一次点击
            if (this.init_num == 0) {
                this.leftActiveNum = index;
                if (index + 1 == 6) {
                    this.rightActiveNum = 1;
                } else {
                    this.rightActiveNum = index + 1;
                }
                this.$emit("leftActiveItem", this.leftActiveNum);
                this.$emit("rightActiveItem", this.rightActiveNum);
                this.init_num++;
                this.num++;
                return;
            }
            this.rightActiveNum = index;
            this.$emit("rightActiveItem", index);
        },
        nameActiveItem (index) {
            // 点击的监测总览 初始化
            if (index == 6) {
                window.open(window.MANAGEPLATFORM_URL);
                return;
            }
            if (index == 0) {
                this.$emit("leftActiveItem", 0);
                this.$emit("rightActiveItem", 0);
                this.leftActiveNum = 0;
                this.rightActiveNum = 0;
                this.init_num = 0;
                this.num = 0;
                return;
            }
            // 第一次点击
            if (this.init_num == 0) {
                this.leftActiveNum = index;
                if (index + 1 == 6) {
                    this.rightActiveNum = 1;
                } else {
                    this.rightActiveNum = index + 1;
                }
                this.$emit("leftActiveItem", this.leftActiveNum);
                this.$emit("rightActiveItem", this.rightActiveNum);
                this.init_num++;
                this.num++;
                return;
            }
            // 重复点击 不触发
            if (index == this.leftActiveNum || index == this.rightActiveNum) {
                return;
            } else if (this.num % 2 != 0) {
                // 替换左边
                this.leftActiveNum = index;
                this.$emit("leftActiveItem", index);
            } else {
                // 替换右边
                this.rightActiveNum = index;
                this.$emit("rightActiveItem", index);
            }
            this.num++;
            // this.$emit("allActiveItem", index);
        },
        // 改变缩放
        changeScale () {
            this.SET_ISSCALE(!this.isScale);
        },
    },
};
</script>

<style lang="scss" scoped>
.leftMenu {
    width: 657px;
    position: relative;
    z-index: 10;
    height: 100%;
    overflow: hidden;
    .bg {
        position: absolute;
        left: 6px;
        top: 107px;
    }
    .topDesc {
        position: relative;
        .title {
            position: absolute;
            left: 0;
            top: 10px;
        }
        .desc {
            height: 42px;
            font-family: '思源黑体';
            font-size: 36px;
            color: #a2c2f8;
            position: relative;
            left: 10px;
            top: -8px;
            letter-spacing: 2px;
        }
        .logo {
            width: 426px;
            height: 68px;
            margin-top: 40px;
        }
    }
    .menuPart {
        width: 100%;
        height: calc(100% - 132px);
        padding-top: 33px;
        // animation: circleRoate 5s linear 3s infinite;
        position: relative;
        z-index: 10;

        .menuItem {
            // height: 50px;
            height: 60px;
            margin-bottom: 30px;
            // line-height: 50px;
            line-height: 60px;
            position: relative;
            text-align: center;
            position: relative;
            perspective: 1000px;
            &:hover {
                .pageBack {
                    transform: rotateX(0deg);
                    z-index: 2;
                }
                .pageFront {
                    transform: rotateX(180deg);
                    z-index: 1;
                }
            }
            .pageBack {
                backface-visibility: hidden;
                transition: 0.6s ease-out;
                transform-style: preserve-3d;
                position: absolute;
                z-index: 1;
                transform: rotateX(-180deg);
                width: 340px;
                height: 60px;
                // background: url("~@image/bigScreen/icon/menuActive.png")
                //     no-repeat !important;
                // background-size: 100% 100%;
                .name {
                    background-image: linear-gradient(
                        90deg,
                        rgba(189, 215, 236, 1),
                        rgba(255, 205, 128, 1)
                    );
                    -webkit-background-clip: text;
                    color: transparent;
                }
            }
            .pageFront {
                backface-visibility: hidden;
                transition: 0.6s ease-out;
                transform-style: preserve-3d;
                position: absolute;
                z-index: 2;
                transform: rotateX(0deg);

                width: 329px;
                height: 60px;
                // background: url("~@image/bigScreen/icon/menuDefault.png")
                //     no-repeat;
                // background-size: 100% 100%;
                .name {
                    color: rgba(189, 215, 236, 1);
                }
            }
            .bgImg {
                position: absolute;
                height: 100%;
                width: 340px;
                position: absolute;
                right: 0;
                top: 0;
            }
            &:nth-last-child(1) {
                margin-bottom: 0;
            }
            .name {
                font-size: 30px;
            }
            .rectangle {
                width: 100px;
                position: absolute;
                left: 30px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 10;
                .rectItem {
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    font-size: 14px;
                    &:nth-child(1) {
                        margin-right: 10px;
                    }
                }
                .rectItemActive {
                    background: rgba(255, 205, 128, 1);
                    color: rgba(4, 14, 30, 1);
                }
                .rectItemDefault {
                    border: 1px solid rgba(255, 205, 128, 1);
                    color: rgba(255, 205, 128, 1);
                }
            }
        }
        .menuItemActive {
            .pageBack {
                transform: rotateX(0deg);
                z-index: 2;
            }
            .pageFront {
                transform: rotateX(180deg);
                z-index: 1;
            }
        }

        .menuItem_0 {
            left: 44px;
        }
        .menuItem_1 {
            left: 68px;
        }
        .menuItem_2 {
            left: 76px;
        }
        .menuItem_3 {
            // left: 77px;
            left: 73px;
        }
        .menuItem_4 {
            // left: 66px;
            left: 58px;
        }
        .menuItem_5 {
            // left: 48px;
            left: 29px;
        }
        .menuItem_6 {
            // left: 16px;
            left: -17px;
        }
        // .menuItem_7 {
        //     left: -28px;
        // }
    }
}
.leftEnglish {
    .bg {
        left: 180px;
    }
    .topDesc {
        position: relative;
        .title {
            top: 70px;
        }
        .logo {
            margin-top: 80px;
        }
        .en-item {
            height: 46px;
        }
    }
    .menuPart {
        .menuItem {
            text-align: right;
            width: 630px;
            .pageBack {
                width: 476px;
                text-align: right;
                padding-right: 100px;
            }
            .pageFront {
                width: 476px;
                text-align: right;
                padding-right: 100px;
            }
            .name {
                width: 386px;
                font-size: 26px;
                letter-spacing: -1px;
                white-space: nowrap;
            }
            &:nth-last-child(1) {
                .pageBack {
                    padding-right: 0;
                }
                .pageFront {
                    padding-right: 0;
                }
            }
        }
        .menuItem_0 {
            // left: 94px;
            left: 100px;
        }
        .menuItem_1 {
            // left: 106px;
            left: 110px;
        }
        .menuItem_2 {
            // left: 110px;
            left: 114px;
        }
        .menuItem_3 {
            left: 104px;
        }
        .menuItem_4 {
            left: 88px;
        }
        .menuItem_5 {
            // left: 66px;
            left: 54px;
        }
        .menuItem_6 {
            // left: 26px;
            left: 0;
        }
        // .menuItem_7 {
        //     left: -30px;
        // }
    }
}

@keyframes circleRoate {
    0% {
        transform: rotate(0deg);
        transform-origin: 0 50%;
    }

    25% {
        transform: rotate(-5deg);
        transform-origin: 0 50%;
    }

    50% {
        transform: rotate(0deg);
        transform-origin: 0 50%;
    }

    75% {
        transform: rotate(5deg);
        transform-origin: 0 50%;
    }

    100% {
        transform: rotate(0deg);
        transform-origin: 0 50%;
    }
}
</style>
