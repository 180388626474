<template>
    <div class="screenItem">
        <!-- 社会环境 -->
        <BasicMonitor :text="common.getFyName(fany.shhj)">
            <div
                slot="basic-module"
                class="screenCtn scrollbar-translucent"
                v-if="dataState"
            >
                <div class="condition flex-end">
                    <div class="label">开始年份</div>
                    <div>
                        <SelectTimeTab
                            :selectData="selectDataStart"
                            @getSelectItem="getSelectItemStart"
                        >
                        </SelectTimeTab>
                    </div>

                    <div class="label">结束年份</div>
                    <div>
                        <SelectTimeTab
                            :selectData="selectDataEnd"
                            @getSelectItem="getSelectItemEnd"
                        >
                        </SelectTimeTab>
                    </div>
                </div>
                <div class="desc" v-if="timeState">
                    <span>{{ time }}</span>
                    <span> 茶产总共</span>
                    <span :title="moneyTotalTip + 'Kg'">
                        {{ totalValue }}吨</span
                    >
                    <!-- <span>经济总收入共：</span>
                    <span :title="moneyTotalTip + '元'"
                        >{{ moneyTotal }}亿</span
                    > -->
                </div>
                <div class="echartPart" v-if="timeState">
                    <div class="decorate flex-between">
                        <div class="bg1"></div>
                        <div class="bg2"></div>
                        <div class="bg3"></div>
                        <div class="bg4"></div>
                        <div class="bg5"></div>
                        <div class="bg6"></div>
                    </div>
                    <div class="echart" id="echartShhj"></div>
                </div>
            </div>
            <div
                slot="basic-module"
                class="screenCtn scrollbar-translucent"
                v-else
            >
                <NoMonitor text="暂无数据"></NoMonitor>
            </div>
        </BasicMonitor>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import BasicMonitor from "@views/big_screen/monitor_all/comp/BasicModule";
import SelectTimeTab from "@views/big_screen/comp/SelectTimeTab";
import NoMonitor from "@views/big_screen/comp/NoMonitor";
export default {
    components: {
        BasicMonitor,
        SelectTimeTab,
        NoMonitor,
    },
    name: "",
    data() {
        return {
            time: "",
            totalValue: "",
            totalValueTip: "",
            moneyTotal: "",
            moneyTotalTip: "",
            startYear: "",
            selectDataStart: {
                clearable: false,
                state: true,
                width: "100px",
                defaultValue: "",
                list: [],
                label: "name",
                value: "name",
            },
            endYear: "",
            selectDataEnd: {
                clearable: false,
                state: true,
                width: "100px",
                defaultValue: "",
                list: [],
                label: "name",
                value: "name",
            },
            dataState: true,
            timeState: true,
            listAry: [],
            echart: null,
        };
    },
    computed: {
        ...mapGetters({
            jczlYcysItem: TYPES.jczlYcysItem,
            jczlYczcItem: TYPES.jczlYczcItem,
        }),
    },
    props: {},
    watch: {
        // jczlYcysItem: {
        //     immediate: false,
        //     handler() {
        //         if (this.jczlYcysItem || this.jczlYczcItem) {
        //             this.dataState = false;
        //         } else {
        //             this.dataState = true;
        //             this.reSetTime();
        //             this.getMainData();
        //         }
        //     },
        // },
        // // 某一个遗产组成
        // jczlYczcItem: {
        //     immediate: false,
        //     handler() {
        //         if (this.jczlYczcItem || this.jczlYcysItem) {
        //             this.dataState = false;
        //         } else {
        //             this.dataState = true;
        //             this.reSetTime();
        //             this.getMainData();
        //         }
        //     },
        // },
    },
    mounted() {
        this.getTimeList();
    },
    methods: {
        ...mapActions(["GetMonitoringRecordYears", "GetMonitoringRecords"]),
        ...mapMutations({}),

        // 年份列表
        async getTimeList() {
            let result = await this.GetMonitoringRecordYears({});
            let list = [];
            result.map((item) => {
                let obj = {
                    name: item,
                };
                list.push(obj);
            });
            this.selectDataStart.list = list;
            this.selectDataEnd.list = list;
            this.startYear = "2014";
            this.selectDataStart.defaultValue = "2014";
            this.endYear = list[0].name;
            this.selectDataEnd.defaultValue = list[0].name;
            this.getMainData();
        },
        // 开始年份切换
        getSelectItemStart(item) {
            if (item.name > this.endYear) {
                this.common.showMsg("起始年份不能大于结束年份", "warning");
                this.timeState = false;
                return;
            } else {
                this.timeState = true;
            }
            this.startYear = item.name;
            this.getMainData();
        },
        // 结束年份切换
        getSelectItemEnd(item) {
            if (item.name < this.startYear) {
                this.common.showMsg("结束年份不能小于开始年份", "warning");
                this.timeState = false;
                return;
            } else {
                this.timeState = true;
            }
            this.endYear = item.name;
            this.getMainData();
        },
        async getMainData() {
            let result = await this.GetMonitoringRecords({
                startYear: this.startYear,
                endYear: this.endYear,
            });
            this.time = `${result.TOTAL.STARTYEAR}-${result.TOTAL.ENDYEAR}`;
            this.totalValue = (result.TOTAL.CCZG / 1000).toFixed(2);
            this.totalValueTip = result.TOTAL.CCZG;
            this.moneyTotal = (result.TOTAL.JJZSR / 100000000).toFixed(2);
            this.moneyTotalTip = result.TOTAL.JJZSR;
            result.DATA[1].ZRK = 0;
            result.DATA.map((item) => {
                item.JJZCR = item.JJZCR / 10000;
                item.CYZCL = item.CYZCL / 1000;
            });
            this.listAry = result.DATA;
            this.initEchart();
        },
        initEchart() {
            let listAry = [
                {
                    name: "人口",
                    data: [],
                    color: "rgba(255,204,102,1)",
                    colorP: "rgba(255,204,102,0.2)",
                    colorP0: "rgba(255,204,102,0)",
                },
                {
                    name: "茶叶总量",
                    data: [],
                    color: "rgba(102,204,102,1)",
                    colorP: "rgba(102,204,102,0.2)",
                    colorP0: "rgba(102,204,102,0)",
                },
                {
                    name: "经济收入",
                    data: [],
                    color: "rgba(153,204,255,1)",
                    colorP: "rgba(153,204,255,0.2)",
                    colorP0: "rgba(153,204,255,0)",
                },
                {
                    name: "人均收入",
                    data: [],
                    color: "rgba(204,51,51,1)",
                    colorP: "rgba(204,51,51,0.2)",
                    colorP0: "rgba(204,51,51,0)",
                },
            ];
            let xAxisData = [];
            let colorList = [];
            this.listAry.map((item) => {
                if (!item.ZRK && !item.CYZCL && !item.JJZCR && !item.RJZSR) {
                    return;
                }
                xAxisData.push(item.ND);
                listAry[0].data.push(item.ZRK ? item.ZRK : null);
                listAry[1].data.push(item.CYZCL ? item.CYZCL : null);
                listAry[2].data.push(item.JJZCR ? item.JJZCR : null);
                listAry[3].data.push(item.RJZSR ? item.RJZSR : null);
                colorList.push(item.color);
            });

            let seriesData = [];
            listAry.map((item, index) => {
                let obj = {
                    connectNulls: true,
                    yAxisIndex: index,
                    name: item.name,
                    type: "line",
                    showAllSymbol: true,
                    symbol: "circle",
                    symbolSize: 5,
                    data: item.data,
                    lineStyle: {
                        color: item.color,
                    },
                    itemStyle: {
                        color: item.color,
                        borderColor: item.color,
                        borderWidth: 2,
                        shadowColor: item.colorP,
                        shadowBlur: 0,
                        shadowOffsetY: 2,
                        shadowOffsetX: 2,
                    },
                    areaStyle: {
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    {
                                        offset: 0,
                                        color: item.colorP,
                                    },
                                    {
                                        offset: 1,
                                        color: item.colorP0,
                                    },
                                ],
                                false
                            ),
                            shadowColor: "rgba(105,196,254, 0.9)",
                            shadowBlur: 20,
                        },
                    },
                };
                seriesData.push(obj);
            });
            let option = {
                color: colorList,
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        lineStyle: {
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "rgba(255, 255, 255,1)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                ],
                                global: false,
                            },
                        },
                    },
                    formatter(para) {
                        let str = `${para[0].axisValue}年<br>`;
                        para.map((item) => {
                            let dw = "";
                            switch (item.seriesName) {
                                case "人口":
                                    dw = "人";
                                    break;
                                case "经济收入":
                                    dw = "万元";
                                    break;
                                case "人均收入":
                                    dw = "元";
                                    break;
                                case "茶叶总量":
                                    dw = "吨";
                                    break;
                            }
                            str += `${item.marker} ${item.seriesName} : ${
                                item.value ? item.value : "-"
                            } ${dw} <br>`;
                        });
                        return str;
                    },
                },
                legend: {
                    top: "10px",
                    textStyle: {
                        color: "#fff",
                    },
                    icon: "roundRect", //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
                    itemWidth: 10, // 设置宽度
                    itemHeight: 10, // 设置高度
                },
                // grid: {
                //     left: -50,
                //     right: 0,
                //     top: 50,
                //     bottom: 50,
                //     containLabel: true,
                // },
                grid: {
                    left: 50,
                    right: 50,
                    top: 80,
                    bottom: 10,
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        data: xAxisData,
                        axisLine: {
                            lineStyle: {
                                color: "rgba(255,255,255,0.12)",
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(255,255,255,0.1)",
                            },
                        },
                        axisLabel: {
                            color: "#fff",
                            fontSize: 14,
                        },
                    },
                ],
                yAxis: [
                    {
                        minInterval: 1,
                        position: "left",
                        type: "value",
                        name: "人口",
                        nameTextStyle: {
                            color: "rgba(255,255,255,0.5)",
                        },
                        axisLabel: {
                            margin: -20,
                            fontSize: 14,
                            textStyle: {
                                color: "#e2e9ff",
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(255,255,255,0.1)",
                            },
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(255,255,255,0.12)",
                                width: 2,
                            },
                        },
                    },
                    {
                        type: "value",
                        name: "吨",
                        position: "left",
                        offset: 50,
                        nameTextStyle: {
                            color: "rgba(255,255,255,0.5)",
                        },
                        axisLabel: {
                            margin: -10,
                            fontSize: 14,
                            textStyle: {
                                color: "#e2e9ff",
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(255,255,255,0.1)",
                            },
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(255,255,255,0.12)",
                                width: 2,
                            },
                        },
                    },
                    {
                        type: "value",
                        name: "万元",
                        position: "right",

                        nameTextStyle: {
                            color: "rgba(255,255,255,0.5)",
                        },
                        axisLabel: {
                            margin: -10,
                            fontSize: 14,
                            textStyle: {
                                color: "#e2e9ff",
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(255,255,255,0.1)",
                            },
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(255,255,255,0.12)",
                                width: 2,
                            },
                        },
                    },
                    {
                        type: "value",
                        name: "元",
                        position: "right",
                        offset: 80,
                        nameTextStyle: {
                            color: "rgba(255,255,255,0.5)",
                        },
                        axisLabel: {
                            margin: -20,
                            fontSize: 14,
                            textStyle: {
                                color: "#e2e9ff",
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(255,255,255,0.1)",
                            },
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(255,255,255,0.12)",
                                width: 2,
                            },
                        },
                    },
                ],
                series: seriesData,
            };
            this.echart = this.$echarts.dispose(
                document.getElementById("echartShhj")
            );
            this.echart = this.$echarts.init(
                document.getElementById("echartShhj")
            );
            this.echart.setOption(option);
        },
    },
};
</script>
<style scoped lang="scss">
.screenItem {
    position: relative;
    width: 100%;
    height: 100%;
    .screenCtn {
        width: 100%;
        height: 100%;
        padding-top: 15px;
        .condition {
            position: absolute;
            right: 0;
            top: -30px;
            .label {
                width: 60px;
                color: #a8c8ff;
            }
        }
        .desc {
            font-size: 18px;
            color: rgba(168, 200, 255, 1);
            height: 20px;
        }
        .echartPart {
            width: 100%;
            height: calc(100% - 20px);
            position: relative;
            .decorate {
                position: absolute;
                left: 0;
                top: 40px;
                width: 100%;
                height: calc(100% - 40px);
                padding: 0 10px 0 20px;
                .bg1 {
                    width: 230px;
                    height: calc(100% - 30px);
                    background: rgba(53, 80, 124, 0.15);
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                .bg2 {
                    width: 55px;
                    height: calc(100% - 30px);
                    background: rgba(53, 80, 124, 0.15);
                    position: absolute;
                    left: 117px;
                    top: 0;
                }
                .bg3 {
                    width: 55px;
                    height: calc(100% - 30px);
                    background: rgba(53, 80, 124, 0.15);
                    position: absolute;
                    left: 286px;
                    top: 0;
                }
                .bg4 {
                    width: 55px;
                    height: calc(100% - 30px);
                    background: rgba(53, 80, 124, 0.3);
                    position: absolute;
                    left: 340px;
                    top: 0;
                }
                .bg5 {
                    width: 55px;
                    height: calc(100% - 30px);
                    background: rgba(53, 80, 124, 0.15);
                    position: absolute;
                    right: 55px;
                    top: 0;
                }
                .bg6 {
                    width: 55px;
                    height: calc(100% - 30px);
                    background: rgba(53, 80, 124, 0.3);
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
            .echart {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0px;
            }
        }
    }
}
</style>
