<template>
    <div class="patrol-calendar">
        <div class="calendar-header">
            <div class="title">
                {{ patrolData.XCDWMC }}
            </div>
            <span class="close-btn" @click="$emit('closeCalendar')"></span>
        </div>
        <div class="calendar-sum">
            <div class="sum-part">
                <p class="label">巡查次数</p>
                <p>
                    <span class="sum-number">{{
                        patrolData.XCCS | filterXccs
                    }}</span>
                    <span class="dw">次</span>
                </p>
            </div>
            <div class="sum-part">
                <p class="label">异常次数</p>
                <p>
                    <span class="sum-number">{{ patrolData.YCCS }}</span>
                    <span class="dw">次</span>
                </p>
            </div>
        </div>

        <div class="calendar-search flex-between-y-center">
            <div class="userPart flex">
                <div class="userName">{{ patrolData.REALNAME }}</div>
                <div
                    class="departMentPart ellipsis pointer"
                    :title="patrolData.DEPARTMENTNAME"
                >
                    {{ patrolData.DEPARTMENTNAME }}
                </div>
            </div>
            <div class="timePart common-element-date flex-y-center">
                <span class="xcyf">巡查月份:</span>
                <el-date-picker
                    v-model="selectMonth"
                    type="month"
                    value-format="yyyy-MM"
                    format="yyyy-MM"
                    class="search-date"
                    popper-class="common-element-date-panel"
                    @change="timeChange"
                    style="width: 70px"
                ></el-date-picker>
            </div>
        </div>
        <div class="calendar-centent">
            <overview-calendar :dateValue="selectMonth">
                <template v-slot:content="{ cellData }">
                    <!-- 正常 -->
                    <img
                        v-if="patrolData.ZC.includes(cellData.data.day)"
                        :src="legendList[0].src"
                        class="icon"
                    />

                    <div
                        v-if="patrolDataYcMap[cellData.data.day]"
                        @click="enterErrorDetail(cellData.data.day)"
                    >
                        {{ patrolDataYcMap[cellData.data.day]["YCSFJC"] }}
                        <!-- 异常 处置中 -->
                        <img
                            v-if="
                                patrolDataYcMap[cellData.data.day]['YCSFJC'] ==
                                0
                            "
                            :src="legendList[1].src"
                            class="icon"
                        />
                        <!-- 异常 已解除 -->
                        <img v-else :src="legendList[2].src" class="icon" />
                    </div>
                </template>
            </overview-calendar>
            <div class="flex-end legendPart">
                <div
                    class="legendItem"
                    v-for="(item, index) in legendList"
                    :key="index"
                >
                    <img :src="item.src" class="icon" />
                    <span class="name">{{ item.name }}</span>
                </div>
            </div>
        </div>

        <div class="commonTab" style="margin-top: 10px">
            <el-tabs v-model="activeNum" @tab-click="ycdwChange">
                <el-tab-pane
                    v-for="(item, index) in currentYcxxList"
                    :key="index"
                    :label="item.YCSJ"
                    :name="item.ID"
                    class="tabItem"
                ></el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import OverviewCalendar from "../../comp/OverviewCalendar";
import * as TYPES from "@store/modules/mutation-type.js";
import { mapMutations } from "vuex";
export default {
    components: {
        OverviewCalendar,
    },
    filters: {
        filterXccs: function (value) {
            return value.split("/")[0];
        },
    },
    props: {
        patrolData: {
            type: Object,
            default: () => {
                return {
                    XCDWMC: "暂无点位名称",
                    XCCS: "0/", // 巡查次数
                    YCCS: "0", // 异常次数
                    YC: [], // 异常列表
                    ZC: [], // 正常列表
                    // YJ: [], // 预警
                    REALNAME: "", // 姓名
                    DEPARTMENTNAME: "", // 部门
                };
            },
        },
    },
    data() {
        return {
            selectMonth: this.common.getTimeYm(),
            currentYcxxList: [],
            activeNum: null,
            // YCSFJC": "1" 0 处置中 1 已解除 2 误报
            legendList: [
                {
                    name: "正常",
                    src: require("@image/bigScreen/daily_inspect/legend/normal.png"),
                },
                {
                    name: "处置中异常",
                    src: require("@image/bigScreen/daily_inspect/legend/errorNoSolve.png"),
                },
                {
                    name: "已解除异常",
                    src: require("@image/bigScreen/daily_inspect/legend/errorSolved.png"),
                },
            ],
        };
    },
    computed: {
        patrolDataYcMap: function () {
            const res = {};
            (this.patrolData.YC || []).forEach(({ DATE, YCXX }) => {
                res[DATE] = YCXX[0];
            });
            return res;
        },
    },
    watch: {
        patrolDataYcMap: {
            immediate: true,
            handler() {},
        },
    },
    methods: {
        ...mapMutations({
            SET_RCXCYCDWID: TYPES.SET_RCXCYCDWID,
            SET_RCXCPANELNUM: TYPES.SET_RCXCPANELNUM,
        }),
        // 月份更改
        timeChange() {
            this.$emit("selectDateChange", this.selectMonth);
        },
        // 更换异常点位
        ycdwChange(item) {
            this.activeNum = item.name;
            this.SET_RCXCYCDWID(item.name);
            this.SET_RCXCPANELNUM(2);
        },
        // 查看异常详情
        enterErrorDetail(date) {
            this.currentYcxxList = [];
            this.patrolData.YC.map((item, index) => {
                if (item.DATE === date) {
                    this.SET_RCXCPANELNUM(2);
                    this.SET_RCXCYCDWID(item.YCXX[0].ID);
                    if (item.YCXX.length > 1) {
                        this.activeNum = item.YCXX[0].ID;
                        this.currentYcxxList = item.YCXX;
                    }
                }
            });
        },
    },
};
</script>
<style lang="scss">
div.common-element-date {
    div.el-date-editor.search-date.el-input.el-input--prefix.el-input--suffix.el-date-editor--month {
        > span {
            display: none;
        }
        input.el-input__inner {
            background-color: transparent;
            width: 120px;
            height: auto;
            padding: 0 5px;
            outline: none;
            border: none !important;
            color: #d7eaf5;
            font-weight: 700;
            font-size: 16px;
            line-height: 44px;
        }
    }
}
</style>
<style scoped lang="scss">
$main: rgba(18, 68, 96, 1);
$mainFontColor: #5d8da8;
.patrol-calendar {
    width: 530px;
    padding: 0px 20px 20px;
    z-index: 9;
    background: rgba($color: #08202e, $alpha: 0.9);
    box-shadow: 0 0 15px 10px $mainFontColor;
    span {
        color: rgba(119, 177, 210, 1);
    }
    .calendar-header {
        font-weight: 400;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .title {
            flex: 1;
            font-size: 20px;
            color: $mainFontColor;
            text-align: left;
        }
        .close-btn {
            display: inline-block;
            width: 30px;
            height: 30px;
            background: url("~@image/bigScreen/close.png") no-repeat center
                center;
        }
    }
    .calendar-sum {
        border: solid 2px #09354d;
        display: flex;
        align-items: center;
        height: 70px;
        font-weight: 18px;
        .sum-part {
            text-align: center;
            width: 50%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 45px;
            border-left: 3px solid #105578;
            background: linear-gradient(
                to right,
                rgba(11, 41, 64, 1),
                rgba(11, 41, 64, 0)
            );
            .label {
                font-size: 18px;
                color: rgba(119, 177, 210, 1);
            }
            .sum-number {
                font-size: 32px;
                color: rgba(215, 234, 245, 1);
            }
            .dw {
                font-size: 18px;
                color: rgba(119, 177, 210, 1);
                margin-left: 4px;
            }
        }
    }
    .calendar-search {
        height: 40px;
        padding: 0px 20px;
        border-right: solid 2px #09354d;
        border-left: solid 2px #09354d;
        .userPart {
            .userName {
                font-size: 18px;
                color: #77b1d2;
                margin-right: 10px;
            }
            .departMentPart {
                width: 235px;
                font-size: 18px;
                color: #77b1d2;
            }
        }
        .timePart {
            height: 24px;
            > span.xcyf {
                font-size: 16px;
                font-weight: 700;
            }
            .search-date {
                width: auto;
            }
        }
    }
    .calendar-centent {
        height: 360px;
        border: solid 2px #09354d;
        .icon {
            width: 14px;
            height: 14px;
        }
        .legendPart {
            position: absolute;
            right: 30px;
            bottom: 60px;
            .legendItem {
                margin-right: 10px;
                .name {
                    color: #fff;
                    font-size: 14px;
                    margin-right: 4px;
                }
                .icon {
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                }
            }
        }
    }
}
</style>
