<template>
    <div class="rightPart scrollbar-hidden">
        <BoxTitle v-if="rcxcPanelNum === 1"
                  :title="rcxcAreaItem.QYMC"
                  @handleClose="handleClose"></BoxTitle>
        <div class="xctdPart">
            <!-- 巡查团队 -->
            <basic-module :text="common.getFyName(fany.xctd)">
                <div slot="basic-module"
                     class="jgtjCtn">
                    <div class="flex-between">
                        <div v-for="(item, index) in jgtjList"
                             :key="index"
                             class="listItem animated bounceIn"
                             :style="{ animationDelay: 0.2 + index * 0.2 + 's' }">
                            <div class="title flex-both-center">
                                {{ item.name }}
                            </div>
                            <div class="numPart flex-both-center">
                                <span class="num">{{ item.num }}</span>
                                <span class="dw">{{ item.dw }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </basic-module>
        </div>

        <div class="xctjPart">
            <!-- 巡查统计 -->
            <basic-module :text="common.getFyName(fany.xctj)">
                <div slot="basic-module"
                     class="xctjCtn">
                    <div class="condition flex-end">
                        <div class="line"></div>
                        <SelectTimeTab :selectData="selectData"
                                       :tabData="tabData"
                                       @getTabItem="getTabItem"
                                       @getSelectItem="getSelectItem">
                        </SelectTimeTab>
                    </div>
                    <div class="echart"
                         :id="'echartXctj' + echartId"></div>

                    <!-- <div class="sntq pointer" v-if="selectData.state">
                        <div
                            class="flex-y-center"
                            :class="[sntqState ? 'sntqActive' : 'sntqDefault']"
                            @click="changeSntqState()"
                        >
                            <div class="circle"></div>
                            <div class="name">上年同期</div>
                        </div>
                    </div> -->
                </div>
            </basic-module>
        </div>

        <div class="yctjPart">
            <!-- 异常统计 -->
            <basic-module :text="common.getFyName(fany.yctj)">
                <div slot="basic-module"
                     class="yctjCtn">
                    <div class="tableList flex-between">
                        <div v-for="(item, index) in yjtjPart.yctjList"
                             :key="index"
                             class="tableItem flex">
                            <div class="name flex-both-center pointer"
                                 @click="getMarkerYctj(index)">
                                {{ item.name }}
                            </div>
                            <div class="numPart flex-both-center">
                                <div class="num"
                                     :style="{
                                        color: item.color,
                                    }">
                                    {{ item.num }}
                                </div>
                                <div class="dw">个</div>
                            </div>
                        </div>
                    </div>
                    <div class="echartPart flex-between">
                        <div class="echartItem">
                            <EchartCircle :colorList="yjtjPart.leftPart.colorList"
                                          :listAry="yjtjPart.leftPart.listAry"></EchartCircle>
                        </div>
                        <div class="echartItem">
                            <EchartCircle :colorList="yjtjPart.rightPart.colorList"
                                          :listAry="yjtjPart.rightPart.listAry"></EchartCircle>
                        </div>
                    </div>
                    <div class="yclbCtn">
                        <TableList :maxHeight="179"
                                   :tableColums="tableColums"
                                   :tableData="tableData"
                                   :state="state"
                                   @loadData="loadData"
                                   @tableItemDetail="tableItemDetail"></TableList>
                    </div>
                </div>
            </basic-module>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import SelectTimeTab from "@views/big_screen/comp/SelectTimeTab";
import TableList from "@views/big_screen/comp/TableList";
import BoxTitle from "@views/big_screen/comp/BoxTitle.vue"; //标题
import NoMonitor from "@views/big_screen/comp/NoMonitor.vue"; //标题
import EchartCircle from "./comp/EchartCircle.vue"; //圆形echart
export default {
    components: {
        SelectTimeTab,
        TableList,
        BoxTitle,
        NoMonitor,
        EchartCircle,
    },
    name: "",
    data () {
        return {
            echartId: Math.random(),
            jgtjList: [
                {
                    name: "巡查人数",
                    num: 0,
                    dw: "人",
                },
                {
                    name: "林业局巡查数据",
                    num: 0,
                    dw: "条",
                },
                {
                    name: "管理局巡查数据",
                    num: 0,
                    dw: "条",
                },
                {
                    name: "其他上报数据",
                    num: 0,
                    dw: "条",
                },
            ],
            tabData: {
                list: [
                    {
                        name: "月",
                    },
                    {
                        name: "年",
                    },
                ],
                value: "name",
            },
            selectData: {
                clearable: false,
                state: true,
                width: "100px",
                defaultValue: "",
                list: [],
                label: "name",
                value: "name",
            },
            monthData: {
                type: 0, //0 月度统计 1 年度统计
                year: "", //this.common.getTimeY(0),
            },
            xctjObj: [],
            tableColums: [
                {
                    prop: "CJSJ",
                    label: "时间",
                    width: 160,
                },
                {
                    prop: "YCSJ",
                    label: "异常事件",
                    width: 160,
                    align: "center",
                    headerAlign: "center",
                },
                {
                    prop: "YCFSWZ",
                    label: "异常位置",
                    width: 160,
                },
                {
                    // 0 处置中  1 已处置
                    propColorNum: "YCSFJC",
                    colorArr: [
                        "rgba(255,54,54,1)",
                        "rgba(0,255,138,1)",
                        "rgba(228,244,255,1)",
                    ],
                    prop: "YCSFJCNAME",
                    label: "状态",
                    width: 80,
                    align: "center",
                    headerAlign: "center",
                },
            ],
            tableData: [],
            state: true, //true 还有未加载的数据 false 已经加载完毕
            pageIndex: 1,
            pageSize: 4,
            totalNum: 0,
            sntqState: true,
            yjtjPart: {
                yctjList: [
                    {
                        name: "异常总数",
                        num: 0,
                        color: "rgba(255, 205, 128, 1)",
                    },
                    {
                        name: "处置中",
                        num: 0,
                        color: "#ff0000",
                    },
                    {
                        name: "已解除",
                        num: 0,
                        color: "#61ff00",
                    },
                ], // 异常统计
                leftPart: {
                    colorList: ["#41DAEC", "#48FF00", "#FFFC00", "#FF7E00"],
                    listAry: [],
                },
                rightPart: {
                    colorList: [
                        "#41DAEC",
                        "#48FF00",
                        "#FFFC00",
                        "#FF7E00",
                        "#6A66FF",
                    ],
                    listAry: [],
                },
            },
        };
    },
    computed: {
        ...mapGetters({
            rcxcAreaItem: TYPES.rcxcAreaItem,
            rcxcPanelNum: TYPES.rcxcPanelNum,
            rxcxYclbItem: TYPES.rxcxYclbItem,
        }),
    },
    props: {},
    watch: {
        rcxcAreaItem: {
            immediate: true,
            handler () {
                this.getJgtjData();
                this.getSctjTimeList();
                this.getYctjTop();
                this.getYctjLeft();
                this.getYctjRight();
                this.pageIndex = 1;
                this.tableData = [];
                this.getYclbData();
            },
        },
    },
    mounted () { },
    methods: {
        ...mapActions([
            "GetRcxcCount",
            "GetInspectionStatisticsYearList",
            "GetRcxcSjtj",
            "GetRcxcYclx",
            "GetRcxcYclb",
            "GetAbnormalRecordStatistics",
            "GetAbnormalRecordComposition",
        ]),
        ...mapMutations({
            SET_RCXCPANELNUM: TYPES.SET_RCXCPANELNUM,
            SET_RCXCAREAITEM: TYPES.SET_RCXCAREAITEM,
            SET_RCXCYCLBITEM: TYPES.SET_RCXCYCLBITEM,
            SET_RCXCRXTJNUM: TYPES.SET_RCXCRXTJNUM,
            SET_RCXCRGSBNUM: TYPES.SET_RCXCRGSBNUM,
        }),
        // 获取结果统计数据
        async getJgtjData () {
            let result = await this.GetRcxcCount({
                qyid: this.rcxcAreaItem ? this.rcxcAreaItem.ID : "",
            });
            this.jgtjList[0].num = result.XCRS; //巡查人数
            this.jgtjList[1].num = result.QLM[0].COUNT;
            this.jgtjList[2].num = result.QLM[1].COUNT;
            this.SET_RCXCRGSBNUM(result.QLM[0].COUNT + result.QLM[1].COUNT)

            this.jgtjList[1].name = result.QLM[0].NAME;
            this.jgtjList[2].name = result.QLM[1].NAME;

            this.jgtjList[3].num = result.CM; //其他上报数据
        },
        // 获取巡查统计数据 时间 年
        async getSctjTimeList () {
            let result = await this.GetInspectionStatisticsYearList({
                qyid: this.rcxcAreaItem ? this.rcxcAreaItem.ID : "",
            });
            this.selectData.list = [];
            result.map((item) => {
                let obj = {
                    name: item,
                };
                this.selectData.list.push(obj);
            });
            if (this.selectData.list.length) {
                this.monthData.year = this.selectData.list[0].name;
                this.selectData.defaultValue = this.monthData.year;
                this.getXctjData();
            } else {
                this.xctjObj = [];
                let id = `echartXctj${this.echartId}`;
                this.$echarts.dispose(document.getElementById(id));
            }
        },
        // 获取巡查统计数据
        async getXctjData () {
            let result = await this.GetRcxcSjtj({
                qyid: this.rcxcAreaItem ? this.rcxcAreaItem.ID : "",
                nf: this.monthData.type == 0 ? this.monthData.year : "", //年份
                tjfs: this.monthData.type, //统计方式（月度统计传0，周统计传1）
            });
            this.xctjObj = result;
            if (this.monthData.type == "0") {
                this.initEchartXctjMonth(result);
            } else {
                this.initEchartXctjYear(result);
            }
        },
        // 上年同期 显示 隐藏
        changeSntqState () {
            this.sntqState = !this.sntqState;
            this.initEchartXctjMonth(this.xctjObj);
        },
        // 月年 切换
        getTabItem (item, index) {
            if (item.name == "年") {
                this.selectData.state = false;
            } else {
                this.selectData.state = true;
            }
            this.monthData.type = index;
            this.getXctjData();
        },
        // 年份切换
        getSelectItem (item) {
            this.monthData.year = item.name;
            this.getXctjData();
        },
        // 巡查统计 月
        initEchartXctjMonth (result) {
            let timeList = [];
            let xAxisData = [];
            let seriesData = [];
            let seriesYccs = {
                name: `异常次数`,
                type: "line",
                smooth: true,
                showSymbol: true,
                symbol: "circle",
                symbolSize: 5,
                areaStyle: {
                    color: new this.$echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                            {
                                offset: 0,
                                color: "rgba(231,100,82,0.8)",
                            },
                            {
                                offset: 0.5,
                                color: "rgba(231,100,82,0)",
                            },
                        ],
                        false
                    ),
                },
                data: [],
            };
            let seriesXccs = {
                name: `巡查次数`,
                type: "line",
                smooth: true,
                showSymbol: true,
                symbol: "circle",
                symbolSize: 5,
                areaStyle: {
                    color: new this.$echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                            {
                                offset: 0,
                                color: "rgba(90,217,166,0.8)",
                            },
                            {
                                offset: 0.5,
                                color: "rgba(90,217,166,0)",
                            },
                        ],
                        false
                    ),
                },
                data: [],
            };
            // let lastSeriesYccs = {
            //     name: `${this.monthData.year - 1}异常次数`,
            //     type: "line",
            //     smooth: true,
            //     showSymbol: true,
            //     symbol: "circle",
            //     symbolSize: 5,
            //     areaStyle: {
            //         color: new this.$echarts.graphic.LinearGradient(
            //             0,
            //             0,
            //             0,
            //             1,
            //             [
            //                 {
            //                     offset: 0,
            //                     color: "rgba(255,159,0,0.8)",
            //                 },
            //                 {
            //                     offset: 0.5,
            //                     color: "rgba(255,159,0,0)",
            //                 },
            //             ],
            //             false
            //         ),
            //     },
            //     data: [],
            // };
            // let lastSeriesXccs = {
            //     name: `${this.monthData.year - 1}巡查次数`,
            //     type: "line",
            //     smooth: true,
            //     showSymbol: true,
            //     symbol: "circle",
            //     symbolSize: 5,
            //     areaStyle: {
            //         color: new this.$echarts.graphic.LinearGradient(
            //             0,
            //             0,
            //             0,
            //             1,
            //             [
            //                 {
            //                     offset: 0,
            //                     color: "rgba(114,194,255,0.8)",
            //                 },
            //                 {
            //                     offset: 0.5,
            //                     color: "rgba(114,194,255,0)",
            //                 },
            //             ],
            //             false
            //         ),
            //     },
            //     data: [],
            // };

            //异常次数
            result.ABNORMAL.map((item) => {
                seriesYccs.data.push(item.NUMBER);
            });
            // 巡查次数
            result.NORMAL.map((item) => {
                seriesXccs.data.push(item.NUMBER);
            });
            // //异常次数 上一年
            // result.LASTABNORMAL.map((item) => {
            //     lastSeriesYccs.data.push(item.NUMBER);
            // });
            // // 巡查次数 上一年
            // result.LASTNORMAL.map((item) => {
            //     lastSeriesXccs.data.push(item.NUMBER);
            // });

            if (result.ABNORMAL.length) {
                result.ABNORMAL.map((item) => {
                    timeList.push(item.SHIJIAN);
                });
            } else if (result.NORMAL.length) {
                result.NORMAL.map((item) => {
                    timeList.push(item.SHIJIAN);
                });
            }
            // else if (result.LASTABNORMAL.length) {
            //     result.LASTABNORMAL.map((item) => {
            //         timeList.push(item.SHIJIAN);
            //     });
            // } else {
            //     result.LASTNORMAL.map((item) => {
            //         timeList.push(item.SHIJIAN);
            //     });
            // }
            xAxisData = [...new Set(timeList)].sort();
            seriesData = [seriesYccs, seriesXccs];
            // if (this.sntqState) {
            //     seriesData = [
            //         seriesYccs,
            //         seriesXccs,
            //         lastSeriesYccs,
            //         lastSeriesXccs,
            //     ];
            // } else {
            //     seriesData = [seriesYccs, seriesXccs];
            // }

            let options = {
                color: [
                    "rgba(231,100,82,1)",
                    "rgba(90,217,166,1)",
                    "rgba(255,159,0,1)",
                    "rgba(114,194,255,1)",
                ],
                tooltip: {
                    trigger: "axis",
                    padding: [2, 10],
                    textStyle: {
                        fontSize: 16,
                        align: "left",
                    },
                    axisPointer: {
                        lineStyle: {
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "rgba(255, 255, 255,1)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                ],
                                global: false,
                            },
                        },
                    },
                },
                legend: {
                    icon: "rect",
                    itemWidth: 14,
                    itemHeight: 5,
                    itemGap: 13,
                    textStyle: {
                        color: "rgba(196,214,225,1)",
                    },
                    left: "left",
                    top: "5%",
                },
                grid: {
                    top: 60,
                    bottom: 20,
                    left: 40,
                    right: 40,
                },
                xAxis: [
                    {
                        name: "时间",
                        nameTextStyle: {
                            fontSize: 12,
                            color: "#9AB4E3",
                        },
                        boundaryGap: false, // 默认，坐标轴留白策略
                        type: "category",
                        axisLabel: {
                            // interval: 0,
                            // rotate: 45,
                            textStyle: {
                                color: "#A8C8FF",
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#0f5378",
                            },
                        },
                        splitArea: {
                            color: "#f00",
                            lineStyle: {
                                color: "#e2e9ff",
                            },
                        },
                        data: xAxisData,
                    },
                ],
                yAxis: {
                    name: "次",
                    nameTextStyle: {
                        fontSize: 12,
                        color: "#9AB4E3",
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(53,80,124,0.2)",
                        },
                    },
                    axisLabel: {
                        margin: 20,
                        fontSize: 12,
                        textStyle: {
                            color: "#9AB4E3",
                            // color: "rgba(53,80,124,1)",
                        },
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: "rgba(53,80,124,0.2)",
                            width: 2,
                        },
                    },
                },
                series: seriesData,
            };
            let id = `echartXctj${this.echartId}`;
            let echart = null;
            echart = this.$echarts.dispose(document.getElementById(id));
            echart = this.$echarts.init(document.getElementById(id));
            echart.setOption(options);
        },
        // 巡查统计 年
        initEchartXctjYear (result) {
            let timeList = [];
            let xAxisData = [];
            let seriesData = [];
            let seriesYccs = {
                name: `异常次数`,
                type: "line",
                smooth: true,
                showSymbol: true,
                symbol: "circle",
                symbolSize: 5,
                areaStyle: {
                    color: new this.$echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                            {
                                offset: 0,
                                color: "rgba(231,100,82,0.8)",
                            },
                            {
                                offset: 0.5,
                                color: "rgba(231,100,82,0)",
                            },
                        ],
                        false
                    ),
                },
                data: [],
            };
            let seriesXccs = {
                name: `巡查次数`,
                type: "line",
                smooth: true,
                showSymbol: true,
                symbol: "circle",
                symbolSize: 5,
                areaStyle: {
                    color: new this.$echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                            {
                                offset: 0,
                                color: "rgba(90,217,166,0.8)",
                            },
                            {
                                offset: 0.5,
                                color: "rgba(90,217,166,0)",
                            },
                        ],
                        false
                    ),
                },
                data: [],
            };
            //异常次数
            result.ABNORMAL.map((item) => {
                seriesYccs.data.push(item.NUMBER);
            });
            // 巡查次数
            result.NORMAL.map((item) => {
                timeList.push(item.SHIJIAN);
                seriesXccs.data.push(item.NUMBER);
            });
            xAxisData = [...new Set(timeList)].sort();
            seriesData = [seriesYccs, seriesXccs];
            let options = {
                color: [
                    "rgba(231,100,82,1)",
                    "rgba(90,217,166,1)",
                    "rgba(255,159,0,1)",
                    "rgba(114,194,255,1)",
                ],
                tooltip: {
                    trigger: "axis",
                    padding: [2, 10],
                    textStyle: {
                        fontSize: 16,
                        align: "left",
                    },
                    axisPointer: {
                        lineStyle: {
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "rgba(255, 255, 255,1)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                ],
                                global: false,
                            },
                        },
                    },
                },
                legend: {
                    icon: "rect",
                    itemWidth: 14,
                    itemHeight: 5,
                    itemGap: 13,
                    textStyle: {
                        color: "rgba(196,214,225,1)",
                    },
                    left: "left",
                    top: "5%",
                },
                grid: {
                    top: 60,
                    bottom: 20,
                    left: 40,
                    right: 40,
                },
                xAxis: [
                    {
                        name: "时间",
                        nameTextStyle: {
                            fontSize: 12,
                            color: "#9AB4E3",
                        },
                        boundaryGap: false, // 默认，坐标轴留白策略
                        type: "category",
                        axisLabel: {
                            // interval: 0,
                            // rotate: 45,
                            textStyle: {
                                color: "#A8C8FF",
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#0f5378",
                            },
                        },
                        splitArea: {
                            color: "#f00",
                            lineStyle: {
                                color: "#e2e9ff",
                            },
                        },
                        data: xAxisData,
                    },
                ],
                yAxis: {
                    name: "次",
                    nameTextStyle: {
                        fontSize: 12,
                        color: "#9AB4E3",
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(53,80,124,0.2)",
                        },
                    },
                    axisLabel: {
                        margin: 20,
                        fontSize: 12,
                        textStyle: {
                            color: "#9AB4E3",
                            // color: "rgba(53,80,124,1)",
                        },
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: "rgba(53,80,124,0.2)",
                            width: 2,
                        },
                    },
                },
                series: seriesData,
            };
            let id = `echartXctj${this.echartId}`;
            let echart = null;
            echart = this.$echarts.dispose(document.getElementById(id));
            echart = this.$echarts.init(document.getElementById(id));
            echart.setOption(options);
        },
        // 巡查统计 月
        // initEchartXctjMonth(result) {
        //     let timeList = [];
        //     let xAxisData = [];
        //     let seriesData = [];
        //     let seriesYccs = {
        //         name: `${this.monthData.year}异常次数`,
        //         type: "line",
        //         smooth: true,
        //         showSymbol: true,
        //         symbol: "circle",
        //         symbolSize: 5,
        //         areaStyle: {
        //             color: new this.$echarts.graphic.LinearGradient(
        //                 0,
        //                 0,
        //                 0,
        //                 1,
        //                 [
        //                     {
        //                         offset: 0,
        //                         color: "rgba(231,100,82,0.8)",
        //                     },
        //                     {
        //                         offset: 0.5,
        //                         color: "rgba(231,100,82,0)",
        //                     },
        //                 ],
        //                 false
        //             ),
        //         },
        //         data: [],
        //     };
        //     let seriesXccs = {
        //         name: `${this.monthData.year}巡查次数`,
        //         type: "line",
        //         smooth: true,
        //         showSymbol: true,
        //         symbol: "circle",
        //         symbolSize: 5,
        //         areaStyle: {
        //             color: new this.$echarts.graphic.LinearGradient(
        //                 0,
        //                 0,
        //                 0,
        //                 1,
        //                 [
        //                     {
        //                         offset: 0,
        //                         color: "rgba(90,217,166,0.8)",
        //                     },
        //                     {
        //                         offset: 0.5,
        //                         color: "rgba(90,217,166,0)",
        //                     },
        //                 ],
        //                 false
        //             ),
        //         },
        //         data: [],
        //     };
        //     let lastSeriesYccs = {
        //         name: `${this.monthData.year - 1}异常次数`,
        //         type: "line",
        //         smooth: true,
        //         showSymbol: true,
        //         symbol: "circle",
        //         symbolSize: 5,
        //         areaStyle: {
        //             color: new this.$echarts.graphic.LinearGradient(
        //                 0,
        //                 0,
        //                 0,
        //                 1,
        //                 [
        //                     {
        //                         offset: 0,
        //                         color: "rgba(255,159,0,0.8)",
        //                     },
        //                     {
        //                         offset: 0.5,
        //                         color: "rgba(255,159,0,0)",
        //                     },
        //                 ],
        //                 false
        //             ),
        //         },
        //         data: [],
        //     };
        //     let lastSeriesXccs = {
        //         name: `${this.monthData.year - 1}巡查次数`,
        //         type: "line",
        //         smooth: true,
        //         showSymbol: true,
        //         symbol: "circle",
        //         symbolSize: 5,
        //         areaStyle: {
        //             color: new this.$echarts.graphic.LinearGradient(
        //                 0,
        //                 0,
        //                 0,
        //                 1,
        //                 [
        //                     {
        //                         offset: 0,
        //                         color: "rgba(114,194,255,0.8)",
        //                     },
        //                     {
        //                         offset: 0.5,
        //                         color: "rgba(114,194,255,0)",
        //                     },
        //                 ],
        //                 false
        //             ),
        //         },
        //         data: [],
        //     };

        //     //异常次数
        //     result.ABNORMAL.map((item) => {
        //         seriesYccs.data.push(item.NUMBER);
        //     });
        //     // 巡查次数
        //     result.NORMAL.map((item) => {
        //         seriesXccs.data.push(item.NUMBER);
        //     });
        //     //异常次数 上一年
        //     result.LASTABNORMAL.map((item) => {
        //         lastSeriesYccs.data.push(item.NUMBER);
        //     });
        //     // 巡查次数 上一年
        //     result.LASTNORMAL.map((item) => {
        //         lastSeriesXccs.data.push(item.NUMBER);
        //     });

        //     if (result.ABNORMAL.length) {
        //         result.ABNORMAL.map((item) => {
        //             timeList.push(item.SHIJIAN);
        //         });
        //     } else if (result.NORMAL.length) {
        //         result.NORMAL.map((item) => {
        //             timeList.push(item.SHIJIAN);
        //         });
        //     } else if (result.LASTABNORMAL.length) {
        //         result.LASTABNORMAL.map((item) => {
        //             timeList.push(item.SHIJIAN);
        //         });
        //     } else {
        //         result.LASTNORMAL.map((item) => {
        //             timeList.push(item.SHIJIAN);
        //         });
        //     }
        //     xAxisData = [...new Set(timeList)].sort();
        //     if (this.sntqState) {
        //         seriesData = [
        //             seriesYccs,
        //             seriesXccs,
        //             lastSeriesYccs,
        //             lastSeriesXccs,
        //         ];
        //     } else {
        //         seriesData = [seriesYccs, seriesXccs];
        //     }

        //     let options = {
        //         color: [
        //             "rgba(231,100,82,1)",
        //             "rgba(90,217,166,1)",
        //             "rgba(255,159,0,1)",
        //             "rgba(114,194,255,1)",
        //         ],
        //         tooltip: {
        //             trigger: "axis",
        //             padding: [2, 10],
        //             textStyle: {
        //                 fontSize: 16,
        //                 align: "left",
        //             },
        //             axisPointer: {
        //                 lineStyle: {
        //                     color: {
        //                         type: "linear",
        //                         x: 0,
        //                         y: 0,
        //                         x2: 0,
        //                         y2: 1,
        //                         colorStops: [
        //                             {
        //                                 offset: 0,
        //                                 color: "rgba(0, 255, 233,0)",
        //                             },
        //                             {
        //                                 offset: 0.5,
        //                                 color: "rgba(255, 255, 255,1)",
        //                             },
        //                             {
        //                                 offset: 1,
        //                                 color: "rgba(0, 255, 233,0)",
        //                             },
        //                         ],
        //                         global: false,
        //                     },
        //                 },
        //             },
        //         },
        //         legend: {
        //             icon: "rect",
        //             itemWidth: 14,
        //             itemHeight: 5,
        //             itemGap: 13,
        //             textStyle: {
        //                 color: "rgba(196,214,225,1)",
        //             },
        //             left: "left",
        //             top: "5%",
        //         },
        //         grid: {
        //             top: 60,
        //             bottom: 20,
        //             left: 40,
        //             right: 40,
        //         },
        //         xAxis: [
        //             {
        //                 name: "时间",
        //                 nameTextStyle: {
        //                     fontSize: 12,
        //                     color: "#9AB4E3",
        //                 },
        //                 boundaryGap: false, // 默认，坐标轴留白策略
        //                 type: "category",
        //                 axisLabel: {
        //                     // interval: 0,
        //                     // rotate: 45,
        //                     textStyle: {
        //                         color: "#A8C8FF",
        //                     },
        //                 },
        //                 axisLine: {
        //                     show: true,
        //                     lineStyle: {
        //                         color: "#0f5378",
        //                     },
        //                 },
        //                 splitArea: {
        //                     color: "#f00",
        //                     lineStyle: {
        //                         color: "#e2e9ff",
        //                     },
        //                 },
        //                 data: xAxisData,
        //             },
        //         ],
        //         yAxis: {
        //             name: "次",
        //             nameTextStyle: {
        //                 fontSize: 12,
        //                 color: "#9AB4E3",
        //             },
        //             splitLine: {
        //                 show: true,
        //                 lineStyle: {
        //                     color: "rgba(53,80,124,0.2)",
        //                 },
        //             },
        //             axisLabel: {
        //                 margin: 20,
        //                 fontSize: 12,
        //                 textStyle: {
        //                     color: "#9AB4E3",
        //                     // color: "rgba(53,80,124,1)",
        //                 },
        //             },
        //             axisLine: {
        //                 show: false,
        //                 lineStyle: {
        //                     color: "rgba(53,80,124,0.2)",
        //                     width: 2,
        //                 },
        //             },
        //         },
        //         series: seriesData,
        //     };
        //     let id = `echartXctj${this.echartId}`;
        //     let echart = null;
        //     echart = this.$echarts.dispose(document.getElementById(id));
        //     echart = this.$echarts.init(document.getElementById(id));
        //     echart.setOption(options);
        // },
        // 获取异常类型 顶部数据
        async getYctjTop () {
            let result = await this.GetAbnormalRecordStatistics({
                qyid: this.rcxcAreaItem ? this.rcxcAreaItem.ID : "",
            });
            this.yjtjPart.yctjList[0].num = result.ZS;
            this.yjtjPart.yctjList[1].num = result.WJC;
            this.yjtjPart.yctjList[2].num = result.YJC;
        },
        // 获取异常类型 左侧echart
        async getYctjLeft () {
            let result = await this.GetAbnormalRecordComposition({
                qyid: this.rcxcAreaItem ? this.rcxcAreaItem.ID : "",
            });

            this.yjtjPart.leftPart.listAry = [
                {
                    NAME: "古茶林",
                    COUNT: result.GCLZS,
                    COUNTP: result.GCLZSP,
                    WJC: result.GCLWJC,
                    WJCP: result.GCLWJCP,
                    YJC: result.GCLYJC,
                    YJCP: result.GCLYJCP,
                },
                {
                    NAME: "传统村落",
                    COUNT: result.CTCLZS,
                    COUNTP: result.CTCLZSP,
                    WJC: result.CTCLWJC,
                    WJCP: result.CTCLWJCP,
                    YJC: result.CTCLYJC,
                    YJCP: result.CTCLYJCP,
                },
                {
                    NAME: "分隔防护林",
                    COUNT: result.FGFHLZS,
                    COUNTP: result.FGFHLZSP,
                    WJC: result.FGFHLWJC,
                    WJCP: result.FGFHLWJCP,
                    YJC: result.FGFHLYJC,
                    YJCP: result.FGFHLYJCP,
                },
                {
                    NAME: "其他",
                    COUNT: result.QTZS,
                    COUNTP: result.QTZSP,
                    WJC: result.QTWJC,
                    WJCP: result.QTWJCP,
                    YJC: result.QTYJC,
                    YJCP: result.QTYJCP,
                },
            ];
        },
        // 获取异常类型
        async getYctjRight () {
            let result = await this.GetRcxcYclx({
                qyid: this.rcxcAreaItem ? this.rcxcAreaItem.ID : "",
            });
            result.DATA.map((item) => {
                item.COUNT = item.ZS;
                item.COUNTP = item.ZSP;
                item.WJC = item.WJCYCSL;
                item.WJCP = item.WJCYCSLP;
                item.YJC = item.YCSL;
                item.YJCP = item.YCSLP;
            });
            this.yjtjPart.rightPart.listAry = result.DATA;
        },
        // 获取 异常列表 数据
        async getYclbData () {
            let result = await this.GetRcxcYclb({
                qyid: this.rcxcAreaItem ? this.rcxcAreaItem.ID : "",
                pageindex: this.pageIndex,
                pagesize: this.pageSize,
            });
            this.totalNum = result.TOTAL;
            this.tableData = [...this.tableData, ...result.DATA];
            if (this.tableData.length < this.totalNum) {
                this.state = true;
            } else {
                this.state = false;
            }
        },
        loadData () {
            if (this.state) {
                if (this.tableData.length < this.totalNum) {
                    this.pageIndex++;
                    this.getYclbData();
                } else {
                    this.state = false;
                }
            }
        },
        tableItemDetail (item) {
            this.SET_RCXCYCLBITEM(item);
        },
        handleClose () {
            this.SET_RCXCPANELNUM(0);
            this.SET_RCXCAREAITEM(null);
        },
        // 筛选 异常统计
        getMarkerYctj (index) {
            this.SET_RCXCRXTJNUM(index);
        },
    },
};
</script>
<style scoped lang="scss">
.rightPart {
    width: 100%;
    height: 100%;
    background: rgba(26, 56, 89, 0.3);
    // 巡查状况
    .xctdPart {
        width: 100%;
        height: 183px;
        .jgtjCtn {
            width: 100%;
            height: 100%;
            .listItem {
                width: 162px;
                .title {
                    width: 100%;
                    height: 40px;
                    background: url('~@image/bigScreen/icon/titleBg.png')
                        no-repeat;
                    background-size: 100% 100%;
                    font-size: 18px;
                    color: rgba(168, 200, 255, 1);
                }
                .numPart {
                    margin-top: 10px;
                    height: 65px;
                    border: 1px solid rgba(53, 80, 124, 0.4);
                    .num {
                        font-size: 70px;
                        color: rgba(189, 215, 236, 1);
                        font-family: DBPM;
                    }
                    .dw {
                        color: rgba(189, 215, 236, 1);
                        position: relative;
                        left: 4px;
                        top: 4px;
                        font-size: 18px;
                    }
                }
            }
        }
    }
    // 巡查统计
    .xctjPart {
        width: 100%;
        height: 254px;
        .xctjCtn {
            width: 100%;
            height: 100%;
            position: relative;
            .condition {
                position: absolute;
                right: 0;
                top: -38px;
                .line {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 522px;
                    height: 1px;
                    background: rgba(53, 80, 124, 0.4);
                    z-index: -1;
                }
            }
            .sntq {
                position: absolute;
                right: 0;
                top: 0;
                .sntqActive {
                    .circle {
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background: #ffcd80;
                        margin-right: 10px;
                    }
                    .name {
                        color: #afc9e1;
                        font-size: 14px;
                    }
                }
                .sntqDefault {
                    .circle {
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background: #bdd7ec;
                        margin-right: 10px;
                    }
                    .name {
                        color: #bdd7ec;
                        font-size: 14px;
                    }
                }
            }

            .echart {
                width: 100%;
                height: 100%;
            }
            .dw {
                position: absolute;
                right: 0;
                bottom: -20px;
                color: #35507c;
            }
        }
    }
    // 异常统计
    .yctjPart {
        width: 100%;
        .yctjCtn {
            width: 100%;
            height: 100%;
            .tableList {
                width: 100%;
                height: 40px;
                .tableItem {
                    height: 100%;
                    .name {
                        width: 102px;
                        height: 100%;
                        background: url('~@image/bigScreen/icon/titleBg.png')
                            no-repeat;
                        background-size: 100% 100%;
                        font-size: 18px;
                        color: #a8c8ff;
                        margin-right: 5px;
                    }
                    .numPart {
                        width: 104px;
                        height: 100%;
                        border: 1px solid rgba(53, 80, 124, 1);
                        .num {
                            font-size: 50px;
                            font-family: DBPM;
                        }
                        .dw {
                            color: rgba(214, 238, 255, 1);
                            font-size: 14px;
                            position: relative;
                            left: 4px;
                            top: 2px;
                        }
                    }
                }
            }
            .echartPart {
                width: 100%;
                height: 168px;
                .echartItem {
                    width: 50%;
                    height: 100%;
                }
            }
            .yclbCtn {
                height: 110px;
                margin-bottom: 10px;
            }
        }
    }
}
</style>
