<template>
    <div class="screenItem">
        <!-- 病害列表 -->
        <basic-module :text="common.getFyName(fany.bhlb)">
            <div slot="basic-module" class="screenCtn flex-between">
                <div style="width: 50%; position: relative">
                    <div class="echartPart" :id="'bhlb' + chartId"></div>
                    <NoMonitor
                        v-if="listAry.length == 0"
                        text="暂无数据"
                    ></NoMonitor>
                </div>

                <div class="listPart flex-1 scrollbar-hidden">
                    <SearchList
                        :listAry="detailList"
                        fontSize="20px"
                        height="40px"
                        :activeNumOut="activeNum"
                        labelValue="BHMC"
                        :searchState="searchState"
                        @handleSearchItem="handleSearchItem"
                        @loadData="loadData"
                    ></SearchList>
                    <NoMonitor
                        v-if="detailList.length == 0"
                        text="暂无数据"
                    ></NoMonitor>
                </div>
            </div>
        </basic-module>
    </div>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import SearchList from "../comp/SearchListBtbh.vue"; //搜索结果
import NoMonitor from "@views/big_screen/comp/NoMonitor";
export default {
    components: {
        SearchList,
        NoMonitor,
    },
    name: "",
    data() {
        return {
            listAry: [],
            detailList: [],
            detailAllList: [],
            searchState: true,
            activeNum: null,
            pageIndex: 1,
            pageSize: 20,
            chartId: Math.random(),
        };
    },
    computed: {
        ...mapGetters(["btbhYcys", "btbhYcysType"]),
    },
    props: {},
    watch: {
        btbhYcys: {
            immediate: false,
            deep: true,
            handler() {
                this.getBhAllList();
            },
        },
        btbhYcysType: {
            immediate: false,
            deep: true,
            handler() {
                this.getBhEchartList();
                this.getBhAllList();
            },
        },
    },
    mounted() {
        this.getBhAllList();
        this.getBhEchartList();
    },
    methods: {
        ...mapActions(["getBhList", "getBhLxStatistic"]),
        ...mapMutations({
            setStateBtbh: "SET_STATE_BTBH",
        }),
        // 获取病害列表
        async getBhAllList() {
            let res = await this.getBhList({
                yczcfb: this.btbhYcysType ? this.btbhYcysType : "",
                ycysbm: this.btbhYcys ? this.btbhYcys.BM : "",
            });
            this.detailAllList = (res && res.DT) || [];
            this.detailList = this.detailAllList.slice(0, this.pageSize);
            this.setStateBtbh({
                bhList: this.detailAllList,
            });

            if (this.btbhYcys) {
                this.activeNum = 0;
                this.setStateBtbh({
                    btbhPanelNum: 2, // 右侧页面切换
                    btbhDetail: this.detailList[0], // 被点击的病害
                });
            } else {
                this.activeNum = null;
            }
        },
        // 懒加载病害列表
        loadData() {
            if (this.detailAllList.length === this.detailList.length) return;
            this.pageIndex += 1;
            this.detailList.push(
                ...this.detailAllList.slice(
                    (this.pageIndex - 1) * this.pageSize,
                    this.pageIndex * this.pageSize
                )
            );
        },
        // echart 数据
        async getBhEchartList() {
            let res = await this.getBhLxStatistic({
                yczcfb: this.btbhYcysType ? this.btbhYcysType : "",
                // ycysbm: this.btbhYcys ? this.btbhYcys.BM : "",
            });
            let state = false;
            res.map((item) => {
                if (item.COUNT != 0 || item.YXFCOUNT != 0) {
                    state = true;
                }
            });
            this.$echarts.dispose(
                document.getElementById("bhlb" + this.chartId)
            );
            if (state) {
                this.listAry = res;
                this.initEchartBhlb(this.listAry);
            } else {
                this.listAry = [];
            }
        },
        initEchartBhlb(list) {
            let colorArr = ["rgb(65, 218, 236)", "rgb(236, 105, 65)"];
            let fontColor = "rgba(154,180,227,1)";
            let lineColor = "rgba(53,80,124,1)";
            let legend = ["病害数", "已修复病害数"];
            let max = Math.max.apply(
                null,
                list.map((item) => item.COUNT)
            );
            let seriesData = [];
            legend.forEach((item, index) => {
                seriesData.push({
                    name: item,
                    type: "radar",
                    data: [
                        index == 0
                            ? list.map((item) => item.COUNT)
                            : list.map((item) => item.YXFCOUNT),
                    ],
                    symbol: "none",
                    z: index,
                    lineStyle: {
                        normal: {
                            color: colorArr[index],
                            width: 2,
                        },
                    },
                    areaStyle: {
                        normal: {
                            color: colorArr[index],
                            opacity: 0.2,
                        },
                    },
                    emphasis: {
                        areaStyle: {
                            opacity: 0.3,
                        },
                    },
                });
            });
            let indicatorList = [];
            indicatorList = list.map((item, index) => {
                return {
                    name: item.BHLXMC,
                    max: max,
                };
            });
            let option = {
                tooltip: {
                    show: true,
                    trigger: "item",
                },
                radar: {
                    radius: "55%",
                    shape: "circle",
                    name: {
                        textStyle: {
                            fontSize: 16,
                            color: fontColor,
                        },
                    },
                    axisLabel: {
                        show: false,
                        fontSize: 18,
                        color: lineColor,
                        fontStyle: "normal",
                        fontWeight: "normal",
                    },
                    splitArea: {
                        areaStyle: {
                            color: "transparent",
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: lineColor,
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: lineColor,
                        },
                    },
                    indicator: indicatorList,
                },
                series: seriesData,
            };

            let echart = null;
            echart = this.$echarts.dispose(
                document.getElementById("bhlb" + this.chartId)
            );
            echart = this.$echarts.init(
                document.getElementById("bhlb" + this.chartId)
            );
            echart.setOption(option);
        },
        handleSearchItem(item, index) {
            this.setStateBtbh({
                btbhPanelNum: item ? 2 : 0, // 右侧页面切换
                btbhDetail: item, // 被点击的病害
            });
        },
    },
};
</script>
<style scoped lang="scss">
.screenItem {
    width: 100%;
    height: 100%;
    .screenCtn {
        width: 100%;
        height: 100%;
        .echartPart {
            width: 352px;
            height: 100%;
        }
        .listPart {
            position: relative;
            height: 100%;
        }
    }
}
</style>
