<template>
    <div class="gclbh-chart" id="gclbh" style="{width: '655px', height: '240px'}">
    </div>
</template>
<script>
import echarts from "echarts";
export default {
    name: "gclbh",
    data() {
        return {
        }
    },
    watch: {
        chartData: {
            immediate: true,
            handler() {
                this.$nextTick(() => {
                    this.initChart();
                });
            }
        }
    },
    mounted() {
        
    },
    methods: {
        initChart(){
            var myChart = echarts.init(document.getElementById('gclbh'))

            var category = ['茶 圆 赤 星 病', '茶 轮 斑 病', '茶 云 纹 叶 枯 病', '茶 白 星 病', '茶 煤 病', '茶 炭 疽 病'];
            var barData = [21,25,9,22,29,32];
            var barDatamax = [45,45,45,45,45,45];
            var barWidth = 16;
            var sum = 45;
            var option = {
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        height: "240",
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false
                        },
                        splitLine: {
                        show: false,
                        },
                    },
                    yAxis: {
                        type: 'category',
                        data: category,
                        splitLine: {show: false},
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        offset: 0,
                        nameTextStyle: {
                            fontSize: 15,
                        },
                        axisLabel: {
                            color: '#BDD7EC',
                        }
                    },
                    series: [
                        {  // 右半截柱子
                            name: '数量',
                            type: 'bar',
                            barWidth: 16,
                            barGap: '-100%',
                            barCategoryGap: '50%',
                            z: 0,
                            itemStyle: {
                                opacity: 7,
                                normal: {
                                    barBorderRadius: 7,
                                    color: 'rgba(46, 167, 223,0.1)',
                                },
                            },
                            data: barDatamax,
                        },
                        {//左半截柱子
                            name: '数量',
                            type: 'bar',
                            barWidth: 16,
                            barGap: '-100%',
                            barCategoryGap: '50%',
                            data: barData,
                            smooth: true,
                            label: {
                                normal: {
                                    show: true,
                                    position: 'right',
                                    offset: [30, 1],
                                    formatter: function(params) {
                                        return ((params.value / sum) * 100).toFixed(0) + '%';
                                    },
                                    textStyle: {
                                        color: '#FFFFFF',
                                        fontSize: 13
                                    }
                                }
                            },
                            itemStyle: {
                                emphasis: {
                                    barBorderRadius: 7
                                },
                                normal: {
                                    barBorderRadius: 7,
                                    opacity: 1,
                                    color: new echarts.graphic.LinearGradient(
                                        0, 0, 1, 0,
                                        [
                                            {offset: 0, color: 'rgba(46, 167, 223,0.16)'},
                                            {offset: 1, color: 'rgba(46, 167, 223,1)'}
                                        ]
                                    )
                                }
                            }
                        },
                        { //左半截柱子顶部圆片
                            "name": "",
                            "type": "pictorialBar",
                            "symbolSize": [barWidth, 15],
                            "symbolOffset": [0, 0],
                            "z": 12,
                            "symbolPosition": "end",
                            itemStyle: {
                                opacity: 1,
                                color: '#2EA7E0',
                                borderColor: new echarts.graphic.LinearGradient(
                                    0, 0, 1, 0,
                                    [
                                        {offset: 0, color: 'rgba(255, 255, 255,0.5)'}, // 0% 处的颜色
                                        {offset: 1, color: 'rgba(255, 255, 255,1)'} // 100% 处的颜色
                                    ]
                                )
                            },
                            label: {
                                show: true,
                                offset: [20, 1],
                            },
                            "data": barData
                        },
                        { //右半截柱子顶部圆片
                            "name": "",
                            "type": "pictorialBar",
                            "symbolSize": [barWidth, 15],
                            "symbolOffset": [0, -0],
                            "z": 12,
                            itemStyle: {
                                opacity: 1,
                                color: 'rgba(46, 167, 223,0.1)',
                                borderColor: new echarts.graphic.LinearGradient(
                                    0, 0, 1, 0,
                                    [
                                        {offset: 0, color: 'rgba(46, 167, 223,0.2)'},
                                        {offset: 1, color: 'rgba(46, 167, 223,1)'}
                                    ]
                                )
                            },
                            label: {
                                show: false,
                            },
                            "symbolPosition": "end",
                            "data": barDatamax
                        },
                        { //左半截柱子底部圆片
                            "name": "",
                            "type": "pictorialBar",
                            "symbolSize": [barWidth, 15],
                            "symbolOffset": [0, 0],
                            "z": 10,
                            itemStyle: {
                                opacity: 1,
                                color:'rgba(46, 167, 223, 0.16)',
                                borderColor: new echarts.graphic.LinearGradient(
                                    0, 0, 1, 0,
                                    [
                                        {offset: 0, color: 'rgba(46, 167, 223,0.3)'}, // 0% 处的颜色
                                        {offset: 1, color: 'rgba(46, 167, 223,1)'} // 100% 处的颜色
                                    ]
                                )
                            },
                            "data": [1, 1, 1, 1, 1, 1]
                        },
                    ]
                }
            myChart.setOption(option)
        },
    },
}
</script>
<style lang="scss" scoped>
.gclbh-chart {
    width: 100%;
    height: 100%;
}
</style>