import DialogGcs from "./comp/DialogGcs.vue"; //古茶树 
import DialogGsmm from "./comp/DialogGsmm.vue"; // 古树名木
import DialogYcys from "./comp/DialogYcys.vue"; //遗产要素
import DialogYcysMj from "./comp/DialogYcysMj.vue"; //遗产要素 民居
import DialogHistoryHj from "./comp/DialogHistoryHj.vue"; //历史火警
import DialogOthersYj from "./comp/DialogOthersYj.vue"; //预警
import DialogMsgYj from "./comp/DialogMsgYj.vue"; //消息推送的预警
// import DialogSxt from "./comp/DialogSxt_自己插件.vue"; //摄像头 flv
// import DialogSxt from "./comp/DialogSxt.vue"; //摄像头 live-player 厂家提供的插件
import DialogSxt from "./comp/DialogSxtIframe.vue"; //摄像头 iframe 公司 live-player 厂家提供的插件 第三方的监控 

import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    components: {
        DialogGcs,
        DialogGsmm,
        DialogYcys,
        DialogYcysMj,
        DialogHistoryHj,
        DialogOthersYj,
        DialogMsgYj,
        DialogSxt
    },
    props: {

    },
    computed: {
        ...mapGetters({
        }),
    },
    watch: {

    },
    data() {
        return {

        };
    },
    created() {
    },
    mounted() {
    },
    methods: {

    }

};