<template>
    <div class="rightPart scrollbar-hidden">
        <BoxTitle
            :title="btbhDetail.BHMC || '暂无病害名称'"
            @handleClose="handleClose"
        />
        <div class="ztbcztPart">
            <saveState
                class="ztbczt"
                :stateName="stateName"
                :stateType="btbhJbxxData.ZT_FIRST"
            ></saveState>
        </div>
        <div class="jbxxPart">
            <!-- 基本信息 -->
            <basic-module :text="common.getFyName(fany.jbxx)">
                <div slot="basic-module" class="jbxxCtn">
                    <div class="bhPic">
                        <el-image
                            :src="common.getThumbnailImg(btbhJbxxData.BHSYT)"
                            @click="getImgDetail(btbhJbxxData.BHSYT)"
                            fit="cover"
                            style="width: 100%; height: 100%"
                            class="pointer"
                        >
                            <div slot="error" style="width: 100%; height: 100%">
                                <SnNoData width="170px"></SnNoData>
                            </div>
                        </el-image>
                    </div>
                    <div class="bhMsg">
                        <div class="basicItem">
                            <div class="name">病害名称:</div>
                            <div
                                class="desc ellipsis"
                                :class="{ empty: !btbhJbxxData.BHMC }"
                            >
                                {{ btbhJbxxData.BHMC | strictNull }}
                            </div>
                        </div>
                        <div class="flex-between basicItem">
                            <div class="basicItemOne">
                                <div class="name">遗产要素:</div>
                                <div
                                    class="desc ellipsis"
                                    :class="{ empty: !btbhJbxxData.YCYSMC }"
                                >
                                    {{ btbhJbxxData.YCYSMC | strictNull }}
                                </div>
                            </div>
                            <div class="basicItemOne">
                                <div class="name">病害类型:</div>
                                <div
                                    class="desc ellipsis"
                                    :class="{ empty: !btbhJbxxData.BHLXMC }"
                                >
                                    {{ btbhJbxxData.BHLXMC | strictNull }}
                                </div>
                            </div>
                        </div>
                        <div class="flex-between basicItem">
                            <div class="basicItemOne">
                                <div class="name">采集时间:</div>
                                <div
                                    class="desc ellipsis"
                                    :class="{ empty: !btbhJbxxData.CJSJ }"
                                >
                                    {{ btbhJbxxData.CJSJ | strictNull }}
                                </div>
                            </div>
                            <div class="basicItemOne">
                                <div class="name">病害存在状态:</div>
                                <div
                                    class="desc ellipsis"
                                    :class="{ empty: !btbhJbxxData.BHCZZTMC }"
                                >
                                    {{ btbhJbxxData.BHCZZTMC | strictNull }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </basic-module>
        </div>
        <div class="bhjc-part">
            <!-- 病害监测 -->
            <basic-module :text="common.getFyName(fany.bhjc)">
                <div slot="basic-module" class="bhjc-ctn pageNoBg">
                    <!-- 病害位置 -->
                    <div v-if="selectData.list.length > 0">
                        <SelectTimeTab
                            class="bhwzList"
                            :selectData="selectData"
                            @getSelectItem="getSelectItem"
                        ></SelectTimeTab>
                        <img
                            class="fdBtn"
                            @click="blowUp()"
                            src="@image/bigScreen/ontological_disease/toFull.png"
                            title="放大"
                        />
                        <div v-if="total > 0">
                            <div class="bhjc-img flex-between">
                                <!-- <div class="leftPic">
                                    <picSelect :listAry="listAry"></picSelect>
                                </div>
                                <div class="rightPic">
                                    <picSelect
                                        :listAry="listAry"
                                        :defaultIndex="
                                            listAry.length > 1 ? 1 : 0
                                        "
                                    ></picSelect>
                                </div> -->
                                <el-image
                                    :src="
                                        common.getThumbnailImg(monitorData.TPLJ)
                                    "
                                    @click="getImgDetail(monitorData.TPLJ)"
                                    fit="cover"
                                    style="width: 277px; height: 100%"
                                    class="pointer"
                                >
                                    <div
                                        slot="error"
                                        style="width: 100%; height: 100%"
                                    >
                                        <SnNoData width="277px"></SnNoData>
                                    </div>
                                </el-image>
                                <div class="bhMsg">
                                    <div class="basicItem">
                                        <div class="name">采集人:</div>
                                        <div
                                            class="desc ellipsis"
                                            :class="{
                                                empty: !btbhJbxxData.CJRMC,
                                            }"
                                        >
                                            {{ monitorData.CJRMC | strictNull }}
                                        </div>
                                    </div>
                                    <div class="basicItem">
                                        <div class="name">评估状态:</div>
                                        <div
                                            class="desc ellipsis"
                                            :class="{
                                                empty: !btbhJbxxData.PGMC,
                                            }"
                                        >
                                            {{ monitorData.PGMC | strictNull }}
                                        </div>
                                    </div>
                                    <div class="basicItem">
                                        <div class="name">现场描述:</div>
                                        <div
                                            class="desc ellipsis"
                                            :class="{
                                                empty: !btbhJbxxData.XCMS,
                                            }"
                                            :title="monitorData.XCMS"
                                        >
                                            {{ monitorData.XCMS | strictNull }}
                                        </div>
                                    </div>
                                    <div class="basicItem">
                                        <div class="name">采集时间:</div>
                                        <div
                                            class="desc ellipsis"
                                            :class="{
                                                empty: !btbhJbxxData.CJSJ,
                                            }"
                                        >
                                            {{ monitorData.CJSJ | strictNull }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="timePart" v-if="listAry.length">
                                <TimeLine
                                    ref="timeLine"
                                    :listAry="listAry"
                                    timeKey="CJSJ"
                                    @handleTimeLine="handleTimeLine"
                                ></TimeLine>
                            </div>
                            <el-pagination
                                small
                                class="pages"
                                @current-change="handleCurrentChange"
                                :current-page="pageIndex"
                                :page-size="pageSize"
                                :total="total"
                                layout="prev, pager, next"
                            >
                            </el-pagination>
                        </div>
                        <NoMonitor v-else text="暂未监测"></NoMonitor>
                    </div>
                    <NoMonitor v-else text="暂未监测"></NoMonitor>
                </div>
            </basic-module>
        </div>
        <ViewerAlert ref="myImgList" :imgList="imgList"></ViewerAlert>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import BoxTitle from "@views/big_screen/comp/BoxTitle.vue"; //标题
import saveState from "../comp/saveState";
import SelectTimeTab from "@views/big_screen/comp/SelectTimeTab";
import picSelect from "../comp/picSelect";
import NoMonitor from "@views/big_screen/comp/NoMonitor";
import TimeLine from "@views/big_screen/comp/TimeLine.vue"; //时间线
import ViewerAlert from "@comp/ViewerAlert.vue"; //大图查看

export default {
    name: "",
    components: {
        BoxTitle,
        saveState,
        SelectTimeTab,
        picSelect,
        NoMonitor,
        TimeLine,
        ViewerAlert,
    },
    props: {},
    data() {
        return {
            imgList: [],
            // 总体保存状况
            stateName: "总体保存状况",
            // 基本信息
            btbhJbxxData: {},
            // 病害监测
            selectData: {
                state: true,
                width: "120",
                list: [],
                label: "JCWZMC",
                value: "JCWZID",
                clearable: false,
                defaultValue: "",
            },
            currentJcwz: "",
            pageIndex: 1,
            pageSize: 10,
            total: 20,
            monitorData: {},
            listAry: [],
        };
    },
    computed: {
        ...mapGetters(["btbhDetail", "btbhYcys", "btbhBhjcShow", "jczlFyNum"]),
    },
    filters: {
        strictNull(value) {
            if (value === null || value === undefined || value === "") {
                return "暂无数据";
            }
            return value;
        },
    },
    watch: {
        jczlFyNum: {
            immediate: true,
            handler() {
                this.stateName = this.common.getFyName(this.fany.ztbczt);
            },
        },
        btbhDetail: {
            immediate: true,
            deep: true,
            handler() {
                this.setStateBtbh({
                    btbhBhjcShow: 0,
                });
                this.getJbxxData();
                this.getJcwzList();
                this.getJcsjData();
            },
        },
        currentJcwz() {
            this.getJcsjData();
        },
    },
    mounted() {},
    methods: {
        ...mapActions(["getBtbhDetail", "getBhwzAndCjsj", "getBhJcsjData"]),
        ...mapMutations({
            setStateBtbh: "SET_STATE_BTBH",
        }),
        handleClose() {
            this.setStateBtbh({
                btbhPanelNum: 0, // 右侧页面切换
                btbhDetail: null, // 被点击的病害
                btbhYcys: null,
            });
        },
        async getJbxxData() {
            let res = await this.getBtbhDetail({
                bhid: this.btbhDetail.ID,
            });
            this.btbhJbxxData = res;
            if (this.btbhJbxxData.ZT_FIRST == "无") {
                this.btbhJbxxData.ZT_FIRST = "未监测";
            }
        },
        async getJcwzList() {
            let res = await this.getBhwzAndCjsj({
                bhid: this.btbhDetail.ID,
            });
            this.selectData.list = res;
            this.selectData.defaultValue = res.length > 0 ? res[0].JCWZID : "";
            this.currentJcwz = res.length > 0 ? res[0].JCWZID : "";
        },
        getSelectItem(item) {
            this.currentJcwz = item.JCWZID;
        },
        async getJcsjData() {
            let res = await this.getBhJcsjData({
                jcwzid: this.currentJcwz,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            });
            this.total = res.COUNT;
            this.listAry = res.RESULT;
            if (this.listAry.length) {
                this.monitorData = this.listAry[0];
            }
        },
        // 点击圆圈
        handleTimeLine(obj, index) {
            this.monitorData = obj;
        },
        handleCurrentChange(page) {
            this.pageIndex = page;
            this.getJcsjData();
        },
        blowUp() {
            this.setStateBtbh({
                btbhBhjcShow: Math.random(),
            });
        },
        //日常点位里的 图片 或者视频
        getImgDetail(src) {
            this.imgList = [
                {
                    alt: "",
                    src: src,
                    thumbnail: this.common.getThumbnailImg(src),
                },
            ];
            if (this.imgList.length) {
                this.$refs.myImgList.showViewer();
            }
        },
    },
};
</script>
<style lang="scss"></style>
<style scoped lang="scss">
.rightPart {
    width: 100%;
    height: 100%;
    padding: 15px 0;
    // 总体保存状态
    .ztbcztPart {
        height: 92px;
        padding: 0 24px;
        margin-top: 15px;
    }
    // 基本信息
    .jbxxPart {
        margin-top: 7px;
        height: 287px;
        .jbxxCtn {
            display: flex;
            width: 100%;
            height: 100%;
            .bhPic {
                width: 335px;
                height: 100%;
            }
            .bhMsg {
                flex: 1;
                padding-left: 10px;
                .basicItem {
                    width: 100%;
                    margin-top: 14px;
                    &:nth-child(1) {
                        margin-top: 0;
                    }
                    .basicItemOne {
                        width: calc(50% - 4px);
                    }
                    .name {
                        height: 28px;
                        line-height: 28px;
                        text-align: left;
                        color: rgba(86, 115, 162, 1);
                    }
                    .desc {
                        width: 100%;
                        min-height: 35px;
                        line-height: 35px;
                        text-align: left;
                        background: rgba(26, 48, 89, 0.3);
                        color: #d7eaf5;
                        padding-left: 10px;
                        font-size: 16px;
                    }
                    .check {
                        overflow: hidden;
                        line-height: 30px;
                        font-size: 14px;
                        height: 113px;
                    }
                }
            }
        }
    }
    // 病害监测
    .bhjc-part {
        height: 440px;
        .bhjc-ctn {
            height: 100%;
            .bhwzList {
                position: absolute;
                top: -30px;
                right: -15px;
            }
            .fdBtn {
                position: absolute;
                top: -33px;
                left: 90px;
                cursor: pointer;
            }
            .bhjc-img {
                height: 290px;
                .bhMsg {
                    width: calc(100% - 277px);
                    flex: 1;
                    padding-left: 10px;
                    .basicItem {
                        width: 100%;
                        margin-top: 14px;
                        &:nth-child(1) {
                            margin-top: 0;
                        }
                        .basicItemOne {
                            width: calc(50% - 4px);
                        }
                        .name {
                            height: 28px;
                            line-height: 28px;
                            text-align: left;
                            color: rgba(86, 115, 162, 1);
                        }
                        .desc {
                            min-height: 35px;
                            line-height: 35px;
                            text-align: left;
                            background: rgba(26, 48, 89, 0.3);
                            color: #d7eaf5;
                            padding-left: 10px;
                            font-size: 16px;
                        }
                        .check {
                            overflow: hidden;
                            line-height: 30px;
                            font-size: 14px;
                            height: 113px;
                        }
                    }
                }
            }
            .timePart {
                margin-top: 10px;
            }
            .pages {
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
    }
}
</style>
