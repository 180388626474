// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@image/bigScreen/tea_culture/tea_use_bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".leftPart[data-v-5dc8bf7d]{padding:15px 0}.leftPart[data-v-5dc8bf7d],.leftPart .cytCtn[data-v-5dc8bf7d]{width:100%;height:100%}.leftPart .cytCtn .condition[data-v-5dc8bf7d]{position:absolute;right:0;top:-38px}.leftPart .cytCtn .cytShowBox[data-v-5dc8bf7d]{width:100%;margin:10px 0 10px 0;height:calc(25% - 10px);display:flex}.leftPart .cytCtn .cytShowBox .pic-part[data-v-5dc8bf7d]{width:40%;height:100%;background:rgba(26,48,89,.22)}.leftPart .cytCtn .cytShowBox .pic-part .photo-box[data-v-5dc8bf7d]{width:calc(100% - 40px);margin:5px 20px 0 20px}.leftPart .cytCtn .cytShowBox .pic-part .photo-box .img[data-v-5dc8bf7d]{width:100%;height:100%}.leftPart .cytCtn .cytShowBox .pic-part .information-box[data-v-5dc8bf7d]{width:calc(100% - 40px);height:32px;margin:5px 20px 20px 20px;background:rgba(53,80,124,.3);display:flex;justify-content:space-between;color:#bdd7ec;line-height:32px}.leftPart .cytCtn .cytShowBox .text-part[data-v-5dc8bf7d]{width:calc(60% - 10px);height:100%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");margin-left:10px}.leftPart .cytCtn .cytShowBox .text-part .title[data-v-5dc8bf7d]{width:67px;height:33px;color:#daeeff;margin:10px 0 0 10px;font-size:18px}.leftPart .cytCtn .cytShowBox .text-part .text[data-v-5dc8bf7d]{width:100%;padding:0 0 10px 10px;color:#a8c8ff;font-size:14px}", ""]);
// Exports
module.exports = exports;
