<template>
    <div class="rightPart scrollbar-hidden">
        <BoxTitle
            :title="titleName"
            :backBtn="false"
            :downloadBtn="true"
            @handleDownloadClick="handleDownloadClick"
        ></BoxTitle>
        <div class="jcsb-border">
            <div
                class="jcsb-item"
                :class="currentJcsbId === item.id ? 'current-jcsb' : ''"
                v-for="item in jcsbList"
                :key="'jcsb' + item.id"
                @click="handleJcsbClick(item)"
            >
                {{ item.label }}
            </div>
        </div>
        <div class="jcsj-module">
            <!-- 物候监测 -->
            <basic-module :text="common.getFyName(fany.whjc)">
                <div slot="basic-module" class="jcsj-bar-chart">
                    <!-- <SnNoData
                        :noDataState="true"
                        noDataText="暂无数据"
                        v-if="!isChartShow"
                    ></SnNoData> -->
                    <div class="color-part flex">
                        <div
                            class="color-item flex-y-center"
                            v-for="(item, index) in colorList"
                            :key="index"
                        >
                            <div
                                class="color-block"
                                :style="{ background: item.color }"
                            ></div>
                            <div class="label">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="echart" :id="echartPart.id"></div>
                    <!-- <lineMarkerChart
                        v-show="isChartShow"
                        :chartData="whjcChartData"
                    ></lineMarkerChart> -->
                </div>
            </basic-module>
        </div>
        <div class="jbxxPart">
            <!-- 基本信息 -->
            <basic-module :text="common.getFyName(fany.jlfx)">
                <div slot="basic-module" class="screenCtn">
                    <div class="timePart bs-datepicker jcsj-search">
                        <el-date-picker
                            size="mini"
                            style="width: 120px"
                            v-model="nfValue"
                            type="month"
                            value-format="yyyy-MM"
                            popper-class="bs-select-panel"
                            placeholder="选择月"
                            :clearable="false"
                            @change="getBaseInfo()"
                        >
                        </el-date-picker>
                    </div>
                    <div class="carouselPart carouselArrowShow">
                        <el-carousel
                            height="240px"
                            :autoplay="false"
                            indicator-position="none"
                            :arrow="baseInfoList.length > 1 ? 'hover' : 'never'"
                        >
                            <el-carousel-item
                                v-for="(item, index) in baseInfoList"
                                :key="index"
                                class=""
                            >
                                <div class="carouselItem pointer">
                                    <el-image
                                        @click="previewFile()"
                                        :src="item.TPLJ"
                                        fit="cover"
                                        class="pointer"
                                        style="width: 100%; height: 240px"
                                    >
                                        <div
                                            slot="error"
                                            style="width: 100%; height: 100%"
                                        >
                                            <SnNoData width="150px"></SnNoData>
                                        </div>
                                    </el-image>
                                    <div class="time">
                                        {{ item.CJSJ }}
                                    </div>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                        <SnNoData
                            :noDataState="true"
                            noDataText="暂无数据"
                            v-if="!baseInfoList.length"
                        ></SnNoData>
                    </div>
                </div>
            </basic-module>
        </div>
        <div class="jlfx-module">
            <!-- 结论分析 -->
            <basic-module :text="common.getFyName(fany.jlfx)">
                <div slot="basic-module" class="jlfx-border">
                    <div class="jlfx-label">综合评价</div>
                    <div class="jlfx-text">{{ zhpjText | strictNull }}</div>
                    <div class="jlfx-label">保护建议</div>
                    <div class="jlfx-text">{{ bhjyText | strictNull }}</div>
                </div>
            </basic-module>
        </div>
        <ViewerAlert ref="myImgList" :imgList="imgList"></ViewerAlert>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import BoxTitle from "@views/big_screen/comp/BoxTitle.vue"; //标题
import NoMonitor from "@views/big_screen/comp/NoMonitor.vue"; //标题
import lineMarkerChart from "../comp/line_marker_chart.vue";
import ViewerAlert from "@comp/ViewerAlert.vue"; //大图查看
export default {
    name: "",
    components: {
        BoxTitle,
        NoMonitor,
        lineMarkerChart,
        ViewerAlert,
    },
    data() {
        return {
            titleName: "物侯监测服务",
            jcsbList: [
                {
                    id: "f31070e8-824d-11eb-b3ce-000c2977b7fd",
                    label: "芒景上下寨-芒洪古茶林物候监测站",
                },
                {
                    id: "f3107002-824d-11eb-b3ce-000c2977b7fd",
                    label: "大平掌古茶林物候监测站",
                },
            ],
            currentJcsbId: "f31070e8-824d-11eb-b3ce-000c2977b7fd",
            zhpjText: "",
            bhjyText: "",

            // 基本信息
            baseInfoList: [],
            bgxzXdlj: "",
            whjcChartData: {
                legendData: [],
                seriesData: [],
            },
            nfValue: this.common.getTimeYm(),
            isChartShow: false,
            imgList: [],
            echartPart: {
                id: "whjc" + Math.random(),
                echart: null,
            },
            colorList: [
                {
                    name: "发芽期",
                    color: "#3bdbd5",
                },
                {
                    name: "展叶期",
                    color: "#66cc66",
                },
                {
                    name: "开花期",
                    color: "#f95050",
                },
                {
                    name: "果期",
                    color: "#e556f6",
                },
                {
                    name: "落叶期",
                    color: "#ffcc66",
                },
                {
                    name: "休眠期",
                    color: "#aecff0",
                },
            ],
        };
    },
    filters: {
        strictNull(value) {
            if (value === null || value === undefined || value === "") {
                return "暂无数据";
            }
            return value;
        },
    },
    mounted() {
        this.getJlbg();
        this.getWhjcTjt();
        this.getBaseInfo();
    },
    methods: {
        ...mapActions([
            "GetWhjcTjt", // 物候监测统计图
            "GetJlbg", // 获取结论报告
            "GetWhjcPic",
        ]),
        // 设备按钮点击
        handleJcsbClick(val) {
            if (val.id === this.currentJcsbId) return;
            this.nfValue = this.common.getTimeYm();
            this.currentJcsbId = val.id;
            this.getWhjcTjt();
            this.getBaseInfo();
        },
        // 统计图
        async getWhjcTjt() {
            let sum = 0;
            this.whjcChartData = {
                legendData: [],
                seriesData: [],
            };
            const result = await this.GetWhjcTjt({
                jcd: this.currentJcsbId,
            });
            this.whjcTjtData = result;
            if (result && result.length > 0) {
                let legendData = [];
                let seriesData = [];
                result.forEach((item) => {
                    legendData.push(item.NF.toString());
                    if (item.RQDTO) {
                        sum +=
                            Number(item.RQDTO.FYQ) +
                            Number(item.RQDTO.ZYQ) +
                            Number(item.RQDTO.KHQ) +
                            Number(item.RQDTO.GQ) +
                            Number(item.RQDTO.LYQ) +
                            Number(item.RQDTO.XMQ);
                        let obj = {
                            name: item.NF,
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            showAllSymbol: true,
                            symbol: "rect",
                            symbolSize: 8,
                            lineStyle: {
                                width: 3,
                            },
                            data: [
                                [item.RQDTO.FYQ, "发芽期"],
                                [item.RQDTO.ZYQ, "展叶期"],
                                [item.RQDTO.KHQ, "开花期"],
                                [item.RQDTO.GQ, "果期"],
                                [item.RQDTO.LYQ, "落叶期"],
                                [item.RQDTO.XMQ, "休眠期"],
                            ],
                        };
                        seriesData.push(obj);
                    }
                });
                this.whjcChartData = {
                    legendData,
                    seriesData,
                };
            }
            if (sum === 0) {
                this.isChartShow = false;
            } else {
                this.isChartShow = true;
            }
            this.initEchart();
        },
        initEchart() {
            let list = [
                {
                    time: "2020",
                    name: "果树1",
                    fyqStart: "01-01",
                    fyqEnd: "02-01",
                    zyqStart: "03-01",
                    zyqEnd: "04-01",
                    khqStart: "05-01",
                    khqEnd: "06-01",
                    gqStart: "07-01",
                    gqEnd: "08-01",
                    lyqStart: "09-01",
                    lyqEnd: "10-01",
                    xmqStart: "11-01",
                    xmqEnd: "12-01",
                },
                {
                    time: "2021",
                    name: "果树2",
                    fyqStart: "01-04",
                    fyqEnd: "02-04",
                    zyqStart: "03-02",
                    zyqEnd: "04-02",
                    khqStart: "05-02",
                    khqEnd: "06-02",
                    gqStart: "07-02",
                    gqEnd: "08-02",
                    lyqStart: "09-02",
                    lyqEnd: "10-02",
                    xmqStart: "11-02",
                    xmqEnd: "12-02",
                },
            ];
            let xAxisData = [];
            list.map((item) => {
                xAxisData.push(item.fyqStart);
                xAxisData.push(item.fyqEnd);
                xAxisData.push(item.zyqStart);
                xAxisData.push(item.zyqEnd);

                xAxisData.push(item.khqStart);
                xAxisData.push(item.khqEnd);
                xAxisData.push(item.gqStart);
                xAxisData.push(item.gqEnd);

                xAxisData.push(item.lyqStart);
                xAxisData.push(item.lyqEnd);
                xAxisData.push(item.xmqStart);
                xAxisData.push(item.xmqEnd);
            });
            xAxisData = [...new Set(xAxisData)].sort();

            var seriesData1 = [
                ["01-01", 1, "", "", "发芽期"],
                ["02-01", 1, "30天", "发芽期"],
                ,
                ["03-01", 1, "", "", "展叶期"],
                ["04-01", 1, "30天", "展叶期"],
                ,
                ["05-01", 1, "", "", "开花期"],
                ["06-01", 1, "30天", "开花期"],
                ,
                ["07-01", 1, "", "", "果期"],
                ["08-01", 1, "30天", "果期"],
                ,
                ["09-01", 1, "", "", "落叶期"],
                ["10-01", 1, "30天", "落叶期"],
                ,
                ["11-01", 1, "", "", "休眠期"],
                ["12-01", 1, "30天", "休眠期"],
            ];
            var seriesData2 = [
                ["01-04", 2, "", "", "发芽期"],
                ["02-04", 2, "30天", "发芽期"],
                ,
                ["03-02", 2, "", "", "展叶期"],
                ["04-02", 2, "30天", "展叶期"],
                ,
                ["05-02", 2, "", "", "开花期"],
                ["06-02", 2, "30天", "开花期"],
                ,
                ["07-02", 2, "", "", "果期"],
                ["08-02", 2, "30天", "果期"],
                ,
                ["09-02", 2, "", "", "落叶期"],
                ["10-02", 2, "30天", "落叶期"],
                ,
                ["11-02", 2, "", "", "休眠期"],
                ["12-02", 2, "30天", "休眠期"],
            ];

            let option = {
                tooltip: {
                    show: false,
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                        label: {
                            backgroundColor: "#4c647c",
                        },
                    },
                },
                grid: {
                    left: 40,
                    right: 20,
                    bottom: 50,
                    top: 40,
                },
                xAxis: [
                    {
                        type: "category",
                        boundaryGap: false, //坐标轴两边留白
                        data: xAxisData,
                        axisLabel: {
                            interval: 0, //设置为 1，表示『隔一个标签显示一个标签』
                            rotate: 40,
                            margin: 15,
                            textStyle: {
                                color: "#4c647c",
                                fontStyle: "normal",
                                fontFamily: "微软雅黑",
                                fontSize: 14,
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#4c647c",
                                opacity: 0.2,
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        minInterval: 1,
                        axisLabel: {
                            textStyle: {
                                color: "#fff",
                                fontStyle: "normal",
                                fontSize: 14,
                            },
                            formatter: (params) => {
                                let num = 0;
                                switch (params) {
                                    case 0:
                                        num = "";
                                        break;
                                    case 1:
                                        num = 2021;
                                        break;
                                    case 2:
                                        num = 2022;
                                        break;
                                }
                                return num;
                            },
                        },
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ["#000"],
                                opacity: 0.08,
                                type: "dotted", //'dotted'虚线 'solid'实线
                            },
                        },
                        // data: [
                        //     "发芽期",
                        //     "展叶期",
                        //     "开花期",
                        //     "果期",
                        //     "落叶期",
                        //     "休眠期",
                        // ],
                    },
                ],
                series: [
                    {
                        name: "折线图",
                        type: "line",
                        data: seriesData1,
                        symbol: "none",
                        itemStyle: {
                            color: "gray",
                        },
                    },
                    {
                        name: "折线图",
                        type: "line",
                        data: seriesData2,
                        symbol: "none",
                        itemStyle: {
                            color: "gray",
                        },
                    },
                    {
                        name: "柱状图",
                        type: "bar",
                        data: seriesData1,
                        tooltip: {
                            show: false,
                        },
                        animation: false,
                        barWidth: 1,
                        label: {
                            normal: {
                                show: true, //开启显示
                                position: "top", //在上方显示
                                formatter: (params) => {
                                    if (!params.value) {
                                        return;
                                    }
                                    return params.value[2];
                                },
                                textStyle: {
                                    color: "#fff",
                                    fontSize: 12,
                                },
                            },
                        },
                        itemStyle: {
                            lineStyle: {
                                width: 2,
                                type: "dotted",
                            },
                            color: (params) => {
                                if (!params.value) {
                                    return;
                                }
                                let name = params.value[3] || params.value[4];
                                let color = "";
                                switch (name) {
                                    case "发芽期":
                                        color = this.colorList[0].color;
                                        break;
                                    case "展叶期":
                                        color = this.colorList[1].color;
                                        break;
                                    case "开花期":
                                        color = this.colorList[2].color;
                                        break;
                                    case "果期":
                                        color = this.colorList[3].color;
                                        break;
                                    case "落叶期":
                                        color = this.colorList[4].color;
                                        break;
                                    case "休眠期":
                                        color = this.colorList[5].color;
                                        break;
                                }
                                return color;
                            },
                        },
                    },
                    {
                        name: "柱状图",
                        type: "bar",
                        data: seriesData2,
                        tooltip: {
                            show: false,
                        },
                        animation: false,
                        barWidth: 1,
                        label: {
                            normal: {
                                show: true, //开启显示
                                position: "top", //在上方显示
                                formatter: function (para) {
                                    return para.value[2];
                                },
                                textStyle: {
                                    color: "#fff",
                                    fontSize: 12,
                                },
                            },
                        },
                        itemStyle: {
                            // color: "#66cc99",
                            lineStyle: {
                                width: 1,
                                type: "dotted",
                            },
                            color: (params) => {
                                if (!params.value) {
                                    return;
                                }
                                let name = params.value[3] || params.value[4];
                                let color = "";
                                switch (name) {
                                    case "发芽期":
                                        color = this.colorList[0].color;
                                        break;
                                    case "展叶期":
                                        color = this.colorList[1].color;
                                        break;
                                    case "开花期":
                                        color = this.colorList[2].color;
                                        break;
                                    case "果期":
                                        color = this.colorList[3].color;
                                        break;
                                    case "落叶期":
                                        color = this.colorList[4].color;
                                        break;
                                    case "休眠期":
                                        color = this.colorList[5].color;
                                        break;
                                }
                                return color;
                            },
                        },
                    },
                ],
            };

            this.echartPart.echart = this.$echarts.dispose(
                document.getElementById(this.echartPart.id)
            );
            this.echartPart.echart = this.$echarts.init(
                document.getElementById(this.echartPart.id)
            );
            this.echartPart.echart.setOption(option);
        },
        // 获取监测报告
        async getJlbg() {
            this.zhpjText = "";
            this.bhjyText = "";
            this.bgxzXdlj = "";
            const result = await this.GetJlbg({
                lx: "2",
            });
            if (!result) return;
            this.zhpjText = result.ZHPJ;
            this.bhjyText = result.BHJY;
            this.bgxzXdlj = result.BGLJ;
        },
        // 报告下载
        handleDownloadClick() {
            if (!this.bgxzXdlj) {
                this.common.showMsg("暂无报告", "warning");
                return;
            }
            window.open("http://" + this.bgxzXdlj);
        },
        // 获取基本信息
        async getBaseInfo() {
            let result = await this.GetWhjcPic({
                jcd: this.currentJcsbId,
                rq: this.nfValue,
            });

            this.baseInfoList = result;
        },
        //查看上传附件
        previewFile() {
            let ary = [];
            this.baseInfoList.map((item) => {
                let obj = {
                    alt: item.CJSJ,
                    src: item.TPLJ,
                    // thumbnail: this.common.getThumbnailImg(item.TPLJ),
                };
                ary.push(obj);
            });
            this.imgList = Array.from(ary);
            if (this.imgList.length) {
                this.$refs.myImgList.showViewer();
            }
        },
    },
};
</script>
<style scoped lang="scss">
.rightPart {
    width: 100%;
    height: 100%;
    padding: 15px 0;
    .jcsb-border {
        display: flex;
        padding-left: 8px;
        .jcsb-item {
            // width: 120px;
            height: 35px;
            line-height: 35px;
            padding: 0 10px;
            border-radius: 5px;
            text-align: center;
            font-size: 16px;
            color: #a8c8ff;
            background: #35507c;
            margin-left: 15px;
            margin-top: 14px;
            cursor: pointer;
        }
        .current-jcsb {
            color: #0b1b30;
            background: #ffcd80;
            cursor: default;
        }
    }
    .sssj-module {
        width: 100%;
        .sssj-border {
            display: flex;
            flex-flow: row wrap;
            .sssj-item {
                width: 150px;
                height: 77px;
                margin-right: 18px;
                background: rgba(26, 48, 89, 0.3);
                .sssj-top {
                    display: flex;
                    width: 100%;
                    height: 50px;
                }
                .sssj-bottom {
                    display: flex;
                    height: 27px;
                    line-height: 27px;
                    padding: 0 9px;
                    color: #a8c8ff;
                }
            }
            .sssj-item:nth-child(4) {
                margin-bottom: 15px;
                margin-right: 0;
            }
        }
    }
    .jcsj-module {
        width: 100%;
        height: 360px;
        .jcsj-bar-chart {
            position: relative;
            width: 100%;
            height: 100%;
            .color-part {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                .color-item {
                    height: 10px;
                    margin-right: 10px;
                    .color-block {
                        width: 10px;
                        height: 10px;
                        margin-right: 4px;
                    }
                    .label {
                        color: #fff;
                        font-size: 12px;
                    }
                }
            }
            .echart {
                width: 100%;
                height: 100%;
            }
        }
    }
    .jlfx-module {
        width: 100%;
        .jlfx-border {
            width: 100%;
            .jlfx-label {
                width: 100%;
                color: #5673a2;
            }
            .jlfx-text {
                width: 100%;
                line-height: 24px;
                margin: 5px 0;
                padding: 9px;
                text-align: justify;
                font-size: 16px;
                font-weight: 500;
                color: #bdd7ec;
                background: rgba(26, 48, 89, 0.3);
            }
        }
    }

    // 基本信息
    .jbxxPart {
        width: 100%;
        height: 310px;
        .screenCtn {
            width: 100%;
            height: 100%;
            position: relative;
            .carouselPart {
                width: 100%;
                height: 240px;
                .carouselItem {
                    position: relative;
                    width: 100%;
                    height: 240px;
                    .time {
                        position: absolute;
                        right: 10px;
                        bottom: 10px;
                        color: #fff;
                        background: rgba(11, 27, 48, 0.8);
                        border-radius: 4px;
                        padding: 2px 4px;
                    }
                }
            }
            .timePart {
                position: absolute;
                right: 0;
                top: -50px;
            }
        }
    }
}
</style>
