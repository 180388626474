<template>
    <div class="dialogCtn scrollbar-hidden">
        <!-- 第三方的监控 -->
        <!-- <live-player
            :video-url="address"
            live="true"
            style="width: 394px; height: 500px"
            :poster="poster"
            @timeupdate="timeupdate"
        ></live-player> -->
        <video
            v-show="!detail.PD"
            id="vidoeBox"
            controls
            muted
            style="width: 1341px; height: 894px"
        ></video>
        <!-- 公司的监控 -->
        <!-- width="100%"
                height="100%" -->
        <iframe
            v-show="detail.PD"
            scrolling="no"
            width="1341px"
            height="894px"
            frameborder="0"
            style="position:relative;top:-26px;"
            :src="src"
        ></iframe>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import flvjs from "flv.js";
export default {
    components: {},
    name: "",
    data() {
        return {
            poster: require("@image/bigScreen/icon/sxtPoster.gif"),
            player: null,
            num: 0,
            address: "",
            src: "",
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {
        detail: {
            Type: Object,
            default: () => {
                let obj = {
                    AZDJD: "",
                    AZDWD: "",
                    SBMC: "",
                    RTMP: "",
                    PD: "",
                };
                return obj;
            },
        },
        width: {
            Type: String,
            default: () => {
                return "1341px";
            },
        },
    },
    watch: {
        detail: {
            immediate: false,
            handler() {
                this.getVideo();
            },
            deep: true,
        },
    },
    mounted() {
        this.getVideo();
    },
    destroyed() {
        this.player.pause();
        this.player.unload();
        this.player.detachMediaElement();
        this.player.destroy();
        this.player = null;
    },
    methods: {
        ...mapActions([]),
        ...mapMutations({
            SET_JCZLSXTSTATE: TYPES.SET_JCZLSXTSTATE,
        }),
        error(error) {},
        message(msg) {},
        timeupdate(msg) {
            if (!this.num) {
                this.num++;
                this.endTime = this.common.getTimeYmdhms();
                this.longTime =
                    new Date(this.endTime).getTime() -
                    new Date(this.startTime).getTime();
            }
        },
        getVideo() {
            this.getUrl().then((url) => {
                this.num = 0;
                this.startTime = this.common.getTimeYmdhms();
                let hostname = "";
                switch (window.location.hostname) {
                    // 监测中心 外网
                    // 公司
                    case "jmsgcl.geo-compass.com":
                        hostname = `http://jmsjc.jmsclwh.cn`;
                        break;
                    case "jmsjc.jmsclwh.cn":
                        hostname = `https://jmsjc.jmsclwh.cn`;
                        break;
                    // 监测中心 内网
                    case "192.168.23.236":
                    //开发环境
                    default:
                        hostname = `http://192.168.23.236`;
                        break;
                }
                if (this.detail.PD) {
                    this.SET_JCZLSXTSTATE(false);
                    this.src = `${hostname}/mobile/index.html?url=${url}&v=1`;
                } else {
                    this.SET_JCZLSXTSTATE(true);
                    if (this.player) {
                        this.clearDom(url);
                    } else {
                        this.goDom(url);
                    }
                }
            });
        },
        goDom(url) {
            this.player = flvjs.createPlayer({
                type: "flv",
                isLive: true,
                hasAudio: false,
                url: url,
            });
            this.initEvent();
        },
        initEvent() {
            let dom = document.getElementById(`vidoeBox`);
            this.player.attachMediaElement(dom);
            this.player.load();
            this.player.play();
            // 【flvjs播放器事件侦听】
            this.player.on(flvjs.Events.LOADING_COMPLETE, (res) => {
                console.log("加载完成", res);
            });
            let startTime = this.common.getTimeYmdhms();

            this.player.on(flvjs.Events.MEDIA_INFO, (res) => {
                let endTime = this.common.getTimeYmdhms();
                let time =
                    new Date(endTime).getTime() - new Date(startTime).getTime();
                let str = `
                设备名称${this.detail.PD}:
                开始时间${startTime};
                结束时间${endTime};
                时长${time}毫秒`;
                console.log(
                    "%c 🍩 媒体信息-加载完成: ",
                    "font-size:20px;background-color: #465975;color:#fff;",
                    str
                );
                // console.log("媒体信息-加载完成", res, );
            });
            this.player.on(flvjs.Events.METADATA_ARRIVED, (res) => {
                // console.log("获取元数据", res);
            });
            this.player.on(flvjs.Events.RECOVERED_EARLY_EOF, (res) => {
                console.log("恢复早期EOF", res);
            });
            this.player.on(flvjs.Events.SCRIPTDATA_ARRIVED, (res) => {
                // console.log("获取到脚本数据", res);
            });
            this.player.on(
                flvjs.Events.ERROR,
                (errorType, errorDetail, errorInfo) => {
                    console.log("视频错误信息回调");
                    console.log("errorType:", errorType);
                    console.log("errorDetail:", errorDetail);
                    console.log("errorInfo:", errorInfo);
                }
            );

            // 【重要事件监听】http请求建立好后，该事件会一直监听flvjs实例
            this.player.on(flvjs.Events.STATISTICS_INFO, (res) => {
                // console.log("请求数据信息", res);
            });
        },
        clearDom(url) {
            this.player.pause();
            this.player.unload();
            this.player.detachMediaElement();
            this.player.destroy();
            this.player = null;
            this.goDom(url);
        },
        async getUrl() {
            let url = "";
            if (this.detail.PD) {
                switch (window.location.hostname) {
                    // 监测中心 外网
                    // 公司
                    case "jmsgcl.geo-compass.com":
                        url = `http://jmsjc.jmsclwh.cn/flv/live/${this.detail.PD}.flv`;
                        // url = `http://jmsjc.jmsclwh.cn/live/${this.detail.PD}.m3u8`;
                        break;
                    case "jmsjc.jmsclwh.cn":
                        url = `https://jmsjc.jmsclwh.cn/flv/live/${this.detail.PD}.flv`;
                        // url = `https://jmsjc.jmsclwh.cn/live/${this.detail.PD}.m3u8`;
                        break;
                    // 监测中心 内网
                    case "192.168.23.236":
                    //开发环境
                    default:
                        url = `http://192.168.23.235:53372/live/${this.detail.PD}.flv`;
                        // url = `http://192.168.23.235:80/live/${this.detail.PD}.m3u8`;
                        break;
                }
                console.log("%c Line:244 🥖 公司", "color:#42b983", url);
            } else {
                url = await this.getUrlFlv();
                console.log("%c Line:244 🥖 第三方", "color:#42b983", url);
            }
            return url;
        },
        async getUrlFlv() {
            let result = await this.getUrlFlvAgain(this.detail);
            let url = "";
            if (result.code == -1 || result.code == 100) {
                result = this.getUrlFlvAgain(this.detail);
            } else {
                url = result.data.https_flv.url;
            }
            return url;
        },
        async getUrlFlvAgain(item) {
            let result = await this.common.getFlvUrl(item);
            return result;
        },
    },
};
</script>
<style scoped lang="scss">
.dialogCtn {
    width: 100%;
    height: 100%;
}
</style>
