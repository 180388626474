var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tablePart"},[(_vm.tableData.length)?_c('div',{staticClass:"tableList",style:(("height:" + _vm.maxHeight + "px"))},[_c('div',{staticClass:"tableHeader flex-between"},_vm._l((_vm.tableColums),function(item,index){return _c('div',{key:index,staticClass:"headerItem",style:({
                    textAlign: index > 0 ? 'left' : 'left',
                    width: item.width + 'px',
                })},[_vm._v(" "+_vm._s(item.label)+" ")])}),0),_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.handleScroll),expression:"handleScroll"}],staticClass:"tableCtn scrollbar-hidden",attrs:{"infinite-scroll-immediate":false}},_vm._l((_vm.tableData),function(item,index){return _c('div',{key:index,staticClass:"ctnItem pointer flex-between",style:({
                    background: _vm.activeNum === index ? '#1a3059' : '',
                }),on:{"click":function($event){return _vm.handleTableRowClick(item, index)}}},_vm._l((_vm.tableColums),function(itemInner,indexInner){return _c('div',{key:indexInner,staticClass:"ellipsis",style:({
                        textAlign: indexInner > 0 ? 'left' : 'left',
                        width: itemInner.width + 'px',
                        color: itemInner.colorArr
                            ? itemInner.colorArr[
                                  Number(item[itemInner.propColorNum])
                              ]
                            : '#9AB4E3',
                    }),attrs:{"title":item[itemInner.titleProp]
                            ? item[itemInner.titleProp]
                            : item[itemInner.prop]}},[_vm._v(" "+_vm._s(item[itemInner.prop])+" ")])}),0)}),0)]):_c('SnNoData',{attrs:{"noDataState":true,"noDataText":_vm.noDataText,"fontSize":"20px"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }