<template>
    <div class="rightCtn">
        <div class="label flex-y-center"
             :class="[jczlFyNum == 0 ? '' : 'labelEndlish']">
            {{ common.getFyName(menuList[activeNum == null ? 0 : activeNum]) }}
        </div>
        <div class="rightScreenCtn flex">
            <img src="@image/bigScreen/icon/ruler.png"
                 class="ruler"
                 v-if="activeNum !== 0" />
            <div class="rightScreenCtnNoRuler"
                 v-if="activeNum !== null">
                <MonitorAll v-if="activeNum === 0"></MonitorAll>
                <DailyInpect v-if="activeNum === 1"
                             :positionStr="positionStr"></DailyInpect>
                <EcologicalEnvironment v-if="activeNum === 2"
                                       :positionStr="positionStr"></EcologicalEnvironment>
                <BuildControl v-if="activeNum === 3"
                              :positionStr="positionStr"></BuildControl>
                <OntologicalDisease v-if="activeNum === 4"
                                    :positionStr="positionStr"></OntologicalDisease>
                <ProtectProject v-if="activeNum === 5"
                                :positionStr="positionStr"></ProtectProject>
                <!-- <TeaCulture v-if="activeNum === 6"
                            :positionStr="positionStr"></TeaCulture> -->
            </div>
        </div>
    </div>
</template>
<script>
import MonitorAll from "./monitor_all"; //监测总览
import EcologicalEnvironment from "./ecological_environment"; //生态环境
import OntologicalDisease from "./ontological_disease"; //本体病害
import ProtectProject from "./protect_project"; //保护工程
import DailyInpect from "./daily_inspect"; //日常巡查
import BuildControl from "./build_control"; //建设控制
import TeaCulture from "./tea_culture"; //茶文化
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    name: "",
    components: {
        MonitorAll,
        EcologicalEnvironment,
        DailyInpect,
        OntologicalDisease,
        ProtectProject,
        BuildControl,
        TeaCulture,
    },
    props: {
        activeNum: {
            type: Number,
            default: null,
        },
        positionStr: {
            type: String,
            default: () => {
                return "";
            },
        },
    },
    data () {
        return {
            menuList: [
                {
                    name: "监测总览",
                },
                {
                    name: "日常巡查",
                },
                {
                    name: "生态环境",
                },
                {
                    name: "建设控制",
                },
                {
                    name: "本体病害",
                },
                {
                    name: "保护工程",
                },
                // {
                //     name: "茶文化",
                // },
            ],
        };
    },
    computed: {
        ...mapGetters({
            jczlFyNum: TYPES.jczlFyNum,
        }),
    },
    watch: {},
    mounted () {
        this.menuList = this.fany.menuList;
    },
    methods: {},
};
</script>
<style lang="scss">
.rightCtn {
    .rightScreenCtn .screenCtn .centerView {
        border-left: 6px solid rgba(4, 14, 30, 1);
        border-right: 6px solid rgba(4, 14, 30, 1);
        margin: 0;
    }
}
</style>
<style lang="scss" scoped>
.rightCtn {
    width: 100%;
    height: 100%;
    .label {
        width: 100%;
        height: 55px;
        border-top: 2px solid rgba(53, 80, 124, 1); //rgba(26, 56, 89, 0.3);
        border-bottom: 2px solid rgba(53, 80, 124, 1); //rgba(26, 56, 89, 0.3);
        padding-left: 100px;
        font-size: 30px;
        color: rgba(168, 200, 255, 1);
        background-color: rgba(26, 56, 89, 0.3);
    }
    .labelEndlish {
        font-size: 24px;
    }
    .rightScreenCtn {
        width: 100%;
        height: calc(100% - 55px);
        position: relative;
        background-color: rgba(26, 56, 89, 0.3);
        .ruler {
            width: 29px;
            height: calc(100% - 24px);
            position: absolute;
            left: 0;
            top: 15px;
        }
        .rightScreenCtnNoRuler {
            margin-left: 15px;
            width: 100%;
            height: 100%;
        }
    }
}
</style>
