<template>
    <div class="dialogCtn flex-between">
        <div class="popRight">
            <div class="label">发生时间</div>
            <div class="detail">
                {{ detail.FSSJ }}
            </div>
            <div class="label">火警状态</div>
            <div class="detail">
                {{ detail.JCZT == "0" ? "未解除" : "已解除" }}
            </div>
        </div>
        <div class="popRight">
            <div class="label">经度</div>
            <div class="detail">{{ detail.FSJD }}</div>
            <div class="label">纬度</div>
            <div class="detail">{{ detail.FSWD }}</div>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    components: {},
    name: "",
    data() {
        return {};
    },
    computed: {
        ...mapGetters({}),
    },
    props: {
        detail: {
            Type: Object,
            default: () => {
                let obj = {
                    FSSJ: "", //发生时间
                    JCZT: "", //状态 解除状态(0未解除、1已接触)
                    FSJD: "", //经度
                    FSWD: "", //维度
                };
                return obj;
            },
        },
    },
    watch: {},
    mounted() {},
    methods: {
        ...mapActions([]),
        ...mapMutations({}),
    },
};
</script>
<style scoped lang="scss">
.dialogCtn {
    width: 100%;
    height: 100%;
    .popLeft {
        width: calc(50% - 5px);
        height: 100%;
    }
    .popRight {
        width: calc(50% - 5px);
        height: 100%;

        .label {
            color: rgba(86, 115, 162, 1);
            font-size: 14px;
            margin-top: 10px;
        }
        .detail {
            color: rgba(168, 200, 255, 1);
            font-size: 14px;
            background: rgba(53, 80, 124, 0.2);
            padding: 4px 10px;
        }
    }
}
</style>
