<template>
    <div class="dialogCtn scrollbar-translucent">
        <div class="title" v-if="listAry.length">图片</div>
        <div class="flex-between flex-wrap">
            <div
                class="list-item"
                v-for="(item, index) in listAry"
                :key="index"
            >
                <el-image
                    @click="getImgDetail(item.CCLJ)"
                    :src="common.getThumbnailImg(item.CCLJ)"
                    fit="cover"
                    style="width: 152px; height: 130px; margin-right: 10px"
                    class="pointer"
                >
                    <div slot="error" style="width: 100%; height: 100%">
                        <SnNoData width="100px"></SnNoData>
                    </div>
                </el-image>
                <div class="name-item">
                    <div class="label">{{ item.WDMC }}</div>
                </div>
            </div>
        </div>
        <div class="title" v-if="videoList.length">视频</div>
        <div class="flex-between flex-wrap">
            <div
                class="list-item"
                v-for="(item, index) in videoList"
                :key="index"
            >
                <video
                    :src="item.CCLJ"
                    fit="cover"
                    style="width: 152px; height: 130px; margin-right: 10px"
                    class="pointer"
                    controls
                ></video>
                <div class="name-item">
                    <div class="label">{{ item.WDMC }}</div>
                </div>
            </div>
        </div>
        <ViewerAlert ref="myImgList" :imgList="imgList"></ViewerAlert>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import ViewerAlert from "@comp/ViewerAlert.vue"; //大图查看

export default {
    components: {
        ViewerAlert,
    },
    name: "",
    data() {
        return {
            imgList: [],
            listAry: [],
            videoList: [],
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {
        detail: {
            Type: Object,
            default: () => {
                let obj = {
                    ID: "",
                };
                return obj;
            },
        },
    },
    watch: {
        detail: {
            deep: true,
            immediate: true,
            handler() {
                this.getMainData();
            },
        },
    },
    mounted() {},
    methods: {
        ...mapActions(["GetGcychyfDetail"]),
        ...mapMutations({}),
        async getMainData() {
            let result = await this.GetGcychyfDetail({
                yfid: this.detail.ID,
            });
            this.listAry = [];
            this.videoList = [];
            result.PIC.map((item) => {
                let dw = item.CCLJ.substring(item.CCLJ.lastIndexOf(".") + 1);
                switch (dw) {
                    case "mp4":
                    case "MOV":
                        this.videoList.push(item);
                        break;
                    default:
                        this.listAry.push(item);
                        break;
                }
            });
        },
        //日常点位里的 图片 或者视频
        getImgDetail(src) {
            this.imgList = [
                {
                    alt: "",
                    src: src,
                    thumbnail: this.common.getThumbnailImg(src),
                },
            ];
            if (this.imgList.length) {
                this.$refs.myImgList.showViewer();
            }
        },
    },
};
</script>
<style scoped lang="scss">
.dialogCtn {
    width: 100%;
    max-height: 100%;
    .title {
        width: 50px;
        height: 20px;
        line-height: 20px;
        margin-bottom: 10px;
        font-size: 16px;
        text-align: center;
        color: #9ab4e3;
        letter-spacing: 2px;
        background: rgba(26, 48, 89, 0.6);
    }
    .list-item {
        margin-bottom: 14px;
        .name-item {
            width: calc(100% - 10px);
            padding: 0 6px;
            // background: rgba(53, 80, 124, 0.2);
            .label {
                color: rgba(86, 115, 162, 1);
                font-size: 14px;
            }
            .detail {
                color: rgba(168, 200, 255, 1);
                font-size: 14px;
            }
        }
    }
}
</style>
