// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@image/bigScreen/ecological_environment/Right/background.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".ccltjPart[data-v-52adb004],.ccltjPart .ccltjCtn[data-v-52adb004]{width:100%;height:100%}.ccltjPart .ccltjCtn[data-v-52adb004]{position:relative}.ccltjPart .ccltjCtn .condition[data-v-52adb004]{position:absolute;right:0;top:-38px}.ccltjPart .ccltjCtn .condition .line[data-v-52adb004]{position:absolute;top:50%;transform:translateY(-50%);width:522px;height:1px;background:rgba(53,80,124,.4);z-index:-1}.ccltjPart .ccltjCtn .sntq[data-v-52adb004]{position:absolute;right:0;top:0}.ccltjPart .ccltjCtn .sntq .sntqActive .circle[data-v-52adb004]{width:10px;height:10px;border-radius:50%;background:#ffcd80;margin-right:10px}.ccltjPart .ccltjCtn .sntq .sntqActive .name[data-v-52adb004]{color:#afc9e1;font-size:14px}.ccltjPart .ccltjCtn .sntq .sntqDefault .circle[data-v-52adb004]{width:10px;height:10px;border-radius:50%;background:#bdd7ec;margin-right:10px}.ccltjPart .ccltjCtn .sntq .sntqDefault .name[data-v-52adb004]{color:#bdd7ec;font-size:14px}.ccltjPart .ccltjCtn .echart[data-v-52adb004]{width:100%;height:calc(100% - 208px)}.ccltjPart .ccltjCtn .dw[data-v-52adb004]{position:absolute;right:0;bottom:-20px;color:#35507c}.ccltjPart .ccltjCtn .circleEchartPart[data-v-52adb004]{width:100%;height:188px;margin-top:20px}.ccltjPart .ccltjCtn .circleEchartPart .echartItem[data-v-52adb004]{width:50%;height:100%;display:flex;flex-direction:row}.ccltjPart .ccltjCtn .circleEchartPart .echartItem .echartTitle[data-v-52adb004]{width:50px;height:100%;background:rgba(26,56,89,.3);-ms-writing-mode:tb-lr;writing-mode:vertical-lr;text-align:center;font-size:18px;padding-left:15px;color:#bdd7ec}.ccltjPart .ccltjCtn .circleEchartPart .echartItem .echartBox[data-v-52adb004]{width:calc(100% - 50px);background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:95%;background-position:50%}", ""]);
// Exports
module.exports = exports;
