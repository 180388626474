<template>
  <div class="rightPart scrollbar-hidden">
    <BoxTitle
      :title="titleName"
      :backBtn="false"
      :downloadBtn="true"
      @handleDownloadClick="handleDownloadClick"
    ></BoxTitle>
    <div class="tjjg-module">
      <!-- 植物类型 -->
      <basic-module :text="common.getFyName(fany.zwlx)">
        <div slot="basic-module">
          <div class="bs-datepicker time">
            <el-date-picker
              size="mini"
              style="width: 110px"
              v-model="nfValue"
              type="year"
              value-format="yyyy"
              popper-class="bs-select-panel"
              placeholder="选择年"
              :clearable="false"
              @change="changeYear"
            >
            </el-date-picker>
          </div>
          <resultStatic :resource="resultStaticResource"></resultStatic>
        </div>
      </basic-module>
    </div>
    <div class="fbqk-module">
      <!-- 多样性统计 -->
      <basic-module :text="common.getFyName(fany.dyxtj)">
        <div slot="basic-module" class="fbqk-border">
          <NoMonitor v-show="!isChartShow" text="暂无数据"></NoMonitor>
          <borderPieChart
            v-show="isChartShow"
            :chartData="dyxtjChartData"
            :showLegend="showLegend"
          ></borderPieChart>
          <div class="jcsj-search">
            <el-image
              v-if="showLegend"
              class="show-big-picture"
              :src="tpljImg"
              fit="cover"
              v-viewer
            ></el-image>
            <!-- <el-select
                            class="jcsj-select"
                            popper-class="bs-select-panel"
                            v-model="yfValue"
                            placeholder="请选择样方"
                            @change="handleYfChanged"
                        >
                            <el-option
                                v-for="item in yfList"
                                :key="'yf' + item.ID"
                                :label="item.MC"
                                :value="item.ID"
                            >
                            </el-option>
                        </el-select> -->
          </div>
        </div>
      </basic-module>
    </div>
    <!-- <div class="sssj-module">
            <basic-module text="基本信息">
                <div slot="basic-module" class="sssj-border">
                    <div
                        class="sssj-item"
                        v-for="(item, index) in sssjList"
                        :key="'sssj' + item.id"
                    >
                        <div class="sssj-top">{{ item.label }}</div>
                        <div class="sssj-bottom">
                            <span>{{ item.value | strictNull }}</span>
                            <span v-if="item.value">{{ item.dw }}</span>
                        </div>
                    </div>
                    <div class=" bs-datepicker jcsj-search">
                        <el-date-picker
                            
                            size="mini"
                            style="width: 100px"
                            v-model="nfValue2"
                            type="year"
                            value-format="yyyy"
                            popper-class="bs-select-panel"
                            placeholder="选择年"
                            :clearable="false"
                            @change="handleJbxxNfChanged"
                        >
                        </el-date-picker>
                        <div
                            :class="
                                currentJcsjBtnId === item.id
                                    ? 'jscj-btn-group-current'
                                    : 'jscj-btn-group'
                            "
                            v-for="(item, index) in jcsjBtnList"
                            :key="'jcsjbtn' + item.id"
                            @click="handleJscjBtnClick(item)"
                        >
                            {{ item.label }}
                        </div>
                    </div>
                </div>
            </basic-module>
        </div> -->
    <div class="jlfx-module">
      <!-- 监测报告 -->
      <basic-module :text="common.getFyName(fany.jcbg)">
        <div slot="basic-module" class="jlfx-border">
          <div class="jlfx-label">综合评价</div>
          <div class="jlfx-text" :title="zhpjText">
            {{ zhpjText | strictNull }}
          </div>
          <div class="jlfx-label">保护建议</div>
          <div class="jlfx-text" :title="bhjyText">
            {{ bhjyText | strictNull }}
          </div>
        </div>
      </basic-module>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import BoxTitle from "@views/big_screen/comp/BoxTitle.vue"; //标题
import NoMonitor from "@views/big_screen/comp/NoMonitor.vue"; //标题
import resultStatic from "../comp/result_static.vue";
import borderPieChart from "../comp/border_pie_chart.vue";
export default {
  name: "",
  components: {
    BoxTitle,
    NoMonitor,
    resultStatic,
    borderPieChart,
  },
  data() {
    return {
      titleName: "植物多样性监测服务",
      sssjList: [
        {
          id: "0",
          label: "平均冠幅",
          value: "",
          dw: "cm",
        },
        {
          id: "1",
          label: "平均高度",
          value: "",
          dw: "m",
        },
        {
          id: "2",
          label: "平均胸径",
          value: "",
          dw: "cm",
        },
        {
          id: "3",
          label: "密度",
          value: "",
          dw: "",
        },
        {
          id: "4",
          label: "郁闭度",
          value: "",
          dw: "",
        },
      ],
      resultStaticResource: [
        {
          name: "乔木",
          num: 0,
          dw: "类",
        },
        {
          name: "灌木",
          num: 0,
          dw: "类",
        },
        {
          name: "草本",
          num: 0,
          dw: "类",
        },
        {
          name: "珍稀濒危种",
          num: 0,
          dw: "类",
        },
      ],
      dyxtjChartData: [],
      nfValue: "2021",
      nfValue2: "2021",
      jcsjBtnList: [
        // {
        //     id: "群落",
        //     label: "群落",
        // },
        {
          id: "乔木",
          label: "乔木层",
        },
        {
          id: "灌木",
          label: "灌木层",
        },
        {
          id: "草本",
          label: "草本层",
        },
      ],
      currentJcsjBtnId: "乔木",
      isChartShow: false,
      yfList: [], // 样方列表
      yfValue: "",
      showLegend: false,
      tpljImg: "",
      zhpjText: "", //综合评价
      bhjyText: "", //保护建议
    };
  },
  filters: {
    strictNull(value) {
      if (value === null || value === undefined || value === "") {
        return "暂无数据";
      }
      return value;
    },
    toFixed(value) {
      return Number(value);
    },
  },
  mounted() {
    this.getZwlxData();
    this.getDyxTj();
    this.getZwDetail();
    this.getJlbg();
    this.getZwdyxYf();
  },
  methods: {
    ...mapActions([
      "GetZwlxHz", // 植物类型统计
      "GetDyxTj", // 植物多样性统计
      "GetZwDetail", // 植物多样性-基本信息
      "GetJlbg", // 获取结论报告
      "GetZwdyxYf", // 植物多样性样方
      "GetZwdyxTjByYf", // （根据样方统计物种数量 ）参数-样方id - yfid
    ]),
    changeYear() {
        
      this.yfValue = "";
      this.getDyxTj();
      this.getZwlxData();
    },
    // 获取植物类型数据
    async getZwlxData() {
      const res = await this.GetZwlxHz({
        nf: this.nfValue,
      });
      const keysMap = {
        QM: 0,
        GM: 1,
        CB: 2,
        ZXBWWZ: 3,
      };
      if (res && typeof res === "object") {
        for (const key in keysMap) {
          if (this.resultStaticResource[keysMap[key]]) {
            this.resultStaticResource[keysMap[key]].num = Number(
              res[key]
            ).toFixed(0);
          }
        }
      }
    },
    // 多样性统计
    async getDyxTj() {
      this.showLegend = false;
      let sum = 0;
      this.dyxtjChartData = [];
      const result = await this.GetDyxTj({
        nf: this.nfValue,
      });
      if (result && result.length > 0) {
        this.dyxtjChartData = result;
        this.dyxtjChartData.map((item) => {
          item.name = item.ZWLX;
          item.value = item.ZWSL;
          item.legend = item.ZYWZ;
          sum += Number(item.ZWSL);
        });
      }
      if (sum === 0) {
        this.isChartShow = false;
      } else {
        this.isChartShow = true;
      }
    },
    handleJbxxNfChanged(val) {
      this.initCurrentJcsjBtnId();
      this.getZwDetail();
    },
    async getZwDetail() {
      const result = await this.GetZwDetail({
        lx: this.currentJcsjBtnId,
        nf: this.nfValue2,
      });
      if (result && result.length > 0) {
        this.sssjList.forEach((item, index) => {
          switch (item.label) {
            case "平均冠幅":
              item.value = result[0].PJGF;
              break;
            case "平均高度":
              item.value = result[0].PJGD;
              break;
            case "平均胸径":
              item.value = result[0].PJXJ;
              break;
            case "密度":
              item.value = result[0].MD;
              break;
            case "总盖度":
              item.value = result[0].ZGD;
              break;
            case "郁闭度":
              item.value = result[0].YBD;
              break;
          }
        });
      }
    },
    initCurrentJcsjBtnId() {
      switch (this.currentJcsjBtnId) {
        case "乔木":
          this.sssjList = [
            {
              id: "0",
              label: "平均冠幅",
              value: "",
              dw: "cm",
            },
            {
              id: "1",
              label: "平均高度",
              value: "",
              dw: "m",
            },
            {
              id: "2",
              label: "平均胸径",
              value: "",
              dw: "cm",
            },
            {
              id: "3",
              label: "密度",
              value: "",
              dw: "",
            },
            {
              id: "4",
              label: "郁闭度",
              value: "",
              dw: "",
            },
          ];
          break;
        case "灌木":
          this.sssjList = [
            {
              id: "0",
              label: "平均高度",
              value: 0,
              dw: "cm",
            },
            {
              id: "1",
              label: "密度",
              value: "",
              dw: "",
            },
            {
              id: "2",
              label: "总盖度",
              value: "",
              dw: "",
            },
          ];
          break;
        case "草本":
          this.sssjList = [
            {
              id: "0",
              label: "平均高度",
              value: 0,
              dw: "m",
            },
            {
              id: "1",
              label: "密度",
              value: "",
              dw: "",
            },
            {
              id: "2",
              label: "总盖度",
              value: "",
              dw: "",
            },
          ];
          break;
      }
    },
    handleJscjBtnClick(val) {
      if (val.id === this.currentJcsjBtnId) return;
      this.currentJcsjBtnId = val.id;
      this.initCurrentJcsjBtnId();
      this.getZwDetail();
    },
    // 获取监测报告
    async getJlbg() {
      this.bgxzXdlj = "";
      let result = await this.GetJlbg({
        lx: "4",
      });
      if (!result) return;
      this.zhpjText = result.ZHPJ; //综合评价
      this.bhjyText = result.BHJY; //保护建议
    },
    // 报告下载
    handleDownloadClick() {
      if (!this.bgxzXdlj) {
        this.common.showMsg("暂无报告", "warning");
        return;
      }
      window.open("http://" + this.bgxzXdlj);
    },
    
    // 植物多样性样方
    async getZwdyxYf() {
      const result = await this.GetZwdyxYf();
      if (result.ISSUCCESS) {
        this.yfList = result.RESULTVALUE;
      } else {
        this.yfList = [];
      }
    },
    // 选择样方
    handleYfChanged() {
      this.nfValue = "";
      this.tpljImg = "";
      if (this.yfList && this.yfList.length > 0) {
        this.yfList.forEach((item) => {
          if (item.ID === this.yfValue) {
            this.tpljImg = item.TPLJ;
          }
        });
      }
      this.getZwdyxTjByYf();
    },
    // （根据样方统计物种数量 ）参数-样方id - yfid
    async getZwdyxTjByYf() {
      this.showLegend = true;
      let sum = 0;
      this.dyxtjChartData = [];
      const result = await this.GetZwdyxTjByYf({
        yfid: this.yfValue,
      });
      if (
        result.ISSUCCESS &&
        result.RESULTVALUE &&
        result.RESULTVALUE.length > 0
      ) {
        this.dyxtjChartData = result.RESULTVALUE;
        this.dyxtjChartData.map((item) => {
          item.name = item.ZWZM;
          item.value = item.ZWSL;
          sum += Number(item.ZWSL);
        });
      }
      if (sum === 0) {
        this.isChartShow = false;
      } else {
        this.isChartShow = true;
      }
    },
    // 查看大图
    handleShowBigPictrue() {},
  },
};
</script>
<style scoped lang="scss">
.rightPart {
  width: 100%;
  height: 100%;
  padding: 15px 0;
  .tjjg-module {
    width: 100%;
    height: 185px;
    position: relative;
    .time {
      position: absolute;
      right: 0;
      top: -40px;
    }
  }
  .fbqk-module {
    width: 100%;
    height: 365px;
    .fbqk-border {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
  .sssj-module {
    width: 100%;
    height: 290px;
    .sssj-border {
      position: relative;
      display: flex;
      flex-flow: row wrap;
      .sssj-item {
        position: relative;
        width: 320px;
        height: 65px;
        .sssj-top {
          width: 100%;
          height: 30px;
          line-height: 30px;
          color: #5673a2;
        }
        .sssj-bottom {
          width: 100%;
          height: 35px;
          line-height: 35px;
          padding-left: 10px;
          font-size: 16px;
          color: #bdd7ec;
          background: rgba(26, 48, 89, 0.3);
        }
      }
      .sssj-item:nth-child(odd) {
        margin-right: 15px;
      }
    }
  }
  .jcsj-search {
    display: flex;
    position: absolute;
    right: 0;
    top: -40px;
    .jcsj-select {
      width: 180px;
      height: 20px;
      margin-right: 10px;
    }
    .show-big-picture {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      cursor: pointer;
    }
    .jscj-btn-group {
      width: 56px;
      height: 20px;
      line-height: 20px;
      margin-left: 15px;
      border-radius: 5px;
      text-align: center;
      color: #a8c8ff;
      background: #35507c;
      cursor: pointer;
    }
    .jscj-btn-group-current {
      width: 56px;
      height: 20px;
      line-height: 20px;
      margin-left: 15px;
      border-radius: 5px;
      text-align: center;
      color: #040e1e;
      background: #ffcd80;
    }
  }
  .jlfx-module {
    width: 100%;
    .jlfx-border {
      width: 100%;
      .jlfx-label {
        width: 100%;
        color: #5673a2;
      }
      .jlfx-text {
        width: 100%;
        line-height: 24px;
        margin: 5px 0;
        padding: 9px;
        text-align: justify;
        font-size: 16px;
        font-weight: 500;
        color: #bdd7ec;
        background: rgba(26, 48, 89, 0.3);
      }
    }
  }
}
</style>
