import { mapGetters, mapMutations, mapActions } from "vuex";
import { derectionData } from "@libs/dailyInspectAreaList"; //

import * as TYPES from "@store/modules/mutation-type.js";
export default {
    components: {},
    props: {},
    computed: {
        ...mapGetters({}),
    },
    watch: {},
    data() {
        return {
            derectionData,
            areaList: [],
            mapboxMap: null,
            tdlyIdList: [], //图例利用id数组
        };
    },
    created() {},
    mounted() {
        this.getSxtList();
        this.getDepetnMentList();
    },
    methods: {
        ...mapActions([
            "getVectorStyle",
            "GetRcxcXcpq",
            "MapCameraList",
            "GetDepartmentAndUser",
        ]),
        // 遗产要素分类 专题图
        async drawBasicMap(group) {
            this.clearLayersGroup(group);
            let layerStyle = await this.getVectorStyle({
                url: window.MAP_ZTT,
            });
            let obj = JSON.parse(JSON.stringify(layerStyle));
            let ycshcq = [];
            this.tdlyIdList = [];
            obj.layers.map((item) => {
                if (item["source-layer"] == "土地利用") {
                    this.tdlyIdList.push(item.id);
                }
                if (
                    item["source-layer"] &&
                    item["source-layer"] != "国保单位建设控制地带" &&
                    item["source-layer"] != "国保单位保护范围" &&
                    item["source-layer"] != "古茶林" &&
                    item["source-layer"] != "分隔防护林" &&
                    item["source-layer"] != "遗产要素村寨" &&
                    item["source-layer"] != "村寨面"
                ) {
                    ycshcq.push(item);
                }
            });
            obj.layers = ycshcq;
            let allProvince1 = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: obj,
            }).addTo(group);
            this.common.showVectorLayers();
            this.mapboxMap = allProvince1.getMapboxMap();
            this.mapboxMap.on("load", () => {
                // this.onOffList[0].idList.map((itemInner) => {
                //     this.mapboxMap.setPaintProperty(
                //         itemInner,
                //         "fill-opacity",
                //         0
                //     );
                // });
                if (this.positionStr == "right") {
                    this.baseLayerChange("img");
                }
            });
        },
        // 显示 隐藏 土地利用
        changeTdlyMap(num) {
            this.tdlyIdList.map((item) => {
                this.mapboxMap.setPaintProperty(item, "fill-opacity", num);
            });
        },
        //获取片区数据
        async getAreaInfo() {
            let result = await this.GetRcxcXcpq({});
            let areaColorList = [
                "#5a91fb",
                "#5ad9a7",
                "#5c7091",
                "#f7bd16",
                "#e86452",
                "#6ec9eb",
                "#955eb9",
                "#ff9944",
                "#1e9593",
                "#ff99c3",
                "#81511c",
                "#7d0022",
                "#556fb5",
                "#009944",
                "#0068b7",
                "#d31247",
                "#f260ce",
                "#7148f5",
                "#94b750",
                "#0b3da9",
                "#f4da80",
                "#863803",
                "#d2c7b6",
            ];
            result.map((item, index) => {
                this.derectionData.map((itemInner) => {
                    if (item.ID == itemInner.ID) {
                        Object.assign(item, itemInner);
                    }
                });
            });
            this.areaList = result;
            this.drawXcq(this.xcqGroup);
        },
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        // 加载高清影像
        loadGqyx(group) {
            new window.L.tileLayer(window.MAP_GQXY_50_2022, {
                minZoom: 0,
                maxZoom: 20,
            }).addTo(group);
            new window.L.tileLayer(window.MAP_GQXY_10_2022, {
                minZoom: 0,
                maxZoom: 22,
            }).addTo(group);
            this.common.showVectorLayers();
            // let myContainer = this.ycyHcq._glMap.getContainer();
            // myContainer.style.zIndex = 50;
        },
        // 改变 异常类型 统计 0 异常总数 1 未解除 2已解除
        changeRxcxYctjNum(num) {
            if (num == null) {
                return;
            }
            let list = [];
            switch (num) {
                case 0:
                    list = this.errorList;
                    break;
                case 1:
                    this.errorList.map((item) => {
                        if (item.YCSFJC != "1") {
                            list.push(item);
                        }
                    });
                    break;
                case 2:
                    this.errorList.map((item) => {
                        if (item.YCSFJC == "1") {
                            list.push(item);
                        }
                    });
                    break;
            }
            this.drawMarkerError(this.ycdGroup, list);
            this.onOffList.map((item) => {
                if (item.name == "异常点") {
                    item.state = true;
                }
            });
        },
        // 最新版的marker
        closeComDialog() {
            this.commonDialog.dialog = false;
            this.commonDialog.sxtDialog = false; //摄像头 弹出框
        },
        // 摄像机列表
        async getSxtList() {
            let result = await this.MapCameraList({});
            this.onOffList.map((item) => {
                if (item.name == "摄像机") {
                    item.list = result;
                }
            });
        },
        // 画摄像机
        drawSxt(obj) {
            let list = obj.list;
            let group = obj.group;
            this.clearLayersGroup(group);

            list.forEach((item) => {
                // SFJT 0 -否 1-是
                let url = "";
                if (item.SFJT) {
                    // SBQTLX 0:球机 1：枪机
                    if (item.SBQTLX == 0) {
                        url = obj.src;
                    } else {
                        url = obj.src_gun;
                    }
                } else {
                    // SBQTLX 0:球机 1：枪机
                    if (item.SBQTLX == 0) {
                        url = obj.src_error;
                    } else {
                        url = obj.src_gun_error;
                    }
                }
                let icon = window.L.icon({
                    iconUrl: url,
                    iconSize: [32, 32],
                    iconAnchor: [16, 16],
                });
                let layer = window.L.marker([item.AZDWD, item.AZDJD], {
                    icon: icon,
                }).addTo(group);
                layer.on("click", () => {
                    // if (!item.RTMP) {
                    //     this.common.showMsg("暂无设备", "warning");
                    //     return;
                    // }
                    if (!item.SFJT) {
                        this.common.showMsg("设备故障", "warning");
                    }
                    // this.commonDialog.dialog = true;
                    // this.commonDialog.sxtDialog = true;
                    // this.commonDialog.detail = item;
                    // this.commonDialog.title = item.SBMC;

                    this.commonDialog.dialog = "sxtDialog";
                    this.commonDialog.detail = item;
                    this.commonDialog.title = item.SBMC;
                    this.$emit("getCommonDialog", this.commonDialog);
                });
                layer
                    .on("mouseover", () => {
                        this.markerPopup
                            .setLatLng([item.AZDWD, item.AZDJD])
                            .setContent(`<div class="tip">${item.SBMC}</div>`)
                            .openOn(this.myMap);
                    })
                    .on("mouseout", () => {
                        this.markerPopup.removeFrom(this.myMap);
                    });
            });
        },
        // 获取部门列表
        async getDepetnMentList() {
            let result = await this.GetDepartmentAndUser({});
            this.departmentList = result.ResultValue;
        },
    },
};
