<template>
    <div class="pdfDialog">
        <div class="flex-between">
            <div class="title">{{ pdf.title }}</div>
            <img
                src="@image/icon/close.png"
                alt="关闭按钮"
                @click="closePdf()"
                class="close pointer"
            />
        </div>
        <iframe
            :src="
                'static/pdf_pre/web/viewer.html?pdf=' +
                pdf.src +
                '#page=' +
                pdf.page +
                '&v=2'
            "
            frameborder="0"
            width="100%"
            height="100%"
            style="margin-top: 10px"
        ></iframe>
    </div>
</template>
<script>
export default {
    name: "",
    data() {
        return {};
    },
    components: {},
    computed: {},
    watch: {
        pdf: {
            immediate: true,
            handler() {},
        },
    },
    props: {
        pdf: {
            type: Object,
            default: () => {
                let obj = {
                    page: 1,
                    src: "",
                    title: "",
                };
                return obj;
            },
        },
    },
    mounted() {},
    methods: {
        closePdf() {
            this.$emit("closePdf");
        },
    },
};
</script>
<style scoped lang="scss">
.pdfDialog {
    width: 100%;
    height: 100%;
    padding: 15px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    background: rgba(3, 23, 38, 0.8);
    .title {
        font-size: 18px;
        color: #fff;
    }
    .close {
        width: 22px;
        height: 22px;
    }
}
</style>
