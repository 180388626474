<template>
    <div class="rightPart scrollbar-hidden">
        <BoxTitle
            :title="titleName"
            :backBtn="false"
            :downloadBtn="true"
            @handleDownloadClick="loadReport()"
        ></BoxTitle>
        <saveState
            class="ztbcztPart"
            :stateName="stateName"
            :stateType="stateType"
            :stateTypeList="stateTypeList"
        ></saveState>
        <div class="tjjg-module">
            <!-- 结果统计 -->
            <basic-module :text="common.getFyName(fany.jgtj)">
                <div slot="basic-module">
                    <div class="basicStatistics flex-between-y-center">
                        <div
                            class="centerItem animated bounceInRight"
                            v-for="(item, index) in resultStaticList"
                            :key="index"
                            :style="{
                                animationDelay: 0.2 + index * 0.2 + 's',
                            }"
                        >
                            <div class="name">
                                {{ item.LXNAME }}
                            </div>

                            <div class="count flex-x-center flex-y-center">
                                <div
                                    :class="[index <= 1 ? 'num' : '']"
                                    :style="{
                                        fontSize: index >= 2 ? '22px' : '',
                                    }"
                                >
                                    {{ item.VALUE }}
                                </div>
                                <div class="dw">{{ item.dw }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </basic-module>
        </div>
        <div class="monitor-part">
            <!-- 监测数据 -->
            <basic-module :text="common.getFyName(fany.jcsj)">
                <div slot="basic-module" class="bad-part">
                    <div class="condition flex-end">
                        <div class="line"></div>
                        <SelectTimeTab
                            :selectData="selectData"
                            :tabData="tabData"
                            @getTabItem="getTabItem"
                            @getSelectItem="getSelectItem"
                        >
                        </SelectTimeTab>
                    </div>
                    <div class="echart" id="animalsEchart"></div>
                </div>
            </basic-module>
        </div>
        <div class="conclusion-analysis">
            <!-- 结论分析 -->
            <basic-module :text="common.getFyName(fany.jlfx)">
                <div slot="basic-module" class="jlfx-border">
                    <div class="jlfx-label">绿色防治</div>
                    <div class="jlfx-text" v-html="zhpjText">
                        <!-- {{ zhpjText | strictNull }} -->
                    </div>
                </div>
            </basic-module>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import BoxTitle from "@views/big_screen/comp/BoxTitle.vue"; //标题
import saveState from "../comp/saveState";
import SelectTimeTab from "@views/big_screen/comp/SelectTimeTab";
export default {
    name: "",
    components: {
        BoxTitle,
        saveState,
        SelectTimeTab,
    },
    data() {
        return {
            titleName: "森林病虫害遥感监测服务",
            // 总体保存状况
            stateName: "变化程度评估",
            stateType: 0,
            stateTypeList: [
                {
                    label: "面积变大",
                    color: "red",
                    desc: 1,
                },
                {
                    label: "面积变小",
                    color: "#61FF00",
                    desc: -1,
                },
                {
                    label: "无变化",
                    color: "#61FF00",
                    desc: 0,
                },
                {
                    label: "无变化",
                    color: "#61FF00",
                    desc: null,
                },
            ],
            resultStaticList: [],
            tabData: {
                list: [
                    // {
                    //     name: "数量",
                    // },
                    // {
                    //     name: "面积",
                    // },
                ],
                value: "name",
                result: "数量",
            },
            selectData: {
                clearable: false,
                state: true,
                width: "100px",
                defaultValue: "",
                list: [],
                label: "name",
                value: "name",
                result: "",
            },
            animalsPart: {
                listAry: [],
                echart: null,
            },
            zhpjText: "",
            reportUrl: "", //报告下载
        };
    },
    filters: {
        strictNull(value) {
            if (value === null || value === undefined || value === "") {
                return "暂无数据";
            }
            return value;
        },
    },
    mounted() {
        this.getTimeList();
        this.getResultStatistics();
        this.getJlfxPart();
    },
    methods: {
        ...mapActions(["GetJlbg", "GetSthjJgtj", "GetYgjcTj"]),
        getTimeList() {
            let list = [2021];
            this.selectData.list = [];
            list.map((item) => {
                let obj = {
                    name: item,
                };
                this.selectData.list.push(obj);
            });
            if (this.selectData.list.length) {
                this.selectData.result = this.selectData.list[0].name;
                this.selectData.defaultValue = this.selectData.list[0].name;
                this.getMonitorData();
            }
        },
        // 获取结果统计
        async getResultStatistics() {
            const res = await this.GetSthjJgtj({
                lx: 6,
            });
            res.map((item) => {
                if (item.LXNAME == "病虫害面积（公顷）") {
                    item.LXNAME = "病虫害面积";
                    item.dw = "公顷";
                }
            });
            this.resultStaticList = res;
        },
        // 获取监测数据
        async getMonitorData() {
            let result = await this.GetYgjcTj({
                nf: this.selectData.result,
            });
            this.animalsPart.listAry = result;
            this.initEchartMonitor(result);
        },
        // 数量 面积 切换
        getTabItem(item, index) {
            this.tabData.result = item.name;
            this.initEchartMonitor();
        },
        // 年份切换
        getSelectItem(item) {
            this.selectData.result = item.name;
            this.getMonitorData();
        },
        initEchartMonitor() {
            let xAxisData = [];
            let seriesDataLine = [];
            let seriesDataColumn = [];
            this.animalsPart.listAry.map((item) => {
                xAxisData.push(item.YCZC);
                seriesDataLine.push(item.TBSL);
                seriesDataColumn.push(item.MJ);
            });
            let option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        lineStyle: {
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "rgba(255, 255, 255,1)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                ],
                                global: false,
                            },
                        },
                    },
                    formatter: function (params) {
                        let str = "";
                        params.map((item, index) => {
                            str += `${item.marker}${item.name}</br>${item.value}`;
                            if (index == 0) {
                                str += "个</br>";
                            } else {
                                str += "公顷</br>";
                            }
                        });
                        return str;
                    },
                },
                grid: {
                    top: 30,
                    left: 40,
                    right: 40,
                    bottom: 40,
                },
                xAxis: {
                    name: "",
                    data: xAxisData,
                    axisLabel: {
                        // interval: 0,
                        // rotate: 45,
                        textStyle: {
                            color: "#A8C8FF",
                        },
                        formatter: function (value) {
                            var ret = "";
                            var maxLength = 8;
                            var rowNum = Math.ceil(value.length / maxLength);
                            if (rowNum > 1) {
                                for (var i = 0; i < rowNum; i++) {
                                    var temp = "";
                                    var start = i * maxLength;
                                    var end = start + maxLength;
                                    temp = value.substring(start, end) + "\n";
                                    ret += temp;
                                }
                                return ret;
                            } else {
                                return value;
                            }
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#0f5378",
                        },
                    },
                    splitArea: {
                        color: "#f00",
                        lineStyle: {
                            color: "#e2e9ff",
                        },
                    },
                },
                yAxis: [
                    {
                        name: "个",
                        minInterval: 10,
                        // nameTextStyle: {
                        //     color: "rgba(168,200,255,1)",
                        // },
                        nameTextStyle: {
                            fontSize: 12,
                            color: "#9AB4E3",
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "rgba(53,80,124,0.2)",
                            },
                        },
                        axisLabel: {
                            margin: 20,
                            fontSize: 12,
                            textStyle: {
                                color: "#9AB4E3",
                                // color: "rgba(53,80,124,1)",
                            },
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(53,80,124,0.2)",
                                width: 2,
                            },
                        },
                    },
                    {
                        name: "公顷",
                        position: "right",
                        minInterval: 0.2,
                        // nameTextStyle: {
                        //     color: "rgba(168,200,255,1)",
                        // },
                        nameTextStyle: {
                            fontSize: 12,
                            color: "#9AB4E3",
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "rgba(53,80,124,0.2)",
                            },
                        },
                        axisLabel: {
                            margin: 20,
                            fontSize: 12,
                            textStyle: {
                                color: "#9AB4E3",
                                // color: "rgba(53,80,124,1)",
                            },
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(53,80,124,0.2)",
                                width: 2,
                            },
                        },
                    },
                ],
                series: [
                    {
                        data: seriesDataLine,
                        type: "line",
                        barWidth: 10,
                        hoverAnimation: true,
                        smooth: true,
                        animation: true,
                        label: {
                            show: false,
                            position: "top",
                            textStyle: {
                                fontSize: 14,
                            },
                        },
                        itemStyle: {
                            color: "#cda77a",
                        },
                    },
                    {
                        data: seriesDataColumn,
                        yAxisIndex: 1,
                        type: "bar",
                        barWidth: 10,
                        hoverAnimation: true,
                        smooth: true,
                        animation: true,
                        label: {
                            show: false,
                            position: "top",
                            textStyle: {
                                fontSize: 14,
                            },
                        },
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "rgba(153,204,255,1)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(153,204,255,0)",
                                        },
                                    ],
                                    false
                                ),
                                // shadowColor: "rgba(105,196,254, 0.9)",
                                // shadowBlur: 20,
                            },
                        },
                    },
                ],
            };
            this.animalsPart.echart = this.$echarts.dispose(
                document.getElementById("animalsEchart")
            );
            this.animalsPart.echart = this.$echarts.init(
                document.getElementById("animalsEchart")
            );
            if (this.animalsPart.listAry.length) {
                this.animalsPart.echart.setOption(option);
            }
        },
        // 结论分析部分
        async getJlfxPart() {
            this.zhpjText = "";
            this.reportUrl = "";
            const result = await this.GetJlbg({
                lx: "6",
            });
            if (!result) return;
            this.zhpjText = result.ZHPJ;
            this.reportUrl = result.BGLJ;
        },
        // 报告下载
        loadReport() {
            if (!this.reportUrl) {
                this.common.showMsg("暂无报告", "warning");
                return;
            }
            window.open("http://" + this.reportUrl);
        },
    },
};
</script>
<style scoped lang="scss">
.rightPart {
    width: 100%;
    height: 100%;
    padding: 15px 0;
    // 总体保存状态
    .ztbcztPart {
        height: 92px;
        padding: 0 24px;
        margin-top: 15px;
    }
    .tjjg-module {
        width: 100%;
        .basicStatistics {
            width: 100%;
            height: 100%;
            position: relative;
            .centerItem {
                width: 24%;
                .name {
                    width: 100%;
                    height: 40px;
                    background: url("~@image/bigScreen/icon/titleBg.png")
                        no-repeat;
                    background-size: 100% 100%;
                    font-size: 18px;
                    font-weight: 500;
                    color: rgba(168, 200, 255, 1);
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                }
                .count {
                    margin-top: 5px;
                    height: 65px;
                    border: 1px solid rgba(53, 80, 124, 0.4);
                    color: #bdd7ec;
                    position: relative;
                    .num {
                        font-size: 48px;
                        font-family: DBPM;
                    }
                    .dw {
                        margin-left: 5px;
                        font-size: 20px;
                        color: #a8c8ff;
                    }
                }
            }
        }
    }
    .monitor-part {
        width: 100%;
        height: 280px;
        .bad-part {
            position: relative;
            width: 100%;
            height: 100%;
            .condition {
                position: absolute;
                right: 0;
                top: -38px;
                .line {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 522px;
                    height: 1px;
                    background: rgba(53, 80, 124, 0.4);
                    z-index: -1;
                }
            }
            .echart {
                width: 100%;
                height: 100%;
            }
        }
    }
    .conclusion-analysis {
        width: 100%;
        .jlfx-border {
            width: 100%;
            .jlfx-label {
                width: 100%;
                color: #5673a2;
            }
            .jlfx-text {
                width: 100%;
                line-height: 24px;
                margin: 5px 0;
                padding: 9px;
                text-align: justify;
                font-size: 16px;
                font-weight: 500;
                color: #bdd7ec;
                background: rgba(26, 48, 89, 0.3);
            }
        }
    }
}
</style>
