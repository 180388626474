<template>
    <div class="screenCenter ontologicalScreen">
        <!-- 地图 -->
        <div class="mapPart markerPopup">
            <myMap ref="myMap" :myMap.sync="myMap" :mapData="mapData" />
        </div>
        <!-- 图层 -->
        <div class="mapChangePart">
            <div
                class="layer pointer flex-between-y-center"
                @click="layerState = !layerState"
            >
                <div class="desc">图层</div>
                <img src="@image/bigScreen/icon/layer.png" />
            </div>
            <div v-show="layerState" class="layerCtn">
                <LayerList
                    :listAry="onOffList"
                    keyValue="ontological_disease"
                    @switchStateChange="switchStateChange"
                ></LayerList>
            </div>
        </div>
        <!-- 图例 -->
        <div class="legendPart">
            <LegendList
                :listAry="legendList"
                screenName="ontological_disease"
            ></LegendList>
        </div>
        <bhkzBox></bhkzBox>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import MyMap from "@views/big_screen/comp/map/BaseMap.vue";
import OnOff from "@views/big_screen/comp/OnOff.vue"; //开关
import bhkzBox from "../3_page_right/page_right_btbh_bhkz.vue";
import LegendList from "@views/big_screen/comp/LegendList.vue"; //图例
import LayerList from "@views/big_screen/comp/LayerList.vue"; //图层

export default {
    components: {
        MyMap,
        OnOff,
        bhkzBox,
        LegendList,
        LayerList,
    },
    name: "",
    data() {
        return {
            myMap: {},
            mapData: {
                baseMapNum: 0,
                mapTypeBtn: true,
                showTool: true,
            },
            layerState: false,
            legendList: [],
            onOffList: [
                {
                    name: "病害",
                    group: null,
                    state: true,
                },
            ],
            markerPopup: null,
        };
    },
    computed: {
        ...mapGetters(["btbhBhList", "btbhDetail", "btbhYcys"]),
    },
    props: {
        positionStr: {
            type: String,
            default: "",
        },
    },
    watch: {
        positionStr: {
            immediate: true,
            handler() {
                if (this.positionStr == "left") {
                    this.mapData.baseMapNum = 0;
                } else if (this.positionStr == "right") {
                    this.mapData.baseMapNum = 1;
                }
            },
        },
        btbhBhList: {
            immediate: false,
            handler() {
                this.drawBhlbMarker(this.onOffList[0].group, this.btbhBhList);
                this.getLegendList();
            },
        },
        btbhDetail: {
            immediate: false,
            handler() {
                if (this.btbhDetail) {
                    this.myMap.setView(
                        [this.btbhDetail.BHWD, this.btbhDetail.BHJD],
                        16
                    );
                    this.markerPopup
                        .setLatLng([this.btbhDetail.BHWD, this.btbhDetail.BHJD])
                        .setContent(
                            `<div class="tip">${this.btbhDetail.BHMC}</div>`
                        )
                        .openOn(this.myMap);
                } else {
                    this.markerPopup.removeFrom(this.myMap);
                    this.$refs.myMap.changeFitBounds();
                }
            },
        },
        // 本体病害 某一个遗产要素
        btbhYcys: {
            immediate: false,
            handler() {
                // if (this.btbhYcys) {
                //     this.myMap.setView(
                //         [this.btbhYcys.WD, this.btbhYcys.JD],
                //         16
                //     );
                //     this.markerPopup
                //         .setLatLng([this.btbhYcys.WD, this.btbhYcys.JD])
                //         .setContent(
                //             `<div class="tip">${this.btbhYcys.MC}</div>`
                //         )
                //         .openOn(this.myMap);
                // } else {
                //     this.markerPopup.removeFrom(this.myMap);
                //     this.$refs.myMap.changeFitBounds();
                // }
            },
        },
    },
    mounted() {
        this.markerPopup = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, -4],
            className: "markerPopup",
        });
        this.onOffList.map((item) => {
            item.group = window.L.layerGroup().addTo(this.myMap);
        });
    },
    methods: {
        ...mapActions(["getVectorStyle"]),
        ...mapMutations({
            setStateBtbh: "SET_STATE_BTBH",
        }),
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        // 更改图层
        switchStateChange(state, item) {
            item.state = state;
            let group = item.group;
            switch (item.name) {
                case "病害":
                    if (!state) {
                        this.clearLayersGroup(group);
                    } else {
                        this.drawBhlbMarker(group, this.btbhBhList);
                    }
                    this.legendList.map((item) => {
                        item.state = state;
                    });
                    break;
            }
        },
        getLegendList() {
            let list = this.legendList;
            this.btbhBhList.forEach((item) => {
                if (list.findIndex((i) => item.BHLXMC == i.name) === -1) {
                    list.push({
                        name: item.BHLXMC,
                        src: require(`@image/bigScreen/ontological_disease/legend/${Number(
                            item.BHLX
                        )}.png`),
                        width: "20px",
                        height: "20px",
                        state: true,
                    });
                }
            });
        },
        // 病害列表的marker
        drawBhlbMarker(group, list) {
            this.clearLayersGroup(group);
            list.forEach((item) => {
                let marker = window.L.marker([item.BHWD, item.BHJD], {
                    icon: window.L.icon({
                        iconUrl: require(`@image/bigScreen/ontological_disease/legend/${Number(
                            item.BHLX
                        )}.png`),
                        iconSize: [20, 20],
                        iconAnchor: [10, 10],
                        popupAnchor: [0, -10],
                        className: "bhIcon",
                    }),
                }).addTo(group);
                marker.on("click", () => {
                    this.setStateBtbh({
                        btbhDetail: item,
                        btbhPanelNum: 2,
                    });
                });
                marker.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([item.BHWD, item.BHJD])
                        .setContent(`<div class="tip">${item.BHMC}</div>`)
                        .openOn(this.myMap);
                });
                marker.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
            });
        },
    },
};
</script>
<style scoped lang="scss">
.screenCenter {
    position: relative;
    width: 100%;
    height: 100%;
    .mapPart {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .mapChangePart {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 10;
        .layer {
            position: absolute;
            top: 0;
            left: 0;
            width: 130px;
            height: 40px;
            background: #1a3059;
            border: 1px solid #35507c;
            border-radius: 10px;
            box-shadow: 5px 9px 30px 0px rgba(0, 44, 75, 0.2);
            padding: 0 20px;

            .desc {
                color: #fff;
                font-size: 18px;
            }
        }

        .layerCtn {
            min-width: 130px;
            margin-top: 50px;
            padding: 10px;
            background: #1a3059;
            border-radius: 5px;
        }
    }
    // 图例
    .legendPart {
        position: absolute;
        left: 10px;
        bottom: 10px;
        height: 56px;
        z-index: 10;
        .title {
            width: 20px;
            height: 56px;
            line-height: 23px;
            background: rgba(4, 28, 67, 0.8);
            font-weight: 400;
            font-size: 12px;
            color: #d8eaf6;
            text-align: center;
            padding-top: 5px;
        }
        .list {
            padding-left: 10px;
            background: rgba(4, 28, 67, 0.6);
            .legendItem {
                margin-right: 10px;
                .img {
                    margin-right: 5px;
                }
                .name {
                    font-weight: 400;
                    font-size: 12px;
                    color: #d8eaf6;
                }
            }
        }
    }
}
</style>
