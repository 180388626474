
import { derectionData } from "@libs/dailyInspectAreaList"; //
import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapGetters } from "vuex";
export default {
    components: {
    },
    props: {
    },
    computed: {
        ...mapGetters({
            jczlJghjState: TYPES.jczlJghjState,
        }),
    },
    watch: {
        // 景观环境
        jczlJghjState: {
            immediate: false,
            handler() {
                if (this.jczlJghjState) {
                    this.drawAltitude(this.altitudeGroup, this.altitudeList);
                } else {
                    this.clearLayersGroup(this.altitudeGroup);
                }
            },
        },
    },
    data() {
        return {
            derectionData,
            basicBg: {
                layer: null, //遗产要素分布图  矢量瓦片背景
                group: null,
                mapbox: null
            },
            noteGroup: null, //注记图层组
            mapboxMap: null,
            // 遗产要素分布图
            onOffList: [
                {
                    name: "遗产区、缓冲区",
                    sourceLayerList: ["遗产区划线"],
                    idList: [],
                    state: true,
                },
                {
                    name: "保护范围、建控地带",
                    sourceLayerList: [
                        "国保单位建设控制地带",
                        "国保单位保护范围",
                    ],
                    idList: [],
                    state: false,
                },
                {
                    name: "古茶林",
                    sourceLayerList: ["古茶林"],
                    idList: [],
                    state: true,
                    src: require("@image/bigScreen/monitor_all/marker/active/gcl.png"),
                    srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                    legend: true,
                    imgState: true,
                },
                {
                    name: "传统村落",
                    // sourceLayerList: ["遗产要素村寨"],
                    sourceLayerList: ["村寨面", "遗产要素村寨"],
                    idList: [],
                    state: true,
                    src: require("@image/bigScreen/monitor_all/marker/active/ctcl.png"),
                    srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                    legend: true,
                    imgState: true,
                },
                {
                    name: "分隔防护林",
                    sourceLayerList: ["分隔防护林"],
                    idList: [],
                    state: true,
                    src: require("@image/bigScreen/monitor_all/marker/active/fgfhl.png"),
                    srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                    legend: true,
                    imgState: true,
                },
                {
                    name: "土地利用",
                    sourceLayerList: ["土地利用"],
                    idList: [],
                    state: false,
                },
            ],
            onOffListVector: [
                {
                    name: "规划图",
                    group: null,
                    state: false,
                    url: window.MAP_GHT,
                    mulList: [
                        {
                            name: "村庄建设",
                            sourceName: "村庄建设边界",
                            src: require("@image/bigScreen/monitor_all/marker/active/czjsbj.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                            list: [],
                            group: null,
                            state: false,
                            imgState: true,
                        },
                        {
                            name: "生态保护",
                            sourceName: "生态保护红线",
                            src: require("@image/bigScreen/monitor_all/marker/active/stbh.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                            list: [],
                            group: null,
                            state: false,
                            imgState: true,
                        },
                        {
                            name: "古茶林保护线",
                            sourceName: "古茶林保护线",
                            src: require("@image/bigScreen/monitor_all/marker/active/gxlbhx.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                            list: [],
                            group: null,
                            state: false,
                            imgState: true,
                        },
                        {
                            name: "基本农田",
                            sourceName: "基本农田",
                            src: require("@image/bigScreen/monitor_all/marker/active/jbnt.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                            list: [],
                            group: null,
                            state: false,
                            imgState: true,
                        },
                    ],
                    legend: true,
                },
                {
                    name: "地形图",
                    group: null,
                    state: false,
                    url: window.MAP_DXT,
                },
                {
                    name: "国家森林公园",
                    group: null,
                    state: false,
                    url: window.MAP_GJSLGY,
                    mulList: [
                        {
                            name: "公园界桩",
                            sourceName: "景迈山片区界桩（碑）位置",
                            src: require("@image/bigScreen/monitor_all/marker/active/gjslgy_border.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                            list: [],
                            group: null,
                            state: false,
                            imgState: true,
                        },
                        {
                            name: "公园范围",
                            sourceName: "云南澜沧国家森林公园景迈山片区边界范围",
                            src: require("@image/bigScreen/monitor_all/marker/active/gjslgy_area.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                            list: [],
                            group: null,
                            state: false,
                            imgState: true,
                        },
                    ],
                    legend: true,
                },
                // {
                //     name: "水系山脉图",
                //     group: null,
                //     state: false,
                //     url: window.MAP_SXSM,
                // },
            ],
            mapboxGht: null, // 规划图 局部显示隐藏
            // 农业分布图
            onOffListNyfbt: [
                {
                    name: "农业用地",
                    sourceLayerList: ["惠民镇芒云村老寨村民小组", "惠民镇芒景村", "糯福乡勐宋村"],
                    idList: [],
                    state: false,
                    legend: true,
                    imgState: true,
                    src: require("@image/bigScreen/monitor_all/marker/nyfbt/gd.png"),
                    srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                },
                {
                    name: "林业用地",
                    sourceLayerList: [
                        "2020森林主要指标监测",
                    ],
                    idList: [],
                    mulList: [
                        {
                            name: "耕地",
                            src: require("@image/bigScreen/monitor_all/marker/nyfbt/gd.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                            id: "",
                            state: false,
                            imgState: true,
                        },
                        {
                            name: "有林地",
                            src: require("@image/bigScreen/monitor_all/marker/nyfbt/yld.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                            id: "",
                            state: false,
                            imgState: true,
                        },
                        {
                            name: "灌木林地",
                            src: require("@image/bigScreen/monitor_all/marker/nyfbt/gmld.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                            id: "",
                            state: false,
                            imgState: true,
                        },
                        {
                            name: "无立木林地",
                            src: require("@image/bigScreen/monitor_all/marker/nyfbt/wlmld.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                            id: "",
                            state: false,
                            imgState: true,
                        },
                        {
                            name: "未成林地",
                            src: require("@image/bigScreen/monitor_all/marker/nyfbt/wcld.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                            id: "",
                            state: false,
                            imgState: true,
                        },
                        {
                            name: "建设用地",
                            src: require("@image/bigScreen/monitor_all/marker/nyfbt/jsyd.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                            id: "",
                            state: false,
                            imgState: true,
                        },
                        {
                            name: "未利用地",
                            src: require("@image/bigScreen/monitor_all/marker/nyfbt/wlyd.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                            id: "",
                            state: false,
                            imgState: true,
                        },
                        {
                            name: "宜林地",
                            src: require("@image/bigScreen/monitor_all/marker/nyfbt/yild.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                            id: "",
                            state: false,
                            imgState: true,
                        },
                        {
                            name: "林业辅助生产用地",
                            src: require("@image/bigScreen/monitor_all/marker/nyfbt/lyfzscyd.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                            id: "",
                            state: false,
                            imgState: true,
                        },
                        {
                            name: "水域",
                            sourceName: "基本农田",
                            src: require("@image/bigScreen/monitor_all/marker/nyfbt/sy.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                            id: "",
                            state: false,
                            imgState: true,
                        },
                    ],
                    state: false,
                    legend: true,
                },

            ],
            nyfbtGroup: null, // 农业分布图
            nyfbtMapbox: null, //改变透明的

            // 消防专题图
            onOffListXfztt: [
                {
                    name: "地质灾害专题图",
                    sourceLayerList: [
                        "地质灾害点及地质点_point",
                    ],
                    idList: [],
                    mulList: [
                        {
                            name: "不稳定斜坡",
                            src: require("@image/bigScreen/monitor_all/marker/active/fire_special/bwdxp.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/fire_special/bwdxp.png"),
                            id: "",
                            state: false,
                            imgState: true,
                        },
                        {
                            name: "崩塌",
                            src: require("@image/bigScreen/monitor_all/marker/active/fire_special/tanta.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/fire_special/tanta.png"),
                            id: "",
                            state: false,
                            imgState: true,
                        },
                        {
                            name: "滑坡",
                            src: require("@image/bigScreen/monitor_all/marker/active/fire_special/huapo.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/fire_special/huapo.png"),
                            id: "",
                            state: false,
                            imgState: true,
                        },
                    ],
                    state: false,
                    legend: true,
                },
                {
                    name: "消防专题图",
                    sourceLayerList: [
                        "2020森林主要指标监测",
                    ],
                    idList: [],
                    mulList: [
                        {
                            name: "摄像头",
                            src: require("@image/bigScreen/monitor_all/marker/active/fire_special/sxt.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/fire_special/sxt.png"),
                            id: "",
                            state: false,
                            imgState: true,
                        },
                        {
                            name: "消防广播",
                            src: require("@image/bigScreen/monitor_all/marker/active/fire_special/fireBroadCast.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/fire_special/fireBroadCast.png"),
                            id: "",
                            state: false,
                            imgState: true,
                        },
                        {
                            name: "消火栓按钮",
                            src: require("@image/bigScreen/monitor_all/marker/active/fire_special/xfsBtn.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/fire_special/xfsBtn.png"),
                            id: "",
                            state: false,
                            imgState: true,
                        },
                        {
                            name: "室外消火栓",
                            src: require("@image/bigScreen/monitor_all/marker/active/fire_special/roomOutXhs.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/fire_special/roomOutXhs.png"),
                            id: "",
                            state: false,
                            imgState: true,
                        },
                        {
                            name: "室内消火栓",
                            src: require("@image/bigScreen/monitor_all/marker/active/fire_special/roomInXhs.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/fire_special/roomInXhs.png"),
                            id: "",
                            state: false,
                            imgState: true,
                        },
                    ],
                    state: false,
                    legend: true,
                },
            ],
            xfzttGroup: null, // 消防专题图
            xfzttMapbox: null, //改变透明的
            areaList: []
        };
    },
    created() {
        this.messagePush();
        this.getYcysIdList();
    },
    mounted() {
        this.getAreaInfo()
    },
    methods: {
        ...mapActions([
            "getVectorStyle",
            "GetRcxcXcpq",
        ]),
        //获取片区数据
        async getAreaInfo() {
            let result = await this.GetRcxcXcpq({});
            result.map((item, index) => {
                let areaItem = JSON.parse(item.QYZB);
                this.derectionData.map((itemInner) => {
                    if (item.ID == itemInner.ID) {
                        if (itemInner.JD) {
                            Object.assign(item, itemInner);
                        } else {
                            let center = window.turf.centerOfMass(areaItem);
                            itemInner.JD = center.geometry.coordinates[0];
                            itemInner.WD = center.geometry.coordinates[1];
                            Object.assign(item, itemInner);
                        }
                    }
                });
            });
            this.areaList = result;
        },
        // 画 巡查区
        drawXcq(group,) {
            this.clearLayersGroup(this.areaGroup);
            this.areaList.map((item) => {
                let areaItem = JSON.parse(item.QYZB);
                // 巡查区
                window.L.geoJSON(areaItem, {
                    onEachFeature: (feature, layer) => {
                        layer.setStyle({
                            // fillColor: '#A2CD5A',
                            color: item.YS,
                            fillOpacity: '0'
                        })
                    },
                }).addTo(group);
            })
        },
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        // 清空 规划图
        clearGht() {
            this.onOffListVector.map((item) => {
                switch (item.name) {
                    case "规划图":
                        item.state = false
                        this.clearLayersGroup(item.group)
                        break;
                }
            });
        },
        // 画海拔 marker点
        drawAltitude(group, list) {
            this.clearLayersGroup(group);
            list.map((item) => {
                let icon = window.L.divIcon({
                    html: `<div style="
                        position:relative;
                        width:0px;
                        height:0px;
						" >
                       <div class="flex-both-center"
                        style="
                            width:22px;
                            height:22px;
                            position:relative;
                            top:-11px;
                            left:-11px;
                            border-radius:50%;
                            background:rgba(255,170,91,0.5);" >
                            <div style="width:10px;height:10px;background:rgba(255,170,91,1);border-radius:50%;"></div>
                       </div>
                        <div style="
                            width:1px;
                            height:${item.lineHeight};
                            background: rgba(255,170,91,1);
                            position:absolute;
                            top:0;
                            right:0;
                            z-index:-1;
                            transform-origin:0 0;
                            transform:rotate(${item.lineRotate})">
                        </div>
                        <div  style="
                                width:124px;
                                position:absolute;
                                top:${item.panelTop};
                                right:${item.panelRight};">
                            <div style="
                                text-align:center;
                                background-color:#364971;
                                color: #fff;
                                font-size: 14px;
                                border-top:1px solid rgba(255,170,91,1);
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                padding: 0 10px;"
                                title="${item.name}">${item.name}
                            </div>
                            <div class="flex-both-center"
                                style="
                                    background: #222e49;
                                    padding:5px 8px;
                                ">
                                <div style="color:#fff;"
                                    title="海拔：${item.altitudeHeight}米">
                                    <span>海拔：</span>
                                    <span style="
                                        color: rgba(255,170,91,1);
                                        font-size: 24px;
                                        font-family: fashionSimplifiedFont;
                                        ">
                                        ${item.altitudeHeight}</span>
                                    <span> 米</span>
                                </div>
                            </div>
                        </div>
                    </div>`,
                    iconAnchor: [0, 0],
                    className: "",
                });
                window.L.marker([item.lat, item.lng], {
                    icon: icon,
                }).addTo(group);
            });
        },
        // 天地图影像地图注记
        loadMapNote(group) {
            new window.L.TileLayer.WMTS(
                `http://t{s}.tianditu.gov.cn/cia_w/wmts?tk=${window.tk}`,
                {
                    zIndex: 5,
                    tileSize: 256,
                    layer: "cia",
                    style: "default",
                    tilematrixSet: "w",
                    crs: window.L.CRS.EPSG3857,
                    format: "tiles",
                    subdomains: "01234567",
                    minZoom: 3,
                    maxZoom: 17,
                    isBaseLayer: true,
                }
            ).addTo(group);
            this.common.showVectorLayers();
        },
        // 国家森林公园
        async getGjslgyData(group, url) {
            let layerStyle = await this.getVectorStyle({
                url: url,
            });
            this.onOffListVector.map((item) => {
                if (item.name == "国家森林公园") {
                    // 遍历获取数据
                    layerStyle.layers.map((itemLayers) => {
                        item.mulList.map((itemMul) => {
                            if (itemLayers["source-layer"] == itemMul.sourceName) {
                                let obj = {
                                    id: itemLayers.id,
                                    type: itemLayers.type
                                }
                                itemMul.list.push(obj);
                            }
                        });
                    });
                }
            })
            let allProvince1 = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: layerStyle,
            }).addTo(group);
            this.common.showVectorLayers();
            this.mapboxGjslgy = allProvince1.getMapboxMap();
            this.mapboxGjslgy.on("load", () => {
                this.$nextTick(() => {
                    let myContainer = this.mapboxGjslgy.getContainer();
                    myContainer.style.zIndex = 4;
                    // myContainer.className = `leaflet-gl-layer mapboxgl-map mapboxGjslgyMap`;
                });
            });
        },
        // 画地形图
        async getDxtData(obj) {
            let group = obj.group
            let url = obj.url
            let layerStyle = await this.getVectorStyle({
                url: url,
            });
            let list = [];
            layerStyle.layers.map((item) => {
                if (
                    item["source-layer"] &&
                    item["source-layer"] != "遗产区划线"
                ) {
                    list.push(item);
                }
            });
            layerStyle.layers = Array.from(list);
            let mapboxLayer = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: layerStyle,
            }).addTo(group);
            this.common.showVectorLayers();
            obj.mapbox = mapboxLayer.getMapboxMap();
            obj.mapbox.on("load", () => {
                this.$nextTick(() => {
                    let myContainer = obj.mapbox.getContainer();
                    myContainer.className = `leaflet-gl-layer mapboxgl-map dxtMap`;
                    // myContainer.style.zIndex = 4;
                });
            });
        },
        // 画水系山脉图
        async drawSxsmt(group, url) {
            let layerStyle = await this.getVectorStyle({
                url: url,
            });
            let allProvince1 = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: layerStyle,
            }).addTo(group);
            this.common.showVectorLayers();
            let layer = allProvince1.getMapboxMap();
            layer.on("load", () => {
                this.$nextTick(() => {
                    let myContainer = layer.getContainer();
                    myContainer.className = `leaflet-gl-layer mapboxgl-map sxsmMap`;
                    // myContainer.style.zIndex = 4;
                });
            });
        },
        // 打开规划图 并且关闭 传统村落 分隔防护林 古茶林
        async drawGht(group, url) {
            let layerStyle = await this.getVectorStyle({
                url: url,
            });
            this.onOffListVector.map((item) => {
                if (item.name == "规划图") {
                    // 遍历获取数据
                    layerStyle.layers.map((itemLayers) => {
                        item.mulList.map((itemMul) => {
                            if (itemLayers["source-layer"] == itemMul.sourceName) {
                                let obj = {
                                    id: itemLayers.id,
                                    type: itemLayers.type
                                }
                                itemMul.list.push(obj);
                            }
                        });
                    });
                }
            })
            let allProvince1 = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: layerStyle,
            }).addTo(group);
            this.common.showVectorLayers();
            this.mapboxGht = allProvince1.getMapboxMap();
            this.mapboxGht.on("load", () => {
                this.$nextTick(() => {
                    let myContainer = this.mapboxGht.getContainer();
                    myContainer.className = `leaflet-gl-layer mapboxgl-map sxsmMap`;
                });
            });
            // 关闭 传统村落 分隔防护林 古茶林
            // let list = ["传统村落", "分隔防护林", "古茶林"]
            // this.changeYcysMap(list, 0)
        },
        // 消息推送部分
        messagePush() {
            // 火警 TestFireWarnSignalR
            window.signalr_connection.on("FireAlarm", (data) => {
                let resultHj = JSON.parse(data);
                // 未解除
                if (resultHj.JCZT == "0") {
                    this.playMusic();
                    resultHj.type = 0; // 0 火警
                    resultHj.name = resultHj.FSDD;
                    resultHj.time = resultHj.FSSJ;
                    this.warnPart.list.push(resultHj);
                    this.warnPart.dialog = true;
                    this.drawFireBurn(this.warnPart.group, [resultHj]);
                } else {
                    // 已解除
                    this.warnPart.list.map((item, index) => {
                        if (item.ID == resultHj.ID) {
                            this.warnPart.list.splice(index, 1);
                            let group = this.warnPart.group;
                            Object.values(group._layers).forEach((item) => {
                                if (item.options.data.ID == resultHj.ID) {
                                    item.remove();
                                }
                            });
                        }
                    });
                    this.getHistoryHjList();
                }
            });
            window.signalr_connection.on("EarlyWarning", (data) => {
                let resultYj = JSON.parse(data);
                if (resultYj.YJZT == "0") {
                    //  `YJZT` int(2) DEFAULT '0' COMMENT '预警状态0：未处置，2：无效预警   1：处置中 3：解除',
                    this.playMusic();
                    resultYj.type = 1; // 0 预警
                    resultYj.name = resultYj.YJSJ;
                    resultYj.time = resultYj.FSYJSJ;
                    this.warnPart.list.push(resultYj);
                    this.warnPart.dialog = true;
                    this.drawMarkerYjMsg(this.warnPart.group, [resultYj]);
                } else {
                    // 已解除
                    this.warnPart.list.map((item, index) => {
                        if (item.ID == resultYj.ID) {
                            this.warnPart.list.splice(index, 1);
                            let group = this.warnPart.group;
                            Object.values(group._layers).forEach((item) => {
                                if (item.options.data.ID == resultYj.ID) {
                                    item.remove();
                                }
                            });
                        }
                    });
                    this.getOthersYj();
                }
            });
        },
        // 播放报警铃声
        playMusic() {
            let playPromise = this.$refs.myAudio.play();
            playPromise
                .then(() => {
                    if (
                        this.$refs.myAudio.duration == "NaN" ||
                        this.$refs.myAudio.currentTime == undefined
                    ) {
                        this.playMusic();
                    }
                })
                .catch(() => {
                    // this.playMusic();
                });
        },
        // 关闭报警消息
        closeMsgDialog() {
            this.warnPart.dialog = false;
            this.$refs.myAudio.stop();
        },
        // 暂停铃声
        stopWarn() {
            this.$refs.myAudio.pause();
        },
        // 遗产要素ID列表
        async getYcysIdList() {
            let layerStyle = await this.getVectorStyle({
                url: window.MAP_ZTT,
            });
            // 遍历获取数据
            layerStyle.layers.map((item) => {
                this.onOffList.map((itemInner) => {
                    itemInner.sourceLayerList.map((itemInnerInner) => {
                        if (item["source-layer"] == itemInnerInner) {
                            if (item.id != "15") {
                                itemInner.idList.push(item.id);
                            }
                        }
                    });
                });
            });
        },
        // 加载 传统村落 古茶林 分隔防护林
        async getCtclFgfhlGcl(group) {
            let layerStyle = await this.getVectorStyle({
                url: window.MAP_ZTT,
            });

            let objBg = JSON.parse(JSON.stringify(layerStyle))
            let objOther = JSON.parse(JSON.stringify(layerStyle))
            let layersBg = []
            let layersOthers = []
            // let list = ["古茶林", "分隔防护林", "遗产要素村寨",]
            let list = ["6", "7", "8",]
            objBg.layers.map((item) => {
                if (item.type == "background") {
                    layersBg.push(item);
                } else {
                    // 初始化 把不显示的图层 透明度隐藏
                    let type = null;
                    if (item.type == "line") {
                        type = "line-opacity";
                    } else {
                        type = "fill-opacity";
                    }
                    if (list.includes(item['id'])) {
                        if (item['id'] == "8") {
                            item.paint['fill-opacity'] = 1
                        } else {
                            item.paint['fill-opacity'] = 0.8
                        }
                    }
                    let hidelist = ['国保单位保护范围', "国保单位建设控制地带", '土地利用',]
                    if (hidelist.includes(item['source-layer'])) {
                        item.paint[type] = 0
                    }
                    let hidelistNew = ['15',]
                    if (!hidelistNew.includes(item['id'])) {
                        layersOthers.push(item);
                    }
                }
            });
            objOther.layers = layersOthers
            objBg.layers = layersBg
            let allProvince1 = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: objOther,
            }).addTo(group);
            this.basicBg.layer = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: objBg,
            })
            this.common.showVectorLayers();
            this.mapboxMap = allProvince1.getMapboxMap();
            this.mapboxMap.on("load", () => {
                let hidelist = ["保护范围、建控地带", '土地利用',]
                this.changeYcysMap(hidelist, 0)
                // let ary = this.mapboxMap.queryRenderedFeatures()
            });
        },
        // 显示 隐藏 遗产要素 某一个块区
        changeYcysMap(list, num) {
            this.onOffList.map((item) => {
                let type = null;
                if (
                    item.name == "遗产区、缓冲区" || item.name == "保护范围、建控地带"
                ) {
                    type = "line-opacity";
                } else {
                    type = "fill-opacity";
                }
                if (list.includes(item.name)) {
                    if (item.name == "传统村落") {
                        item.idList.map((itemInner) => {
                            this.mapboxMap.setPaintProperty(
                                itemInner,
                                type,
                                num ? 1 : 0
                            );
                        });
                    } else {
                        item.idList.map((itemInner) => {
                            this.mapboxMap.setPaintProperty(
                                itemInner,
                                type,
                                num
                            );
                        });
                    }

                }
            });
        },
        // 显示隐藏片区
        changePq(list, num) {
            list.map((item) => {
                this.changeYcysMap([item], num);
            });
        },
        // 遗产要素类型 选中效果动画
        ycysTypeAnimate() {
            this.onOffList.map((item) => {
                if (item.name == this.jczlYcysTypeItem.name) {
                    item.idList.map((itemInner) => {
                        setTimeout(() => {
                            this.mapboxMap.setPaintProperty(
                                itemInner,
                                "fill-opacity",
                                0
                            );
                        }, 400);
                        setTimeout(() => {
                            this.mapboxMap.setPaintProperty(
                                itemInner,
                                "fill-opacity",
                                item.name == "传统村落" ? 1 : 0.8
                            );
                        }, 800);
                    });
                }
            });
        },
        // 遗产要素 选中效果动画
        ycysAnimate(obj) {
            this.onOffListCom.map((itemGroup) => {
                if (itemGroup.name == "遗产要素") {
                    Object.values(itemGroup.group._layers).forEach((item) => {
                        Object.values(item._featureGroup._layers).forEach((itemInner) => {
                            if (itemInner.options.data && itemInner.options.data.BM == obj.BM) {
                                setTimeout(() => {
                                    itemInner.setOpacity(0.2);
                                }, 1000);
                                setTimeout(() => {
                                    itemInner.setOpacity(1);
                                }, 1500);
                                setTimeout(() => {
                                    itemInner.setOpacity(0.2);
                                }, 2000);
                                setTimeout(() => {
                                    itemInner.setOpacity(1);
                                }, 2500);
                            }
                        })
                    })
                }
            });
        },
        formatYcysList(num) {
            let list = []
            // 土地利用
            if (num == 1) {
                this.onOffList.map((item) => {
                    if (item.name == "土地利用") {
                        list.push(item)
                    }
                })
            } else {
                this.onOffList.map((item) => {
                    if (item.name != "土地利用") {
                        list.push(item)
                    }
                })
            }
            return list
        },
        // 加载高清影像
        loadGqyx(group) {
            this.clearLayersGroup(group);
            if(this.mapTime == "2021"){
                new window.L.tileLayer(window.MAP_GQXY_50, {
                    minZoom: 0,
                    maxZoom: 20,
                    unlimited: true,
                }).addTo(group);
                new window.L.tileLayer(window.MAP_GQXY_10, {
                    minZoom: 0,
                    maxZoom: 22,
                }).addTo(group);
            }else{
                new window.L.tileLayer(window.MAP_GQXY_50_2022, {
                    minZoom: 0,
                    maxZoom: 20,
                    unlimited: true,
                }).addTo(group);
                new window.L.tileLayer(window.MAP_GQXY_10_2022, {
                    minZoom: 0,
                    maxZoom: 22,
                }).addTo(group);
            }
           
           
            this.getWmtsLayer("img").addTo(group); // 影像地图
            this.common.showVectorLayers();
            // let myContainer = this.ycyHcq._glMap.getContainer();
            // myContainer.style.zIndex = 50;
        },
        // 加载WMTS图层
        getWmtsLayer(layerName) {
            let url = `https://t{s}.tianditu.gov.cn/${layerName}_w/wmts`;
            let layer = new window.L.TileLayer.WMTS(url, {
                tk: window.tk,
                zIndex: 0,
                tileSize: 256,
                crs: window.L.CRS.EPSG3857,
                layer: layerName,
                style: "default",
                tilematrixSet: "w",
                format: "tiles",
                subdomains: "01234567",
                minZoom: 3,
                maxZoom: 17,
                isBaseLayer: true,
            });
            return layer;
        },
        // 农业分布图 画
        async drawNyfbt(group, url) {
            this.clearLayersGroup(group);
            let layerStyle = await this.getVectorStyle({
                url: url,
            });
            layerStyle.layers.map((item) => {
                if (item.type == "background") {
                    return;
                }
                item.paint["fill-opacity"] = 0
                if (item["source-layer"] == '2020森林主要指标监测') {
                    this.onOffListNyfbt[1].idList.push(item.id);
                    this.onOffListNyfbt[1].mulList.map((itemInner) => {
                        if (item.id == "0") {
                        } else if (itemInner.name == item.filter[2]) {
                            itemInner.id = item.id
                        }
                    })
                } else {
                    this.onOffListNyfbt[0].idList.push(item.id);
                }
            });
            let allProvince1 = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: layerStyle,
            }).addTo(group);
            this.common.showVectorLayers();
            this.nyfbtMapbox = allProvince1.getMapboxMap();
            this.nyfbtMapbox.on("load", () => {
                this.onOffListNyfbt.map((item) => {
                    item.idList.map((itemInner) => {
                        this.nyfbtMapbox.setPaintProperty(
                            itemInner,
                            "fill-opacity",
                            0
                        );
                    });
                });
            });
        },
        // 农业分布图 画 更改图层 
        switchStateNyfbt(state, item, index) {
            item.state = state
            let num = 1
            if (!state) {
                num = 0
            }
            item.idList.map((item) => {
                this.nyfbtMapbox.setPaintProperty(
                    item,
                    "fill-opacity",
                    num
                );
            });
            if (item.name == "农业用地") {
                item.imgState = true
            } else {
                item.mulList.map((itemInner) => {
                    itemInner.imgState = true
                })
            }
        },
        // 图例 点击 农业用地
        legendChangeNyyd(item) {
            item.imgState = !item.imgState;
            let num = 1
            if (!item.imgState) {
                num = 0
            }
            item.idList.map((itemInner) => {
                this.nyfbtMapbox.setPaintProperty(
                    itemInner,
                    "fill-opacity",
                    num
                );
            })
        },
        // 图例 点击 林业用地
        legendChangeLyyd(item) {
            item.imgState = !item.imgState;
            let num = 1
            if (!item.imgState) {
                num = 0
            }
            this.nyfbtMapbox.setPaintProperty(
                item.id,
                "fill-opacity",
                num
            );
        },
        // 消防专题图 画
        async drawXfztt(group, url) {
            this.clearLayersGroup(group);
            let layerStyle = await this.getVectorStyle({
                url: url,
            });
            let layers = []
            let idList = ["0", "1", "2"]
            layerStyle.layers.map((item) => {
                if (idList.includes(item.id)) {
                    return;
                }
                item.paint["icon-opacity"] = 0
                layers.push(item)
                if (item["source-layer"] == '地质灾害点及地质点_point') {
                    this.onOffListXfztt[0].idList.push(item.id);
                    this.onOffListXfztt[0].mulList.map((itemInner) => {
                        if (itemInner.name == item.filter[2]) {
                            itemInner.id = item.id
                        }
                    })
                } else {
                    this.onOffListXfztt[1].idList.push(item.id);
                    this.onOffListXfztt[1].mulList.map((itemInner) => {
                        if (itemInner.name == item["source-layer"]) {
                            itemInner.id = item.id
                        }
                    })
                }
            });
            layerStyle.layers = layers
            let allProvince1 = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: layerStyle,
            }).addTo(group);
            this.common.showVectorLayers();
            this.xfzttMapbox = allProvince1.getMapboxMap();
            this.xfzttMapbox.on("load", () => {
                let myContainer = this.xfzttMapbox.getContainer();
                myContainer.style.zIndex = 20;
                let ary = this.xfzttMapbox.queryRenderedFeatures()
                this.xfzttMapbox.on("click", e => {
                    let tilesAty = this.xfzttMapbox.queryRenderedFeatures(e.point)

                    if (tilesAty.length > 0) {
                        console.log('%c 🍿 tilesAty: ', 'font-size:20px;background-color: #F5CE50;color:#fff;', tilesAty);
                    }
                })
                this.xfzttMapbox.on('mousemove', (e) => {
                    let tilesAty = this.mapboxMap.queryRenderedFeatures(e.point);
                    console.log('%c 🍰 tilesAty: ', 'font-size:20px;background-color: #33A5FF;color:#fff;', tilesAty);
                })
            });

        },
        // 消防专题图 更改图层
        switchStateXfztt(state, item, index) {
            item.state = state
            let num = 1
            if (!state) {
                num = 0
            }
            item.idList.map((item) => {
                this.xfzttMapbox.setPaintProperty(
                    item,
                    "icon-opacity",
                    num
                );
            });
        },
        // 图例 点击 消防专题图
        legendChangeDzzh(item) {
            item.imgState = !item.imgState;
            let num = 1
            if (!item.imgState) {
                num = 0
            }
            this.xfzttMapbox.setPaintProperty(
                item.id,
                "icon-opacity",
                num
            );
        },

    },
};