<template>
    <div class="rightPart scrollbar-hidden">
        <BoxTitle :title="basicData.YCMC" @handleClose="handleClose"></BoxTitle>
        <div class="titlePart flex-between">
            <div class="titleLeft">
                <div class="listPart flex-between">
                    <div
                        v-for="(item, index) in listAry"
                        :key="index"
                        class="listItem animated bounceIn"
                        :style="{ animationDelay: 0.2 + index * 0.2 + 's' }"
                    >
                        <div class="title flex-both-center">
                            {{ item.name }}
                        </div>
                        <div class="num flex-both-center">{{ item.num }}</div>
                    </div>
                </div>
            </div>
            <div class="titleCenter animated bounceIn">
                <div class="happenTime">
                    <span class="label">发生时间:</span>
                    <span>{{ happenTime }}</span>
                </div>
                <div class="endTime" v-if="endTime">
                    <span class="label">结束时间:</span>
                    <span>{{ endTime }}</span>
                </div>
            </div>
            <div class="titleRight animated bounceIn">
                <img :src="solvedState ? solvedPic : solvedNoPic" />
            </div>
        </div>
        <!-- 基本信息 -->
        <div class="baseInfoPart">
            <div class="state" v-if="basicData.STATE">
                <span
                    class="name"
                    :style="{
                        color: basicData.color,
                        border: `1px solid ${basicData.color}`,
                        padding: `2px 4px`,
                    }"
                >
                    {{ basicData.STATE }}
                </span>
            </div>
            <!-- 基本信息 -->
            <!-- 上报 -->
            <basic-module :text="common.getFyName(fany.sb)">
                <div slot="basic-module" class="baseInfoCtn flex-between">
                    <div class="photoPart carouselArrowShow">
                        <el-carousel
                            v-if="basicData.listAry.length"
                            indicator-position="none"
                            height="265px"
                            :autoplay="true"
                            :arrow="
                                basicData.listAry.length > 1 ? 'hover' : 'never'
                            "
                        >
                            <el-carousel-item
                                v-for="(item, index) in basicData.listAry"
                                :key="index"
                                class="pointer"
                            >
                                <el-image
                                    v-if="item.type == 'img'"
                                    :src="common.getThumbnailImg(item.src)"
                                    fit="cover"
                                    class="pointer"
                                    style="width: 100%; height: 100%"
                                    @click="openBasicDetail(index)"
                                >
                                    <div
                                        slot="error"
                                        style="width: 100%; height: 100%"
                                    >
                                        <SnNoData width="150px"></SnNoData>
                                    </div>
                                </el-image>
                                <video
                                    :id="`video_${index}`"
                                    index
                                    v-else
                                    :src="item.src"
                                    controls
                                    style="width: 100%; height: 100%"
                                />
                            </el-carousel-item>
                        </el-carousel>
                        <SnNoData v-else width="150px"></SnNoData>
                    </div>

                    <div class="listPart scrollbar-hidden">
                        <div class="listItem">
                            <span class="name">发生地点</span>
                            <span class="value" :title="basicData.FSDD">
                                {{ basicData.FSDD }}
                            </span>
                        </div>
                        <div class="listItem">
                            <span class="name">巡查员</span>
                            <span class="value">
                                <span style="margin-right: 10px">{{
                                    basicData.XCY
                                }}</span>
                                <span>{{ basicData.DEPARTMENTNAME }}</span>
                            </span>
                        </div>
                        <div class="listItem">
                            <span class="name">事件描述</span>
                            <span class="value" :title="basicData.SJMS">
                                {{ basicData.SJMS }}
                            </span>
                        </div>
                        <div class="listItem">
                            <span class="name">当时采取的措施</span>
                            <span class="value" :title="basicData.DSCQDCS">
                                {{ basicData.DSCQDCS }}
                            </span>
                        </div>
                    </div>
                </div>
            </basic-module>
        </div>
        <!-- 跟踪 处置 -->
        <div class="followInfoPart">
            <!-- 转发,处置,跟踪 -->
            <basic-module
                @getTextItem="getTextItem"
                ref="dealFollow"
                :text="common.getFyName(fany.zfczgz)"
            >
                <div slot="basic-module" class="followInfoCtn">
                    <div v-if="trackDetail.name == '转发' || trackDetail.name == 'Forward'">
                        <div class="followDetail flex-between">
                            <div class="photoPart carouselArrowShow">
                                <el-image
                                    :src="
                                        common.getThumbnailImg(
                                            forwardPart.YDDJZTLJ
                                        )
                                    "
                                    fit="cover"
                                    class="pointer"
                                    style="width: 100%; height: 100%"
                                >
                                    <div
                                        slot="error"
                                        style="width: 100%; height: 100%"
                                    >
                                        <SnNoData width="150px"></SnNoData>
                                    </div>
                                </el-image>
                            </div>
                            <div class="listPart zfPart">
                                <div class="listItem">
                                    <span class="name">转发单位</span>
                                    <span class="value">
                                        {{ forwardPart.ZFDEPMC }}
                                    </span>
                                </div>
                                <div class="listItem">
                                    <span class="name"> 转发时间 </span>
                                    <span
                                        class="value ellipsis"
                                        :title="forwardPart.ZFSJ"
                                    >
                                        {{ forwardPart.ZFSJ.split(" ")[0] }}
                                    </span>
                                </div>
                                <div class="listItem flex">
                                    <span class="name">处置单位</span>
                                    <span
                                        class="value pointer flex-1"
                                        :title="forwardPart.CZDEPMC"
                                    >
                                        {{ forwardPart.CZDEPMC }}
                                    </span>
                                </div>
                                <div class="listItem flex">
                                    <span class="name">处置人</span>
                                    <span
                                        class="value pointer flex-1"
                                        :title="forwardPart.CZRMC"
                                    >
                                        {{ forwardPart.CZRMC }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div
                            class="followDetail flex-between"
                            v-if="trackDetail.listAry.length"
                        >
                            <div class="photoPart carouselArrowShow">
                                <el-carousel
                                    v-if="trackDetail.mediaList.length"
                                    indicator-position="none"
                                    height="257px"
                                    :autoplay="false"
                                    :arrow="
                                        trackDetail.mediaList.length > 1
                                            ? 'hover'
                                            : 'never'
                                    "
                                >
                                    <el-carousel-item
                                        v-for="(
                                            item, index
                                        ) in trackDetail.mediaList"
                                        :key="index"
                                        class="pointer"
                                    >
                                        <el-image
                                            v-if="item.type == 'img'"
                                            :src="
                                                common.getThumbnailImg(item.src)
                                            "
                                            fit="cover"
                                            class="pointer"
                                            style="width: 100%; height: 100%"
                                            @click="openFollowDetail(item)"
                                        >
                                            <div
                                                slot="error"
                                                style="
                                                    width: 100%;
                                                    height: 100%;
                                                "
                                            >
                                                <SnNoData
                                                    width="150px"
                                                ></SnNoData>
                                            </div>
                                        </el-image>
                                        <video
                                            :id="`video_${index}`"
                                            index
                                            v-else
                                            :src="item.src"
                                            controls
                                            style="width: 100%; height: 100%"
                                        />
                                    </el-carousel-item>
                                </el-carousel>
                                <SnNoData v-else width="150px"></SnNoData>
                            </div>

                            <div class="listPart">
                                <div class="listItem">
                                    <span class="name">巡查员</span>
                                    <span class="value">
                                        {{ trackDetail.XCRY }}
                                    </span>
                                </div>
                                <div class="listItem">
                                    <span class="name">
                                        {{ getDwName() }}单位
                                    </span>
                                    <span
                                        class="value ellipsis"
                                        :title="trackDetail.DEPARTMENTNAME"
                                    >
                                        {{ trackDetail.DEPARTMENTNAME }}
                                    </span>
                                </div>
                                <div class="listItem flex">
                                    <span class="name">情况描述</span>
                                    <span
                                        class="
                                            value
                                            pointer
                                            scrollbar-hidden
                                            flex-1
                                        "
                                        :title="trackDetail.SJMS"
                                        style="
                                            height: 184px;
                                            display: inline-block;
                                        "
                                    >
                                        {{ trackDetail.SJMS }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="timePart" v-if="trackDetail.listAry.length">
                            <TimeLine
                                ref="timeLine"
                                :listAry="trackDetail.listAry"
                                timeKey="XCSJ"
                                @handleTimeLine="handleTimeLine"
                            ></TimeLine>
                        </div>
                        <SnNoData
                            v-if="!trackDetail.listAry.length"
                            :noDataState="true"
                            :noDataText="`暂无信息`"
                            width="150px"
                        ></SnNoData>
                    </div>
                </div>
            </basic-module>
        </div>

        <ViewerAlert ref="myImgList" :imgList="imgList"></ViewerAlert>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import BoxTitle from "@views/big_screen/comp/BoxTitle.vue"; //标题
import TimeLine from "@views/big_screen/comp/TimeLine.vue"; //时间线
import ViewerAlert from "@comp/ViewerAlert.vue"; //大图查看
export default {
    components: {
        BoxTitle,
        TimeLine,
        ViewerAlert,
    },
    name: "",
    data() {
        return {
            imgList: [],
            happenTime: "",
            endTime: "",
            listAry: [
                {
                    name: "历时天数",
                    num: 0,
                },
            ],
            solvedState: true,
            solvedPic: require("@image/bigScreen/daily_inspect/solved.png"),
            solvedNoPic: require("@image/bigScreen/daily_inspect/solveNo.png"),
            basicData: {
                color: "",
                YCMC: "",
                listAry: [],
                imgList: [],
                videoList: [],
                STATE: "",
                LJ: "",
                FSDD: "",
                XCY: "",
                SJMS: "",
                DSCQDCS: "",
                DEPARTMENTNAME: "", //单位
            },
            trackDetail: {
                name: "转发",
                listAry: [], //当前列表
                dealList: [], //处置列表
                followList: [], //跟踪列表
                forwardList: [], //转发列表
                mediaList: [], //图片 或者视频列表
                SJMS: "", //描述
                XCRY: "", //巡查员
                XCSJ: "", //巡查时间
                DEPARTMENTNAME: "", //单位
                CZDEPMC: "", //处置单位
            },
            forwardPart: {
                CZDEPMC: "",
                CZRMC: "",
                YDDJZTLJ: "",
                ZFDEPMC: "",
                ZFSJ: "",
            },
            imgVideoList: [],
        };
    },
    computed: {
        ...mapGetters({
            jczlFyNum: TYPES.jczlFyNum,
            rxcxYcdwId: TYPES.rxcxYcdwId,
            rcxcAreaItem: TYPES.rcxcAreaItem,
        }),
    },
    props: {},
    watch: {
        rxcxYcdwId: {
            immediate: true,
            deep: true,
            handler() {
                if (this.rxcxYcdwId) {
                    this.getYcdwInfo();
                }
            },
        },
    },
    mounted() {},
    methods: {
        ...mapActions(["GetRcxcJbxxYcgz"]),
        ...mapMutations({
            SET_RCXCPANELNUM: TYPES.SET_RCXCPANELNUM,
            SET_RCXCYCDWID: TYPES.SET_RCXCYCDWID,
        }),
        // 获取 异常点位 信息
        async getYcdwInfo() {
            let result = await this.GetRcxcJbxxYcgz({
                ycid: this.rxcxYcdwId,
            });
            let baseObj = result.ZBXX[0];
            // 基本信息
            this.listAry[0].num = baseObj.LSTS;
            this.happenTime = baseObj.FSSJ;
            this.endTime = baseObj.JCSJ;
            if (baseObj.YCSFJC == "未解除") {
                this.solvedState = false;
            } else {
                this.solvedState = true;
            }
            this.basicData.STATE = baseObj.PGNAME;
            this.basicData.DSCQDCS = baseObj.DSCQDCS;
            this.basicData.DEPARTMENTNAME = baseObj.DEPARTMENTNAME;
            this.basicData.color = this.common.getYcysStateColor(
                baseObj.PGNAME
            );
            this.basicData.YCMC = baseObj.YCMC;
            this.basicData.LJ = baseObj.LJ.length ? baseObj.LJ[0] : "";
            this.basicData.listAry = [];
            baseObj.LJ.map((item) => {
                let obj = {
                    type: "img",
                    src: item,
                };
                this.basicData.listAry.push(obj);
            });
            baseObj.VIDEOLJ.map((item) => {
                let obj = {
                    type: "video",
                    src: item,
                };
                this.basicData.listAry.push(obj);
            });
            this.basicData.imgList = baseObj.LJ;
            this.basicData.videoList = baseObj.VIDEOLJ;

            this.basicData.FSDD = baseObj.XCDD;
            this.basicData.XCY = baseObj.XCRY;
            this.basicData.SJMS = baseObj.SJMS;
            this.trackDetail.name = "转发";
            //0 汉语 1 英语
            if(this.jczlFyNum){
                this.trackDetail.name = "Forward";
            }
           
            // Forward
            this.$refs.dealFollow.resetActive();
            // 处置数据
            this.trackDetail.dealList = result.CZJL;
            // 跟踪数据
            this.trackDetail.followList = result.GZJL;
            // 转发数据
            result.ZFDT.map((item) => {
                item.XCSJ = item.ZFSJ;
                item.DEPARTMENTNAME = item.ZFDEPMC;
                item.XCRY = item.CZRMC;
            });
            if (result.ZFDT.length) {
                this.forwardPart = result.ZFDT[0];
            } else {
                this.forwardPart = {
                    CZDEPMC: "",
                    CZRMC: "",
                    YDDJZTLJ: "",
                    ZFDEPMC: "",
                    ZFSJ: "",
                };
            }
        },
        // 点击处置 跟踪
        getTextItem(name) {
            this.trackDetail.name = name;
            switch (name) {
                case "处置":
                case "Deal with":
                    this.trackDetail.listAry = this.trackDetail.dealList;
                    break;
                case "跟踪":
                case "Track":
                    this.trackDetail.listAry = this.trackDetail.followList;
                    break;
            }
            this.resetDealFollowInfo(this.trackDetail.listAry);
        },
        resetDealFollowInfo(list) {
            this.trackDetail.SJMS = "";
            this.trackDetail.XCRY = "";
            this.trackDetail.XCSJ = "";
            this.trackDetail.DEPARTMENTNAME = "";
            this.trackDetail.mediaList = [];
            if (list.length) {
                let obj = list[0];
                this.trackDetail.SJMS = obj.SJMS;
                this.trackDetail.XCRY = obj.XCRY;
                this.trackDetail.XCSJ = obj.XCSJ;
                this.trackDetail.DEPARTMENTNAME = obj.DEPARTMENTNAME;
                this.trackDetail.mediaList = [];
                obj.LJ.map((item) => {
                    let obj = {
                        type: "img",
                        src: item,
                    };
                    this.trackDetail.mediaList.push(obj);
                });
                obj.VIDEOLJ.map((item) => {
                    let obj = {
                        type: "video",
                        src: item,
                    };
                    this.trackDetail.mediaList.push(obj);
                });
                this.$nextTick(() => {
                    this.$refs.timeLine.resetTimeLine(0);
                });
            }
        },
        // 点击圆圈
        handleTimeLine(obj, index) {
            this.trackDetail.mediaList = [];
            obj.LJ.map((item) => {
                let obj = {
                    type: "img",
                    src: item,
                };
                this.trackDetail.mediaList.push(obj);
            });
            obj.VIDEOLJ.map((item) => {
                let obj = {
                    type: "video",
                    src: item,
                };
                this.trackDetail.mediaList.push(obj);
            });
            this.trackDetail.SJMS = obj.SJMS;
            this.trackDetail.XCRY = obj.XCRY;
            this.trackDetail.XCSJ = obj.XCSJ;
        },
        //日常点位里的 图片 或者视频
        openBasicDetail(index) {
            let ary = [];
            this.basicData.imgList.map((item) => {
                let obj = {
                    alt: this.basicData.SJMS,
                    src: item,
                    // thumbnail: this.common.getThumbnailImg(item.TPLJ),
                };
                ary.push(obj);
            });
            this.imgList = Array.from(ary);
            if (this.imgList.length) {
                this.$refs.myImgList.showViewer(index);
            }
        },
        // 点击跟踪数据的图片
        openFollowDetail(item) {
            let ary = [];
            let obj = {
                alt: `${this.trackDetail.SJMS} ${this.trackDetail.XCSJ}`,
                src: item.src,
                // thumbnail: this.common.getThumbnailImg(item.TPLJ),
            };
            ary.push(obj);
            this.imgList = Array.from(ary);
            if (this.imgList.length) {
                this.$refs.myImgList.showViewer();
            }
        },
        handleClose() {
            this.SET_RCXCYCDWID(null);
            if (this.rcxcAreaItem) {
                this.SET_RCXCPANELNUM(1);
            } else {
                this.SET_RCXCPANELNUM(0);
            }
        },
        getDwName(){
            let name = ""
            switch (this.trackDetail.name) {
                case "Deal with":
                case "处置":
                    name = "处置"
                    break;
                case "Track":
                case "跟踪":
                    name = "跟踪"
                    break;
            }
            return name
        }
    },
};
</script>
<style scoped lang="scss">
.rightPart {
    width: 100%;
    height: 100%;
    background: rgba(26, 56, 89, 0.3);
    position: relative;
    .titlePart {
        width: 100%;
        margin-top: 17px;
        padding: 0 24px;
        .titleLeft {
            .listPart {
                width: 100%;
                height: 108px;
                .listItem {
                    width: 162px;
                    height: 100%;

                    .title {
                        width: 100%;
                        height: 40px;
                        background: url("~@image/bigScreen/icon/titleBg.png")
                            no-repeat;
                        background-size: 100% 100%;
                        font-size: 18px;
                        color: rgba(168, 200, 255, 1);
                    }
                    .num {
                        margin-top: 10px;
                        height: calc(100% - 48px);
                        border: 1px solid rgba(53, 80, 124, 0.4);
                        font-size: 48px;
                        color: rgba(189, 215, 236, 1);
                        font-family: DBPM;
                    }
                }
            }
        }
        .titleCenter {
            width: 302px;
            height: 110px;
            background: url("~@image/bigScreen/daily_inspect/topBg.png");
            background-size: 100% 100%;
            text-align: center;
            font-size: 18px;
            color: rgba(168, 200, 255, 1);
            .happenTime {
                margin-top: 14px;
            }
            .endTime {
                margin-top: 30px;
            }
            .label {
                margin-right: 20px;
            }
        }
        .titleRight {
        }
    }

    // 基本信息
    .baseInfoPart {
        width: 100%;
        height: 348px;
        position: relative;
        .state {
            position: absolute;
            right: 24px;
            top: 20px;
            z-index: 10;
        }
        .baseInfoCtn {
            width: 100%;
            height: 100%;
            .photoPart {
                width: calc(50% - 5px);
                height: 265px;
                position: relative;
            }
            .listPart {
                width: calc(50% - 5px);
                padding-top: 2px;
                .listItem {
                    width: 100%;
                    flex: 1;
                    margin-bottom: 10px;
                    .name {
                        height: 25px;
                        line-height: 25px;
                        text-align: left;
                        color: rgba(86, 115, 162, 1);
                        font-size: 16px;
                    }
                    .value {
                        min-height: 35px;
                        line-height: 35px;
                        text-align: left;
                        background: rgba(26, 48, 89, 0.3);
                        color: #d7eaf5;
                        padding-left: 10px;
                        font-size: 16px;
                    }
                    .check {
                        overflow: hidden;
                        line-height: 30px;
                        font-size: 14px;
                        height: 60px;
                    }
                }
            }
        }
    }
    // 跟踪信息
    .followInfoPart {
        width: 100%;
        height: 390px;
        .followInfoCtn {
            width: 100%;
            height: 100%;
            position: relative;
            .followDetail {
                height: 257px;
                .listPart {
                    width: calc(50% - 5px);
                    .listItem {
                        width: 100%;
                        flex: 1;
                        .name {
                            height: 35px;
                            line-height: 35px;
                            text-align: left;
                            color: rgba(86, 115, 162, 1);
                            font-size: 16px;
                        }
                        .value {
                            height: 35px;
                            line-height: 35px;
                            text-align: left;
                            background: rgba(26, 48, 89, 0.3);
                            color: #d7eaf5;
                            padding-left: 10px;
                            font-size: 16px;
                        }
                        .check {
                            overflow: hidden;
                            line-height: 30px;
                            font-size: 14px;
                            height: 60px;
                        }
                    }
                }
                .zfPart {
                    .listItem {
                        margin-bottom: 30px;
                    }
                }
                .photoPart {
                    width: calc(50% - 5px);
                    height: 257px;
                    position: relative;
                }
            }
            .timePart {
                height: 40px;
                margin-top: 14px;
            }
        }
    }
    .imgVideoPart {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 908px;
        background: rgba(0, 0, 0, 0.8);
        z-index: 100;
    }
}
</style>
