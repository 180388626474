<template>
    <div class="rightPart scrollbar-hidden">
        <BoxTitle
            :title="btbhYcys.MC || '暂无要素名称'"
            @handleClose="handleClose"
        />
        <div class="ztbcztPart">
            <saveState
                class="ztbczt"
                :stateName="stateName"
                :stateType="btbhYcys.ZTNAME || '暂未评估'"
            ></saveState>
            <div class="ztChart" :id="'ztChart' + chartID"></div>
        </div>
        <div class="bhkzPart">
            <!-- 病害控制 -->
            <basic-module :text="common.getFyName(fany.bhkz)">
                <div slot="basic-module" class="bhkzCtn">
                    <div class="allCount">
                        <div
                            v-for="(item, index) in allCountList"
                            :key="index"
                            class="itemCount"
                        >
                            <span class="name">{{ item.name }}</span>
                            <span class="num">{{ item.value || 0 }}</span>
                        </div>
                    </div>
                    <div class="bhCount flex">
                        <div
                            v-for="(item, index) in bhList"
                            :key="index"
                            class="ysbcztBox"
                            :class="'ysbcztBox' + index"
                        >
                            <bczkBox
                                :chartData="item"
                                :allNum="allNum"
                            ></bczkBox>
                        </div>
                    </div>
                    <div class="bhChart">
                        <bhkzBox
                            :chartData="chartData"
                            :lengendData="lengendData"
                        ></bhkzBox>
                    </div>
                </div>
            </basic-module>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import BoxTitle from "@views/big_screen/comp/BoxTitle.vue"; //标题
import saveState from "../comp/saveState";
import bhkzBox from "../comp/bhkzBox.vue";
import bczkBox from "../comp/bczkBox.vue";
export default {
    components: {
        BoxTitle,
        saveState,
        bhkzBox,
        bczkBox,
    },
    name: "",
    data() {
        return {
            // 总体保存状况
            stateName: "总体保存状况",
            stateList: [],
            chartID: Math.random(),
            ztChart: null,
            // 病害控制
            allCountList: [
                {
                    name: "数量",
                    value: "",
                },
                {
                    name: "采集数据量",
                    value: "",
                },
            ],
            bhList: [],
            allNum: 0,
            chartData: [],
            lengendData: [],
        };
    },
    computed: {
        ...mapGetters(["btbhYcys", "jczlFyNum"]),
    },
    props: {},
    watch: {
        jczlFyNum: {
            immediate: true,
            handler() {
                this.stateName = this.common.getFyName(this.fany.ztbczt);
            },
        },
        btbhYcys: {
            deep: true,
            handler() {
                this.initPage();
            },
        },
    },
    mounted() {
        this.initPage();
    },
    methods: {
        ...mapMutations({
            setStateBtbh: "SET_STATE_BTBH",
        }),
        ...mapActions(["getYcysPgInfo", "getBhkzztpg", "getBtbhjcgkByYcys"]),
        initPage() {
            this.getBcztData();
            this.getBhkzData();
            this.getBhkzNum();
        },
        handleClose() {
            this.setStateBtbh({
                btbhPanelNum: 0, // 右侧页面切换
                btbhYcys: {}, // 被点击的遗产要素
                btbhDetail: {},
            });
        },
        async getBcztData() {
            let da = await this.getYcysPgInfo({
                ycysbm: this.btbhYcys.BM,
            });
            let res = da.sort((a, b) => {
                return new Date(a.CJSJ) - new Date(b.CJSJ);
            });
            let xAxis = res.map((item) => item.CJSJ);
            let yAxis = ["好", "；良好", "一般", "差"];
            let colorArr = ["#61FF00", "#FFE500", "#FF9B00", "#E92810"];
            let seriesData = res.map((item, index) => {
                return [index, +item.ZT];
            });
            let option = {
                tooltip: {
                    trigger: "axis",
                    formatter: (params) => {
                        let str = "<div>";
                        str += `<p style="color: #fff;">${params[0].name}</p>`;
                        str += `<p style="color: #fff;">
                            <i style="display:inline-block;border-radius:50%;width: 10px;height:10px;background:${
                                colorArr[params[0].data[1]]
                            }"></i>
                            ${yAxis[params[0].data[1]]}
                            </p>`;
                        str += "</div>";
                        return str;
                    },
                },
                grid: {
                    top: 0,
                    left: 35,
                    right: 17,
                    bottom: 20,
                },
                xAxis: {
                    type: "category",
                    data: xAxis,
                    boundaryGap: false,
                    axisLine: {
                        lineStyle: {
                            color: "#526789",
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        color: "#BDD7EC",
                        formatter: (value, index) => {
                            if (
                                index === 0 ||
                                value.slice(0, 5) !=
                                    xAxis[index - 1].slice(0, 5)
                            ) {
                                return value;
                            } else {
                                return value.slice(5);
                            }
                        },
                    },
                },
                yAxis: {
                    type: "category",
                    minInterval: 1,
                    maxInterval: 1,
                    data: yAxis,
                    axisLine: {
                        show: false,
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: ["#364966"],
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        color: "#BDD7EC",
                    },
                },
                series: [
                    {
                        data: seriesData,
                        type: "scatter",
                        symbolSize: 10,
                        itemStyle: {
                            color: (seriesIndex) => {
                                return colorArr[seriesIndex.data[1]];
                            },
                        },
                    },
                ],
            };
            if (this.ztChart) {
                this.ztChart = this.$echarts.dispose(
                    document.getElementById("ztChart" + this.chartID)
                );
            }
            this.ztChart = this.$echarts.init(
                document.getElementById("ztChart" + this.chartID)
            );
            this.ztChart.setOption(option);
        },
        async getBhkzNum() {
            let res = await this.getBtbhjcgkByYcys({
                ycysbm: this.btbhYcys.BM,
            });
            this.allCountList[0].value = res.YCYSJCBHSL;
            this.allCountList[1].value = res.YCYSJCBHJCSL;
        },
        // 控制状态2、3
        async getBhkzData() {
            let res = await this.getBhkzztpg({
                ycysbm: this.btbhYcys.BM,
            });
            res.BHLXKZZTPG.forEach((item) => {
                (item.chartID = "chart" + Math.random()),
                    (item.name = item.BHLXMC);
                item.value = item.COUNT;
            });
            this.allNum = eval(
                res.BHLXKZZTPG.map((item) => item.COUNT).join("+")
            );
            this.bhList = res.BHLXKZZTPG;
            this.lengendData = res.BHKZZTPG;
            this.chartData = res.KZZTPGINFO.map((item) => {
                return {
                    name: item.BHKZZTPGMC,
                    value: item.COUNT,
                };
            });
        },
    },
};
</script>
<style lang="scss">
</style>
<style scoped lang="scss">
.rightPart {
    width: 100%;
    height: 100%;
    padding: 15px 0;
    // 总体保存状态
    .ztbcztPart {
        padding: 0 24px;
        .ztbczt {
            height: 92px;
            margin: 15px 0;
        }
        .ztChart {
            height: 180px;
        }
    }
    // 病害控制
    .bhkzPart {
        height: 540px;
        .allCount {
            height: 40px;
            display: flex;
            justify-content: space-between;
            .itemCount {
                width: calc(50% - 9px);
                display: flex;
                text-align: center;
                height: 40px;
                line-height: 40px;
                .name {
                    width: 160px;
                    background: url("~@image/bigScreen/icon/titleBg.png")
                        no-repeat;
                    background-size: 100% 100%;
                    font-size: 18px;
                    color: rgba(168, 200, 255, 1);
                }
                .num {
                    width: calc(100% - 170px);
                    font-family: DBPM;
                    color: #ffcd80;
                    font-size: 50px;
                    border: 1px solid #1e3353;
                    margin-left: 10px;
                }
            }
        }
        .bhCount {
            margin: 15px 0;
            height: 130px;
            .ysbcztBox {
                margin-right: 10px;
            }
        }
        .bhChart {
            height: 270px;
        }
    }
}
</style>
