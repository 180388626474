// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@image/bigScreen/build_control/label.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".yjczCtn[data-v-1cdff878]{width:100%;height:100%}.yjczCtn .title[data-v-1cdff878]{width:125px;height:37px;line-height:37px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;color:#a8c8ff;font-size:16px;text-indent:20px}.yjczCtn .leftPart[data-v-1cdff878]{width:125px;margin-right:20px}.yjczCtn .leftPart .yjcz-steps[data-v-1cdff878]{margin-top:10px;height:calc(100% - 37px)}.yjczCtn .leftPart .yjcz-steps .steps-label[data-v-1cdff878]{position:relative;width:100px;height:46px;line-height:40px;text-align:center;font-size:14px;cursor:pointer;margin-bottom:10px}.yjczCtn .leftPart .yjcz-steps .steps-label .bgImg[data-v-1cdff878]{width:100%;height:100%;position:absolute;left:0;top:0}.yjczCtn .rightPart[data-v-1cdff878]{width:486px;padding-top:47px;height:100%}", ""]);
// Exports
module.exports = exports;
