<template>
    <div style="margin-top: 4px">
        <div
            v-for="(item, index) in listAry"
            :key="keyValue + index"
            class="flex-between-y-center layerItem"
        >
            <onOff
                :onOffState="item.state"
                :item="item"
                :index="index"
                @switchStateChange="switchStateChange"
            ></onOff>
            <div class="name">{{ item.name }}</div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import OnOff from "@views/big_screen/comp/OnOff.vue"; //开关
export default {
    components: {
        OnOff,
    },
    name: "",
    data() {
        return {};
    },
    computed: {
        ...mapGetters({}),
    },
    props: {
        listAry: {
            type: Array,
            default() {
                return [];
            },
        },
        keyValue: {
            type: String,
            default() {
                return "";
            },
        },
    },
    watch: {},
    mounted() {},
    methods: {
        ...mapActions([]),
        ...mapMutations({}),
        switchStateChange(state, item, index) {
            this.$emit("switchStateChange", state, item, index);
        },
    },
};
</script>
<style scoped lang="scss">
.layerItem {
    margin-bottom: 8px;
    &:nth-last-child(1) {
        margin-bottom: 0;
    }
}
.name {
    color: #fff;
    font-size: 14px;
    margin-left: 10px;
}
</style>
