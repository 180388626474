<template>
    <div class="dialogCtn scrollbar-hidden">
        <div class="topImg">
            <el-image
                v-if="baseInfo.type == 0"
                :src="baseInfo.src"
                v-viewer
                fit="cover"
                class="pointer"
                style="width: 100%; height: 100%; border-radius: 20px"
            >
                <div slot="error" style="width: 100%; height: 100%">
                    <SnNoData width="150px"></SnNoData>
                </div>
            </el-image>
            <video
                :src="baseInfo.src"
                v-if="baseInfo.type == 1"
                controls
                style="width: 100%; height: 100%"
            ></video>
        </div>
        <div class="img-list flex flex-wrap" v-if="detail.PIC.length > 1">
            <div
                v-for="(item, index) in detail.PIC"
                :key="index"
                @click="getDetailItem(item, index)"
                class="img-item"
                :class="[activeNum == index ? 'img-active' : '']"
            >
                <el-image
                    :src="item.WDLX == 'MP4' ? videoUrl : item.CCLJ"
                    fit="cover"
                    class="pointer"
                    style="width: 100%; height: 100%; border-radius: 4px"
                >
                    <div slot="error" style="width: 100%; height: 100%">
                        <SnNoData width="30px"></SnNoData>
                    </div>
                </el-image>
            </div>
        </div>
        <div
            class="bottomDesc"
            :style="{ marginTop: listAry.length > 1 ? '12px' : '24px' }"
        >
            <div class="label">
                {{ detail.MC }}
            </div>
            <div class="detail scrollbar" :title="detail.YCYSMS">
                {{ detail.YCYSMS }}
            </div>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import TableList from "@views/big_screen/comp/TableList";
export default {
    components: {
        TableList,
    },
    name: "",
    data() {
        return {
            listAry: [],
            activeNum: 0,
            baseInfo: {
                type: 0, //0 图片 1 视频
                src: "",
            },
            videoUrl: require("@image/bigScreen/monitor_all/video.png"),
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {
        detail: {
            Type: Object,
            default: () => {
                let obj = {
                    MC: "", //名称
                    YCYSLX_DESC: "", //类型
                    GM: "", //规模
                    ZTNAME: 0, //树龄
                    DBXTP: "",
                    PICTUREPATH: [],
                    YCYSMS: "",
                    PIC: [],
                };
                return obj;
            },
        },
    },
    watch: {
        detail: {
            immediate: true,
            deep: true,
            handler() {
                if (this.detail.MC && this.detail.PIC.length) {
                    this.baseInfo.src = this.detail.PIC[0].CCLJ;
                    if (this.detail.PIC[0].WDLX == "MP4") {
                        this.baseInfo.type = 1; //0 图片 1 视频
                    } else {
                        this.baseInfo.type = 0;
                    }
                } else {
                    this.baseInfo.src = "";
                }
            },
        },
    },
    mounted() {},
    methods: {
        ...mapActions([]),
        ...mapMutations({}),
        getDetailItem(item, index) {
            this.activeNum = index;
            this.baseInfo.src = item.CCLJ;
            this.baseInfo.type = item.WDLX == "MP4" ? 1 : 0; //0 图片 1 视频
        },
    },
};
</script>
<style scoped lang="scss">
.dialogCtn {
    height: 100%;
    .topImg {
        width: 539px;
        height: 435px;
        position: relative;
        margin: 0 auto;
    }
    .img-list {
        width: 100%;
        margin-top: 24px;
        padding: 0 40px;
        .img-item {
            width: 64px;
            height: 56px;
            border-radius: 4px;
            margin-right: 14px;
            margin-bottom: 14px;
            &:nth-child(7n) {
                margin-right: 0;
            }
            cursor: pointer;
            &:hover {
                border: 1px solid #a9c5f7;
            }
        }
        .img-active {
            border: 1px solid #a9c5f7;
        }
    }
    .bottomDesc {
        width: 539px;
        height: 250px;
        background: #0e1b31;
        margin: 0 auto;
        padding: 4px 20px;
        .label {
            color: #a8c8ff;
            font-size: 30px;
            margin-top: 10px;
        }
        .detail {
            margin-top: 20px;
            color: #a8c8ff;
            font-size: 16px;
            height: 150px;
            text-indent: 30px;
        }
    }
}
</style>
