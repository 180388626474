<template>
    <div class="jgHjPart">
        <div class="tabPart flex-end">
            <div
                v-for="(item, index) in tabList"
                :key="index"
                class="tabItem pointer"
                :class="[
                    tabActiveNum === index ? 'tabItemActive' : 'tabItemDefault',
                ]"
                @click="getTabDetail(item, index)"
            >
                {{ item.name }}
            </div>
        </div>
        <div class="imgPart animated fadeIn scrollbar-hidden">
            <el-image
                :src="xzSrc"
                fit="cover"
                class="pointer"
                style="width: 100%; height: 360px"
            >
                <div slot="error" style="width: 100%; height: 100%">
                    <SnNoData width="150px"></SnNoData>
                </div>
            </el-image>
        </div>
    </div>
</template>

<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
    components: {},
    name: "",
    data() {
        return {
            noImg: require("@image/bigScreen/noImg.png"),
            xzSrc: require("@image/bigScreen/monitor_all/zrhj/czltjg.png"),
            tabActiveNum: 0,
            tabList: [
                {
                    name: "垂直立体景观",
                    src: require("@image/bigScreen/monitor_all/zrhj/czltjg.png"),
                },
                {
                    name: "平面圈层景观",
                    src: require("@image/bigScreen/monitor_all/zrhj/pmqcjg.png"),
                },
            ],
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {},
    watch: {},
    mounted() {},
    methods: {
        getTabDetail(item, index) {
            this.tabActiveNum = index;
            this.xzSrc = item.src;
        },
    },
};
</script>

<style lang="scss" scoped>
.jgHjPart {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 14px;
    .tabPart {
        position: absolute;
        right: 0;
        top: -10px;
        z-index: 999;
        .tabItem {
            height: 20px;
            line-height: 20px;
            font-size: 14px;
            text-align: center;
            border-radius: 5px;
            margin-right: 10px;
            padding: 0 10px;
            &:nth-last-child(1) {
                margin-right: 0px;
            }
        }
        .tabItemActive {
            background: rgba(255, 205, 128, 1);
            color: rgba(4, 14, 30, 1);
        }
        .tabItemDefault {
            background: rgba(53, 80, 124, 1);
            color: rgba(168, 200, 255, 1);
        }
    }
    .imgPart {
        width: 100%;
        height: 100%;
    }
}
</style>
