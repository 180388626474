const yczcFormatList = [
    {
        YCYSFL_2: "勐本",
        JD: "100.04850447177888",
        WD: "22.20348787158507",
        LEVEL: 18
    },
    {
        YCYSFL_2: "景迈-糯岗分隔防护林",
        JD: "100.01198205775151",
        WD: "22.2194619156112",
        LEVEL: 14.4
    },
    {
        YCYSFL_2: "景迈-芒景分隔防护林",
        JD: "100.01619691051697",
        WD: "22.185001756687367",
        LEVEL: 13.3
    },
    {
        YCYSFL_2: "景迈大寨",
        JD: "100.02704241809886",
        WD: "22.205132837882672",
        LEVEL: 17.6
    },
    {
        YCYSFL_2: "景迈大寨古茶林",
        JD: "100.02099803312421",
        WD: "22.206841468428095",
        LEVEL: 15.2
    },
    {
        YCYSFL_2: "糯岗",
        JD: "100.00044465160212",
        WD: "22.21693052591327",
        LEVEL: 18
    },
    {
        YCYSFL_2: "糯岗-芒景分隔防护林",
        JD: "100.00081836525973",
        WD: "22.2076955178534",
        LEVEL: 14.6
    },
    {
        YCYSFL_2: "糯岗古茶林",
        JD: "99.99942541122437",
        WD: "22.217920166311025",
        LEVEL: 16
    },
    {
        YCYSFL_2: "翁基",
        JD: "99.99866264225271",
        WD: "22.17357587536195",
        LEVEL: 17
    },
    {
        YCYSFL_2: "翁基-翁洼古茶林",
        JD: "99.99445175698136",
        WD: "22.178011298572056",
        LEVEL: 16.4
    },
    {
        YCYSFL_2: "翁洼",
        JD: "99.99092617433219",
        WD: "22.18060147991707",
        LEVEL: 17.7
    },
    {
        YCYSFL_2: "芒埂",
        JD: "100.05390980807879",
        WD: "22.20245371552725",
        LEVEL: 18
    },
    {
        YCYSFL_2: "芒埂-勐本古茶林",
        JD: "100.05099512699789",
        WD: "22.2070",
        LEVEL: 15.8
    },
    {
        YCYSFL_2: "芒景上下寨-芒洪古茶林",
        JD: "100.01337786851913",
        WD: "22.160847535207317",
        LEVEL: 15
    },
    {
        YCYSFL_2: "芒景上寨",
        JD: "100.01211228756833",
        WD: "22.167516514727443",
        LEVEL: 16.8
    },
    {
        YCYSFL_2: "芒景下寨",
        JD: "100.01585855869621",
        WD: "22.1580890",
        LEVEL: 17.4
    },
    {
        YCYSFL_2: "芒洪",
        JD: "100.01437557525189",
        WD: "22.146876827524004",
        LEVEL: 17.3
    },
];
export { yczcFormatList };