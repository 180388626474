<template>
    <div class="screenCenter">
        <!-- 地图 -->
        <div class="mapPart">
            <MyMap :myMap.sync="myMap"
                   ref="myMap"
                   :mapData="mapData" />
        </div>
        <!-- 图层 -->
        <div class="mapChangePart">
            <div class="layer pointer flex-between-y-center"
                 @click="layerState = !layerState">
                <img src="@image/bigScreen/icon/layer.png" />
                <div class="desc">图层</div>
            </div>
            <div v-show="layerState"
                 class="layerCtn">
                <LayerList :listAry="onOffList"
                           keyValue="build_control"
                           @switchStateChange="switchStateChange"></LayerList>
            </div>
        </div>
        <!-- 图例 -->
        <div class="legendPart flex">
            <LegendList :listAry="onOffList"
                        screenName="build_control"></LegendList>
        </div>
        <!-- marker 弹出框-->
        <div v-show="commonDialog.dialog"
             class="commonDialogPart">
            <div class="popupTitle flex-between">
                <div style="flex: 1; border-bottom: 1px #84acc2 solid"></div>
                <div class="titleCenter"
                     :title="commonDialog.title">
                    <img src="@image/bigScreen/titleBgleft.png" />
                    <span class="titleContent">{{ commonDialog.title }}</span>
                    <img src="@image/bigScreen/titleBgright.png" />
                </div>
                <div style="flex: 1; border-bottom: 1px #84acc2 solid"></div>
                <i class="el-icon-close titleIcon pointer"
                   @click="closeComDialog()"></i>
            </div>
            <div class="popupCtn">
                <DialogJg :detail="commonDialog.detail"
                          v-if="commonDialog.jgDialog"></DialogJg>
                <DialogSxt :detail="commonDialog.detail"
                           v-if="commonDialog.sxtDialog"></DialogSxt>
            </div>
        </div>
        <!-- pdf阅读 -->
        <div class="pdfPart"
             v-if="pdf.dialog">
            <PdfRead :pdf="pdf"
                     @closePdf="closePdf"></PdfRead>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import MyMap from "@views/big_screen/comp/map/BaseMap.vue";
import OnOff from "@views/big_screen/comp/OnOff.vue"; //开关
import LegendList from "@views/big_screen/comp/LegendList.vue"; //图例
import DialogJg from "./comp/DialogJg.vue"; //景观视线 景观风貌 弹出框
// import DialogSxt from "@views/big_screen/monitor_all/4_page_side/comp/DialogSxt.vue"; //摄像头 弹出框
import DialogSxt from "@views/big_screen/monitor_all/4_page_side/comp/DialogSxtIframe.vue"; //摄像头 iframe 公司 live-player 厂家提供的插件 第三方的监控
import LayerList from "@views/big_screen/comp/LayerList.vue"; //图层
import PdfRead from "@views/big_screen/comp/PdfRead.vue";
export default {
    components: {
        MyMap,
        OnOff,
        LegendList,
        DialogJg,
        DialogSxt,
        LayerList,
        PdfRead,
    },
    name: "",
    data () {
        return {
            myMap: {},
            mapData: {
                baseMapNum: 0,
                mapTypeBtn: true,
                showTool: true,
                ycqHcqState: false,
            },
            layerState: false,
            onOffList: [
                {
                    name: "遗产区、缓冲区",
                    group: null,
                    list: [],
                    state: false,
                    legend: false,
                    sourceLayerList: ["遗产区划线"],
                    idList: [],
                }, {
                    name: "保护范围、建控地带",
                    group: null,
                    list: [],
                    state: true,
                    legend: false,
                    sourceLayerList: [
                        "国保单位建设控制地带",
                        "国保单位保护范围",
                    ],
                    idList: [],
                },
                {
                    name: "民居建设",
                    state: true,
                    group: null,
                    list: [],
                    width: "20px",
                    height: "20px",
                    src: require("@image/bigScreen/monitor_all/marker/active/bhgc.png"),
                },
                // {
                //     name: "景观视线",
                //     state: true,
                //     group: null,
                //     list: [],
                //     width: "20px",
                //     height: "20px",
                //     src: require("@image/bigScreen/build_control/jgsx.png"),
                // },
                {
                    name: "景观风貌监测",
                    src: require("@image/bigScreen/monitor_all/marker/active/jgfm.png"),
                    srcDefault: require("@image/bigScreen/monitor_all/marker/default/jgfm.png"),
                    group: null,
                    list: [],
                    state: false,
                    imgState: true,
                    legend: true,
                    width: "20px",
                    height: "20px",
                },
                // {
                //     name: "风貌监测设备",
                //     src: require("@image/bigScreen/monitor_all/marker/active/fmjc.png"),
                //     srcDefault: require("@image/bigScreen/monitor_all/marker/default/fmjc.png"),
                //     group: null,
                //     list: [],
                //     state: false,
                //     imgState: true,
                //     legend: true,
                //     width: "20px",
                //     height: "20px",
                // },
                {
                    name: "景观视线监测",
                    src: require("@image/bigScreen/monitor_all/marker/active/jgsx.png"),
                    srcDefault: require("@image/bigScreen/monitor_all/marker/default/jgsx.png"),
                    group: null,
                    list: [],
                    state: false,
                    imgState: true,
                    legend: true,
                    width: "20px",
                    height: "20px",
                },

            ],
            markerPopup: null,
            // 景观视线
            jgsxGroup: null,
            jgsjList: [],
            xmlbGroup: null, //民居建设
            xmlbList: [],
            // marker弹出框
            commonDialog: {
                title: "",
                detail: {},
                dialog: false,
                jgDialog: false, //景观视线 景观风貌 弹出框
                sxtDialog: false, //摄像头 弹出框
            },
            pdf: {
                dialog: false,
                title: "",
                src: "",
                page: 1,
            },
        };
    },
    computed: {
        ...mapGetters(["jskzProjectItem", "jskzPdfItem"]),
    },
    props: {
        positionStr: {
            type: String,
            default: "",
        },
    },
    watch: {
        // 打开pdf 文件
        jskzPdfItem: {
            immediate: false,
            handler () {
                this.pdf.title = this.jskzPdfItem.name;
                this.pdf.src = this.jskzPdfItem.src;
                this.pdf.page = 1;
                this.pdf.dialog = true;
            },
        },
        positionStr: {
            immediate: true,
            handler () {
                if (this.positionStr == "left") {
                    this.mapData.baseMapNum = 0;
                } else if (this.positionStr == "right") {
                    this.mapData.baseMapNum = 1;
                }
            },
        },
        jskzProjectItem: {
            jskzProjectList: true,
            handler () {
                if (this.jskzProjectItem && this.jskzProjectItem.XMWZSL) {
                    if (!this.jskzProjectItem.XMWZSL.length) {
                        return;
                    }

                    let obj = JSON.parse(this.jskzProjectItem.XMWZSL)[0]
                        .geometry;
                    // 点
                    if (obj.type == "Point") {
                        let lng = obj.coordinates[0];
                        let lat = obj.coordinates[1];
                        this.myMap.flyTo([lat, lng], 21);
                        this.markerPopup
                            .setLatLng([lat, lng])
                            .setContent(
                                `<div class="tip">${this.jskzProjectItem.XMMC}</div>`
                            )

                            .openOn(this.myMap);
                    } else {
                        // 面
                        let ary = [];
                        let list = obj.coordinates[0];
                        list.map((item) => {
                            ary.push([item[1], item[0]]);
                        });
                        this.myMap.flyToBounds(ary);
                    }
                } else {
                    this.$refs.myMap.changeFitBounds();
                    if (this.markerPopup) {
                        this.markerPopup.removeFrom(this.myMap);
                    }
                }
            },
        },
    },
    created () {
    },
    mounted () {
        //marker 弹出框
        this.markerPopup = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, -8],
            className: "markerPopup",
        });
        this.onOffList.map((item) => {
            item.group = window.L.layerGroup().addTo(this.myMap);
        });
        this.xmlbGroup = window.L.layerGroup().addTo(this.myMap);
        this.getXmlbData();
        this.getJgfmList(); //景观风貌
        this.getJgsxList(); // 景观视线
        this.getYcqHcqBhfwJkdd(this.onOffList[0].group)
    },
    methods: {
        ...mapActions([
            "getVectorStyle",
            "GetNewProjectList",
            "GetSightLineList",
            "GetLandscapeStyleMap",
            "GetLandscapeLineMap",
        ]),
        ...mapMutations({
            setStateJskz: "setStateJskz",
        }),
        // 清空图层组
        clearLayersGroup (group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        // 加载 遗产区 缓冲区 保护范围 建控地带
        async getYcqHcqBhfwJkdd (group) {
            let layerStyle = await this.getVectorStyle({
                url: window.MAP_ZTT,
            });
            let objOther = JSON.parse(JSON.stringify(layerStyle))
            let layersOthers = []
            objOther.layers.map((item) => {
                // id存储
                this.onOffList.map((itemInner) => {
                    if (itemInner.sourceLayerList) {
                        itemInner.sourceLayerList.map((itemInnerInner) => {
                            if (item["source-layer"] == itemInnerInner) {
                                itemInner.idList.push(item.id);
                            }
                        });
                    }
                });
                if (item.type == "background") {
                } else {
                    // 初始化 把不显示的图层 透明度隐藏
                    let type = null;
                    if (item.type == "line") {
                        type = "line-opacity";
                    } else {
                        type = "fill-opacity";
                    }
                    let hidelist = ['遗产区划线', "遗产区划线",]
                    if (hidelist.includes(item['source-layer'])) {
                        item.paint[type] = 0
                    }
                    let showlist = ["遗产区划线", '国保单位保护范围', "国保单位建设控制地带",]
                    if (showlist.includes(item['source-layer'])) {
                        layersOthers.push(item);
                    }
                }
            });
            objOther.layers = layersOthers
            let allProvince1 = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: objOther,
            }).addTo(group);
            this.common.showVectorLayers();
            this.mapboxMap = allProvince1.getMapboxMap();
            // this.mapboxMap.on("load", () => {
            //     let hidelist = ["保护范围、建控地带", '土地利用',]
            //     this.changeYcysMap(hidelist, 0)
            //     let ary = this.mapboxMap.queryRenderedFeatures()
            // });
        },
        // 显示 隐藏 遗产要素 某一个块区
        changeYcysMap (list, num) {
            this.onOffList.map((item) => {
                if (item.sourceLayerList) {
                    let type = null;
                    if (
                        item.name == "遗产区、缓冲区" || item.name == "保护范围、建控地带"
                    ) {
                        type = "line-opacity";
                    } else {
                        type = "fill-opacity";
                    }
                    if (list.includes(item.name)) {
                        if (item.name == "传统村落") {
                            item.idList.map((itemInner) => {
                                this.mapboxMap.setPaintProperty(
                                    itemInner,
                                    type,
                                    num ? 1 : 0
                                );
                            });
                        } else {
                            item.idList.map((itemInner) => {
                                this.mapboxMap.setPaintProperty(
                                    itemInner,
                                    type,
                                    num
                                );
                            });
                        }
                    }
                }
            });
        },
        // 更改图层
        switchStateChange (state, item) {
            item.state = state;
            let group = item.group;
            let list = item.list;
            switch (item.name) {
                case "遗产区、缓冲区":
                    this.changeYcysMap([item.name], state ? 1 : 0);
                    this.changeYcysMap(["保护范围、建控地带"], 0);
                    this.onOffList[1].state = false;
                    break;
                case "保护范围、建控地带":
                    this.changeYcysMap([item.name], state ? 1 : 0);
                    this.changeYcysMap(["遗产区、缓冲区"], 0);
                    this.onOffList[0].state = false;
                    break;
                case "民居建设":
                    if (!state) {
                        this.clearLayersGroup(item.group);
                        return;
                    }
                    this.drawXjxm(group, list);
                    break;
                case "景观风貌监测":
                    if (!state) {
                        this.clearLayersGroup(item.group);
                        return;
                    }
                    this.drawMarkerJg(item);
                    break;

                case "景观视线监测":
                    if (!state) {
                        this.clearLayersGroup(item.group);
                        return;
                    }
                    this.drawMarkerJg(item);
                    break;
                // case "风貌监测设备":
                //     this.drawMarkerSxt(item);
                //     break;
            }
        },
        // 景观风貌列表
        async getJgfmList () {
            let result = await this.GetLandscapeStyleMap({});
            this.onOffList.map((item) => {
                if (item.name == "景观风貌监测") {
                    result.map((item1) => {
                        item1.typeName = "景观风貌监测";
                        item1.MONITORS.map((item2) => {
                            item2.TIME = item2.CJSJ
                                ? item2.CJSJ.split(" ")[0]
                                : "";
                        });
                    });
                    item.list = result;
                    // this.drawMarkerJg(item);
                }
            });
        },
        // 景观视线列表
        async getJgsxList () {
            let result = await this.GetLandscapeLineMap({});
            this.onOffList.map((item) => {
                if (item.name == "景观视线监测") {
                    result.map((item1) => {
                        item1.typeName = "景观视线监测";
                        item1.MONITORS.map((item2) => {
                            item2.TIME = item2.CJSJ
                                ? item2.CJSJ.split(" ")[0]
                                : "";
                        });
                    });
                    item.list = result;
                    if (item.name == "景观视线监测") {
                        // this.drawMarkerJg(item);
                    }
                }
            });
        },
        // 获取 项目列表 数据
        async getXmlbData () {
            let result = await this.GetNewProjectList({
                pageIndex: 1,
                pageSize: 9999999,
            });
            this.onOffList.map((item) => {
                if (item.name == "民居建设") {
                    item.list = result.DATA;
                    this.drawXjxm(item.group, result.DATA);
                }
            });
        },
        // 民居建设
        drawXjxm (group, list) {
            let src = ""
            this.onOffList.map((item) => {
                if (item.name == "民居建设") {
                    src = item.src
                }
            })
            this.clearLayersGroup(group);
            list.map((item) => {
                if (item.XMWZSL) {
                    JSON.parse(item.XMWZSL).map((itemInner) => {
                        window.L.geoJSON(itemInner, {
                            style: function () {
                                return {
                                    color: "#35b1bb",
                                };
                            },
                            onEachFeature: (feature, layer) => {
                                let icon = "";
                                switch (layer.feature.geometry.type) {
                                    case "Point":
                                        icon = window.L.divIcon({
                                            html: `<img style="width:30px;height:30px; filter: drop-shadow(2px 2px 5px rgba(7,29,41,0.5));" src=${src} />`,
                                            iconSize: [30, 30],
                                            iconAnchor: [15, 15],
                                        });
                                        layer.setIcon(icon);
                                        layer.on("mouseover", () => {
                                            let lng =
                                                layer.feature.geometry
                                                    .coordinates[0];
                                            let lat =
                                                layer.feature.geometry
                                                    .coordinates[1];
                                            this.markerPopup
                                                .setLatLng([lat, lng])
                                                .setContent(
                                                    `<div class="tip">${item.XMMC}</div>`
                                                )
                                                .openOn(this.myMap);
                                        });
                                        layer.on("mouseout", () => {
                                            this.markerPopup.removeFrom(
                                                this.myMap
                                            );
                                        });
                                        break;
                                    // 线
                                    case "Polygon":
                                        let list =
                                            window.turf.centerOfMass(itemInner)
                                                .geometry.coordinates;
                                        let lat = list[1];
                                        let lng = list[0];
                                        icon = window.L.divIcon({
                                            html: `<div class="pointer ellipsis" 
                                                    style="position: absolute;
                                                        top: 0;
                                                        left: 50%;
                                                        text-align:center;
                                                        transform: translateX(-50%);
                                                        width:100px;
                                                        color:#eee;
                                                        font-size:12px;
                                                        font-weight:600;" title=${item.XMMC}>${item.XMMC}</div>`,
                                        });
                                        window.L.marker([lat, lng], {
                                            icon: icon,
                                            iconSize: [100, 52],
                                            iconAnchor: [50, 84],
                                            className: "",
                                        }).addTo(group);
                                        break;
                                }
                                layer.on("click", () => {
                                    this.setStateJskz({
                                        jskzPanelNum: 1,
                                        jskzProjectItem: item,
                                    });
                                });
                            },
                        }).addTo(group);
                    });
                }
            });
        },
        // 绘制marker 景观风貌 景观视线
        drawMarkerJg (obj) {
            let list = obj.list;
            let group = obj.group;
            let src = obj.src;
            let icon = window.L.divIcon({
                html: `<img style="width:32px;height:32px; filter: drop-shadow(2px 2px 5px rgba(7,29,41,0.5));" src=${src} />`,
                iconSize: [32, 32],
                iconAnchor: [16, 16],
            });

            this.clearLayersGroup(group);
            list.map((item) => {
                if (obj.name == "景观视线监测") {
                    let sectorOut = require("@image/bigScreen/monitor_all/jgsx/sectorOut.png");
                    let sectorInner = require("@image/bigScreen/monitor_all/jgsx/sectorInner.png");
                    icon = window.L.divIcon({
                        html: `<div style="
                        position:relative;
                        width:44px;
                        height:44px;
						position:absolute;
						left:-22px;
						top:-22px;" >
                        <img style="
								width:32px;
								height:32px; 
								filter: drop-shadow(2px 2px 5px rgba(7,29,41,0.5));
								position:absolute;
                                top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								z-index:10;"
								src=${src} />
                        <div  style="
									position:absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%) rotate(${item.FX}deg);
									transform-origin:center;
									width:0;
									height:0;
									 ">
										<img style="
											width:62px;
											height:50px;
										    position:absolute;
											left:50%;
											bottom:0;
											transform: translateX(-50%);"
											src=${sectorOut} />
										<img 
										style="
										    width:62px;
											height:50px;
											position:absolute;
											left:50%;
											bottom:0;
											transform: translateX(-50%);
											animation: sectorInnerAnimate 1.4s ease-in-out infinite;"
											src=${sectorInner} />
                        </div>
                </div>`,
                        iconAnchor: [0, 0],
                        className: "jgsxMarker",
                    });
                }
                let layer = window.L.marker([item.WD, item.JD], {
                    icon: icon,
                    data: item,
                }).addTo(group);

                layer.on("click", () => {
                    this.closeComDialog();
                    this.commonDialog.title = `${item.MC}${obj.name}`;
                    this.commonDialog.dialog = true;
                    this.commonDialog.jgDialog = true;
                    this.commonDialog.detail = item;
                    if (obj.name == "景观视线监测") {
                        this.setStateJskz({
                            jskzPanelNum: 2,
                            jskzJgsxItem: item,
                        });
                    }
                });
                layer.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([item.WD, item.JD])
                        .setContent(`<div class="tip">${item.MC}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
            });
        },
        // 绘制marker 风貌监测设备
        drawMarkerSxt (obj) {
            let list = obj.list;
            let group = obj.group;
            let src = obj.src;
            let icon = window.L.divIcon({
                html: `<img style="width:32px;height:32px; filter: drop-shadow(2px 2px 5px rgba(7,29,41,0.5));" src=${src} />`,
                iconSize: [32, 32],
                iconAnchor: [16, 16],
            });

            this.clearLayersGroup(group);
            list.map((item) => {
                let layer = window.L.marker([item.WD, item.JD], {
                    icon: icon,
                    data: item,
                }).addTo(group);

                layer.on("click", () => {
                    // if (!item.RTMP) {
                    //     this.common.showMsg("暂无设备", "warning");
                    //     return;
                    // }
                    this.closeComDialog();
                    this.common.getFlvUrl(item).then((res) => {
                        if (res.Data && res.Data.outUrl) {
                            item.flvUrl = res.Data.outUrl;
                            this.commonDialog.dialog = true;
                            this.commonDialog.sxtDialog = true;
                            this.commonDialog.detail = item;
                            this.commonDialog.title = `${item.MC}${obj.name}`;
                        } else {
                            this.common.showMsg(
                                `${item.MC}${obj.name}设备故障`,
                                "warning"
                            );
                        }
                    });
                });
                layer.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([item.WD, item.JD])
                        .setContent(`<div class="tip">${item.MC}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
            });
        },
        // 最新版的marker
        closeComDialog () {
            this.commonDialog.dialog = false;
            this.commonDialog.jgDialog = false; //景观视线 景观风貌 弹出框
            this.commonDialog.sxtDialog = false; //摄像头 弹出框
        },
        closePdf () {
            this.pdf.dialog = false;
        },
    },
};
</script>
<style scoped lang="scss">
.screenCenter {
    position: relative;
    width: 100%;
    height: 100%;
    .mapPart {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .mapChangePart {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 10;
        .layer {
            position: absolute;
            top: 0;
            left: 0;
            width: 130px;
            height: 40px;
            background: #1a3059;
            border: 1px solid #35507c;
            border-radius: 10px;
            box-shadow: 5px 9px 30px 0px rgba(0, 44, 75, 0.2);
            padding: 0 20px;

            .desc {
                color: #fff;
                font-size: 18px;
            }
        }

        .layerCtn {
            min-width: 130px;
            margin-top: 50px;
            padding: 10px;
            background: #1a3059;
            border-radius: 5px;
        }
    }
    // 图例
    .legendPart {
        position: absolute;
        left: 10px;
        bottom: 10px;
        height: 56px;
        z-index: 10;
        .title {
            width: 20px;
            height: 56px;
            line-height: 23px;
            background: rgba(4, 28, 67, 0.8);
            font-weight: 400;
            font-size: 12px;
            color: #d8eaf6;
            text-align: center;
            padding-top: 5px;
        }
        .list {
            padding-left: 10px;
            background: rgba(4, 28, 67, 0.6);
            .legendItem {
                margin-right: 10px;
                .img {
                    margin-right: 5px;
                    width: 20px;
                }
                .name {
                    font-weight: 400;
                    font-size: 12px;
                    color: #d8eaf6;
                }
            }
        }
    }
    .commonDialogPart {
        position: absolute;
        top: -5px;
        right: 5px;
        z-index: 999;
        background: #040e1e;
        // box-shadow: 0 0 15px 10px #5d8da8;
        padding: 10px;
        .popupTitle {
            width: 100%;
            height: 36px;
            line-height: 35px;
            display: flex;
            position: relative;
            padding: 0 15px;
            // 标题内容
            .titleCenter {
                width: fit-content;
                height: 100%;
                margin: 0 auto;
                font-size: 18px;
                color: rgb(210, 234, 246);
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                .titleContent {
                    padding: 0 30px;
                    min-width: 200px;
                    display: inline-block;
                    border-top: 1px solid #84acc2;
                    background: linear-gradient(
                        rgba(48, 80, 97, 1),
                        rgba(48, 80, 97, 0)
                    );
                }
            }
            // 标题按钮
            .titleIcon {
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
                color: #ffffff;
                font-size: 24px;
            }
        }
        .popupCtn {
            width: 100%;
            padding: 20px 14px 10px 14px;
            height: calc(100% - 30px);
        }
    }
    .pdfPart {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1000;
        width: 100%;
        height: 100%;
    }
}
</style>
