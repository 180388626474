<template>
    <div class="yjczItemPart scrollbar-hidden">
        <div
            v-for="(item, index) in czgzDataList"
            :key="index"
            class="czgzItem"
        >
            <div class="czgzHeader">
                <div class="fknr">{{ item.FKNR }}</div>
                <div class="flex-between stateNameTimePart">
                    <div
                        class="state"
                        :style="{
                            color: czgzColors[Number(item.SFYGZSJ)],
                        }"
                    >
                        {{
                            item.SFYGZSJ && item.SFYGZSJ === "1"
                                ? "已跟踪"
                                : "未跟踪"
                        }}
                    </div>
                    <div class="name">
                        <span>{{ item.FKR }} </span>
                        <span>{{ item.FKSJ }}</span>
                    </div>
                </div>
            </div>
            <div
                v-if="
                    item.SFYGZSJ === '1' &&
                    item.CZGZJL &&
                    item.CZGZJL.length > 0
                "
                class="czgBody flex-between"
            >
                <div class="textPart">
                    <div class="xcms">
                        {{ item.CZGZJL[0].XCMS }}
                    </div>
                    <div>
                        <span class="cjrmc">{{ item.CZGZJL[0].CJRMC }}</span>
                        <span class="">{{ item.CZGZJL[0].CJSJ }}</span>
                    </div>
                </div>
                <div
                    v-if="item.CZGZJL[0].PIC && item.CZGZJL[0].PIC.length > 0"
                    class="imgPart"
                >
                    <el-image
                        :src="item.CZGZJL[0].PIC[0].CCLJ"
                        fit="cover"
                        style="width: 100px; height: 100px"
                        class="pointer"
                        v-viewer
                    >
                        <div slot="error" style="width: 100%; height: 100%">
                            <SnNoData typeNum="1" width="60px"></SnNoData>
                        </div>
                    </el-image>
                </div>
            </div>
        </div>
        <div v-if="!dataState" class="noData">尚未开始跟踪</div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "",
    props: {
        id: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            czgzDataList: [],
            czgzColors: ["#d9001b", "#70b603"],
            dataState: true,
        };
    },
    watch: {},
    mounted() {
        this.getCzgzjlData();
    },
    methods: {
        ...mapActions([
            "GetCzgzjlData", // 获取处置跟踪记录数据
        ]),
        // 获取处置跟踪列表
        async getCzgzjlData() {
            this.czgzDataList = [];
            let result = await this.GetCzgzjlData({
                yjsjId: this.id,
            });
            if (result && result.length > 0) {
                result.map((item) => {
                    item.FKSJ = item.FKSJ.substr(0, 10);
                });
                this.czgzDataList = result;
            } else {
                this.dataState = false;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.yjczItemPart {
    height: 260px;
    width: 100%;
    position: relative;
    .noData {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-30deg);
        color: #5673a2;
        font-size: 18px;
    }
    .czgzItem {
        width: 100%;
        height: 220px;
        .czgzHeader {
            padding: 0 40px 2px 10px;
            background: rgba(53, 80, 124, 0.2);
            .fknr {
                width: 100%;
                color: #5673a2;
                font-size: 14px;
                border-bottom: 1px solid rgba(53, 80, 124, 0.2);
                padding: 18px 27px 14px 20px;
            }
            .stateNameTimePart {
                padding: 10px 0;
                .state {
                    color: #5673a2;
                    font-size: 14px;
                }
                .name {
                    color: #5673a2;
                    font-size: 14px;
                }
            }
        }
        .czgBody {
            width: 100%;
            padding: 10px 0;
            .textPart {
                color: #5673a2;
                .xcms {
                    height: 28px;
                    font-size: 15px;
                }
                .cjrmc {
                    margin-right: 20px;
                }
            }
            .imgPart {
                width: 100px;
                height: 100px;
            }
        }
    }
}
</style>
