<template>
    <div class="rightPart scrollbar-hidden">
        <div class="xczkPart">
            <!-- 项目状态 -->
            <basic-module :text="common.getFyName(fany.xmzt)">
                <div slot="basic-module" class="xczkCtn flex">
                    <div
                        class="leftEchart"
                        :id="'jxkzEchartXmzt' + echartId"
                    ></div>
                    <div class="rightLegend flex-1 flex-column flex-around">
                        <div
                            v-for="(item, index) in xzztLegendData"
                            :key="index"
                            class="lefendItem flex-y-center"
                        >
                            <div
                                class="color"
                                :style="{ background: item.color }"
                            ></div>
                            <div class="name">{{ item.name }}</div>
                        </div>
                    </div>
                </div>
            </basic-module>
        </div>

        <div class="dbfgPart">
            <!-- 地表覆盖 -->
            <basic-module :text="common.getFyName(fany.dbfg)">
                <div slot="basic-module" class="dbfgCtn">
                    <div class="condition flex-end">
                        <div class="bg"></div>
                        <div class="selectPart">
                            <SelectTimeTab
                                :selectData="selectData"
                                :tabData="tabData"
                                @getTabItem="getTabItem"
                                @getSelectItem="getSelectItem"
                            >
                            </SelectTimeTab>
                        </div>
                    </div>
                    <div
                        class="echartPart"
                        :id="'jskzEchartDbfg' + echartId"
                    ></div>
                    <div class="decorate">
                        <div class="decorate1"></div>
                        <div class="decorate2"></div>
                        <div class="decorate3"></div>
                        <div class="decorate4"></div>
                        <div class="decorate5"></div>
                        <div class="decorate6"></div>
                    </div>
                    <div class="lineTop"></div>
                    <div class="lineLeft"></div>
                    <div class="lineBottom"></div>
                </div>
            </basic-module>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import SelectTimeTab from "@views/big_screen/comp/SelectTimeTab";

export default {
    components: {
        SelectTimeTab,
    },
    name: "",
    data() {
        return {
            echartId: Math.random(),
            xzztLegendData: [
                {
                    name: "实施完成",
                    color: "rgba(65,218,236,1)",
                },
                {
                    name: "正在实施",
                    color: "rgba(72,255,0,1)",
                },
                {
                    name: "未实施",
                    color: "rgba(255,252,0,1)",
                },
            ],
            tabData: {
                list: [
                    {
                        name: "面积占比",
                    },
                ],
                value: "name",
            },
            selectData: {
                state: false,
                width: "100px",
                key: "全部",
                clearable: false,
                list: [
                    {
                        name: "全部",
                    },
                    {
                        name: "2020",
                    },
                    {
                        name: "2019",
                    },
                ],
                label: "name",
                value: "name",
            },
            echart: null,
            dbfgList: [], //地表覆盖
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {},
    watch: {},
    mounted() {
        this.getXmztData();
        this.getDbfgData();
        $(window).resize(() => {
            this.initEchartDbfg();
        });
    },
    methods: {
        ...mapActions(["GetNewProjectTotal", "GetLandUseList"]),
        ...mapMutations({}),
        // 项目状态数据
        async getXmztData() {
            let result = await this.GetNewProjectTotal({});
            this.initEchartXmzt(result.YWC, result.SGZ, result.WKG);
        },
        initEchartXmzt(num1, nunm2, num3) {
            // 过滤掉 非0的数值
            let resultList = [
                {
                    NAME: "实施完成",
                    COUNT: num1,
                },
                {
                    NAME: "正在实施",
                    COUNT: nunm2,
                },
                {
                    NAME: "未实施",
                    COUNT: num3,
                },
            ]; //后台只返回 数据部位0的数据
            // 颜色数组
            let colorList = [
                "rgba(65,218,236,1)",
                "rgba(72,255,0,1)",
                "rgba(255,252,0,1)",
            ];
            let animateBgColor = "#09354d";
            let noDataColor = "rgba(9,53,77,0.4)";
            let listOut = []; //最外层数据
            let listCenter = []; //中间层数据
            let listAnimate = []; //动画层数据
            let legendState = false; // 图例显示隐藏

            let innerRadius = ["0", "30%"]; //内层半径
            let centerRadius = ["50%", "74%"]; //中间层半径
            let outRadius = ["74%", "80%"]; //最外层半径
            let animateRadius = ["74%", "76%"]; //动画半径
            let animateSplitNum = 4; //动画拆分数据
            let animateState = true; //打开 关闭动画
            let total = 0; //数据总数
            let labelLineState = false;
            let labelLength = 10; //label长度
            let labelLength2 = 20; //label长度
            let legendData = [];
            for (let i in resultList) {
                total += resultList[i].COUNT;
            }

            // 空白间隔
            let placeHolderStyle = {
                normal: {
                    label: {
                        show: false,
                    },
                    labelLine: {
                        show: false,
                    },
                    color: "rgba(0, 0, 0, 0)",
                    borderColor: "rgba(0, 0, 0, 0)",
                    borderWidth: 0,
                },
            };
            // 最外层label 样式
            let rich = {
                white: {
                    align: "center",
                    padding: [3, 0],
                },
            };

            let splitNum = 100; //拆分数目
            if (resultList.length == 1) {
                splitNum = 10000000000;
            }

            // 饼状图 数据
            if (total == 0) {
                // 最外层 数值为0
                let aryOut0 = [
                    {
                        value: 0,
                        name: "",
                        itemStyle: {
                            normal: {
                                // borderWidth: 5,
                                // shadowBlur: 20,
                                // borderColor: color[i],
                                // shadowColor: color[i],
                                color: noDataColor,
                            },
                        },
                    },
                ];
                listOut = [...listOut, ...aryOut0];
                listCenter = [...listCenter, ...aryOut0];
            } else {
                for (let i in resultList) {
                    legendData.push(resultList[i].NAME);
                    // 最外层 数值为0
                    let aryOut0 = [
                        {
                            value: resultList[i].COUNT,
                            name: resultList[i].NAME,
                            itemStyle: {
                                normal: {
                                    // borderWidth: 5,
                                    // shadowBlur: 20,
                                    // borderColor: color[i],
                                    // shadowColor: color[i],
                                    color: colorList[i],
                                },
                            },
                        },
                    ];
                    // 最外层 数值为不为0
                    let aryOut1 = [
                        {
                            value: resultList[i].COUNT,
                            name: resultList[i].NAME,
                            itemStyle: {
                                normal: {
                                    // borderWidth: 5,
                                    // shadowBlur: 20,
                                    // borderColor: color[i],
                                    // shadowColor: color[i],
                                    color: colorList[i],
                                },
                            },
                        },
                        {
                            value: total / splitNum,
                            name: "",
                            itemStyle: placeHolderStyle,
                        },
                    ];

                    // 最外层 数值为0
                    let aryCenter0 = [
                        {
                            value: resultList[i].COUNT,
                            name: resultList[i].NAME,
                            itemStyle: {
                                normal: {
                                    // borderWidth: 5,
                                    // shadowBlur: 20,
                                    // borderColor: color[i],
                                    // shadowColor: color[i],
                                    color: colorList[i],
                                    opacity: 0.2,
                                },
                            },
                        },
                    ];
                    // 最外层 数值为不为0
                    let aryCenter1 = [
                        {
                            value: resultList[i].COUNT,
                            name: resultList[i].NAME,
                            itemStyle: {
                                normal: {
                                    // borderWidth: 5,
                                    // shadowBlur: 20,
                                    // borderColor: color[i],
                                    // shadowColor: color[i],
                                    color: colorList[i],
                                    opacity: 0.2,
                                },
                            },
                        },
                        {
                            value: total / splitNum,
                            name: "",
                            itemStyle: placeHolderStyle,
                        },
                    ];

                    if (resultList[i].COUNT == 0) {
                        // 外层数据
                        listOut = [...listOut, ...aryOut0];
                        // // 中间层数据
                        listCenter = [...listCenter, ...aryCenter0];
                    } else {
                        // 外层数据
                        listOut = [...listOut, ...aryOut1];
                        // 中间层数据
                        listCenter = [...listCenter, ...aryCenter1];
                    }
                }
            }

            // 动画部分 数据
            for (let i = 0; i < animateSplitNum; i++) {
                listAnimate.push(
                    {
                        value: 20,
                        name: i,
                        itemStyle: {
                            normal: {
                                color: animateBgColor,
                            },
                        },
                    },
                    {
                        value: 1,
                        name: "",
                        itemStyle: placeHolderStyle,
                    }
                );
            }

            // 最外层的label
            let func = (params) => {
                let percent = ((params.value / total) * 100).toFixed(1);
                let name = params.name.replace(/\n/g, "");
                if (params.name !== "") {
                    return name + params.value + "\n{white|" + percent + "%}";
                } else {
                    return "";
                }
            };

            let option = {
                tooltip: {
                    show: true,
                    trigger: "item",
                    formatter: (e) => {
                        if (
                            e.data.name == "" ||
                            e.seriesName == "最内层" ||
                            e.seriesName == "动画部分" ||
                            total == 0
                        ) {
                            return "";
                        } else {
                            let bgColor = "";
                            resultList.map((item, index) => {
                                if (item.NAME == e.name) {
                                    bgColor = colorList[index];
                                }
                            });
                            let percent = (e.value / total) * 100;
                            if (parseInt(percent) != parseFloat(percent)) {
                                percent = percent.toFixed(2);
                            }
                            let marker = `<div style='display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${bgColor};'></div>`;
                            let str = `${marker}${e.name}<br>${e.value}(${percent}%)`;
                            return str;
                        }
                    },
                },
                legend: {
                    show: legendState,
                    orient: "vertical",
                    data: legendData,
                    icon: "circle",
                    left: "5px",
                    top: "10px",
                    textStyle: {
                        fontSize: 20,
                        color: "#fff",
                    },
                },
                series: [
                    // {
                    //     name: "动画部分",
                    //     type: "pie",
                    //     label: {
                    //         normal: {
                    //             show: false,
                    //         },
                    //     },
                    //     labelLine: {
                    //         normal: {
                    //             show: false,
                    //         },
                    //     },
                    //     center: ["50%", "50%"],
                    //     radius: animateRadius,
                    //     hoverAnimation: false,
                    //     itemStyle: {
                    //         normal: {
                    //             label: {
                    //                 show: false,
                    //             },
                    //             labelLine: {
                    //                 show: false,
                    //             },
                    //         },
                    //     },
                    //     data: animateState ? listAnimate : [],
                    // },
                    {
                        name: "最外层",
                        type: "pie",
                        clockWise: false,
                        zlevel: 3,
                        center: ["50%", "50%"],
                        radius: outRadius,
                        hoverAnimation: false,
                        itemStyle: {
                            normal: {
                                label: {
                                    show: labelLineState,
                                    position: "outside",
                                    formatter: func,
                                    rich: rich,
                                },
                                labelLine: {
                                    length: labelLength,
                                    length2: labelLength2,
                                    show: labelLineState,
                                },
                            },
                        },
                        data: listOut,
                        animationType: "scale",
                        animationEasing: "elasticOut",
                        animationDelay: function (idx) {
                            return idx * 50;
                        },
                    },
                    {
                        name: "中间层",
                        type: "pie",
                        clockWise: false,
                        center: ["50%", "50%"],
                        radius: centerRadius,
                        hoverAnimation: false,
                        itemStyle: {
                            normal: {
                                label: {
                                    show: false,
                                },
                                labelLine: {
                                    show: false,
                                },
                            },
                        },
                        data: listCenter,
                    },
                    {
                        name: "最内层",
                        type: "pie",
                        hoverAnimation: false,
                        radius: innerRadius,
                        center: ["50%", "50%"],

                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        data: [
                            {
                                // 总数
                                value: total,
                                name: "",
                                label: {
                                    normal: {
                                        show: true,
                                        position: "center",
                                        color: "#fff",
                                        formatter: (params) => {
                                            return params.value;
                                        },
                                        fontSize: 48,
                                        fontFamily: "DBPM",
                                    },
                                },
                                itemStyle: {
                                    normal: {
                                        color: new this.$echarts.graphic.RadialGradient(
                                            0.5,
                                            0.5,
                                            0.99,
                                            [
                                                {
                                                    offset: 0,
                                                    color: "rgb(0, 0, 0, 0)",
                                                },
                                                {
                                                    offset: 1,
                                                    color: "#d7eaf5",
                                                },
                                            ],
                                            false
                                        ),
                                    },
                                },
                            },
                        ],
                    },
                ],
            };

            // 网站加载缓慢问题
            // setTimeout(loop, 1000);

            function loop() {
                let option = myChart.getOption();
                option.series[0].startAngle = option.series[0].startAngle - 1;
                myChart.setOption(option);
                setTimeout(function () {
                    window.requestAnimationFrame(loop);
                }, 50);
            }

            let id = `jxkzEchartXmzt${this.echartId}`;
            let echart = this.$echarts.dispose(document.getElementById(id));
            echart = this.$echarts.init(document.getElementById(id));
            echart.setOption(option);
        },
        // 地表覆盖数据
        async getDbfgData() {
            let result = await this.GetLandUseList({});
            this.dbfgList = result;
            this.initEchartDbfg();
        },

        getTabItem(item, index) {
            this.initEchartDbfg();
        },
        getSelectItem(item) {
            this.initEchartDbfg();
        },
        // 地表覆盖
        initEchartDbfg() {
            let yAxisData = [];
            let seriesDataYcq = [];
            let seriesDataHcq = [];
            let yAxisRightData = [];
            let colorYcq = "rgba(30,149,147,1)";
            let colorHcq = "rgba(188,222,223,1)";
            let colorBg = "rgba(26,48,89,0.3)";
            this.dbfgList.map((item, index) => {
                yAxisData.push(item.LX_DESC);
                seriesDataYcq.push(item.YCQMJ);
                seriesDataHcq.push(item.HCQMJ);
                yAxisRightData.push(`${item.YCQHHCQZB}%`);
            });

            let option = {
                color: ["#65779E"],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
                    },
                    formatter: (params) => {
                        let str = "";
                        params.map((item) => {
                            str += `${item.marker} ${item.seriesName} ${item.value} 公顷</br>`;
                        });
                        return str;
                    },
                },
                grid: {
                    left: 100,
                    right: 80,
                    top: 10,
                    bottom: 30,
                },
                legend: {
                    align: "right",
                    x: "center",
                    y: "bottom",
                    itemGap: 12,
                    right: 20,
                    textStyle: {
                        fontSize: 12,
                        color: "rgba(196,214,225,1)",
                    },
                },
                xAxis: [
                    {
                        type: "value",
                        axisPointer: {
                            type: "shadow",
                        },
                        // 横坐标 分割线等取消显示
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            show: false,
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "category",
                        inverse: true,
                        axisLabel: {
                            textStyle: {
                                fontSize: 14,
                                color: "rgba(154, 180, 227, 1)",
                            },
                        },
                        // 纵坐标数据
                        data: yAxisData,
                        yAxisIndex: 0,
                        // 横坐标 分割线等取消显示
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                    },
                    {
                        type: "category",
                        inverse: true,
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            show: true,
                            inside: false,
                            textStyle: {
                                fontSize: 20,
                                color: "rgba(228,244,255,1)",
                            },
                        },
                        // 统计的总量 用纵坐标模拟
                        data: yAxisRightData,
                    },
                ],
                series: [
                    {
                        name: "遗产区",
                        type: "bar",
                        // 宽度
                        barWidth: "16",
                        // 堆叠
                        stack: "总量",
                        showBackground: true,
                        // 全部背景
                        backgroundStyle: {
                            color: colorBg,
                        },
                        data: seriesDataYcq,
                        itemStyle: {
                            normal: {
                                show: true,
                                textStyle: {
                                    fontSize: 16,
                                },
                                color: colorYcq,
                            },
                        },
                    },
                    {
                        name: "缓冲区",
                        type: "bar",
                        // 宽度
                        barWidth: "16",
                        // 堆叠
                        stack: "总量",
                        showBackground: true,
                        // 全部背景
                        backgroundStyle: {
                            color: colorBg,
                        },
                        data: seriesDataHcq,
                        itemStyle: {
                            normal: {
                                show: true,
                                textStyle: {
                                    fontSize: 16,
                                },
                                color: colorHcq,
                            },
                        },
                    },
                ],
            };
            let id = `jskzEchartDbfg${this.echartId}`;
            this.echartDbfg = this.$echarts.dispose(
                document.getElementById(id)
            );
            this.echartDbfg = this.$echarts.init(document.getElementById(id));
            this.echartDbfg.setOption(option);
        },
    },
};
</script>
<style scoped lang="scss">
.rightPart {
    width: 100%;
    height: 100%;
    background: rgba(26, 56, 89, 0.3);
    // 项目状态
    .xczkPart {
        width: 100%;
        height: 337px;
        .xczkCtn {
            width: 100%;
            height: 100%;
            .leftEchart {
                width: 560px;
                height: 257px;
                background: url("~@image/bigScreen/build_control/xmztBg.png")
                    no-repeat;
                background-size: 100% 100%;
            }
            .rightLegend {
                padding-left: 20px;
                .lefendItem {
                    .color {
                        width: 11px;
                        height: 11px;
                        margin-right: 10px;
                    }
                    .name {
                        font-size: 14px;
                        color: rgba(215, 234, 245, 1);
                    }
                }
            }
        }
    }
    // 地表覆盖
    .dbfgPart {
        margin-top: 25px;
        width: 100%;
        height: 520px;
        .dbfgCtn {
            width: 100%;
            height: 100%;
            position: relative;
            .condition {
                margin-top: 10px;
                position: relative;
                .bg {
                    width: 100%;
                    height: 2px;
                    background: rgba(53, 80, 124, 0.4);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                }
                .selectPart {
                    position: relative;
                    z-index: 10;
                }
            }
            .echartPart {
                width: 100%;
                height: calc(100% - 50px);
            }
            .decorate {
                position: absolute;
                left: 0;
                top: 30px;
                width: 100px;
                height: calc(100% - 90px);

                z-index: 1;
                .decorate1 {
                    width: 100%;
                    height: 120px;
                    background: rgba(53, 80, 124, 0.15);
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                .decorate2 {
                    width: 100%;
                    height: 34px;
                    background: rgba(53, 80, 124, 0.15);
                    position: absolute;
                    left: 0;
                    top: 44px;
                }

                .decorate3 {
                    width: 100%;
                    height: 79px;
                    background: rgba(53, 80, 124, 0.15);
                    position: absolute;
                    left: 0;
                    top: 123px;
                }

                .decorate4 {
                    width: 100%;
                    height: 34px;
                    background: rgba(53, 80, 124, 0.15);
                    position: absolute;
                    left: 0;
                    top: 167px;
                }

                .decorate5 {
                    width: 100%;
                    height: 34px;
                    background: rgba(53, 80, 124, 0.15);
                    position: absolute;
                    left: 0;
                    bottom: 44px;
                }

                .decorate6 {
                    width: 100%;
                    height: 78px;
                    background: rgba(53, 80, 124, 0.15);
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
            }
            .lineTop {
                position: absolute;
                left: 0;
                top: 30px;
                width: 100%;
                height: 1px;
                background: rgba(53, 80, 124, 1);
            }
            .lineLeft {
                position: absolute;
                left: 100px;
                top: 30px;
                width: 1px;
                height: calc(100% - 100px);
                background: rgba(53, 80, 124, 1);
            }
            .lineBottom {
                position: absolute;
                left: 0;
                bottom: 60px;
                width: 100%;
                height: 1px;
                background: rgba(53, 80, 124, 1);
            }
        }
    }
}
</style>
