<template>
    <div class="leftPart scrollbar-hidden">
        <div class="leftTop">
            <Xmsl></Xmsl>
        </div>
        <div class="leftBottom">
            <Xmlb></Xmlb>
        </div>
    </div>
</template>
<script>
import Xmsl from "./1_xmsl.vue"; //项目数量
import Xmlb from "./2_xmlb.vue"; //项目列表
export default {
    name: "",
    components: {
        Xmsl,
        Xmlb,
    },
};
</script>
<style scoped lang="scss">
.leftPart {
    width: 100%;
    height: 100%;
    background: rgba(26, 56, 89, 0.3);
    .leftTop {
        width: 100%;
        height: 470px;
    }
    .leftBottom {
        width: 100%;
        height: 437px;
        // border: 1px solid #fff;
    }
}
</style>
