<template>
    <div class="yjczItemPart">
        <div class="flex-between actionBtn">
            <div>预警上报{{ yjsbTableTotal }}条</div>
        </div>
        <!-- 列表 -->
        <div class="yjsbTablePart scrollbar-hidden">
            <div
                class="tableItem"
                v-for="(item, index) in yjsbTableData"
                :key="index"
            >
                <div class="label">接收部门</div>
                <div
                    class="detail pointer flex-1 flex-y-center"
                    :title="item.CZBM"
                >
                    {{ item.CZBM }}
                </div>

                <div class="label">接收人</div>
                <div
                    class="detail pointer flex-1 flex-y-center"
                    :title="item.CZR"
                >
                    {{ item.CZR }}
                </div>
                <div class="label">上报时间</div>
                <div
                    class="detail pointer flex-1 flex-y-center"
                    :title="item.CZSJ"
                >
                    {{ item.CZSJ }}
                </div>
                <div class="label">上报人</div>
                <div
                    class="detail pointer flex-1 flex-y-center"
                    :title="item.CJRNAME"
                >
                    {{ item.CJRNAME }}
                </div>
                <div class="label">上报方式</div>
                <div
                    class="detail pointer flex-1 flex-y-center"
                    :title="item.SBFSNAME"
                >
                    {{ item.SBFSNAME }}
                </div>
                <div class="label">附件</div>
                <div
                    class="detail pointer flex-1 flex-y-center"
                    @click="getFile(item)"
                >
                    查看
                </div>
            </div>
            <!--            
            <el-table
                :data="yjsbTableData"
                style="width: 100%"
                max-height="200"
                size="small"
            >
                <el-table-column
                    prop="CZBM"
                    label="接收部门"
                    show-overflow-tooltip
                    width="100"
                >
                    <template slot-scope="scope">
                        <div
                            class="pointer ellipsis"
                            style="height: 20px; line-height: 20px"
                            :title="scope.row.CZBM"
                        >
                            {{ scope.row.CZBM }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="CZR"
                    label="接收人"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <div
                            class="pointer ellipsis"
                            style="height: 20px; line-height: 20px"
                            :title="scope.row.CZR"
                        >
                            {{ scope.row.CZR }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="CZSJ"
                    label="上报时间"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <div>
                            {{
                                scope.row.CZSJ
                                    ? scope.row.CZSJ.substr(0, 10)
                                    : ""
                            }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="CJRNAME" label="上报人">
                    <template slot-scope="scope">
                        <div
                            class="pointer ellipsis"
                            style="height: 20px; line-height: 20px"
                            :title="scope.row.CJRNAME"
                        >
                            {{ scope.row.CJRNAME }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="SBFSNAME"
                    label="上报方式"
                    show-overflow-tooltip
                ></el-table-column>
                <el-table-column label="附件" width="50">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            class="yjsb-file-btn"
                            @click.native.prevent="
                                getFile(scope.$index, scope.row)
                            "
                            >{{
                                scope.row.PIC.length > 0 ? "查看" : ""
                            }}</el-button
                        >
                    </template>
                </el-table-column>
            </el-table> -->
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "",
    props: {
        id: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            yjsbTableData: [], //表单数据
            yjsbTableTotal: 0, //数据总条数
        };
    },
    watch: {},
    mounted() {
        this.getYjsbList(); //预警上报列表
    },
    methods: {
        ...mapActions([
            "GetYjsbList", // 获取预警上报列表
        ]),
        // 获取预警上报列表
        async getYjsbList() {
            this.yjsbTableTotal = 0;
            let result = await this.GetYjsbList({
                yjsjId: this.id,
            });
            if (result) {
                if (result.DATA && result.DATA.length > 0) {
                    result.DATA.map((item) => {
                        item.CZSJ = item.CZSJ.substr(0, 10);
                    });
                    this.yjsbTableData = result.DATA;
                }
                if (result.TOTAL) {
                    this.yjsbTableTotal = result.TOTAL;
                }
            }
        },
        // 表格查看附件
        getFile(item) {
            if (item.PIC.length) {
                window.open(item.PIC[0].CCLJ);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.yjczItemPart {
    position: relative;
    height: 100%;
    .noData {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-30deg);
        color: #5673a2;
        font-size: 18px;
    }
    .actionBtn {
        width: 100%;
        padding: 2px 20px 6px 0px;
        color: #5673a2;
        font-size: 14px;
    }
    .yjsbTablePart {
        height: 220px;
        .tableItem {
            padding: 10px;
            background: rgba(53, 80, 124, 0.2);
            margin-bottom: 10px;
            .label {
                color: #5673a2;
                font-size: 14px;
                height: 28px;
                color: #5673a2;
                font-size: 14px;
                margin-top: 10px;
                &:nth-child(1) {
                    margin-top: 0;
                }
            }
            .detail {
                min-height: 28px;
                color: #a8c8ff;
                font-size: 14px;
                background: rgba(53, 80, 124, 0.2);
                padding: 4px 10px;
            }
        }
    }
}
</style>
