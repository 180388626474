

export default {
    components: {

    },
    props: {

    },
    computed: {

    },
    watch: {

    },
    data() {
        return {
            // 遗产要素分布图
            comLegendList: [
                {
                    // name: "遗产要素村寨",
                    name: "传统村落",
                    sourceLayerList: ["遗产要素村寨"],
                    idList: [],
                    state: true,
                    src: require("@image/bigScreen/monitor_all/marker/active/ctcl.png"),
                    srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                    legend: true,
                    imgState: true,
                },
                {
                    name: "分隔防护林",
                    sourceLayerList: ["分隔防护林"],
                    idList: [],
                    state: true,
                    src: require("@image/bigScreen/monitor_all/marker/active/fgfhl.png"),
                    srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                    legend: true,
                    imgState: true,
                },
                {
                    name: "古茶林",
                    sourceLayerList: ["古茶林"],
                    idList: [],
                    state: true,
                    src: require("@image/bigScreen/monitor_all/marker/active/gcl.png"),
                    srcDefault: require("@image/bigScreen/monitor_all/marker/default/marker.png"),
                    legend: true,
                    imgState: true,
                },
            ],
        };
    },
    created() {
    },
    mounted() {

    },
    methods: {
    },
};