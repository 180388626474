<template>
    <div class="yjczItemPart">
        <div class="label">处置结果{{ totalNum }}条</div>
        <!-- 表单 -->
        <div class="yjsbTablePart scrollbar-hidden">
            <div
                class="tableItem"
                v-for="(item, index) in tableList"
                :key="index"
            >
                <div class="label">反馈部门</div>
                <div
                    class="detail pointer flex-1 flex-y-center"
                    :title="item.FKBM"
                >
                    {{ item.FKBM }}
                </div>

                <div class="label">反馈人</div>
                <div
                    class="detail pointer flex-1 flex-y-center"
                    :title="item.FKR"
                >
                    {{ item.FKR }}
                </div>
                <div class="label">反馈时间</div>
                <div
                    class="detail pointer flex-1 flex-y-center"
                    :title="item.FKSJ"
                >
                    {{ item.FKSJ }}
                </div>
                <div class="label">反馈内容</div>
                <div
                    class="detail pointer flex-1 flex-y-center"
                    :title="item.FKNR"
                >
                    {{ item.FKNR }}
                </div>
                <div class="label">附件</div>
                <div
                    class="detail pointer flex-1 flex-y-center"
                    @click="getFile(item)"
                >
                    查看
                </div>
            </div>
            <!-- <el-table
                :data="tableList"
                style="width: 100%"
                max-height="200"
                size="small"
            >
                <el-table-column prop="FKBM" label="反馈部门" width="120">
                    <template slot-scope="scope">
                        <div
                            class="pointer ellipsis"
                            style="height: 20px; line-height: 20px"
                            :title="scope.row.FKBM"
                        >
                            {{ scope.row.FKBM }}
                        </div>
                    </template></el-table-column
                >
                <el-table-column prop="FKR" label="反馈人" width="70">
                    <template slot-scope="scope">
                        <div
                            class="pointer ellipsis"
                            style="height: 20px; line-height: 20px"
                            :title="scope.row.FKR"
                        >
                            {{ scope.row.FKR }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="FKSJ" label="反馈时间" width="90">
                </el-table-column>
                <el-table-column prop="FKNR" label="反馈内容">
                    <template slot-scope="scope">
                        <div
                            class="pointer ellipsis"
                            style="height: 20px; line-height: 20px"
                            :title="scope.row.FKNR"
                        >
                            {{ scope.row.FKNR }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="附件" width="50">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            class="yjsb-file-btn"
                            @click.native.prevent="
                                getFile(scope.$index, scope.row)
                            "
                            >{{
                                scope.row.PIC && scope.row.PIC.length > 0
                                    ? "查看"
                                    : ""
                            }}</el-button
                        >
                    </template>
                </el-table-column>
            </el-table> -->
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "",
    props: {
        id: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            tableList: [],
            totalNum: 0,
            gzTime: [], //已有跟踪数据 起止时间
            gzList: [], //跟踪列表
        };
    },
    watch: {},
    mounted() {
        this.getCzjgfkList();
        this.getYjsbJsbmList();
    },
    methods: {
        ...mapActions([
            "GetCzjgList", // 获取处置结果列表
        ]),
        // 获取处置结果反馈列表
        async getCzjgfkList() {
            this.tableList = [];
            this.totalNum = 0;
            let result = await this.GetCzjgList({
                yjsjId: this.id,
            });
            if (result && result.length > 0) {
                result.map((item) => {
                    item.FKSJ = item.FKSJ.split(" ")[0];
                });
                this.tableList = result;
                this.totalNum = result.length;
            }
        },
        // 表格查看附件
        getFile(row) {
            if (row.PIC.length) {
                window.open(row.PIC[0].CCLJ);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.yjczItemPart {
    position: relative;
    height: 100%;
    .noData {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-30deg);
        color: #5673a2;
        font-size: 18px;
    }
    .label {
        height: 28px;
        line-height: 28px;
        color: #5673a2;
        font-size: 14px;
    }
    .yjsbTablePart {
        height: 220px;
        .tableItem {
            padding: 10px;
            background: rgba(53, 80, 124, 0.2);
            margin-bottom: 10px;
            .label {
                color: #5673a2;
                font-size: 14px;
                height: 28px;
                color: #5673a2;
                font-size: 14px;
                margin-top: 10px;
                &:nth-child(1) {
                    margin-top: 0;
                }
            }
            .detail {
                min-height: 28px;
                color: #a8c8ff;
                font-size: 14px;
                background: rgba(53, 80, 124, 0.2);
                padding: 4px 10px;
            }
        }
    }
}
</style>
