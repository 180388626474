<template>
    <div class="stateBox">
        <span class="left"></span>
        <div class="center">
            <div class="main flex-both-center">
                <span
                    class="name"
                    :style="{ fontSize: jczlFyNum == 0 ? '' : '20px' }"
                    >{{ stateName }}</span
                >
                <span class="state" :style="{ color: getStateColor() }">{{
                    stateType
                }}</span>
                <div class="lineBox">
                    <i class="line line1"></i>
                    <i class="line line2"></i>
                    <i class="line line3"></i>
                    <i class="line line4"></i>
                </div>
                <div class="shapeBox">
                    <div class="top">
                        <i class="shape"></i>
                        <i class="shape shapRotate"></i>
                        <i class="shape"></i>
                    </div>
                    <div class="bottom">
                        <i class="shape shapRotate"></i>
                        <i class="shape"></i>
                        <i class="shape shapRotate"></i>
                    </div>
                </div>
            </div>
        </div>
        <span class="right"></span>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
    name: "stateBox",
    props: {
        stateName: String,
        stateType: {
            type: String,
            default() {
                return "未监测";
            },
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            jczlFyNum: TYPES.jczlFyNum,
        }),
    },
    methods: {
        getStateColor() {
            let color = "";
            window.YCYS_COLOR_LIST.map((item) => {
                if (
                    item.label === this.stateType ||
                    item.desc === this.stateType
                ) {
                    color = item.color;
                }
            });
            return color;
        },
    },
};
</script>
<style scoped lang="scss">
$stateLineColor: #35507c;
.stateBox {
    display: flex;
    .left,
    .right {
        width: 92px;
        height: 92px;
        display: inline-block;
        background-image: url("~@image/bigScreen/ontological_disease/stateIcon.png");
        background-size: contain;
    }
    .center {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        .main {
            position: relative;
            width: calc(100% - 20px);
            height: 50px;
            border-top: 2px solid $stateLineColor;
            border-bottom: 2px solid $stateLineColor;
            font-size: 30px;
            .name {
                color: #a8c8ff;
                margin-right: 50px;
            }
            .lineBox {
                position: absolute;
                top: -10px;
                left: -3px;
                right: -3px;
                bottom: -10px;
                .line {
                    width: 2px;
                    height: 10px;
                    display: block;
                    background-color: $stateLineColor;
                    position: absolute;
                    &.line1 {
                        top: 0;
                        left: 0;
                        transform: rotate(-30deg);
                    }
                    &.line2 {
                        top: 0;
                        right: 0;
                        transform: rotate(30deg);
                    }
                    &.line3 {
                        bottom: 0;
                        left: 0;
                        transform: rotate(-150deg);
                    }
                    &.line4 {
                        bottom: 0;
                        right: 0;
                        transform: rotate(150deg);
                    }
                }
            }
            .shapeBox {
                width: 90px;
                height: calc(100% + 84px);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                .shape {
                    display: inline-block;
                    width: 28px;
                    height: 0;
                    border-width: 0 3px 4px 3px;
                    border-style: none solid solid;
                    border-color: transparent transparent #aac6fc;
                    transform: rotate(180deg);
                    &.shapRotate {
                        transform: rotate(0);
                    }
                }
                .top {
                    height: 60px;
                }
            }
        }
    }
}
</style>
