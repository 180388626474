<template>
    <div class="basicStatistics flex-y-center">
        <div class="centerItem animated bounceInRight"
             v-for="(item, index) in resource"
             :key="index"
             :style="{
                animationDelay: 0.2 + index * 0.2 + 's',
                width: item.width ? item.width : '150px',
            }">
            <div class="name">
                {{ item.name }}
            </div>
            <div class="count flex-x-center flex-y-center">
                <span v-show="item.num"
                      class="num">{{ item.num }}</span>
                <span v-show="!item.num"
                      class="zwsj">暂无数据</span>
                <span v-if="item.num"
                      class="dw">{{ item.dw }}</span>
                <!-- <img class="arrow-img" v-if="Number(item.change)===1||Number(item.change)===-1" :src="require(`@image/bigScreen/ecological_environment/3GclBchdc/${Number(item.change)===1?'arrowUp':Number(item.change)===-1?'arrowDown':''}.png`)" alt=""> -->
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "",
    data () {
        return {};
    },
    props: {
        resource: {
            type: Array,
            default: () => { },
        },
    },
    // filters: {
    //     strictNull(value) {
    //         if (value === null || value === undefined || value === "") {
    //             return "暂无数据";
    //         }
    //         return value;
    //     },
    // },
    mounted () { },
    methods: {
        ...mapActions([]),
    },
};
</script>
<style scoped lang="scss">
.basicStatistics {
    width: 100%;
    height: 100%;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    .centerItem {
        width: 162px;
        margin: 7px;
        .name {
            width: 100%;
            height: 40px;
            background: url('~@image/bigScreen/icon/titleBg.png') no-repeat;
            background-size: 100% 100%;
            font-size: 18px;
            font-weight: 500;
            color: rgba(168, 200, 255, 1);
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
        .count {
            margin-top: 5px;
            height: 65px;
            height: 40px;
            border: 1px solid rgba(53, 80, 124, 0.4);
            color: #bdd7ec;
            position: relative;
            .num {
                font-size: 48px;
                font-family: DBPM;
            }
            .zwsj {
                font-size: 20px;
            }
            .dw {
                margin-left: 5px;
                font-size: 20px;
                color: #a8c8ff;
            }
            .arrow-img {
                margin-left: 5px;
            }
        }
    }
}
</style>
