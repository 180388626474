<template>
    <!-- 预警处置 -->
    <div class="yjczCtn flex">
        <div class="leftPart">
            <div class="title">预警处置</div>
            <div class="yjcz-steps scrollbar-hidden">
                <div
                    v-for="(item, index) in yjczSteps"
                    :key="index"
                    class="flex"
                >
                    <div
                        class="steps-label"
                        @click="handleYjczStepClick(index)"
                    >
                        <img :src="getbgImg(index)" class="bgImg" />
                        <span
                            :style="{
                                color:
                                    index <= ztNum
                                        ? '#fff'
                                        : 'rgba(168,200,255,1)',
                            }"
                            >{{ item.name }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="rightPart">
            <!-- 0.预警核实 :yjztNum="yjztNum"   -->
            <Yjhs
                v-if="currentStep === 0"
                :id="detail.ID"
                :ztNum="ztNum"
            ></Yjhs>
            <!-- 1.预警分发 -->
            <Yjsb
                v-if="currentStep === 1"
                :id="detail.ID"
                :ztNum="ztNum"
                :gzfzrList="gzfzrList"
            ></Yjsb>
            <!-- 2.处置结果反馈 -->
            <Jgfk
                v-if="currentStep === 2"
                :id="detail.ID"
                :ztNum="ztNum"
                :gzfzrList="gzfzrList"
            ></Jgfk>
            <!-- 3.处置跟踪 -->
            <Czgz
                v-if="currentStep === 3"
                :id="detail.ID"
                :ztNum="ztNum"
            ></Czgz>
            <!-- 4.处置工作完成 -->
            <Czgzwc
                v-if="currentStep === 4"
                :id="detail.ID"
                :ztNum="ztNum"
                :yjztNum="yjztNum"
            ></Czgzwc>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import Yjhs from "./1_yjhs.vue"; //预警核实
import Yjsb from "./2_yjsb.vue"; //预警分发
import Jgfk from "./3_jgfk.vue"; //结果反馈
import Czgz from "./4_czgz.vue"; //处置跟踪
import Czgzwc from "./5_czgzwc.vue"; //处置工作完成

export default {
    components: {
        Yjhs,
        Yjsb,
        Jgfk,
        Czgz,
        Czgzwc,
    },
    name: "",
    data() {
        return {
            yjztNum: -1, // 预警状态
            ztNum: -1, // 预警步骤
            currentStep: -1, // 当前点击的步骤，内容板块可见性
            yjczSteps: [
                {
                    name: "预警核实",
                },
                {
                    name: "预警分发",
                },
                {
                    name: "结果反馈 ",
                },
                {
                    name: "处置跟踪",
                },
                {
                    name: "处置工作完成",
                },
            ],
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {
        detail: {
            Type: Object,
            default: () => {
                let obj = {
                    BHCS: "", //变化次数
                    FMYXCS: "", //负面影响次数
                    ID: "",
                    JCCS: "", //监测次数
                    JCKSSJ: "", //监测开始时间
                    JD: "",
                    MC: "", //名称
                    MONITORS: [], //监测数据
                    PICTUREPATH: [], //基础数据照片
                    WD: "",
                    YXPG: "", //0正面影响；1兼有正、负面影响；2负面影响；3目前尚不确定；4无影响
                    YXPG_DESC: "", //影响评估枚举翻译
                    ZB: "",
                };
                return obj;
            },
        },
    },
    watch: {},
    mounted() {
        this.getYjInfo();
    },
    methods: {
        ...mapActions(["GetYjInfo"]),
        ...mapMutations({}),
        // 获取预警信息 或许最新状态
        async getYjInfo() {
            let result = await this.GetYjInfo({
                yjsjId: this.detail.ID, // 预警数据ID
            });
            if (result && result.length > 0) {
                this.yjztNum = Number(result[0].YJZT); //预警状态
                this.ztNum = Number(result[0].ZT); //zt 就是处置步骤到哪一步了
                this.currentStep = Number(result[0].ZT); //zt 就是处置步骤到哪一步了
            }
        },
        // 表头背景色
        handleYjczStepClick(index) {
            this.currentStep = index;
        },
        // 获取左侧背景图片
        getbgImg(index) {
            let src = "";
            if (this.currentStep == index) {
                // 选中
                src = require("@image/bigScreen/build_control/cellActive.png");
            } else if (index <= this.ztNum) {
                // 已完成
                src = require("@image/bigScreen/build_control/cellSuccess.png");
            } else {
                // 未完成
                src = require("@image/bigScreen/build_control/cellError.png");
            }
            return src;
        },
    },
};
</script>
<style scoped lang="scss">
.yjczCtn {
    width: 100%;
    height: 100%;
    .title {
        width: 125px;
        height: 37px;
        line-height: 37px;
        background: url("~@image/bigScreen/build_control/label.png") no-repeat;
        background-size: 100% 100%;
        color: rgba(168, 200, 255, 1);
        font-size: 16px;
        text-indent: 20px;
    }

    .leftPart {
        width: 125px;
        margin-right: 20px;
        .yjcz-steps {
            margin-top: 10px;
            height: calc(100% - 37px);
            .steps-label {
                position: relative;
                width: 100px;
                height: 46px;
                line-height: 40px;
                text-align: center;
                font-size: 14px;
                cursor: pointer;
                margin-bottom: 10px;
                .bgImg {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
        }
    }
    .rightPart {
        width: 486px;
        padding-top: 47px;
        height: 100%;
    }
}
</style>
