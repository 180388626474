<template>
    <div class="rightPart scrollbar-hidden">
        <BoxTitle :title="titleName"
                  :backBtn="false"
                  :downloadBtn="true"
                  @handleDownloadClick="handleDownloadClick"></BoxTitle>
        <div class="jcsb-border">
            <div class="jcsb-item"
                 :class="currentJcsbId === item.id ? 'current-jcsb' : ''"
                 v-for="item in jcsbList"
                 :key="'jcsb' + item.id"
                 @click="handleJcsbClick(item)">
                {{ item.label }}
            </div>
        </div>
        <div class="tjjg-module">
            <!-- 实时数据 -->
            <basic-module :text="common.getFyName(fany.sssj)">
                <div slot="basic-module">
                    <resultStatic :resource="resultStaticResource"
                                  itemWidth="120px"></resultStatic>
                </div>
            </basic-module>
        </div>
        <div class="jcsj-module">
            <!-- 监测数据 -->
            <basic-module :text="common.getFyName(fany.jcsj)">
                <div slot="basic-module"
                     class="jcsj-bar-chart">
                    <NoMonitor v-show="!isChartShow"
                               text="暂无数据"></NoMonitor>
                    <lineChart v-show="isChartShow"
                               :chartData="jmsjChartData"></lineChart>
                    <div class="bs-datepicker jcsj-search">
                        <el-date-picker v-if="currentJcsjBtnId === '1'"
                                        size="mini"
                                        style="width: 100px"
                                        v-model="nfValue"
                                        type="year"
                                        value-format="yyyy"
                                        popper-class="bs-select-panel"
                                        placeholder="选择年"
                                        :clearable="false"
                                        @change="handleDyxtjNfChanged">
                        </el-date-picker>
                        <el-date-picker v-if="currentJcsjBtnId === '2'"
                                        size="mini"
                                        style="width: 120px"
                                        v-model="nfValue"
                                        type="month"
                                        value-format="yyyy-MM"
                                        popper-class="bs-select-panel"
                                        placeholder="选择月"
                                        :clearable="false"
                                        @change="handleDyxtjNfChanged">
                        </el-date-picker>
                        <div :class="
                                currentJcsjBtnId === item.id
                                    ? 'jscj-btn-group-current'
                                    : 'jscj-btn-group'
                            "
                             v-for="(item, index) in jcsjBtnList"
                             :key="'jcsjbtn' + item.id"
                             @click="handleJscjBtnClick(item)">
                            {{ item.label }}
                        </div>
                    </div>
                </div>
            </basic-module>
        </div>
        <div class="jlfx-module">
            <!-- 监测报告 -->
            <basic-module :text="common.getFyName(fany.jcbg)">
                <div slot="basic-module"
                     class="jlfx-border">
                    <div class="jlfx-label">监测结果</div>
                    <div class="jlfx-text">{{ zhpjText | strictNull }}</div>
                    <!-- <div class="jlfx-label">保护建议</div>
                    <div class="jlfx-text">{{ bhjyText | strictNull }}</div> -->
                </div>
            </basic-module>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import BoxTitle from "@views/big_screen/comp/BoxTitle.vue"; //标题
import NoMonitor from "@views/big_screen/comp/NoMonitor.vue"; //标题
import resultStatic from "../comp/result_static.vue";
import lineChart from "../comp/line_chart.vue";
export default {
    name: "",
    components: {
        BoxTitle,
        NoMonitor,
        resultStatic,
        lineChart,
    },
    data () {
        return {
            titleName: "微环境水质监测服务",
            jcsbList: [
                {
                    id: "f3105aea-824d-11eb-b3ce-000c2977b7fd",
                    label: "景迈大桥水质监测站",
                },
                {
                    id: "f31066f7-824d-11eb-b3ce-000c2977b7fd",
                    label: "糯岗水库水质监测站",
                },
            ],
            currentJcsbId: "f31066f7-824d-11eb-b3ce-000c2977b7fd",
            resultStaticResource: [
                {
                    name: "水温(℃)",
                    num: 0,
                    dw: "",
                    change: 1,
                },
                {
                    name: "pH",
                    num: 0,
                    dw: "",
                    change: -1,
                },
                {
                    name: "溶解氧(mg/L)",
                    num: 0,
                    dw: "",
                    change: -1,
                },
                {
                    name: "浊度(NTU)",
                    num: 0,
                    dw: "",
                    change: -1,
                },
                {
                    name: "COD(mg/L)",
                    num: 0,
                    dw: "",
                    change: -1,
                },
                {
                    name: "电导率(us/cm)",
                    num: 0,
                    dw: "",
                    change: -1,
                },
                {
                    name: "氨氮(mg/L)",
                    num: 0,
                    dw: "",
                    change: -1,
                },
            ],
            nfValue: this.common.getTimeYm(),
            jcsjBtnList: [
                {
                    id: "1",
                    label: "月",
                },
                {
                    id: "2",
                    label: "日",
                },
            ],
            currentJcsjBtnId: "2",
            zhpjText: "",
            bhjyText: "",
            jmsjChartData: {
                xAxisData: [],
                legendData: [],
                selectedObj: {},
                seriesData: [],
                xAxisName: "",
                dwArr: [],
            },
            bgxzXdlj: "",
            isChartShow: false,
        };
    },
    filters: {
        strictNull (value) {
            if (value === null || value === undefined || value === "") {
                return "暂无数据";
            }
            return value;
        },
        toFixed (value) {
            return Number(value);
        },
    },
    mounted () {
        let today = this.common.getTimeYmd();
        if (Number(today.split("-")[2]) <= 3) {
            this.nfValue = this.common.someMonthsAgo(1);
        } else {
            this.nfValue = this.common.getTimeYm();
        }
        this.getData();
        this.getSwjcTjt();
        this.getJlbg();
    },
    methods: {
        ...mapActions([
            "GetSwjcHz", // 水文监测汇总
            "GetSwjcTjt", // 水文监测统计图
            "GetJlbg", // 获取结论报告
        ]),
        handleJscjBtnClick (val) {
            if (val.id === this.currentJcsjBtnId) return;
            this.currentJcsjBtnId = val.id;
            if (this.currentJcsjBtnId === "1") {
                this.nfValue = this.common.getTimeY();
            } else {
                this.nfValue = this.common.getTimeYm();
            }
            this.getSwjcTjt();
        },
        handleDyxtjNfChanged () {
            this.getSwjcTjt();
        },
        async getSwjcTjt () {
            let sum = 0;
            this.jmsjChartData = {
                xAxisData: [],
                legendData: [],
                selectedObj: {},
                seriesData: [],
                xAxisName: "",
                dwArr: [],
            };
            const result = await this.GetSwjcTjt({
                ny: this.nfValue,
                sbid: this.currentJcsbId,
            });
            if (result && result.length > 0) {
                let xAxisData = [];
                let PH = [];

                let COD = [];
                let RJY = [];
                let WD = [];
                let ZD = [];
                let DDL = [];
                let AD = [];
                result.forEach((item) => {
                    xAxisData.push(item.RQ);
                    PH.push(item.PH);
                    if (item.PH != null) {
                        sum += Number(item.PH);
                    }
                    COD.push(item.COD);
                    if (item.COD != null) {
                        sum += Number(item.COD);
                    }
                    RJY.push(item.RJY);
                    if (item.RJY != null) {
                        sum += Number(item.RJY);
                    }
                    WD.push(item.WD);
                    if (item.WD != null) {
                        sum += Number(item.WD);
                    }
                    ZD.push(item.ZD);
                    if (item.ZD != null) {
                        sum += Number(item.ZD);
                    }
                    DDL.push(item.DDL);
                    if (item.DDL != null) {
                        sum += Number(item.DDL);
                    }
                    AD.push(item.AD);
                    if (item.AD != null) {
                        sum += Number(item.AD);
                    }
                });

                this.jmsjChartData = {
                    xAxisData,
                    legendData: ["pH", "COD", "溶解氧", "温度", "浊度", "电导率", "氨氮"],
                    selectedObj: {
                        pH: true,
                        COD: false,
                        溶解氧: false,
                        温度: true,
                        浊度: false,
                        电导率: false,
                        氨氮: false,
                    },
                    xAxisName: this.currentJcsjBtnId == "1" ? "月" : "日",
                    // dwArr: ['','mg/L','mg/L','℃','JTU'],
                    dwArr: {
                        pH: "",
                        COD: "mg/L",
                        溶解氧: "mg/L",
                        温度: "℃",
                        浊度: "NTU",
                        电导率: "us/cm",
                        氨氮: "mg/L",
                    },
                    seriesData: [
                        {
                            name: "pH",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: PH,
                            connectNulls: true,
                        },
                        {
                            name: "COD",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: COD,
                            connectNulls: true,
                        },
                        {
                            name: "溶解氧",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: RJY,
                            connectNulls: true,
                        },
                        {
                            name: "温度",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: WD,
                            connectNulls: true,
                        },
                        {
                            name: "浊度",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: ZD,
                            connectNulls: true,
                        },
                        {
                            name: "电导率",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: DDL,
                            connectNulls: true,
                        },
                        {
                            name: "氨氮",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: AD,
                            connectNulls: true,
                        },
                    ],
                };
            }
            if (sum === 0) {
                this.isChartShow = false;
            } else {
                this.isChartShow = true;
            }
        },
        async getJlbg () {
            this.zhpjText = "";
            this.bhjyText = "";
            this.bgxzXdlj = "";
            const result = await this.GetJlbg({
                lx: "9",
            });
            if (!result) return;
            this.zhpjText = result.ZHPJ;
            this.bhjyText = result.BHJY;
            this.bgxzXdlj = result.BGLJ;
        },

        // 报告下载
        handleDownloadClick () {
            if (!this.bgxzXdlj) {
                this.common.showMsg("暂无报告", "warning");
                return;
            }
            window.open("http://" + this.bgxzXdlj);
        },
        handleJcsbClick (val) {
            if (val.id === this.currentJcsbId) return;
            this.currentJcsbId = val.id;
            this.getData();
            this.getSwjcTjt();
        },
        async getData () {
            const res = await this.GetSwjcHz({
                sbid: this.currentJcsbId,
            });
            const keysMap = {
                SW: 0,
                PH: 1,
                RJY: 2,
                ZD: 3,
                COD: 4,
                DDL: 5,
                AD: 6,
            };
            const keysMap2 = {
                SWBH: 0,
                PHBH: 1,
                RJYBH: 2,
                ZDBH: 3,
                DDLBH: 4,
            };

            if (res && typeof res === "object") {
                for (const key in keysMap) {
                    if (this.resultStaticResource[keysMap[key]]) {
                        if (Number(res[key]) > 1) {
                            this.resultStaticResource[keysMap[key]].num = Number(
                                res[key]
                            ).toFixed(0);
                        } else {
                            this.resultStaticResource[keysMap[key]].num = Number(
                                res[key]
                            ).toFixed(2);
                        }
                    }
                }
                for (const key in keysMap2) {
                    if (this.resultStaticResource[keysMap2[key]]) {
                        this.resultStaticResource[keysMap2[key]].change =
                            Number(res[key]).toFixed(0);
                    }
                }
            }
        },
    },
};
</script>
<style scoped lang="scss">
.rightPart {
    width: 100%;
    height: 100%;
    padding: 15px 0;
    .jcsb-border {
        display: flex;
        padding-left: 8px;
        .jcsb-item {
            // width: 120px;
            height: 35px;
            line-height: 35px;
            padding: 0 10px;
            border-radius: 5px;
            text-align: center;
            font-size: 16px;
            color: #a8c8ff;
            background: #35507c;
            margin-left: 15px;
            margin-top: 14px;
            cursor: pointer;
        }
        .current-jcsb {
            color: #0b1b30;
            background: #ffcd80;
            cursor: default;
        }
    }
    .tjjg-module {
        width: 100%;
    }
    .jcsj-module {
        width: 100%;
        height: 360px;
        .jcsj-bar-chart {
            position: relative;
            width: 100%;
            height: 100%;
            .jcsj-search {
                display: flex;
                position: absolute;
                right: 0;
                top: -40px;
                .jscj-btn-group {
                    width: 40px;
                    height: 20px;
                    line-height: 20px;
                    margin-left: 15px;
                    border-radius: 5px;
                    text-align: center;
                    color: #a8c8ff;
                    background: #35507c;
                    cursor: pointer;
                }
                .jscj-btn-group-current {
                    width: 40px;
                    height: 20px;
                    line-height: 20px;
                    margin-left: 15px;
                    border-radius: 5px;
                    text-align: center;
                    color: #040e1e;
                    background: #ffcd80;
                }
            }
        }
    }
    .jlfx-module {
        width: 100%;
        .jlfx-border {
            width: 100%;
            .jlfx-label {
                width: 100%;
                color: #5673a2;
            }
            .jlfx-text {
                width: 100%;
                line-height: 24px;
                margin: 5px 0;
                padding: 9px;
                text-align: justify;
                font-size: 16px;
                font-weight: 500;
                color: #bdd7ec;
                background: rgba(26, 48, 89, 0.3);
            }
        }
    }
}
</style>
