<template>
    <div class="rightPart scrollbar-hidden">
        <BoxTitle
            :title="titleName"
            :backBtn="false"
            :downloadBtn="true"
            @handleDownloadClick="handleDownloadClick"
        ></BoxTitle>
        <div class="tjjg-module">
            <!-- 生长状况 -->
            <basic-module :text="common.getFyName(fany.szzk)">
                <div slot="basic-module" class="tjjg-border">
                    <div
                        v-for="(item, index) in ysbcztList"
                        :key="index"
                        class="ysbcztBox"
                        :class="'ysbcztBox' + index"
                    >
                        <bczkBox :chartData="item" :allNum="allNum"></bczkBox>
                    </div>
                </div>
            </basic-module>
        </div>
        <div class="fbqk-module">
            <!-- 科属统计 -->
            <basic-module :text="common.getFyName(fany.kstj)">
                <div slot="basic-module" class="fbqk-border">
                    <NoMonitor
                        v-show="!isChartShow"
                        text="暂无数据"
                    ></NoMonitor>
                    <rosePieChart
                        v-show="isChartShow"
                        :chartData="fbqkChartData"
                        :lengendData="fbqkLengendData"
                        @getEchartItemName="getEchartItemName"
                    ></rosePieChart>
                </div>
            </basic-module>
        </div>
        <div class="jcsj-module">
            <!-- 分布情况 -->
            <basic-module :text="common.getFyName(fany.fbqk)">
                <div slot="basic-module" class="jcsj-bar-chart">
                    <NoMonitor
                        v-show="!isChartShow2"
                        text="暂无数据"
                    ></NoMonitor>
                    <barChart
                        v-show="isChartShow2"
                        :chartData="kstjChartData"
                    ></barChart>
                </div>
            </basic-module>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapMutations } from "vuex";
import BoxTitle from "@views/big_screen/comp/BoxTitle.vue"; //标题
import NoMonitor from "@views/big_screen/comp/NoMonitor.vue"; //标题
import barChart from "../comp/bar_chart.vue";
import bczkBox from "../../ontological_disease/comp/bczkBox.vue";
import rosePieChart from "../comp/rose_pie_chart.vue";
export default {
    name: "",
    components: {
        BoxTitle,
        NoMonitor,
        barChart,
        bczkBox,
        rosePieChart,
    },
    data() {
        return {
            titleName: "古树名木监测服务",
            ysbcztList: [],
            allNum: 0,
            fbqkChartData: [], // 古树名木科属统计-分布情况
            fbqkLengendData: [],
            kstjChartData: {
                xAxisData: [],
                seriesData: [],
                maxData: [],
            },
            bgxzXdlj: "",
            isChartShow: false,
            isChartShow2: false,
            typeName: "", //类型名称
        };
    },
    filters: {
        toFixed(value) {
            return Number(value);
        },
    },
    mounted() {
        this.getYsbczkData();
        this.getGsmmKstj();
        this.getGsmmFbqk();
        this.getJlbg();
    },
    methods: {
        ...mapActions([
            "GetSzzkStatistic", // 古树名木生长状况
            "GetGsmmKstj", // 古树名木科属统计
            "GetGsmmFbqk", // 古树名木分布情况
            "GetJlbg", // 获取结论报告
        ]),
        ...mapMutations({
            SET_STHJGSMMTYPENAME: TYPES.SET_STHJGSMMTYPENAME,
        }),
        async getYsbczkData() {
            this.ysbcztList = [];
            this.allNum = 0;
            let res = await this.GetSzzkStatistic();
            let allNum = eval(res.map((item) => item.SL).join("+"));

            res.forEach((item) => {
                (item.chartID = "chart" + Math.random()),
                    (item.ratio = ((item.SL / allNum) * 100).toFixed(2));

                if (allNum > 0) {
                    item.name = item.NAME + item.ratio + "%";
                } else {
                    item.name = item.NAME + "0%";
                }

                item.value = item.SL;
            });
            this.ysbcztList = res;
            this.allNum = allNum;
        },
        async getGsmmKstj() {
            let sum = 0;
            this.fbqkChartData = [];
            this.fbqkLengendData = [];
            const result = await this.GetGsmmKstj();
            if (result && result.length > 0) {
                this.fbqkChartData = result;
                this.fbqkChartData.map((item) => {
                    item.name = item.NAME;
                    item.value = item.SL;
                    sum += Number(item.SL);
                    this.fbqkLengendData.push(item.NAME);
                });
            }
            if (sum === 0) {
                this.isChartShow = false;
            } else {
                this.isChartShow = true;
            }
        },
        async getGsmmFbqk() {
            const result = await this.GetGsmmFbqk();
            let sum = 0;
            let nameArr = [];
            let slArr = [];
            let maxVal = 0;
            let maxArr = [];
            this.kstjChartData = {
                xAxisData: [],
                seriesData: [],
                maxData: [],
            };
            if (result && result.length > 0) {
                result.forEach((item1) => {
                    nameArr.push(item1.NAME);
                    slArr.push(item1.SL);
                    sum += Number(item1.SL);
                });
                maxVal = Math.max(...slArr);
                for (let i = 0; i < slArr.length; i++) {
                    maxArr.push(maxVal);
                }
                this.kstjChartData = {
                    xAxisData: nameArr,
                    seriesData: slArr,
                    maxData: maxArr,
                };
            }
            if (sum === 0) {
                this.isChartShow2 = false;
            } else {
                this.isChartShow2 = true;
            }
        },
        async getJlbg() {
            this.bgxzXdlj = "";
            const result = await this.GetJlbg({
                lx: "11",
            });
            if (!result) return;
            this.bgxzXdlj = result.BGLJ;
        },
        // 报告下载
        handleDownloadClick() {
            if (!this.bgxzXdlj) {
                this.common.showMsg("暂无报告", "warning");
                return;
            }
            window.open("http://" + this.bgxzXdlj);
        },
        //点击 科属统计
        getEchartItemName(name) {
            if (this.typeName == name) {
                this.SET_STHJGSMMTYPENAME(null);
            } else {
                this.typeName = name;
                this.SET_STHJGSMMTYPENAME(name);
            }
        },
    },
};
</script>
<style scoped lang="scss">
.rightPart {
    width: 100%;
    height: 100%;
    padding: 15px 0;
    .tjjg-module {
        width: 100%;
        height: 185px;
        .tjjg-border {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 0 15px;
        }
    }
    .fbqk-module {
        width: 100%;
        height: 330px;
        .fbqk-border {
            width: 100%;
            height: 100%;
        }
    }
    .jcsj-module {
        width: 100%;
        height: 325px;
        .jcsj-bar-chart {
            position: relative;
            width: 100%;
            height: 100%;
        }
    }
}
</style>
