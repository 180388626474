<template>
    <div class="project-progress">
        <!-- 工程进展情况统计 -->
        <basic-module :text="common.getFyName(fany.gcjzqktj)">
            <template slot="basic-module">
                <div class="chart_main">
                    <div :id="id" class="chart_box"></div>
                    <i class="tl"></i>
                    <i class="tr"></i>
                    <i class="bl"></i>
                    <i class="br"></i>
                </div>
            </template>
        </basic-module>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            dataConfig: null,
            chart: null,
            id: "gcjzqktj" + Math.random(),
        };
    },
    mounted() {
        this.getData();
        $(window).resize(() => {
            setTimeout(() => {
                this.chart && this.chart.resize();
            }, 300);
        });
    },
    methods: {
        ...mapActions(["getBhgcJzqkStatistics"]),
        async getData() {
            const res = await this.getBhgcJzqkStatistics();
            this.dealData(res);
        },
        dealData({ TOTAL = 0, BHGCJZQKMX = [] } = {}) {
            const colorList = ["#5AD9A6", "#9E66A8", "#F59A23", "#81D3F8"];
            const seriesData = [];
            const legend = [];
            if (Array.isArray(BHGCJZQKMX) && BHGCJZQKMX.length > 0) {
                BHGCJZQKMX.forEach((ele) => {
                    seriesData.push({
                        name: ele.BHGCJZQK_CN,
                        type: "bar",
                        stack: "1",
                        barWidth: 30,
                        data: [Number(ele.COUNT)],
                        itemStyle: {
                            borderWidth: 3,
                            borderColor: "#040e1e",
                        },
                    });
                    legend.push(ele.BHGCJZQK_CN);
                });
            }
            this.dataConfig = {
                color: colorList,
                legend: {
                    show: true,
                    data: legend,
                    icon: "rect",
                    itemWidth: 14,
                    itemHeight: 5,
                    itemGap: 13,
                    textStyle: {
                        color: "rgba(196,214,225,1)",
                    },
                },
                tooltip: {
                    formatter: function (params) {
                        let result = `
                            <span 
                                style="display:inline-block;
                                        margin-right:5px;
                                        border-radius:10px;
                                        width:9px;
                                        height:9px;
                                        background-color:${params.color}"
                            >
                            </span>
                            ${params.seriesName}：${params.value}个（${(
                            (params.value / TOTAL) *
                            100
                        ).toFixed(2)}%）
                        `;
                        return result;
                    },
                },
                title: {
                    text: "工程数量" + TOTAL,
                    textStyle: {
                        color: "#A8C8FF",
                        fontSize: 14,
                        fontWeight: "normal",
                    },
                    x: "right",
                    y: 5,
                },
                grid: {
                    containLabel: true,
                    left: 10,
                    right: 10,
                    top: 20,
                    bottom: 0,
                },
                xAxis: {
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                },
                yAxis: {
                    data: ["sss"],
                    axisLabel: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                },
                series: seriesData,
            };
            this.draw();
        },
        draw() {
            this.chart = this.$echarts.init(document.getElementById(this.id));
            this.chart.setOption(this.dataConfig);
        },
    },
};
</script>

<style lang="scss" scoped>
.project-progress {
    .chart_main {
        width: calc(100% - 2px);
        height: 100%;
        border: 1px solid rgba(168, 200, 255, 0.4);
        position: relative;
        .chart_box {
            height: 100%;
        }
        > i {
            position: absolute;
            display: inline-block;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background-color: rgba(168, 200, 255, 0.8);
            &.tl {
                top: -2px;
                left: -2px;
            }
            &.tr {
                top: -2px;
                right: -2px;
            }
            &.bl {
                bottom: -2px;
                left: -2px;
            }
            &.br {
                bottom: -2px;
                right: -2px;
            }
        }
    }
}
</style>
