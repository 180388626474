<template>
    <div class="screenCtn flex-between">
        <!-- 左侧面板 -->
        <div class="leftView">
            <PageLeft></PageLeft>
        </div>
        <!-- 中间地图面板 -->
        <div class="centerView flex-1">
            <PageCenter :positionStr="positionStr"></PageCenter>
        </div>
        <!-- 右侧面板 -->
        <div class="rightView">
            <PageRight v-if="bhgcPanelNum == 0"></PageRight>
            <PageRightBhgc v-if="bhgcPanelNum == 1"></PageRightBhgc>
        </div>
    </div>
</template>
<script>
import PageLeft from "./1_page_left/index"; //左测 部分
import PageCenter from "./2_page_center/index"; //中间 地图部分
import PageRight from "./3_page_right/page_right"; //右侧 总体
import PageRightBhgc from "./3_page_right/page_right_bhgc"; //工程详情
import * as TYPES from "@store/modules/mutation-type.js";
import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
    name: "",
    components: {
        PageLeft,
        PageCenter,
        PageRight,
        PageRightBhgc,
    },
    data() {
        return {
            listAry: [],
        };
    },
    computed: {
        ...mapGetters(["bhgcPanelNum"]),
    },
    props: {
        positionStr: {
            type: String,
            default: "",
        },
    },
    watch: {
        bhgcPanelNum: {
            immediate: true,
            handler() {
                // console.log(
                //     "%c 🌮 this.bhgcPanelNum: ",
                //     "font-size:20px;background-color: #93C0A4;color:#fff;",
                //     this.bhgcPanelNum
                // );
            },
        },
    },
    created() {},
    mounted() {
        // this.setStateBhgc({
        //     bhgcYcysItem: null, //遗产要素 某一个
        //     bhgcPanelNum: 1, //右侧面板数
        //     bhgcItem: null, //保护工程 某一个
        //     bhgcJcwzItem: "", // 某一个监测点位
        //     bhgcActiveNum: 0, // 保护工程 选中项
        // });
    },
    methods: {
        // ...mapMutations({
        //     setStateBhgc: "setStateBhgc",
        // }),
    },
};
</script>
<style scoped lang="scss">
/* 中间主体内容 */
.screenCtn {
    position: relative;
    height: 100%;
    /* 左边部分 */
    .leftView {
        position: relative;
        width: 725px;
        height: 100%;
        // border:1px solid #fff;
    }

    /* 中间部分 */
    .centerView {
        margin: 0 6px;
        height: 100%;
    }

    /* 右边部分 */
    .rightView {
        position: relative;
        width: 711px;
        height: 100%;
    }
}
</style>
