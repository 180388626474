// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@image/bigScreen/icon/titleBg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".rightPart[data-v-4ebd9573]{width:100%;height:100%;padding:15px 0}.rightPart .ztbcztPart[data-v-4ebd9573]{padding:0 24px}.rightPart .ztbcztPart .ztbczt[data-v-4ebd9573]{height:92px;margin:15px 0}.rightPart .ztbcztPart .ztChart[data-v-4ebd9573]{height:180px}.rightPart .bhkzPart[data-v-4ebd9573]{height:540px}.rightPart .bhkzPart .allCount[data-v-4ebd9573]{height:40px;display:flex;justify-content:space-between}.rightPart .bhkzPart .allCount .itemCount[data-v-4ebd9573]{width:calc(50% - 9px);display:flex;text-align:center;height:40px;line-height:40px}.rightPart .bhkzPart .allCount .itemCount .name[data-v-4ebd9573]{width:160px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;font-size:18px;color:#a8c8ff}.rightPart .bhkzPart .allCount .itemCount .num[data-v-4ebd9573]{width:calc(100% - 170px);font-family:DBPM;color:#ffcd80;font-size:50px;border:1px solid #1e3353;margin-left:10px}.rightPart .bhkzPart .bhCount[data-v-4ebd9573]{margin:15px 0;height:130px}.rightPart .bhkzPart .bhCount .ysbcztBox[data-v-4ebd9573]{margin-right:10px}.rightPart .bhkzPart .bhChart[data-v-4ebd9573]{height:270px}", ""]);
// Exports
module.exports = exports;
