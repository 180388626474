<template>
    <div class="rightPart scrollbar-hidden">
        <BoxTitle
            :title="titleName"
            :backBtn="false"
            :downloadBtn="true"
            @handleDownloadClick="handleDownloadClick"
        ></BoxTitle>
        <!-- 古茶树病虫害危害程度 -->
        <div class="topPart">
            <!-- 古茶树病虫害危害程度 -->
            <basic-module :text="common.getFyName(fany.gcsbchwhcd)">
                <div slot="basic-module" class="tjjgAll">
                    <div
                        class="tjjg-center"
                        v-for="(item, index) in bchwhcdList"
                        :key="index"
                        @click="getItem(item, index)"
                    >
                        <div class="name" :style="{ color: item.color }">
                            {{ item.name }}
                        </div>
                        <div class="count">
                            <bcwhcdBox
                                :chartData="item"
                                :allNum="allNum"
                            ></bcwhcdBox>
                        </div>
                    </div>
                </div>
            </basic-module>
        </div>
        <!-- 古茶林虫害 古茶林病害 古茶林寄生物 -->
        <div class="centerPart">
            <basic-module text=" ">
                <div slot="basic-module" class="jcsj-all">
                    <div class="jcsj-title">
                        <div
                            v-for="(item, index) in gclList"
                            :key="index"
                            class="gclItem"
                            @click="changeTanGcl(item, index)"
                        >
                            <div
                                class="item-label"
                                :class="[
                                    currentGclId == index
                                        ? 'labelActive'
                                        : 'labelDefault',
                                ]"
                                :style="{
                                    fontSize: jczlFyNum == 0 ? '' : '18px',
                                }"
                            >
                                {{ jczlFyNum == 0 ? item.label : item.enLabel }}
                            </div>
                        </div>
                    </div>
                    <div class="gclchonghai" v-if="currentGclId == 0">
                        <div class="jcsj-label">
                            2021年调查共2纲7目9科16种害虫
                        </div>
                        <div class="jcsj-border">
                            <div class="jcsj-hc">
                                <img
                                    src="@image/bigScreen/ecological_environment/Right/gclch1.png"
                                />
                            </div>
                            <div class="jcsj-Chart1">
                                <gclch></gclch>
                            </div>
                        </div>
                    </div>
                    <div class="gclbh" v-if="currentGclId == 1">
                        <div class="jcsj-label">
                            2021年调查，共记录到6种病害，分别为茶煤病、茶炭疽病、茶白星病、茶云纹叶枯病、茶轮斑病和茶圆赤星病
                        </div>
                        <div class="jcsj-border">
                            <div class="jcsj-chart2">
                                <gclbh></gclbh>
                            </div>
                        </div>
                    </div>
                    <div class="jcsj-gcljsw" v-if="currentGclId == 2">
                        <div class="jcsj-jsw">
                            <div class="jcsj-pic">
                                <img
                                    src="@image/bigScreen/ecological_environment/Right/tx.png"
                                />
                            </div>
                            <div class="jcsj-name">苔藓</div>
                        </div>
                        <div class="jcsj-jsw">
                            <div class="jcsj-pic">
                                <img
                                    src="@image/bigScreen/ecological_environment/Right/dy.png"
                                />
                            </div>
                            <div class="jcsj-name">地衣</div>
                        </div>
                        <div class="jcsj-jsw">
                            <div class="jcsj-pic">
                                <img
                                    src="@image/bigScreen/ecological_environment/Right/bzhjs.png"
                                />
                            </div>
                            <div class="jcsj-name">扁枝叶槲寄生</div>
                        </div>
                    </div>
                </div>
            </basic-module>
        </div>
        <!-- 结论分析 -->
        <div class="bottomPart">
            <!-- 结论分析 -->
            <basic-module :text="common.getFyName(fany.jlfx)">
                <!-- <div slot="basic-module" class="jlfx-border">
                    <div class="jlfx-label">绿色防治</div>
                    <div class="jlfx-text" v-html="zhpjText">
                    </div>
                </div> -->
                <div slot="basic-module" class="jlfxPart">
                    <div class="jlfx-label">绿色防治</div>
                    <div class="jlfx-text" v-html="zhpjText"></div>
                    <!-- <div
                        v-for="(item, index) in jlfxList"
                        :key="index"
                        class="jlfxItem"
                    >
                        <div class="titlePart">
                            <div class="dot"></div>
                            <div class="title">
                                {{ item.title }}
                            </div>
                        </div>
                        <div class="flex descPart">
                            <div
                                class="desc flex-1 pointer ellipsis"
                                :title="item.desc"
                            >
                                {{ item.desc }}
                            </div>
                            <div
                                class="detail pointer"
                                @click="openPdfDetail(item)"
                            >
                                {{ item.detail }}
                            </div>
                        </div>
                    </div> -->
                </div>
            </basic-module>
        </div>
    </div>
</template>
<script>
import BoxTitle from "@views/big_screen/comp/BoxTitle.vue"; //标题
import bcwhcdBox from "../comp/gclbchdc/bcwhcdBox.vue";
import gclch from "../comp/gclbchdc/gclch.vue";
import gclbh from "../comp/gclbchdc/gclbh.vue";
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    name: "",
    components: {
        BoxTitle,
        bcwhcdBox,
        gclch,
        gclbh,
    },
    data() {
        return {
            titleName: "古茶林病虫害调查服务",
            bchwhcdList: [
                {
                    name: "重度",
                    num: 0,
                    per: 0,
                    chartID: "zdd",
                    color: "#ff4c4c",
                },
                {
                    name: "中度",
                    num: 0,
                    per: 0,
                    chartID: "zd",
                    color: "#ffb508",
                },
                {
                    name: "轻度",
                    num: 0,
                    per: 0,
                    chartID: "qd",
                    color: "#ffff00",
                },
                {
                    name: "正常",
                    num: 0,
                    per: 0,
                    chartID: "zc",
                    color: "#00bd8d",
                },
            ], // 病虫害危害程度
            allNum: 0,
            bgxzUrl: "", // 报告下载地址
            gclList: [
                {
                    id: "0",
                    label: "古茶林虫害",
                    enLabel: "Insect pests of Old tea forest",
                },
                {
                    id: "1",
                    label: "古茶林病害",
                    enLabel: "Old tea forest disease",
                },
                {
                    id: "2",
                    label: "古茶林寄生物",
                    enLabel: "Old tea forest parasite",
                },
            ],
            currentGclId: 0, //中间古茶林 选中某一个
            gclchList: [
                {
                    value: "",
                    name: "",
                },
            ],
            gclbhList: {
                category: "",
                barData: "",
            },
            // 结论分析
            jlfxList: [
                {
                    title: "绿色防治",
                    desc: "",
                    detail: "详情>>",
                    page: 0,
                },
                {
                    title: "",
                    desc: "",
                    detail: "详情>>",
                    page: 0,
                },
                {
                    title: "",
                    desc: "",
                    detail: "详情>>",
                    page: 0,
                },
            ],
            pdfPart: {
                title: "",
                src: "",
            },
            zhpjText: "",
        };
    },
    props: {},
    computed: {
        ...mapGetters({
            jczlFyNum: TYPES.jczlFyNum,
        }),
    },
    mounted() {
        this.getGcsbchwhcdData();
        this.getJlbgData();
        this.getGclbhData();
        this.getGclchData();
    },
    methods: {
        ...mapActions([
            "GetJlbg", // 获取结论报告
            "GetGclch", // 获取古茶林病虫害统计
            "GetBchWhcd", // 获取病虫害危害程度
        ]),
        ...mapMutations({
            SET_STHJJLFXXQSELECT: TYPES.SET_STHJJLFXXQSELECT,
        }),
        // 报告下载
        handleDownloadClick() {
            if (!this.bgxzUrl) {
                this.common.showMsg("暂无报告", "warning");
                return;
            }
            window.open(this.bgxzUrl);
        },
        //页面切换
        changeTanGcl(item, index) {
            this.currentGclId = index;
        },
        //获取病虫害危害程度
        async getGcsbchwhcdData() {
            let result = await this.GetBchWhcd();
            this.bchwhcdList[0].num = result.SHSLZHD; // 重度
            this.bchwhcdList[1].num = result.SHSLZD; // 中度
            this.bchwhcdList[2].num = result.SHSLQD; // 轻度
            this.bchwhcdList[3].num = result.SHSLWWH; // 正常

            this.allNum =
                result.SHSLZHD + result.SHSLZD + result.SHSLQD + result.SHSLWWH;
            this.bchwhcdList[0].per = (
                (result.SHSLZHD / this.allNum) *
                100
            ).toFixed(2); // 重度
            this.bchwhcdList[1].per = (
                (result.SHSLZD / this.allNum) *
                100
            ).toFixed(2); // 中度
            this.bchwhcdList[2].per = (
                (result.SHSLQD / this.allNum) *
                100
            ).toFixed(2); // 轻度
            this.bchwhcdList[3].per = (
                (result.SHSLWWH / this.allNum) *
                100
            ).toFixed(2); // 轻度
        },
        //获取古茶林  病害统计
        async getGclbhData() {
            let result = await this.GetGclch({
                lx: "1",
            });
            this.gclbhList.category = result.MC; //病害名称
            this.gclbhList.barData = result.SHSL; //受害数量
        },
        //获取古茶林  虫害统计
        async getGclchData() {
            let result = await this.GetGclch({
                lx: "0",
            });
            this.gclchList.name = result.MC; //虫害名称
            this.gclchList.value = result.SHSL; //比例
        },
        // 获取监测报告及结论分析
        async getJlbgData() {
            let result = await this.GetJlbg({
                lx: "7",
            });
            if (!result) return;
            this.bgxzUrl = result.BGLJ;

            this.zhpjText = result.ZHPJ;
            // 结论分析
            this.jlfxList[0].page = result.ZHPJPAGE;
            this.jlfxList[0].desc = result.ZHPJ;

            this.jlfxList[1].page = result.FZCSPAGE;
            this.jlfxList[1].desc = result.FZCS;

            this.jlfxList[2].page = result.BHJYPAGE;
            this.jlfxList[2].desc = result.BHJY;
            this.pafPart = {
                title: result.BGMC,
                src: result.PDFLJ,
            };
        },
        // PDF
        openPdfDetail(item) {
            let obj = {
                title: this.pafPart.title,
                src: this.pafPart.src,
                page: item.page,
            };
            this.SET_STHJJLFXXQSELECT(obj);
        },
    },
};
</script>
<style scoped lang="scss">
.rightPart {
    width: 100%;
    height: 100%;
    padding: 15px 0;
    // 总体保存状态
    .ztbcztPart {
        height: 92px;
        padding: 0 24px;
        margin-top: 15px;
    }
    .topPart {
        width: 100%;
        .tjjgAll {
            width: 100%;
            height: 180px;
            display: flex;
            justify-content: space-around;
            .tjjg-center {
                width: 160px;
                .name {
                    width: 100%;
                    height: 40px;
                    background: url("~@image/bigScreen/icon/titleBg.png")
                        no-repeat;
                    background-size: 100% 100%;
                    font-size: 18px;
                    font-weight: 500;
                    color: rgba(168, 200, 255, 1);
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                }
                .count {
                    margin-top: 5px;
                    height: 140px;
                    border: 1px solid rgba(53, 80, 124, 0.4);
                    color: #bdd7ec;
                }
            }
        }
    }
    .centerPart {
        width: 100%;
        height: 350px;
        .jcsj-all {
            width: 100%;
            height: 100%;
            .jcsj-title {
                width: 100%;
                height: 60px;
                color: #a8c8ff;
                font-size: 20px;
                display: flex;
                justify-content: flex-start;
                position: absolute;
                top: -55px;
                left: 0px;
                .gclItem {
                    height: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 10px;
                    cursor: pointer;
                    .item-label {
                        color: #a8c8ff;
                    }
                    .labelActive {
                        color: #ffaa5b;
                    }
                    &:hover {
                        .item-label {
                            color: #ffaa5b;
                        }
                    }
                }
            }
            .jcsj-label {
                width: 100%;
                height: 40px;
                color: #bdd7ec;
                font-size: 16px;
            }
            .jcsj-border {
                width: 100%;
                height: 240px;
                display: flex;
                flex-direction: row;
                .jcsj-hc {
                    width: 50%;
                }
                .jcsj-Chart1 {
                    width: 50%;
                    background: url("~@image/bigScreen/ecological_environment/Right/background.png")
                        no-repeat;
                    background-size: 100%;
                    background-position: center;
                }
                .jcsj-chart2 {
                    width: 100%;
                    height: 100%;
                    margin-top: 10px;
                }
            }
        }
    }
    .centerPart {
        width: 100%;
        height: 350px;
        .jcsj-gcljsw {
            height: 100%;
            display: flex;
            flex-direction: row;
            .jcsj-jsw {
                width: 33.3%;
                height: 100%;
                .jcsj-pic {
                    width: 100%;
                    margin: 0;
                    img {
                        margin-left: auto;
                        margin-right: auto;
                        display: block;
                    }
                }
                .jcsj-name {
                    height: 50px;
                    color: #9ab4e3;
                    font-size: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
    .bottomPart {
        width: 100%;
        .jlfxPart {
            width: 100%;
            .jlfx-label {
                width: 100%;
                color: #5673a2;
            }
            .jlfx-text {
                width: 100%;
                line-height: 24px;
                margin: 5px 0;
                padding: 9px;
                text-align: justify;
                font-size: 16px;
                font-weight: 500;
                color: #bdd7ec;
                background: rgba(26, 48, 89, 0.3);
            }
            .jlfxItem {
                width: 100%;
                .titlePart {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    .dot {
                        width: 4px;
                        height: 4px;
                        opacity: 1;
                        border-radius: 50%;
                        background: #99b7eb;
                        margin-top: 8px;
                    }
                    .title {
                        width: calc(100% - 4px);
                        color: #5673a2;
                        font-size: 15px;
                        margin-left: 10px;
                    }
                }
                .descPart {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    .desc {
                        width: calc(100% - 50px);
                        line-height: 20px;
                        margin: 5px 0;
                        margin-left: 15px;
                        text-align: justify;
                        font-size: 15px;
                        font-weight: 500;
                        color: #bdd7ec;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    .detail {
                        width: 50px;
                        line-height: 20px;
                        margin: 5px 0;
                        text-align: justify;
                        font-size: 14px;
                        color: #ffcd80;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>
