<template>
    <div class="screenCenter rcxcScreen">
        <!-- 地图 -->
        <div class="mapPart">
            <MyMap
                ref="myMap"
                :myMap.sync="myMap"
                :mapData="mapData"
                @reSetMap="reSetMap()"
            ></MyMap>
        </div>
        <!-- 图层 -->
        <div class="mapChangePart">
            <div
                class="layer pointer flex-between-y-center"
                @click="layerState = !layerState"
            >
                <div class="desc">图层</div>
                <img src="@image/bigScreen/icon/layer.png" />
            </div>
            <div v-show="layerState" class="layerCtn">
                <LayerList
                    :listAry="onOffList"
                    keyValue="daily_inspect"
                    @switchStateChange="switchStateChange"
                ></LayerList>
            </div>
        </div>
        <!-- 图例 -->
        <div class="legendPart">
            <LegendList :listAry="legendList"></LegendList>
        </div>
        <!-- 采集点 弹出框-->
        <div v-show="dialogCjdData.dialog" class="popupFramePatrol">
            <Calendar
                :patrolData="dialogCjdData.patrolData"
                @closeCalendar="closeCalendar"
                @selectDateChange="selectDateChange"
            ></Calendar>
        </div>

        <!-- 巡查人员 右侧 -->
        <div v-show="patrolPeople.dialog" class="patrolPart rcxcScreenXcry">
            <div class="title">巡查人员</div>
            <div class="inputPart">
                <el-date-picker
                    v-model="patrolPeople.time"
                    type="date"
                    style="width: 140px"
                    :picker-options="pickerOptions"
                    placeholder="选择时间"
                    value-format="yyyy-MM-dd"
                    popper-class="common-element-date-panel "
                    :clearable="false"
                    @change="getInspectorList()"
                ></el-date-picker>
            </div>
            <div class="selectPart">
                <el-select
                    style="margin: 4px 0; width: 140px"
                    v-model="patrolPeople.departmentId"
                    placeholder="部门筛选"
                    popper-class="rcxc-department-select-pop-panel"
                    filterable
                    clearable
                    @change="getInspectorList()"
                >
                    <el-option
                        v-for="(item, index) in departmentList"
                        :key="index"
                        :label="item.DepartmentName"
                        :value="item.DepartmentId"
                    >
                    </el-option>
                </el-select>
            </div>

            <!-- @change="drawPeopleTrail" -->
            <div class="patrolPeopleList scrollbar-translucent">
                <div v-for="(item, index) in patrolPeople.list" :key="index">
                    <div class="flex-between-y-center patrolPeopleItem pointer">
                        <!-- <img
                            @click="drawPeopleTrail(item)"
                            :src="
                                item.state
                                    ? patrolPeople.checkActive
                                    : patrolPeople.checkDefault
                            "
                        /> -->
                        <div
                            class="flex flex-1"
                            @click="getPersonPosition(item, index)"
                        >
                            <img
                                :src="
                                    item.ZXZT === 1
                                        ? patrolPeople.peopleOnLine
                                        : patrolPeople.peopleOutLine
                                "
                                class="personIcon"
                                style="margin-right: 10px"
                            />
                            <span
                                :title="item.REALNAME"
                                class="name flex-1"
                                :style="{
                                    color:
                                        checkTrailIndex == index
                                            ? '#5a75ba'
                                            : '',
                                }"
                                >{{ item.REALNAME }}</span
                            >
                        </div>
                        <img
                            @click="getPeopleRoute(item, index)"
                            :src="
                                item.SFXC == '1'
                                    ? patrolPeople.routeActive
                                    : patrolPeople.routeDefault
                            "
                            class="personIcon"
                        />
                    </div>
                </div>
            </div>
        </div>

        <!-- 巡查员 弹出框-->
        <div v-show="dialogViewerData.dialog" class="popupFrameViewer">
            <div class="popupTitle flex-between-y-center">
                <div class="title pointer">{{ dialogViewerData.title }}</div>
                <img
                    src="@image/bigScreen/close.png"
                    alt="关闭按钮"
                    class="closeBtn pointer"
                    @click="dialogViewerData.dialog = false"
                />
            </div>
            <div class="popupCtn">
                <div class="popupCtnTop flex-between-y-center">
                    <div class="popupItem">
                        <div class="desc">负责区域</div>
                        <div class="num">{{ dialogViewerData.fzqy }}</div>
                    </div>
                    <div class="popupItem">
                        <div class="desc">完成率</div>
                        <div class="num">{{ dialogViewerData.wcl }}</div>
                    </div>
                </div>
                <div class="popupCtnBottom flex-between-y-center">
                    <div class="bottomItem flex-1">
                        <div class="desc">采集数据</div>
                        <div>
                            <span class="num">{{ dialogViewerData.cjsj }}</span>
                            <span class="numDw">条</span>
                        </div>
                    </div>
                    <div class="bottomItem flex-1">
                        <div class="desc">巡查路程</div>
                        <div>
                            <span class="num">{{ dialogViewerData.xclc }}</span>
                            <span class="numDw">Km</span>
                        </div>
                    </div>
                    <div class="bottomItem flex-1">
                        <div class="desc">巡查时长</div>
                        <div>
                            <span class="num">{{ dialogViewerData.xcsc }}</span>
                            <span class="numDw">小时</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 打卡点 弹出框-->
        <div v-show="dialogSignData.dialog" class="popupFrameViewer">
            <div class="popupTitle flex-between-y-center">
                <div class="title pointer">{{ dialogSignData.title }}</div>
                <img
                    src="@image/bigScreen/close.png"
                    alt="关闭按钮"
                    class="closeBtn pointer"
                    @click="dialogSignData.dialog = false"
                />
            </div>
            <div class="popupCtn">
                <div class="popupCtnBottom flex-between-y-center">
                    <div class="bottomItem flex-1">
                        <div class="desc">签到次数</div>
                        <div>
                            <span class="num">{{ dialogSignData.num }}</span>
                            <span class="numDw">次</span>
                        </div>
                    </div>
                    <div class="bottomItem flex-1">
                        <div class="desc">签到人</div>
                        <div>
                            <span class="num">{{ dialogSignData.people }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 地图影像切换 -->
        <div class="mapTypePart">
            <MapChangeBtn
                screenName="daily_inspect"
                @baseLayerChange="baseLayerChange"
            ></MapChangeBtn>
        </div>

        <!-- marker 弹出框-->
        <!-- <div v-show="commonDialog.dialog"
             class="commonDialogPart">
            <div class="popupTitle flex-between">
                <div style="flex: 1; border-bottom: 1px #84acc2 solid"></div>
                <div class="titleCenter"
                     :title="commonDialog.title">
                    <img src="@image/bigScreen/titleBgleft.png" />
                    <span class="titleContent">{{ commonDialog.title }}</span>
                    <img src="@image/bigScreen/titleBgright.png" />
                </div>
                <div style="flex: 1; border-bottom: 1px #84acc2 solid"></div>
                <i class="el-icon-close titleIcon pointer"
                   @click="closeComDialog()"></i>
            </div>
            <div class="popupCtn">
                <DialogSxt :detail="commonDialog.detail"
                           v-if="commonDialog.sxtDialog"></DialogSxt>
            </div>
        </div> -->
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import MyMap from "@views/big_screen/comp/map/BaseMap.vue";
import LegendList from "@views/big_screen/comp/LegendList.vue"; //图例
import MapChangeBtn from "@comp/map/MapChangeBtn.vue"; //开关
import Calendar from "./../comp/Calendar"; // 巡查人 日历
import infoMixin from "./info_mixin.js";
import LayerList from "@views/big_screen/comp/LayerList.vue"; //图层
// import DialogSxt from "@views/big_screen/monitor_all/4_page_side/comp/DialogSxt.vue"; //摄像头 弹出框
import DialogSxt from "@views/big_screen/monitor_all/4_page_side/comp/DialogSxtIframe.vue"; //摄像头 iframe 公司 live-player 厂家提供的插件 第三方的监控

export default {
    components: {
        MyMap,
        Calendar,
        MapChangeBtn,
        LegendList,
        LayerList,
        DialogSxt,
    },
    mixins: [infoMixin],
    name: "",
    data() {
        const disabledDate = (time) => {
            const res = !this.dateTimeList.includes(time.getTime());
            return res;
        };
        return {
            myMap: null,
            mapType: "vector",
            mapData: {
                baseMapNum: null,
                mapTypeBtn: false,
                ycqHcqState: false,
                showTool: true,
            },
            layerState: false,
            onOffList: [
                {
                    name: "巡查区",
                    state: true,
                    idList: [],
                },
                {
                    name: "采集点",
                    state: true,
                },
                {
                    name: "打卡点",
                    state: true,
                },
                {
                    name: "巡查员",
                    state: false,
                },
                {
                    name: "异常点",
                    state: false,
                },
                {
                    name: "摄像机",
                    state: false,
                    list: [],
                    group: null,
                    src: require("@image/bigScreen/monitor_all/marker/active/monitor.png"),
                    src_error: require("@image/bigScreen/monitor_all/marker/active/monitor_error.png"),
                    src_gun: require("@image/bigScreen/monitor_all/marker/active/monitor_gun.png"),
                    src_gun_error: require("@image/bigScreen/monitor_all/marker/active/monitor_gun_error.png"),
                },
            ],
            xcqGroup: null, //巡查区 图层组
            xcqLabelGroup: null, //巡查区 名称
            cjdGroup: null, //采集点 图层组
            qddGroup: null, //打卡点 图层组
            ycdGroup: null, //异常点 图层组
            //图例
            legendList: [
                {
                    name: "已采集采集点",
                    src: require("@image/bigScreen/daily_inspect/legend/inspected.png"),
                    width: "24px",
                    height: "28px",
                    state: true,
                },
                {
                    name: "未采集采集点",
                    src: require("@image/bigScreen/daily_inspect/legend/noInspected.png"),
                    width: "24px",
                    height: "28px",
                    state: true,
                },
                {
                    name: "已签到打卡点",
                    src: require("@image/bigScreen/daily_inspect/legend/signed.png"),
                    width: "24px",
                    height: "28px",
                    state: true,
                },
                {
                    name: "未签到打卡点",
                    src: require("@image/bigScreen/daily_inspect/legend/noSigned.png"),
                    width: "24px",
                    height: "28px",
                    state: true,
                },
                {
                    name: "巡查员",
                    src: require("@image/bigScreen/daily_inspect/legend/xcry.png"),
                    width: "20px",
                    height: "20px",
                    state: false,
                },
                {
                    name: "处置中异常",
                    src: require("@image/bigScreen/daily_inspect/legend/errorNoSolve.png"),
                    width: "20px",
                    height: "20px",
                    state: false,
                },
                {
                    name: "已解除异常",
                    src: require("@image/bigScreen/daily_inspect/legend/errorSolved.png"),
                    width: "20px",
                    height: "20px",
                    state: false,
                },
                {
                    name: "球形摄像机",
                    src: require("@image/bigScreen/monitor_all/marker/active/monitor.png"),
                    width: "26px",
                    height: "26px",
                    state: false,
                },
                {
                    name: "枪形摄像机",
                    src: require("@image/bigScreen/monitor_all/marker/active/monitor_gun.png"),
                    width: "26px",
                    height: "26px",
                    state: false,
                },
            ],
            layerGroup: [], //图层组 片区
            cjdList: [], //采集点 列表
            signList: [], //签到 列表
            errorList: [], //异常点 列表
            inspectorList: [], //巡查员 列表
            selectMonth: this.common.getTimeYm(),
            dialogCjdData: {
                id: "",
                dialog: false,
                patrolData: {
                    XCDWMC: "", // 点位名称
                    XCCS: "", // 巡查次数
                    YCCS: "", // 异常次数
                    YC: [], // 异常列表
                    ZC: [], // 正常列表
                    YJ: "", // 预警
                    REALNAME: "", // 姓名
                    DEPARTMENTNAME: "", // 部门
                },
            },
            // 在线人员
            patrolPeople: {
                peopleOnLine: require("@image/bigScreen/daily_inspect/peopleOnLine.png"),
                peopleOutLine: require("@image/bigScreen/daily_inspect/peopleOutLine.png"),
                checkActive: require("@image/bigScreen/daily_inspect/checkActive.png"),
                checkDefault: require("@image/bigScreen/daily_inspect/checkDefault.png"),
                routeActive: require("@image/bigScreen/daily_inspect/routeActive.png"),
                routeDefault: require("@image/bigScreen/daily_inspect/routeDefault.png"),
                dialog: false,
                time: this.common.getTimeYmd(),
                list: [],
                timeShowList: [],
                departmentId: "", //部门
            },
            departmentList: [], //部门数组
            trailGroup: null, //轨迹图层组
            xcyGroup: null, //巡查员图层组
            trailId: "", //某一个人的轨迹 继续运动
            checkTrailIndex: null,
            dialogViewerData: {
                dialog: false,
                title: "巡查员",
                width: "400px",
                fzqy: "", // 负责区域
                wcl: "", // 完成率
                cjsj: "", // 采集数据
                xclc: "", // 巡查路程
                xcsc: "", // 巡查时长
                dw: "", //单位
            },
            // 打卡点 弹出框
            dialogSignData: {
                dialog: false,
                title: "打卡点",
                width: "400px",
                people: "", // 签到人
                num: "", // 未完成的/总的
            },
            pickerOptions: {
                disabledDate,
            },
            markerPopupXcd: null, //采集点 弹出框
            markerPopupSign: null, //打卡点 弹出框
            markerPopupError: null, //异常点 弹出框
            sportRouteColorList: this.common.getRandomRgbColor(50), //运动轨迹色颜色

            areaColorList: [
                "#5a91fb",
                "#5ad9a7",
                "#5c7091",
                "#f7bd16",
                "#e86452",
                "#6ec9eb",
                "#955eb9",
                "#ff9944",
                "#1e9593",
                "#ff99c3",
                "#81511c",
                "#7d0022",
                "#556fb5",
                "#009944",
                "#0068b7",
                "#d31247",
                "#f260ce",
                "#7148f5",
                "#94b750",
                "#0b3da9",
                "#f4da80",
                "#863803",
                "#d2c7b6",
            ],
            basicGroup: null, //遗产要素分布图
            gqyxGroup: null, //高清影像
            areaList: [],
            // marker弹出框
            commonDialog: {
                title: "",
                detail: {},
                dialog: false,
                sxtDialog: false, //摄像机
            },
        };
    },
    props: {
        positionStr: {
            type: String,
            default: "",
        },
    },
    computed: {
        dateTimeList() {
            const list = this.patrolPeople.timeShowList;
            if (Array.isArray(list) && list.length > 0) {
                return list.map((ele) => {
                    const [year, month, day] = ele.split("-");
                    return new Date(year, month - 1, day).getTime();
                });
            }
            return [];
        },
        ...mapGetters({
            rcxcAreaItem: TYPES.rcxcAreaItem,
            rcxcAreaList: TYPES.rcxcAreaList,
            rxcxYclbItem: TYPES.rxcxYclbItem,
            rxcxYctjNum: TYPES.rxcxYctjNum,
        }),
    },
    watch: {
        positionStr: {
            immediate: false,
            handler() {
                if (this.positionStr == "left") {
                    this.baseLayerChange("vector");
                } else if (this.positionStr == "right") {
                    this.baseLayerChange("img");
                }
            },
        },
        rcxcAreaItem: {
            immediate: false,
            handler() {
                this.getRcxcAreaItemAnimate();
            },
        },
        rcxcAreaList: {
            immediate: false,
            handler() {
                // this.drawXcq(this.xcqGroup);
            },
        },
        rxcxYclbItem: {
            immediate: false,
            deep: true,
            handler() {
                if (this.rxcxYclbItem) {
                    let item = this.rxcxYclbItem;
                    this.myMap.flyTo([item.WD, item.JD], 15);
                    this.markerPopupYclb
                        .setLatLng([item.WD, item.JD])
                        .setContent(`<div class="tip">${item.YCSJ}</div>`)
                        .openOn(this.myMap);
                } else {
                    this.markerPopupYclb.removeFrom(this.myMap);
                    this.$refs.myMap.changeFitBounds();
                }
            },
        },
        // 异常类型 统计 0 异常总数 1 未解除 2已解除
        rxcxYctjNum: {
            immediate: false,
            deep: true,
            handler() {
                this.changeRxcxYctjNum(this.rxcxYctjNum);
            },
        },
    },
    destroyed() {
        window.signalr_connection.off("Login");
        window.signalr_connection.off("Logout");
        window.signalr_connection.off("UpLoadTrack");
        window.signalr_connection.off("latestLocation");
    },
    mounted() {
        this.xcqGroup = window.L.layerGroup().addTo(this.myMap);
        this.xcqLabelGroup = window.L.layerGroup().addTo(this.myMap); //巡查区
        this.cjdGroup = window.L.layerGroup().addTo(this.myMap); //采集点
        this.qddGroup = window.L.layerGroup().addTo(this.myMap); //打卡点
        this.xcyGroup = window.L.layerGroup().addTo(this.myMap); //巡查员
        this.ycdGroup = window.L.layerGroup().addTo(this.myMap); //异常点
        this.trailGroup = window.L.layerGroup().addTo(this.myMap); //轨迹
        this.gqyxGroup = window.L.layerGroup().addTo(this.myMap);
        this.basicGroup = window.L.layerGroup().addTo(this.myMap);
        this.onOffList.map((item) => {
            if (item.name == "摄像机") {
                item.group = window.L.layerGroup().addTo(this.myMap);
            }
        });
        this.drawBasicMap(this.basicGroup);
        this.getAreaInfo();
        this.getCjdList(); //采集点
        this.getDkdList(); //打卡点
        this.getInspectorList(); //巡查人
        this.getErrorList(); //异常点
        this.getSxtList();
        // this.myMap.on("zoomend", (e) => {
        // console.log("放大等级inner:", e.target._zoom);
        // let level = e.target._zoom;
        // if (level >= 14.5) {
        //      巡查区
        //     this.drawXcqLabel(this.xcqLabelGroup);
        // } else {
        //     this.clearLayersGroup(this.xcqLabelGroup);
        // }
        // });

        // 定时循环 人员位置
        window.signalr_connection.on("latestLocation", () => {
            if (this.patrolPeople.time !== this.common.getTimeYmd()) {
                return;
            }
            this.getInspectorList();
        });

        // 登录
        window.signalr_connection.on("Login", () => {
            if (this.patrolPeople.time !== this.common.getTimeYmd()) {
                return;
            }
            // let res = JSON.parse(data);
            this.getInspectorList();
        });
        // 退出
        window.signalr_connection.on("Logout", () => {
            if (this.patrolPeople.time !== this.common.getTimeYmd()) {
                return;
            }
            // let res = JSON.parse(data);
            // this.patrolPeople.list.map((item, index) => {
            //     if (item.SJCJFZRID == res.USERID) {
            //         item.ZXZT = 0;
            //         this.$set(this.patrolPeople.list, index, item);
            //     }
            //     return true;
            // });
            this.getInspectorList();
        });
        // 继续运动
        window.signalr_connection.on("UpLoadTrack", () => {
            let res = JSON.parse(data);
            console.log("继续运动", res);
            this.patrolPeople.list.map((item, index) => {
                if (item.SJCJFZRID === res[0].userId) {
                    this.drawPeopleMarkerContinue(item, res);
                }
            });
        });
        //marker 弹出框
        this.markerPopupXcd = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, -22],
            className: "markerPopup",
        });

        this.markerPopupSign = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, -22],
            className: "markerPopup",
        });

        this.markerPopupError = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, -12],
            className: "markerPopup",
        });

        this.markerPopupYclb = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, -22],
            className: "markerPopup",
        });

        //marker 弹出框
        this.markerPopup = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, 0],
            className: "markerPopup",
        });
    },
    methods: {
        ...mapActions([
            "getVectorStyle",
            "GetRcxcYclb",
            "GetRcxcTckzXcd",
            "GetRcxcTckzQdd",
            "GetRcxcXcryAndDate",
            "GetRcxcXcdDetail",
            "GetRcxcXclxByDate",
            "GetRcxcRyxx",
        ]),
        ...mapMutations({
            SET_RCXCYCDWID: TYPES.SET_RCXCYCDWID,
            SET_RCXCAREAITEM: TYPES.SET_RCXCAREAITEM,
            SET_RCXCAREAACTIVENUM: TYPES.SET_RCXCAREAACTIVENUM,
            SET_RCXCPANELNUM: TYPES.SET_RCXCPANELNUM,
            SET_RCXCRXTJNUM: TYPES.SET_RCXCRXTJNUM,
        }),
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        getRcxcAreaItemAnimate() {
            // 缩放地图 到 该片区
            if (this.rcxcAreaItem) {
                let item = this.rcxcAreaItem;
                let areaItem = JSON.parse(item.QYZB);
                let center = window.turf.centerOfMass(areaItem);
                let lng = center.geometry.coordinates[0];
                let lat = center.geometry.coordinates[1];
                this.markerPopup
                    .setLatLng([lat, lng])
                    .setContent(`<div class="tip">${item.QYMC}</div>`)
                    .openOn(this.myMap);
                let markerList = [];
                window.turf.coordAll(areaItem).map((item) => {
                    markerList.push([item[1], item[0]]);
                });
                this.myMap.fitBounds(markerList, {
                    maxZoom: 15,
                });
                //片区选中效果
                Object.values(this.xcqGroup._layers).forEach((itemInner) => {
                    if (itemInner.options.id == this.rcxcAreaItem.ID) {
                        itemInner.setStyle({
                            width: 3,
                            // color: item.YS,
                            // fillColor: item.YS,
                            fillOpacity: "0.8",
                        });
                        setTimeout(() => {
                            itemInner.setStyle({
                                width: 3,
                                fillOpacity: "0.3",
                            });
                        }, 1000);
                        setTimeout(() => {
                            itemInner.setStyle({
                                width: 3,
                                fillOpacity: "0.8",
                            });
                        }, 2000);
                    } else {
                        itemInner.setStyle({
                            width: 1,
                            fillOpacity: "0.3",
                        });
                    }
                });
            } else {
                this.markerPopup.removeFrom(this.myMap);
                this.$refs.myMap.changeFitBounds();
                Object.values(this.xcqGroup._layers).forEach((item) => {
                    item.setStyle({
                        width: 1,
                        fillOpacity: "0.3",
                    });
                });
            }
        },
        // 巡查区
        drawXcq(group) {
            this.clearLayersGroup(group);
            this.areaList.map((item, index) => {
                if (!item.QYZB) {
                    return;
                }
                let areaItem = JSON.parse(item.QYZB);
                // 巡查区
                let layer = window.L.geoJSON(areaItem, {
                    id: item.ID,
                    onEachFeature: (feature, layer) => {
                        layer.setStyle({
                            width: 1,
                            color: item.YS,
                            fillColor: item.YS,
                            fillOpacity: "0.3",
                        });
                    },
                }).addTo(group);
                // layer.on("mouseover", () => {
                //     let lng = 0;
                //     let lat = 0;
                //     if (item.JD) {
                //         lng = item.JD;
                //         lat = item.WD;
                //     } else {
                //         let center = window.turf.centerOfMass(areaItem);
                //         lat = center.geometry.coordinates[1];
                //         lng = center.geometry.coordinates[0];
                //     }
                //     this.markerPopup
                //         .setLatLng([lat, lng])
                //         .setContent(`<div class="tip">${item.QYMC}</div>`)
                //         .openOn(this.myMap);
                // });
                // layer.on("mouseout", () => {
                //     this.markerPopup.removeFrom(this.myMap);
                // });
                layer.on("click", () => {
                    this.SET_RCXCAREAACTIVENUM(index);
                    this.SET_RCXCAREAITEM(item);
                    this.SET_RCXCPANELNUM(1);
                });
            });
        },
        closeAllDialog() {
            this.dialogCjdData.dialog = false;
            this.dialogViewerData.dialog = false;
            this.dialogSignData.dialog = false;
        },
        // 更改图层
        switchStateChange(state, item) {
            item.state = state;
            let list = [];
            switch (item.name) {
                case "巡查区":
                    if (!state) {
                        this.clearLayersGroup(this.xcqGroup);
                    } else {
                        this.drawXcq(this.xcqGroup);
                    }
                    break;
                case "采集点":
                    list = ["已采集采集点", "未采集采集点"];
                    if (!state) {
                        this.clearLayersGroup(this.cjdGroup);
                    } else {
                        this.drawMarkerPatrol(this.cjdGroup, this.cjdList);
                    }
                    break;
                case "打卡点":
                    list = ["已签到打卡点", "未签到打卡点"];
                    if (!state) {
                        this.clearLayersGroup(this.qddGroup);
                    } else {
                        this.drawMarkerDkd(this.qddGroup, this.signList);
                    }
                    break;
                case "异常点":
                    list = ["处置中异常", "已解除异常"];
                    this.SET_RCXCRXTJNUM(null);
                    if (!state) {
                        this.clearLayersGroup(this.ycdGroup);
                    } else {
                        this.drawMarkerError(this.ycdGroup, this.errorList);
                    }
                    break;
                case "巡查员":
                    list = ["巡查员"];
                    this.patrolPeople.dialog = state;
                    if (!state) {
                        this.clearLayersGroup(this.xcyGroup);
                    } else {
                        this.drawPeopleMarker(
                            this.patrolPeople.list,
                            this.xcyGroup
                        );
                    }
                    break;
                case "摄像机":
                    list = ["球形摄像机", "枪形摄像机"];
                    if (state) {
                        this.onOffList.map((item) => {
                            if (item.name == "摄像机") {
                                this.drawSxt(item);
                            }
                        });
                    } else {
                        this.onOffList.map((item) => {
                            if (item.name == "摄像机") {
                                this.clearLayersGroup(item.group);
                            }
                        });
                    }
                    break;
            }
            // 显示异常下方的图例

            this.legendList.map((item) => {
                list.map((itemInner) => {
                    if (item.name == itemInner) {
                        item.state = state;
                    }
                });
            });
        },
        // 获取  采集点列表
        async getCjdList() {
            let result = await this.GetRcxcTckzXcd({});
            this.cjdList = result;
            // this.cjdList = [
            //     {
            //         ID: "5fc4e31e-8c06-4bf3-aa23-b7659eeffc44",
            //         JCWZMC: "景迈大寨佛寺采集点",
            //         ZBXX: '{"type":"Point","coordinates":[100.027721,22.20316]}',
            //         DWLX: "巡查点",
            //         JCPZID: "e5ac0053-a31a-4240-94ed-e6659e42ac3d",
            //         SFXC: "0",
            //     },
            // ];
            this.drawMarkerPatrol(this.cjdGroup, this.cjdList);
        },
        // 采集点
        drawMarkerPatrol(group, list) {
            this.clearLayersGroup(group);
            let iconSuccess = window.L.icon({
                iconUrl: this.legendList[0].src,
                iconSize: [24, 28],
                iconAnchor: [12, 28],
            });
            let iconError = window.L.icon({
                iconUrl: this.legendList[1].src,
                iconSize: [24, 28],
                iconAnchor: [12, 28],
            });

            list.map((item) => {
                let lat = JSON.parse(item.ZBXX).coordinates[1];
                let lng = JSON.parse(item.ZBXX).coordinates[0];
                let layer = window.L.marker([lat, lng], {
                    icon: item.SFXC == 0 ? iconError : iconSuccess,
                }).addTo(group);

                layer.on("click", () => {
                    this.dialogCjdData.id = item.ID;
                    this.getCjdDetail();
                });
                layer.on("mouseover", () => {
                    this.markerPopupXcd
                        .setLatLng([lat, lng])
                        .setContent(`<div class="tip">${item.JCWZMC}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopupXcd.removeFrom(this.myMap);
                });
            });
        },
        // 采集点位 详情
        async getCjdDetail() {
            let result = await this.GetRcxcXcdDetail({
                xcid: this.dialogCjdData.id,
                date: this.selectMonth,
            });
            this.closeAllDialog();
            this.dialogCjdData.dialog = true;
            this.dialogCjdData.patrolData.XCDWMC = result.XCDWMC; // 点位名称
            this.dialogCjdData.patrolData.XCCS = result.XCCS; // 巡查次数
            this.dialogCjdData.patrolData.YCCS = result.YCCS; // 异常次数
            this.dialogCjdData.patrolData.YC = result.YC; // 异常列表
            this.dialogCjdData.patrolData.ZC = result.ZC; // 正常列表
            this.dialogCjdData.patrolData.YJ = result.YJ; // 预警
            this.dialogCjdData.patrolData.REALNAME = result.REALNAME; // 姓名
            this.dialogCjdData.patrolData.DEPARTMENTNAME =
                result.DEPARTMENTNAME; // 部门
        },
        // 获取 打卡列表
        async getDkdList() {
            let result = await this.GetRcxcTckzQdd({});
            this.signList = result;
            this.drawMarkerDkd(this.qddGroup, this.signList);
        },
        // 打卡点
        drawMarkerDkd(group, list) {
            this.clearLayersGroup(group);
            let iconSuccess = window.L.icon({
                iconUrl: this.legendList[2].src,
                iconSize: [24, 28],
                iconAnchor: [12, 28],
            });
            let iconError = window.L.icon({
                iconUrl: this.legendList[3].src,
                iconSize: [24, 28],
                iconAnchor: [12, 28],
            });

            list.map((item) => {
                let lat = JSON.parse(item.ZBXX).coordinates[1];
                let lng = JSON.parse(item.ZBXX).coordinates[0];
                // console.log("lat", lat);
                let layer = window.L.marker([lat, lng], {
                    icon: item.SFQD == 0 ? iconError : iconSuccess,
                }).addTo(group);

                layer.on("click", () => {
                    this.closeAllDialog();
                    this.dialogSignData.title = item.JCWZMC;
                    this.dialogSignData.num = item.QDCS;
                    this.dialogSignData.people = item.QDRMC;
                    this.dialogSignData.dialog = true;
                });
                layer.on("mouseover", () => {
                    this.markerPopupSign
                        .setLatLng([lat, lng])
                        .setContent(`<div class="tip">${item.JCWZMC}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopupSign.removeFrom(this.myMap);
                });
            });
        },
        // 获取 巡查员列表
        async getInspectorList() {
            // 清空选中的轨迹
            this.checkTrailIndex = null;
            this.clearLayersGroup(this.trailGroup);

            let result = await this.GetRcxcXcryAndDate({
                date: this.patrolPeople.time,
                departmentId: this.patrolPeople.departmentId
                    ? this.patrolPeople.departmentId
                    : "",
            });
            this.clearLayersGroup(this.trailGroup);
            // 加入今天的时间
            let today = this.common.getTimeYmd();
            if (!result.DATEDT.includes(today)) {
                result.DATEDT.push(today);
            }
            this.patrolPeople.list = result.XCRYDT;
            this.patrolPeople.timeShowList = result.DATEDT;
            // 消息推送 上下线 人员marker 显示隐藏
            this.onOffList.map((item) => {
                if (item.name == "巡查员" && item.state) {
                    this.drawPeopleMarker(
                        this.patrolPeople.list,
                        this.xcyGroup
                    );
                }
            });
        },
        //  巡查员
        drawPeopleMarker(list, group) {
            this.clearLayersGroup(group);
            let img = require(`@image/bigScreen/daily_inspect/legend/inspector.png`);
            list.map((item) => {
                if (!item.NEWJD || !item.NEWWD || item.ZXZT == 0) {
                    return;
                }
                let html = `<div style="position: absolute;
                                    top: 0;
                                    left: 50%;
                                    text-align:center;
                                    transform: translateX(-50%);">
                            <div
                             style="background: rgb(3, 23, 38);
                                    opacity: 0.8;
                                    border-radius: 5px;
                                    white-space:nowrap;
                                    padding:4px 10px;color:#fff;">
                              ${item.REALNAME}
                            </div>
                    <img src=${img} style="margin-top:4px;"/>
                </div> `;
                let iconUrl = window.L.divIcon({
                    html: html,
                    iconSize: [40, 52],
                    iconAnchor: [20, 84],
                    className: "",
                });
                let layer = window.L.marker([item.NEWWD, item.NEWJD], {
                    icon: iconUrl,
                }).addTo(group);
                layer.on("click", () => {
                    this.getPatrolPeopleInfo(item);
                });
            });
        },
        // 地图放大到 该人员位置
        getPersonPosition(obj) {
            if (obj.ZXZT != "1") {
                this.common.showMsg(
                    `${obj.REALNAME} ${this.patrolPeople.time} 不在线`,
                    "warning"
                );
                return;
            }
            this.myMap.flyTo([obj.NEWWD, obj.NEWJD], 16.5);
        },
        // 获取 巡查人员是否有轨迹
        async getPeopleRoute(item, index) {
            if (item.SFXC != 1) {
                this.common.showMsg(
                    `${item.REALNAME} ${this.patrolPeople.time} 暂无轨迹`,
                    "warning"
                );
                return;
            }
            if (this.checkTrailIndex == index) {
                this.checkTrailIndex = null;
            } else {
                this.checkTrailIndex = index;
            }
            let result = await this.GetRcxcXclxByDate({
                date: this.patrolPeople.time,
                userid: item.SJCJFZRID,
                sbid: item.SBID,
            });
            item.list = result[0].XCLX;

            if (this.checkTrailIndex == null) {
                this.clearLayersGroup(this.trailGroup);
            } else {
                this.drawPeopleTrail(item);
            }
        },
        //  巡查轨迹
        drawPeopleTrail(item) {
            this.trailId = item.SJCJFZRID;
            let list = item.list;
            // let color = this.sportRouteColorList[0];
            let color = "#ff7e00";
            let group = this.trailGroup;
            this.clearLayersGroup(group);
            let img = require(`@image/bigScreen/daily_inspect/legend/inspector.png`);
            let html = `<div style="position: absolute;
                                    top: 0;
                                    left: 50%;
                                    text-align:center;
                                    transform: translateX(-50%);">
                            <div
                             style="background: rgb(3, 23, 38);
                                    opacity: 0.8;
                                    border-radius: 5px;
                                    white-space:nowrap;
                                    padding:4px 10px;color:#fff;">
                              ${item.REALNAME}
                            </div>
                    <img src=${img} style="margin-top:4px;"/>
                </div> `;
            let iconUrl = window.L.divIcon({
                html: html,
                iconSize: [40, 52],
                iconAnchor: [20, 84],
                className: "",
            });

            if (list.length === 1) {
                let lng = list[0].JD;
                let lat = list[0].WD;
                window.L.marker([lat, lng], {
                    icon: iconUrl,
                }).addTo(group);
                this.myMap.flyTo([lat, lng], 15);
                return;
            }
            let ary = [];
            list.map((item) => {
                let obj = {
                    lat: item.WD,
                    lng: item.JD,
                };
                ary.push(obj);
            });
            this.myMap.flyToBounds(ary, {
                maxZoom: 15,
            });

            var seqGroup = window.L.motion
                .seq([
                    window.L.motion.polyline(
                        ary,
                        {
                            color: color,
                        },
                        {
                            speed: 1,
                            duration: 10000,
                            easing: window.L.Motion.Ease.linear,
                        },
                        {
                            removeOnEnd: false,
                            icon: iconUrl,
                        }
                    ),
                ])
                .addTo(group);
            setTimeout(() => {
                seqGroup.motionStart();
                seqGroup.on("click", () => {
                    this.getPatrolPeopleInfo(item);
                    this.dialogViewerData.title = item.REALNAME;
                });
            }, 1000);
        },
        // marker 巡查员 消息推送
        drawPeopleMarkerContinue(item, list) {
            if (this.trailId != item.ID) {
                return;
            }
            let color = "#ff7e00";
            let group = this.trailGroup;
            let name = item.REALNAME;
            // console.log("marker 巡查员", name, index, list);
            this.clearLayersGroup(group);
            let img = require(`@image/bigScreen/daily_inspect/legend/inspector.png`);
            let html = `<div style="position: absolute;
                                    top: 0;
                                    left: 50%;
                                    text-align:center;
                                    transform: translateX(-50%);">
                            <div
                             style="background: rgb(3, 23, 38);
                                    opacity: 0.8;
                                    border-radius: 5px;
                                    white-space:nowrap;
                                    padding:4px 10px;color:#fff;">
                              ${name}
                            </div>
                    <img src=${img} style="margin-top:4px;"/>
                </div> `;
            let iconUrl = window.L.divIcon({
                html: html,
                iconSize: [40, 52],
                iconAnchor: [20, 84],
                className: "",
            });

            let ary = [];
            list.map((item) => {
                let obj = {
                    lat: item.WD,
                    lng: item.JD,
                };
                ary.push(obj);
            });

            var seqGroup = window.L.motion
                .seq([
                    window.L.motion.polyline(
                        ary,
                        {
                            color: color,
                        },
                        {
                            speed: 1,
                            duration: 10000,
                            easing: window.L.Motion.Ease.linear,
                        },
                        {
                            removeOnEnd: false,
                            icon: iconUrl,
                        }
                    ),
                ])
                .addTo(group);
            setTimeout(() => {
                seqGroup.motionStart();
                seqGroup.on("click", () => {
                    this.getPatrolPeopleInfo(item);
                    this.dialogViewerData.title = item.REALNAME;
                });
            }, 1000);
        },
        // 获取巡查人信息
        async getPatrolPeopleInfo(item) {
            let result = await this.GetRcxcRyxx({
                cjrid: item.SJCJFZRID,
                date: this.patrolPeople.time,
            });
            this.closeAllDialog();

            this.dialogViewerData.fzqy = result.FZQY.join(","); // 负责区域
            this.dialogViewerData.wcl = result.WCL; // 完成率
            this.dialogViewerData.cjsj = result.CJSJ; // 采集数据
            this.dialogViewerData.xclc = result.XCLC; // 巡查路程
            this.dialogViewerData.xcsc = result.XCSC; // 巡查时长
            this.dialogViewerData.dw = result.DEPARTMENTNAME; // 单位
            this.dialogViewerData.title = `巡查员 ${item.REALNAME} ${this.dialogViewerData.dw}`;
            this.dialogViewerData.dialog = true;
        },
        // 获取 异常 列表
        async getErrorList() {
            let result = await this.GetRcxcYclb({
                qyid: "",
                pageindex: 1,
                pagesize: 10000,
            });
            this.errorList = result.DATA;
            // this.drawMarkerError(this.ycdGroup, this.errorList);
        },
        // 异常点
        drawMarkerError(group, list) {
            this.clearLayersGroup(group, list);
            let iconSuccess = window.L.icon({
                iconUrl: require("@image/bigScreen/daily_inspect/legend/errorSolvedMap.png"),
                iconSize: [42, 42],
                iconAnchor: [21, 21],
            });
            let iconError = window.L.icon({
                iconUrl: require("@image/bigScreen/daily_inspect/legend/errorNoSolveMap.png"),
                iconSize: [42, 42],
                iconAnchor: [21, 21],
            });
            list.map((item) => {
                let layer = window.L.marker([item.WD, item.JD], {
                    icon: item.YCSFJC == "1" ? iconSuccess : iconError,
                }).addTo(group);

                layer.on("click", () => {
                    this.SET_RCXCYCDWID(item.ID);
                    this.SET_RCXCPANELNUM(2);
                });
                layer.on("mouseover", () => {
                    this.markerPopupError
                        .setLatLng([item.WD, item.JD])
                        .setContent(`<div class="tip">${item.YCSJ}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopupError.removeFrom(this.myMap);
                });
            });
        },
        // 关闭日历
        closeCalendar() {
            this.dialogCjdData.dialog = false;
        },
        // 日历 月份修改
        selectDateChange(time) {
            this.selectMonth = time;
            this.getCjdDetail();
        },

        //底图切换
        baseLayerChange(maptype) {
            this.mapType = maptype;
            switch (maptype) {
                case "vector":
                    this.changeTdlyMap(1); //显示土地利用
                    this.clearLayersGroup(this.gqyxGroup);
                    break;
                case "img":
                    this.changeTdlyMap(0); //隐藏土地利用
                    this.loadGqyx(this.gqyxGroup);
                    break;
            }
        },
        // 地图复位
        reSetMap() {},
    },
};
</script>
<style scoped lang="scss">
.screenCenter {
    position: relative;
    width: 100%;
    height: 100%;
    .mapPart {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
    }
    // 图层控制
    .mapChangePart {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 10;
        .layer {
            position: absolute;
            top: 0;
            left: 0;
            width: 130px;
            height: 40px;
            background: #1a3059;
            border: 1px solid #35507c;
            border-radius: 10px;
            box-shadow: 5px 9px 30px 0px rgba(0, 44, 75, 0.2);
            padding: 0 20px;

            .desc {
                color: #fff;
                font-size: 18px;
            }
        }

        .layerCtn {
            min-width: 130px;
            margin-top: 50px;
            padding: 10px;
            background: #1a3059;
            border-radius: 5px;
        }
    }
    // 图例
    .legendPart {
        position: absolute;
        left: 10px;
        bottom: 10px;
        z-index: 10;
    }

    // 弹出框 采集点
    .popupFramePatrol {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2000;
    }
    // 弹出框 巡查人员
    .patrolPart {
        position: absolute;
        top: 61px;
        right: 10px;
        width: 140px;
        z-index: 10;
        .title {
            width: 100%;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background: #1a3059;
            color: #eee;
            font-weight: 700;
            font-size: 12px;
        }
        .patrolPeopleList {
            width: 100%;
            max-height: 400px;
            padding-bottom: 10px;
            background: rgba(0, 0, 0, 0.5);
            .patrolPeopleItem {
                padding: 5px 4px;
                .personIcon {
                    width: 20px;
                }
                .name {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    color: #fff;
                }
            }
        }
    }
    // 弹出框 巡查员
    .popupFrameViewer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2000;
        background-color: rgba(3, 23, 38, 0.8);
        padding: 0 20px 20px 20px;
        // width: 525px;
        .popupTitle {
            height: 40px;
            .title {
                float: left;
                color: #eee;
                font-size: 22px;
            }
            .closeBtn {
                width: 20px;
                height: 20px;
            }
        }
        .popupCtn {
            border: 1px solid #09354d;
            .popupCtnTop {
                height: 94px;
                .popupItem {
                    width: 50%;
                    height: 60px;
                    .desc {
                        font-size: 22px;
                        color: #77b1d2;
                        text-align: center;
                    }
                    .num {
                        font-size: 32px;
                        color: #d7eaf5;
                        text-align: center;
                        // font-family: fashionSimplifiedFont;
                    }
                }
                .popupItem:nth-child(1) {
                    border-right: 1px solid #09354d;
                }
            }

            .popupCtnBottom {
                border-top: 1px solid #09354d;
                height: 99px;
                .bottomItem {
                    border-right: 1px solid #09354d;
                    text-align: center;
                    height: 80px;
                    padding: 0 40px;
                    white-space: nowrap;
                    .desc {
                        font-size: 22px;
                        color: #77b1d2;
                        text-align: center;
                    }

                    .num {
                        font-size: 32px;
                        color: #d7eaf5;
                        text-align: center;
                        // font-family: fashionSimplifiedFont;
                    }
                    .numDw {
                        font-size: 22px;
                        color: #77b1d2;
                        position: relative;
                        left: 10px;
                    }
                }
                .bottomItem:last-child {
                    border-right: none;
                }
            }
        }
    }
    // 异常 图片 视频
    .imgVideoPart {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2000;
        background-color: rgba(3, 23, 38, 0.8);
        padding: 0 20px 20px 20px;
        width: 100%;
        height: 100%;
        .popupTitle {
            height: 40px;
            .title {
                float: left;
                color: #eee;
                font-size: 22px;
            }
            .closeBtn {
                width: 20px;
                height: 20px;
            }
        }
        .ctnPart {
            height: 340px;
            border: 1px solid #09354d;
        }
    }
    //右下角地图切换
    .mapTypePart {
        position: absolute;
        right: 10px;
        bottom: 10px;
        z-index: 999;
    }
    .commonDialogPart {
        position: absolute;
        top: -40px;
        right: 5px;
        z-index: 999;
        background: #040e1e;
        padding: 10px;
        .popupTitle {
            width: 100%;
            height: 36px;
            line-height: 35px;
            display: flex;
            position: relative;
            padding: 0 15px;
            // 标题内容
            .titleCenter {
                width: fit-content;
                height: 100%;
                margin: 0 auto;
                font-size: 18px;
                color: rgb(210, 234, 246);
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                .titleContent {
                    padding: 0 30px;
                    min-width: 200px;
                    display: inline-block;
                    border-top: 1px solid #84acc2;
                    background: linear-gradient(
                        rgba(48, 80, 97, 1),
                        rgba(48, 80, 97, 0)
                    );
                }
            }
            // 标题按钮
            .titleIcon {
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
                color: #ffffff;
                font-size: 24px;
            }
        }
        .popupCtn {
            width: 100%;
            padding: 20px 14px 10px 14px;
            height: calc(100% - 30px);
        }
    }
}
</style>
