<template>
    <div class="leftPart scrollbar-hidden">
        <!-- 茶用途 -->
        <basic-module :text="common.getFyName(fany.cyt)">
            <div slot="basic-module" class="cytCtn">
                <div class="condition flex-end">
                    <SelectTimeTab
                        :selectData="selectData"
                        @getSelectItem="getSelectItem"
                    >
                    </SelectTimeTab>
                </div>
                <div 
                    v-for="(item, index) in teaUseList"
                    :key="index"
                    class="cytShowBox"
                >
                    <div v-if="index%2 == 1" class="text-part scrollbar-hidden">
                        <div class="title">{{item.MC}}</div>
                        <div class="text ">{{item.MS}}</div>
                    </div>
                    <div class="pic-part">
                        <div class="photo-box">
                            <el-carousel
                                indicator-position="none"
                                height="120px"
                                :autoplay="true"
                                :arrow="
                                    item.PIC.length > 1 ? 'hover' : 'never'
                                "
                                @change="getImgChange(item, index)"
                            >
                                <el-carousel-item
                                    v-for="(item1, index) in item.PIC"
                                    :key="index"
                                    class="pointer"
                                >
                                    <el-image
                                        :src="item1.CCLJ"
                                        fit="cover"
                                        class="pointer"
                                        style="width: 100%; height: 100%"
                                        @click="getImgShow(item, index)"
                                    >
                                        <div
                                            slot="error"
                                            style="width: 100%; height: 100%"
                                        >
                                            <SnNoData width="100px"></SnNoData>
                                        </div>
                                    </el-image>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                        <div class="information-box">
                            <div style="padding-left: 5px;">{{item.CJSJ}}</div>
                            <div style="padding-right: 5px;">{{`${item.photoNum}/${item.PIC.length}`}}</div>
                        </div>
                    </div>
                    <div v-if="index%2 == 0 || index == 0" class="text-part scrollbar-hidden">
                        <div class="title">{{item.MC}}</div>
                        <div class="text ">{{item.MS}}</div>
                    </div>
                </div>
                <ViewerAlert ref="myImgList" :imgList="imgList"></ViewerAlert>
                <sn-no-data
                    v-if="teaUseList.length == 0"
                    :noDataState="true"
                    :width="300"
                ></sn-no-data>
            </div>
        </basic-module>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import SelectTimeTab from "@views/big_screen/comp/SelectTimeTab";
import ViewerAlert from "@comp/ViewerAlert.vue"; //大图查看
export default {
    name: "",
    components: {
        SelectTimeTab,
        ViewerAlert,
    },
    data() {
        return {
            selectData: {
                clearable: true,
                state: true,
                width: "120px",
                placeholder: "请选择年份",
                defaultValue: "",
                list: [],
                label: "time",
                value: "time",
            },
            teaUseList: [],
            imgList: [],
            year: '',
        };
    },
    props: {},
    computed: {
        ...mapGetters({
            
        }),
    },
    mounted() {
        this.getYearList();
        this.getTeaUseList();
    },
    methods: {
        ...mapActions([
            "GetTeaUse",
            "GetTeaUseYear",
        ]),
        ...mapMutations({
            
        }),
        async getTeaUseList() {
            let result = await this.GetTeaUse({
                nf: this.year,
            });
            if(result.ISSUCCESS) {
                this.teaUseList = result.RESULTVALUE;
                this.teaUseList.map((ele) => {
                    ele.CJSJ = ele.PIC[0].CJSJ;
                    ele.photoNum = 1;
                });
            }
        },
        async getYearList() {
            let result = await this.GetTeaUseYear();
            if(result.ISSUCCESS) {
                this.selectData.list = [];
                result.RESULTVALUE.map((item) => {
                    let obj = {
                        time: item,
                    };
                    this.selectData.list.push(obj);
                });
                if (this.selectData.list.length) {
                    this.getTeaUseList();
                }
            }
        },
        // 年份切换
        getSelectItem(item) {
            if(item) {
                this.year = item.time;
            } else {
                this.year = "";
            }
            this.getTeaUseList();
        },
        getImgShow(item, index) {
            let ary = [];
            let list = [];
            list = item.PIC;
            list.map((ele) => {
                let obj = {
                    src: ele.CCLJ,
                    thumbnail: this.common.getThumbnailImg(ele.CCLJ),
                };
                ary.push(obj);
            });
            this.imgList = Array.from(ary);
            if (this.imgList.length) {
                this.$refs.myImgList.showViewer(index);
            }
        },
        getImgChange(item, index) {

        },
    },
};
</script>
<style scoped lang="scss">
.leftPart {
    width: 100%;
    height: 100%;
    padding: 15px 0;
    .cytCtn {
        width: 100%;
        height: 100%;
        .condition {
            position: absolute;
            right: 0;
            top: -38px;
        }
        .cytShowBox {
            width: 100%;
            margin: 10px 0 10px 0;
            height: calc(25% - 10px);
            display: flex;
            .pic-part {
                width: 40%;
                height: 100%;
                background: rgba(26,48,89,0.22);
                .photo-box {
                    width: calc(100% - 40px);
                    margin: 5px 20px 0 20px;
                    .img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .information-box {
                    width: calc(100% - 40px);
                    height: 32px;
                    margin: 5px 20px 20px 20px;
                    background: rgba(53,80,124,0.3);
                    display: flex;
                    justify-content: space-between;
                    color: #BDD7EC;
                    line-height: 32px;
                }
            }
            .text-part {
                width: calc(60% - 10px);
                height: 100%;
                background: url("~@image/bigScreen/tea_culture/tea_use_bg.png");
                margin-left: 10px;
                .title {
                    width: 67px;
                    height: 33px;
                    color: #DAEEFF;
                    margin: 10px 0 0 10px;
                    font-size: 18px;
                }
                .text {
                    width: 100%;
                    padding: 0 0 10px 10px;
                    color: #A8C8FF;
                    font-size: 14px;
                }
            }
        }
    }
}
</style>
