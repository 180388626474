// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@image/bigScreen/monitor_all/sideDialog/line.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".commonDialogPart[data-v-08639705]{height:100%;background:#040e1e}.commonDialogPart .popupTitle[data-v-08639705]{width:100%;height:41px;position:relative}.commonDialogPart .popupTitle .titleCenter[data-v-08639705]{height:100%;font-size:18px;color:#d2eaf6;text-align:center}.commonDialogPart .popupTitle .titleCenter .titleContent[data-v-08639705]{min-width:200px;max-width:456px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%}.commonDialogPart .popupTitle .titleCenter .arrow[data-v-08639705]{position:absolute;bottom:0;right:50%;transform:translateX(-50%)}.commonDialogPart .popupTitle .closeIcon[data-v-08639705]{position:absolute;top:50%;right:40px;transform:translateY(-50%);color:#fff;font-size:24px}.commonDialogPart .popupCtn[data-v-08639705]{width:100%;padding:20px 14px 10px 14px;height:calc(100% - 30px)}", ""]);
// Exports
module.exports = exports;
