<template>
    <div class="flex-between timeLinePart scrollbarX">
        <div
            v-for="(item, index) in listAry"
            :key="index"
            @click="changeCircle(item, index)"
            class="timeLineItem pointer flex-1"
        >
            <div
                class="timeCircle"
                :class="[
                    activeNum == index
                        ? 'timeCircleActive'
                        : 'timeCircleDefault',
                ]"
            ></div>
            <div
                class="time"
                :class="[activeNum == index ? 'timeActive' : 'timeDefault']"
            >
                {{ item[timeKey] }}
            </div>
            <div class="lineLeft lineLeftDefault"></div>
            <!-- :class="[activeNum>=index?'lineLeftActive':'lineLeftDefault']" -->
        </div>
    </div>
</template>
<script>
export default {
    name: "",
    data() {
        return {
            activeNum: 0,
        };
    },
    components: {},
    computed: {},
    props: {
        listAry: {
            type: Array,
            default: () => {
                return [];
            },
        },
        timeKey: {
            type: String,
            default: () => {
                let str = "CJSJ";
                return str;
            },
        },
    },
    mounted() {},
    methods: {
        // 点击圆圈
        changeCircle(item, index) {
            this.activeNum = index;
            this.$emit("handleTimeLine", item, index);
        },
        // 重置时间轴 给父级页面 调用
        resetTimeLine(num) {
            this.activeNum = num;
        },
    },
};
</script>
<style scoped lang="scss">
$activeColor: rgba(255, 255, 255, 1);
$defaultColor: rgba(170, 198, 252, 0.5);
$defaultCircleColor: rgba(170, 198, 252, 1);
.timeLinePart {
    width: 100%;
    height: 55px;
    .timeLineItem {
        position: relative;
        min-width: 100px;
        .time {
            position: absolute;
            top: 25px;
            right: 0;
            width: 100%;
            text-align: center;
            font-size: 12px;
        }
        .timeActive {
            color: $activeColor;
        }
        .timeDefault {
            color: $defaultColor;
        }
        .timeCircle {
            position: absolute;
            top: 5px;
            right: 50%;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            z-index: 2;
        }
        .timeCircleActive {
            background: $activeColor;
        }
        .timeCircleDefault {
            background: $defaultCircleColor;
        }
        .lineLeft {
            position: absolute;
            left: 0;
            top: 14px;
            z-index: 1;
            width: 100%;
            height: 2px;
        }
        .lineLeftActive {
            background: $activeColor;
        }
        .lineLeftDefault {
            background: $defaultColor;
        }
    }
}
@media screen and (max-width: 1500px) {
}
</style>
