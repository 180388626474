<template>
    <div class="rightPart scrollbar-hidden">
        <div class="ztbcztPart">
            <saveState
                :stateName="stateName"
                :stateType="stateType"
            ></saveState>
        </div>
        <div class="jcgkPart">
            <!-- 监测概况 -->
            <basic-module :text="common.getFyName(fany.jcgk)">
                <div slot="basic-module" class="jcgkCtn flex">
                    <div
                        v-for="(item, index) in jcgkList"
                        :key="index"
                        class="item animated bounceInRight"
                        :style="{ animationDelay: 0.2 + index * 0.2 + 's' }"
                    >
                        <img
                            :src="
                                require(`@image/bigScreen/ontological_disease/${item.img}.png`)
                            "
                        />
                        <div class="content">
                            <p style="height: 25px; line-height: 25px">
                                监测{{ item.name }}共
                            </p>
                            <p v-if="Number(item.num) < 10000">
                                <i class="num">{{ item.num }}</i
                                ><span style="margin-left: 5px">{{
                                    item.unit
                                }}</span>
                            </p>
                            <p v-else>
                                <i class="num">{{
                                    Number((item.num / 10000).toFixed(2))
                                }}</i
                                >万<span style="margin-left: 5px">{{
                                    item.unit
                                }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </basic-module>
        </div>
        <div class="bhlxAndYsbczkPart">
            <div class="bhlxPart">
                <!-- 病害类型 -->
                <basic-module :text="common.getFyName(fany.bhlx)">
                    <div slot="basic-module" class="bhlxCtn">
                        <div class="jcbhChart" :id="bhlxPart.id"></div>
                        <div class="noData">
                            <SnNoData
                                :noDataState="true"
                                noDataText="暂无数据"
                                v-if="bhlxPart.sum == 0"
                            ></SnNoData>
                        </div>
                    </div>
                </basic-module>
            </div>
            <div class="ysbcztPart">
                <!-- 要素保存状况 -->
                <basic-module :text="common.getFyName(fany.ysbczt)">
                    <div slot="basic-module" class="ysbcztCtn">
                        <div
                            v-for="(item, index) in ysbcztList"
                            :key="index"
                            class="ysbcztBox"
                            :class="'ysbcztBox' + index"
                        >
                            <bczkBox
                                :chartData="item"
                                :allNum="allNum"
                            ></bczkBox>
                        </div>
                    </div>
                </basic-module>
            </div>
        </div>

        <div class="bhkzPart">
            <!-- 病害控制 -->
            <basic-module :text="common.getFyName(fany.bhkz)">
                <div slot="basic-module" class="bhkzCtn">
                    <bhkzBox
                        :chartData="chartData"
                        :lengendData="lengendData"
                    ></bhkzBox>
                </div>
            </basic-module>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import saveState from "../comp/saveState";
import bhkzBox from "../comp/bhkzBox.vue";
import bczkBox from "../comp/bczkBox.vue";
export default {
    name: "",
    components: {
        saveState,
        bhkzBox,
        bczkBox,
    },
    props: {},
    data() {
        return {
            stateName: "总体保存状况",
            stateType: "",
            // 监测概况
            jcgkList: [
                {
                    name: "遗产要素",
                    num: "",
                    unit: "处",
                    img: "ycys",
                },
                {
                    name: "病害",
                    num: "",
                    unit: "个",
                    img: "bh",
                },
                {
                    name: "病害数据",
                    num: "",
                    unit: "条",
                    img: "bhsl",
                },
            ],
            // 病害类型
            bhlxPart: {
                echart: null,
                id: "bhlx" + Math.random(),
                sum: 0,
            },
            // 要素保存状况
            ysbcztList: [],
            allNum: 0,
            // 病害控制
            chartData: [],
            lengendData: [],
        };
    },
    computed: {
        ...mapGetters({
            jczlFyNum: TYPES.jczlFyNum,
        }),
    },
    watch: {
        jczlFyNum: {
            immediate: true,
            handler() {
                this.stateName = this.common.getFyName(this.fany.ztbczt);
            },
        },
    },
    mounted() {
        this.initPage();
    },
    methods: {
        ...mapActions([
            "getBtbhJCGKex",
            "getBtbhCountDetail",
            "getYcysbcztStatistic",
            "getBhkzztpg",
        ]),
        ...mapMutations({}),
        initPage() {
            this.getBcztAndJcgkData();
            this.getYsbczkData();
            this.getBhkzData();
            this.getJcbhChart();
        },
        async getBcztAndJcgkData() {
            let res = await this.getBtbhJCGKex();
            this.stateType = res.PGDXINFO.ZTMC || "暂未评估";
            let arr = [res.YCYSJCSL, res.YCYSJCBHSL, res.YCYSJCBHJCSL];
            this.jcgkList.forEach((item, index) => {
                item.num = arr[index] || 0;
            });
        },
        // 获取病害类型数据
        async getJcbhChart() {
            let arr = window.YCYS_COLOR_LIST;
            let colorArr = arr.map((item) => item.color);
            let res = await this.getBtbhCountDetail();
            let xAxis = res.BHLXMC;
            let max = 0;
            xAxis.forEach((item, index) => {
                let numArr = [];
                res.DETAIL.forEach((ele) => {
                    this.bhlxPart.sum += Number(ele.DATA[index]);
                    numArr.push(ele.DATA[index]);
                });
                max = Math.max(max, eval(numArr.join("+")));
            });
            let num = 7;
            let dataZoomShow = xAxis.length > num;
            let end = (num / xAxis.length) * 100;
            let option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                },
                grid: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: dataZoomShow ? 15 : 0,
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    data: xAxis,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        color: "#9AB4E3",
                        formatter: function (value) {
                            var ret = "";
                            var maxLength = 2;
                            var rowNum = Math.ceil(value.length / maxLength);
                            if (rowNum > 1) {
                                for (var i = 0; i < rowNum; i++) {
                                    var temp = "";
                                    var start = i * maxLength;
                                    var end = start + maxLength;
                                    temp = value.substring(start, end) + "\n";
                                    ret += temp;
                                }
                                return ret;
                            } else {
                                return value;
                            }
                        },
                    },
                },
                yAxis: {
                    type: "value",
                    max: max,
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                },
                dataZoom: [
                    {
                        show: dataZoomShow,
                        start: 0,
                        end: end,
                        height: 10,
                        bottom: 0,
                        textStyle: {
                            color: "#9AB4E3",
                        },
                    },
                ],
                series: this.getSeries(res.DETAIL, colorArr),
            };

            this.bhlxPart.echart = this.$echarts.dispose(
                document.getElementById(this.bhlxPart.id)
            );
            this.bhlxPart.echart = this.$echarts.init(
                document.getElementById(this.bhlxPart.id)
            );
            if (this.bhlxPart.sum) {
                this.bhlxPart.echart.setOption(option);
            }
        },
        getSeries(data, colorArr) {
            console.log(
                "%c 🍉 data: ",
                "font-size:20px;background-color: #FFDD4D;color:#fff;",
                data
            );
            let series = [];
            data.forEach((item, index) => {
                series.push({
                    name: item.NAME,
                    type: "bar",
                    stack: "jcbh",
                    barWidth: 30,
                    itemStyle: {
                        normal: {
                            color: colorArr[index],
                        },
                    },
                    showBackground: true,
                    backgroundStyle: {
                        color: "rgba(19, 38, 63, 1)",
                    },
                    data: item.DATA,
                });
            });
            return series;
        },
        async getYsbczkData() {
            let res = await this.getYcysbcztStatistic();
            let allNum = eval(res.map((item) => item.COUNT).join("+"));
            res.forEach((item) => {
                (item.chartID = "chart" + Math.random()),
                    (item.ratio = ((item.COUNT / allNum) * 100).toFixed(2));
                item.name = item.PGMC + item.ratio + "%";
                item.value = item.COUNT;
            });
            this.ysbcztList = res;
            this.allNum = allNum;
        },
        async getBhkzData() {
            let res = await this.getBhkzztpg();
            this.lengendData = res.BHKZZTPG;
            this.chartData = res.KZZTPGINFO.map((item) => {
                return {
                    name: item.BHKZZTPGMC,
                    value: item.COUNT,
                };
            });
        },
    },
};
</script>
<style lang="scss">
.bhlxAndYsbczkPart {
    .mudule {
        padding: 0;
    }
}
</style>
<style scoped lang="scss">
.rightPart {
    width: 100%;
    height: 100%;
    padding: 15px 0;
    // 总体保存状态
    .ztbcztPart {
        height: 92px;
        padding: 0 24px;
    }
    // 监测概况
    .jcgkPart {
        width: 100%;
        height: 143px;
        margin-top: 7px;
        .jcgkCtn {
            .item {
                width: calc(100% / 3);
                height: 73px;
                position: relative;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: rgba(0, 9, 60, 0.3);
                }
                .content {
                    height: 100%;
                    margin-left: 73px;
                    padding: 5px 10px;
                    font-size: 12px;
                    p {
                        color: #a8c8ff;
                    }
                    p:nth-child(1) {
                        height: 14px;
                        line-height: 14px;
                    }
                    p:nth-child(2) {
                        height: 38px;
                        line-height: 38px;
                    }
                    i {
                        font-size: 18px;
                        // font-weight: 700;
                        color: #ffcd80;
                    }
                    .num {
                        font-family: DBPM;
                        font-size: 45px;
                    }
                }
            }
        }
    }
    .bhlxAndYsbczkPart {
        margin-top: 7px;
        display: flex;
        height: 290px;
        justify-content: space-between;
        padding: 0 24px;
        // 病害类型
        .bhlxPart {
            width: calc(50% - 10px);
            height: 100%;
            .bhlxCtn {
                width: 100%;
                height: 100%;
                .jcbhChart {
                    height: 100%;
                }
                .noData {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        // 要素保存状况
        .ysbcztPart {
            width: calc(50% - 10px);
            height: 100%;
            .ysbcztCtn {
                width: 100%;
                height: 100%;
                position: relative;
                .ysbcztBox {
                    width: 40%;
                    height: 50%;
                    position: absolute;
                    &.ysbcztBox0 {
                        top: 0;
                        left: 0;
                    }
                    &.ysbcztBox1 {
                        top: 0;
                        left: 41%;
                    }
                    &.ysbcztBox2 {
                        top: 50%;
                        left: 20%;
                    }
                    &.ysbcztBox3 {
                        top: 50%;
                        left: 61%;
                    }
                }
            }
        }
    }
    // 病害控制
    .bhkzPart {
        margin-top: 7px;
        width: 100%;
        height: 330px;
        .bhkzCtn {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
