<template>
    <div class="screenCtn flex-between rcxcScreen">
        <!-- 左侧面板 -->
        <div class="leftView">
            <PageLeft></PageLeft>
        </div>
        <!-- 中间地图面板 -->
        <div class="centerView ">
            <PageCenter :positionStr="positionStr"
                        @getCommonDialog="getCommonDialog"></PageCenter>
        </div>
        <!-- 弹框部分  -->
        <div class="sideDialog"
             :style="{
                width: getWidth(),
            }">
            <page-side ref="commonDialog"
                       @closeComDialog="closeComDialog"></page-side>
        </div>
        <!-- 右侧面板 -->
        <div class="rightView">
            <PageRight v-if="rcxcPanelNum === 0 || rcxcPanelNum === 1"></PageRight>
            <PageRightBh v-if="rcxcPanelNum === 2"></PageRightBh>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapMutations, mapGetters } from "vuex";
import PageLeft from "./1_page_left/index"; //左测 部分
import PageCenter from "./2_page_center/index"; //中间 地图部分
import PageRight from "./3_page_right/page_right"; //右侧 总体
import PageRightBh from "./3_page_right/page_right_bh"; //右侧 病害
import PageSide from "./4_page_side/index"; //右侧 总体

export default {
    name: "",
    components: {
        PageLeft,
        PageCenter,
        PageRight,
        PageRightBh,
        PageSide
    },
    data () {
        return {
            listAry: [],
            commonDialog: {
                dialog: false,
                width: "",
            },
        };
    },
    computed: {
        ...mapGetters({
            rcxcPanelNum: TYPES.rcxcPanelNum,
        }),
    },
    watch: {},
    created () {
        this.initStore();
    },
    mounted () { },
    props: {
        positionStr: {
            type: String,
            default: "",
        },
    },
    methods: {
        ...mapMutations({
            SET_RCXCPANELNUM: TYPES.SET_RCXCPANELNUM,
            SET_RCXCAREALIST: TYPES.SET_RCXCAREALIST,
            SET_RCXCYCDWID: TYPES.SET_RCXCYCDWID,
            SET_RCXCAREAITEM: TYPES.SET_RCXCAREAITEM,
            SET_RCXCYCLBITEM: TYPES.SET_RCXCYCLBITEM,
            SET_RCXCRXTJNUM: TYPES.SET_RCXCRXTJNUM,
        }),
        initStore () {
            this.SET_RCXCPANELNUM(0);
            this.SET_RCXCAREALIST([]);
            this.SET_RCXCYCDWID("");
            this.SET_RCXCAREAITEM(null);
            this.SET_RCXCYCLBITEM(null);
            this.SET_RCXCRXTJNUM(null);
        },
        // 关闭
        closeComDialog () {
            this.commonDialog.dialog = false;
            this.$refs.pageCenter.closeComDialog();
        },
        getCommonDialog (obj) {
            let width = "";
            switch (obj.dialog) {
                case "sxtDialog": //摄像头
                    width = "1371px";
                    break;
            }
            this.commonDialog.width = width;
            this.commonDialog.dialog = true;
            this.$nextTick(() => {
                this.$refs.commonDialog.getDetail(obj);
            });
        },
        getWidth () {
            let width = "0px";
            if (this.commonDialog.dialog) {
                if (this.commonDialog.width) {
                    width = this.commonDialog.width;
                } else {
                    width = "1371px";
                }
            }
            return width;
        },
    },
};
</script>
<style scoped lang="scss">
/* 中间主体内容 */
.screenCtn {
    position: relative;
    height: 100%;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 0px;
    }
    &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 0;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }
    /* 左边部分 */
    .leftView {
        position: relative;
        width: 725px;
        height: 100%;
        // border:1px solid #fff;
    }

    /* 中间部分 */
    .centerView {
        width: 1047px;
        margin: 0 6px;
        height: 100%;
    }

    .sideDialog {
        height: 100%;
        transition: all 0.6s ease-in-out;
        overflow: hidden;
    }

    /* 右边部分 */
    .rightView {
        position: relative;
        width: 711px;
        height: 100%;
        // border: 1px solid #fff;
    }
}
</style>
