<template>
    <div class="rightPart scrollbar-hidden">
        <whjQxjc v-if="currentId === '0'"></whjQxjc>
        <bchCqcb v-if="currentId === '1'"></bchCqcb>
        <gclBchdc v-if="currentId === '2'"></gclBchdc>
        <slbchYgjc v-if="currentId === '3'"></slbchYgjc>
        <whjKqzljc v-if="currentId === '4'"></whjKqzljc>
        <whjTrjc v-if="currentId === '5'"></whjTrjc>
        <whjSwsz v-if="currentId === '6'"></whjSwsz>
        <gsmmJc v-if="currentId === '7'"></gsmmJc>
        <zwDyx v-if="currentId === '8'"></zwDyx>
        <whjc v-if="currentId === '9'"></whjc>
        <dwDyx v-if="currentId === '10'"></dwDyx>
        <teaQuadrat v-if="currentId === '11'"></teaQuadrat>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import whjQxjc from "./1_whj_qxjc.vue"; // 微环境 气象监测
import bchCqcb from "./2_bch_cqcb.vue"; // 病虫害 虫情测报
import gclBchdc from "./3_gcl_bchdc.vue"; // 古茶林 病虫害调查
import slbchYgjc from "./4_slbch_ygjc.vue"; // 森林病虫害 遥感监测
import whjKqzljc from "./5_whj_kqzljc.vue"; // 微环境 空气质量监测
import whjTrjc from "./6_whj_trjc.vue"; // 微环境 土壤监测
import whjSwsz from "./7_whj_swsz.vue"; // 微环境 水文水质监测
import gsmmJc from "./8_gsmm_jc.vue"; // 古树名木 监测
import zwDyx from "./9_zw_dyx.vue"; // 植物 多样性监测
import whjc from "./10_whjc.vue"; // 物候监测
import dwDyx from "./11_dw_dyx.vue"; // 动物 多样性监测
// ①微环境 气象监测
// ②物候监测
// ③动物 多样性监测
// ④植物 多样性监测
// ⑤病虫害 虫情测报
// ⑥森林病虫害 遥感监测
// ⑦古茶林 病虫害调查
// ⑧微环境 土壤监测
// ⑨微环境 水文水质 监测
// ⑩微环境 空气质量 监测
// ⑪古树名木 监测
export default {
    components: {
        whjQxjc,
        bchCqcb,
        gclBchdc,
        slbchYgjc,
        whjKqzljc,
        whjTrjc,
        whjSwsz,
        gsmmJc,
        zwDyx,
        whjc,
        dwDyx,
    },
    name: "",
    data() {
        return {
            currentId: "4",
        };
    },
    computed: {
        ...mapGetters({
            sthjJcnrSelect: TYPES.sthjJcnrSelect,
        }),
    },
    watch: {
        sthjJcnrSelect: {
            immediate: false,
            deep: true,
            handler() {
                this.currentId = this.sthjJcnrSelect.id;
            },
        },
    },
    mounted() {},
    methods: {
        ...mapActions([]),
        ...mapMutations({}),
        handleSthjJcnrSelect(val) {
            this.currentId = val.id;
        },
    },
};
</script>
<style scoped lang="scss">
.rightPart {
    width: 100%;
    height: 100%;
    // 巡查状况
    .xczkPart {
        width: 100%;
        height: 142px;
        .xczkCtn {
            width: 100%;
            height: 100%;
        }
    }
    // 巡查统计
    .xctjPart {
        margin-top: 25px;
        width: 100%;
        height: 254px;
        .xczkCtn {
            width: 100%;
            height: 100%;
        }
    }
    // 异常类型
    .ycxlPart {
        margin-top: 25px;
        width: 100%;
        height: 246px;
        .xczkCtn {
            width: 100%;
            height: 100%;
        }
    }
    // 异常列表
    .yclbPart {
        margin-top: 25px;
        width: 100%;
        height: 206px;
        .xczkCtn {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
