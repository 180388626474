<template>
    <div class="screenCtn flex-between">
        <!-- 左侧面板 -->
        <div class="leftView">
            <PageLeft></PageLeft>
        </div>
        <!-- 中间地图面板 -->
        <div class="centerView flex-1">
            <PageCenter :positionStr="positionStr"></PageCenter>
        </div>
        <!-- 右侧面板 -->
        <div class="rightView">
            <page-right v-if="btbhPanelNum === 0"></page-right>
            <page-right-ycys v-if="btbhPanelNum === 1"></page-right-ycys>
            <page-right-btbh v-if="btbhPanelNum === 2"></page-right-btbh>
        </div>
    </div>
</template>
<script>
import PageLeft from "./1_page_left/index"; //左测 部分
import PageCenter from "./2_page_center/index"; //中间 地图部分
import PageRight from "./3_page_right/page_right"; //右侧 总体
import PageRightBtbh from "./3_page_right/page_right_btbh"; //右侧 本体病害
import PageRightYcys from "./3_page_right/page_right_ycys"; //右侧 遗产要素
import * as TYPES from "@store/modules/mutation-type.js";
import { mapMutations, mapGetters } from "vuex";

export default {
    name: "",
    components: {
        PageLeft,
        PageCenter,
        PageRight,
        PageRightBtbh,
        PageRightYcys,
    },
    data() {
        return {
            listAry: [],
        };
    },
    computed: {
        ...mapGetters(["btbhPanelNum"]),
    },
    props: {
        positionStr: {
            type: String,
            default: "",
        },
    },
    watch: {},
    mounted() {},
    methods: {
        ...mapMutations({}),
    },
};
</script>
<style scoped lang="scss">
/* 中间主体内容 */
.screenCtn {
    position: relative;
    height: 100%;
    /* 左边部分 */
    .leftView {
        position: relative;
        width: 725px;
        height: 100%;
        // border:1px solid #fff;
    }

    /* 中间部分 */
    .centerView {
        margin: 0 6px;
        height: 100%;
    }

    /* 右边部分 */
    .rightView {
        position: relative;
        width: 711px;
        height: 100%;
    }
}
</style>
