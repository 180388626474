<template>
    <div class="baseMap" :id="'mapidbox' + mapid"></div>
</template>

<script>
export default {
    name: "picMap",
    components: {},
    props: {
        imgUrl: {
            type: String,
            default: () => {
                return "";
            },
        },
    },
    data() {
        return {
            mapid: Math.random(),
            myMap: null,
        };
    },
    computed: {},
    watch: {
        imgUrl: function() {
            this.initLayer();
            if (this.imgLayer) {
                this.myMap.removeLayer(this.imgLayer);
            }
        },
    },
    mounted() {
        this.initMap();
    },
    destroyed() {},
    methods: {
        initMap() {
            let id = `mapidbox${this.mapid}`;
            this.myMap = window.L.map(id, {
                crs: window.L.CRS.EPSG4326,
                zoomSnap: 0.1,
                editable: true,
                zoomControl: false,
                zoomDelta: 1,
                minZoom: 1,
                maxZoom: 17,
                center: [0, 0],
                attributionControl: false,
            });
            this.initLayer();
            // this.$emit("update:myMap", this.myMap);
            this.$emit('changeMap', this.myMap);
        },
        initLayer() {
            let imageUrl = this.imgUrl;
            let img = new Image();
            img.src = this.imgUrl;
            // eslint-disable-next-line
            let w = $(".leaflet-container").width();
            // eslint-disable-next-line
            let h = $(".leaflet-container").height();
            img.onload = () => {
                let imageBounds = [
                    [0, 0],
                    [1, w / h],
                ];
                this.imgLayer = window.L.imageOverlay(imageUrl, imageBounds)
                this.myMap.addLayer(this.imgLayer)
                this.myMap.fitBounds([imageBounds]);
                this.myMap.setMaxBounds(imageBounds);
            };
        },
    },
};
</script>
<style lang="scss" scoped>
.baseMap {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}
</style>
