// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@image/bigScreen/icon/titleBg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".basicStatistics[data-v-40a2dbbe]{width:100%;height:100%;position:relative;flex-direction:row;flex-wrap:wrap}.basicStatistics .centerItem[data-v-40a2dbbe]{width:162px;margin:7px}.basicStatistics .centerItem .name[data-v-40a2dbbe]{width:100%;height:40px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;font-size:18px;font-weight:500;color:#a8c8ff;display:flex;align-items:center;justify-content:space-around}.basicStatistics .centerItem .count[data-v-40a2dbbe]{margin-top:5px;height:65px;height:40px;border:1px solid rgba(53,80,124,.4);color:#bdd7ec;position:relative}.basicStatistics .centerItem .count .num[data-v-40a2dbbe]{font-size:48px;font-family:DBPM}.basicStatistics .centerItem .count .zwsj[data-v-40a2dbbe]{font-size:20px}.basicStatistics .centerItem .count .dw[data-v-40a2dbbe]{margin-left:5px;font-size:20px;color:#a8c8ff}.basicStatistics .centerItem .count .arrow-img[data-v-40a2dbbe]{margin-left:5px}", ""]);
// Exports
module.exports = exports;
