<template>
    <div class="dialogCtn">
        <div class="topPart flex-between-y-center">
            <div class="imgPart">
                <div class="circleBg0"></div>
                <div class="circleBg1 flex-both-center">
                    <img
                        src="@image/bigScreen/monitor_all/yj/ciecleBg1.png"
                        class="img1"
                    />
                </div>
                <div class="circleBg2 flex-both-center">
                    <img
                        src="@image/bigScreen/monitor_all/yj/ciecleBg2.png"
                        class="img2"
                    />
                </div>
                <img :src="yjList[detail.YJZT].src" alt="" />
                <div
                    class="yjzt flex-both-center"
                    :style="{ color: yjList[detail.YJZT].color }"
                >
                    {{ detail.YJZTNAME }}
                </div>
            </div>
            <div class="timePart flex-1">
                <div class="label">开始时间</div>
                <div class="detail">
                    {{ detail.FSYJSJ }}
                </div>
                <div class="label">结束时间</div>
                <div class="detail">
                    {{ detail.JCSJ }}
                </div>
            </div>
            <div class="totalTimePart">
                <!-- 装饰 -->
                <div class="corner">
                    <img
                        src="@image/bigScreen/monitor_all/yj/left_top.png"
                        class="left_top"
                    />
                    <img
                        src="@image/bigScreen/monitor_all/yj/left_bottom.png"
                        class="left_bottom"
                    />
                    <img
                        src="@image/bigScreen/monitor_all/yj/right_top.png"
                        class="right_top"
                    />
                    <img
                        src="@image/bigScreen/monitor_all/yj/right_bottom.png"
                        class="right_bottom"
                    />
                </div>
                <div class="name">历时</div>
                <div class="detail">
                    <span class="time">{{ totalTime }}</span>
                    <span class="dw">(小时)</span>
                </div>
            </div>
        </div>
        <div></div>
        <div class="label">描述</div>
        <div class="detail ellipsis pointer" :title="detail.YJSM">
            {{ detail.YJSM }}
        </div>
        <div class="label">负责人</div>
        <div class="detail">
            {{ detail.FZRNAME }}
        </div>
        <!-- 预警处置 -->
        <div class="yjczPart">
            <Yjcz :detail="detail"></Yjcz>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import Yjcz from "./yjcz/index.vue"; //预警处置

export default {
    components: {
        Yjcz,
    },
    name: "",
    data() {
        return {
            // '预警状态0：未处置，2：无效预警   1：处置中 3：解除'
            yjList: [
                {
                    src: require("@image/bigScreen/monitor_all/yj/circle1.png"),
                    color: "#ff4c4c",
                    name: "未处置",
                },
                {
                    src: require("@image/bigScreen/monitor_all/yj/circle2.png"),
                    color: "#ffff00",
                    name: "无效预警",
                },
                {
                    src: require("@image/bigScreen/monitor_all/yj/circle3.png"),
                    color: "#ffb508",
                    name: "处置中",
                },
                {
                    src: require("@image/bigScreen/monitor_all/yj/circle4.png"),
                    color: "#00bd8d",
                    name: "解除",
                },
            ],
            totalTime: "-",
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {
        detail: {
            Type: Object,
            default: () => {
                let obj = {
                    YJDJ: "", //预警等级
                    FSYJSJ: "", //开始时间
                    JCSJ: "", //结束时间
                    YJSM: "", //预警说明
                    FZRNAME: "", //预警负责人
                    YJZTNAME: "", //预警状态
                    YJZT: "", //预警状态
                };
                return obj;
            },
        },
    },
    watch: {
        "detail.JCSJ": {
            immediate: true,
            deep: true,
            handler() {
                this.totalTime = this.getTimeDistance(
                    this.detail.FSYJSJ,
                    this.detail.JCSJ
                );
            },
        },
    },
    mounted() {},
    methods: {
        ...mapActions([]),
        ...mapMutations({}),
        getTimeDistance(startTme, endTime) {
            let stime = new Date(startTme).getTime();
            let etime = null;
            if (endTime) {
                etime = new Date(endTime).getTime();
            } else {
                etime = new Date().getTime();
            }

            let usedTime = etime - stime; //两个时间戳相差的毫秒数
            let time = (usedTime / (3600 * 1000)).toFixed(2);
            return time;
        },
    },
};
</script>
<style scoped lang="scss">
.dialogCtn {
    width: 100%;
    height: 100%;
    .topPart {
        .imgPart {
            width: 180px;
            height: 180px;
            border-radius: 50%;
            background: url("~@image/bigScreen/monitor_all/yj/bgCircle.png")
                no-repeat;
            position: relative;
            .circleBg0 {
                width: 160px;
                height: 160px;
                border-radius: 50%;
                border: 2px dashed #3f506e;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .circleBg1 {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                .img1 {
                    width: 140px;
                    height: 140px;
                    animation: rotateAnimate 6s linear infinite;
                }
            }
            .circleBg2 {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                .img2 {
                    width: 130px;
                    height: 130px;
                    animation: rotateAnimate1 6s linear infinite;
                }
            }
            .yjzt {
                width: 80px;
                height: 40px;
                background: rgba(53, 80, 124, 0.8);
                position: absolute;
                right: 0;
                bottom: 44px;
            }
        }
        .timePart {
            margin: 0 10px;
        }
        .totalTimePart {
            min-width: 170px;
            height: 90px;
            background: rgba(53, 80, 124, 1);
            position: relative;
            top: 16px;
            padding: 14px 20px;
            .corner {
                width: calc(100% - 20px);
                height: calc(100% - 28px);
                position: relative;
                position: absolute;
                left: 10px;
                top: 14px;
                .left_top {
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                .left_bottom {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
                .right_top {
                    position: absolute;
                    right: 0;
                    top: 0;
                }
                .right_bottom {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
            .name {
                color: rgba(168, 200, 255, 1);
                font-size: 14px;
            }
            .detail {
                text-align: center;
                position: relative;
                top: -10px;
                white-space: nowrap !important;
                .time {
                    color: rgba(255, 205, 128, 1);
                    font-size: 26px;
                }
                .dw {
                    color: rgba(168, 200, 255, 1);
                    font-size: 16px;
                }
            }
        }
    }
    .label {
        height: 28px;
        color: rgba(86, 115, 162, 1);
        font-size: 14px;
        margin-top: 10px;
    }
    .detail {
        height: 28px;
        color: rgba(168, 200, 255, 1);
        font-size: 14px;
        background: rgba(53, 80, 124, 0.2);
        padding: 4px 10px;
    }
    .yjczPart {
        width: 100%;
        height: 300px;
        margin-top: 10px;
    }
}

@keyframes rotateAnimate {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes rotateAnimate1 {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(-90deg);
    }
    50% {
        transform: rotate(-180deg);
    }
    75% {
        transform: rotate(-270deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}
</style>
