<template>
    <div class="bcwhcdBox">
        <div class="chartPart">
            <div class="chart" :id="chartData.chartID"></div>
            <div class="num">{{ chartData.num }}</div>
        </div>
        <div class="descPart">{{ chartData.per }}%</div>
    </div>
</template>
<script>
export default {
    name: "bcwhcdBox",
    props: {
        chartData: Object,
        allNum: Number,
    },
    data() {
        return {
            chart: null,
        };
    },
    watch: {
        chartData: {
            deep: true,
            immediate: false,
            handler() {
                this.initChart();
            },
        },
    },
    mounted() {},
    methods: {
        initChart() {
            let option = {
                polar: {
                    radius: ["90%", "100%"],
                    center: ["50%", "50%"],
                },
                angleAxis: {
                    max: this.allNum,
                    show: false,
                },
                radiusAxis: {
                    type: "category",
                    show: true,
                    axisLabel: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                },
                series: [
                    {
                        name: "",
                        type: "bar",
                        roundCap: true,
                        barWidth: 50,
                        showBackground: true,
                        backgroundStyle: {
                            color: "#2b3d59",
                        },
                        data: [this.chartData.num],

                        coordinateSystem: "polar",
                        itemStyle: {
                            normal: {
                                color: "#AAC6FC",
                            },
                        },
                    },
                ],
            };
            this.chart = this.$echarts.dispose(
                document.getElementById(this.chartData.chartID)
            );
            this.chart = this.$echarts.init(
                document.getElementById(this.chartData.chartID)
            );
            this.chart.setOption(option);
        },
    },
};
</script>
<style scoped lang="scss">
$stateLineColor: #35507c;
.bcwhcdBox {
    height: 100%;
    .chartPart {
        width: 100px;
        height: 100px;
        position: relative;
        margin: 0 auto;
        margin-top: 10px;
        &::before,
        &::after {
            content: "";
            display: block;
            width: 15px;
            height: 2px;
            background-color: #2b3d59;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        &::before {
            left: -15px;
        }
        &::after {
            right: -15px;
        }
        .chart {
            width: 100px;
            height: 100px;
            position: absolute;
            top: 0;
            left: 0;
        }
        .num {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 82px;
            height: 82px;
            line-height: 75px;
            text-align: center;
            border-radius: 50%;
            background: linear-gradient(
                180deg,
                rgba(14, 30, 53, 1) 0%,
                rgba(57, 76, 106, 1) 100%
            );
            box-shadow: 0px -1px 3px 0px #43597a inset;
            font-size: 45px;
            font-family: DBPM;
            color: #aac6fc;
        }
    }
    .descPart {
        height: 20px;
        line-height: 20px;
        text-align: center;
        color: #aac6fc;
    }
}
</style>