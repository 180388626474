<template>
    <div class="screenItem">
        <!-- 工程列表 -->
        <basic-module :text="common.getFyName(fany.gclb)">
            <div
                slot="basic-module"
                class="scrollbar-translucent"
                style="height: 100%; overflow-x: hidden"
            >
                <div class="noData">
                    <SnNoData
                        :noDataState="true"
                        noDataText="暂无数据"
                        v-if="bhgcList.length == 0"
                    ></SnNoData>
                </div>
                <div
                    v-for="(item, index) in bhgcList"
                    :key="index"
                    class="projectItem pointer"
                    :class="[bhgcActiveNum == index ? 'projectItemActive' : '']"
                    @click="getBhgcDetail(item, index)"
                >
                    <div class="flex-y-center">
                        <div class="title ellipsis" :title="item.GCFLBM">
                            {{ item.GCFLBM }}
                        </div>
                        <div class="time">
                            <span v-if="item.KGSJ" style="margin-right: 30px">
                                <span class="label">开工时间：</span>
                                <span class="num">
                                    {{ item.KGSJ }}
                                </span>
                            </span>
                            <span v-if="item.JGSJ">
                                <span class="label">竣工时间：</span>
                                <span class="num">
                                    {{ item.JGSJ }}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="content ellipsis">{{ item.GCMC }}</div>
                    <div
                        class="state"
                        :class="[`stat_${item.BHGCJZQK.BHGCJZQK_ENUM}`]"
                    >
                        {{ item.BHGCJZQK.BHGCJZQK_CN }}
                    </div>
                </div>
            </div>
        </basic-module>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import NoMonitor from "@views/big_screen/comp/NoMonitor";
export default {
    name: "",
    components: {
        NoMonitor,
    },
    data() {
        return {
            bhgcList: [],
        };
    },
    computed: {
        ...mapGetters(["bhgcYczcItem", "bhgcYcysItem", "bhgcActiveNum"]),
    },
    watch: {
        bhgcYcysItem: {
            immediate: false,
            handler() {
                let obj = this.bhgcYcysItem
                    ? JSON.parse(this.bhgcYcysItem)
                    : "";
                if (obj && this.bhgcList.length) {
                    let listDefault = [];
                    let listActive = [];
                    this.bhgcList.map((item) => {
                        if (item.SJDDYCGCYSMC.split(",").includes(obj.MC)) {
                            listActive.push(item);
                        } else {
                            listDefault.push(item);
                        }
                    });
                    this.bhgcList = [...listActive, ...listDefault];
                    this.getBhgcDetail(this.bhgcList[0], 0);
                } else {
                    this.getBhgcList();
                }
            },
        },
        bhgcYczcItem: {
            immediate: true,
            handler() {
                this.getBhgcList();
            },
        },
    },
    mounted() {},
    methods: {
        ...mapActions([
            "getBhgcDpList",
            "getBhgcDetail",
            "getBhgcSlStatistics",
            "getJcwzList",
        ]),
        ...mapMutations({
            setStateBhgc: "setStateBhgc",
        }),
        // 获取保护工程列表 必须要 遗产要素编码
        async getBhgcList() {
            let ycysbm = this.bhgcYcysItem
                ? JSON.parse(this.bhgcYcysItem).BM
                : "";
            let yczc = this.bhgcYczcItem
                ? JSON.parse(this.bhgcYczcItem).YCYSZC
                : "";
            const result = await this.getBhgcDpList({
                ycyszc: yczc,
                // ycysbm: ycysbm,
            });
            this.bhgcList = result;
            if (this.bhgcYcysItem) {
                if (this.bhgcList.length) {
                    this.getBhgcDetail(this.bhgcList[0], 0);
                } else {
                    this.setStateBhgc({
                        bhgcItem: null,
                        bhgcActiveNum: null,
                        bhgcPanelNum: 0,
                    });
                }
            } else {
                this.setStateBhgc({
                    bhgcItem: null,
                    bhgcActiveNum: null,
                    bhgcPanelNum: 0,
                });
            }
        },
        // 获取工程详情
        getBhgcDetail(item, index) {
            this.setStateBhgc({
                bhgcItem: item,
                bhgcActiveNum: index,
                bhgcPanelNum: 1,
            });
        },
    },
};
</script>
<style scoped lang="scss">
.screenItem {
    width: 100%;
    height: 100%;
    position: relative;
    .noData {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .projectItem {
        position: relative;
        height: 80px;
        padding: 15px 8px;
        background: rgba(154, 180, 227, 0.1);
        overflow: hidden;
        margin-bottom: 10px;
        &:nth-last-child(1) {
            margin-bottom: 0;
        }
        &:hover {
            background: rgba(126, 190, 235, 0.2);
        }
        .title {
            width: 140px;
            padding: 0 10px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            color: rgba(188, 254, 161, 1);
            border: 1px solid;
            font-size: 14px;
            margin-right: 10px;
            border-image: linear-gradient(0deg, #bcfea1 0%, #65d46b 100%) 1 1;
        }

        .content {
            width: calc(100% - 140px);
            font-size: 16px;
            color: #a8c8ff;
            letter-spacing: 1px;
            margin-top: 10px;
        }

        .time {
            height: 21px;
            color: #bad1d8;
            .num {
                color: #8ca2a9;
            }
        }

        .state {
            position: absolute;
            font-size: 12px;
            font-weight: 400;
            width: 100px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            right: 30px;
            top: 30px;
            color: #000;
            transform-origin: right top;
            transform: rotate(45deg) translate(50px, -15px);
            // 未开工
            &.stat_1 {
                background-color: #81d3f8;
            }
            // 施工中
            &.stat_2 {
                background-color: #f59a23;
            }
            // 竣工待验收
            &.stat_3 {
                background-color: #9e66a8;
            }
            // 已验收
            &.stat_4 {
                background-color: #5ad8a6;
            }
        }
    }
    .projectItemActive {
        background: rgba(154, 180, 227, 0.2) !important;
    }
}
</style>
