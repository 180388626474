<template>
    <div class="screenCenter monitorScreen">
        <audio
            src="@image/bigScreen/monitor_all/warn.mp3"
            loop
            preload="auto"
            ref="myAudio"
            style="position: absolute; left: 0; top: 0; width: 0; height: 0"
        ></audio>
        <div class="decorateBottom flex-between">
            <div
                v-for="i in 4"
                :key="i"
                class="decorateItem animated bounceInLeft"
                :style="{ animationDelay: 0.2 + i * 0.2 + 's' }"
            ></div>
        </div>
        <div class="lineTop flex-between">
            <div class="lineTop1 animated bounceInRight"></div>
            <div
                class="lineTop2 animated bounceInRight"
                :style="{ animationDelay: 0.2 + 0 * 0.2 + 's' }"
            ></div>
            <div
                class="lineTop3 animated bounceInRight flex-1"
                :style="{ animationDelay: 0.2 + 1 * 0.2 + 's' }"
            ></div>
        </div>
        <img src="@image/bigScreen/icon/ruler.png" class="ruler" />
        <!-- 地图 -->
        <div class="mapPart">
            <MyMap
                ref="myMap"
                :myMap.sync="myMap"
                :mapData="mapData"
                @reSetMap="reSetMap"
            />
        </div>
        <div class="timePart common-element-select-jczl">
            <el-select
                v-model="mapTime"
                popper-class="common-element-select-panel"
                :clearable="false"
                @change="changeMapTime"
                style="height:40px;width:93px"
            >
                <el-option label="2022年" value="2022"> </el-option>
                <el-option label="2021年" value="2021"> </el-option>
            </el-select>
        </div>

        <!-- 行政区划 -->
        <div class="xzqhTreePart">
            <XzqhTree
                :myMap="myMap"
                ref="XzqhTree"
                @xzqhChange="reSetState()"
            ></XzqhTree>
        </div>
        <!-- 图层 -->
        <div class="mapChangePart">
            <div
                class="layer pointer flex-between-y-center"
                @click="layerState = !layerState"
            >
                <div class="desc">图层</div>
                <img src="@image/bigScreen/icon/layer.png" />
            </div>
            <div v-show="layerState" class="layerCtn">
                <!-- 遗产要素地图 除去土地利用 -->
                <LayerList
                    :listAry="formatYcysList(0)"
                    keyValue="ycys"
                    @switchStateChange="switchStateChange"
                ></LayerList>
                <!-- 遗产要素 + 古茶树 + 古树名木 -->
                <LayerList
                    :listAry="onOffListCom"
                    keyValue="ycys"
                    @switchStateChange="switchStateChangeCom"
                ></LayerList>
                <!-- 矢量分布图 -->
                <LayerList
                    :listAry="onOffListVector"
                    keyValue="slfbt"
                    @switchStateChange="switchStateVector"
                ></LayerList>
                <!-- 农业分布图 -->
                <LayerList
                    :listAry="onOffListNyfbt"
                    keyValue="njfbt"
                    @switchStateChange="switchStateNyfbt"
                ></LayerList>
                <!-- 消防专题图 -->
                <LayerList
                    :listAry="onOffListXfztt"
                    keyValue="xfztt"
                    @switchStateChange="switchStateXfztt"
                ></LayerList>
                <!-- 遗产要素地图 土地利用 -->
                <LayerList
                    :listAry="formatYcysList(1)"
                    keyValue="ycys"
                    @switchStateChange="switchStateChange"
                ></LayerList>
            </div>
        </div>
        <!-- 图例 -->
        <div class="legendPart flex scrollbar-hidden">
            <div class="title flex-y-center">图例</div>
            <div class="flex flex-1 list flex-wrap">
                <!-- 遗产要素分布图 -->
                <LegendList
                    :listAry="onOffList"
                    keyValue="ycys"
                    @legendChangeSingle="legendChangeMap"
                ></LegendList>
                <!-- 矢量分布图 -->
                <LegendList
                    :listAry="onOffListVector"
                    keyValue="slfbt"
                    @legendChangeMul="legendChangeVectorMul"
                    @legendChangeSingle="legendChangeVector"
                ></LegendList>
                <!-- 自定义图层 -->
                <LegendList
                    :listAry="onOffListCom"
                    keyValue="custom"
                    @legendChangeMul="legendChangeMulCustom"
                    @legendChangeSingle="legendChangeSingleCustom"
                ></LegendList>
                <!-- 农业分布图 -->
                <LegendList
                    :listAry="onOffListNyfbt"
                    keyValue="nyfbt"
                    @legendChangeMul="legendChangeLyyd"
                    @legendChangeSingle="legendChangeNyyd"
                ></LegendList>
                <!-- 消防专题图 -->
                <LegendList
                    :listAry="onOffListXfztt"
                    keyValue="xfztt"
                    @legendChangeMul="legendChangeDzzh"
                ></LegendList>
                <!-- 历史预警 -->
            </div>
        </div>
        <!-- 遗产要素 聚合列表 -->
        <div
            id="ycysCluster"
            class="clusterPart"
            v-show="ycysCluster.list.length"
        >
            <div class="title flex-between">
                <span>
                    {{ ycysCluster.title }}
                </span>
                <i
                    class="closeBtn pointer el-icon-close"
                    @click="closeClusterYcys()"
                ></i>
            </div>
            <div class="clusterCtn scrollbar-translucent">
                <div
                    v-for="(item, index) in ycysCluster.list"
                    :key="index"
                    @click="getYcysDetail(item)"
                    class="ycdtItem pointer flex-between-y-center"
                    :title="item.MC"
                >
                    <div
                        style="width: 170px"
                        class="pointer ellipsis"
                        :title="item.MC"
                    >
                        {{ index + 1 }}.{{ item.MC }}
                    </div>
                    <img
                        v-if="item.ewicon"
                        :src="item.ewicon"
                        class="pointer"
                        style="width: 20px; height: 20px"
                    />
                </div>
            </div>
        </div>
        <!-- 古树名木 聚合列表 -->
        <div
            id="gsmmCluster"
            class="clusterPart"
            v-show="gsmmCluster.list.length"
        >
            <div class="title flex-between">
                <span>
                    {{ gsmmCluster.title }}
                </span>
                <i
                    class="closeBtn pointer el-icon-close"
                    @click="closeClusterGsmm()"
                ></i>
            </div>
            <div class="clusterCtn scrollbar-translucent">
                <div
                    v-for="(item, index) in gsmmCluster.list"
                    :key="index"
                    @click="getGsmmDetail(item)"
                    class="ycdtItem pointer flex-between-y-center"
                    :title="item.SZZWM"
                >
                    <div
                        style="width: 170px"
                        class="pointer ellipsis"
                        :title="item.SZZWM"
                    >
                        {{ index + 1 }}.{{ item.SZZWM }}
                    </div>
                </div>
            </div>
        </div>
        <!-- 其他预警 -->
        <div
            id="othersYjCluster"
            class="clusterPart"
            v-show="othersYjCluster.list.length"
            style="width: 440px"
        >
            <div class="title flex-between">
                <span>
                    {{ othersYjCluster.title }}
                </span>
                <i
                    class="closeBtn pointer el-icon-close"
                    @click="closeClusterOthersYj()"
                ></i>
            </div>
            <div class="clusterCtn scrollbar-translucent">
                <div
                    v-for="(item, index) in othersYjCluster.list"
                    :key="index"
                    @click="getOthersYjDetail(item)"
                    class="ycdtItem pointer flex-between-y-center"
                    :title="item.YJSJ + item.FSYJSJ"
                >
                    <div style="width: 440px" class="pointer ellipsis">
                        {{ index + 1 }}. {{ item.FSYJSJ }} {{ item.YJSJ }}
                    </div>
                </div>
            </div>
        </div>
        <!-- 地图影像切换 -->
        <div id="mapType">
            <MapChangeBtn @baseLayerChange="baseLayerChange"></MapChangeBtn>
        </div>
        <!-- 预警部分 -->
        <div
            class="bellTipBtn pointer"
            :style="{
                right: commonDialog.dialog ? '-2577px' : '-1903px',
            }"
            @click="warnPart.dialog = true"
        >
            <img class="bell" src="@image/bigScreen/monitor_all/yj/bell.png" />
            <div class="num">{{ warnPart.list.length }}</div>
        </div>
        <div class="yjMsgDialog" v-if="warnPart.dialog">
            <DialogMsgYj
                :listAry="warnPart.list"
                @closeDialog="closeMsgDialog()"
                @stopWarn="stopWarn()"
            ></DialogMsgYj>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import MyMap from "@views/big_screen/comp/map/BaseMap.vue";
import "leaflet.markercluster/dist/leaflet.markercluster-src.js"; // 聚合
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import infoMixin from "./info_mixin.js";
import LayerList from "@views/big_screen/comp/LayerList.vue"; //图层
import LegendList from "./comp/LegendList.vue"; //图例
import XzqhTree from "./comp/XzqhTree.vue"; //行政区划
import MapChangeBtn from "@comp/map/MapChangeBtn.vue"; //开关

export default {
    components: {
        MyMap,
        LayerList,
        LegendList,
        XzqhTree,
        MapChangeBtn,
    },
    mixins: [infoMixin],
    name: "",
    data() {
        return {
            mapTime: "2022",
            myMap: null,
            mapData: {
                zoom: 12.6,
                maxZoom: 18,
                baseMapNum: null,
                mapTypeBtn: false,
                showTool: true,
                ycqHcqState: false,
                dxtState: false,
                reSetBtnState: false, //不显示地图复位按钮
            },
            ctclFgfhlGclGroup: null,
            layerState: false,
            ycysTypeTimer: null,
            ycysTypeOpacityList: [
                {
                    mapId: "",
                    name: "古茶林",
                    opacity: "",
                },
                {
                    mapId: "",
                    name: "分隔防护林",
                    opacity: "",
                },
                {
                    mapId: "",
                    name: "遗产要素村寨",
                    opacity: "",
                },
            ],
            onOffListCom: [
                {
                    name: "遗产要素",
                    src: require("@image/bigScreen/monitor_all/marker/active/ycys.png"),
                    srcDefault: require("@image/bigScreen/monitor_all/marker/default/ycys.png"),
                    list: [], //数据列表
                    group: null, //图层组
                    state: false, //左上 图层显示隐藏
                    imgState: true, //显示 隐藏 该图层
                    legend: true, //视图里（左上图层中 有的不显示图例）
                    actState: false, // 是否手动打开遗产要素图层
                },
                {
                    name: "古茶树",
                    src: require("@image/bigScreen/monitor_all/marker/active/gcs.png"),
                    srcDefault: require("@image/bigScreen/monitor_all/marker/default/gcs.png"),
                    group: null,
                    list: [],
                    state: false,
                    imgState: true,
                    legend: true,
                },
                {
                    name: "古树名木",
                    src: require("@image/bigScreen/monitor_all/marker/active/gsmm.png"),
                    srcDefault: require("@image/bigScreen/monitor_all/marker/default/gsmm.png"),
                    group: null,
                    list: [],
                    state: false,
                    imgState: true,
                    legend: true,
                },
                {
                    name: "摄像机",
                    state: false,
                    imgState: true,
                    legend: true,
                    mulList: [
                        {
                            name: "球形摄像机",
                            src: require("@image/bigScreen/monitor_all/marker/active/monitor.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/monitor.png"),
                            src_error: require("@image/bigScreen/monitor_all/marker/active/monitor_error.png"), //故障摄像机
                            list: [],
                            group: null,
                            state: false,
                            imgState: true,
                        },
                        {
                            name: "枪形摄像机",
                            src: require("@image/bigScreen/monitor_all/marker/active/monitor_gun.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/monitor_gun.png"),
                            src_error: require("@image/bigScreen/monitor_all/marker/active/monitor_gun_error.png"), //故障摄像机
                            list: [],
                            group: null,
                            state: false,
                            imgState: true,
                        },
                    ],
                },
                // {
                //     name: "报警设备",
                //     src: require("@image/bigScreen/monitor_all/marker/active/bjsb.png"),
                //     srcDefault: require("@image/bigScreen/monitor_all/marker/default/bjsb.png"),
                //     group: null,
                //     list: [],
                //     state: false,
                //     imgState: true,
                //     legend: true,
                // },
                // {
                //     name: "灭火器材",
                //     mulList: [
                //         {
                //             name: "消防栓",
                //             src: require("@image/bigScreen/monitor_all/marker/active/xfs.png"),
                //             srcDefault: require("@image/bigScreen/monitor_all/marker/default/xfs.png"),
                //             list: [],
                //             group: null,
                //             state: false,
                //             imgState: true,
                //         },
                //         {
                //             name: "灭火器",
                //             src: require("@image/bigScreen/monitor_all/marker/active/mhq.png"),
                //             srcDefault: require("@image/bigScreen/monitor_all/marker/default/mhq.png"),
                //             list: [],
                //             group: null,
                //             state: false,
                //             imgState: true,
                //         },
                //         {
                //             name: "蓄水罐",
                //             src: require("@image/bigScreen/monitor_all/marker/active/xsg.png"),
                //             srcDefault: require("@image/bigScreen/monitor_all/marker/default/xsg.png"),
                //             list: [],
                //             group: null,
                //             state: false,
                //             imgState: true,
                //         },
                //     ],
                //     state: false,
                //     legend: true,
                // },
                // 历史预警(预警 + 火警)
                {
                    name: "历史预警",
                    mulList: [
                        {
                            name: "火警",
                            src: require("@image/bigScreen/monitor_all/marker/active/hj.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/hj.png"),
                            list: [],
                            group: null,
                            state: true,
                            imgState: true,
                        },
                        {
                            name: "预警",
                            src: require("@image/bigScreen/monitor_all/marker/active/qtyj.png"),
                            srcDefault: require("@image/bigScreen/monitor_all/marker/default/qtyj.png"),
                            list: [],
                            group: null,
                            state: true,
                            imgState: true,
                        },
                    ],
                    state: false,
                    legend: true,
                },
            ],
            basicGroup: null, //遗产要素分布图
            gqyxGroup: null, //高清影像
            noteGroup: null, //注记图层组
            areaGroup: null,

            areaList: [],
            markerPopup: null,
            mapBoxMap: null,
            // 海拔高度
            altitudeGroup: null,
            altitudeList: [
                {
                    name: "景迈大桥",
                    altitudeHeight: "1100",
                    lng: "100.0603222846985",
                    lat: "22.223829727794755",
                    lineRotate: "-128deg",
                    lineHeight: "62px",
                    panelTop: "-39px",
                    panelRight: "-172px",
                },
                {
                    name: "翁基",
                    altitudeHeight: "1500",
                    lng: "99.99896116",
                    lat: "22.17418354",
                    lineRotate: "128deg",
                    lineHeight: "62px",
                    panelTop: "-39px",
                    panelRight: "50px",
                },
                {
                    name: "哎冷山",
                    altitudeHeight: "1600",
                    lng: "100.01914141700765",
                    lat: "22.16944820397415100",
                    lineRotate: "-128deg",
                    lineHeight: "62px",
                    panelTop: "-39px",
                    panelRight: "-172px",
                },
            ],
            topEchartState: false,
            ycysCluster: {
                title: "遗产要素",
                list: [],
                dialog: null,
                group: null,
            },
            gsmmCluster: {
                title: "古树名木",
                list: [],
                dialog: null,
                group: null,
            },
            othersYjCluster: {
                title: "预警",
                list: [],
                dialog: null,
                group: null,
            },
            clusterTimer: null,
            // marker弹出框
            commonDialog: {
                title: "",
                detail: {},
                dialog: false,
                gcsDialog: false, //古茶树
                gsmmDialog: false, //古树名木
                ycysDialog: false, //遗产要素
                ycysMjDialog: false, //遗产要素 民居
                hjDialog: false, //历史火警
                othersDialog: false, //预警
            },
            xfsbActiveItem: null, //设备消防 选中某一个
            xfsbActiveLayer: null, //设备消防 选中某一个 上一个
            xfsbTimerShow: null, //设备消防 定时器 显示
            xfsbTimerHide: null, //设备消防 定时器 隐藏

            ycyHcq: null, // 遗产区、缓冲区的线条
            markGroup: null, //标注
            // 预警弹框
            warnPart: {
                dialog: false,
                music: null, // 音乐
                group: null, //图层组
                popup: null, //文字提示pop
                list: [],
            },
            // 预警等级图片
            yjdjImgList: [
                {
                    name: "一级预警",
                    src: require("@image/bigScreen/monitor_all/yj/alerm1.gif"),
                },
                {
                    name: "二级预警",
                    src: require("@image/bigScreen/monitor_all/yj/alerm2.gif"),
                },
                {
                    name: "三级预警",
                    src: require("@image/bigScreen/monitor_all/yj/alerm3.gif"),
                },
                {
                    name: "四级预警",
                    src: require("@image/bigScreen/monitor_all/yj/alerm4.gif"),
                },
            ],
            btnList: [], //选中的类型
            ycysSingleGroup: null, //一个遗产要素图层组
        };
    },
    computed: {
        ...mapGetters({
            jczlYcysTypeItem: TYPES.jczlYcysTypeItem,
            jczlYcysList: TYPES.jczlYcysList,
            jczlYcysItem: TYPES.jczlYcysItem,
            jczlYczcItem: TYPES.jczlYczcItem,
            jczlYczcItemYcysList: TYPES.jczlYczcItemYcysList,
            jczlYjItem: TYPES.jczlYjItem,
        }),
    },
    props: {},
    watch: {
        // 遗产要素类型 古茶林 传统村落 分隔防护林
        jczlYcysTypeItem: {
            immediate: false,
            handler() {
                this.clearLayersGroup(this.areaGroup);
                let nameList = ["古茶林", "传统村落", "分隔防护林"];
                if (this.jczlYcysTypeItem) {
                    switch (this.btnList.length) {
                        case 0:
                            // 显示一个隐藏两个
                            this.onOffList.map((item) => {
                                if (nameList.includes(item.name)) {
                                    if (
                                        item.name == this.jczlYcysTypeItem.name
                                    ) {
                                        this.btnList.push(item.name);
                                        this.changePq(this.btnList, 0.8);
                                        item.state = true;
                                    } else {
                                        this.changeYcysMap([item.name], 0);
                                        item.state = false;
                                    }
                                }
                            });
                            break;
                        case 1:
                            // 显示两个
                            this.onOffList.map((item) => {
                                if (nameList.includes(item.name)) {
                                    if (
                                        item.name == this.jczlYcysTypeItem.name
                                    ) {
                                        this.btnList.push(item.name);
                                        this.changePq(this.btnList, 0.8);
                                        item.state = true;
                                        // 隐藏一个
                                        nameList.map((item) => {
                                            this.btnList.map((itemInner) => {
                                                // 找到隐藏的一个元素
                                                if (
                                                    !nameList.includes(
                                                        itemInner
                                                    )
                                                ) {
                                                    this.changeYcysMap(
                                                        [item.name],
                                                        0
                                                    );
                                                    this.onOffList.map(
                                                        (item2) => {
                                                            // 图层关掉
                                                            if (
                                                                item2.name ==
                                                                item.name
                                                            ) {
                                                                item.state = false;
                                                            }
                                                        }
                                                    );
                                                }
                                            });
                                        });
                                    }
                                }
                            });
                            break;
                        case 2:
                            // 三个全出现
                            this.onOffList.map((item) => {
                                if (nameList.includes(item.name)) {
                                    if (
                                        item.name == this.jczlYcysTypeItem.name
                                    ) {
                                        this.btnList.push(item.name);
                                        this.changePq(this.btnList, 0.8);
                                        item.state = true;
                                    }
                                }
                            });
                            break;
                        case 3:
                            this.btnList = [];
                            // 显示一个隐藏两个
                            this.onOffList.map((item) => {
                                if (nameList.includes(item.name)) {
                                    if (
                                        item.name == this.jczlYcysTypeItem.name
                                    ) {
                                        this.btnList.push(item.name);
                                        this.changePq(this.btnList, 0.8);
                                        item.state = true;
                                    } else {
                                        this.changeYcysMap([item.name], 0);
                                        item.state = false;
                                    }
                                }
                            });
                            break;
                    }
                    this.ycysTypeAnimate();
                } else {
                    this.onOffList.map((item) => {
                        if (nameList.includes(item.name)) {
                            this.changeYcysMap([item.name], 0.6);
                            item.state = true;
                        }
                    });
                    this.btnList = [];
                }
            },
        },
        // 遗产组成某一个下的遗产要素列表
        jczlYczcItemYcysList: {
            immediate: false,
            handler() {
                let list = [
                    "景迈-糯岗分隔防护林",
                    "糯岗-芒景分隔防护林",
                    "景迈-芒景分隔防护林",
                ];
                // 点击 分隔防护林下的 遗产组成 实现自动点击三个 分隔防护林每个点击事件
                if (
                    this.jczlYczcItem &&
                    this.jczlYczcItem.YCYSFL_2 &&
                    list.includes(this.jczlYczcItem.YCYSFL_2) &&
                    this.jczlYcysList.length
                ) {
                    this.jczlYcysList.map((item) => {
                        if (item.YCYSFL_2 == this.jczlYczcItem.YCYSFL_2) {
                            this.getYcysDetail(item);
                        }
                    });
                }
            },
        },
        // 某一个遗产组成
        jczlYczcItem: {
            immediate: false,
            handler() {
                this.getOthersYj(); //预警
                this.closeComDialog();
                if (this.jczlYczcItem) {
                    this.myMap.flyTo(
                        [this.jczlYczcItem.WD, this.jczlYczcItem.JD],
                        this.jczlYczcItem.LEVEL
                    );

                    this.onOffListCom.map((item) => {
                        switch (item.name) {
                            case "遗产要素":
                                if (!this.common.containGroup(item.group)) {
                                    item.state = true;
                                    this.drawMarkerYcys(this.jczlYcysList);
                                }
                                break;
                        }
                    });
                } else {
                    this.$refs.myMap.changeFitBounds(
                        window.mapCenter[0],
                        window.mapCenter[1],
                        this.mapData.zoom
                    );
                    this.clearLayersGroup(this.ycysSingleGroup);
                    this.reSetState();
                }
            },
        },
        // 某一个遗产要素
        jczlYcysItem: {
            immediate: false,
            handler() {
                this.getOthersYj(); //预警
                let item = this.jczlYcysItem;
                if (item) {
                    if (!item.WD) {
                        return;
                    }
                    this.myMap.flyTo(
                        [this.jczlYcysItem.WD, this.jczlYcysItem.JD],
                        20
                    );
                    this.getYcysDetail(item);
                    // 点击左侧列表中的遗产要素
                    // 地图图层没有打开 遗产要素图层
                    // 画一个marker
                    this.onOffListCom.map((item) => {
                        if (item.name == "遗产要素" && !item.state) {
                            this.drawYcysMarkerSingle(
                                this.ycysSingleGroup,
                                this.jczlYcysItem
                            );
                        }
                    });
                    this.onOffListCom.map((item) => {
                        if (
                            item.name == "遗产要素" &&
                            this.common.containGroup(item.group)
                        ) {
                            // 遗产要素选中动画
                            this.ycysAnimate(this.jczlYcysItem);
                        }
                    });
                } else {
                    this.markerPopup.removeFrom(this.myMap);
                    this.clearLayersGroup(this.ycysSingleGroup);
                    this.closeComDialog();
                }
            },
        },
        // 某一条预警信息
        jczlYjItem: {
            immediate: false,
            handler() {
                if (this.jczlYjItem) {
                    let item = this.jczlYjItem;
                    this.commonDialog.dialog = true;
                    this.commonDialog.othersDialog = true;
                    this.commonDialog.title = item.YJSJ;
                    this.commonDialog.detail = item;
                    this.markerPopup
                        .setLatLng([item.WD, item.JD])
                        .setContent(`<div class="tip">${item.YJSJ}</div>`)
                        .openOn(this.myMap);
                    this.myMap.flyTo([item.WD, item.JD], 17);
                } else {
                    if (this.markerPopup) {
                        this.markerPopup.removeFrom(this.myMap);
                    }
                }
            },
        },
    },
    mounted() {
        this.basicBg.group = window.L.layerGroup().addTo(this.myMap);
        this.gqyxGroup = window.L.layerGroup().addTo(this.myMap);
        this.basicGroup = window.L.layerGroup().addTo(this.myMap);
        this.altitudeGroup = window.L.layerGroup().addTo(this.myMap);
        this.markGroup = window.L.layerGroup().addTo(this.myMap);
        this.nyfbtGroup = window.L.layerGroup().addTo(this.myMap);
        this.xfzttGroup = window.L.layerGroup().addTo(this.myMap);
        this.areaGroup = window.L.layerGroup().addTo(this.myMap);
        this.ctclFgfhlGclGroup = window.L.layerGroup().addTo(this.myMap);
        this.ycysSingleGroup = window.L.layerGroup().addTo(this.myMap);
        this.getCtclFgfhlGcl(this.ctclFgfhlGclGroup);
        this.loadMapNote(this.markGroup);
        this.onOffListCom.map((item) => {
            if (item.mulList) {
                item.mulList.map((itemInner) => {
                    itemInner.group = window.L.layerGroup().addTo(this.myMap);
                });
            } else {
                item.group = window.L.layerGroup().addTo(this.myMap);
            }
        });
        // 矢量地图
        this.onOffListVector.map((item) => {
            item.group = window.L.layerGroup().addTo(this.myMap);
        });
        this.baseLayerChange("img");
        this.drawNyfbt(this.nyfbtGroup, window.MAP_NYFBT); //农业分布图
        this.drawXfztt(this.xfzttGroup, window.MAP_XFZTT); //消防专题图
        this.getGcsList(); //古茶林
        this.getGsmmList(); //古树名木列表
        this.getSxtList(); //摄像机列表
        // this.getMhqcList(); //灭火器材列表
        // this.getBjsbList(); //报警设备列表
        // 历史预警包括 火警 + 预警
        this.getHistoryHjList(); //火警
        this.getOthersYj(); //预警

        this.warnPart.group = window.L.layerGroup().addTo(this.myMap);
        this.warnPart.popup = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, -10],
            className: "markerPopup",
        });

        this.markerPopup = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, -10],
            className: "markerPopup",
        });

        this.ycysCluster.dialog = window.L.popup({
            closeButton: false,
            offset: [-5, -14],
            minWidth: 200,
            className: "aggregate",
        })
            .setContent(document.getElementById("ycysCluster"))
            .setLatLng([0, 0]);
        // 古树名木
        this.gsmmCluster.dialog = window.L.popup({
            closeButton: false,
            offset: [-5, -14],
            minWidth: 200,
            className: "aggregate",
        })
            .setContent(document.getElementById("gsmmCluster"))
            .setLatLng([0, 0]);
        // 预警
        this.othersYjCluster.dialog = window.L.popup({
            closeButton: false,
            offset: [-5, -14],
            minWidth: 440,
            className: "aggregate",
        })
            .setContent(document.getElementById("othersYjCluster"))
            .setLatLng([0, 0]);

        this.myMap.on("zoomend", (ev) => {
            let level = ev.target._zoom;
            // >=15.2 加载摄像头 去掉填充色 显示 边界线
            if (level >= 15.2) {
                this.onOffListCom.map((item) => {
                    switch (item.name) {
                        case "摄像机":
                            item.state = true;
                            this.drawSxt(item.mulList[0]);
                            this.drawSxt(item.mulList[1]);
                    }
                });
                // 隐藏掉矢量瓦片这三个片区
                this.changePq(["古茶林", "传统村落", "分隔防护林"], 0);
                // 画边界线
                this.drawXcq(this.areaGroup);
            } else {
                if (this.markerPopup) {
                    this.markerPopup.removeFrom(this.myMap);
                }
                this.onOffListCom.map((item) => {
                    switch (item.name) {
                        case "摄像机":
                            item.state = false;
                            item.mulList.map((item) => {
                                this.clearLayersGroup(item.group);
                            });
                            break;
                    }
                });
                this.clearLayersGroup(this.areaGroup);
                if (this.btnList.length) {
                    // 加载选中的类型片区
                    this.btnList.map((item) => {
                        this.changePq([item], 0.8);
                    });
                } else {
                    // 没有选中类型 加载所有片区
                    this.changePq(["古茶林", "传统村落", "分隔防护林"], 0.8);
                }
            }
        });
    },
    methods: {
        ...mapActions([
            "GetTeaTreesMap",
            "GetAncientTreesMap",
            "MapCameraList",
            "GetFireControlsMap",
            "GetFireAlarmMap",
            "GetWarnInfoList",
            "GetFireWarningEquipments",
        ]),
        ...mapMutations({
            SET_JCZLYCYSITEM: TYPES.SET_JCZLYCYSITEM,
            SET_JCZLYJITEM: TYPES.SET_JCZLYJITEM,
        }),
        // 地图年月切换
        changeMapTime() {
            this.baseLayerChange("img");
        },
        // 更改图层 遗产要素分布图
        switchStateChange(state, item) {
            this.changeYcysMap([item.name], state ? 1 : 0);
            item.state = state;
            switch (item.name) {
                case "遗产区、缓冲区":
                    this.changeYcysMap(["保护范围、建控地带"], 0);
                    this.onOffList[1].state = false;
                    break;
                case "保护范围、建控地带":
                    this.changeYcysMap(["遗产区、缓冲区"], 0);
                    this.onOffList[0].state = false;
                    break;
                case "传统村落":
                case "分隔防护林":
                case "古茶林":
                    if (state) {
                        this.clearGht();
                    }
                    break;
            }
        },
        // 更改图层 矢量分布图
        switchStateVector(state, item, index) {
            item.state = state;
            switch (item.name) {
                case "国家森林公园":
                    if (state) {
                        item.mulList.map((itemInner) => {
                            itemInner.imgState = true;
                        });
                        this.getGjslgyData(item.group, item.url);
                    }
                    if (!state) {
                        item.mulList.map((itemInner) => {
                            itemInner.state = false;
                        });
                        this.clearLayersGroup(item.group);
                        return;
                    }
                    break;
                case "地形图":
                    if (state) {
                        this.getDxtData(item);
                    } else {
                        this.clearLayersGroup(item.group);
                    }
                    break;
                case "水系山脉图":
                    if (state) {
                        this.drawSxsmt(item.group, item.url);
                    } else {
                        this.clearLayersGroup(item.group);
                    }
                    break;
                case "规划图":
                    if (state) {
                        item.mulList.map((itemInner) => {
                            itemInner.imgState = true;
                        });
                        this.drawGht(item.group, item.url);
                    }
                    if (!state) {
                        item.mulList.map((itemInner) => {
                            itemInner.state = false;
                        });
                        this.clearLayersGroup(item.group);
                        return;
                    }
                    break;
            }
        },
        // 更改图层 自定图图层
        switchStateChangeCom(state, item, index) {
            if (item.mulList) {
                this.switchStateChangeComMul(state, item, index);
            } else {
                this.switchStateChangeComSingle(state, item, index);
            }
        },
        //图例改变 传统村落 分隔防护林 古茶林
        legendChangeMap(obj) {
            obj.imgState = !obj.imgState;
            obj.idList.map((item) => {
                this.mapboxMap.setPaintProperty(
                    item,
                    "fill-opacity",
                    obj.imgState ? 0.8 : 0
                );
            });
        },
        //图例改变 矢量分布图Mul
        legendChangeVectorMul(obj) {
            obj.imgState = !obj.imgState;
            let num = obj.imgState == true ? 1 : 0;
            switch (obj.name) {
                case "公园界桩":
                case "公园范围":
                    obj.list.map((item) => {
                        let type = "";
                        if (item.type == "symbol") {
                            type = "icon-opacity";
                        } else {
                            type = "fill-opacity";
                        }
                        this.mapboxGjslgy.setPaintProperty(item.id, type, num);
                    });
                    break;
                case "村庄建设":
                case "生态保护":
                case "古茶林保护线":
                case "基本农田":
                    obj.list.map((item) => {
                        let type = "";
                        if (item.type == "line") {
                            type = "line-opacity";
                        } else {
                            type = "fill-opacity";
                        }
                        this.mapboxGht.setPaintProperty(item.id, type, num);
                    });
                    break;
            }
        },
        legendChangeVector() {},
        // 图例改变 多个图层
        legendChangeMulCustom(item) {
            item.imgState = !item.imgState;
            if (!item.imgState) {
                this.clearLayersGroup(item.group);
                return;
            }
            switch (item.name) {
                case "消防栓":
                case "灭火器":
                case "蓄水罐":
                    this.drawMarkerMhqc(item.group, item.list, item.src);
                    break;
                case "火警":
                    this.drawMarkerHistoryHj(item.group, item.list);
                    break;
                case "预警":
                    this.drawMarkerOthersYj(item);
                    break;
                case "球形摄像机":
                    this.drawSxt(item);
                    break;
                case "枪形摄像机":
                    this.drawSxt(item);
                    break;
            }
        },
        // 图例改变 一个图层
        legendChangeSingleCustom(item) {
            item.imgState = !item.imgState;
            if (!item.imgState) {
                this.clearLayersGroup(item.group);
                return;
            }
            switch (item.name) {
                case "遗产要素":
                    this.drawMarkerYcys(this.jczlYcysList);
                    break;
                case "古茶树":
                    this.drawMarkerGcs(item);
                    break;
                case "古树名木":
                    this.drawMarkerGsmm(item);
                    break;

                case "报警设备":
                    this.drawMarkerBjsb(item.group, item.list, item.src);
                    break;
            }
        },
        // 控制多个图层
        switchStateChangeComMul(state, item, index) {
            this.onOffListCom[index].state = state;
            if (state) {
                item.mulList.map((itemInner) => {
                    itemInner.imgState = true;
                });
            }
            if (!state) {
                item.mulList.map((itemInner) => {
                    itemInner.state = false;
                    this.clearLayersGroup(itemInner.group);
                });
                return;
            }
            switch (item.name) {
                case "灭火器材":
                    item.mulList.map((itemInner) => {
                        itemInner.state = true;
                        this.drawMarkerMhqc(
                            itemInner.group,
                            itemInner.list,
                            itemInner.src
                        );
                    });
                    break;
                case "历史预警":
                    item.mulList.map((itemInner) => {
                        if (itemInner.name == "火警") {
                            itemInner.state = true;
                            this.drawMarkerHistoryHj(
                                itemInner.group,
                                itemInner.list
                            );
                        } else if (itemInner.name == "预警") {
                            itemInner.state = true;
                            this.drawMarkerOthersYj(itemInner);
                        }
                    });
                    break;
                case "摄像机":
                    this.drawSxt(item.mulList[0]); //摄像机 球形
                    this.drawSxt(item.mulList[1]); //摄像机 枪形
                    break;
            }
        },
        // 控制一个图层
        switchStateChangeComSingle(state, item, index) {
            this.onOffListCom[index].state = state;
            if (state) {
                item.imgState = true;
            }
            if (!state) {
                this.clearLayersGroup(item.group);
                switch (item.name) {
                    case "遗产要素":
                        item.actState = state;
                        break;
                }
                return;
            }
            switch (item.name) {
                case "遗产要素":
                    item.actState = state;
                    this.drawMarkerYcys(this.jczlYcysList);
                    break;
                case "古茶树":
                    this.drawMarkerGcs(item);
                    break;
                case "古树名木":
                    this.drawMarkerGsmm(item);
                    break;

                case "报警设备":
                    this.drawMarkerBjsb(item.group, item.list, item.src);
                    break;
            }
        },
        // 地图和影像切换
        baseLayerChange(type) {
            let list = ["土地利用"];
            switch (type) {
                case "vector":
                    this.clearLayersGroup(this.gqyxGroup);
                    // this.basicBg.layer.addTo(this.basicBg.group);
                    this.myMap.setMaxZoom(18);
                    // this.changeYcysMap(list, 1);
                    break;
                case "img":
                    //遗产要素瓦片背景
                    // this.clearLayersGroup(this.basicBg.group);
                    this.loadGqyx(this.gqyxGroup);
                    // this.changeYcysMap(list, 0);
                    this.myMap.setMaxZoom(20);
                    break;
            }
        },
        //遗产要素 marker 所有
        drawMarkerYcys(list) {
            // 清空掉 左侧点击绘制的marker
            this.clearLayersGroup(this.ycysSingleGroup);
            let group = null;
            let src = null;
            this.onOffListCom.map((item) => {
                if (item.name == "遗产要素") {
                    group = item.group;
                    src = item.src;
                }
            });
            if (!group) {
                return;
            }

            this.clearLayersGroup(group);
            this.ycysCluster.group = new window.L.markerClusterGroup({
                maxClusterRadius: 30, //像素
                spiderfyOnMaxZoom: false,
                showCoverageOnHover: false,
                zoomToBoundsOnClick: false,
                iconCreateFunction: (cluster) => {
                    // let da = cluster.getAllChildMarkers();
                    let html2 =
                        "<div style='background-image: linear-gradient(180deg, #e83828 0%, #e83828 100%);color: #fff;border-radius: 50%;position: absolute;left: -16px;top: -16px;width: 32px;height: 32px;opacity: 0.8;font-size:16px;' class='flex-both-center'>" +
                        cluster.getChildCount();
                    ("</div>");
                    return window.L.divIcon({ html: html2 });
                },
            }).addTo(group);
            // 聚合移入
            this.ycysCluster.group.on("clustermouseover", (e) => {
                this.clusterTimer = setTimeout(() => {
                    this.ycysCluster.list = [];
                    e.layer.getAllChildMarkers().map((item) => {
                        let obj = item.options.data;
                        this.ycysCluster.list.push(obj);
                    });
                    this.ycysCluster.dialog
                        .setLatLng([e.layer._latlng.lat, e.layer._latlng.lng])
                        .openOn(this.myMap);
                }, 300);
            });
            // 聚合移出
            this.ycysCluster.group.on("clustermouseout", () => {
                clearTimeout(this.clusterTimer);
            });
            list.map((item) => {
                if (!item.WD) {
                    return;
                }
                let html = `<div style="position: absolute;
                                    top: 0;
                                    left: 50%;
                                    text-align:center;
                                    transform: translateX(-50%);">
                            <div
                             style="background: rgb(3, 23, 38);
                                    opacity: 0.8;
                                    border-radius: 5px;
                                    white-space:nowrap;
                                    padding:4px 10px;color:#fff;">
                              ${item.MC}
                            </div>
                    <img src=${src} style="margin-top:4px;"/>
                </div> `;
                let icon = window.L.divIcon({
                    html: html,
                    // iconSize: [32, 32],
                    // iconAnchor: [16, 16],
                    iconSize: [32, 62],
                    iconAnchor: [16, 62],
                });

                let layer = window.L.marker([item.WD, item.JD], {
                    icon: icon,
                    data: item,
                }).addTo(this.ycysCluster.group);

                layer.on("click", () => {
                    this.getYcysDetail(item);
                });
                // layer.on("mouseover", () => {
                //     this.markerPopup
                //         .setLatLng([item.WD, item.JD])
                //         .setContent(`<div class="tip">${item.MC}</div>`)
                //         .openOn(this.myMap);
                // });
                // layer.on("mouseout", () => {
                //     this.markerPopup.removeFrom(this.myMap);
                // });
            });

            if (this.jczlYcysItem) {
                this.ycysAnimate(this.jczlYcysItem);
            }
        },
        // 遗产要素 marker 一个
        drawYcysMarkerSingle(group, item) {
            this.clearLayersGroup(group);
            let src = null;
            this.onOffListCom.map((item) => {
                if (item.name == "遗产要素") {
                    src = item.src;
                }
            });

            let html = `<div style="position: absolute;
                                    top: 0;
                                    left: 50%;
                                    text-align:center;
                                    transform: translateX(-50%);">
                            <div
                             style="background: rgb(3, 23, 38);
                                    opacity: 0.8;
                                    border-radius: 5px;
                                    white-space:nowrap;
                                    padding:4px 10px;color:#fff;">
                              ${item.MC}
                            </div>
                    <img src=${src} style="margin-top:4px;"/>
                </div> `;
            let icon = window.L.divIcon({
                html: html,
                // iconSize: [32, 32],
                // iconAnchor: [16, 16],
                iconSize: [32, 62],
                iconAnchor: [16, 62],
            });

            let layer = window.L.marker([item.WD, item.JD], {
                icon: icon,
                data: item,
            }).addTo(group);

            layer.on("click", () => {
                this.getYcysDetail(item);
            });
        },
        // 遗产要素详情
        getYcysDetail(obj) {
            this.closeComDialog();
            if (obj.JMLX == "1") {
                this.commonDialog.dialog = "ycysDialog";
            } else {
                // 民居部分
                this.commonDialog.dialog = "ycysMjDialog";
            }
            this.commonDialog.title = `${obj.MC}`;
            this.commonDialog.detail = obj;
            this.$emit("getCommonDialog", this.commonDialog);
            this.SET_JCZLYCYSITEM(obj);

            // this.myMap.setView([obj.WD, obj.JD], 18);
            // this.myMap.flyTo([obj.WD, obj.JD], 18);
            // let group = this.onOffListCom[1].group;
            // Object.values(group._layers).forEach((item) => {
            //     Object.values(item._featureGroup._layers).forEach(
            //         (itemInner1) => {
            //             if (itemInner1.options.data) {
            //                 this.closeCluster();
            //                 if (itemInner1.options.data.BM == obj.BM) {
            //                     itemInner1.setOpacity(0.3);
            // setTimeout(() => {
            //     itemInner1.setOpacity(0.3);
            // }, 3000);
            // setTimeout(() => {
            //     itemInner1.setOpacity(1);
            // }, 4000);
            //                 } else {
            // itemInner1.setOpacity(1);
            //                 }
            //             }
            //         }
            //     );
            // });
        },
        closeClusterYcys() {
            this.ycysCluster.dialog.removeFrom(this.myMap);
            this.ycysCluster.list = [];
        },
        // 最新版的marker
        closeComDialog() {
            this.commonDialog.dialog = false;
            this.commonDialog.jgDialog = false; //景观视线 景观风貌 弹出框
            this.commonDialog.sxtDialog = false; //摄像头 弹出框
            this.commonDialog.gcsDialog = false; //古茶树
            this.commonDialog.gsmmDialog = false; // 古树名木
            this.commonDialog.ycysDialog = false; //遗产要素
            this.commonDialog.ycysMjDialog = false; //遗产要素民居
            this.commonDialog.hjDialog = false; //历史火警
            this.commonDialog.othersDialog = false; //预警
            if (this.jczlYjItem) {
                this.SET_JCZLYJITEM(null);
            }
        },
        // 古茶树列表
        async getGcsList() {
            let result = await this.GetTeaTreesMap({});
            this.onOffListCom.map((item) => {
                if (item.name == "古茶树") {
                    item.list = result;
                    // this.drawMarkerGcs(item);
                }
            });
        },
        drawMarkerGcs(obj) {
            let list = obj.list;
            let group = obj.group;
            let src = obj.src;
            this.clearLayersGroup(group);
            list.map((item) => {
                let icon = window.L.divIcon({
                    html: `<img style="width:32px;height:32px; filter: drop-shadow(2px 2px 5px rgba(7,29,41,0.5));" src=${src} />`,
                    iconSize: [32, 32],
                    iconAnchor: [16, 16],
                });
                let layer = window.L.marker([item.BW, item.DJ], {
                    icon: icon,
                    data: item,
                }).addTo(group);
                layer.on("click", () => {
                    this.commonDialog.dialog = "gcsDialog";
                    this.commonDialog.title = `${item.SZZWM}`;
                    this.commonDialog.detail = item;
                    this.$emit("getCommonDialog", this.commonDialog);
                });
                layer.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([item.BW, item.DJ])
                        .setContent(`<div class="tip">${item.SZZWM}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
            });
        },
        // 古树名木列表
        async getGsmmList() {
            let result = await this.GetAncientTreesMap({});
            this.onOffListCom.map((item) => {
                if (item.name == "古树名木") {
                    item.list = result;
                    // this.drawMarkerGsmm(item);
                }
            });
        },

        drawMarkerGsmm(obj) {
            let list = obj.list;
            let group = obj.group;
            let src = obj.src;
            this.clearLayersGroup(group);
            this.gsmmCluster.group = new window.L.markerClusterGroup({
                maxClusterRadius: 30, //像素
                spiderfyOnMaxZoom: false,
                showCoverageOnHover: false,
                zoomToBoundsOnClick: false,
                iconCreateFunction: (cluster) => {
                    // let da = cluster.getAllChildMarkers();
                    let html2 =
                        "<div style='background-image: linear-gradient(180deg, #14be50 0%, #14be50 100%);color: #fff;border-radius: 50%;position: absolute;left: -16px;top: -16px;width: 32px;height: 32px;opacity: 0.8;font-size:16px;' class='flex-both-center'>" +
                        cluster.getChildCount();
                    ("</div>");
                    return window.L.divIcon({ html: html2 });
                },
            }).addTo(group);
            // 聚合移入
            this.gsmmCluster.group.on("clustermouseover", (e) => {
                this.clusterTimer = setTimeout(() => {
                    this.gsmmCluster.list = [];
                    e.layer.getAllChildMarkers().map((item) => {
                        let obj = item.options.data;
                        this.gsmmCluster.list.push(obj);
                    });
                    this.gsmmCluster.dialog
                        .setLatLng([e.layer._latlng.lat, e.layer._latlng.lng])
                        .openOn(this.myMap);
                }, 300);
            });
            // 聚合移出
            this.gsmmCluster.group.on("clustermouseout", () => {
                clearTimeout(this.clusterTimer);
            });
            list.map((item) => {
                let icon = window.L.divIcon({
                    html: `<img style="width:32px;height:32px; filter: drop-shadow(2px 2px 5px rgba(7,29,41,0.5));" src=${src} />`,
                    iconSize: [32, 32],
                    iconAnchor: [16, 16],
                });

                let layer = window.L.marker([item.BW, item.DJ], {
                    icon: icon,
                    data: item,
                }).addTo(this.gsmmCluster.group);

                layer.on("click", () => {
                    this.commonDialog.dialog = "gsmmDialog";
                    this.commonDialog.title = `${item.SZZWM}`;
                    this.commonDialog.detail = item;
                    this.$emit("getCommonDialog", this.commonDialog);
                });
                layer.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([item.BW, item.DJ])
                        .setContent(`<div class="tip">${item.SZZWM}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
            });
        },
        getGsmmDetail(item) {
            this.closeComDialog();
            this.commonDialog.dialog = "gsmmDialog";
            this.commonDialog.title = `${item.SZZWM}`;
            this.commonDialog.detail = item;
            this.$emit("getCommonDialog", this.commonDialog);

            if (this.myMap.getZoom() < 21) {
                this.myMap.setView([item.BW, item.DJ], 18);
            }

            this.markerPopup
                .setLatLng([item.BW, item.DJ])
                .setContent(`<div class="tip">${item.SZZWM}</div>`)
                .openOn(this.myMap);
        },
        closeClusterGsmm() {
            this.gsmmCluster.dialog.removeFrom(this.myMap);
            this.gsmmCluster.list = [];
        },
        // 摄像机列表
        async getSxtList() {
            let result = await this.MapCameraList({});
            let circleList = [];
            let gunList = [];
            result.map((item) => {
                if (item.SBQTLX == 0) {
                    circleList.push(item);
                } else {
                    gunList.push(item);
                }
            });
            this.onOffListCom.map((item) => {
                if (item.name == "摄像机") {
                    item.mulList[0].list = circleList;
                    item.mulList[1].list = gunList;
                }
            });
        },
        // 画摄像机
        drawSxt(obj) {
            let list = obj.list;
            let group = obj.group;
            this.clearLayersGroup(group);
            list.forEach((item) => {
                if (!item.AZDWD || !item.AZDJD) {
                    return;
                }
                // SFJT 0 -否 1-是
                // SBQTLX 0:球机 1：枪机
                let url = "";
                if (item.SFJT) {
                    url = obj.src;
                } else {
                    url = obj.src_error;
                }
                let icon = window.L.icon({
                    iconUrl: url,
                    iconSize: [32, 32],
                    iconAnchor: [16, 16],
                });
                let layer = window.L.marker([item.AZDWD, item.AZDJD], {
                    icon: icon,
                }).addTo(group);
                layer.on("click", () => {
                    // if (!item.RTMP) {
                    //     this.common.showMsg("暂无设备", "warning");
                    //     return;
                    // }
                    if (!item.SFJT) {
                        this.common.showMsg(`${item.SBMC}设备故障`, "warning");
                    }
                    this.commonDialog.dialog = "sxtDialog";
                    this.commonDialog.detail = item;
                    this.commonDialog.title = item.SBMC;
                    this.$emit("getCommonDialog", this.commonDialog);
                });
                layer
                    .on("mouseover", () => {
                        this.markerPopup
                            .setLatLng([item.AZDWD, item.AZDJD])
                            .setContent(`<div class="tip">${item.SBMC}</div>`)
                            .openOn(this.myMap);
                    })
                    .on("mouseout", () => {
                        this.markerPopup.removeFrom(this.myMap);
                    });
            });
        },
        // 灭火器材列表
        async getMhqcList() {
            let result = await this.GetFireControlsMap({});
            this.onOffListCom.map((item) => {
                if (item.name == "灭火器材") {
                    item.mulList[0].list = result.XFS;
                    item.mulList[1].list = result.MHQ;
                    item.mulList[2].list = result.XSC;
                    // this.drawMarkerMhqc(
                    //     item.mulList[0].group,
                    //     item.mulList[0].list,
                    //     item.mulList[0].src
                    // );
                    // this.drawMarkerMhqc(
                    //     item.mulList[0].group,
                    //     item.mulList[1].list,
                    //     item.mulList[1].src
                    // );
                    // this.drawMarkerMhqc(
                    //     item.mulList[0].group,
                    //     item.mulList[2].list,
                    //     item.mulList[2].src
                    // );
                }
            });
        },
        drawMarkerMhqc(group, list, src) {
            list.map((item) => {
                if (item.AZWD) {
                    let icon = window.L.divIcon({
                        html: `<img style="width:32px;height:32px; filter: drop-shadow(2px 2px 5px rgba(7,29,41,0.5));" src=${src} />`,
                        iconSize: [32, 32],
                        iconAnchor: [16, 16],
                    });

                    let layer = window.L.marker([item.AZWD, item.AZJD], {
                        icon: icon,
                        data: item,
                    }).addTo(group);

                    layer.on("click", () => {
                        if (
                            this.xfsbActiveItem &&
                            this.xfsbActiveItem.ID == item.ID
                        ) {
                            this.xfsbActiveItem = null;
                            clearInterval(this.xfsbTimerShow);
                            clearInterval(this.xfsbTimerHide);
                            layer.setOpacity(1);
                        } else {
                            if (this.xfsbActiveLayer) {
                                this.xfsbActiveLayer.setOpacity(1);
                            }
                            this.xfsbActiveLayer = layer;
                            this.xfsbActiveItem = item;

                            layer.setOpacity(0);
                            if (this.xfsbTimerShow) {
                                clearInterval(this.xfsbTimerShow);
                            }
                            if (this.xfsbTimerHide) {
                                clearInterval(this.xfsbTimerHide);
                            }
                            this.xfsbTimerShow = setInterval(() => {
                                layer.setOpacity(1);
                            }, 1000);
                            this.xfsbTimerHide = setInterval(() => {
                                layer.setOpacity(0);
                            }, 2000);
                        }
                    });
                    layer.on("mouseover", () => {
                        this.markerPopup
                            .setLatLng([item.AZWD, item.AZJD])
                            .setContent(`<div class="tip">${item.SBMC}</div>`)
                            .openOn(this.myMap);
                    });
                    layer.on("mouseout", () => {
                        this.markerPopup.removeFrom(this.myMap);
                    });
                }
            });
        },
        // 报警设备列表
        async getBjsbList() {
            let result = await this.GetFireWarningEquipments({});
            this.onOffListCom.map((item) => {
                if (item.name == "报警设备") {
                    item.list = result.XFYJSB;
                    // this.drawMarkerBjsb(item.group, item.list, item.src);
                }
            });
        },
        drawMarkerBjsb(group, list, src) {
            list.map((item) => {
                if (item.AZWD) {
                    let icon = window.L.divIcon({
                        html: `<img style="width:32px;height:32px; filter: drop-shadow(2px 2px 5px rgba(7,29,41,0.5));" src=${src} />`,
                        iconSize: [32, 32],
                        iconAnchor: [16, 16],
                    });
                    let layer = window.L.marker([item.AZWD, item.AZJD], {
                        icon: icon,
                        data: item,
                    }).addTo(group);

                    layer.on("click", () => {});
                    layer.on("mouseover", () => {
                        this.markerPopup
                            .setLatLng([item.AZWD, item.AZJD])
                            .setContent(`<div class="tip">${item.SBMC}</div>`)
                            .openOn(this.myMap);
                    });
                    layer.on("mouseout", () => {
                        this.markerPopup.removeFrom(this.myMap);
                    });
                }
            });
        },

        // 历史火警列表
        async getHistoryHjList() {
            let result = await this.GetFireAlarmMap({});
            this.onOffListCom.map((item) => {
                if (item.name == "历史预警") {
                    item.mulList[0].list = result;
                    this.clearLayersGroup(item.mulList[0].group);
                    // this.drawMarkerHistoryHj(
                    //     item.mulList[0].group,
                    //     item.mulList[0].list
                    // );
                }
            });
        },
        // 历史火警
        drawMarkerHistoryHj(group, list) {
            let src = require("@image/bigScreen/monitor_all/marker/hjIcon.gif");
            list.map((item) => {
                let icon = window.L.divIcon({
                    html: `<img style="width:32px;height:32px; filter: drop-shadow(2px 2px 5px rgba(7,29,41,0.5));" src=${src} />`,
                    iconSize: [32, 32],
                    iconAnchor: [16, 16],
                });

                let layer = window.L.marker([item.FSWD, item.FSJD], {
                    icon: icon,
                    data: item,
                }).addTo(group);

                layer.on("click", () => {
                    this.commonDialog.dialog = "hjDialog";
                    this.commonDialog.title = `${item.FSDD}`;
                    this.commonDialog.detail = item;
                    this.$emit("getCommonDialog", this.commonDialog);
                });
                layer.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([item.FSWD, item.FSJD])
                        .setContent(`<div class="tip">${item.FSDD}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
            });
        },
        // 预警列表
        async getOthersYj() {
            let result = await this.GetWarnInfoList({
                ycysbm: this.jczlYcysItem ? this.jczlYcysItem.BM : "",
                ycysfl2: this.jczlYczcItem ? this.jczlYczcItem.YCYSFL_2 : "",
                pageIndex: 1,
                pageSize: 99999,
            });

            this.onOffListCom.map((item) => {
                if (item.name == "历史预警") {
                    let list = [];
                    result.DATA.map((item) => {
                        if (item.JD && item.WD) {
                            list.push(item);
                        }
                    });
                    item.mulList[1].list = list;
                    // this.drawMarkerOthersYj(item.mulList[1]);
                }
            });
        },
        // 预警
        drawMarkerOthersYj(obj) {
            let group = obj.group;
            let list = obj.list;
            this.othersYjCluster.group = new window.L.markerClusterGroup({
                maxClusterRadius: 30, //像素
                spiderfyOnMaxZoom: false,
                showCoverageOnHover: false,
                zoomToBoundsOnClick: false,
                iconCreateFunction: (cluster) => {
                    // let da = cluster.getAllChildMarkers();
                    let html2 =
                        "<div style='background-image: linear-gradient(180deg, #00a09e 0%, #00a09e 100%);color: #fff;border-radius: 50%;position: absolute;left: -16px;top: -16px;width: 32px;height: 32px;opacity: 0.8;font-size:16px;' class='flex-both-center'>" +
                        cluster.getChildCount();
                    ("</div>");
                    return window.L.divIcon({ html: html2 });
                },
            }).addTo(group);
            // 聚合移入
            this.othersYjCluster.group.on("clustermouseover", (e) => {
                this.clusterTimer = setTimeout(() => {
                    this.othersYjCluster.list = [];
                    e.layer.getAllChildMarkers().map((item) => {
                        let obj = item.options.data;
                        this.othersYjCluster.list.push(obj);
                    });
                    this.othersYjCluster.dialog
                        .setLatLng([e.layer._latlng.lat, e.layer._latlng.lng])
                        .openOn(this.myMap);
                }, 300);
            });
            // 聚合移出
            this.othersYjCluster.group.on("clustermouseout", () => {
                clearTimeout(this.clusterTimer);
            });

            list.map((item) => {
                let src = this.yjdjImgList[item.YJDJ].src;
                let icon = window.L.divIcon({
                    html: `<img style="width:32px;height:32px; filter: drop-shadow(2px 2px 5px rgba(7,29,41,0.5));" src=${src} />`,
                    iconSize: [32, 32],
                    iconAnchor: [16, 16],
                });

                let layer = window.L.marker([item.WD, item.JD], {
                    icon: icon,
                    data: item,
                }).addTo(this.othersYjCluster.group);

                layer.on("click", () => {
                    this.commonDialog.dialog = "othersDialog";
                    this.commonDialog.title = item.YJSJ;
                    this.commonDialog.detail = item;
                    this.$emit("getCommonDialog", this.commonDialog);
                });
                layer.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([item.WD, item.JD])
                        .setContent(`<div class="tip">${item.YJSJ}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
            });
        },
        // 消息推送的预警
        drawMarkerYjMsg(group, list) {
            list.map((item) => {
                let src = this.yjdjImgList[item.YJDJ].src;
                let icon = window.L.divIcon({
                    html: `<img style="width:32px;height:32px; filter: drop-shadow(2px 2px 5px rgba(7,29,41,0.5));" src=${src} />`,
                    iconSize: [32, 32],
                    iconAnchor: [16, 16],
                });

                let layer = window.L.marker([item.WD, item.JD], {
                    icon: icon,
                    data: item,
                }).addTo(group);

                layer.on("click", () => {
                    this.commonDialog.dialog = "othersDialog";
                    this.commonDialog.title = item.YJSJ;
                    this.commonDialog.detail = item;
                    this.$emit("getCommonDialog", this.commonDialog);
                });
                layer.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([item.WD, item.JD])
                        .setContent(`<div class="tip">${item.YJSJ}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
            });
        },
        getOthersYjDetail(item) {
            this.commonDialog.dialog = "othersDialog";
            this.commonDialog.title = item.YJSJ;
            this.commonDialog.detail = item;
            this.$emit("getCommonDialog", this.commonDialog);
            if (this.myMap.getZoom() < 21) {
                this.myMap.setView([item.BW, item.DJ], 16);
            }
            this.markerPopup
                .setLatLng([item.WD, item.JD])
                .setContent(`<div class="tip">${item.YJSJ}</div>`)
                .openOn(this.myMap);
        },
        closeClusterOthersYj() {
            this.othersYjCluster.dialog.removeFrom(this.myMap);
            this.othersYjCluster.list = [];
        },
        // 未解决的火警
        drawFireBurn(group, list) {
            let src = require("@image/bigScreen/monitor_all/marker/hjIcon.gif");
            list.map((item) => {
                let html = `<div style="position: absolute;
                                    top: 0;
                                    left: 50%;
                                    text-align:center;
                                    transform: translateX(-50%);">
                            <div
                             style="background: rgb(3, 23, 38);
                                    opacity: 0.8;
                                    border-radius: 5px;
                                    white-space:nowrap;
                                    padding:4px 10px;color:#fff;">
                              ${item.FSDD}
                            </div>
                    <img src=${src} style="margin-top:4px;filter: drop-shadow(2px 2px 5px rgba(7,29,41,0.5));"/>
                </div> `;
                let icon = window.L.divIcon({
                    html: html,
                    iconSize: [40, 52],
                    iconAnchor: [20, 84],
                    className: "",
                });

                let layer = window.L.marker([item.FSWD, item.FSJD], {
                    icon: icon,
                    data: item,
                }).addTo(group);

                layer.on("click", () => {
                    this.commonDialog.dialog = "hjDialog";
                    this.commonDialog.title = `${item.FSDD}`;
                    this.commonDialog.detail = item;
                    this.$emit("getCommonDialog", this.commonDialog);
                });
            });
        },
        // 地图复位
        reSetMap() {
            this.$refs.XzqhTree.reSet();
        },
        // 初始化状态
        reSetState() {
            this.onOffListCom.map((item) => {
                switch (item.name) {
                    case "遗产要素":
                        item.state = false;
                        this.clearLayersGroup(item.group);
                        break;
                }
            });
            if (this.markerPopup) {
                this.markerPopup.removeFrom(this.myMap);
            }
            this.gsmmCluster.list = [];
            this.othersYjCluster.list = [];
            this.ycysCluster.list = [];
        },
    },
};
</script>
<style scoped lang="scss">
.screenCenter {
    position: relative;
    width: 100%;
    height: 100%;
    border-bottom: 2px solid rgba(53, 80, 124, 1);
    .lineTop {
        position: absolute;
        left: 0px;
        top: -2px;
        width: 100%;
        .lineTop1 {
            width: 300px;
            height: 4px;
            background: rgba(53, 80, 124, 1);
        }
        .lineTop2 {
            width: 300px;
            height: 4px;
            background: rgba(255, 205, 128, 1);
            margin: 0 10px;
        }
        .lineTop3 {
            height: 4px;
            background: rgba(53, 80, 124, 1);
        }
    }
    .decorateBottom {
        position: absolute;
        right: 200px;
        bottom: -4px;
        z-index: 10;
        .decorateItem {
            width: 8px;
            height: 8px;
            border: 2px solid rgba(255, 205, 128, 1);
            border-radius: 50%;
            margin-right: 200px;
            &:nth-last-child(1) {
                margin-right: 0;
            }
        }
    }
    .ruler {
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 10;
        height: 100%;
    }
    .mapPart {
        width: 100%;
        height: 100%;
        position: relative;
    }
    .timePart {
        position: absolute;
        right: 11px;
        top: 74px;
        z-index: 10;
        width: 130px;
    }
    .xzqhTreePart {
        position: absolute;
        right: 14px;
        top: 70px;
        z-index: 10;
        width: 130px;
    }
    //图层控制
    .mapChangePart {
        position: absolute;
        top: 10px;
        left: 20px;
        z-index: 10;
        .layer {
            position: absolute;
            top: 0;
            left: 0;
            width: 130px;
            height: 40px;
            background: #1a3059;
            border: 1px solid #35507c;
            border-radius: 10px;
            box-shadow: 5px 9px 30px 0px rgba(0, 44, 75, 0.2);
            padding: 0 20px;

            .desc {
                color: #fff;
                font-size: 18px;
            }
        }

        .layerCtn {
            min-width: 130px;
            margin-top: 50px;
            padding: 10px;
            background: #1a3059;
            border-radius: 5px;
            .layerItem {
                margin-bottom: 8px;
                &:nth-last-child(1) {
                    margin-bottom: 0;
                }
            }
        }

        .name {
            color: #fff;
            font-size: 14px;
            margin-left: 10px;
        }
    }
    // 图例
    .legendPart {
        position: absolute;
        left: 20px;
        bottom: 10px;
        min-height: 56px;
        max-height: 100px;
        z-index: 10;
        .title {
            width: 20px;
            min-height: 56px;
            max-height: 100px;
            background: rgba(4, 28, 67, 0.8);
            font-weight: 400;
            font-size: 12px;
            color: #d8eaf6;
            text-align: center;
            padding-top: 5px;
        }
        .list {
            padding-left: 10px;
            background: rgba(4, 28, 67, 0.6);
        }
    }
    // 顶部echart
    .topEchartPart {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-55%);
        width: 1000px;
        height: 180px;
        .topEchartCtn {
            padding: 10px 0;
            width: 100%;
            height: 100%;
            background: rgba(41, 71, 93, 0.8);
        }
        .arraw {
            position: absolute;
            right: 4px;
            top: 4px;
            font-size: 24px;
            color: #fff;
            z-index: 10;
            cursor: pointer;
        }
    }
    // 聚合
    .clusterPart {
        width: 201px;
        position: relative;
        .closeBtn {
            width: 20px;
            height: 20px;
            font-size: 18px;
            color: #fff;
        }
        .title {
            font-size: 18px;
            color: #fff;
            text-align: right;
        }
        .clusterCtn {
            margin-top: 10px;
            max-height: 121px;
            width: 100%;
            .ycdtItem {
                color: rgba(126, 190, 235, 1);
                width: calc(100% - 3px);
                height: 40px;
                line-height: 40px;
                box-sizing: border-box;
                font-size: 14px;
                border-bottom: 1px solid rgba(186, 232, 240, 0.5);
                &:hover {
                    color: #fff;
                }
            }
        }
    }
    //右下角地图切换
    #mapType {
        position: absolute;
        right: 10px;
        bottom: 10px;
        height: 70px;
        width: 106px;
        z-index: 999;
        border-radius: 2px;
        cursor: pointer;
        transition: width 0.8s;
        background: rgba(255, 255, 255, 0.3);
        &:hover {
            width: 200px;
            .vectorType {
                right: 101px;
            }

            .imgType {
                right: 5px;
            }
        }
        .mapTypeCard {
            position: absolute;
            top: 5px;
            width: 86px;
            height: 60px;
            border-radius: 5px;
            border: solid 1px rgba(255, 255, 255, 0);
            span {
                position: absolute;
                bottom: -1px;
                left: -1px;
                width: 86px;
                display: inline-block;
                font-size: 12px;
                height: 16px;
                line-height: 16px;
                text-align: center;
                color: #fff;
                background: #888f88;
                opacity: 0.8;
            }
        }

        .vectorType {
            right: 15px;
            background: url("~@image/mapToolbar/mapbtn.png") no-repeat 0 0;
            z-index: 3;
            transition: right 0.8s;
            &:hover {
                border: solid 1px #31a5f7;
                span {
                    background: #31a5f7;
                }
            }
        }

        .imgType {
            right: 10px;
            background: url("~@image/mapToolbar/imagebtn.png") no-repeat 0 0;
            z-index: 2;
            transition: right 0.8s;
            &:hover {
                border: solid 1px #31a5f7;
                span {
                    background: #31a5f7;
                }
            }
        }

        .terType {
            right: 5px;
            background: url("~@image/mapToolbar/terbtn.png") no-repeat 0 0;
            z-index: 1;
            transition: right 0.8s;
            &:hover {
                border: solid 1px #31a5f7;
                span {
                    background: #31a5f7;
                }
            }
        }
        .active {
            border: solid 1px #31a5f7;
            span {
                background: #31a5f7;
            }
        }
    }
    // 预警
    .bellTipBtn {
        position: absolute;
        right: -1903px;
        // right: -2465px;
        top: 10px;
        z-index: 10;
        .num {
            width: 20px;
            height: 20px;
            line-height: 20px;
            background: red;
            border-radius: 50%;
            position: relative;
            top: -40px;
            right: -30px;
            color: #fff;
            text-align: center;
        }
        .bell {
            width: 30px;
            height: 30px;
        }
    }
    .yjMsgDialog {
        position: absolute;
        right: -1930px;
        top: -10px;
        width: 480px;
        height: 344px;
        z-index: 10;
    }
}
</style>
