<template>
    <div class="cqxxPart">
        <!-- 异常统计 -->
        <basic-module :text="common.getFyName(fany.cqxx)">
            <div slot="basic-module" class="cqxxCtn">
                <div class="cqxxCtn">
                    <TableList
                        :maxHeight="179"
                        :tableColums="tableColums"
                        :tableData="tableData"
                        @tableItemDetail="tableItemDetail"
                    ></TableList>
                    <!-- :state="state" -->
                    <!-- @loadData="loadData" -->
                </div>
            </div>
        </basic-module>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import TableList from "@views/big_screen/comp/TableList";
export default {
    components: {
        TableList,
    },
    name: "",
    data() {
        return {
            tableColums: [
                {
                    prop: "MC",
                    label: "茶企名称",
                    width: 160,
                },
                {
                    prop: "CYRYS",
                    label: "茶产业从业人员数量(人)",
                    width: 260,
                    align: "center",
                    headerAlign: "center",
                },
                {
                    prop: "LXR",
                    label: "负责人",
                    width: 80,
                },
                {
                    prop: "XZC",
                    label: "行政村小组",
                    width: 80,
                    align: "center",
                    headerAlign: "center",
                },
            ],
            tableData: [],
            state: true, //true 还有未加载的数据 false 已经加载完毕
            pageIndex: 1,
            pageSize: 4,
            totalNum: 0,
            sntqState: true,
        };
    },
    computed: {
        ...mapGetters({
            rcxcAreaItem: TYPES.rcxcAreaItem,
            rcxcPanelNum: TYPES.rcxcPanelNum,
            rxcxYclbItem: TYPES.rxcxYclbItem,
        }),
    },
    props: {},
    watch: {
        
    },
    mounted() {
        this.pageIndex = 1;
        this.tableData = [];
        this.getCqxxData();
    },
    methods: {
        ...mapActions([
            "GetTeaEnterpriseData",
        ]),
        ...mapMutations({
            
        }),
        // 获取 异常列表 数据
        // async getCqxxData() {
        //     let result = await this.GetTeaEnterpriseData({
        //         qyid: this.rcxcAreaItem ? this.rcxcAreaItem.ID : "",
        //         pageindex: this.pageIndex,
        //         pagesize: this.pageSize,
        //     });
        //     this.totalNum = result.TOTAL;
        //     this.tableData = [...this.tableData, ...result.DATA];
        //     if (this.tableData.length < this.totalNum) {
        //         this.state = true;
        //     } else {
        //         this.state = false;
        //     }
        // },
        //获取 茶企信息 列表
        async getCqxxData() {
            let result = await this.GetTeaEnterpriseData();
            if(result.ISSUCCESS) {
                this.tableData = result.RESULTVALUE;
            }
        },
        loadData() {
            if (this.state) {
                if (this.tableData.length < this.totalNum) {
                    this.pageIndex++;
                    this.getCqxxData();
                } else {
                    this.state = false;
                }
            }
        },
        tableItemDetail(item) {

        },
        handleClose() {

        },
    },
};
</script>
<style scoped lang="scss">
// 茶企信息
.cqxxPart {
    width: 100%;
    .cqxxCtn {
        width: 100%;
        height: 100%;
        .tableList {
            width: 100%;
            height: 40px;
            .tableItem {
                height: 100%;
                .name {
                    width: 102px;
                    height: 100%;
                    background: url("~@image/bigScreen/icon/titleBg.png")
                        no-repeat;
                    background-size: 100% 100%;
                    font-size: 18px;
                    color: #a8c8ff;
                    margin-right: 5px;
                }
                .numPart {
                    width: 104px;
                    height: 100%;
                    border: 1px solid rgba(53, 80, 124, 1);
                    .num {
                        font-size: 50px;
                        font-family: DBPM;
                    }
                    .dw {
                        color: rgba(214, 238, 255, 1);
                        font-size: 14px;
                        position: relative;
                        left: 4px;
                        top: 2px;
                    }
                }
            }
        }
        .cqxxCtn {
            min-height: 110px;
            margin-bottom: 10px;
        }
    }
}
</style>
