<template>
    <div class="followInfo">
        <!-- 跟踪信息 -->
        <basic-module :text="common.getFyName(fany.gzxx)">
            <div slot="basic-module" style="height: 100%; position: relative">
                <div class="condition" v-if="selectData.list.length > 0">
                    <SelectTimeTab
                        :selectData="selectData"
                        @getSelectItem="getSelectItem"
                    >
                    </SelectTimeTab>
                </div>
                <div v-if="listAry.length > 0">
                    <div class="followDetail flex-between">
                        <div class="photoPart">
                            <el-image
                                :src="common.getThumbnailImg(detail.src)"
                                @click="getImgDetail(detail.src)"
                                fit="cover"
                                style="width: 100%; height: 100%"
                                class="pointer"
                            >
                                <div
                                    slot="error"
                                    style="width: 100%; height: 100%"
                                >
                                    <SnNoData width="150px"></SnNoData>
                                </div>
                            </el-image>
                        </div>
                        <div class="listPart">
                            <div class="listItem">
                                <div class="name">现场情况:</div>
                                <div class="value ellipsis3">
                                    {{ detail.desc }}
                                </div>
                            </div>
                            <div class="listItem">
                                <div class="name">采集人:</div>
                                <div class="value ellipsis">
                                    {{ detail.cjrName }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="timePart pageNoBg" v-if="currentListAry.length">
                        <TimeLine
                            ref="timeLine"
                            :listAry="currentListAry"
                            @handleTimeLine="handleTimeLine"
                        ></TimeLine>

                        <el-pagination
                            small
                            class="pages"
                            @current-change="currentChange"
                            :current-page="page"
                            :page-size="pageSize"
                            :total="listAry.length"
                            layout="prev, pager, next"
                        >
                        </el-pagination>
                    </div>
                </div>
                <NoMonitor v-else text="暂无跟踪信息"></NoMonitor>
            </div>
        </basic-module>
        <ViewerAlert ref="myImgList" :imgList="imgList"></ViewerAlert>
    </div>
</template>

<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import SelectTimeTab from "@views/big_screen/comp/SelectTimeTab";
import TimeLine from "@views/big_screen/comp/TimeLine";
import NoMonitor from "@views/big_screen/comp/NoMonitor";
import ViewerAlert from "@comp/ViewerAlert.vue"; //大图查看

export default {
    components: {
        TimeLine,
        SelectTimeTab,
        NoMonitor,
        ViewerAlert,
    },
    data() {
        return {
            imgList: [],
            listAry: [],
            page: 1,
            pageSize: 5,
            key: "",
            selectData: {
                state: true,
                defaultValue: "",
                width: "200px",
                list: [],
                label: "JCWZMC",
                value: "ID",
                clearable: false,
            },
            detail: {
                src: "",
                desc: "",
                cjrName: "",
            },
        };
    },
    computed: {
        ...mapGetters(["bhgcItem", "bhgcJcdwItem"]),
        currentListAry: function () {
            const page = this.page;
            const pageSize = this.pageSize;
            const res = this.listAry.slice(
                (page - 1) * pageSize,
                page * pageSize
            );
            return res;
        },
    },
    watch: {
        bhgcItem: {
            immediate: true,
            handler() {
                this.selectData.list = this.bhgcItem.JCWZLIST;
                if (this.selectData.list.length) {
                    this.selectData.defaultValue = this.selectData.list[0].ID;
                    this.key = this.selectData.list[0].ID;
                    this.page = 1;
                    this.getFollowData();
                } else {
                    this.selectData.defaultValue = "";
                    this.detail.src = "";
                    this.detail.desc = "";
                    this.detail.cjrName = "";
                }
            },
        },
        bhgcJcdwItem: {
            immediate: false,
            handler() {
                this.selectData.defaultValue = this.bhgcJcdwItem.ID;
                this.key = this.bhgcJcdwItem.ID;
                this.page = 1;
                this.getFollowData();
            },
        },
    },
    mounted() {},
    methods: {
        ...mapActions(["getBhgcGzxx"]),
        ...mapMutations([]),
        selected(jcwzID) {
            this.jcwzID = jcwzID;
            this.getFollowData();
        },
        // 获取跟踪数据
        async getFollowData() {
            let result = await this.getBhgcGzxx({
                jcwzID: this.key,
            });
            this.listAry = result;
            if (this.listAry.length) {
                let obj = this.listAry[0];
                this.detail.src = obj.PIC.length ? obj.PIC[0].FJLJ : "";
                this.detail.desc = obj.XCQK;
                this.detail.cjrName = obj.CJRMC;
            } else {
                this.selectData.defaultValue = "";
                this.detail.src = "";
                this.detail.desc = "";
                this.detail.cjrName = "";
            }
        },
        getSelectItem(item) {
            this.key = item.ID;
            this.page = 1;
            this.getFollowData();
        },
        currentChange(page) {
            this.page = page;
        },
        handleTimeLine(item) {
            this.detail.src = item.PIC.length ? item.PIC[0].FJLJ : "";
            this.detail.desc = item.XCQK;
            this.detail.cjrName = item.CJRMC;
        },
        getImgDetail(src) {
            this.imgList = [
                {
                    alt: "",
                    src: src,
                    thumbnail: this.common.getThumbnailImg(src),
                },
            ];
            if (this.imgList.length) {
                this.$refs.myImgList.showViewer();
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.followInfo {
    width: 100%;
    height: calc(100% - 510px);
    .el-pagination.el-pagination--small {
        text-align: right;
        button,
        li {
            background: transparent;
        }
    }
    .condition {
        position: absolute;
        right: -15px;
        top: -44px;
    }
    .followDetail {
        .listPart {
            width: calc(50% - 5px);
            .listItem {
                width: 100%;
                flex: 1;
                .name {
                    height: 35px;
                    line-height: 35px;
                    text-align: left;
                    color: rgba(86, 115, 162, 1);
                    font-size: 16px;
                }
                .value {
                    height: 35px;
                    line-height: 35px;
                    text-align: left;
                    background: rgba(26, 48, 89, 0.3);
                    color: #d7eaf5;
                    padding-left: 10px;
                    font-size: 16px;
                }
                .check {
                    overflow: hidden;
                    line-height: 30px;
                    font-size: 14px;
                    height: 60px;
                }
            }
        }
        .photoPart {
            width: calc(50% - 5px);
            height: 217px;
        }
    }
    .timePart {
        margin-top: 5px;
        position: relative;
        .pages {
            position: absolute;
            right: 0;
            bottom: -10px;
        }
    }
}
</style>
