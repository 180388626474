<template>
    <div class="maskDialog" v-if="btbhBhjcShow != 0">
        <div class="flex-between maskTop">
            <el-select
                class="picSelectMask"
                size="mini"
                v-model="selectData.defaultValue"
                style="width: 280px"
                popper-class="common-element-select-panel"
                @change="getSelectItem"
            >
                <el-option
                    v-for="(item, index) in selectData.list"
                    :key="index"
                    :label="item.JCWZMC"
                    :value="item.JCWZID"
                >
                </el-option>
            </el-select>
            <img src="@image/bigScreen/ontological_disease/close.png" alt="关闭按钮" @click="closeMask()" class="close pointer" />
        </div>
        <div class="picBox">
            <div class="leftBox">
                <picBox :selectValue.sync="leftSelectValue" type="mapLeft" :basicData="basicData" @changeMap="changeMap"></picBox>
            </div>
            <div class="rightBox">
                <picBox :selectValue.sync="rightSelectValue" type="mapRight" :basicData="basicData" @changeMap="changeMap"></picBox>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import picBox from "../comp/bhkzPicBox";
export default {
    name: "page_right_btbh_bhkz",
    data() {
        return {
            leftSelectValue: 0,
            rightSelectValue: 1,
            selectData: {
                list: [],
                defaultValue: "",
            },
            basicData: null,
            monitorData: [],
            mapLeft: null,
            mapRight: null,
            mapImgActiveNum: 0
        };
    },
    components: {
        picBox,
    },
    computed: {
        ...mapGetters(["btbhBhjcShow", "btbhDetail", "btbhPanelNum"])
    },
    watch: {
        btbhBhjcShow: {
            immediate: true,
            handler() {
                if (this.btbhBhjcShow != 0) {
                    this.initPage();
                    this.mapAnimate();
                }
            }
        },
        btbhPanelNum: {
            immediate: true,
            handler() {
                if (this.btbhPanelNum != 2) {
                    this.setStateBtbh({
                        btbhBhjcShow: 0
                    });
                }
            }
        }
    },
    methods: {
        ...mapMutations({
            setStateBtbh: "SET_STATE_BTBH"
        }),
        ...mapActions([
            "getBhwzAndCjsj",
            "getBhJcsjData"
        ]),
        getSelectItem(item) {
            this.basicData = this.common.aryListFindOne(
                this.selectPart.list,
                "JCWZID",
                item
            );
        },
        initPage() {
            this.getBasicData();
        },
        async getBasicData() {
            let res = await this.getBhwzAndCjsj({
                bhid: this.btbhDetail.ID
            });
            this.selectData.list = res;
            this.selectData.defaultValue = res.length > 0 ? res[0].JCWZID : '';
            this.basicData = res.length > 0 ? res[0] : {};
        },
        closeMask() {
            this.setStateBtbh({
                btbhBhjcShow: 0
            });
        },
        changeMap(val, type) {
            this[type] = val;
            this.mapAnimate();
        },
        mapAnimate() {
            // 地图 放大 移动 联动
            this.mapLeft && this.mapLeft.on("mouseover", () => {
                this.mapImgActiveNum = 0;
            });

            this.mapRight && this.mapRight.on("mouseover", () => {
                this.mapImgActiveNum = 1;
            });

            this.mapLeft && this.mapLeft.on("moveend", () => {
                if (this.mapImgActiveNum === 0) {
                    let obj = this.mapLeft.getBounds();
                    let imageBounds = [
                        [obj._northEast.lat, obj._northEast.lng],
                        [obj._southWest.lat, obj._southWest.lng],
                    ];
                    this.mapRight && this.mapRight.fitBounds([imageBounds]);
                }
            });
            this.mapRight && this.mapRight.on("moveend", () => {
                if (this.mapImgActiveNum === 1) {
                    let obj = this.mapRight.getBounds();
                    let imageBounds = [
                        [obj._northEast.lat, obj._northEast.lng],
                        [obj._southWest.lat, obj._southWest.lng],
                    ];
                    this.mapLeft && this.mapLeft.fitBounds([imageBounds]);
                }
            });
        },
    },
};
</script>
<style lang="scss">
.picSelectMask {
    .el-input__inner {
        background-color: transparent;
        border-color:  #4580a0;
        color: #a8c8ff;
        font-size: 16px;
    }
}
</style>
<style scoped lang="scss">
.maskDialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 500;
    background: rgba(3, 23, 38, 0.8);
    box-shadow: 2px 2px 5px #031726;
    .maskTop {
        border-bottom: 1px solid #09466a;
        padding: 10px;
        align-items: center;
        .close {
            width: 20px;
            height: 20px;
        }
    }
    .picBox {
        width: 100%;
        height: calc(100% - 60px);
        margin-top: 5px;
        display: flex;
        >div {
            width: 50%;
            margin: 0 5px;
        }
    }
}
</style>
