<template>
    <div class="screenItem">
        <div class="condition flex-y-center">
            <!-- 同期对比 历年对比切换 -->
            <div class="type-btn">
                <SelectTimeTab :tabData="tabData" @getTabItem="getTabItem">
                </SelectTimeTab>
            </div>
            <div v-if="activeNum == 1" class="flex-y-center">
                <div class="label">历史同期对比</div>
                <!-- 温度和降雨量 -->
                <div>
                    <SelectTimeTab
                        :selectData="selectDataType"
                        @getSelectItem="changeType"
                    >
                    </SelectTimeTab>
                </div>
                <div class="time common-element-date">
                    <el-date-picker
                        v-model="startTime"
                        type="year"
                        size="mini"
                        value-format="yyyy"
                        format="yyyy"
                        placeholder="选择日期"
                        style="width: 90px"
                        :clearable="false"
                        popper-class="common-element-date-panel-opacity"
                        @change="getMainInfo()"
                    >
                    </el-date-picker>
                </div>
                <div class="time common-element-date">
                    <el-date-picker
                        v-model="endTime"
                        type="year"
                        size="mini"
                        value-format="yyyy"
                        format="yyyy"
                        placeholder="选择日期"
                        style="width: 90px"
                        :clearable="false"
                        popper-class="common-element-date-panel-opacity"
                        @change="getMainInfo()"
                    >
                    </el-date-picker>
                </div>
            </div>
        </div>
        <div class="echartPart">
            <img
                :src="require('@image/bigScreen/monitor_all/zrhj/cornor1.png')"
                class="img1"
            />
            <img
                :src="require('@image/bigScreen/monitor_all/zrhj/cornor2.png')"
                class="img2"
            />
            <img
                :src="require('@image/bigScreen/monitor_all/zrhj/cornor3.png')"
                class="img3"
            />
            <img
                :src="require('@image/bigScreen/monitor_all/zrhj/cornor4.png')"
                class="img4"
            />
            <div id="zrhjEchart" class="echart"></div>
            <NoMonitor v-if="listAry.length == 0" text="暂无数据"></NoMonitor>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import SelectTimeTab from "@views/big_screen/comp/SelectTimeTab";
import NoMonitor from "@views/big_screen/comp/NoMonitor";

export default {
    components: {
        SelectTimeTab,
        NoMonitor,
    },
    name: "",
    data() {
        return {
            startTime: String(
                this.common.getNowTime({
                    typeNum: "y",
                }) - 2
            ),
            endTime: String(
                this.common.getNowTime({
                    typeNum: "y",
                }) - 1
            ),
            activeNum: 1,
            selectDataType: {
                state: true,
                defaultValue: 2, //默认值
                clearable: false,
                width: "100px",
                list: [
                    {
                        id: 1,
                        name: "降雨量",
                        dw: "ml",
                    },
                    {
                        id: 2,
                        name: "温度",
                        dw: "℃",
                    },
                ],
                label: "name",
                value: "id",
                result: 2,
            },
            tabData: {
                list: [
                    {
                        id: 1,
                        name: "同期对比",
                    },
                    {
                        id: 2,
                        name: "历年对比",
                    },
                ],
                value: "name",
            },
            echart3D: null,
            listAry: [],
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {},
    watch: {},
    mounted() {
        this.getMainInfo();
    },
    methods: {
        ...mapActions(["GetEnvironmentalMonitors"]),
        ...mapMutations({}),
        // 获取 天气预警
        async getMainInfo() {
            let para = {};
            if (this.activeNum == 1) {
                para = {
                    startTime: this.startTime,
                    endTime: this.endTime,
                    type: this.selectDataType.result,
                };
            }
            let result = await this.GetEnvironmentalMonitors(para);
            this.listAry = result;
            if (this.activeNum == 1) {
                this.initEchart1(result);
            } else {
                this.initEchart2(result);
            }
        },
        // 温度和降雨量
        changeType(item) {
            this.selectDataType.result = item.id;
            this.selectDataType.defaultValue = item.id;
            this.getMainInfo();
        },
        // 同期对比 历年对比切换
        getTabItem(item, index) {
            this.activeNum = item.id;
            this.getMainInfo();
        },
        initEchart2(list) {
            let colorList = ["rgba(245,110,43,1)", "rgba(66,205,235,1)"];
            let xAxisData = [];
            let seriesData = [];
            let seriesTemprature = {
                name: `温度`,
                type: "line",
                smooth: true,
                showSymbol: true,
                symbol: "circle",
                symbolSize: 5,
                areaStyle: {
                    color: new this.$echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                            {
                                offset: 0,
                                color: "rgba(245,110,43,0.8)",
                            },
                            {
                                offset: 1,
                                color: "rgba(245,110,43,0)",
                            },
                        ],
                        false
                    ),
                },
                data: [],
                markPoint: {
                    data: [
                        { type: "max", name: "最大值", symbolSize: 40 },
                        { type: "min", name: "最小值", symbolSize: 40 },
                    ],
                },
            };
            let seriesRain = {
                name: `降雨量`,
                position: "right",
                yAxisIndex: 1,
                type: "line",
                smooth: true,
                showSymbol: true,
                symbol: "circle",
                symbolSize: 5,
                areaStyle: {
                    color: new this.$echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                            {
                                offset: 0,
                                color: "rgba(66,205,235,0.8)",
                            },
                            {
                                offset: 1,
                                color: "rgba(66,205,235,0)",
                            },
                        ],
                        false
                    ),
                },
                data: [],
                markPoint: {
                    data: [
                        { type: "max", name: "最大值", symbolSize: 40 },
                        { type: "min", name: "最小值", symbolSize: 40 },
                    ],
                },
            };

            list.map((item) => {
                xAxisData.push(item.NF);
                //温度
                seriesTemprature.data.push(item.QW);
                // 降雨量
                seriesRain.data.push(item.JYL);
            });

            seriesData = [seriesTemprature, seriesRain];
            let _this = this;
            let option = {
                color: colorList,
                tooltip: {
                    trigger: "axis",
                    padding: [2, 10],
                    textStyle: {
                        fontSize: 16,
                        align: "left",
                    },
                    axisPointer: {
                        lineStyle: {
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "rgba(255, 255, 255,1)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                ],
                                global: false,
                            },
                        },
                    },
                    formatter(para) {
                        let str = `${para[0].axisValue}年<br>`;
                        para.map((item, index) => {
                            let dw = _this.selectDataType.list[index].dw;
                            str += `${item.marker} ${item.seriesName} : ${item.value} ${dw} <br>`;
                        });
                        return str;
                    },
                },
                legend: {
                    icon: "rect",
                    itemWidth: 14,
                    itemHeight: 5,
                    itemGap: 13,
                    textStyle: {
                        color: "rgba(196,214,225,1)",
                    },
                    top: "5%",
                },
                grid: {
                    top: 60,
                    bottom: 30,
                    left: 50,
                    right: 80,
                },
                xAxis: [
                    {
                        name: "",
                        nameTextStyle: {
                            fontSize: 12,
                            color: "#9AB4E3",
                        },
                        boundaryGap: false, // 默认，坐标轴留白策略
                        type: "category",
                        axisLabel: {
                            // interval: 0,
                            // rotate: 45,
                            textStyle: {
                                color: "#A8C8FF",
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#0f5378",
                            },
                        },
                        splitArea: {
                            color: "#f00",
                            lineStyle: {
                                color: "#e2e9ff",
                            },
                        },
                        data: xAxisData,
                    },
                ],
                yAxis: [
                    {
                        name: "°C",
                        nameTextStyle: {
                            fontSize: 12,
                            color: "#9AB4E3",
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "rgba(53,80,124,0.2)",
                            },
                        },
                        axisLabel: {
                            margin: 20,
                            fontSize: 12,
                            textStyle: {
                                color: "#9AB4E3",
                                // color: "rgba(53,80,124,1)",
                            },
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(53,80,124,0.2)",
                                width: 2,
                            },
                        },
                    },
                    {
                        name: "ml",
                        position: "right",
                        nameTextStyle: {
                            fontSize: 12,
                            color: "#9AB4E3",
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "rgba(53,80,124,0.2)",
                            },
                        },
                        axisLabel: {
                            margin: 20,
                            fontSize: 12,
                            textStyle: {
                                color: "#9AB4E3",
                                // color: "rgba(53,80,124,1)",
                            },
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(53,80,124,0.2)",
                                width: 2,
                            },
                        },
                    },
                ],
                series: seriesData,
            };
            this.echart3D = this.$echarts.dispose(
                document.getElementById("zrhjEchart")
            );
            this.echart3D = this.$echarts.init(
                document.getElementById("zrhjEchart")
            );
            this.echart3D.setOption(option);
        },
        initEchart1(list) {
            let dw = "";
            let name = "";
            if (this.selectDataType.result == 2) {
                dw = "°C";
                name = "温度";
            } else {
                name = "降雨量";
                dw = "ml";
            }

            let xAxisData = [];
            let seriesData = [];
            let seriesTemprature = {
                name: this.startTime + name,
                type: "line",
                smooth: true,
                showSymbol: true,
                symbol: "circle",
                symbolSize: 5,
                areaStyle: {
                    color: new this.$echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                            {
                                offset: 0,
                                color: "rgba(245,110,43,0.8)",
                            },
                            {
                                offset: 1,
                                color: "rgba(245,110,43,0)",
                            },
                        ],
                        false
                    ),
                },
                data: [],
                markPoint: {
                    data: [
                        { type: "max", name: "最大值", symbolSize: 40 },
                        { type: "min", name: "最小值", symbolSize: 40 },
                    ],
                },
            };
            let seriesRain = {
                name: this.endTime + name,
                type: "line",
                smooth: true,
                showSymbol: true,
                symbol: "circle",
                symbolSize: 5,
                areaStyle: {
                    color: new this.$echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                            {
                                offset: 0,
                                color: "rgba(66,205,235,0.8)",
                            },
                            {
                                offset: 1,
                                color: "rgba(66,205,235,0)",
                            },
                        ],
                        false
                    ),
                },
                data: [],
                markPoint: {
                    data: [
                        { type: "max", name: "最大值", symbolSize: 40 },
                        { type: "min", name: "最小值", symbolSize: 40 },
                    ],
                },
            };

            list[0].DATA.map((item) => {
                seriesTemprature.data.push(item.VALUE);
                xAxisData.push(`${item.YF}`);
            });

            list[1].DATA.map((item) => {
                seriesRain.data.push(item.VALUE);
            });
            seriesData = [seriesTemprature, seriesRain];
            let _this = this;
            let option = {
                tooltip: {
                    trigger: "axis",
                    padding: [2, 10],
                    textStyle: {
                        fontSize: 16,
                        align: "left",
                    },
                    axisPointer: {
                        lineStyle: {
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "rgba(255, 255, 255,1)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                ],
                                global: false,
                            },
                        },
                    },
                    formatter(para) {
                        let str = `${para[0].seriesName.slice(4)}<br>`;
                        para.map((item, index) => {
                            if (index == 0) {
                                str += `${item.marker} ${_this.startTime}年 ${item.axisValue}月 : ${item.value} ${dw} <br>`;
                            } else {
                                str += `${item.marker} ${_this.endTime}年 ${item.axisValue}月 : ${item.value} ${dw} <br>`;
                            }
                        });
                        return str;
                    },
                },
                color: ["rgba(245,110,43,1)", "rgba(66,205,235,1)"],
                legend: {
                    icon: "rect",
                    itemWidth: 14,
                    itemHeight: 5,
                    itemGap: 13,
                    textStyle: {
                        color: "rgba(196,214,225,1)",
                    },
                    top: "5%",
                },
                grid: {
                    top: 60,
                    bottom: 30,
                    left: 50,
                    right: 50,
                },
                xAxis: [
                    {
                        name: "月",
                        nameTextStyle: {
                            fontSize: 12,
                            color: "#9AB4E3",
                        },
                        boundaryGap: false, // 默认，坐标轴留白策略
                        type: "category",
                        axisLabel: {
                            // interval: 0,
                            // rotate: 45,
                            textStyle: {
                                color: "#A8C8FF",
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#0f5378",
                            },
                        },
                        splitArea: {
                            color: "#f00",
                            lineStyle: {
                                color: "#e2e9ff",
                            },
                        },
                        data: xAxisData,
                    },
                ],
                yAxis: {
                    name: dw,
                    nameTextStyle: {
                        fontSize: 12,
                        color: "#9AB4E3",
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(53,80,124,0.2)",
                        },
                    },
                    axisLabel: {
                        margin: 20,
                        fontSize: 12,
                        textStyle: {
                            color: "#9AB4E3",
                        },
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: "rgba(53,80,124,0.2)",
                            width: 2,
                        },
                    },
                },
                series: seriesData,
            };

            this.echart3D = this.$echarts.dispose(
                document.getElementById("zrhjEchart")
            );
            this.echart3D = this.$echarts.init(
                document.getElementById("zrhjEchart")
            );
            if (this.listAry.length) {
                this.echart3D.setOption(option);
            }
        },
    },
};
</script>
<style scoped lang="scss">
.screenItem {
    position: relative;
    width: 100%;
    height: 100%;
    .condition {
        .type-btn {
            margin-right: 10px;
        }
        .label {
            margin-right: 10px;
            color: #bdd7ec;
        }
        .time {
            margin-right: 10px;
        }
    }
    .echartPart {
        width: 100%;
        height: calc(100% - 10px);
        position: relative;
        .echart {
            width: calc(100% - 40px);
            height: calc(100% - 40px);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: rgba(53, 80, 124, 0.4);
        }
        .img1 {
            position: absolute;
            top: 10px;
            left: 2px;
            z-index: 1;
        }
        .img2 {
            position: absolute;
            top: 10px;
            right: 2px;
            z-index: 1;
        }
        .img3 {
            position: absolute;
            bottom: 10px;
            left: 2px;
            z-index: 1;
        }
        .img4 {
            position: absolute;
            bottom: 10px;
            right: 2px;
            z-index: 1;
        }
    }
}
</style>
