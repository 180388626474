<template>
    <div class="main-part">
        <div class="selectTimePart common-element-select">
            <el-select
                v-model="timePart.id"
                placeholder="请选择时间"
                popper-class="common-element-select-panel"
                clearable
                @change="getDetailData()"
                filterable
                size="mini"
                style="width: 140px"
            >
                <el-option
                    v-for="(item, index) in timePart.list"
                    :key="index"
                    :label="item.DCSJ"
                    :value="item.ID"
                >
                </el-option>
            </el-select>
        </div>
        <div class="dialogCtn scrollbar-translucent">
            <div class="headBox">
                <div class="basicInfo">
                    <div class="infoItem">
                        <div class="name">行政村:</div>
                        <div class="value">
                            {{ detailData.XZC }}
                        </div>
                    </div>
                    <div class="infoItem">
                        <div class="name">村民小组:</div>
                        <div class="value">{{ detailData.XZZ }}</div>
                    </div>
                    <div class="infoItem">
                        <div class="name">民居编号:</div>
                        <div class="value">{{ detailData.MJBH }}</div>
                    </div>
                </div>
                <div class="time">
                    <div class="name">监测日期:</div>
                    <!-- <div class="value" style="margin-left: 15px">
                        {{ time }}
                    </div> -->
                </div>
            </div>
            <div class="tableBox scrollbar-hidden">
                <table border="1" bordercolor="#9ab4e3">
                    <tr>
                        <td rowspan="8" class="name">基本信息</td>
                        <td class="itemLable">户主姓名</td>
                        <td class="itemValue">
                            {{ detailData.HZXM }}
                        </td>
                        <td class="itemLable">身份证号码</td>
                        <td class="itemValue">
                            {{ common.hidePhoneNum(detailData.SFZH) }}
                        </td>
                    </tr>
                    <tr>
                        <td class="itemLable">开户银行</td>
                        <td class="itemValue">
                            {{ detailData.KHYH }}
                        </td>
                        <td class="itemLable">银行卡号</td>
                        <td class="itemValue">
                            {{ common.hidePhoneNum(detailData.YHKH) }}
                        </td>
                    </tr>
                    <tr>
                        <td class="itemLable">户籍人口</td>
                        <td class="itemValue">
                            {{ detailData.JTRS }}
                        </td>
                        <td class="itemLable">常住人口</td>
                        <td class="itemValue">
                            {{ detailData.CZRS }}
                        </td>
                    </tr>
                    <tr>
                        <td class="itemLable">联系电话</td>
                        <td class="itemValue" style="border-right: none">
                            {{ common.hidePhoneNum(detailData.LXDH) }}
                        </td>
                    </tr>
                    <tr>
                        <td class="itemLable">上次修缮时间</td>
                        <td class="itemValue">
                            {{ detailData.SCXSSJ }}
                        </td>
                        <td class="itemLable">建房时间</td>
                        <td class="itemValue">
                            {{ detailData.JFSJ }}
                        </td>
                    </tr>
                    <tr>
                        <td class="itemLable">层数</td>
                        <td class="itemValue">
                            {{ detailData.CS }}
                        </td>
                        <td class="itemLable">屋脊高度</td>
                        <td class="itemValue">
                            {{ detailData.WJGD ? `${detailData.WJGD}m` : "" }}
                        </td>
                    </tr>
                    <tr>
                        <td class="itemLable">宅基地面积</td>
                        <td class="itemValue">
                            {{
                                detailData.ZJDMJ ? `${detailData.ZJDMJ}m²` : ""
                            }}
                        </td>
                        <td class="itemLable">建筑面积</td>
                        <td class="itemValue">
                            {{ detailData.JZMJ ? `${detailData.JZMJ}m²` : "" }}
                        </td>
                    </tr>
                    <tr>
                        <td class="itemLable">门牌号</td>
                        <td class="itemValue">
                            {{ detailData.MPH }}
                        </td>
                        <td class="itemLable">房屋类型</td>
                        <td class="itemValue">
                            <div class="flex-between-y-center">
                                <div
                                    v-for="(item, index) in buildList"
                                    :key="index"
                                    :style="{
                                        color:
                                            item.CODE == detailData.FWLX
                                                ? '#ffcd80'
                                                : '',
                                    }"
                                >
                                    {{ item.LABEL }}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="itemLable">位置</td>
                        <td colspan="3">
                            <div class="locationBox">
                                <div class="locationItem">
                                    <div class="locationname">经度:</div>
                                    <div class="locationvalue">
                                        {{ detailData.JD }}
                                    </div>
                                </div>
                                <div class="locationItem">
                                    <div class="locationname">纬度:</div>
                                    <div class="locationvalue">
                                        {{ detailData.WD }}
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="name">现状监测情况</td>
                        <td colspan="4">
                            <div class="photoBox">
                                <div class="photoRow">
                                    <div class="photoItem">
                                        <div class="label">室外图片</div>
                                        <div
                                            class="imgList flex-wrap scrollbar"
                                        >
                                            <el-image
                                                v-for="(item,
                                                index) in photoList.roomOutList"
                                                :key="index"
                                                @click="
                                                    getImgList(
                                                        index,
                                                        photoList.roomOutList
                                                    )
                                                "
                                                :src="
                                                    common.getThumbnailImg(
                                                        item.url
                                                    )
                                                "
                                                fit="cover"
                                                class="pointer img"
                                            >
                                                <div
                                                    slot="error"
                                                    style="
                                                        width: 100%;
                                                        height: 100%;
                                                    "
                                                >
                                                    <SnNoData
                                                        width="80px"
                                                    ></SnNoData>
                                                </div>
                                            </el-image>
                                        </div>
                                    </div>
                                    <div class="photoItem">
                                        <div class="label">室内图片</div>
                                        <div
                                            class="imgList flex-wrap scrollbar"
                                        >
                                            <el-image
                                                v-for="(item,
                                                index) in photoList.roomInList"
                                                :key="index"
                                                @click="
                                                    getImgList(
                                                        index,
                                                        photoList.roomInList
                                                    )
                                                "
                                                :src="
                                                    common.getThumbnailImg(
                                                        item.url
                                                    )
                                                "
                                                fit="cover"
                                                class="pointer img"
                                            >
                                                <div
                                                    slot="error"
                                                    style="
                                                        width: 100%;
                                                        height: 100%;
                                                    "
                                                >
                                                    <SnNoData
                                                        width="80px"
                                                    ></SnNoData>
                                                </div>
                                            </el-image>
                                        </div>
                                    </div>
                                </div>
                                <div class="photoRow">
                                    <div class="photoItem">
                                        <div class="label">局部图片</div>
                                        <div
                                            class="imgList flex-wrap scrollbar"
                                            style="width: 680px"
                                        >
                                            <el-image
                                                v-for="(item,
                                                index) in photoList.partList"
                                                :key="index"
                                                @click="
                                                    getImgList(
                                                        index,
                                                        photoList.partList
                                                    )
                                                "
                                                :src="
                                                    common.getThumbnailImg(
                                                        item.url
                                                    )
                                                "
                                                fit="cover"
                                                class="pointer img"
                                            >
                                                <div
                                                    slot="error"
                                                    style="
                                                        width: 100%;
                                                        height: 100%;
                                                    "
                                                >
                                                    <SnNoData
                                                        width="80px"
                                                    ></SnNoData>
                                                </div>
                                            </el-image>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="name">监测图纸</td>
                        <td colspan="4">
                            <div class="monitorPhotoBox flex-between">
                                <div
                                    class="monitorPhotoItem"
                                    v-for="(v, i) in monitorPhotoList"
                                    :key="i"
                                >
                                    <div class="tag">{{ v.label }}</div>
                                    <el-image
                                        class="img"
                                        fit="cover"
                                        :src="v.FJLJ"
                                        v-viewer
                                        :alt="v.label"
                                    >
                                        <div
                                            slot="error"
                                            style="width: 100%; height: 100%"
                                        >
                                            <SnNoData width="80px"></SnNoData>
                                        </div>
                                    </el-image>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr style="height: 40px">
                        <td class="name">保护现状</td>
                        <td colspan="4">
                            <div
                                class="flex-between-y-center"
                                style="padding: 0 4px"
                            >
                                <div
                                    v-for="(item, index) in protectList"
                                    :key="index"
                                    :style="{
                                        color: item.state ? '#ffcd80' : '',
                                    }"
                                >
                                    {{ item.LABEL }}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="name">综合评估</td>
                        <td colspan="4">
                            <div class="flex-y-center" style="padding: 0 4px">
                                <div
                                    v-for="(item, index) in pgList"
                                    :key="index"
                                    style="margin-right: 10px"
                                    :style="{
                                        color:
                                            item.CODE == detailData.ZHPG
                                                ? '#ffcd80'
                                                : '',
                                    }"
                                >
                                    {{ item.LABEL }}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <!-- <tr>
                    <td class="name">问题描述</td>
                    <td
                        colspan="4"
                        class="desc ellipsis pointer"
                        :title="detailData.WTMS"
                    >
                        {{ detailData.WTMS }}
                    </td>
                </tr>
                <tr>
                    <td class="name">群众诉求</td>
                    <td
                        colspan="4"
                        class="desc ellipsis pointer"
                        :title="detailData.QZSQ"
                    >
                        {{ detailData.QZSQ }}
                    </td>
                </tr>
                <tr>
                    <td class="name">处理意见及建议</td>
                    <td
                        colspan="4"
                        class="desc ellipsis pointer"
                        :title="detailData.CLJYJJY"
                    >
                        {{ detailData.CLJYJJY }}
                    </td>
                </tr>
                <tr>
                    <td class="name">处理结果</td>
                    <td
                        colspan="4"
                        class="desc ellipsis pointer"
                        :title="detailData.CLJG"
                    >
                        {{ detailData.CLJG }}
                    </td>
                </tr>
                <tr>
                    <td class="name">备注</td>
                    <td
                        colspan="4"
                        class="desc ellipsis pointer"
                        :title="detailData.BZ"
                    >
                        {{ detailData.BZ }}
                    </td>
                </tr> -->
                    <tr class="InfoBox">
                        <td class="name">监测员</td>
                        <td colspan="4">
                            <div class="monitorBox">
                                <div class="monitorValue">
                                    {{ detailData.DCRXM }}
                                </div>
                                <div class="tel">电话</div>
                                <div class="monitorValue">
                                    {{
                                        common.hidePhoneNum(detailData.JCYLXDH)
                                    }}
                                </div>
                                <div class="people">抽查员</div>
                                <div class="monitorValue">
                                    {{ detailData.CCRXM }}
                                </div>
                                <div class="tel">电话</div>
                                <div class="monitorValue">
                                    {{
                                        common.hidePhoneNum(detailData.CCYLXDH)
                                    }}
                                </div>
                                <div class="people">交办人</div>
                                <div class="monitorValue">
                                    {{ detailData.JBRMC }}
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <ViewerAlert ref="myImgList" :imgList="imgList"></ViewerAlert>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import ViewerAlert from "@comp/ViewerAlert.vue"; //大图查看

export default {
    components: {
        ViewerAlert,
    },
    name: "",
    data() {
        return {
            imgList: [],
            timePart: {
                id: "",
                list: [],
            },
            detailData: {
                XZC: "", // 行政村
                XZZ: "", // 行政组
                MJBH: "", // 民居编号
                DCSJ: "", // 调查时间
                HZXM: "", // 户主姓名
                KHYH: "", // 开户银行
                SFZH: "", // 身份证号
                JTRS: "", // 家庭人数
                YHKH: "", // 银行卡号
                LXDH: "", // 住户联系电话
                CZRS: "", // 常住人口
                JFSJ: "", // 建房时间
                SCXSSJ: "", // 上次修缮时间
                CS: "", // 层数
                WJGD: "", // 屋脊高度(m)
                ZJDMJ: "", // 宅基地面积(m2)
                JZMJ: "", // 建筑面积(m2)
                MPH: "", // 门牌号
                FWLX: "", // 房屋类型
                WTMS: "", // 问题描述
                QZSQ: "", // 群众诉求
                CLJYJJY: "", // 处理意见及建议
                CLJG: "", // 处理结果
                BZ: "", // 备注
                JD: "", // 经度
                WD: "", // 纬度
                BHXZ: "", // 保护现状
                ZHPG: "", // 综合评估
                DCRXM: "", // 监测人姓名
                CCRXM: "", // 抽查人姓名
                JCYLXDH: "", // 监测员联系电话
                CCYLXDH: "", // 抽查员联系电话
                DCSJ: "", // 调查时间
                JBRMC: "", // 交办人
            },
            time: "",
            buildList: [],
            pgList: [],
            protectList: [],
            photoList: {
                roomInList: [],
                roomOutList: [],
                partList: [],
            },
            sfzOk: true, // 验证身份证
            yhkhOk: true, // 验证银行卡号
            lxdhOk: true, // 验证联系电话
            jcyLxdhOk: true, // 验证监测员联系电话
            ccyLxdhOk: true, // 验证抽查员联系电话
            saveZt: [],
            // 监测图纸栏，包括5张图纸，一般是4张，房屋位置图、一层平面图、二层平面图、剖面图、屋顶俯看图
            monitorPhotoList: {
                roomWzt: {
                    FJLJ: "",
                    label: "房屋位置图",
                    ID: "",
                    LB: "1",
                },
                roomYcpmt: {
                    FJLJ: "",
                    label: "一层平面图",
                    ID: "",
                    LB: "2",
                },
                roomEcpmt: {
                    FJLJ: "",
                    label: "二层平面图",
                    ID: "",
                    LB: "3",
                },
                roomPmt: {
                    FJLJ: "",
                    label: "剖面图",
                    ID: "",
                    LB: "4",
                },
                roomWdfkt: {
                    FJLJ: "",
                    label: "屋顶俯看图",
                    ID: "",
                    LB: "5",
                },
            },
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {
        detail: {
            Type: Object,
            default: () => {
                let obj = {
                    MC: "", //名称
                    DCQK: "",
                };
                return obj;
            },
        },
    },
    watch: {
        detail: {
            immediate: true,
            deep: true,
            handler() {
                this.getTimeList();
            },
        },
    },
    mounted() {
        this.getEnumData();
    },
    methods: {
        ...mapActions([
            "GetJmdcDetail",
            "GetAllEnumInfobytableID",
            "GetMjDcsjList",
        ]),
        ...mapMutations({}),
        // 获得详情
        async getTimeList() {
            // mjbh: "WW20", 有数据
            this.timePart.id = "";
            let res = await this.GetMjDcsjList({
                mjbh: this.detail.MC,
            });
            if (res.ISSUCCESS) {
                this.timePart.list = res.RESULTVALUE;
                if (this.timePart.list.length) {
                    this.timePart.id = res.RESULTVALUE[0].ID;
                }
                this.getDetailData();
            }
        },
        // 获得详情
        async getDetailData() {
            let id = this.timePart.id ? this.timePart.id : this.detail.DCQK;
            let res = await this.GetJmdcDetail({ id: id });
            if (res.ISSUCCESS) {
                this.detailData = res.RESULTVALUE;
                if (this.detailData.FWLX) {
                    this.detailData.FWLX = String(this.detailData.FWLX);
                }

                if (this.detailData.ZHPG) {
                    this.detailData.ZHPG = String(this.detailData.ZHPG);
                }
                if (this.detailData.DCSJ) {
                    this.time = this.getTimeYmd(this.detailData.DCSJ);
                }
                // 保护现状
                if (this.detailData.BHXZ) {
                    this.saveZt = this.detailData.BHXZ.split(",");
                    this.protectList.map((item) => {
                        if (this.saveZt.includes(item.CODE)) {
                            item.state = true;
                        }
                    });
                }
                if (
                    this.detailData.FILELIST &&
                    this.detailData.FILELIST.length
                ) {
                    let roomInList = [];
                    let roomOutList = [];
                    let partList = [];
                    // for (let i = 0; i < 20; i++) {
                    //     this.detailData.FILELIST.push({
                    //         FJDX: null,
                    //         FJLJ: "",
                    //         FJLX: "jpeg",
                    //         FJMC: "IMG_121517863.jpeg",
                    //         ID: "2f471da1-b9ab-41a4-b534-f8c83de4e643",
                    //         LB: 1,
                    //         PX: 0,
                    //         RKSJ: "2021-01-14 19:07:58",
                    //         SJID: "919db8d8-4408-11eb-9f70-000c2977b7fd",
                    //         TABLECODE: null,
                    //     });
                    // }
                    this.detailData.FILELIST.forEach((item) => {
                        let obj = {
                            ID: item.ID,
                            url: item.FJLJ,
                            name: item.FJMC,
                        };
                        switch (item.LB) {
                            case 1:
                                roomOutList.push(obj);
                                break;
                            case 2:
                                roomInList.push(obj);
                                break;
                            case 3:
                            case 4:
                                partList.push(obj);
                                break;
                        }
                    });
                    this.photoList.roomInList = roomInList;
                    this.photoList.roomOutList = roomOutList;
                    this.photoList.partList = partList;
                }
                if (this.detailData.TZLIST && this.detailData.TZLIST.length) {
                    this.detailData.TZLIST.forEach((v) => {
                        // 1 房屋位置图
                        if (v.LB == "1") {
                            $.extend(this.monitorPhotoList.roomWzt, v);
                        }
                        // 2 一层平面图
                        if (v.LB == "2") {
                            $.extend(this.monitorPhotoList.roomYcpmt, v);
                        }
                        // 3 二层平面图
                        if (v.LB == "3") {
                            $.extend(this.monitorPhotoList.roomEcpmt, v);
                        }
                        // 4 剖面图
                        if (v.LB == "4") {
                            $.extend(this.monitorPhotoList.roomPmt, v);
                        }
                        // 5 屋顶俯看图
                        if (v.LB == "5") {
                            $.extend(this.monitorPhotoList.roomWdfkt, v);
                        }
                    });
                }
                // this.detailData.CLJYJJY = "111111111111111";
                // this.detailData.CLJG = "111111111111111";
                // this.detailData.BZ = "111111111111111";

                // this.detailData.JCYLXDH = "18132666666";
                // this.detailData.CCYLXDH = "18132666666";
                // this.detailData.JBRMC = "李四";
                // this.detailData.CCRXM = "李四";
            }
        },
        // 获取时间 年月日
        getTimeYmd(value) {
            let date = new Date(value);
            let data_year = date.getFullYear();
            let data_month;
            let data_day;

            if (date.getMonth() + 1 < 10) {
                data_month = "0" + (date.getMonth() + 1);
            } else {
                data_month = date.getMonth() + 1;
            }

            if (date.getDate() + 1 <= 10) {
                data_day = "0" + date.getDate();
            } else {
                data_day = date.getDate();
            }
            return data_year + "年" + data_month + "月" + data_day + "日";
        },
        // 获取枚举值
        async getEnumData() {
            let result = await this.GetAllEnumInfobytableID({
                bid: "jmdcqkb",
            });
            result.RESULTVALUE.map((item) => {
                if (item.COLUMNID == "BHXZ") {
                    this.protectList = item.DATAITEMS;
                }
                if (item.COLUMNID == "FWLX") {
                    this.buildList = item.DATAITEMS;
                    this.protectList.map((item) => {
                        if (this.saveZt.includes(item.CODE)) {
                            item.state = true;
                        }
                    });
                }
                if (item.COLUMNID == "ZHPG") {
                    this.pgList = item.DATAITEMS;
                }
            });
        },
        //日常点位里的 图片 或者视频
        getImgList(index, list) {
            let ary = [];
            list.map((item) => {
                let obj = {
                    alt: "",
                    src: item.url,
                    thumbnail: this.common.getThumbnailImg(item.url),
                };
                ary.push(obj);
            });
            this.imgList = Array.from(ary);
            if (this.imgList.length) {
                this.$refs.myImgList.showViewer(index);
            }
        },
    },
};
</script>
<style scoped lang="scss">
.main-part {
    width: 100%;
    height: 100%;
    position: relative;
    .selectTimePart {
        position: absolute;
        right: 40px;
        top: 10px;
        z-index: 1000;
    }
}

$colorMain: #9ab4e3;
.dialogCtn {
    width: 100%;
    height: 100%;
    color: $colorMain;
    .headBox {
        display: flex;
        justify-content: space-between;
        margin: 0 20px;
        height: 40px;
        line-height: 40px;
        .basicInfo {
            display: flex;
            justify-content: flex-start;
        }
        .time {
            width: 240px;
            display: flex;
        }
        .infoItem {
            width: 180px;
            display: flex;
            .value {
                margin: 0 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 114px;
            }
        }
    }
    .tableBox {
        width: calc(100% - 40px);
        height: calc(100% - 42px);
        // margin: 0 20px;
        // overflow-y: auto;
        // overflow-x: hidden;
        position: relative;
        table {
            border-collapse: collapse;
            border-color: $colorMain;
            width: 100%;
            height: 100%;
            padding-bottom: 10px;
            .name {
                width: 200px;
                text-align: center;
                line-height: 40px;
            }
            .desc {
                text-indent: 10px;
            }
            .itemLable {
                width: 150px;
                text-align: center;
                line-height: 40px;
            }
            .itemValue {
                width: 200px;
                padding: 0 5px;
            }
            .fangwu {
                display: flex;
                justify-content: space-between;
                .lable {
                    width: 80px;
                    border-left: 1px solid $colorMain;
                    height: 40px;
                    border-right: 1px solid $colorMain;
                    line-height: 40px;
                    text-align: center;
                }
                .value {
                    flex: 1;
                    height: 40px;
                    line-height: 40px;
                }
            }
            .locationBox {
                display: flex;
                justify-content: space-between;
                line-height: 40px;
                height: 40px;
                .locationItem {
                    flex: 1;
                    display: flex;
                    justify-content: space-between;
                    .locationname {
                        width: 80px;
                        text-align: center;
                    }
                    .locationvalue {
                        flex: 1;
                    }
                }
            }
            .monitorBox {
                display: flex;
                justify-content: space-between;
                line-height: 40px;
                height: 40px;
                text-align: center;
                .tel {
                    width: 60px;
                    border-left: 1px solid $colorMain;
                    border-right: 1px solid $colorMain;
                }
                .people {
                    width: 60px;
                    border-left: 1px solid $colorMain;
                    border-right: 1px solid $colorMain;
                }
                .monitorValue {
                    flex: 1;
                    padding: 0 5px;
                }
            }
            .photoBox {
                height: 100%;
                .photoRow {
                    height: 50%;
                    display: flex;
                    justify-content: space-between;
                    &:nth-child(1) {
                        border-bottom: 1px solid $colorMain;
                    }
                    .photoItem {
                        flex: 1;
                        height: 100%;
                        position: relative;
                        padding: 2px;
                        &:nth-child(1) {
                            border-right: 1px solid $colorMain;
                        }
                        .label {
                            height: 30px;
                            line-height: 30px;
                            text-indent: 10px;
                        }
                        .imgList {
                            width: 339px;
                            height: 115px;
                            .img {
                                width: 100px;
                                height: 100px;
                                margin-right: 10px;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
            .monitorPhotoBox {
                width: 100%;
                .monitorPhotoItem {
                    width: 20%;
                    height: 100%;
                    position: relative;
                    .tag {
                        position: absolute;
                        left: 2px;
                        top: 2px;
                        z-index: 10;
                        height: 28px;
                        line-height: 26px;
                        font-size: 12px;
                        color: $colorMain;
                        background: #1a3059;
                        border: 1px solid #35507c;
                        border-radius: 4px;
                        box-shadow: 5px 9px 30px 0px rgb(0 44 75 / 20%);
                        padding: 0 4px;
                    }
                    .img {
                        width: 136px;
                        height: 120px;
                    }
                }
            }
        }
    }
}
</style>
