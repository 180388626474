<template>
    <div class="screenCtn monitorAllScreen flex-between">
        <!-- 左侧面板 -->
        <div class="leftView">
            <page-left></page-left>
        </div>
        <!-- 中间地图面板 -->
        <div class="centerView">
            <page-center ref="pageCenter"
                         @getCommonDialog="getCommonDialog"></page-center>
        </div>
        <!-- 弹框部分  -->
        <div class="sideDialog"
             :style="{
                width: getWidth(),
            }">
            <page-side ref="commonDialog"
                       @closeComDialog="closeComDialog"></page-side>
        </div>
        <!-- 右侧面板 -->
        <div class="rightView">
            <page-right></page-right>
        </div>
    </div>
</template>
<script>
import PageLeft from "./1_page_left/index"; //左测 部分
import PageCenter from "./2_page_center/index"; //中间 地图部分
import PageRight from "./3_page_right/page_right"; //右侧 总体
import PageSide from "./4_page_side/index"; //右侧 总体
import * as TYPES from "@store/modules/mutation-type.js";
import { mapMutations, mapGetters } from "vuex";
export default {
    name: "",
    components: {
        PageLeft,
        PageCenter,
        PageRight,
        PageSide,
    },
    data () {
        return {
            listAry: [],
            commonDialog: {
                dialog: false,
                width: "",
            },
        };
    },
    computed: {
        ...mapGetters({
            jczlYcysTypeItem: TYPES.jczlYcysTypeItem,
            jczlYczcItem: TYPES.jczlYczcItem,
            jczlYcysItem: TYPES.jczlYcysItem,
            jczlSxtState: TYPES.jczlSxtState,
        }),
    },
    watch: {
        jczlYcysTypeItem: {
            immediate: false,
            handler () {
                this.closeComDialog();
            },
        },
        jczlYczcItem: {
            immediate: false,
            handler () {
                this.closeComDialog();
            },
        },
        jczlYcysItem: {
            immediate: false,
            handler () {
                if (!this.jczlYcysItem) {
                    this.closeComDialog();
                }
            },
        },
    },
    created () { },
    destroyed () {
        window.signalr_connection.off("MonitoringOverview");
    },
    mounted () {
        this.initStore();
    },
    methods: {
        ...mapMutations({
            SET_JCZLYCYSTYPE: TYPES.SET_JCZLYCYSTYPE,
            SET_JCZLYCYSLIST: TYPES.SET_JCZLYCYSLIST,
            SET_JCZLYCZCITEM: TYPES.SET_JCZLYCZCITEM,
            SET_JCZLYCZCITEMYCYSLIST: TYPES.SET_JCZLYCZCITEMYCYSLIST,
            SET_JCZLYCYSITEM: TYPES.SET_JCZLYCYSITEM,
            SET_JCZLJGHJSTATE: TYPES.SET_JCZLJGHJSTATE,
        }),
        initStore () {
            this.SET_JCZLYCYSTYPE(null); // 遗产要素 类型某一个
            this.SET_JCZLYCYSLIST([]); // 遗产要素列表
            this.SET_JCZLYCZCITEM(null); // 遗产组成某一个
            this.SET_JCZLYCZCITEMYCYSLIST([]); // 遗产组成某一个下的遗产要素列表
            this.SET_JCZLYCYSITEM(null); // 遗产要素某一个
            this.SET_JCZLJGHJSTATE(false); // 景观环境
        },
        getCommonDialog (obj) {
            let width = "";
            switch (obj.dialog) {
                case "jgDialog": //景观视线 景观风貌 弹出框
                    width = "783px";
                    break;
                case "sxtDialog": //摄像头
                    width = "";
                    break;
                case "gcsDialog": //古茶树
                    width = "830px";
                    break;
                case "gsmmDialog": //古树名木
                    width = "830px";
                    break;
                case "ycysDialog": //遗产要素
                    width = "652px";
                    break;
                case "ycysMjDialog": //遗产要素民居
                    width = "952px";
                    break;
                case "hjDialog": //历史火警
                    width = "548px";
                    break;
                case "othersDialog": //预警
                    width = "611px";
                    break;
            }
            this.commonDialog.width = width;
            this.commonDialog.dialog = true;
            this.$nextTick(() => {
                this.$refs.commonDialog.getDetail(obj);
            });
        },
        // 关闭
        closeComDialog () {
            this.commonDialog.dialog = false;
            this.$refs.pageCenter.closeComDialog();
        },
        getWidth () {
            let width = "0px";
            if (this.commonDialog.dialog) {
                if (this.commonDialog.width) {
                    width = this.commonDialog.width;
                } else {
                    width = "1371px";
                    // if (this.jczlSxtState) {
                    //     width = "1371px";
                    // } else {
                    //     width = "1613px";
                    // }
                }
            }
            return width;
            // 1608px commonDialog.dialog ? commonDialog.width : '0px'
        },
    },
};
</script>
<style scoped lang="scss">
/* 中间主体内容 */
.screenCtn {
    width: 100%;
    position: relative;
    height: 100%;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 0px;
    }
    &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 0;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }
    /* 左边部分 */
    .leftView {
        position: relative;
        width: 680px;
        height: 100%;
        // border: 1px solid #fff;
    }

    /* 中间部分 */
    .centerView {
        width: 1203px;
        margin: 0 32px;
        height: 100%;
    }

    .sideDialog {
        height: 100%;
        transition: all 0.6s ease-in-out;
        overflow: hidden;
    }

    /* 右边部分 */
    .rightView {
        position: relative;
        width: 3054px;
        height: 100%;
        // border: 1px solid #fff;
    }
}
</style>
