<template>
    <div class="screenItem">
        <div class="topAnimate">
            <img
                src="@image/bigScreen/monitor_all/zrhj/topPlay.png"
                class="topPlay"
            />
            <div class="carouselPart" v-if="yjList.length">
                <el-carousel height="50px" direction="vertical">
                    <el-carousel-item
                        v-for="(item, index) in yjList"
                        :key="index"
                        class="carouselItem flex-y-center"
                    >
                        <img
                            class="icon"
                            :src="getYjlxImg(item)"
                            v-if="getYjlxImg(item)"
                        />
                        <div
                            class="time"
                            :style="{
                                color: common.getYjdjStateColor(item.YJDJNAME),
                            }"
                        >
                            {{ item.FSYJSJ }}
                        </div>
                        <div
                            class="desc ellipsis flex-1"
                            :style="{
                                color: common.getYjdjStateColor(item.YJDJNAME),
                            }"
                            :title="item.YJSJ"
                        >
                            {{ item.YJSJ }}
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <span v-else class="noData">{{ stateDesc }}</span>
        </div>
        <div class="tableList">
            <div
                v-for="(item, index) in listAry"
                :key="index"
                class="tableItem flex"
            >
                <div class="tableLeft">
                    <div class="flex">
                        <div class="imgWrap flex-both-center">
                            <img :src="item.src" class="animated swing" />
                            <div class="state flex-both-center">
                                <div class="steteIcon stateLeftTop"></div>
                                <div class="steteIcon stateRightTop"></div>
                                <div class="steteIcon stateLeftBottom"></div>
                                <div class="steteIcon stateRightBottom"></div>
                            </div>
                        </div>
                        <div class="numWrap flex-1 flex-y-center">
                            <div class="num">{{ item.num }}</div>
                            <div class="dw">{{ item.dw }}</div>
                            <div class="linePart flex-between">
                                <div class="lineItem"></div>
                                <div class="lineItem"></div>
                                <div class="lineItem"></div>
                                <div class="lineItem"></div>
                            </div>
                        </div>
                    </div>

                    <div class="name flex-y-center">
                        {{ item.name }}
                    </div>
                </div>
                <div class="tableRight flex-1" :id="`echart${index}`"></div>
            </div>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import BasicMonitor from "@views/big_screen/monitor_all/comp/BasicModule";
export default {
    components: {
        BasicMonitor,
    },
    name: "",
    data() {
        return {
            stateDesc: "当前无预警",
            yjList: [], // 预警列表 大风 台风 暴雨 雷电  霜冻 道路结冰  森林火险预警
            listAry: [
                {
                    name: "温度",
                    src: require("@image/bigScreen/monitor_all/zrhj/icon/wd.png"),
                    list: [],
                    num: 0,
                    dw: "℃",
                },
                {
                    name: "湿度",
                    src: require("@image/bigScreen/monitor_all/zrhj/icon/water.gif"),
                    list: [],
                    num: 0,
                    dw: "%",
                },
                {
                    name: "降雨量",
                    src: require("@image/bigScreen/monitor_all/zrhj/icon/jyl.png"),
                    list: [],
                    num: 0,
                    dw: "mm",
                },
            ],
            echart0: null,
            echart1: null,
            echart2: null,
            timer: null,
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {},
    watch: {},

    mounted() {
        this.getMainInfo();
        this.getWeatherInfo();
        this.monitorWeatherInfo();
    },
    destroyed() {
        clearTimeout(this.timer);
    },
    methods: {
        ...mapActions(["GetNaturalWarnInfoList", "GetWeatherList"]),
        ...mapMutations({}),
        // 获取 天气预警
        async getMainInfo() {
            let result = await this.GetNaturalWarnInfoList({
                pageIndex: 1,
                pageSize: 10,
            });
            this.yjList = result.DATA;
        },
        // 获取 天气信息
        async getWeatherInfo() {
            let result = await this.GetWeatherList({});
            let list = result.DATA;
            let obj = result.NEWDT[0];
            this.listAry[0].num = obj.DQQW;
            this.listAry[1].num = obj.DQSD;
            this.listAry[2].num = obj.JYL_1H;
            this.initEchart("echart0", list, 0);
            this.initEchart("echart1", list, 1);
            this.initEchart("echart2", list, 2);
        },
        // 监听 天气信息
        monitorWeatherInfo() {
            window.signalr_connection.on("MonitoringOverview", (data) => {
                let result = JSON.parse(data).WEATHER;
                let list = result.DATA;
                let obj = result.NEWDT[0];
                this.listAry[0].num = obj.DQQW;
                this.listAry[1].num = obj.DQSD;
                this.listAry[2].num = obj.JYL_1H;
                this.initEchart1("echart0", list, 0);
                this.initEchart1("echart1", list, 1);
                this.initEchart1("echart2", list, 2);
            });
        },
        initEchart(id, list, num) {
            let seriesData = [];
            let xAxisData = [];
            list.map((item) => {
                switch (num) {
                    case 0:
                        if (this.testData(item.DQQW)) {
                            seriesData.push(item.DQQW);
                        }
                        break;
                    case 1:
                        if (this.testData(item.DQSD)) {
                            seriesData.push(item.DQSD);
                        }
                        break;
                    case 2:
                        if (this.testData(item.JYL_1H)) {
                            seriesData.push(item.JYL_1H);
                        }
                        break;
                }
                xAxisData.push(item.FBSJ);
            });

            let option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        lineStyle: {
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "rgba(255, 255, 255,1)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                ],
                                global: false,
                            },
                        },
                    },
                    formatter: (params) => {
                        let obj = this.listAry[num];
                        let str = `${params[0].axisValue}<br/>${obj.name}<br/>${params[0].value}${obj.dw}`;
                        return str;
                    },
                },
                grid: {
                    top: 10, //30
                    left: -34,
                    right: -34,
                    bottom: 10,
                },
                // dataZoom: [
                //     {
                //         show: true,
                //         height: 10,
                //         xAxisIndex: [0],
                //         bottom: 0,
                //         start: 95,
                //         end: 100,
                //         handleSize: "110%",
                //         handleStyle: {
                //             color: "rgba(204,187,225,0)",
                //         },
                //         textStyle: {
                //             color: "rgba(204,187,225,0)",
                //         },
                //         fillerColor: "rgba(67,55,160,0)",
                //         borderColor: "rgba(204,187,225,0)",
                //     },
                //     {
                //         type: "inside",
                //         show: true,
                //         height: 15,
                //         start: 1,
                //         end: 35,
                //     },
                // ],
                xAxis: [
                    {
                        show: false,
                        type: "category",
                        data: xAxisData,
                        axisLine: {
                            lineStyle: {
                                color: "#999",
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        show: false,
                        type: "value",
                        splitNumber: 4,
                        splitLine: {
                            lineStyle: {
                                type: "dashed",
                                color: "#DDD",
                            },
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#333",
                            },
                        },
                        nameTextStyle: {
                            color: "#999",
                        },
                        splitArea: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: "课时",
                        type: "line",
                        data: seriesData,
                        lineStyle: {
                            normal: {
                                width: 2,
                                color:
                                    num == 0
                                        ? "#ffb508"
                                        : "rgba(154,180,227,1)",
                                shadowColor: "rgba(72,216,191, 0.3)",
                                shadowBlur: 10,
                                shadowOffsetY: 20,
                            },
                        },
                        itemStyle: {
                            normal: {
                                color: "#fff",
                                borderWidth: 2,
                                borderColor: "rgba(154,180,227,1)",
                            },
                        },
                        smooth: true,
                    },
                ],
            };
            this[id] = this.$echarts.dispose(document.getElementById(id));
            this[id] = this.$echarts.init(document.getElementById(id));
            this[id].setOption(option);
        },
        testData(num) {
            let state = false;
            if (
                num != "9999" &&
                num != "999" &&
                num != "999.0" &&
                num != "9999.0"
            ) {
                state = true;
            }
            return state;
        },
        initEchart1(id, list, num) {
            let seriesData = [];
            let xAxisData = [];
            list.map((item) => {
                switch (num) {
                    case 0:
                        seriesData.push(item.DQQW);
                        break;
                    case 1:
                        seriesData.push(item.DQSD);
                        break;
                    case 2:
                        seriesData.push(item.JYL_1H);
                        break;
                }
                xAxisData.push(item.FBSJ);
            });

            let option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        lineStyle: {
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "rgba(255, 255, 255,1)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                ],
                                global: false,
                            },
                        },
                    },
                    formatter: (params) => {
                        let obj = this.listAry[num];
                        let str = `${params[0].axisValue}<br/>${obj.name}<br/>${params[0].value}${obj.dw}`;
                        return str;
                    },
                },
                // dataZoom: [
                //     {
                //         show: true,
                //         height: 10,
                //         xAxisIndex: [0],
                //         bottom: 0,
                //         start: 95,
                //         end: 100,
                //         handleSize: "110%",
                //         handleStyle: {
                //             color: "rgba(204,187,225,0)",
                //         },
                //         textStyle: {
                //             color: "rgba(204,187,225,0)",
                //         },
                //         fillerColor: "rgba(67,55,160,0)",
                //         borderColor: "rgba(204,187,225,0)",
                //     },
                //     {
                //         type: "inside",
                //         show: true,
                //         height: 15,
                //         start: 1,
                //         end: 35,
                //     },
                // ],
                grid: {
                    top: 10,
                    left: -34,
                    right: -34,
                    bottom: 10,
                },
                xAxis: [
                    {
                        show: false,
                        type: "category",
                        data: xAxisData,
                        axisLine: {
                            lineStyle: {
                                color: "#999",
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        show: false,
                        type: "value",
                        splitNumber: 4,
                        splitLine: {
                            lineStyle: {
                                type: "dashed",
                                // color: num == 0 ? "#ffb508" : "#DDD",
                            },
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                // color: num == 0 ? "#ffb508" : "#333",
                            },
                        },
                        nameTextStyle: {
                            color: "#999",
                        },
                        splitArea: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: "课时",
                        type: "line",
                        data: seriesData,
                        lineStyle: {
                            normal: {
                                width: 2,
                                color: "rgba(154,180,227,1)",
                                shadowColor: "rgba(72,216,191, 0.3)",
                                shadowBlur: 10,
                                shadowOffsetY: 20,
                            },
                        },
                        itemStyle: {
                            normal: {
                                color: "#fff",
                                borderWidth: 2,
                                borderColor: "rgba(154,180,227,1)",
                            },
                        },
                        smooth: true,
                    },
                ],
            };
            this[id] = this.$echarts.init(document.getElementById(id));
            this[id].setOption(option);
        },
        getYjlxImg(item) {
            let name = "";
            let src = "";
            switch (item.YJLX) {
                case 0: //大风
                    name = "df";
                    break;
                case 1: //台风
                    name = "typhoon";
                    break;
                case 2: //暴雨
                    name = "hardrain";
                    break;
                case 3: //雷电
                    name = "ld";
                    break;
                case 4: //霜冻
                    name = "sd";
                    break;
                case 5: //道路结冰
                    name = "dljb";
                    break;
                case 6: //森林火险预警
                    name = "slhxyj";
                    break;
            }
            if (name) {
                src = `@image/bigScreen/monitor_all/zrhj/warning/${name}${item.YJDJ}.png`;
            }
            return src;
        },
    },
};
</script>
<style scoped lang="scss">
.screenItem {
    width: 100%;
    height: 100%;
    padding: 12px 0 17px 0;
    .topAnimate {
        width: 100%;
        height: 123px;
        background: url("~@image/bigScreen/monitor_all/zrhj/topBg.png")
            no-repeat;
        background-size: 100% 100%;
        position: relative;
        margin-bottom: 15px;
        .topPlay {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            animation: rolltext 6s linear 0s infinite;
        }
        .carouselPart {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            .carouselItem {
                padding: 0 50px;
                .icon {
                    width: 20px;
                    margin-right: 10px;
                }
                .time {
                    font-size: 18px;
                    margin-right: 10px;
                }
                .desc {
                    font-size: 18px;
                }
            }
        }

        .noData {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 30px;
            color: rgba(168, 200, 255, 1);
        }
    }
    .tableList {
        width: 100%;
        height: 245px;
        .tableItem {
            width: 100%;
            height: calc(33% - 4px);
            &:nth-child(2) {
                margin: 10px 0;
            }
            .tableLeft {
                width: 186px;
                height: 100%;
                margin-right: 13px;
                .imgWrap {
                    width: 53px;
                    height: 53px;
                    position: relative;
                    margin-right: 30px;
                    .state {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        border: 1px solid rgba(103, 159, 192, 0.3);
                        background: rgba(26, 48, 89, 0.4);
                        .steteIcon {
                            width: 2px;
                            height: 2px;
                            background: rgba(168, 200, 255, 1);
                        }
                        .stateLeftTop {
                            position: absolute;
                            left: 0;
                            top: 0;
                        }
                        .stateRightTop {
                            position: absolute;
                            right: 0;
                            top: 0;
                        }
                        .stateLeftBottom {
                            position: absolute;
                            left: 0;
                            bottom: 0;
                        }
                        .stateRightBottom {
                            position: absolute;
                            right: 0;
                            bottom: 0;
                        }
                    }
                }
                .numWrap {
                    height: 53px;
                    position: relative;
                    .num {
                        font-size: 48px;
                        color: rgba(168, 200, 255, 1);
                        margin-right: 10px;
                        font-family: DBPM;
                    }
                    .dw {
                        font-size: 8px;
                        color: rgba(168, 200, 255, 1);
                        position: relative;
                        top: 2px;
                    }
                    .linePart {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        .lineItem {
                            width: 22px;
                            height: 4px;
                            background: rgba(53, 80, 124, 1);
                        }
                    }
                }
                .name {
                    margin-top: 5px;
                    color: #a8c8ff;
                    font-size: 14px;
                    background: rgba(53, 80, 124, 0.1);
                    padding-left: 10px;
                }
            }
            .tableRight {
                height: 100%;
                background: url("~@image/bigScreen/monitor_all/zrhj/listBg.png")
                    no-repeat;
                background-size: 100% 100%;
            }
        }
    }
}
@keyframes rolltext {
    0% {
        width: 0;
        transform: translateX(10px);
    }
    20% {
        width: 134px;
        transform: translateX(10px);
    }
    100% {
        width: 134px;
        transform: translateX(450px);
    }
}
</style>
