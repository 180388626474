<template>
    <div class="dialogCtn scrollbar-translucent carouselArrowShow">
        <!-- <el-image :src="detail.src"
                  fit="cover"
                  style="width: 100%; height: 100%">
        </el-image> -->
        <el-carousel indicator-position="none"
                     height="811px"
                     ref="carouselImg"
                     @change="changeCarouse"
                     :autoplay="false">
            <el-carousel-item v-for="(item, index) in detail"
                              :key="index">
                <el-image :src="item.src"
                          fit="cover"
                          class="pointer"
                          style="width: 100%; height: 100%">
                </el-image>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
    components: {},
    name: "",
    data () {
        return {};
    },
    computed: {
        ...mapGetters({}),
    },
    props: {
        detail: {
            Type: Array,
            default: () => {
                return []
            },
        },
    },
    watch: {
        detail: {
            deep: true,
            immediate: true,
            handler () {
            },
        },
    },
    mounted () { },
    methods: {
        ...mapActions([""]),
        ...mapMutations({}),
        scrollTo (num) {
            this.$refs.carouselImg.setActiveItem(num)
        },
        changeCarouse (num) {
            this.$emit("changeCarouseTime", num)
        }
    },
};
</script>
<style scoped lang="scss">
.dialogCtn {
    width: 100%;
    max-height: 100%;
}
</style>
