<template>
    <div class="leftPart scrollbar-hidden">
        <div class="leftTop">
            <Ycys></Ycys>
        </div>
        <div class="leftBottom">
            <Bhlb></Bhlb>
        </div>
    </div>
</template>
<script>
import Ycys from "./1_ycys.vue"; //遗产要素
import Bhlb from "./2_bhlb.vue"; //病害列表
export default {
    name: "",
    components: {
        Ycys,
        Bhlb,
    },
};
</script>
<style scoped lang="scss">
.leftPart {
    width: 100%;
    height: 100%;
    .leftTop {
        width: 100%;
        height: 470px;
    }
    .leftBottom {
        width: 100%;
        height: calc(100% - 470px);
    }
}
</style>
