import { render, staticRenderFns } from "./zrhj.vue?vue&type=template&id=498da8c5&scoped=true&"
import script from "./zrhj.vue?vue&type=script&lang=js&"
export * from "./zrhj.vue?vue&type=script&lang=js&"
import style0 from "./zrhj.vue?vue&type=style&index=0&id=498da8c5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "498da8c5",
  null
  
)

export default component.exports