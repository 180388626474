<template>
    <div class="sthj-line-chart" :id="chartId"></div>
</template>
<script>
export default {
    name: "",
    data() {
        return {
            chartId: "sthjLineChart" + Math.random(),
            myChart: null,
        };
    },
    props: {
        chartData: {
            type: Object,
            default: () => {},
        },
    },
    mounted() {
        // this.initChart();
        $(window).resize(() => {
            this.initChart();
        });
    },
    watch: {
        chartData: {
            handler(newVal, oldVal) {
                this.initChart();
            },
        },
    },
    methods: {
        initChart() {
            // 基于准备好的dom，初始化echarts实例
            this.myChart = this.$echarts.dispose(
                document.getElementById(this.chartId)
            );
            this.myChart = this.$echarts.init(
                document.getElementById(this.chartId)
            );

            // 指定图表的配置项和数据
            let option = {
                grid: {
                    top: 35,
                    right: 35,
                    bottom: 30,
                    left: 40,
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                    },
                    formatter: (params) => {
                        var relVal = params[0].name + this.chartData.xAxisName;
                        for (var i = 0, l = params.length; i < l; i++) {
                            if (params[i].value == undefined) {
                                relVal +=
                                    "<br/>" +
                                    params[i].marker +
                                    params[i].seriesName +
                                    " : " +
                                    "-";
                            } else {
                                relVal +=
                                    "<br/>" +
                                    params[i].marker +
                                    params[i].seriesName +
                                    " : " +
                                    params[i].value +
                                    " " +
                                    this.chartData.dwArr[params[i].seriesName];
                            }
                        }
                        return relVal;
                    },
                },
                legend: {
                    type: "scroll",
                    left: 0,
                    textStyle: {
                        color: "#C4D6E1",
                    },
                    icon: "rect",
                    itemWidth: 15,
                    itemHeight: 5,
                    itemGap: 30,
                    data: this.chartData.legendData,
                    selected: this.chartData.selectedObj || {},
                },
                color: [
                    "#E76452",
                    "#5AD9A6",
                    "#5AC9D9",
                    "#5A5DD9",
                    "#7E5AD9",
                    "#D95A93",
                    "#D9AE5A",
                ],
                xAxis: [
                    {
                        data: this.chartData.xAxisData,
                        axisLabel: {
                            color: "#A8C8FF",
                            fontSize: 16,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#35507C",
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                        name: this.chartData.xAxisName,
                        nameTextStyle: {
                            color: "#A8C8FF",
                        },
                    },
                ],
                yAxis: {
                    axisLabel: {
                        color: "#A8C8FF",
                    },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        lineStyle: {
                            color: "rgba(53,80,124,.5)",
                        },
                    },
                },
                series: this.chartData.seriesData,
            };

            // 使用刚指定的配置项和数据显示图表。
            this.myChart.setOption(option);
        },
    },
};
</script>
<style lang="scss" scoped>
.sthj-line-chart {
    width: 100%;
    height: 100%;
}
</style>