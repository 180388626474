<template>
    <div class="zrHjPart">
        <div class="tabPart flex-end">
            <div
                v-for="(item, index) in tabList"
                :key="index"
                class="tabItem pointer"
                :class="[
                    tabActiveNum === index ? 'tabItemActive' : 'tabItemDefault',
                ]"
                @click="getTabDetail(item, index)"
            >
                {{ item.name }}
            </div>
        </div>
        <!-- 地质 -->
        <div
            class="dzPart animated fadeIn scrollbar-hidden"
            v-show="this.tabActiveNum == 0"
        >
            <div class="infoPart flex-between">
                <div class="infoItem">
                    <el-image
                        :src="
                            require(`@image/bigScreen/monitor_all/zrhj/dz.png`)
                        "
                        fit="cover"
                        style="width: 100%; height: 100%"
                    >
                        <div slot="error" style="width: 100%; height: 100%">
                            <SnNoData width="170px"></SnNoData>
                        </div>
                    </el-image>
                </div>
                <div class="infoItem">
                    <div class="basicItem">
                        <div class="name">总厚度:</div>
                        <div class="desc ellipsis">大于1003米</div>
                    </div>
                    <div class="basicItem">
                        <div class="name">下段厚度:</div>
                        <div class="desc ellipsis">大于541米</div>
                    </div>
                    <div class="basicItem">
                        <div class="name">上段厚度:</div>
                        <div class="desc ellipsis">大于462米</div>
                    </div>
                </div>
            </div>
            <div class="descPart">
                <div class="name">地质简介:</div>
                <div class="desc">
                    普洱景迈山地层出露有中元古界澜沧群变质岩系，总厚度大于1003米
                    其中下段厚度大于541米，以绢云、白云石英片岩、云母片岩为主。上
                    段厚度大于462米，以云母石英片岩、云母片岩为主。
                </div>
            </div>
        </div>
        <!-- 气象气候 -->
        <div
            class="qhqxPart animated fadeIn scrollbar-hidden"
            v-show="this.tabActiveNum == 2"
        >
            <div class="tableList flex-between flex-wrap">
                <div
                    v-for="(item, index) in qxqhList"
                    :key="index"
                    class="tableItem flex"
                >
                    <div class="tableLeft">
                        <div class="flex">
                            <div class="imgWrap flex-both-center">
                                <img :src="item.src" class="animated swing" />
                                <div class="state flex-both-center">
                                    <div class="steteIcon stateLeftTop"></div>
                                    <div class="steteIcon stateRightTop"></div>
                                    <div
                                        class="steteIcon stateLeftBottom"
                                    ></div>
                                    <div
                                        class="steteIcon stateRightBottom"
                                    ></div>
                                </div>
                            </div>
                            <div class="numWrap flex-1 flex-both-center">
                                <div class="num">{{ item.num }}</div>
                                <div class="dw">{{ item.dw }}</div>
                                <div class="linePart flex-between">
                                    <div class="lineItem"></div>
                                    <div class="lineItem"></div>
                                    <div class="lineItem"></div>
                                    <div class="lineItem"></div>
                                </div>
                            </div>
                        </div>

                        <div class="name flex-both-center">
                            {{ item.name }}
                        </div>
                    </div>
                </div>
            </div>

            <el-image
                fit="cover"
                :src="require(`@image/bigScreen/monitor_all/zrhj/qxqh.png`)"
            >
                <div slot="error" style="width: 100%; height: 100%">
                    <SnNoData width="170px"></SnNoData>
                </div>
            </el-image>
            <div class="descPart">
                <div class="desc">
                    由于环绕景迈山的南朗河、南门河输送充分的水汽，特殊的对流条件和气温分层
                    现象使海拔
                    1000-1200米的南朗河、南门河峡谷容易形成大规模云海，每年出现
                    云海的天数180 天左右。
                </div>
            </div>
        </div>
        <!-- 土壤 -->
        <div
            class="trPart animated fadeIn scrollbar-hidden"
            v-show="this.tabActiveNum == 1"
        >
            <div class="tableList flex-between flex-wrap">
                <div
                    v-for="(item, index) in topList"
                    :key="index"
                    class="tableItem flex"
                >
                    <div class="titlePart flex-both-center">
                        <div class="name">{{ item.name }}</div>
                    </div>
                    <div class="numPart flex-both-center">
                        <div class="num">{{ item.num }}</div>
                    </div>
                </div>
            </div>
            <div class="descPart">
                <div class="name">地质简介:</div>
                <div class="desc">
                    区域内土壤类型属赤红土壤一大类，亚类有红色赤红色土壤，黄色赤红
                    土壤，紫色赤红土壤等。土壤ph值为4.5~5.5，速效磷含量为0.5mg/
                    kg,速效磷含量为0.5mg/kg，土壤类型非常适宜温性的普洱茶生长。
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    name: "",
    data() {
        return {
            noImg: require("@image/bigScreen/noImg.png"),
            tabActiveNum: 0,
            tabList: [
                {
                    name: "地质",
                },
                {
                    name: "土壤",
                },
                {
                    name: "气象气候",
                },
            ],
            topList: [
                {
                    name: "土壤pH值",
                    num: "4.5~5.5",
                },
                {
                    name: "速效磷含量",
                    num: "0.5mg/kg",
                },
                {
                    name: "速效硼含量",
                    num: "0.5mg/kg",
                },
            ],
            // 气象气候
            qxqhList: [
                {
                    name: "平均气温",
                    src: require("@image/bigScreen/monitor_all/zrhj/icon/pjqw.png"),
                    num: 18.4,
                    dw: "℃",
                },
                {
                    name: "平均相对湿度",
                    src: require("@image/bigScreen/monitor_all/zrhj/icon/wet.png"),
                    num: 79,
                    dw: "%",
                },
                {
                    name: "年降雨量",
                    src: require("@image/bigScreen/monitor_all/zrhj/icon/jyl.png"),
                    num: 1689.7,
                    dw: "mm",
                },
                {
                    name: "年平均日照时间",
                    src: require("@image/bigScreen/monitor_all/zrhj/icon/rz.png"),
                    num: 2135,
                    dw: "小时",
                },
                {
                    name: "≥10℃积温",
                    src: require("@image/bigScreen/monitor_all/zrhj/icon/jw.png"),
                    num: "6200-7500",
                    dw: "℃",
                },
                {
                    name: "年无霜期",
                    src: require("@image/bigScreen/monitor_all/zrhj/icon/sd.png"),
                    num: 265,
                    dw: "天",
                },
            ],
        };
    },
    computed: {},
    props: {},
    watch: {},
    mounted() {},
    methods: {
        getTabDetail(item, index) {
            this.tabActiveNum = index;
        },
    },
};
</script>

<style lang="scss" scoped>
.zrHjPart {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 14px;
    .tabPart {
        position: absolute;
        right: 0;
        top: -10px;
        z-index: 999;
        .tabItem {
            height: 20px;
            line-height: 20px;
            font-size: 14px;
            text-align: center;
            border-radius: 5px;
            margin-right: 10px;
            padding: 0 10px;
            &:nth-last-child(1) {
                margin-right: 0px;
            }
        }
        .tabItemActive {
            background: rgba(255, 205, 128, 1);
            color: rgba(4, 14, 30, 1);
        }
        .tabItemDefault {
            background: rgba(53, 80, 124, 1);
            color: rgba(168, 200, 255, 1);
        }
    }
    // 地质
    .dzPart {
        width: 100%;
        height: calc(100% - 14px);
        .infoPart {
            width: 100%;
            height: 202px;
            .infoItem {
                width: calc(50% - 5px);
                height: 100%;
                .basicItem {
                    width: 100%;
                    .name {
                        height: 28px;
                        line-height: 28px;
                        text-align: left;
                        color: rgba(86, 115, 162, 1);
                        font-size: 16px;
                    }
                    .desc {
                        height: 36px;
                        line-height: 36px;
                        background: rgba(26, 48, 89, 0.3);
                        color: #d7eaf5;
                        padding-left: 10px;
                        font-size: 16px;
                    }
                }
            }
        }
        .descPart {
            width: 100%;
            .name {
                height: 28px;
                line-height: 28px;
                text-align: left;
                color: rgba(86, 115, 162, 1);
                font-size: 16px;
            }
            .desc {
                background: rgba(26, 48, 89, 0.3);
                color: #d7eaf5;
                padding: 0 10px;
                font-size: 16px;
                line-height: 36px;
                text-indent: 40px;
            }
        }
    }
    // 气象气候
    .qhqxPart {
        width: 100%;
        height: calc(100% - 14px);
        .tableList {
            width: 100%;
            .tableItem {
                width: calc(33% - 2px);
                height: 80px;
                background: rgba(16, 33, 60, 1);
                margin-bottom: 6px;
                .tableLeft {
                    width: 186px;
                    height: 100%;
                    .imgWrap {
                        width: 45px;
                        height: 45px;
                        position: relative;
                        margin-right: 2px;
                        .state {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            border: 1px solid rgba(103, 159, 192, 0.3);
                            background: rgba(26, 48, 89, 0.4);
                            .steteIcon {
                                width: 2px;
                                height: 2px;
                                background: rgba(168, 200, 255, 1);
                            }
                            .stateLeftTop {
                                position: absolute;
                                left: 0;
                                top: 0;
                            }
                            .stateRightTop {
                                position: absolute;
                                right: 0;
                                top: 0;
                            }
                            .stateLeftBottom {
                                position: absolute;
                                left: 0;
                                bottom: 0;
                            }
                            .stateRightBottom {
                                position: absolute;
                                right: 0;
                                bottom: 0;
                            }
                        }
                    }
                    .numWrap {
                        height: 45px;
                        position: relative;
                        .num {
                            font-size: 18px;
                            color: rgba(168, 200, 255, 1);
                            margin-right: 10px;
                        }
                        .dw {
                            font-size: 16px;
                            color: rgba(168, 200, 255, 1);
                            position: relative;
                            top: 2px;
                        }
                        .linePart {
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            width: 100%;
                            border-bottom: 2px dotted rgba(53, 80, 124, 1);
                            // .lineItem {
                            //     width: 22px;
                            //     height: 4px;
                            //     background: rgba(53, 80, 124, 1);
                            // }
                        }
                    }
                    .name {
                        margin-top: 5px;
                        font-size: 14px;
                        color: rgba(168, 200, 255, 1);
                    }
                }
                .tableRight {
                    height: 100%;
                    background: url("~@image/bigScreen/monitor_all/zrhj/listBg.png")
                        no-repeat;
                    background-size: 100% 100%;
                }
            }
        }
        .descPart {
            width: 100%;
            .desc {
                background: rgba(26, 48, 89, 0.3);
                color: #d7eaf5;
                padding: 0 10px;
                font-size: 16px;
                line-height: 36px;
                text-indent: 40px;
            }
        }
    }
    // 土壤
    .trPart {
        width: 100%;
        height: calc(100% - 14px);
        .tableList {
            width: 100%;
            .tableItem {
                width: calc(50% - 5px);
                height: 40px;
                margin-bottom: 5px;

                .titlePart {
                    width: calc(50% - 5px);
                    height: 100%;
                    background: url("~@image/bigScreen/icon/titleBg.png")
                        no-repeat;
                    background-size: 100% 100%;
                    .name {
                        font-size: 18px;
                        color: #a8c8ff;
                    }
                }
                .numPart {
                    width: calc(50% - 5px);
                    height: 100%;
                    border: 1px solid #35507c;
                    margin-left: 5px;
                    .num {
                        font-size: 18px;
                        color: #ffcd80;
                    }
                }
            }
        }
        .descPart {
            width: 100%;
            .name {
                height: 28px;
                line-height: 28px;
                text-align: left;
                color: rgba(86, 115, 162, 1);
                font-size: 16px;
            }
            .desc {
                background: rgba(26, 48, 89, 0.3);
                color: #d7eaf5;
                padding: 0 10px;
                font-size: 16px;
                line-height: 36px;
                text-indent: 40px;
            }
        }
    }
}
</style>
