<template>
    <div class="screenItem">
        <!-- 项目数量 -->
        <basic-module :text="common.getFyName(fany.xmsl)">
            <div slot="basic-module" class="screenCtn scrollbar-hidden">
                <div class="tableList flex-between flex-wrap">
                    <div
                        v-for="(item, index) in topList"
                        :key="index"
                        class="tableItem flex"
                    >
                        <div class="titlePart flex-both-center">
                            <div class="name">{{ item.name }}</div>
                        </div>
                        <div class="numPart flex-both-center">
                            <div class="num">{{ item.num }}</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="condition flex-end">
                    <div class="bg"></div>
                    <div class="selectPart">
                        <SelectTimeTab
                            :selectData="selectData"
                            :tabData="tabData"
                            @getTabItem="getTabItem"
                            @getSelectItem="getSelectItem"
                        >
                        </SelectTimeTab>
                    </div>
                </div> -->
                <div class="echartPart">
                    <div class="echart" :id="'jskzXmsl' + echartId"></div>
                    <div class="noData" v-if="!echartList.length">
                        <SnNoData
                            :noDataState="true"
                            noDataText="暂无数据"
                        ></SnNoData>
                    </div>
                </div>
            </div>
        </basic-module>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import SelectTimeTab from "@views/big_screen/comp/SelectTimeTab";

export default {
    components: {
        SelectTimeTab,
    },
    name: "",
    data() {
        return {
            echartId: Math.random(),
            tabData: {
                list: [
                    {
                        name: "年",
                    },
                    {
                        name: "月",
                    },
                ],
                value: "name",
            },
            time: "",
            selectData: {
                state: false,
                width: "100px",
                defaultValue: "",
                clearable: false,
                list: [],
                label: "name",
                value: "name",
            },
            topList: [
                {
                    name: "遗产区",
                    num: 0,
                },
                {
                    name: "缓冲区",
                    num: 0,
                },
                {
                    // name: "遗产区和缓冲区",
                    name: "保护范围",
                    num: 0,
                },
                {
                    // name: "区划外",
                    name: "建控地带",
                    num: 0,
                },
            ],
            legendKzlh: require("@image/bigScreen/build_control/legendKzlh.png"),
            echart: null,
            echartList: [],
            // 10个对比鲜明的颜色
            colorList: [
                {
                    color: "rgba(74,145,255,0)",
                    colorP: "rgba(74,145,255,1)",
                },
                {
                    color: "rgba(101,195,171,0)",
                    colorP: "rgba(101,195,171,1)",
                },
                {
                    color: "rgba(147,194,132,0)",
                    colorP: "rgba(147,194,132,1)",
                },
                {
                    color: "rgba(245,214,93,0)",
                    colorP: "rgba(245,214,93,1)",
                },
                {
                    color: "rgba(225,132,78,0)",
                    colorP: "rgba(225,132,78,1)",
                },
                {
                    color: "rgba(225,111,122,0)",
                    colorP: "rgba(225,111,122,1)",
                },
                {
                    color: "rgba(159,122,231,0)",
                    colorP: "rgba(159,122,231,1)",
                },
                {
                    color: "rgba(125,194,235,0)",
                    colorP: "rgba(125,194,235,1)",
                },
                {
                    color: "rgba(245,173,86,0)",
                    colorP: "rgba(245,173,86,1)",
                },
                {
                    color: "rgba(224,125,227,0)",
                    colorP: "rgba(224,125,227,1)",
                },
                {
                    color: "rgba(0,176,226,0)",
                    colorP: "rgba(0,176,226,1)",
                },
                {
                    color: "rgba(235,182,113,0)",
                    colorP: "rgba(235,182,113,1)",
                },
                {
                    color: "rgba(183,128,128,0)",
                    colorP: "rgba(183,128,128,1)",
                },
                {
                    color: "rgba(157,164,80,0)",
                    colorP: "rgba(157,164,80,1)",
                },
                {
                    color: "rgba(119,135,173,0)",
                    colorP: "rgba(119,135,173,1)",
                },
                {
                    color: "rgba(203,92,92,0)",
                    colorP: "rgba(203,92,92,1)",
                },
            ],
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {},
    watch: {},
    mounted() {
        this.getMainData();
        this.getEchartData();
    },
    methods: {
        ...mapActions([
            "GetNewProjectYearList",
            "GetHeritageBufferTotal",
            "GetNewProjectEcharts",
            "GetNewProjectEchartsEx",
        ]),
        ...mapMutations({}),
        // 获取顶部  数据
        async getMainData() {
            let result = await this.GetHeritageBufferTotal({});
            this.topList[0].num = result.YCQCON;
            this.topList[1].num = result.HCQCON;
            this.topList[2].num = result.YCQHCQCON;
            this.topList[3].num = result.QHWCON;
        },
        // 获取 时间
        async getTimeList() {
            let result = await this.GetNewProjectYearList({});
            if (result.length) {
                result.map((item) => {
                    item.name = item.NF;
                });
                this.selectData.list = result;
                this.time = this.selectData.list[0].name;
                this.selectData.defaultValue = this.selectData.list[0].name;
            }
        },
        async getEchartData() {
            let result = await this.GetNewProjectEchartsEx({});
            this.echartList = result;
            this.initEchartXmsl();
        },
        // 年月切换
        getTabItem(item, index) {
            if (index == 1) {
                this.selectData.state = true;
                if (this.selectData.list.length) {
                    this.time = this.selectData.list[0].name;
                    this.selectData.defaultValue = this.selectData.list[0].name;
                }
            } else {
                this.selectData.state = false;
                this.time = "";
            }
            this.getEchartData();
        },
        // 选择时间
        getSelectItem(item) {
            this.time = item.name;
            this.getEchartData();
        },
        initEchartXmsl() {
            let xAxisData = [];
            let seriesData = [];
            this.echartList.map((item, index) => {
                xAxisData.push(item.NAME);
                let obj = {
                    value: item.VALUE,
                    itemStyle: {
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    {
                                        offset: 1,
                                        color: this.colorList[index].color,
                                    },
                                    {
                                        offset: 0,
                                        color: this.colorList[index].colorP,
                                    },
                                ],
                                false
                            ),
                            // shadowColor: "rgba(105,196,254, 0.9)",
                            // shadowBlur: 20,
                        },
                    },
                };
                seriesData.push(obj);
            });
            let option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "none", // 默认为直线，可选为：'line' | 'shadow'
                    },
                },
                grid: {
                    left: 50,
                    right: 50,
                    bottom: 20,
                    top: 50,
                },
                xAxis: {
                    type: "category",
                    name: "",
                    data: xAxisData,
                    nameTextStyle: {
                        fontSize: 12,
                        color: "#9AB4E3",
                    },
                    boundaryGap: true, // 默认，坐标轴留白策略
                    type: "category",
                    axisLabel: {
                        // interval: 0,
                        // rotate: 45,
                        textStyle: {
                            color: "#A8C8FF",
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#0f5378",
                        },
                    },
                    splitArea: {
                        color: "#f00",
                        lineStyle: {
                            color: "#e2e9ff",
                        },
                    },
                },
                yAxis: {
                    name: "个",
                    type: "value",
                    minInterval: 1,
                    nameTextStyle: {
                        fontSize: 12,
                        color: "#9AB4E3",
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(53,80,124,0.2)",
                        },
                    },
                    axisLabel: {
                        margin: 20,
                        fontSize: 12,
                        textStyle: {
                            color: "#9AB4E3",
                            // color: "rgba(53,80,124,1)",
                        },
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: "rgba(53,80,124,0.2)",
                            width: 2,
                        },
                    },
                },
                // dataZoom: [
                //     {
                //         show: false,
                //         height: 12,
                //         xAxisIndex: [0],
                //         bottom: "8%",
                //         start: 30,
                //         end: 90,
                //         handleIcon:
                //             "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
                //         handleSize: "110%",
                //         handleStyle: {
                //             color: "#d3dee5",
                //         },
                //         textStyle: {
                //             color: "#fff",
                //         },
                //         borderColor: "#90979c",
                //     },
                //     {
                //         type: "inside",
                //         show: false,
                //         height: 15,
                //         start: 10,
                //         end: 35,
                //     },
                // ],
                series: {
                    name: "",
                    type: "bar",
                    barWidth: "20px",
                    itemStyle: {
                        normal: {
                            barBorderRadius: 12,
                        },
                    },
                    data: seriesData,
                },
            };

            let id = `jskzXmsl${this.echartId}`;
            this.echart = this.$echarts.dispose(document.getElementById(id));
            this.echart = this.$echarts.init(document.getElementById(id));
            if (this.echartList.length) {
                this.echart.setOption(option);
            }
        },
    },
};
</script>
<style scoped lang="scss">
.screenItem {
    width: 100%;
    height: 100%;
    .screenCtn {
        width: 100%;
        height: 100%;
        .tableList {
            width: 100%;
            .tableItem {
                width: calc(50% - 5px);
                height: 40px;
                margin-bottom: 5px;

                .titlePart {
                    width: calc(50% - 5px);
                    height: 100%;
                    background: url("~@image/bigScreen/icon/titleBg.png")
                        no-repeat;
                    background-size: 100% 100%;
                    .name {
                        font-size: 18px;
                        color: #a8c8ff;
                    }
                }
                .numPart {
                    width: calc(50% - 5px);
                    height: 100%;
                    border: 1px solid #35507c;
                    margin-left: 5px;
                    .num {
                        font-size: 34px;
                        color: #ffcd80;
                        font-family: "DBPM";
                    }
                }
            }
        }
        .condition {
            height: 20px;
            margin-top: 5px;
            position: relative;
            .bg {
                width: 100%;
                height: 2px;
                background: rgba(53, 80, 124, 0.4);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
            }
            .selectPart {
                position: relative;
                z-index: 10;
            }
        }
        .echartPart {
            margin-top: 5px;
            height: calc(100% - 100px);
            position: relative;
            .echart {
                width: 100%;
                height: 100%;
            }
            .noData {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
