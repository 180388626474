<template>
    <div class="count-and-fouds">
        <!-- 工程数量与经费 -->
        <basic-module :text="common.getFyName(fany.gxslyjf)">
            <template slot="basic-module">
                <div :id="id" class="chart_box"></div>
            </template>
        </basic-module>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            dataConfig: null,
            chart: null,
            id: "gcslyjf" + Math.random(),
        };
    },
    mounted() {
        this.getData();
        $(window).resize(() => {
            setTimeout(() => {
                this.chart && this.chart.resize();
            }, 300);
        });
    },
    methods: {
        ...mapActions(["getBhgcSlJFStatistics"]),
        async getData() {
            const res = await this.getBhgcSlJFStatistics();
            this.dealData(res);
        },
        dealData(list = []) {
            const seriesData1 = [];
            const seriesData2 = [];
            const xAxisData = [];
            if (Array.isArray(list) && list.length > 0) {
                list.forEach((ele) => {
                    xAxisData.push(ele.YEAR);
                    seriesData1.push(ele.TRJFZE);
                    seriesData2.push(ele.GCZS);
                });
            }
            const main_color = "#679FC0";

            this.dataConfig = {
                tooltip: {
                    trigger: "axis",
                    formatter: function (params) {
                        let result = `<span style="margin-left:14px;font-size:14px;">${params[0]["axisValueLabel"]}年：<br>
                        <span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${params[0]["color"]}"></span>${params[0]["seriesName"]}：${params[0]["value"]}万元<br>
                        <span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${params[1]["color"]}"></span>${params[1]["seriesName"]}：${params[1]["value"]}个<br>
                        `;
                        return result;
                    },
                },
                legend: {
                    icon: "rect",
                    itemWidth: 14,
                    itemHeight: 5,
                    itemGap: 13,
                    textStyle: {
                        color: "rgba(196,214,225,1)",
                    },
                },
                grid: {
                    top: 40,
                    bottom: 40,
                    left: 40,
                    right: 40,
                },
                xAxis: {
                    data: xAxisData,
                    axisLine: {
                        lineStyle: {
                            color: main_color,
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                },
                yAxis: [
                    {
                        type: "value",
                        name: "经费总额(万元)",
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: main_color,
                            },
                        },
                    },
                    {
                        type: "value",
                        name: "工程数量(个)",
                        axisTick: {
                            show: false,
                        },
                        minInterval: 1,
                        splitLine: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: main_color,
                            },
                        },
                    },
                ],
                series: [
                    {
                        type: "line",
                        name: "经费总额",
                        smooth: true,
                        showSymbol: false,
                        lineStyle: {
                            color: "rgb(231,100,82)",
                        },
                        itemStyle: {
                            color: "rgb(231,100,82)",
                            borderColor: "#fff",
                            borderWidth: 3,
                            shadowColor: "rgba(0, 0, 0, .3)",
                            shadowBlur: 0,
                        },
                        areaStyle: {
                            color: new this.$echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    {
                                        offset: 0,
                                        color: "rgba(231,100,82,1)",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "rgba(231,100,82,0)",
                                    },
                                ],
                                false
                            ),
                        },
                        data: seriesData1,
                    },
                    {
                        type: "line",
                        name: "工程数量",
                        smooth: true,
                        showSymbol: false,
                        lineStyle: {
                            color: "rgb(90,217,166)",
                        },
                        itemStyle: {
                            color: "rgb(90,217,166)",
                            borderColor: "#fff",
                            borderWidth: 3,
                            shadowColor: "rgba(0, 0, 0, .3)",
                            shadowBlur: 0,
                        },
                        areaStyle: {
                            color: new this.$echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    {
                                        offset: 0,
                                        color: "rgba(90,217,166,1)",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "rgba(90,217,166,0)",
                                    },
                                ],
                                false
                            ),
                        },
                        yAxisIndex: 1,
                        data: seriesData2,
                    },
                ],
            };
            this.draw();
        },
        draw() {
            this.chart = this.$echarts.init(document.getElementById(this.id));
            this.chart.setOption(this.dataConfig);
        },
    },
};
</script>

<style lang="scss" scoped>
.count-and-fouds {
    .chart_box {
        height: 100%;
    }
}
</style>
