import { render, staticRenderFns } from "./1_bhgctj.vue?vue&type=template&id=b0ceef1a&scoped=true&"
import script from "./1_bhgctj.vue?vue&type=script&lang=js&"
export * from "./1_bhgctj.vue?vue&type=script&lang=js&"
import style0 from "./1_bhgctj.vue?vue&type=style&index=0&id=b0ceef1a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0ceef1a",
  null
  
)

export default component.exports