<template>
    <div class="commonDialogPart">
        <div class="popupTitle">
            <div class="titleCenter flex-x-center"
                 :title="commonDialog.title">
                <img src="@image/bigScreen/monitor_all/sideDialog/left.png" />
                <div class="titleContent ellipsis">
                    {{ commonDialog.title }}
                </div>
                <img src="@image/bigScreen/monitor_all/sideDialog/arrow.png"
                     class="arrow" />
                <img src="@image/bigScreen/monitor_all/sideDialog/right.png" />
            </div>
            <i class="el-icon-close closeIcon pointer"
               @click="closeComDialog()"></i>
        </div>
        <div class="popupCtn">
            <DialogJg :detail="commonDialog.detail"
                      v-if="commonDialog.jgDialog"></DialogJg>
            <!-- width="1440px"
                height="900px" -->
            <DialogSxt width="1341px"
                       height="894px"
                       :detail="commonDialog.detail"
                       v-if="commonDialog.sxtDialog"></DialogSxt>
            <DialogGcs :detail="commonDialog.detail"
                       v-if="commonDialog.gcsDialog"></DialogGcs>
            <DialogGsmm :detail="commonDialog.detail"
                        v-if="commonDialog.gsmmDialog"></DialogGsmm>
            <DialogYcys :detail="commonDialog.detail"
                        v-if="commonDialog.ycysDialog"></DialogYcys>
            <DialogYcysMj :detail="commonDialog.detail"
                          v-if="commonDialog.ycysMjDialog"></DialogYcysMj>
            <DialogHistoryHj :detail="commonDialog.detail"
                             v-if="commonDialog.hjDialog"></DialogHistoryHj>
            <DialogOthersYj :detail="commonDialog.detail"
                            v-if="commonDialog.othersDialog"></DialogOthersYj>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import infoMixin from "./info_mixin.js";
export default {
    mixins: [infoMixin],
    name: "",
    components: {},
    data () {
        return {
            // marker弹出框
            commonDialog: {
                title: "",
                detail: {},
                dialog: false,
                gcsDialog: false, //古茶树
                gsmmDialog: false, //古树名木
                ycysDialog: false, //遗产要素
                ycysMjDialog: false, //遗产要素 民居
                hjDialog: false, //历史火警
                othersDialog: false, //预警
            },
        };
    },
    computed: {
        ...mapGetters({}),
    },
    watch: {},
    created () { },
    destroyed () { },
    mounted () { },
    methods: {
        ...mapMutations({}),
        getDetail (obj) {
            this.closeAllDialog();
            this.commonDialog[obj.dialog] = true;
            this.commonDialog.detail = obj.detail;
            this.commonDialog.title = obj.title;
        },
        // 关闭
        closeComDialog () {
            this.$emit("closeComDialog");
        },
        // 关闭所有弹框
        closeAllDialog () {
            this.commonDialog.jgDialog = false; //景观视线 景观风貌 弹出框
            this.commonDialog.sxtDialog = false; //摄像头 弹出框
            this.commonDialog.gcsDialog = false; //古茶树
            this.commonDialog.gsmmDialog = false; //古树名木
            this.commonDialog.ycysDialog = false; //遗产要素
            this.commonDialog.ycysMjDialog = false; //遗产要素民居
            this.commonDialog.hjDialog = false; //历史火警
            this.commonDialog.othersDialog = false; //预警
        },
    },
};
</script>
<style scoped lang="scss">
.commonDialogPart {
    height: 100%;
    background: #040e1e;
    .popupTitle {
        width: 100%;
        height: 41px;
        position: relative;
        // 标题内容
        .titleCenter {
            height: 100%;
            font-size: 18px;
            color: rgb(210, 234, 246);
            text-align: center;
            .titleContent {
                min-width: 200px;
                max-width: 456px;
                background: url('~@image/bigScreen/monitor_all/sideDialog/line.png')
                    no-repeat;
                background-size: 100% 100%;
            }
            .arrow {
                position: absolute;
                bottom: 0;
                right: 50%;
                transform: translateX(-50%);
            }
        }
        // 标题按钮
        .closeIcon {
            position: absolute;
            top: 50%;
            right: 40px;
            transform: translateY(-50%);
            color: #ffffff;
            font-size: 24px;
        }
    }
    .popupCtn {
        width: 100%;
        padding: 20px 14px 10px 14px;
        height: calc(100% - 30px);
    }
}
</style>
