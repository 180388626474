<template>
    <div class="yjczItemPart">
        <div class="tableItem" v-for="(item, index) in listAry" :key="index">
            <div class="label">{{ item.name }}</div>
            <div class="detail pointer flex-1 flex-y-center" :title="item.desc">
                {{ item.desc }}
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "",
    props: {
        id: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            sllxList: [
                {
                    ID: 1,
                    LABEL: "警报",
                },
                {
                    ID: 0,
                    LABEL: "误报",
                },
            ],
            yjdjList: [], // 预警等级列表
            yjhslxList: [], // 预警核实-类型列表
            listAry: [
                {
                    name: "受理为",
                    desc: "",
                },
                {
                    name: "预警等级",
                    desc: "",
                },
                {
                    name: "类型",
                    desc: "",
                },
                {
                    name: "受理说明",
                    desc: "",
                },
            ],
        };
    },
    watch: {},
    mounted() {
        this.getYjdjList(); //预警等级列表
        this.getYjxList(); //预警类型列表
        this.getYjhsData(); //预警核实数据
    },
    methods: {
        ...mapActions([
            "GetEnumIdList", // 获取预警等级的枚举
            "GetYjxList", // 获取预警核实-类型数据
            "GetYjhsData", // 获取预警核实数据
        ]),
        // 获取预警等级列表
        async getYjdjList() {
            this.yjdjList = [];
            let result = await this.GetEnumIdList({
                enumId: "212cb2aa-748a-11ea-bd8d-000c2977b7fd",
            });
            if (result.length) {
                this.yjdjList = result;
            }
        },
        // 获取预警核实-类型列表
        async getYjxList() {
            this.yjhslxList = [];
            let result = await this.GetYjxList();
            if (result.length) {
                this.yjhslxList = result;
            }
        },
        // 获取预警核实数据
        async getYjhsData() {
            let result = await this.GetYjhsData({
                yjsjId: this.id,
            });
            if (result.length) {
                let obj = result[0];
                this.listAry[0].desc = this.common.aryListFindOne(
                    this.sllxList,
                    "ID",
                    obj.SFYXYJ
                ).LABEL; //受理为
                this.listAry[1].desc = this.common.aryListFindOne(
                    this.yjdjList,
                    "CODE",
                    obj.HSDJ
                ).NAME;
                //预警等级
                this.listAry[2].desc = this.common.aryListFindOne(
                    this.yjhslxList,
                    "ID",
                    obj.HSYJLX
                ).YJDXMC; //类型
                this.listAry[3].desc = obj.SLSM; //受理说明
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.yjczItemPart {
    .tableItem {
        margin-bottom: 10px;
        .label {
            color: #5673a2;
            font-size: 14px;
            margin-bottom: 6px;
        }
        .detail {
            min-height: 26px;
            color: #a8c8ff;
            font-size: 14px;
            background: rgba(53, 80, 124, 0.2);
            text-indent: 5px;
        }
    }
}
</style>
