// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@image/bigScreen/ontological_disease/stateIcon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".stateBox[data-v-7dd7fb8a]{display:flex}.stateBox .left[data-v-7dd7fb8a],.stateBox .right[data-v-7dd7fb8a]{width:92px;height:92px;display:inline-block;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain}.stateBox .center[data-v-7dd7fb8a]{flex:1;display:flex;align-items:center;justify-content:center}.stateBox .center .main[data-v-7dd7fb8a]{position:relative;width:calc(100% - 20px);height:50px;border-top:2px solid #35507c;border-bottom:2px solid #35507c;font-size:30px}.stateBox .center .main .name[data-v-7dd7fb8a]{color:#a8c8ff}.stateBox .center .main .state[data-v-7dd7fb8a]{margin-left:50px}.stateBox .center .main .lineBox[data-v-7dd7fb8a]{position:absolute;top:-10px;left:-3px;right:-3px;bottom:-10px}.stateBox .center .main .lineBox .line[data-v-7dd7fb8a]{width:2px;height:10px;display:block;background-color:#35507c;position:absolute}.stateBox .center .main .lineBox .line.line1[data-v-7dd7fb8a]{top:0;left:0;transform:rotate(-30deg)}.stateBox .center .main .lineBox .line.line2[data-v-7dd7fb8a]{top:0;right:0;transform:rotate(30deg)}.stateBox .center .main .lineBox .line.line3[data-v-7dd7fb8a]{bottom:0;left:0;transform:rotate(-150deg)}.stateBox .center .main .lineBox .line.line4[data-v-7dd7fb8a]{bottom:0;right:0;transform:rotate(150deg)}.stateBox .center .main .shapeBox[data-v-7dd7fb8a]{width:90px;height:calc(100% + 84px);position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}.stateBox .center .main .shapeBox .shape[data-v-7dd7fb8a]{display:inline-block;width:28px;height:0;border-width:0 3px 4px 3px;border-style:none solid solid;border-color:transparent transparent #aac6fc;transform:rotate(180deg)}.stateBox .center .main .shapeBox .shape.shapRotate[data-v-7dd7fb8a]{transform:rotate(0)}.stateBox .center .main .shapeBox .top[data-v-7dd7fb8a]{height:60px}", ""]);
// Exports
module.exports = exports;
