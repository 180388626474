<template>
    <div class="buildControlSxt">
        <div class="monitorPart flex-between" v-if="!videoState">
            <div class="popLeft">
                <div class="carouselArrowShow">
                    <el-carousel
                        indicator-position="none"
                        height="210px"
                        :autoplay="false"
                        :arrow="
                            detail.PICTUREPATH.length > 1 ? 'hover' : 'never'
                        "
                        v-viewer
                    >
                        <el-carousel-item
                            v-for="(item, index) in detail.PICTUREPATH"
                            :key="index"
                            class="pointer"
                        >
                            <el-image
                                :src="item"
                                fit="cover"
                                class="pointer"
                                style="width: 100%; height: 100%"
                            >
                                <div
                                    slot="error"
                                    style="width: 100%; height: 100%"
                                >
                                    <SnNoData width="150px"></SnNoData>
                                </div>
                            </el-image>
                        </el-carousel-item>
                    </el-carousel>
                    <img
                        v-if="detail.typeName == '景观风貌监测'"
                        :src="
                            require('@image/bigScreen/protect_project/legend/monitor.png')
                        "
                        class="sxtIcon pointer"
                        @click="videoState = true"
                    />
                </div>
                <div class="introPart">
                    <div class="name">简介</div>
                    <div class="ctn" :title="detail.FXMS">
                        {{ detail.FXMS }}
                    </div>
                </div>
            </div>
            <div class="popRight">
                <div class="statePart">
                    <div
                        class="state flex-both-center"
                        :style="{ color: getColor() }"
                    >
                        {{ detail.YXPG_DESC }}
                    </div>
                    <div class="shapeBox">
                        <div class="top">
                            <i class="shape"></i>
                            <i class="shape shapRotate"></i>
                            <i class="shape"></i>
                        </div>
                        <div class="bottom">
                            <i class="shape shapRotate"></i>
                            <i class="shape"></i>
                            <i class="shape shapRotate"></i>
                        </div>
                    </div>
                </div>
                <div class="label">监测开始时间</div>
                <div class="detail">
                    {{ detail.JCKSSJ }}
                </div>
                <div class="label">监测次数</div>
                <div class="detail">{{ detail.JCCS }}次</div>
                <div class="changPart flex-between">
                    <div class="changItem">
                        <div class="num">
                            {{ detail.BHCS }}
                        </div>
                        <div class="desc">变化次数</div>
                    </div>
                    <div class="changItem">
                        <div class="numBad">
                            {{ detail.FMYXCS }}
                        </div>
                        <div class="desc">负面影响次数</div>
                    </div>
                </div>
                <div class="tableList">
                    <TableList
                        :maxHeight="94"
                        :tableColums="tableColums"
                        :tableData="detail.MONITORS"
                    ></TableList>
                </div>
            </div>
        </div>
        <div v-else class="sxtPart">
            <i
                class="el-icon-close closeBtn pointer"
                @click="videoState = false"
            ></i>
            <DialogSxt :detail="detail"></DialogSxt>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import TableList from "@views/big_screen/comp/TableList";
// import DialogSxt from "@views/big_screen/monitor_all/4_page_side/comp/DialogSxt.vue"; //摄像头 弹出框
import DialogSxt from "@views/big_screen/monitor_all/4_page_side/comp/DialogSxtIframe.vue"; //摄像头 iframe 公司 live-player 厂家提供的插件 第三方的监控

export default {
    components: {
        TableList,
        DialogSxt,
    },
    name: "",
    data() {
        return {
            tableColums: [
                {
                    prop: "TIME",
                    titleProp: "CJSJ",
                    label: "采集时间",
                    width: 80,
                },
                {
                    prop: "QKSM",
                    label: "情况说明",
                    width: 160,
                },
                {
                    //异常评估枚举(
                    // 0正面影响 绿；1兼有正、负面影响 红色ff4c4c ；2负面影响 红色；3目前尚不确定 黄 ffb508；4无影响 蓝2EA7E0
                    propColorNum: "YXPG",
                    colorArr: [
                        "#00bd8d",
                        "#ff4c4c",
                        "#ff4c4c",
                        "#ffb508",
                        "#2EA7E0",
                    ],
                    prop: "YXPGDESC",
                    label: "异常评估",
                    width: 120,
                },
            ],
            tableData: [],
            videoState: false,
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {
        detail: {
            Type: Object,
            default: () => {
                let obj = {
                    BHCS: "", //变化次数
                    FMYXCS: "", //负面影响次数
                    ID: "",
                    JCCS: "", //监测次数
                    JCKSSJ: "", //监测开始时间
                    JD: "",
                    MC: "", //名称
                    MONITORS: [], //监测数据
                    PICTUREPATH: [], //基础数据照片
                    WD: "",
                    YXPG: "", //0正面影响；1兼有正、负面影响；2负面影响；3目前尚不确定；4无影响
                    YXPG_DESC: "", //影响评估枚举翻译
                    ZB: "",
                };

                return obj;
            },
        },
    },
    watch: {},
    mounted() {
        console.log(
            "%c 🍗 detail: ",
            "font-size:20px;background-color: #2EAFB0;color:#fff;",
            this.detail
        );
    },
    methods: {
        ...mapActions([]),
        ...mapMutations({}),
        getColor() {
            let color = "";
            switch (this.detail.YXPG) {
                case "0":
                    color = "#00bd8d";
                    break;
                case "1":
                case "2":
                    color = "#ff4c4c";
                    break;
                case "3":
                    color = "#ffb508";
                    break;
                case "4":
                    color = "#2EA7E0";
                    break;
            }
            return color;
        },
    },
};
</script>
<style scoped lang="scss">
.buildControlSxt {
    width: 100%;
    height: 100%;
    .monitorPart {
        width: 100%;
        height: 100%;
        .popLeft {
            width: 313px;
            height: 100%;
            position: relative;
            margin-right: 10px;
            .sxtIcon {
                position: absolute;
                right: 10px;
                top: 10px;
                z-index: 10;
                width: 20px;
                height: 20px;
            }
            .introPart {
                margin-top: 10px;
                .name {
                    color: rgba(86, 115, 162, 1);
                    font-size: 14px;
                }
                .ctn {
                    margin-top: 5px;
                    color: rgba(189, 215, 236, 1);
                    font-size: 12px;
                    background: rgba(53, 80, 124, 0.2);
                    padding: 4px 10px;
                    max-height: 240px;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                }
            }
        }
        .popRight {
            width: 400px;
            height: 100%;
            .statePart {
                width: 100%;
                height: 30px;
                position: relative;
                .state {
                    width: 100%;
                    height: 100%;
                    background: rgba(53, 80, 124, 0.2);
                    color: rgba(189, 215, 236, 1);
                    font-size: 16px;
                }
                .shapeBox {
                    width: 90px;
                    height: 100%;
                    position: absolute;
                    top: -20px;
                    left: 50%;
                    transform: translateX(-50%);
                    .shape {
                        display: inline-block;
                        width: 28px;
                        height: 0;
                        border-width: 0 3px 4px 3px;
                        border-style: none solid solid;
                        border-color: transparent transparent #aac6fc;
                        transform: rotate(180deg);
                        &.shapRotate {
                            transform: rotate(0);
                        }
                    }
                    .top {
                        height: 40px;
                    }
                }
            }
            .label {
                color: rgba(86, 115, 162, 1);
                font-size: 14px;
                margin-top: 10px;
            }
            .detail {
                color: rgba(168, 200, 255, 1);
                font-size: 14px;
                background: rgba(53, 80, 124, 0.2);
                padding: 4px 10px;
                height: 29px;
            }
            .changPart {
                width: 100%;
                height: 60px;
                margin-top: 10px;
                .changItem {
                    width: calc(50% - 5px);
                    height: 100%;
                    background: rgba(53, 80, 124, 0.3);
                    text-align: center;
                    .num {
                        color: rgba(255, 205, 128, 1);
                        font-size: 26px;
                    }
                    .numBad {
                        color: rgba(214, 3, 28, 1);
                        font-size: 26px;
                    }
                    .desc {
                        color: rgba(168, 200, 255, 1);
                        font-size: 12px;
                    }
                }
            }
            .tableList {
                margin-top: 10px;
                width: 100%;
                height: 94px;
                position: relative;
            }
        }
    }
    .sxtPart {
        position: relative;
        padding-bottom: 20px;
        .closeBtn {
            position: absolute;
            right: 10px;
            top: 10px;
            z-index: 10;
            color: #fff;
            font-size: 24px;
        }
    }
}
</style>
