<template>
    <div class="screenItem">
        <YcysList
            ref="ycysList"
            :ycysList="ycysList"
            :ycysTotal="ycysTotal"
            :yczcLState="false"
            @ycysTypeItem="ycysTypeItem"
            @getYcysItem="getYcysItem"
            @getKeyWord="getKeyWord"
        ></YcysList>
    </div>
</template>

<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import YcysList from "@views/big_screen/comp/BasicYcys";
export default {
    components: {
        YcysList,
    },
    name: "",
    data() {
        return {
            keyWord: "",
            ycyslx: "", //遗产要素类型
            state: true, //true 还有数据 false 没有数据了
            keyWords: "",
            pageIndex: 1,
            pageSize: 30,
            totalNum: 0,
            ycysList: [], //遗产要素
            ycysTotal: 0,
        };
    },
    computed: {
        ...mapGetters(["btbhYcys", "btbhPanelNum"]),
    },
    props: {},
    watch: {
        btbhYcys: {
            deep: true,
            handler() {
                if (
                    this.btbhYcys &&
                    (Object.keys(this.btbhYcys).length == 0 ||
                        !this.btbhYcys.BM)
                ) {
                    this.$refs.ycysList.reSetYcys();
                }
            },
        },
    },
    mounted() {
        this.getYcysList();
    },
    methods: {
        ...mapActions(["getBtbhYcysList"]),
        ...mapMutations({
            setStateBtbh: "SET_STATE_BTBH",
        }),
        // 获取 遗产要素
        async getYcysList() {
            let result = await this.getBtbhYcysList({
                keyword: this.keyWord,
            });
            if (!result) {
                return;
            }
            this.ycysList = [];
            if (this.ycyslx) {
                let list = result.RESULT.filter((item) => {
                    return item.YCYSFL == this.ycyslx;
                });
                this.ycysList = (list.length > 0 && list[0].YCYSLIST) || [];
            } else {
                result.RESULT.forEach((item) => {
                    this.ycysList.push(...item.YCYSLIST);
                });
            }
            this.ycysTotal = this.ycysList.length;
        },
        // 某一个遗产要素类型
        ycysTypeItem(item) {
            this.ycyslx = (item && item.name) || "";
            this.getYcysList();
            this.setStateBtbh({
                btbhYcysType: item ? item.name : null,
                btbhYcys: null, // 被点击的遗产要素
            });
        },
        // 某一个遗产要素
        async getYcysItem(item) {
            const ycysbm = (item && item.BM) || "";
            // 保存当前 被点击遗产要素信息
            this.setStateBtbh({
                // btbhPanelNum: ycysbm ? 1 : 0, // 右侧页面切换
                btbhYcys: item, // 被点击的遗产要素
            });
        },
        getKeyWord(item) {
            this.keyWord = item;
            this.getYcysList();
        },
    },
};
</script>

<style lang="scss" scoped>
.screenItem {
    width: 100%;
    height: 100%;

    .screenCtn {
        width: 100%;
        height: 100%;
    }
}
</style>
