<template>
    <div class="basicYcys">
        <!-- 遗产要素 -->
        <BasicMonitor :text="common.getFyName(fany.ycys)" :ycysState="true">
            <div slot="basic-module" class="ycysPart flex-between">
                <el-input
                    class="keyword"
                    :class="[jczlFyNum == 0 ? '' : 'enKeyword']"
                    placeholder="请输入遗产要素名称"
                    v-model="keyWord"
                    clearable
                    @input="getYcysListByYczc()"
                >
                </el-input>

                <!-- 左侧 -->
                <div class="ycysLeft">
                    <!-- 遗产要素类型 -->
                    <div class="ycysTypePart scrollbar-hidden">
                        <div
                            v-for="(item, index) in ycysTypeList"
                            :key="index"
                            class="animated bounceInRight ycysTypeItem"
                            :style="{ animationDelay: 0.2 + index * 0.2 + 's' }"
                            @click="changeYcysType(item, index)"
                        >
                            <el-image
                                :src="
                                    index == ycysTypeActiveNum
                                        ? item.srcActive
                                        : item.srcDefault
                                "
                                fit="cover"
                                class="pointer"
                                style="width: 100%; height: 100%"
                            >
                                <div
                                    slot="error"
                                    style="width: 100%; height: 100%"
                                >
                                    <el-image
                                        fit="cover"
                                        :src="noImg"
                                        style="width: 100%; height: 100%"
                                    ></el-image>
                                </div>
                            </el-image>
                        </div>
                    </div>
                    <img
                        src="@image/bigScreen/icon/decorateYcys.png"
                        class="decorateYcys"
                    />
                </div>
                <!-- 右侧 -->
                <div class="ycysRightPart flex-1">
                    <div class="ycysRightItem">
                        <div class="label flex-between">
                            <span>遗产要素</span>
                            <span v-if="ycysTypeActiveNum == 0">
                                <span>5片古茶林面积共</span>
                                <span style="color: #61ff00">1180</span>
                                <span>公顷</span>
                            </span>

                            <span v-if="ycysTypeActiveNum == 1">
                                <span>3片分隔防护林面积共</span>
                                <span style="color: #61ff00">617.65</span>
                                <span>公顷</span>
                            </span>
                        </div>
                        <div class="ycysRightList scrollbar-hidden">
                            <div
                                v-for="(item, index) in yczcList"
                                :key="index"
                                class="
                                    animated
                                    bounceInRight
                                    ycysItem
                                    flex-between
                                "
                                :style="{
                                    animationDelay: 0.2 + index * 0.2 + 's',
                                }"
                            >
                                <div
                                    class="ycysItemInner flex-between"
                                    v-for="(itemInner, indexInner) in item"
                                    :key="indexInner"
                                    @click="getYczcDetail(itemInner, index)"
                                >
                                    <div class="state flex-both-center">
                                        <div
                                            class="steteIcon stateLeftTop"
                                        ></div>
                                        <div
                                            class="steteIcon stateRightTop"
                                        ></div>
                                        <div
                                            class="steteIcon stateLeftBottom"
                                        ></div>
                                        <div
                                            class="steteIcon stateRightBottom"
                                        ></div>
                                        <div
                                            class="circle"
                                            :style="{
                                                borderColor:
                                                    common.getYcysStateColor(
                                                        itemInner.ZTMC
                                                    ),
                                            }"
                                        ></div>
                                    </div>
                                    <div
                                        class="name pointer flex-1 ellipsis"
                                        :class="[
                                            yczcActiveNum === itemInner.index
                                                ? 'nameActive'
                                                : 'nameDefault',
                                        ]"
                                        :title="
                                            itemInner.MJ
                                                ? itemInner.YCYSFL_2 +
                                                  itemInner.MJ +
                                                  '公顷'
                                                : itemInner.YCYSFL_2
                                        "
                                    >
                                        <span>{{ itemInner.YCYSFL_2 }}</span>
                                        <!-- <span
                                            v-if="itemInner.MJ"
                                            style="
                                                margin-left: 4px;
                                                color: #61ff00;
                                            "
                                            >{{ itemInner.MJ }}</span
                                        > -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="linePart">
                        <div class="lineTop flex">
                            <div class="line1"></div>
                            <div class="line2"></div>
                            <div class="line3"></div>
                            <div class="line4"></div>
                        </div>
                    </div>
                    <div class="ycysRightItem">
                        <div class="label">
                            <span>遗产要素单体</span>
                            <!-- <span>{{ ycysLength }}</span> -->
                        </div>
                        <div class="ycysRightList scrollbar-hidden">
                            <div
                                v-for="(item, index) in ycysList"
                                :key="index"
                                class="
                                    animated
                                    bounceInRight
                                    ycysItem
                                    flex-between
                                "
                                :style="{
                                    animationDelay: 0.2 + index * 0.2 + 's',
                                }"
                            >
                                <div
                                    class="ycysItemInner flex-between"
                                    v-for="(itemInner, indexInner) in item"
                                    :key="indexInner"
                                    @click="getYcysDetail(itemInner, index)"
                                >
                                    <div class="state flex-both-center">
                                        <div
                                            class="steteIcon stateLeftTop"
                                        ></div>
                                        <div
                                            class="steteIcon stateRightTop"
                                        ></div>
                                        <div
                                            class="steteIcon stateLeftBottom"
                                        ></div>
                                        <div
                                            class="steteIcon stateRightBottom"
                                        ></div>
                                        <div
                                            class="circle"
                                            :style="{
                                                borderColor:
                                                    common.getYcysStateColor(
                                                        itemInner.ZTNAME
                                                    ),
                                            }"
                                        ></div>
                                    </div>
                                    <div
                                        class="name pointer flex-1 ellipsis"
                                        :class="[
                                            ycysActiveNum === itemInner.index
                                                ? 'nameActive'
                                                : 'nameDefault',
                                        ]"
                                        :title="itemInner.MC"
                                    >
                                        {{ itemInner.MC }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BasicMonitor>
        <!-- 图例 -->
        <div class="legendPart flex-between-y-center" v-if="legendState">
            <div
                v-for="(item, index) in legendColorList"
                :key="index"
                class="flex-y-center animated bounceInRight"
                :style="{ animationDelay: 0.2 + index * 0.2 + 's' }"
            >
                <div class="triangle" :style="{ background: item.color }"></div>
                <div class="name">
                    {{ item[ycysKey.legendType == 0 ? "label" : "desc"] }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BasicMonitor from "@views/big_screen/monitor_all/comp/BasicModule";
import * as TYPES from "@store/modules/mutation-type.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { yczcFormatList } from "@libs/yczcFormatList"; //遗产组成 合并经纬度
export default {
    components: {
        BasicMonitor,
    },
    name: "",
    data() {
        return {
            keyWord: "",
            ycysTypeActiveNum: null, //要素类型
            ycysActiveNum: null, //遗产要素
            yczcActiveNum: null, //遗产组成
            noImg: require("@image/bigScreen/noImg.png"),
            legendColorList: [],
            yczcList: [], //遗产组成
            ycysList: [], //遗产要素
            ycysLength: 0, //遗产要素的数量
            ycysTypeList: [
                {
                    name: "古茶林",
                    nameMap: "古茶林",
                    srcActive: require("@image/bigScreen/ycysType/gclActive.png"),
                    srcDefault: require("@image/bigScreen/ycysType/gclDefault.png"),
                    area: 1180,
                },
                {
                    name: "分隔防护林",
                    nameMap: "分隔防护林",
                    srcActive: require("@image/bigScreen/ycysType/fgfhlActive.png"),
                    srcDefault: require("@image/bigScreen/ycysType/fgfhlDefault.png"),
                    area: 617.65,
                },
                {
                    name: "传统村落",
                    nameMap: "遗产要素村寨",
                    srcActive: require("@image/bigScreen/ycysType/ctclActive.png"),
                    srcDefault: require("@image/bigScreen/ycysType/ctclDefault.png"),
                    area: 0,
                },
            ],
            ycyslx: "", //遗产要素类型
            yczclx: "", //遗产组成类型
            yczs: 0, // 遗产总数
            jczlYczcItem: null,
        };
    },
    computed: {
        ...mapGetters({
            jczlYcysItem: TYPES.jczlYcysItem,
            jczlFyNum: TYPES.jczlFyNum,
        }),
    },
    props: {
        // 图例 显示 隐藏
        legendState: {
            type: Boolean,
            default: () => {
                return true;
            },
        },
        // 遗产要素的key值
        ycysKey: {
            type: Object,
            default: () => {
                let obj = {
                    legendType: 1, //0可控  轻微 严重  非常严重 1 好 良好  一般 差
                    name: "MC", //名称
                    src: "CCLJ", //图片路径
                    stateNum: "ZT", // 状态 0 可控/好 1 轻微/良好 2 严重/一般 3 非常严重/差   ------- 空
                };
                return obj;
            },
        },
    },
    watch: {
        jczlYcysItem: {
            immediate: false,
            handler() {
                this.reSetActiveYcys();
            },
        },
    },
    mounted() {
        let ary = Array.from(window.YCYS_COLOR_LIST.slice(0, -1));
        this.legendColorList = ary.reverse();
        this.getYczcList();
        this.getYcysList();
    },
    methods: {
        ...mapActions(["GetYcysState", "GetYczcList"]),
        ...mapMutations({
            SET_JCZLYCYSTYPE: TYPES.SET_JCZLYCYSTYPE,
            SET_JCZLYCZCITEM: TYPES.SET_JCZLYCZCITEM,
            SET_JCZLYCYSLIST: TYPES.SET_JCZLYCYSLIST,
            SET_JCZLYCZCITEMYCYSLIST: TYPES.SET_JCZLYCZCITEMYCYSLIST,
            SET_JCZLYCYSITEM: TYPES.SET_JCZLYCYSITEM,
        }),

        // 获取 遗产组成
        async getYczcList() {
            let result = await this.GetYczcList({
                ycyslx: this.ycyslx ? this.ycyslx : "",
                typeNum: 1, //只在大屏上使用
            });

            let list = Array.from(result);
            list.map((item, index) => {
                yczcFormatList.map((itemInner) => {
                    if (item.YCYSFL_2 == itemInner.YCYSFL_2) {
                        item.JD = itemInner.JD;
                        item.WD = itemInner.WD;
                        item.LEVEL = itemInner.LEVEL;
                    }
                });
                if (item.MJ == "0.0") {
                    item.MJ = 0;
                }
                item.index = index;
            });
            this.yczcList = this.common.arrayDevide(list, 2);
        },
        // 遗产组成 变化
        getYczcDetail(item, index) {
            this.ycysActiveNum = null;
            if (this.yczclx == item.YCYSFL_2) {
                this.yczcActiveNum = null;
                this.yczclx = "";
                this.SET_JCZLYCZCITEM(null);
                this.SET_JCZLYCZCITEMYCYSLIST([]);
            } else {
                this.yczcActiveNum = item.index;
                this.yczclx = item.YCYSFL_2;
                this.SET_JCZLYCZCITEM(item);
            }
            this.getYcysListByYczc();
        },
        // 获取 遗产要素 从 遗产组成
        async getYcysListByYczc() {
            let result = await this.GetYcysState({
                keyword: this.keyWord,
                ycyslx: this.ycyslx ? this.ycyslx : "",
                yczc: this.yczclx ? this.yczclx : "",
                typeNum: 1, //只在大屏上使用
            });
            let list = Array.from(result.DATA);
            this.ycysLength = list.length;
            list.map((item, index) => {
                item.index = index;
            });
            this.SET_JCZLYCYSITEM(null);
            this.ycysList = this.common.arrayDevide(list, 2);
            this.SET_JCZLYCYSLIST(result.DATA);
            this.SET_JCZLYCZCITEMYCYSLIST(result.DATA);
            this.yczs = result.COUNT;
        },
        // 获取 遗产要素
        async getYcysList() {
            let result = await this.GetYcysState({
                ycyslx: this.ycyslx ? this.ycyslx : "",
                yczc: this.yczclx ? this.yczclx : "",
                typeNum: 1, //只在大屏上使用
            });

            let list = Array.from(result.DATA);
            this.ycysLength = list.length;
            list.map((item, index) => {
                item.index = index;
            });
            this.ycysList = this.common.arrayDevide(list, 2);
            this.SET_JCZLYCYSLIST(result.DATA);
            this.yczs = result.COUNT;
        },
        // 遗产要素类型切换
        changeYcysType(item, index) {
            this.ycysActiveNum = null;
            this.yczcActiveNum = null;
            if (index === this.ycysTypeActiveNum) {
                this.ycysTypeActiveNum = null;
                this.ycyslx = "";
                this.SET_JCZLYCYSTYPE(null);
            } else {
                this.ycysTypeActiveNum = index;
                this.ycyslx = item.name;
                this.SET_JCZLYCYSTYPE(item);
            }
            this.getYczcList();
            this.getYcysList();
            this.SET_JCZLYCZCITEMYCYSLIST([]);
        },
        // 获取遗产要素详情
        getYcysDetail(item, index) {
            if (this.jczlYcysItem && this.jczlYcysItem.BM == item.BM) {
                // this.ycysActiveNum = null;
                this.SET_JCZLYCYSITEM(null);
                this.$emit("getYcysDetail", null, index);
            } else {
                // this.ycysActiveNum = item.index;
                this.SET_JCZLYCYSITEM(item);
                this.$emit("getYcysDetail", item, index);
            }
        },
        // 点击地图上的遗产要素 列表选中
        reSetActiveYcys() {
            if (this.jczlYcysItem) {
                this.ycysList.map((item) => {
                    item.map((item1) => {
                        if (item1.BM == this.jczlYcysItem.BM) {
                            this.ycysActiveNum = item1.index;
                        }
                    });
                });
            } else {
                this.ycysActiveNum = null;
            }
        },
    },
};
</script>
<style scoped lang="scss">
.basicYcys {
    width: 100%;
    height: 100%;
    position: relative;
    // 遗产要素部分
    .ycysPart {
        width: 100%;
        height: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        position: relative;
        .keyword {
            position: absolute;
            top: -34px;
            left: 127px;
            width: 200px;
        }
        .enKeyword {
            top: -34px;
            left: 240px;
        }
        .ycysLeft {
            width: 110px;
            height: 100%;
            background: rgba(26, 48, 89, 0.5);
            margin-right: 15px;
            text-align: center;
            position: relative;
            .decorate {
                width: 100%;
                height: 100px;
                padding-top: 10px;
                .icon {
                    position: relative;
                    width: 90px;
                    height: 10px;
                    margin: 0 auto;
                    &::before {
                        content: ""; /* 用伪元素来生成一个矩形 */
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: -1;
                        background: rgba(53, 80, 124, 1);
                        transform: perspective(10px) rotateX(-10deg);
                    }
                }

                .yczs {
                    width: calc(100% - 30px);
                    font-size: 16px;
                    color: rgba(255, 170, 91, 1);
                    border-left: 2px solid rgba(189, 215, 236, 1);
                    border-right: 2px solid rgba(189, 215, 236, 1);
                    height: 18px;
                    margin: 0 auto;
                    margin-top: 30px;
                }
                .num {
                    font-size: 40px;
                    color: rgba(189, 215, 236, 1);
                    font-family: DBPM;
                    position: relative;
                    top: -10px;
                }
            }
            .ycysTypePart {
                width: 100%;
                height: calc(100% - 100px);
                padding-top: 17px;
                .ycysTypeItem {
                    margin: 0 auto;
                    width: 90px;
                    height: 90px;
                    margin-bottom: 14px;
                }
            }
            .decorateYcys {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 110px;
                height: 79px;
            }
        }
        .ycysRightPart {
            height: calc(100% - 36px);

            .ycysRightItem {
                height: calc(50% - 10px);
                &:nth-child(1) {
                    margin-bottom: 10px;
                }
                .label {
                    font-size: 14px;
                    color: #35507c;
                }
                .ycysRightList {
                    margin-top: 10px;
                    height: calc(100% - 30px);
                    .ycysItem {
                        width: 100%;
                        height: 45px;
                        margin-bottom: 10px;
                        .ycysItemInner {
                            width: 252px;
                            height: 45px;
                        }
                        .state {
                            width: 45px;
                            height: 45px;
                            margin-right: 10px;
                            border: 1px solid rgba(103, 159, 192, 0.3);
                            position: relative;
                            background: rgba(26, 48, 89, 0.4);
                            .steteIcon {
                                width: 2px;
                                height: 2px;
                                background: rgba(168, 200, 255, 1);
                            }
                            .stateLeftTop {
                                position: absolute;
                                left: 0;
                                top: 0;
                            }
                            .stateRightTop {
                                position: absolute;
                                right: 0;
                                top: 0;
                            }
                            .stateLeftBottom {
                                position: absolute;
                                left: 0;
                                bottom: 0;
                            }
                            .stateRightBottom {
                                position: absolute;
                                right: 0;
                                bottom: 0;
                            }
                            .circle {
                                width: 13px;
                                height: 13px;
                                border-radius: 50%;
                                margin: 0 auto;
                                border: 2px solid #fff;
                            }
                        }
                        .name {
                            width: 200px;
                            height: 45px;
                            line-height: 45px;
                            padding: 0 10px;
                        }
                        .nameDefault {
                            background: rgba(26, 48, 89, 0.4);
                            color: rgba(154, 180, 227, 1);
                        }
                        .nameActive {
                            background: rgba(53, 80, 124, 0.9);
                            color: rgba(255, 255, 255, 1);
                        }
                    }
                }
            }
            .linePart {
                margin-top: 10px;
                width: 100%;
                height: 12px;
                border-top: 2px solid rgba(53, 80, 124, 0.4);
                border-bottom: 2px solid rgba(53, 80, 124, 0.4);
                position: relative;
                .lineTop {
                    position: absolute;
                    left: 0;
                    top: -2px;
                    width: 100%;
                    height: 2px;
                    .line1 {
                        width: 100px;
                        margin-right: 40px;
                        background: rgba(154, 180, 227, 0.8);
                    }
                    .line2 {
                        width: 167px;
                        margin-right: 40px;
                        background: rgba(154, 180, 227, 0.8);
                    }
                    .line3 {
                        width: 40px;
                        margin-right: 40px;
                        background: rgba(154, 180, 227, 0.8);
                    }
                    .line4 {
                        width: 40px;
                        background: rgba(154, 180, 227, 0.8);
                    }
                }
            }
        }
    }
    // 图例部分
    .legendPart {
        position: absolute;
        right: 20px;
        top: 15px;
        width: 190px;
        height: 20px;
        z-index: 3;
        .name {
            font-weight: 400;
            font-size: 16px;
            color: rgba(53, 80, 124, 1);
        }
        .triangle {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 5px;
        }
    }
}
</style>
