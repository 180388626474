// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@image/bigScreen/icon/weather_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".headerPart .timePart[data-v-466061a6]{color:#ccdfff;font-size:36px;font-family:DBPM;margin-right:10px}.headerPart .line[data-v-466061a6]{width:2px;height:30px;background:#a8c8ff;margin-right:10px}.headerPart .weatherPart[data-v-466061a6]{color:#ccdfff;font-size:16px;margin-right:10px}.headerPart .weatherPart .weather-info[data-v-466061a6]{display:inline-block;width:40px;height:40px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 2px 0;transform:scale(.6)}.headerPart .actionPart[data-v-466061a6]{width:183px;margin-left:10px;margin-right:53px}", ""]);
// Exports
module.exports = exports;
