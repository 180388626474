<template>
    <div class="mapChangeBtn">
        <div
            class="mapTypeCard vectorType"
            @click="baseLayerChange('vector')"
            :class="{ active: mapType == 'vector' }"
        >
            <span>地图</span>
        </div>
        <div
            class="mapTypeCard imgType"
            @click="baseLayerChange('img')"
            :class="{ active: mapType == 'img' }"
        >
            <span>影像</span>
        </div>
        <!-- <div
                class="mapTypeCard terType"
                @click="baseLayerChange('ter')"
                :class="{ active: mapType == 'ter' }"
            >
                <span>地形</span>
            </div> -->
    </div>
</template>

<script>
import { $AXIOS } from "@request/http.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    name: "",
    props: {},
    data() {
        return {
            mapType: "", // 地图 影像 地形
        };
    },
    mounted() {},
    components: {},
    watch: {},
    methods: {
        //底图切换
        baseLayerChange(maptype) {
            this.mapType = maptype;
            this.$emit("baseLayerChange", maptype);
        },
    },
};
</script>

<style scoped lang="scss">
.mapChangeBtn {
    height: 70px;
    width: 106px;
    border-radius: 2px;
    cursor: pointer;
    transition: width 0.8s;
    background: rgba(255, 255, 255, 0.3);
    &:hover {
        width: 288px;
        width: 200px;
        .vectorType {
            right: 197px;
            right: 101px;
        }

        .imgType {
            right: 101px;
            right: 5px;
        }

        .terType {
            right: 5px;
        }
    }
    .mapTypeCard {
        position: absolute;
        top: 5px;
        width: 86px;
        height: 60px;
        border-radius: 5px;
        border: solid 1px rgba(255, 255, 255, 0);
        span {
            position: absolute;
            bottom: -1px;
            left: -1px;
            width: 86px;
            display: inline-block;
            font-size: 12px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            color: #fff;
            background: #888f88;
            opacity: 0.8;
        }
    }

    .vectorType {
        right: 15px;
        background: url("~@image/mapToolbar/mapbtn.png") no-repeat 0 0;
        z-index: 3;
        transition: right 0.8s;
        &:hover {
            border: solid 1px #31a5f7;
            span {
                background: #31a5f7;
            }
        }
    }

    .imgType {
        right: 10px;
        background: url("~@image/mapToolbar/imagebtn.png") no-repeat 0 0;
        z-index: 2;
        transition: right 0.8s;
        &:hover {
            border: solid 1px #31a5f7;
            span {
                background: #31a5f7;
            }
        }
    }

    .terType {
        right: 5px;
        background: url("~@image/mapToolbar/terbtn.png") no-repeat 0 0;
        z-index: 1;
        transition: right 0.8s;
        &:hover {
            border: solid 1px #31a5f7;
            span {
                background: #31a5f7;
            }
        }
    }
    .active {
        border: solid 1px #31a5f7;
        span {
            background: #31a5f7;
        }
    }
}
</style>
