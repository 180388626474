// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@image/bigScreen/icon/titleBg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".basicStatistics[data-v-b0ceef1a]{width:100%;height:100%;position:relative}.basicStatistics .mainPart .centerItem[data-v-b0ceef1a]{width:162px}.basicStatistics .mainPart .centerItem .name[data-v-b0ceef1a]{width:100%;height:40px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;font-size:18px;font-weight:500;color:#a8c8ff;display:flex;align-items:center;justify-content:space-around}.basicStatistics .mainPart .centerItem .count[data-v-b0ceef1a]{margin-top:5px;height:65px;border:1px solid rgba(53,80,124,.4);color:#bdd7ec}.basicStatistics .mainPart .centerItem .num[data-v-b0ceef1a]{font-size:48px;font-family:DBPM}.basicStatistics .mainPart .centerItem .dw[data-v-b0ceef1a]{font-size:20px}", ""]);
// Exports
module.exports = exports;
