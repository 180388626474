<template>
    <div class="rightPart scrollbar-hidden">
        <Jnsj class="jnsjPart"></Jnsj>
        <Ccltj class="ccltjPart"></Ccltj>
        <Cqxx class="cqxxPart"></Cqxx>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import Jnsj from "./comp/jnsj.vue";
import Ccltj from "./comp/ccltj.vue";
import Cqxx from "./comp/cqxx.vue";
export default {
    name: "",
    components: {
        Jnsj,
        Ccltj,
        Cqxx,
    },
    data() {
        return {
            
        };
    },
    props: {},
    computed: {
        ...mapGetters({
            
        }),
    },
    mounted() {
        
    },
    methods: {
        ...mapActions([
            
        ]),
        ...mapMutations({
            
        }),
        
    },
};
</script>
<style scoped lang="scss">
.rightPart {
    width: 100%;
    height: 100%;
    padding: 15px 0;
    // 今年数据
    .jnsjPart {
        width: 100%;
        height: 200px;
    }
    //茶产量统计
    .ccltjPart {
        width: 100%;
        height: 522px;
    }
    // 茶企信息
    .cqxxPart {
        width: 100%;
        // height: 180px;
    }
}
</style>
