<template>
    <div class="screenItem">
        <!-- 日常巡查 -->
        <BasicMonitor :text="common.getFyName(fany.rcxc)">
            <div
                slot="basic-module"
                class="screenCtn scrollbar-hidden flex-between"
            >
                <div class="leftPart animated bounceIn">
                    <img
                        src="@image/bigScreen/monitor_all/rcxc/mapBg.png"
                        class="mapBg"
                    />
                    <!-- <img
                        src="@image/bigScreen/monitor_all/rcxc/map.png"
                        class="map"
                    /> -->
                    <MyMap
                        ref="myMap"
                        :myMap.sync="myMap"
                        :mapData="mapData"
                    ></MyMap>
                </div>
                <div class="rightPart flex-1 flex-between flex-column">
                    <div class="flex-y-center flex-1">
                        <div class="topNum flex">
                            <img
                                src="@image/bigScreen/monitor_all/rcxc/line.png"
                                class="line"
                            />
                            <div class="topList flex-1">
                                <div
                                    v-for="(item, index) in listAry"
                                    :key="index"
                                    class="topItem animated bounceInRight"
                                    :style="{
                                        animationDelay: 0.2 + index * 0.2 + 's',
                                    }"
                                >
                                    <div class="dot"></div>
                                    <div class="label">{{ item.name }}</div>
                                    <div class="numPart">
                                        <span class="num">
                                            {{ item.num }}
                                        </span>
                                        <span class="dw">{{ item.dw }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="bottomList flex-between">
                        <div class="bottomLeft">
                            <div class="xcycLabelPart flex-both-center">
                                巡查异常
                            </div>
                            <div class="xcycNumPart flex-both-center">
                                <span class="num"> {{ xcycNum }}</span>
                                <span class="dw">个</span>
                            </div>
                        </div>
                        <div class="bottomRight">
                            <div class="wjc">
                                <div class="state">处置中</div>
                                <div>
                                    <span class="num"> {{ wjcNum }}</span>
                                    <span class="dw">个</span>
                                </div>
                            </div>
                            <div class="yjc">
                                <div class="state">已解除</div>
                                <div>
                                    <span class="num"> {{ yjcNum }}</span>
                                    <span class="dw">个</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BasicMonitor>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import BasicMonitor from "@views/big_screen/monitor_all/comp/BasicModule";
import MyMap from "@views/big_screen/comp/map/BaseMap.vue";
import { derectionData } from "@libs/dailyInspectAreaList"; //
export default {
    components: {
        MyMap,
        BasicMonitor,
    },
    name: "",
    data() {
        return {
            derectionData,
            myMap: null,
            mapData: {
                lng: 100.01575469970705,
                lat: 22.18549757276757,
                zoom: 12,
                baseMapNum: null,
                mapTypeBtn: false,
                resetBtnRight: "50px",
                ycqHcqState: false,
            },
            listAry: [
                {
                    name: "巡查片区",
                    num: 0,
                    dw: "个",
                },
                {
                    name: "巡查点位",
                    num: 0,
                    dw: "个",
                },
                {
                    name: "巡查人数",
                    num: 0,
                    dw: "人",
                },
            ],
            ycyszp: "", //遗产要素图片
            xcycNum: 0,
            wjcNum: 0,
            yjcNum: 0,
        };
    },
    computed: {
        ...mapGetters({
            jczlYczcItem: TYPES.jczlYczcItem,
        }),
    },
    props: {},
    watch: {
        // jczlYczcItem: {
        //     immediate: false,
        //     handler() {
        //         this.getMainInfo();
        //         this.getXcycWjcYjc();
        //     },
        // },
    },
    mounted() {
        this.getAreaInfo();
        this.getMainInfo();
        this.getXcycWjcYjc();
        //marker 弹出框
        this.markerPopup = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, 0],
            className: "markerPopup",
        });
    },
    methods: {
        ...mapActions([
            "GetInspectionTotal",
            "GetAbnormalRecordsStatistic",
            "GetRcxcXcpq",
        ]),
        ...mapMutations({}),
        async getMainInfo() {
            let result = await this.GetInspectionTotal({
                // ycysbm: this.jczlYcysItem ? this.jczlYcysItem.BM : "",
                // ycysfl2: this.jczlYczcItem ? this.jczlYczcItem.YCYSFL_2 : "",
            });
            this.listAry[0].num = result.XCPQ;
            this.listAry[1].num = result.XCDW;
            this.listAry[2].num = result.XCRS;
            this.ycyszp = result.YCYSZP;
        },
        async getXcycWjcYjc() {
            let result = await this.GetAbnormalRecordsStatistic({
                // ycysbm: this.jczlYcysItem ? this.jczlYcysItem.BM : "",
                // ycysfl2: this.jczlYczcItem ? this.jczlYczcItem.YCYSFL_2 : "",
            });
            this.xcycNum = result.TOTAL;
            this.wjcNum = result.WJC;
            this.yjcNum = result.YJC;
        },
        // 监听 日常巡查 数据
        monitorRcxc() {
            // window.signalr_connection.on("InspectionInfo", (data) => {
            //     let result = JSON.parse(data).RESULTVALUE;
            // console.log("日常巡查", result);
            //     this.listAry[0].num = result.YCZS;
            //     this.listAry[1].num = result.HQSJ;
            //     this.listAry[2].num = result.SSZX;
            // });
        },
        //获取片区数据
        async getAreaInfo() {
            let result = await this.GetRcxcXcpq({});
            result.map((item) => {
                this.derectionData.map((itemInner) => {
                    if (item.ID == itemInner.ID) {
                        Object.assign(item, itemInner);
                    }
                });
            });
            this.drawXcq(result);
        },
        // 画 巡查区
        drawXcq(list) {
            list.map((item, index) => {
                let areaItem = JSON.parse(item.QYZB);
                // 巡查区
                let layer = window.L.geoJSON(areaItem, {
                    style: () => {
                        return { color: item.YS };
                    },
                }).addTo(this.myMap);

                layer.on("mouseover", () => {
                    let lng = 0;
                    let lat = 0;
                    if (item.JD) {
                        lng = item.JD;
                        lat = item.WD;
                    } else {
                        let center = window.turf.centerOfMass(areaItem);
                        lat = center.geometry.coordinates[1];
                        lng = center.geometry.coordinates[0];
                    }
                    this.markerPopup
                        .setLatLng([lat, lng])
                        .setContent(`<div class="tip">${item.QYMC}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
            });
        },
    },
};
</script>
<style scoped lang="scss">
.screenItem {
    position: relative;
    width: 100%;
    height: 100%;
    .screenCtn {
        width: 100%;
        height: 100%;
        padding: 12px 0;
        .leftPart {
            width: 385px;
            height: 100%;
            position: relative;
            margin-right: 10px;
            .mapBg {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
            .map {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .rightPart {
            position: relative;
            .topNum {
                width: 100%;
                .line {
                    padding-top: 20px;
                    width: 57px;
                    height: 186px;
                    margin-right: 10px;
                }
                .topList {
                    padding-top: 10px;
                    .topItem {
                        position: relative;
                        height: 70px;
                        .dot {
                            width: 8px;
                            height: 8px;
                            background: rgba(189, 215, 236, 1);
                            border-radius: 50%;
                            position: absolute;
                            left: 0;
                            top: -4px;
                        }
                        .label {
                            font-size: 16px;
                            color: rgba(189, 215, 236, 1);
                            margin-left: 20px;
                            position: relative;
                            top: -14px;
                        }
                        .numPart {
                            position: absolute;
                            left: 20px;
                            top: -9px;
                            width: 100%;
                            .num {
                                font-size: 48px;
                                color: rgba(189, 215, 236, 1);
                                font-family: DBPM;
                                margin-right: 10px;
                            }
                            .dw {
                                font-size: 16px;
                                color: rgba(255, 205, 128, 1);
                            }
                        }
                    }
                }
            }
            .bottomList {
                .label {
                    width: 100%;
                    height: 28px;
                    line-height: 28px;
                    padding-left: 10px;
                    background: rgba(53, 80, 124, 0.5);
                    color: rgba(168, 200, 255, 1);
                    font-size: 16px;
                    border-left: 2px solid rgba(168, 200, 255, 1);
                }
                .bottomLeft {
                    .xcycLabelPart {
                        width: 152px;
                        height: 40px;
                        background: url("~@image/bigScreen/icon/titleBg.png")
                            no-repeat;
                        background-size: 100% 100%;
                        font-size: 18px;
                        color: rgba(168, 200, 255, 1);
                    }
                    .xcycNumPart {
                        width: 152px;
                        height: 57px;
                        background: rgba(53, 80, 124, 0.4);
                        margin-top: 4px;
                        .num {
                            font-size: 48px;
                            color: #bdd7ec;
                            font-family: DBPM;
                        }
                        .dw {
                            font-size: 16px;
                            color: #ffcd80;
                            position: relative;
                            left: 4px;
                            top: 4px;
                        }
                    }
                }
                .bottomRight {
                    text-align: center;
                    .wjc {
                        padding-top: 4px;
                        width: 93px;
                        height: 48px;
                        color: #ff0000;
                        border: 1px solid #ff0000;
                        font-weight: 600;
                        .state {
                            font-size: 14px;
                        }
                        .num {
                            font-size: 14px;
                        }
                        .dw {
                            font-size: 12px;
                            position: relative;
                            left: 4px;
                            top: 0px;
                        }
                    }
                    .yjc {
                        margin-top: 4px;
                        padding-top: 4px;
                        width: 93px;
                        height: 48px;
                        color: #61ff00;
                        border: 1px solid #61ff00;
                        font-weight: 400;
                        .state {
                            font-size: 14px;
                        }
                        .num {
                            font-size: 14px;
                        }
                        .dw {
                            font-size: 12px;
                            position: relative;
                            left: 4px;
                            top: 0px;
                        }
                    }
                }
            }
        }
    }
}
</style>
