<template>
    <div class="screenItem">
        <!-- 监测概览 -->
        <basic-module :text="common.getFyName(fany.jcgl)">
            <div slot="basic-module" class="screenCtn scrollbar-hidden">
                <div class="jcgl-top flex-between">
                    <div class="jcgl-topleft">
                        <div class="topleft-title">{{ topTitle }}</div>
                        <div class="topleft-border">
                            <div class="topleft-circles">
                                <pieChart
                                    :chartData="chartData"
                                    :lengendData="lengendData"
                                ></pieChart>
                            </div>
                        </div>
                    </div>
                    <div class="jcgl-topright flex-between">
                        <div
                            class="jcgl-static-item"
                            v-for="(item, index) in jcglStaticList"
                            :key="'bh' + index"
                        >
                            <div class="name">{{ item.name }}</div>
                            <div class="num">{{ item.num }}</div>
                            <div class="mainName">{{ item.mainName }}</div>
                            <div class="mainNum">{{ item.mainNum }}</div>
                        </div>
                    </div>
                </div>
                <div class="jcgl-line">
                    <div class="line1"></div>
                    <div class="line2"></div>
                    <div class="line3"></div>
                    <div class="line4"></div>
                </div>
                <div class="jcgl-bottom">
                    <!-- 古树名木生长状况 -->
                    <div class="gssz-zktj-title">
                        {{ common.getFyName(fany.gsmmszzt) }}
                    </div>
                    <div class="chart_main">
                        <div :id="gsszzkId" class="chart_box"></div>
                        <div class="wsh-perc">
                            旺盛 {{ gxmmszzkPart.wsPer }}%
                        </div>
                        <div class="total">总数量 {{ gxmmszzkPart.total }}</div>
                        <i class="tl"></i>
                        <i class="tr"></i>
                        <i class="bl"></i>
                        <i class="br"></i>
                        <div class="echartPart flex">
                            <div
                                v-for="(item, index) in gxmmszzkPart.list"
                                :key="index"
                                class="echartItem"
                                :style="{
                                    width: `${item.width}px`,
                                    background: item.color,
                                }"
                                :title="`${item.NAME} ${item.SL}个 (${item.per}%)`"
                            >
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="`${item.NAME} ${item.SL}个 ${item.per} `"
                                    placement="top-start"
                                >
                                </el-tooltip>
                            </div>

                            <!-- this.gxmmszzkPart.list = res; //古树名木生长状况列表
            this.gxmmszzkPart.wsPer = wshPer; //旺盛占比
            this.gxmmszzkPart.total = total; //总数量 -->
                        </div>
                    </div>
                </div>
            </div>
        </basic-module>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import pieChart from "./comp/pie_chart";
export default {
    name: "",
    data() {
        return {
            topTitle: "",
            dataConfig: null,
            chart: null,
            gsszzkId: "gsszzk" + Math.random(),
            gxmmszzkPart: {
                list: [], //古树名木生长状况列表
                wsPer: 0, //旺盛占比
                total: 0, //总数量
            },
            chartData: [],
            lengendData: [],
            jcglStaticList: [
                {
                    name: "植物种类",
                    num: "0",
                    mainName: "珍惜濒危物种",
                    mainNum: "15种",
                },
                {
                    name: "调查古树名木数量",
                    num: "0",
                    mainName: "主要科属",
                    mainNum: "",
                },
                {
                    name: "动物种类",
                    num: "0",
                    mainName: "常见动物",
                    mainNum: "",
                },
            ],
        };
    },
    components: {
        pieChart,
    },
    computed: {
        ...mapGetters({
            sthjJcnrSelect: TYPES.sthjJcnrSelect,
        }),
    },
    props: {},
    watch: {
        sthjJcnrSelect: {
            immediate: false,
            deep: true,
            handler() {
                let idList = ["1", "2"];
                if (idList.includes(this.sthjJcnrSelect.id)) {
                    // 病虫害叫绿色防治（虫情、病虫害、遥感） sthjJcnrSelect
                    this.topTitle = `绿色防治面积占比`;
                } else {
                    this.topTitle = "监测样方统计";
                }
            },
        },
    },
    mounted() {
        this.getGsmmData();
        $(window).resize(() => {
            setTimeout(() => {
                this.chart && this.chart.resize();
            }, 300);
        });
        this.getJczlTjz();
        this.getBhkzData();
    },
    methods: {
        ...mapActions([
            "GetSzzkStatistic", // 古树名木生长状况
            "GetJczlTjz", // 监测总览统计值
            "GetYfTj",
        ]),
        // 古树名木生长状况
        async getGsmmData() {
            const res = await this.GetSzzkStatistic();
            let total = 0;
            let wshCount = 0;
            let wshPer = 0;
            let colorList = ["#81D3F8", "#F59A23", "#9E66A8", "#5AD8A6", "red"];
            if (res && res.length > 0) {
                res.forEach((item, index) => {
                    item.color = colorList[index];
                    total += item.SL;
                    if (item.CODE === "1") {
                        wshCount = item.SL;
                    }
                });

                res.forEach((item) => {
                    item.width = (item.SL / total) * 673;
                    item.per = ((item.SL / total) * 100).toFixed(2);
                });
                if (total > 0) {
                    wshPer = ((wshCount / total) * 100).toFixed(2);
                }
            }
            this.gxmmszzkPart.list = res; //古树名木生长状况列表
            this.gxmmszzkPart.wsPer = wshPer; //旺盛占比
            this.gxmmszzkPart.total = total; //总数量
        },
        dealData({ TOTAL = 0, BHGCJZQKMX = [] } = {}) {
            const colorList = ["#81D3F8", "#F59A23", "#9E66A8", "#5AD8A6"];
            const seriesData = [];
            const legend = [];
            if (Array.isArray(BHGCJZQKMX) && BHGCJZQKMX.length > 0) {
                BHGCJZQKMX.forEach((ele) => {
                    seriesData.push({
                        name: ele.NAME,
                        type: "bar",
                        stack: "1",
                        barWidth: 30,
                        data: [Number(ele.SL)],
                        // itemStyle: {
                        //     borderWidth: 3,
                        //     borderColor: "#040e1e",
                        // },
                        label: {
                            show: false,
                            position: "top",
                        },
                    });
                    legend.push(ele.NAME);
                });
            }
            this.dataConfig = {
                color: colorList,
                legend: {
                    show: false,
                    data: legend,
                    icon: "rect",
                    itemWidth: 14,
                    itemHeight: 5,
                    itemGap: 13,
                    textStyle: {
                        color: "rgba(196,214,225,1)",
                    },
                },
                tooltip: {
                    formatter: function (params) {
                        let result = `
                            <span 
                                style="display:inline-block;
                                        margin-right:5px;
                                        border-radius:10px;
                                        width:9px;
                                        height:9px;
                                        background-color:${params.color}"
                            >
                            </span>
                            ${params.seriesName}：${params.value}个（${(
                            (params.value / TOTAL) *
                            100
                        ).toFixed(2)}%）
                        `;
                        return result;
                    },
                },
                title: {
                    text: "总数量 " + TOTAL,
                    textStyle: {
                        color: "#A8C8FF",
                        fontSize: 14,
                        fontWeight: "normal",
                    },
                    right: 7,
                    y: 1,
                },
                grid: {
                    containLabel: true,
                    left: 10,
                    right: 10,
                    top: 20,
                    bottom: 0,
                },
                xAxis: {
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                },
                yAxis: {
                    data: ["sss"],
                    axisLabel: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                },
                series: seriesData,
            };
            this.chart = this.$echarts.init(
                document.getElementById(this.gsszzkId)
            );
            this.chart.setOption(this.dataConfig);
        },
        // 病虫害面积占比
        async getBhkzData() {
            let res = await this.GetYfTj({});
            res.map((item) => {
                item.name = item.MC;
                item.value = item.SL;
            });
            this.chartData = res;
        },
        async getJczlTjz() {
            const result = await this.GetJczlTjz();
            if (result) {
                // 植物种类
                this.jcglStaticList[0].num = result.ZWZL;
                // 本期调查古树名木数量
                this.jcglStaticList[1].num = result.GSMMSL;
                this.jcglStaticList[1].mainNum = result.ZYKS;
                // 动物种类
                this.jcglStaticList[2].num = result.DWZL;
                this.jcglStaticList[2].mainNum = result.ZYDW;
            }
        },
    },
};
</script>
<style scoped lang="scss">
.screenItem {
    width: 100%;
    height: 100%;
    .screenCtn {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        .jcgl-top {
            width: 100%;
            height: 289px;
            .jcgl-topleft {
                width: 240px;
                height: 100%;
                .topleft-title {
                    width: 100%;
                    height: 43px;
                    line-height: 43px;
                    margin-bottom: 10px;
                    font-size: 16px;
                    text-align: center;
                    color: #9ab4e3;
                    letter-spacing: 2px;
                    background: rgba(26, 48, 89, 0.3);
                }
                .topleft-border {
                    position: relative;
                    width: 100%;
                    height: 240px;
                    background: url("~@image/bigScreen/ecological_environment/JianCeGaiLan/border.png")
                        no-repeat;
                    background-size: 240px;
                    .topleft-circles {
                        width: 206px;
                        height: 186px;
                        background: url("~@image/bigScreen/ecological_environment/JianCeGaiLan/circle.png")
                            no-repeat;
                        background-size: 204px;
                        position: absolute;
                        left: 17px;
                        top: 27px;
                    }
                }
            }
            .jcgl-topright {
                width: 392px;
                max-width: 550px;
                .jcgl-static-item {
                    width: 112px;
                    height: 293px;
                    text-align: center;
                    background: url("~@image/bigScreen/ecological_environment/JianCeGaiLan/zhiWu.png")
                        no-repeat;
                    padding-top: 135px;
                    .name {
                        color: #a8c8ff;
                    }
                    .num {
                        font-size: 24px;
                        color: #daeeff;
                    }
                    .mainName {
                        color: #a8c8ff;
                    }
                    .mainNum {
                        font-size: 24px;
                        margin-top: 10px;
                        color: #daeeff;
                    }
                }
                .jcgl-static-item:nth-child(2) {
                    margin-left: 27px;
                    background: url("~@image/bigScreen/ecological_environment/JianCeGaiLan/shu.png")
                        no-repeat;
                }
                .jcgl-static-item:nth-child(3) {
                    margin-left: 27px;
                    background: url("~@image/bigScreen/ecological_environment/JianCeGaiLan/dongWu.png")
                        no-repeat;
                }
            }
        }
        .jcgl-line {
            position: relative;
            width: 100%;
            height: 2px;
            margin-top: 18px;
            background: rgba(53, 80, 124, 0.4);
            div {
                position: absolute;
                top: 0;
                height: 2px;
                background: rgba(154, 180, 227, 0.8);
            }
            .line1 {
                left: 0;
                width: 105px;
            }
            .line2 {
                left: 145px;
                width: 175px;
            }
            .line3 {
                left: 360px;
                width: 40px;
            }
            .line4 {
                left: 430px;
                width: 40px;
            }
        }
        .jcgl-bottom {
            width: 100%;
            .gssz-zktj-title {
                height: 35px;
                line-height: 40px;
                font-size: 18px;
                color: #a8c8ff;
            }
            .chart_main {
                position: relative;
                width: 100%;
                height: 62px;
                border: 1px solid rgba(168, 200, 255, 0.4);
                .echartPart {
                    width: calc(100% - 2px);
                    position: absolute;
                    left: 1px;
                    bottom: 1px;
                    .echartItem {
                        height: 30px;
                    }
                }
                .total {
                    position: absolute;
                    right: 10px;
                    top: 0px;
                    color: #a8c8ff;
                }
                .chart_box {
                    width: 100%;
                    height: 100%;
                }
                > i {
                    position: absolute;
                    display: inline-block;
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background-color: rgba(168, 200, 255, 0.8);
                    &.tl {
                        top: -2px;
                        left: -2px;
                    }
                    &.tr {
                        top: -2px;
                        right: -2px;
                    }
                    &.bl {
                        bottom: -2px;
                        left: -2px;
                    }
                    &.br {
                        bottom: -2px;
                        right: -2px;
                    }
                }
                .wsh-perc {
                    position: absolute;
                    top: 2px;
                    left: 20px;
                    color: #5ad8a6;
                }
            }
        }
    }
}
</style>
