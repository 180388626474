<template>
    <div class="screenItem scrollbar-hidden">
        <img :src="baseYcysLeft" class="leftSideImg" />
        <img :src="baseYcysRight" class="rightSideImg" />
        <div class="ycysWrap">
            <YcysList
                @getYcysDetail="getYcysDetail"
                @getYczcDetail="getYczcDetail"
            ></YcysList>
        </div>
        <!-- 遗产环境 -->
        <div class="xzPart">
            <div class="ztDescWrap">
                <div class="ztDescPart flex-between-y-center">
                    <div class="descLeft flex-y-center" v-if="!animateState">
                        <div class="label">{{ stateName }}保存现状</div>
                        <div
                            class="state"
                            :style="{
                                color: common.getYcysStateColor(nowState),
                            }"
                        >
                            {{ nowState }}
                        </div>
                    </div>
                    <div class="descRight flex-y-center" v-if="!animateState">
                        <div class="desc">上次状态:</div>
                        <div class="desc">{{ lastState }}</div>
                    </div>
                </div>
            </div>
            <!-- 环境现状 -->
            <basic-module :text="common.getFyName(fany.hjxz)">
                <div slot="basic-module" class="screenCtn flex">
                    <div class="xzLeft scrollbar-hidden">
                        <div
                            v-for="(item, index) in xzList"
                            :key="index"
                            class="animated bounceInRight ycysTypeItem"
                            :style="{
                                animationDelay: 0.2 + index * 0.2 + 's',
                            }"
                            @click="getXzDetail(item, index)"
                        >
                            <el-image
                                :src="
                                    index == xzActiveNum
                                        ? item.srcActive
                                        : item.srcDefault
                                "
                                fit="cover"
                                class="imgObj pointer"
                            >
                                <div
                                    slot="error"
                                    style="width: 100%; height: 100%"
                                >
                                    <el-image
                                        fit="cover"
                                        :src="noImg"
                                        style="width: 100%; height: 100%"
                                    ></el-image>
                                </div>
                            </el-image>
                        </div>
                        <!-- <div class="decorate">
                            <div class="line flex-between">
                                <div class="lineLeft"></div>
                                <div class="lineRight"></div>
                            </div>
                            <i class="el-icon-caret-top triangle"></i>
                        </div> -->

                        <img
                            src="@image/bigScreen/icon/decorateHjxz.png"
                            class="decorateHjxz"
                        />
                    </div>
                    <div class="xzRight flex-1">
                        <div class="linePart flex">
                            <div class="line0"></div>
                            <div class="line1"></div>
                            <div class="line2"></div>
                            <div class="line3"></div>
                        </div>
                        <div v-show="xzActiveNum == 0" class="ctnPart">
                            <Zrhj></Zrhj>
                        </div>
                        <div v-show="xzActiveNum == 1" class="ctnPart">
                            <Jghj></Jghj>
                        </div>
                        <div class="muduleBottomLine flex-between">
                            <div class="muduleBottomItem flex-between-y-center">
                                <div class="dot"></div>
                                <div class="line flex-1"></div>
                            </div>
                            <div class="muduleBottomItem flex-between-y-center">
                                <div class="line flex-1"></div>
                                <div class="dot"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </basic-module>
        </div>
    </div>
</template>

<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import YcysList from "./comp/BasicYcys";
import Jghj from "./comp/Jghj"; //景观环境
import Zrhj from "./comp/Zrhj"; //自然环境
export default {
    components: {
        YcysList,
        Jghj,
        Zrhj,
    },
    name: "",
    data() {
        return {
            stateName: "景迈山遗产",
            baseYcysLeft: require("@image/bigScreen/monitor_all/base_ycys_left.png"),
            baseYcysRight: require("@image/bigScreen/monitor_all/base_ycys_right.png"),
            noImg: require("@image/bigScreen/noImg.png"),

            xzActiveNum: 0,
            xzList: [
                {
                    name: "自然环境",
                    srcActive: require("@image/bigScreen/ycysType/zrhjActive.png"),
                    srcDefault: require("@image/bigScreen/ycysType/zrhjDefault.png"),
                    list: [],
                },
                {
                    name: "景观环境",
                    srcActive: require("@image/bigScreen/ycysType/jghjActive.png"),
                    srcDefault: require("@image/bigScreen/ycysType/jghjDefault.png"),
                    list: [],
                },
                // {
                //     name: "非遗现状",
                //     srcActive: require("@image/bigScreen/ycysType/fyxzActive.png"),
                //     srcDefault: require("@image/bigScreen/ycysType/fyxzDefault.png"),
                //     list: [],
                // },
            ],
            lastState: "无",
            nowState: "",
            animateState: true,
        };
    },
    computed: {
        ...mapGetters({
            jczlYcysItem: TYPES.jczlYcysItem,
            jczlYczcItem: TYPES.jczlYczcItem,
            jczlYcysTypeItem: TYPES.jczlYcysTypeItem,
        }),
    },
    props: {},
    watch: {
        jczlYcysItem: {
            immediate: true,
            handler() {
                if (this.jczlYcysItem) {
                    this.stateName = this.jczlYcysItem.MC;
                } else {
                    if (this.jczlYcysTypeItem) {
                        this.stateName = this.jczlYcysTypeItem.name;
                    } else {
                        this.stateName = "景迈山遗产";
                    }
                }
                this.getYcysState();
            },
        },
        jczlYcysTypeItem: {
            immediate: true,
            handler() {
                if (this.jczlYcysTypeItem) {
                    this.stateName = this.jczlYcysTypeItem.name;
                } else {
                    this.stateName = "景迈山遗产";
                }
                this.getYcysState();
            },
        },
        jczlYczcItem: {
            immediate: false,
            handler() {
                if (this.jczlYcysTypeItem) {
                    this.stateName = this.jczlYcysTypeItem.name;
                } else {
                    this.stateName = "景迈山遗产";
                }
                this.getYcysState();
            },
        },
    },
    mounted() {
        setTimeout(() => {
            this.animateState = false;
        }, 1000);
    },
    methods: {
        ...mapActions(["GetBhpgAndBhlx"]),
        ...mapMutations({
            SET_JCZLJGHJSTATE: TYPES.SET_JCZLJGHJSTATE,
        }),
        async getYcysState() {
            let result = await this.GetBhpgAndBhlx({
                ycyslx: this.jczlYcysTypeItem ? this.jczlYcysTypeItem.name : "",
                yczc: this.jczlYczcItem ? this.jczlYczcItem.YCYSFL_2 : "",
                ycysbm: this.jczlYcysItem ? this.jczlYcysItem.BM : "",
            });
            // console.log("获取 遗产要素 状态", result);
            this.lastState = result.ZT_BEFORE;
            this.nowState = result.ZT_NOW;
        },
        //获取现状详情
        getXzDetail(item, index) {
            this.xzActiveNum = index;
            this.SET_JCZLJGHJSTATE(index == 0 ? false : true);
        },
        // 遗产组成
        getYczcDetail(item) {
            // console.log("遗产组成", item);
            // this.getYcysState();
        },
        // 获取遗产要素详情
        getYcysDetail(item, index) {
            console.log("获取遗产要素详情", item, index);
            this.getYcysState();
        },
    },
};
</script>

<style lang="scss" scoped>
.screenItem {
    width: 100%;
    height: 100%;
    .leftSideImg {
        position: absolute;
        left: -3px;
        top: 0;
        height: 100%;
    }
    .rightSideImg {
        position: absolute;
        right: -3px;
        top: 0;
        height: 100%;
    }
    .ycysWrap {
        width: 100%;
        height: 477px + 36px;
    }

    .xzPart {
        width: 100%;
        height: 486px - 36px;
        position: relative;
        // 保存现状部分 显示在 遗产要素里
        .ztDescWrap {
            width: calc(100% - 165px);
            height: 30px;
            position: absolute;
            right: 20px;
            top: -42px;
            .ztDescPart {
                height: 30px;
                animation: widthChangeToShow 0.5s linear 0s forwards;
                background: rgba(26, 48, 89, 0.4);
                border-left: 2px solid rgba(168, 200, 255, 1);
                border-right: 2px solid rgba(168, 200, 255, 1);
                padding: 0 20px;

                .descLeft {
                    .label {
                        color: rgba(168, 200, 255, 1);
                        font-size: 14px;
                        margin-right: 20px;
                    }
                    .state {
                        font-size: 24px;
                    }
                }
                .descRight {
                    .desc {
                        color: rgba(53, 80, 124, 1);
                        font-size: 14px;
                        &:nth-child(1) {
                            margin-right: 20px;
                        }
                    }
                }
            }
        }

        .screenCtn {
            width: 100%;
            height: 100%;
            .xzLeft {
                padding-top: 17px;
                width: 110px;
                background: rgba(26, 48, 89, 0.5);
                margin-right: 15px;
                text-align: center;
                position: relative;
                .ycysTypeItem {
                    margin: 0 auto;
                    width: 90px;
                    height: 90px;
                    margin-bottom: 14px;
                }
                .decorate {
                    width: 90px;
                    margin: 0 auto;
                    margin-top: 20px;
                    .line {
                        width: 100%;
                        .lineLeft {
                            width: 37px;
                            height: 5px;
                            background: rgba(53, 80, 124, 1);
                        }
                        .lineRight {
                            width: 37px;
                            height: 5px;
                            background: rgba(255, 205, 128, 1);
                        }
                    }
                    .triangle {
                        color: rgba(53, 80, 124, 1);
                        font-size: 28px;
                    }
                }
                .decorateHjxz {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 110px;
                    height: 81px;
                }
            }
            .xzRight {
                position: relative;
                .linePart {
                    width: 100%;
                    height: 3px;
                    position: absolute;
                    left: 0;
                    top: 0px;
                    .line0 {
                        width: 100%;
                        height: 2px;
                        background: rgba(53, 80, 124, 0.4);
                        position: absolute;
                        left: 0;
                        top: 0px;
                    }
                    .line1 {
                        width: 76px;
                        height: 100%;
                        background: rgba(154, 180, 227, 1);
                    }
                    .line2 {
                        width: 28px;
                        height: 100%;
                        background: rgba(53, 80, 124, 0.5);
                        border: 1px solid rgba(154, 180, 227, 1);
                        margin: 0 4px;
                    }
                    .line3 {
                        width: 30px;
                        height: 100%;
                        background: rgba(154, 180, 227, 1);
                    }
                }
                .ctnPart {
                    height: 100%;
                }
                .muduleBottomLine {
                    position: absolute;
                    bottom: 0px;
                    left: 0;
                    width: 100%;
                    .muduleBottomItem {
                        width: calc(50% - 5px);
                        margin-right: 10px;
                        &:nth-last-child(1) {
                            margin-right: 0px;
                        }
                        .dot {
                            width: 3px;
                            height: 3px;
                            border-radius: 50%;
                            background: rgba(168, 200, 255, 1);
                        }
                        .line {
                            height: 2px;
                            background: rgba(53, 80, 124, 0.5);
                        }
                    }
                }
            }
        }
    }
}
</style>
