<template>
    <div class="rightPart">
        <BoxTitle
            :title="jskzProjectItem.XMMC"
            @handleClose="handleClose"
        ></BoxTitle>
        <div class="base-part">
            <!-- 建设信息 -->
            <basic-module :text="common.getFyName(fany.jsxx)">
                <div slot="basic-module" class="base-ctn scrollbar-hidden">
                    <div class="flex-between">
                        <div class="info-item avg4">
                            <div class="name">民居建设编号</div>
                            <div class="value">{{ baseInfo.XJXMBH }}</div>
                        </div>
                        <div class="info-item avg4">
                            <div class="name">村</div>
                            <div class="value">{{ baseInfo.YCCZ }}</div>
                        </div>
                        <div class="info-item avg4">
                            <div class="name">组</div>
                            <div class="value">
                                {{ baseInfo.YCYS }}
                            </div>
                        </div>
                        <div class="info-item avg4">
                            <div class="name">监测日期</div>
                            <div class="value">
                                {{ baseInfo.CJSJ.substring(0, 10) }}
                            </div>
                        </div>
                    </div>
                    <div class="flex-between">
                        <div class="info-item avg4">
                            <div class="name">用户姓名</div>
                            <div class="value">{{ baseInfo.HZXM }}</div>
                        </div>
                        <div class="info-item avg4">
                            <div class="name">家庭人口</div>
                            <div class="value">{{ baseInfo.JTRK }}</div>
                        </div>
                        <div class="info-item avg4">
                            <div class="name">身份证号码</div>
                            <div class="value">
                                {{
                                    baseInfo.SFZHM
                                        ? common.hidePhoneNum(baseInfo.SFZHM)
                                        : ""
                                }}
                            </div>
                        </div>
                        <div class="info-item avg4">
                            <div class="name">联系电话</div>
                            <div class="value">{{ baseInfo.MOBILE }}</div>
                        </div>
                    </div>
                    <div class="flex-between">
                        <div class="info-item avg2">
                            <div class="name">宅基地情况</div>
                            <div
                                class="value pointer"
                                :title="baseInfo.ZJDQK_DESC"
                            >
                                {{ baseInfo.ZJDQK_DESC }}
                            </div>
                        </div>
                        <div class="info-item avg2">
                            <div class="name">宅基地面积（m²）</div>
                            <div class="value">{{ baseInfo.ZJDMJ }}</div>
                        </div>
                    </div>
                    <div class="flex-between">
                        <div class="info-item avg3">
                            <div class="name">建设方案是否通过审批</div>
                            <div class="value">{{ baseInfo.SFSPTG_DESC }}</div>
                        </div>
                        <div class="info-item avg3">
                            <div class="name">房屋结构</div>
                            <div class="value">{{ baseInfo.FWJG }}</div>
                        </div>
                        <div class="info-item avg3">
                            <div class="name">房屋用途</div>
                            <div class="value">{{ baseInfo.FWYT }}</div>
                        </div>
                    </div>
                    <div class="flex-between">
                        <div class="info-item avg4">
                            <div class="name">申请占地面积（m²）</div>
                            <div class="value">{{ baseInfo.SQZDMJ }}</div>
                        </div>
                        <div class="info-item avg4">
                            <div class="name">实际占地面积（m²）</div>
                            <div class="value">{{ baseInfo.SJZDMJ }}</div>
                        </div>
                        <div class="info-item avg4">
                            <div class="name">申请建筑面积（m²）</div>
                            <div class="value">
                                {{ baseInfo.SQJZMJ }}
                            </div>
                        </div>
                        <div class="info-item avg4">
                            <div class="name">实际建筑面积（m²）</div>
                            <div class="value">{{ baseInfo.SJJZMJ }}</div>
                        </div>
                    </div>
                    <div class="flex-between">
                        <div class="info-item avg4">
                            <div class="name">房屋层数</div>
                            <div class="value">{{ baseInfo.FWCS }}</div>
                        </div>
                        <div class="info-item avg4">
                            <div class="name">房屋高度</div>
                            <div class="value">{{ baseInfo.FWGD }}</div>
                        </div>
                        <div class="info-item avg4">
                            <div class="name">外墙颜色</div>
                            <div
                                class="value"
                                style="
                                    width: 30px;
                                    height: 30px;
                                    border-radius: 4px;
                                "
                                :style="{ background: baseInfo.WQYS }"
                            ></div>
                        </div>
                        <div class="info-item avg4">
                            <div class="name">屋顶形式</div>
                            <div class="value">{{ baseInfo.WDXS }}</div>
                        </div>
                    </div>
                    <div class="flex-between">
                        <div class="info-item avg2">
                            <div class="name">建房时间</div>
                            <div class="value">
                                {{
                                    baseInfo.JFRQ
                                        ? baseInfo.JFRQ.substring(0, 10)
                                        : ""
                                }}
                            </div>
                        </div>
                        <div class="info-item avg2" v-if="!baseInfo.JGSJ">
                            <div class="name">计划完成时间</div>
                            <div class="value">
                                {{
                                    baseInfo.JHJGRQ
                                        ? baseInfo.JHJGRQ.substring(0, 10)
                                        : ""
                                }}
                            </div>
                        </div>
                        <div class="info-item avg2" v-else>
                            <div class="name">竣工时间</div>
                            <div class="value">
                                {{
                                    baseInfo.JGSJ
                                        ? baseInfo.JGSJ.substring(0, 10)
                                        : ""
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="flex-between">
                        <div class="info-item avg3">
                            <div class="name">施工单位</div>
                            <div class="value pointer" :title="baseInfo.SGDW">
                                {{ baseInfo.SGDW }}
                            </div>
                        </div>
                        <div class="info-item avg3">
                            <div class="name">施工负责人</div>
                            <div class="value">{{ baseInfo.SGFZR }}</div>
                        </div>
                        <div class="info-item avg3">
                            <div class="name">施工单位联系电话</div>
                            <div class="value">{{ baseInfo.SGDWDH }}</div>
                        </div>
                    </div>
                    <div class="flex-between">
                        <div class="info-item avg2">
                            <div class="name">工程方案</div>
                            <div
                                class="value pointer"
                                @click="getPdf('工程方案')"
                            >
                                {{ baseInfo.FAMC }}
                            </div>
                        </div>
                        <div class="info-item avg2">
                            <div class="name">批复文件</div>
                            <div
                                class="value pointer"
                                @click="getPdf('批复文件')"
                            >
                                {{ baseInfo.SPWJMC }}
                            </div>
                        </div>
                    </div>

                    <!-- <div class="info-item">
                        <div class="name">备注</div>
                        <div class="value">{{ baseInfo.XMSJMS }}</div>
                    </div> -->
                    <div class="flex-between">
                        <div class="info-item avg3">
                            <div class="name">场地平整</div>
                            <div class="value">{{ monitorInfo.CDPZ }}</div>
                        </div>
                        <div class="info-item avg3">
                            <div class="name">基坑</div>
                            <div class="value">{{ monitorInfo.JK }}</div>
                        </div>
                        <div class="info-item avg3">
                            <div class="name">基础</div>
                            <div class="value">{{ monitorInfo.JC }}</div>
                        </div>
                    </div>
                    <!-- 跟踪信息 -->
                    <div class="flex-between">
                        <div class="info-item avg3">
                            <div class="name">框架</div>
                            <div class="value">{{ monitorInfo.KJ }}</div>
                        </div>
                        <div class="info-item avg3">
                            <div class="name">封顶</div>
                            <div class="value">{{ monitorInfo.FD }}</div>
                        </div>
                        <div class="info-item avg3">
                            <div class="name">砌墙</div>
                            <div class="value">{{ monitorInfo.QQ }}</div>
                        </div>
                    </div>
                    <div class="flex-between">
                        <div class="info-item avg3">
                            <div class="name">室内装修</div>
                            <div class="value">{{ monitorInfo.SNZX }}</div>
                        </div>
                        <div class="info-item avg3">
                            <div class="name">室外装修</div>
                            <div class="value">{{ monitorInfo.SWZX }}</div>
                        </div>
                        <div class="info-item avg3">
                            <div class="name">竣工</div>
                            <div class="value">{{ monitorInfo.JG }}</div>
                        </div>
                    </div>
                    <div class="flex-between">
                        <div class="info-item avg3">
                            <div class="name">是否按已审批方案实施</div>
                            <div class="value">
                                {{ monitorInfo.SFAYSPFASS }}
                            </div>
                        </div>
                    </div>

                    <div class="info-item">
                        <div class="name">现状描述</div>
                        <div class="value" style="height: 280px">
                            <span :title="monitorInfo.XZMS">{{
                                monitorInfo.XZMS
                            }}</span>
                            <div
                                class="
                                    flex flex-wrap
                                    scrollbar-translucent
                                    flex-both-center
                                "
                                style="height: 280px"
                            >
                                <el-image
                                    v-for="(item, index) in xzmsPicList"
                                    :key="index"
                                    :src="common.getThumbnailImg(item.CCLJ)"
                                    fit="cover"
                                    class="pointer"
                                    style="
                                        width: 314px;
                                        height: 257px;
                                        margin-right: 10px;
                                        margin-bottom: 10px;
                                    "
                                    @click="getImgDetail(item.CCLJ)"
                                >
                                    <div
                                        slot="error"
                                        style="width: 100%; height: 100%"
                                    >
                                        <SnNoData width="50px"></SnNoData>
                                    </div>
                                </el-image>
                            </div>
                        </div>
                    </div>
                    <div class="timePart pageNoBg" v-if="monitorList.length">
                        <TimeLine
                            ref="timeLine"
                            :listAry="monitorList"
                            timeKey="CJSJ"
                            @handleTimeLine="handleTimeLine"
                        ></TimeLine>
                        <div class="flex-end">
                            <el-pagination
                                small
                                class="pages"
                                @current-change="handleCurrentChange"
                                :current-page="pageIndex"
                                :page-size="pageSize"
                                :total="pageTotal"
                                layout="prev, pager, next"
                            >
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </basic-module>
        </div>

        <ViewerAlert ref="myImgList" :imgList="imgList"></ViewerAlert>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import SelectTimeTab from "@views/big_screen/comp/SelectTimeTab";
import BoxTitle from "@views/big_screen/comp/BoxTitle.vue"; //标题
import TimeLine from "@views/big_screen/comp/TimeLine.vue"; //时间线
import ViewerAlert from "@comp/ViewerAlert.vue"; //大图查看

export default {
    components: {
        SelectTimeTab,
        BoxTitle,
        TimeLine,
        ViewerAlert,
    },
    name: "",
    data() {
        return {
            imgList: [],
            baseInfo: {
                ID: "",
                XJXMBH: "", //民居建设编号
                YCCZ: "", //村
                YCYS: "", //村民小组(组)
                CJSJ: this.common.getTimeYmd(), //创建时间

                HZXM: "", //户主姓名
                JTRK: "", //家庭人口
                SFZHM: "", //身份证号码
                MOBILE: "", //联系电话

                ZJDQK_DESC: "0", //宅基地情况
                ZJDMJ: "", //宅基地面积

                SFSPTG: "1", //建设方案是否审批通过
                FWJG: "", //房屋结构
                FWYT: "", //房屋用途

                SQZDMJ: "", //申请占地面积
                SJZDMJ: "", //实际占地面积
                SQJZMJ: "", //申请建筑面积
                SJJZMJ: "", //实际建筑面积

                FWCS: "", //房屋层数
                FWGD: "", //房屋高度
                WQYS: "#409EFF", //外墙颜色
                WDXS: "", //屋顶形式

                JFRQ: null, //建房时间
                JHJGRQ: null, //计划竣工时间
                JGSJ: null, //竣工时间

                SGDW: "", //施工单位
                SGFZR: "", //施工负责人
                SGDWDH: "", //施工单位联系电话

                BZ: "", //备注
                FAMC: "", //工程方案
                FALJ: "", //工程方案
                SPWJMC: "", //审批
                SPWJLJ: "", //审批
            },
            monitorInfo: {
                CDPZ: "", //场地平整
                JK: "", //基坑
                JC: "", //基础

                KJ: "", //框架
                FD: "", //封顶
                QQ: "", //砌墙

                SN: "", //室内装修
                SW: "", //室外装修
                JG: "", //竣工

                XZMS: "", //现状尺寸
                CZWT: "", //存在问题
            },
            monitorList: [],
            xzmsPicList: [], //现状描述照片

            detailPart: {
                dialog: false,
                list: [],
                LJ: "",
                XCRY: "",
                DEPARTMENTNAME: "",
                XMMS: "",
            },
            pageIndex: 1,
            pageSize: 6,
            pageTotal: 0,
        };
    },
    computed: {
        ...mapGetters(["jskzProjectItem"]),
    },
    props: {},
    watch: {
        jskzProjectItem: {
            immediate: true,
            handler() {
                if (this.jskzProjectItem) {
                    this.getMainInfo();
                }
            },
            deep: true,
        },
    },
    mounted() {},
    methods: {
        ...mapActions(["GetMjjsCjsjList", "GetMjjsCjsjFiles"]),
        ...mapMutations({
            setStateJskz: "setStateJskz",
        }),
        // 获取 监测数据
        async getMainInfo() {
            let result = await this.GetMjjsCjsjList({
                mjjsid: this.jskzProjectItem.ID,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            });
            this.baseInfo = result.BASICDT[0];
            this.monitorList = result.DT;
            result.DT.map((item, index) => {
                item.CJSJ = item.CJSJ.split(" ")[0];
            });

            this.pageTotal = result.COUNT;
            this.pageTotal = result.COUNT;
            if (this.monitorList.length) {
                this.monitorInfo = result.DT[0];
                this.getXzmsPic();
            } else {
                this.monitorInfo = {
                    CDPZ: "", //场地平整
                    JK: "", //基坑
                    JC: "", //基础

                    KJ: "", //框架
                    FD: "", //封顶
                    QQ: "", //砌墙

                    SN: "", //室内装修
                    SW: "", //室外装修
                    JG: "", //竣工

                    XZMS: "", //现状尺寸
                    CZWT: "", //存在问题
                };
            }
        },
        // 获取 现状描述的照片
        async getXzmsPic() {
            let result = await this.GetMjjsCjsjFiles({
                mjjsjcid: this.monitorInfo.ID,
            });
            this.xzmsPicList = result;
        },
        // 监测数据时间轴
        handleTimeLine(item, index) {
            this.monitorInfo = item;
            this.getXzmsPic();
        },
        // 监测照片分页
        handleCurrentChange(num) {
            this.pageIndex = num;
            this.getMainInfo();
        },
        handleClose() {
            this.setStateJskz({
                jskzProjectItem: null,
                jskzPanelNum: 0,
            });
        },
        //日常点位里的 图片 或者视频
        getImgDetail(src) {
            this.imgList = [
                {
                    alt: "",
                    src: src,
                    thumbnail: this.common.getThumbnailImg(src),
                },
            ];
            if (this.imgList.length) {
                this.$refs.myImgList.showViewer();
            }
        },
        getPdf(name) {
            let obj = {};
            let dw = "";
            switch (name) {
                case "工程方案":
                    obj = {
                        name: this.baseInfo.FAMC,
                        src: this.baseInfo.FALJ,
                    };
                    dw = this.baseInfo.FALJ.substring(
                        this.baseInfo.FALJ.lastIndexOf(".") + 1
                    );
                    break;
                case "批复文件":
                    obj = {
                        name: this.baseInfo.SPWJMC,
                        src: this.baseInfo.SPWJLJ,
                    };
                    dw = this.baseInfo.SPWJLJ.substring(
                        this.baseInfo.SPWJLJ.lastIndexOf(".") + 1
                    );
                    break;
            }
            if (dw == "pdf") {
                this.setStateJskz({
                    jskzPdfItem: obj,
                });
            } else {
                window.open(obj.src);
            }
        },
    },
};
</script>
<style scoped lang="scss">
.rightPart {
    width: 100%;
    height: 100%;
    background: rgba(26, 56, 89, 0.3);
    // 基本信息
    .base-part {
        width: 100%;
        height: calc(100% - 50px);
        .base-ctn {
            width: 100%;
            height: 100%;
            .info-item {
                .name {
                    height: 35px;
                    line-height: 35px;
                    text-align: left;
                    color: rgba(86, 115, 162, 1);
                    font-size: 16px;
                }
                .value {
                    height: 35px;
                    line-height: 35px;
                    text-align: left;
                    background: rgba(26, 48, 89, 0.3);
                    color: #d7eaf5;
                    padding-left: 10px;
                    font-size: 16px;

                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                .detail {
                    margin-left: 10px;
                    color: #fff;
                    cursor: pointer;
                }
            }
            .avg2 {
                width: 49%;
            }
            .avg3 {
                width: 32%;
            }
            .avg4 {
                width: 24%;
            }

            .timePart {
                margin-top: 10px;
                position: relative;
                .pages {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }
    // 异常详情
    .detail-ctn {
        width: 100%;
        height: 300px;
        margin-top: 10px;
        .listPart {
            width: calc(50% - 5px);
            .listItem {
                width: 100%;
                flex: 1;
                .name {
                    height: 35px;
                    line-height: 35px;
                    text-align: left;
                    color: rgba(86, 115, 162, 1);
                    font-size: 16px;
                }
                .value {
                    height: 35px;
                    line-height: 35px;
                    text-align: left;
                    background: rgba(26, 48, 89, 0.3);
                    color: #d7eaf5;
                    padding-left: 10px;
                    font-size: 16px;
                }
                .check {
                    overflow: hidden;
                    line-height: 30px;
                    font-size: 14px;
                    height: 60px;
                }
            }
        }
        .photoPart {
            width: calc(50% - 5px);
            height: 217px;
        }
        .timePart {
            margin-top: 10px;
            position: relative;
            .pages {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
    }
}
</style>
