<template>
    <div class="screenCenter protectScreen">
        <!-- 地图 -->
        <div class="mapPart">
            <MyMap :myMap.sync="myMap" :mapData="mapData" ref="myMap"></MyMap>
        </div>
        <!-- 图层 -->
        <div class="mapChangePart">
            <div
                class="layer pointer flex-between-y-center"
                @click="layerState = !layerState"
            >
                <div class="desc">图层</div>
                <img src="@image/bigScreen/icon/layer.png" />
            </div>
            <div v-show="layerState" class="layerCtn">
                <LayerList
                    :listAry="onOffList"
                    keyValue="protect_project"
                    @switchStateChange="switchStateChange"
                ></LayerList>
            </div>
        </div>
        <!-- 图例 -->
        <div class="legendPart flex">
            <LegendList
                :listAry="legendList"
                screenName="protect_project"
            ></LegendList>
        </div>
        <!-- marker 弹出框-->
        <div v-show="commonDialog.dialog" class="commonDialogPart">
            <div class="popupTitle flex-between">
                <div style="flex: 1; border-bottom: 1px #84acc2 solid"></div>
                <div class="titleCenter" :title="commonDialog.title">
                    <img src="@image/bigScreen/titleBgleft.png" />
                    <span class="titleContent">{{ commonDialog.title }}</span>
                    <img src="@image/bigScreen/titleBgright.png" />
                </div>
                <div style="flex: 1; border-bottom: 1px #84acc2 solid"></div>
                <i
                    class="el-icon-close titleIcon pointer"
                    @click="closeComDialog()"
                ></i>
            </div>
            <div class="popupCtn">
                <DialogSxt
                    :detail="commonDialog.detail"
                    v-if="commonDialog.sxtDialog"
                ></DialogSxt>
            </div>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import MyMap from "@views/big_screen/comp/map/BaseMap.vue";
import OnOff from "@views/big_screen/comp/OnOff.vue"; //开关
// import DialogSxt from "@views/big_screen/monitor_all/4_page_side/comp/DialogSxt.vue"; //摄像机 弹出框
import DialogSxt from "@views/big_screen/monitor_all/4_page_side/comp/DialogSxtIframe.vue"; //摄像头 iframe 公司 live-player 厂家提供的插件 第三方的监控

import LegendList from "@views/big_screen/comp/LegendList.vue"; //图例
import LayerList from "@views/big_screen/comp/LayerList.vue"; //图层
export default {
    components: {
        MyMap,
        OnOff,
        DialogSxt,
        LegendList,
        LayerList,
    },
    name: "",
    data() {
        return {
            myMap: {},
            mapData: {
                baseMapNum: 0,
                mapTypeBtn: true,
                showTool: true,
            },
            layerState: false,
            onOffList: [
                {
                    name: "保护工程",
                    state: true,
                    group: null,
                    list: [],
                    width: "20px",
                    height: "20px",
                    src: require("@image/bigScreen/monitor_all/marker/active/bhgc.png"),
                },
                {
                    name: "监测点位",
                    state: true,
                    group: null,
                    list: [],
                    width: "17px",
                    height: "25px",
                    src: require("@image/bigScreen/marker/yellow.png"),
                },
                // {
                //     name: "摄像机",
                //     state: true,
                //     group: null,
                //     list: [],
                //     width: "20px",
                //     height: "20px",
                //     src: require("@image/bigScreen/monitor_all/marker/active/monitor.png"),
                //     src_gun: require("@image/bigScreen/monitor_all/marker/active/monitor_gun.png"),
                //     src_error: require("@image/bigScreen/monitor_all/marker/active/monitor_error.png"),
                //     src_gun_error: require("@image/bigScreen/monitor_all/marker/active/monitor_gun_error.png"),
                // },
            ],
            legendList: [
                {
                    name: "保护工程",
                    width: "20px",
                    height: "20px",
                    state: true,
                    src: require("@image/bigScreen/monitor_all/marker/active/bhgc.png"),
                },
                {
                    name: "监测点位",
                    state: true,
                    width: "17px",
                    height: "25px",
                    src: require("@image/bigScreen/marker/yellow.png"),
                },
                // {
                //     name: "球形摄像机",
                //     state: true,
                //     width: "20px",
                //     height: "20px",
                //     src: require("@image/bigScreen/monitor_all/marker/active/monitor.png"),
                // },
                // {
                //     name: "枪形摄像机",
                //     state: true,
                //     width: "20px",
                //     height: "20px",
                //     src: require("@image/bigScreen/monitor_all/marker/active/monitor_gun.png"),
                // },
            ],
            // marker弹出框
            commonDialog: {
                title: "",
                detail: {},
                dialog: false,
                sxtDialog: false, //摄像机弹出框
            },
            ycysGroup: null,
        };
    },
    props: {
        positionStr: {
            type: String,
            default: "",
        },
    },
    computed: {
        ...mapGetters(["bhgcItem", "bhgcYczcItem", "bhgcYcysItem"]),
    },
    watch: {
        bhgcYczcItem: {
            immediate: true,
            handler() {
                if (this.bhgcYczcItem) {
                    let obj = JSON.parse(this.bhgcYczcItem);
                    this.myMap.flyTo([obj.WD, obj.JD], 20);
                }
            },
        },
        bhgcYcysItem: {
            immediate: false,
            handler() {
                if (this.bhgcYcysItem) {
                    let obj = JSON.parse(this.bhgcYcysItem);
                    this.myMap.flyTo([obj.WD, obj.JD], 20);
                    this.drawYcysMarkerSingle(this.ycysGroup, obj);
                } else {
                    this.clearLayersGroup(this.ycysGroup);
                }
            },
        },
        bhgcItem: {
            immediate: false,
            handler() {
                if (this.bhgcItem) {
                    this.drawBhgcItem();
                } else {
                    this.clearBhgcItem();
                }
            },
        },
        positionStr: {
            immediate: true,
            handler() {
                if (this.positionStr == "left") {
                    this.mapData.baseMapNum = 0;
                } else if (this.positionStr == "right") {
                    this.mapData.baseMapNum = 1;
                }
            },
        },
    },
    mounted() {
        this.onOffList.map((item) => {
            item.group = window.L.layerGroup().addTo(this.myMap);
        });
        this.ycysGroup = window.L.layerGroup().addTo(this.myMap);
        this.getAllBhgcList();
        // this.getSxtList();
        // marker 弹出框
        this.markerPopup = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, -4],
            className: "markerPopup",
        });
    },
    methods: {
        ...mapActions(["getVectorStyle", "MapCameraList", "getBhgcDpList"]),
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        ...mapMutations({
            setStateBhgc: "setStateBhgc",
        }),
        // 更改图层
        async switchStateChange(state, item) {
            item.state = state;
            let group = item.group;
            let list = item.list;
            if (!state) {
                this.clearLayersGroup(group);
                return;
            }
            switch (item.name) {
                case "保护工程":
                    this.drawBhgc(group, list);
                    break;
                case "监测点位":
                    this.drawJcdw(group, list);
                    break;
                case "摄像机":
                    this.drawSxt(item);
                    break;
            }
        },
        // 保护工程列表
        async getAllBhgcList() {
            const result = await this.getBhgcDpList({ ycysbm: "" });
            this.onOffList[0].list = result;
            this.drawBhgc(this.onOffList[0].group, this.onOffList[0].list);
        },
        // 画保护工程
        drawBhgc(group, list) {
            this.clearLayersGroup(group);
            list.forEach((item, index) => {
                const geoJSON = item && item.GCXMZB && JSON.parse(item.GCXMZB);
                if (geoJSON) {
                    let layer = window.L.geoJSON(geoJSON, {
                        id: item.ID,
                        style: function() {
                            return {
                                color: "#35b1bb",
                            };
                        },
                    }).addTo(group);
                    // 每一个区域的点击事件
                    layer.on("click", () => {
                        this.setStateBhgc({
                            bhgcItem: item,
                            bhgcActiveNum: index,
                            bhgcPanelNum: 1,
                        });
                    });
                }
            });

            if (this.bhgcItem) {
                this.drawBhgcItem();
            }
        },
        drawBhgcItem() {
            if (!this.bhgcItem.GCXMZB) {
                return;
            }
            let areaItem = JSON.parse(this.bhgcItem.GCXMZB)[0];
            let markerList = [];
            if (areaItem.geometry.type == "MultiPolygon") {
                areaItem.geometry.coordinates.map((item) => {
                    item.map((itemInner) => {
                        markerList = [...itemInner, ...markerList];
                    });
                });
            } else {
                markerList = areaItem.geometry.coordinates[0];
            }
            let boundsList = [];
            markerList.map((item) => {
                boundsList.push([item[1], item[0]]);
            });
            this.myMap.fitBounds(boundsList);

            let center = window.turf.centerOfMass(areaItem);
            let lat = center.geometry.coordinates[1];
            let lng = center.geometry.coordinates[0];
            this.markerPopup
                .setLatLng([lat, lng])
                .setContent(
                    `<div class="tip">${this.bhgcItem.SJDDYCGCYSMC}</div>`
                )
                .openOn(this.myMap);

            this.onOffList[1].list = this.bhgcItem.JCWZLIST;
            this.drawJcdw(this.onOffList[1].group, this.onOffList[1].list);
            let activeColor = "#35b1bb";
            Object.values(this.onOffList[0].group._layers).map((item) => {
                if (item.options.id == this.bhgcItem.ID) {
                    item.setStyle({
                        width: 3,
                        color: activeColor,
                        fillColor: activeColor,
                        fillOpacity: "0.8",
                    });
                } else {
                    item.setStyle({
                        color: activeColor,
                        fillColor: activeColor,
                        width: 1,
                        fillOpacity: "0.3",
                    });
                }
            });
        },
        clearBhgcItem() {
            this.onOffList[1].list = [];
            this.clearLayersGroup(this.onOffList[1].group);
            this.markerPopup.removeFrom(this.myMap);
            this.$refs.myMap.changeFitBounds();
            Object.values(this.onOffList[0].group._layers).forEach((item) => {
                item.setStyle({
                    color: "#35b1bb",
                    fillColor: "#35b1bb",
                    width: 1,
                    fillOpacity: "0.3",
                });
            });
        },
        // 画监测点位
        drawJcdw(group, list) {
            list.map((item) => {
                if (item.ZBXX) {
                    JSON.parse(item.ZBXX).map((itemInner) => {
                        item.JD = itemInner.geometry.coordinates[0];
                        item.WD = itemInner.geometry.coordinates[1];
                    });
                }
            });
            this.clearLayersGroup(group);
            list.forEach((v) => {
                if (!v.ZBXX) {
                    return;
                }
                let icon = window.L.icon({
                    iconUrl: this.onOffList[1].src,
                    iconSize: [17, 25],
                    iconAnchor: [8, 12],
                });
                let layer = window.L.marker([v.WD, v.JD], {
                    icon: icon,
                }).addTo(group);
                layer.on("click", () => {
                    this.setStateBhgc({
                        bhgcJcdwItem: v,
                    });
                });
                layer
                    .on("mouseover", () => {
                        this.markerPopup
                            .setLatLng([v.WD, v.JD])
                            .setContent(`<div class="tip">${v.JCWZMC}</div>`)
                            .openOn(this.myMap);
                    })
                    .on("mouseout", () => {
                        this.markerPopup.removeFrom(this.myMap);
                    });
            });
        },
        // 获取摄像机列表 保护工程这里没有摄像机
        async getSxtList() {
            let result = await this.MapCameraList({});
            this.onOffList[2].list = result;
            this.onOffList.map((item) => {
                if (item.name == "摄像机") {
                    this.drawSxt(item);
                }
            });
        },
        // 画摄像机
        drawSxt(obj) {
            let list = obj.list;
            let group = obj.group;
            this.clearLayersGroup(group);
            list.forEach((item) => {
                // SFJT 0 -否 1-是
                let url = "";
                if (item.SFJT) {
                    // SBQTLX 0:球机 1：枪机
                    if (item.SBQTLX == 0) {
                        url = obj.src;
                    } else {
                        url = obj.src_gun;
                    }
                } else {
                    // SBQTLX 0:球机 1：枪机
                    if (item.SBQTLX == 0) {
                        url = obj.src_error;
                    } else {
                        url = obj.src_gun_error;
                    }
                }
                let icon = window.L.icon({
                    iconUrl: url,
                    iconSize: [32, 32],
                    iconAnchor: [16, 16],
                });

                let layer = window.L.marker([item.AZDWD, item.AZDJD], {
                    icon: icon,
                }).addTo(group);
                layer.on("click", () => {
                    // if (!item.RTMP) {
                    //     this.common.showMsg("暂无设备", "warning");
                    //     return;
                    // }
                    if (!item.SFJT) {
                        this.common.showMsg("设备故障", "warning");
                    }
                    this.commonDialog.dialog = true;
                    this.commonDialog.sxtDialog = true;
                    this.commonDialog.detail = item;
                    this.commonDialog.title = item.SBMC;
                });
                layer
                    .on("mouseover", () => {
                        this.markerPopup
                            .setLatLng([item.AZDWD, item.AZDJD])
                            .setContent(`<div class="tip">${item.SBMC}</div>`)
                            .openOn(this.myMap);
                    })
                    .on("mouseout", () => {
                        this.markerPopup.removeFrom(this.myMap);
                    });
            });
        },
        closeComDialog() {
            this.commonDialog.dialog = false;
            this.commonDialog.sxtDialog = false; //摄像机 弹出框
        },
        // 遗产要素 marker 一个
        drawYcysMarkerSingle(group, item) {
            this.clearLayersGroup(group);
            let src = require("@image/bigScreen/monitor_all/marker/active/ycys.png");
            let html = `<div style="position: absolute;
                                    top: 0;
                                    left: 50%;
                                    text-align:center;
                                    transform: translateX(-50%);">
                            <div
                             style="background: rgb(3, 23, 38);
                                    opacity: 0.8;
                                    border-radius: 5px;
                                    white-space:nowrap;
                                    padding:4px 10px;color:#fff;">
                              ${item.MC}
                            </div>
                    <img src=${src} style="margin-top:4px;"/>
                </div> `;
            let icon = window.L.divIcon({
                html: html,
                // iconSize: [32, 32],
                // iconAnchor: [16, 16],
                iconSize: [32, 62],
                iconAnchor: [16, 62],
            });
            let layer = window.L.marker([item.WD, item.JD], {
                icon: icon,
                data: item,
            }).addTo(group);
            layer.on("click", () => {});
        },
    },
};
</script>
<style scoped lang="scss">
.screenCenter {
    position: relative;
    width: 100%;
    height: 100%;
    .mapPart {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
    }
    .compassPart {
        position: absolute;
        left: 10px;
        top: 10px;
        z-index: 10;
        width: 50px;
    }
    .mapChangePart {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 10;
        .layer {
            position: absolute;
            top: 0;
            left: 0;
            width: 130px;
            height: 40px;
            background: #1a3059;
            border: 1px solid #35507c;
            border-radius: 10px;
            box-shadow: 5px 9px 30px 0px rgba(0, 44, 75, 0.2);
            padding: 0 20px;

            .desc {
                color: #fff;
                font-size: 18px;
            }
        }

        .layerCtn {
            min-width: 130px;
            margin-top: 50px;
            padding: 10px;
            background: #1a3059;
            border-radius: 5px;
        }
    }
    // 图例
    .legendPart {
        position: absolute;
        left: 10px;
        bottom: 10px;
        z-index: 10;
    }
    .commonDialogPart {
        position: absolute;
        top: -40px;
        right: 5px;
        z-index: 999;
        background: #040e1e;
        // box-shadow: 0 0 15px 10px #5d8da8;
        padding: 10px;
        .popupTitle {
            width: 100%;
            height: 36px;
            line-height: 35px;
            display: flex;
            position: relative;
            padding: 0 15px;
            // 标题内容
            .titleCenter {
                width: fit-content;
                height: 100%;
                margin: 0 auto;
                font-size: 18px;
                color: rgb(210, 234, 246);
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                .titleContent {
                    padding: 0 30px;
                    min-width: 200px;
                    display: inline-block;
                    border-top: 1px solid #84acc2;
                    background: linear-gradient(
                        rgba(48, 80, 97, 1),
                        rgba(48, 80, 97, 0)
                    );
                }
            }
            // 标题按钮
            .titleIcon {
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
                color: #ffffff;
                font-size: 24px;
            }
        }
        .popupCtn {
            width: 100%;
            padding: 20px 14px 10px 14px;
            height: calc(100% - 30px);
        }
    }
}
</style>
