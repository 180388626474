<template>
    <div class="photoPart">
        <div class="carouselPart">
            <el-carousel :interval="4000" ref="myphoto" indicator-position="none" :autoplay="false" :height="carouselHeight" v-viewer>
                <el-carousel-item v-for="(item, index) in listAry" :key="index">
                    <el-image :src="item[imgValue]" style="width: 100%; height: 100%" class="pointer" fit="cover">
                        <div
                            slot="error"
                            style="width:100%;height:100%;"
                        >
                            <SnNoData width="170px"></SnNoData>
                        </div>
                    </el-image>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="actionPart flex">
            <div class="toleft" @click="toLeft()">
                <i class="el-icon-arrow-left"></i>
            </div>
            <div class="photoSelect flex-1">
                <el-select size="mini" class="picSelectSelf" style="width: 100%" popper-class="common-element-select-panel" v-model="nowDate" placeholder=" " @change="changeDate">
                    <el-option v-for="(item, index) in listAry" :key="index" :label="item[timeValue]" :value="item[timeValue]"></el-option>
                </el-select>
            </div>
            <div class="toright" @click="toRight()">
                <i class="el-icon-arrow-right"></i>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "",
        data() {
            return {
                nowIndex: 0,
                nowDate: "",
            };
        },
        components: {},
        props: {
            listAry: {
                type: Array,
                default: () => {
                    return [];
                },
            },
            carouselHeight: {
                type: String,
                default: () => {
                    return "300px";
                },
            },
            imgValue: {
                type: String,
                default: () => {
                    return "TPLJ";
                },
            },
            timeValue: {
                type: String,
                default: () => {
                    return "CJSJ";
                },
            },
            defaultIndex: {
                type: Number,
                default: 0,
            },
        },
        watch: {
            listAry: {
                immediate: true,
                handler() {
                    if (this.defaultIndex) {
                        this.nowIndex = this.defaultIndex;
                    } else {
                        this.nowIndex = 0;
                    }
                    if (this.listAry.length) {
                        this.$refs.myphoto && this.$refs.myphoto.setActiveItem(this.nowIndex);
                        this.nowDate = this.listAry[this.nowIndex][this.timeValue];
                    }
                },
            },
        },
        mounted() {},
        methods: {
            toLeft() {
                if (this.nowIndex > 0) {
                    this.nowIndex = this.nowIndex - 1;
                    this.nowDate = this.listAry[this.nowIndex][this.timeValue];
                    this.$refs.myphoto.setActiveItem(this.nowIndex);
                }
            },
            toRight() {
                if (this.nowIndex < this.listAry.length) {
                    this.nowIndex = this.nowIndex + 1;
                    this.nowDate = this.listAry[this.nowIndex][this.timeValue];
                    this.$refs.myphoto.setActiveItem(this.nowIndex);
                }
            },
            changeDate() {
                let nowIndex = this.listAry.findIndex(
                    (v) => v[this.timeValue] == this.nowDate
                );
                if (nowIndex > -1) {
                    this.nowIndex = nowIndex;
                    this.$refs.myphoto.setActiveItem(this.nowIndex);
                }
            },
            getUrl(url) {
                return this.common.getThumbnailImg(url);
            }
        },
    };
</script>
<style lang="scss">
.picSelectSelf {
    .el-input__inner {
        background-color: transparent;
        border: none;
        color: #a8c8ff;
        font-size: 16px;
    }
}
</style>
<style scoped lang="scss">
    .photoPart {
        width: 100%;
        height: 100%;

        .carouselPart {
            width: 100%;
            height: calc(100% - 40px);

            img {
                width: 100%;
                height: 100%;
            }
        }

        .actionPart {
            height: 30px;
            margin-top: 10px;

            .toleft,
            .toright {
                width: 30px;
                background: #0b2940;
                color: #d7eaf5;
                line-height: 30px;
                font-size: 24px;
                border-radius: 5px;
                cursor: pointer;
                text-align: center;
            }

            .photoSelect {
                border: 1px #4580a0 solid;
                border-radius: 5px;
                margin: 0 10px;
                padding: 0 50px;
            }
        }
    }
</style>