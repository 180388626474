<template>
    <div class="leftPart scrollbar-hidden">
        <div class="leftTop">
            <Ycys></Ycys>
        </div>
        <div class="leftBottom">
            <Bhgc></Bhgc>
        </div>
    </div>
</template>
<script>
import Ycys from "./1_ycys.vue"; //遗产要素
import Bhgc from "./2_bhgc.vue"; //保护工程
export default {
    name: "",
    components: {
        Ycys,
        Bhgc,
    },
    methods: {
        dealMap(info) {
            this.$emit("dealMap", info);
        },
    },
};
</script>
<style scoped lang="scss">
.leftPart {
    width: 100%;
    height: 100%;
    .leftTop {
        width: 100%;
        height: 470px;
    }
    .leftBottom {
        width: 100%;
        height: calc(100% - 470px);
    }
}
</style>
