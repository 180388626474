<template>
    <div class="dialogCtn">
        <div class="flex-between">
            <div class="popRight">
                <div class="label">海拔</div>
                <div class="detail">{{ formData.HB }} 米</div>
                <div class="label">样方名称</div>
                <div class="detail">
                    {{ formData.MC }}
                </div>
                <div class="label">植物种类</div>
                <div class="detail">
                    {{ formData.ZWZL }}
                </div>
            </div>
            <div class="popRight">
                <div class="label">郁闭度</div>
                <div class="detail">{{ formData.YBD }}</div>
                <div class="label">植物群系</div>
                <div class="detail ellipsis" :title="formData.ZWQX">
                    {{ formData.ZWQX }}
                </div>
                <div class="label">植物总数</div>
                <div class="detail">{{ formData.ZWZS }}</div>
            </div>
        </div>
        <div v-if="formData.PIC.length">
            <div class="label">照片</div>
            <div class="detail flex flex-wrap">
                <div v-for="(item, index) in formData.PIC" :key="index">
                    <el-image
                        @click="getImgDetail(item.CCLJ)"
                        :src="common.getThumbnailImg(item.CCLJ)"
                        fit="cover"
                        style="width: 140px; height: 120px; margin-right: 10px"
                        class="pointer"
                    >
                        <div slot="error" style="width: 100%; height: 100%">
                            <SnNoData width="100px"></SnNoData>
                        </div>
                    </el-image>
                    <div class="name">{{ item.WDMC }}</div>
                </div>
            </div>
        </div>
        <ViewerAlert ref="myImgList" :imgList="imgList"></ViewerAlert>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import ViewerAlert from "@comp/ViewerAlert.vue"; //大图查看

export default {
    components: {
        ViewerAlert,
    },
    name: "",
    data() {
        return {
            imgList: [],
            formData: {
                HB: "", //海拔
                MC: "", //样方名称
                YBD: "", //郁闭度
                ZWQX: "", //植物群系
                ZWZL: "", //植物种类
                ZWZS: "", //植物总数
            },
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {
        detail: {
            Type: Object,
            default: () => {
                let obj = {
                    ID: "",
                };
                return obj;
            },
        },
    },
    watch: {
        detail: {
            deep: true,
            immediate: true,
            handler() {
                this.getMainData();
            },
        },
    },
    mounted() {},
    methods: {
        ...mapActions(["GetZwYfDetail"]),
        ...mapMutations({}),
        async getMainData() {
            let result = await this.GetZwYfDetail({
                yfid: this.detail.ID,
            });
            this.formData = result;
        },
        //日常点位里的 图片 或者视频
        getImgDetail(src) {
            this.imgList = [
                {
                    alt: "",
                    src: src,
                    thumbnail: this.common.getThumbnailImg(src),
                },
            ];
            if (this.imgList.length) {
                this.$refs.myImgList.showViewer();
            }
        },
    },
};
</script>
<style scoped lang="scss">
.dialogCtn {
    width: 100%;
    height: 100%;
    .popLeft {
        width: calc(50% - 5px);
        height: 100%;
    }
    .popRight {
        width: calc(50% - 5px);
        height: 100%;
    }
    .label {
        color: rgba(86, 115, 162, 1);
        font-size: 14px;
        margin-top: 10px;
    }
    .detail {
        color: rgba(168, 200, 255, 1);
        font-size: 14px;
        background: rgba(53, 80, 124, 0.2);
        padding: 4px 10px;
    }
}
</style>
