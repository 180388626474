<template>
    <div class="screenItem">
        <!-- 景观监测 -->
        <BasicMonitor :text="common.getFyName(fany.jgjc)">
            <div slot="basic-module" class="screenCtn scrollbar-hidden">
                <div class="jgfmPart">
                    <div class="desc flex-between">
                        <div class="title">景观风貌</div>
                        <div
                            class="state flex-both-center"
                            :class="[
                                jgfm.state == '0'
                                    ? 'stateSuccess'
                                    : 'stateError',
                            ]"
                        >
                            {{ jgfm.desc }}
                        </div>
                    </div>
                    <div class="flex-between detailPart">
                        <div class="img carouselArrowShow">
                            <el-carousel
                                indicator-position="none"
                                height="151px"
                                :arrow="
                                    jgfm.list.length > 1 ? 'hover' : 'never'
                                "
                                :autoplay="true"
                            >
                                <el-carousel-item
                                    v-for="(item, index) in jgfm.list"
                                    :key="index"
                                    class="pointer"
                                >
                                    <el-image
                                        :src="common.getThumbnailImg(item.TPLJ)"
                                        fit="cover"
                                        class="pointer"
                                        style="width: 100%; height: 100%"
                                        @click="getImgShow(0, index)"
                                    >
                                        <div
                                            slot="error"
                                            style="width: 100%; height: 100%"
                                        >
                                            <SnNoData width="100px"></SnNoData>
                                        </div>
                                    </el-image>
                                </el-carousel-item>
                            </el-carousel>
                        </div>

                        <div class="flex-between">
                            <div>
                                <div class="jcwz flex-both-center">
                                    监测位置
                                    <div class="state flex-both-center">
                                        <div
                                            class="steteIcon stateLeftTop"
                                        ></div>
                                        <div
                                            class="steteIcon stateRightTop"
                                        ></div>
                                        <div
                                            class="steteIcon stateLeftBottom"
                                        ></div>
                                        <div
                                            class="steteIcon stateRightBottom"
                                        ></div>
                                    </div>
                                </div>
                                <div class="jcfa flex-both-center">
                                    监测方法
                                    <div class="state flex-both-center">
                                        <div
                                            class="steteIcon stateLeftTop"
                                        ></div>
                                        <div
                                            class="steteIcon stateRightTop"
                                        ></div>
                                        <div
                                            class="steteIcon stateLeftBottom"
                                        ></div>
                                        <div
                                            class="steteIcon stateRightBottom"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="topPart flex-both-center">
                                    <span class="num">{{ jgfm.jcwz }}</span
                                    ><span class="dw">处</span>
                                </div>
                                <div
                                    class="
                                        bottomPart
                                        flex-both-center flex-column
                                    "
                                >
                                    <div class="desc">摄像头</div>
                                    <div class="desc">无人机</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="jgfmPart" style="margin-top: 10px">
                    <div class="desc flex-between">
                        <div class="title">景观视线</div>
                        <div
                            class="state flex-both-center"
                            :class="[
                                jgsx.state == '0' || jgsx.state == '4'
                                    ? 'stateSuccess'
                                    : 'stateError',
                            ]"
                        >
                            {{ jgsx.desc }}
                        </div>
                    </div>
                    <div class="flex-between detailPart">
                        <div class="img carouselArrowShow">
                            <el-carousel
                                indicator-position="none"
                                height="151px"
                                :autoplay="true"
                                :arrow="
                                    jgsx.list.length > 1 ? 'hover' : 'never'
                                "
                            >
                                <el-carousel-item
                                    v-for="(item, index) in jgsx.list"
                                    :key="index"
                                    class="pointer"
                                >
                                    <el-image
                                        :src="common.getThumbnailImg(item.TPLJ)"
                                        fit="cover"
                                        class="pointer"
                                        style="width: 100%; height: 100%"
                                        @click="getImgShow(1, index)"
                                    >
                                        <div
                                            slot="error"
                                            style="width: 100%; height: 100%"
                                        >
                                            <SnNoData width="100px"></SnNoData>
                                        </div>
                                    </el-image>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                        <div class="flex-between">
                            <div>
                                <div class="jcwz flex-both-center">
                                    监测位置
                                    <div class="state flex-both-center">
                                        <div
                                            class="steteIcon stateLeftTop"
                                        ></div>
                                        <div
                                            class="steteIcon stateRightTop"
                                        ></div>
                                        <div
                                            class="steteIcon stateLeftBottom"
                                        ></div>
                                        <div
                                            class="steteIcon stateRightBottom"
                                        ></div>
                                    </div>
                                </div>
                                <div class="jcfa flex-both-center">
                                    监测方法
                                    <div class="state flex-both-center">
                                        <div
                                            class="steteIcon stateLeftTop"
                                        ></div>
                                        <div
                                            class="steteIcon stateRightTop"
                                        ></div>
                                        <div
                                            class="steteIcon stateLeftBottom"
                                        ></div>
                                        <div
                                            class="steteIcon stateRightBottom"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="topPart flex-both-center">
                                    <span class="num">{{ jgsx.jcwz }}</span
                                    ><span class="dw">处</span>
                                </div>
                                <div
                                    class="
                                        bottomPart
                                        flex-both-center flex-column
                                    "
                                >
                                    <div class="desc">移动</div>
                                    <div class="desc">采集</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BasicMonitor>
        <ViewerAlert ref="myImgList" :imgList="imgList"></ViewerAlert>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import BasicMonitor from "@views/big_screen/monitor_all/comp/BasicModule";
import ViewerAlert from "@comp/ViewerAlert.vue"; //大图查看
export default {
    components: {
        BasicMonitor,
        ViewerAlert,
    },
    name: "",
    data() {
        return {
            jgfm: {
                state: 0,
                desc: "",
                jcwz: 0,
                list: [],
            },
            jgsx: {
                state: 0,
                desc: "",
                jcwz: 0,
                list: [],
            },
            imgList: [],
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {},
    watch: {},
    mounted() {
        this.getMainInfo();
    },
    methods: {
        ...mapActions(["GetJgjcInfo"]),
        ...mapMutations({}),
        async getMainInfo() {
            let result = await this.GetJgjcInfo({});
            if (result) {
                this.jgfm.desc = result.JGFMINFO.BHMC;
                this.jgfm.state = result.JGFMINFO.BHCODE; //0 正面影响 1 负面影响
                this.jgfm.jcwz = result.JGFMINFO.JCWZCOUNT;
                this.jgfm.list = result.JGFMINFO.TPLIST;
                this.jgsx.state = result.JGSXINFO.BHCODE;
                this.jgsx.desc = result.JGSXINFO.BHMC;
                this.jgsx.jcwz = result.JGSXINFO.JCWZCOUNT;
                this.jgsx.list = result.JGSXINFO.TPLIST;
                switch (window.location.hostname) {
                    // 监测中心 外网
                    // 公司
                    case "jmsgcl.geo-compass.com":
                    case "jmsjc.jmsclwh.cn":
                        break;
                    // 监测中心 内网
                    case "192.168.23.239":
                        // 景观风貌
                        // this.jgfm.list.map((item) => {
                        //     item.TPLJ =
                        //         "https://jmsjc.jmsclwh.cn/sxtfj/fpic\\20220216\\32_80_1\\20220216_130114952.jpg";
                        // });
                        // 景观视线
                        // this.jgsx.list.map((item) => {
                        //     item.TPLJ =
                        //         "https://jmsjc.jmsclwh.cn/fj/jmjc/basicData//06cbff92-80c8-4644-a3a9-d41cc731de2e.jpg";
                        // });
                        this.jgfm.list.map((item) => {
                            item.TPLJ =
                                "http://192.168.23.235:82" +
                                item.TPLJ.slice(30);
                        });
                        this.jgsx.list.map((item) => {
                            item.TPLJ =
                                "http://192.168.23.236" + item.TPLJ.slice(24);
                        });
                        break;
                    default:
                        break;
                }
            }
        },
        getImgShow(num, index) {
            let ary = [];
            let list = [];
            if (num == 0) {
                list = this.jgfm.list;
            } else {
                list = this.jgsx.list;
            }
            list.map((item) => {
                let obj = {
                    src: item.TPLJ,
                    thumbnail: this.common.getThumbnailImg(item.TPLJ),
                };
                ary.push(obj);
            });
            this.imgList = Array.from(ary);
            if (this.imgList.length) {
                this.$refs.myImgList.showViewer(index);
            }
        },
    },
};
</script>
<style scoped lang="scss">
.screenItem {
    position: relative;
    width: 100%;
    height: 100%;
    .screenCtn {
        width: 100%;
        height: 100%;
        padding: 12px 0;
        .jgfmPart {
            .desc {
                .title {
                    color: #a8c8ff;
                    font-size: 18px;
                }
                .state {
                    font-size: 14px;
                    padding: 0 10px;
                }
                .stateSuccess {
                    font-weight: 400;
                    border: 1px solid #61ff00;
                    color: #61ff00;
                }
                .stateError {
                    font-weight: 600;
                    border: 1px solid #ff0000;
                    color: #ff0000;
                }
            }
            .detailPart {
                margin-top: 10px;
                .img {
                    width: 208px;
                    height: 151px;
                }
                .jcwz {
                    width: 95px;
                    height: 45px;
                    position: relative;
                    font-size: 18px;
                    color: #a8c8ff;
                    margin-right: 4px;
                    margin-top: 8px;
                    background: url("~@image/bigScreen/icon/titleBg.png")
                        no-repeat;
                    background-size: 100% 100%;
                    color: rgba(168, 200, 255, 1);
                }
                .jcfa {
                    width: 95px;
                    height: 82px;
                    position: relative;
                    margin-top: 4px;
                    font-size: 18px;
                    color: #a8c8ff;
                    background: rgba(26, 48, 89, 0.4);
                }
                .state {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border: 1px solid rgba(103, 159, 192, 0.3);
                    .steteIcon {
                        width: 2px;
                        height: 2px;
                        background: rgba(168, 200, 255, 1);
                    }
                    .stateLeftTop {
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    .stateRightTop {
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                    .stateLeftBottom {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                    }
                    .stateRightBottom {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                }
                .topPart {
                    margin-top: 8px;
                    width: 80px;
                    height: 44px;
                    background: rgba(26, 48, 89, 0.4);
                    .num {
                        color: #ffcd80;
                        font-size: 34px;
                        font-family: DBPM;
                    }
                    .dw {
                        color: #ffcd80;
                        font-size: 14px;
                    }
                }
                .bottomPart {
                    margin-top: 4px;
                    width: 80px;
                    height: 81px;
                    background: rgba(26, 48, 89, 0.4);
                    .desc {
                        color: #ffcd80;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
</style>
