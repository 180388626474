<template>
    <div class="screenItem">
        <YcysList
            :yczcList="yczcList"
            :ycysKey="ycysKey"
            :ycysList="ycysList"
            :state="state"
            :legendState="false"
            :ycysTotal="ycysList.length"
            @getYcysItem="getYcysItem"
            @getYczcItem="getYczcItem"
            @loadData="loadData"
            @inputKeyWord="inputKeyWord"
            @ycysTypeItem="ycysTypeItem"
            @getKeyWord="getKeyWord"
            ref="ycys"
        ></YcysList>
    </div>
</template>

<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import YcysList from "@views/big_screen/comp/BasicYcys";
import { yczcFormatList } from "@libs/yczcFormatList"; //遗产组成 合并经纬度

export default {
    components: {
        YcysList,
    },
    name: "",
    data() {
        return {
            yczcList: [], //遗产组成列表 某个遗产组成下的
            ycysList: [], //遗产要素
            ycysListAll: [], //遗产组成列表 所有
            ycysKey: {
                legendType: 1, //0可控  轻微 严重  非常严重 1 好 良好  一般 差
                name: "MC", //名称
                yczcName: "YCYSZC", //遗产组成名称
                src: "CCLJ", //图片路径
                stateNum: "ZT", // 状
            },
            yczclx: "", //遗产组成类型
            keyWord: "",
            state: true, //true 还有数据 false 没有数据了
            ycyslx: "",
            keyWords: "",
            pageIndex: 1,
            pageSize: 30,
            totalNum: 0,
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {},
    watch: {},
    mounted() {
        this.getYcysList();
    },
    methods: {
        loadData() {},
        inputKeyWord() {},
        ...mapActions([
            "getBhgcYcysList",
            "getBhgcSlStatistics",
            "getBhgcGzxx",
            "getBhgcDetail",
        ]),
        ...mapMutations({
            setStateBhgc: "setStateBhgc",
        }),
        ycysTypeItem(item) {
            this.ycyslx = item ? item.name : "";
            this.getYcysList();
        },
        // 获取 遗产组成
        async getYcysList() {
            this.ycysList = [];
            let result = await this.getBhgcYcysList({
                ycyslx: this.ycyslx ? this.ycyslx : "",
                keyword: this.keyWord,
            });

            result.map((item, index) => {
                yczcFormatList.map((itemInner) => {
                    if (item.YCYSZC == itemInner.YCYSFL_2) {
                        item.JD = itemInner.JD;
                        item.WD = itemInner.WD;
                        item.LEVEL = itemInner.LEVEL;
                    }
                });
                item.index = index;
            });
            this.yczcList = result;

            result.map((item) => {
                this.ycysList = [...item.YCYSLIST, ...this.ycysList];
            });
            // 选中要素类型
            if (this.ycyslx) {
                if (this.yczcList.length) {
                    this.$refs.ycys.reSetYczc(0);
                    this.$refs.ycys.reSetYcys(0);
                    this.ycysList = this.yczcList[0].YCYSLIST;
                    this.setStateBhgc({
                        bhgcYcysItem: JSON.stringify(this.yczcList[0]),
                    });
                } else {
                    this.ycysList = [];
                    this.setStateBhgc({
                        bhgcYcysItem: null,
                    });
                }
            } else {
                this.setStateBhgc({
                    bhgcYcysItem: null,
                });
            }
        },
        // 点击遗产组成
        getYczcItem(item) {
            this.setStateBhgc({
                bhgcYczcItem: item ? JSON.stringify(item) : null,
            });
            if (item) {
                this.ycysList = item.YCYSLIST;
                // if (this.ycysList.length) {
                //     this.$refs.ycys.reSetYcys(0);
                //     this.getYcysItem(this.ycysList[0]);
                // }
            } else {
                this.getYcysList();
            }
        },
        // 点击遗产要素
        getYcysItem(item) {
            this.setStateBhgc({
                bhgcYcysItem: item ? JSON.stringify(item) : null,
            });
        },
        getKeyWord(item) {
            this.keyWord = item;
            this.getYcysList();
        },
    },
};
</script>

<style lang="scss" scoped>
.screenItem {
    width: 100%;
    height: 100%;

    .screenCtn {
        width: 100%;
        height: 100%;
    }
}
</style>
