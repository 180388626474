var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"legendCom flex scrollbar-hidden"},[_c('div',{staticClass:"title flex-y-center"},[_vm._v("图例")]),_c('div',{staticClass:"flex flex-wrap list"},[_vm._l((_vm.comLegendList),function(item,index){return _c('div',{key:(index + "_com"),staticClass:"flex-y-center legendItem"},[_c('img',{staticClass:"img",style:({
                    width: _vm.getCtclFgfhlGclWidth(),
                    height: _vm.getCtclFgfhlGclWidth(),
                }),attrs:{"src":item.src}}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))])])}),(_vm.screenName != 'ecological_environment')?_vm._l((_vm.listAry),function(item,index){return _c('div',{key:(index + "inner"),staticClass:"flex-y-center"},[(item.state)?_c('div',{staticClass:"legendItem flex-y-center"},[_c('img',{staticClass:"img",style:({
                            width: item.width ? item.width : '',
                            height: item.height ? item.height : '',
                        }),attrs:{"src":item.src}}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))])]):_vm._e()])}):_vm._l((_vm.listAry),function(item,index){return _c('div',{key:index,staticClass:"flex-y-center"},[(item.state)?_c('div',{staticClass:"legendItem"},[(item.mulList)?_c('div',{staticClass:"flex-y-center"},_vm._l((item.mulList),function(itemInner,indexInner){return _c('div',{key:indexInner,staticClass:"flex-y-center legendItem pointer"},[_c('div',{staticClass:"img",style:({
                                    background: itemInner.bgColor,
                                    width: '12px',
                                    height: '12px',
                                    borderRadius: item.radius,
                                })}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(itemInner.name))])])}),0):_c('div',{staticClass:"flex-y-center legendItem"},[_c('div',{staticClass:"img",style:({
                                background: item.bgColor,
                                width: '12px',
                                height: '12px',
                                borderRadius: item.radius,
                            })}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))])])]):_vm._e()])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }