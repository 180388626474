<template>
    <div class="leftPart scrollbar-hidden">
        <div class="leftTop">
            <Jcnr></Jcnr>
        </div>
        <div class="leftBottom">
            <Jcgl></Jcgl>
        </div>
    </div>
</template>
<script>
import Jcnr from "./1_jcnr.vue"; //监测内容
import Jcgl from "./2_jcgl.vue"; //监测概览
export default {
    name: "",
    components: {
        Jcnr,
        Jcgl
    },
};
</script>
<style scoped lang="scss">
.leftPart {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .leftTop {
        width: 100%;
        height: 383px;
    }
    .leftBottom {
        width: 100%;
        height: calc(100% - 383px);
    }
}
</style>
