<template>
    <div class="bhkzBox">
        <div class="chartPart">
            <div :id="'bhkzChartWind' + chartID" class="echart"></div>
        </div>
    </div>
</template>
<script>
export default {
    name: "bhkzBoxWind",
    props: {
        chartData: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            bhkzChart: null,
            chartID: Math.random(),
        };
    },
    watch: {
        chartData() {
            this.initEchart();
        },
    },
    mounted() {
        $(window).resize(() => {
            this.initChart();
        });
    },
    methods: {
        initEchart() {
            this.bhkzChart = this.$echarts.dispose(
                document.getElementById("bhkzChartWind" + this.chartID)
            );
            if (!this.chartData.length) {
                return;
            }
            let colorArr = ["rgb(65, 218, 236)", "rgb(236, 105, 65)"];
            let fontColor = "rgba(154,180,227,1)";
            let lineColor = "rgba(53,80,124,1)";
            let list = this.chartData;
            let max1 = Math.max.apply(
                null,
                list.map((item) => item.FS)
            );
            let max2 = Math.max.apply(
                null,
                list.map((item) => item.CS)
            );
            let max = max1 > max2 ? max1 : max2;
            let indicatorList = [];
            indicatorList = list.map((item) => {
                return {
                    name: item.FX,
                    max: max,
                };
            });
            let seriesData = [
                {
                    name: "风向偏好",
                    type: "radar",
                    data: [list.map((item) => item.CS)],
                    symbol: "none",
                    z: 0,
                    lineStyle: {
                        normal: {
                            color: colorArr[1],
                            width: 2,
                        },
                    },
                    areaStyle: {
                        normal: {
                            color: colorArr[1],
                            opacity: 0.2,
                        },
                    },
                    emphasis: {
                        areaStyle: {
                            opacity: 0.3,
                        },
                    },
                },
                {
                    name: "风向风速",
                    type: "radar",
                    data: [list.map((item) => item.FS)],
                    symbol: "none",
                    z: 0,
                    lineStyle: {
                        normal: {
                            color: colorArr[0],
                            width: 2,
                        },
                    },
                    areaStyle: {
                        normal: {
                            color: colorArr[0],
                            opacity: 0.2,
                        },
                    },
                    emphasis: {
                        areaStyle: {
                            opacity: 0.3,
                        },
                    },
                },
            ];

            let option = {
                tooltip: {
                    show: true,
                    trigger: "item",
                    formatter: (params) => {
                        var relVal = params.seriesName;
                        for (var i = 0, l = params.value.length; i < l; i++) {
                            if (params.seriesName === "风向风速") {
                                relVal +=
                                    "<br/>" +
                                    this.chartData[i].FX +
                                    " : " +
                                    params.value[i] +
                                    " " +
                                    "m/s";
                            } else if (params.seriesName === "风向偏好") {
                                relVal +=
                                    "<br/>" +
                                    this.chartData[i].FX +
                                    " : " +
                                    params.value[i] +
                                    " " +
                                    "次";
                            } else {
                                relVal +=
                                    "<br/>" +
                                    this.chartData[i].FX +
                                    " : " +
                                    params.value[i];
                            }
                        }
                        return relVal;
                    },
                },
                legend: {
                    data: ["风向风速", "风向偏好"],
                    textStyle: {
                        color: "#fff",
                    },
                },
                radar: {
                    radius: "55%",
                    shape: "circle",
                    name: {
                        textStyle: {
                            fontSize: 16,
                            color: fontColor,
                        },
                    },
                    axisLabel: {
                        show: false,
                        fontSize: 18,
                        color: lineColor,
                        fontStyle: "normal",
                        fontWeight: "normal",
                    },
                    splitArea: {
                        areaStyle: {
                            color: "transparent",
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: lineColor,
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: lineColor,
                        },
                    },
                    indicator: indicatorList,
                },
                series: seriesData,
            };

            this.bhkzChart = this.$echarts.init(
                document.getElementById("bhkzChartWind" + this.chartID)
            );
            this.bhkzChart.setOption(option);
        },
    },
};
</script>
<style lang="scss" scoped>
.bhkzBox {
    height: 100%;
    display: flex;
    .chartPart {
        width: 100%;
        position: relative;
        .echart {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
</style>