<template>
    <div class="screenItem">
        <!-- 监测内容 -->
        <basic-module :text="common.getFyName(fany.jcnr)">
            <div slot="basic-module" class="screenCtn">
                <div
                    class="list-item"
                    :class="
                        currentJcnrId === item.id && currentJcnrId === '5'
                            ? 'current-listitem6'
                            : currentJcnrId === item.id && currentJcnrId !== '5'
                            ? 'current-listitem'
                            : ''
                    "
                    v-for="item in listAry"
                    :key="item.id"
                    @click="handleJcnrClick(item)"
                    :style="{ opacity: item.label1 == '物候监测' ? 0.6 : 1 }"
                >
                    <div class="item-label">{{ item.label1 }}</div>
                    <div class="item-label">{{ item.label2 }}</div>
                    <div class="item-label">{{ item.label3 }}</div>
                </div>
                <div
                    class="dbx-item"
                    v-for="(item, index) in 8"
                    :key="'dbx' + index"
                ></div>
            </div>
        </basic-module>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    components: {},
    name: "",
    data() {
        return {
            listAry: [
                {
                    id: "0",
                    label1: "微环境",
                    label2: "气象监测",
                },
                {
                    id: "1",
                    label1: "病虫害",
                    label2: "虫情测报",
                },
                {
                    id: "2",
                    label1: "古茶林",
                    label2: "病虫害调查",
                },
                {
                    id: "3",
                    label1: "森林病虫害",
                    label2: "遥感监测",
                },
                {
                    id: "4",
                    label1: "微环境",
                    label2: "空气质量",
                    label3: "监测",
                },
                {
                    id: "5",
                    label1: "微环境",
                    label2: "土壤监测",
                },
                {
                    id: "6",
                    label1: "微环境",
                    label2: "水质监测",
                },
                {
                    id: "7",
                    label1: "古树名木",
                    label2: "监测",
                },
                {
                    id: "8",
                    label1: "植物",
                    label2: "多样性监测",
                },
                {
                    id: "9",
                    label1: "物候监测",
                },
                {
                    id: "10",
                    label1: "动物",
                    label2: "多样性监测",
                },
            ],
            currentJcnrId: "4",
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {},
    watch: {},
    mounted() {
        // this.handleJcnrClick(this.listAry[4]);
    },
    methods: {
        ...mapActions([""]),
        ...mapMutations({
            SET_STHJJCNRSELECT: TYPES.SET_STHJJCNRSELECT,
        }),
        handleJcnrClick(val) {
            if (val.label1 == "物候监测") {
                return;
            }
            // if (val.id === this.currentJcnrId) return;
            this.currentJcnrId = val.id;
            this.SET_STHJJCNRSELECT(val);
        },
    },
};
</script>
<style scoped lang="scss">
.screenItem {
    width: 100%;
    height: 100%;
    .screenCtn {
        position: relative;
        width: 100%;
        height: 100%;
        .list-item {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 82px;
            height: 94px;
            background: url("~@image/bigScreen/ecological_environment/JianCeNeiRong/bgSmall.png")
                no-repeat;
            cursor: pointer;
            .item-label {
                line-height: 24px;
                color: #a8c8ff;
            }
        }
        .list-item:hover,
        .current-listitem {
            background: url("~@image/bigScreen/ecological_environment/JianCeNeiRong/bgSmallHover.png")
                no-repeat;
            .item-label {
                color: #ffcd80;
            }
        }
        // 中间
        .list-item:nth-child(6) {
            left: calc(50% - 90px);
            top: calc(50% - 99px);
            width: 179px;
            height: 198px;
            background: url("~@image/bigScreen/ecological_environment/JianCeNeiRong/bgBig.png")
                no-repeat;
            .item-label {
                font-size: 24px;
                line-height: 36px;
            }
        }
        .list-item:nth-child(6):hover,
        .current-listitem6 {
            background: url("~@image/bigScreen/ecological_environment/JianCeNeiRong/bgBigHover.png")
                no-repeat !important;
            .item-label {
                color: #ffcd80;
            }
        }

        // 左1
        .list-item:nth-child(1) {
            left: calc(50% - 90px - 60px);
            top: 0;
        }
        // 左2
        .list-item:nth-child(2) {
            left: 73px;
            top: 25px;
        }
        // 左3
        .list-item:nth-child(3) {
            left: calc(50% - 90px - 60px - 41px);
            top: calc(50% - 47px);
        }
        // 左4
        .list-item:nth-child(4) {
            left: 73px;
            bottom: 25px;
        }
        // 左5
        .list-item:nth-child(5) {
            left: calc(50% - 90px - 60px);
            bottom: 0;
        }

        // 右1
        .list-item:nth-child(7) {
            right: calc(50% - 90px - 60px);
            top: 0;
        }
        // 右2
        .list-item:nth-child(8) {
            right: 73px;
            top: 25px;
        }
        // 右3
        .list-item:nth-child(9) {
            right: calc(50% - 90px - 60px - 41px);
            top: calc(50% - 47px);
        }
        // 右4
        .list-item:nth-child(10) {
            right: 73px;
            bottom: 25px;
        }
        // 右5
        .list-item:nth-child(11) {
            right: calc(50% - 90px - 60px);
            bottom: 0;
        }

        .dbx-item {
            position: absolute;
            width: 76px;
            height: 88px;
            background: url("~@image/bigScreen/ecological_environment/JianCeNeiRong/bg01.png")
                no-repeat;
        }
        // 从12开始
        .dbx-item:nth-child(12) {
            left: 15px;
            top: calc(50% - 44px);
        }
        .dbx-item:nth-child(13) {
            right: 15px;
            top: calc(50% - 44px);
        }
        .dbx-item:nth-child(14) {
            left: 0;
            top: 30px;
            width: 38px;
            height: 44px;
            background: url("~@image/bigScreen/ecological_environment/JianCeNeiRong/bg02.png")
                no-repeat;
        }
        .dbx-item:nth-child(15) {
            left: calc(50% - 17px);
            top: 10px;
            width: 38px;
            height: 44px;
            background: url("~@image/bigScreen/ecological_environment/JianCeNeiRong/bg02.png")
                no-repeat;
        }
        .dbx-item:nth-child(16) {
            left: calc(50% - 23px);
            bottom: 10px;
            width: 38px;
            height: 44px;
            background: url("~@image/bigScreen/ecological_environment/JianCeNeiRong/bg02.png")
                no-repeat;
        }
        .dbx-item:nth-child(17) {
            right: 15px;
            bottom: 0px;
            width: 38px;
            height: 44px;
            background: url("~@image/bigScreen/ecological_environment/JianCeNeiRong/bg02.png")
                no-repeat;
        }
        .dbx-item:nth-child(18) {
            left: 20px;
            bottom: 55px;
            width: 28px;
            height: 31px;
            background: url("~@image/bigScreen/ecological_environment/JianCeNeiRong/bg03.png")
                no-repeat;
        }
        .dbx-item:nth-child(19) {
            right: 0;
            top: 10px;
            width: 28px;
            height: 31px;
            background: url("~@image/bigScreen/ecological_environment/JianCeNeiRong/bg03.png")
                no-repeat;
        }
    }
}
</style>
