<template>
    <div class="tablePart">
        <div
            class="tableList"
            :style="`height:${maxHeight}px`"
            v-if="tableData.length"
        >
            <div class="tableHeader flex-between">
                <div
                    class="headerItem"
                    v-for="(item, index) in tableColums"
                    :key="index"
                    :style="{
                        textAlign: index > 0 ? 'left' : 'left',
                        width: item.width + 'px',
                    }"
                >
                    {{ item.label }}
                </div>
            </div>
            <div
                class="tableCtn scrollbar-hidden"
                v-infinite-scroll="handleScroll"
                :infinite-scroll-immediate="false"
            >
                <div
                    v-for="(item, index) in tableData"
                    :key="index"
                    class="ctnItem pointer flex-between"
                    :style="{
                        background: activeNum === index ? '#1a3059' : '',
                    }"
                    @click="handleTableRowClick(item, index)"
                >
                    <div
                        class="ellipsis"
                        :title="
                            item[itemInner.titleProp]
                                ? item[itemInner.titleProp]
                                : item[itemInner.prop]
                        "
                        v-for="(itemInner, indexInner) in tableColums"
                        :key="indexInner"
                        :style="{
                            textAlign: indexInner > 0 ? 'left' : 'left',
                            width: itemInner.width + 'px',
                            color: itemInner.colorArr
                                ? itemInner.colorArr[
                                      Number(item[itemInner.propColorNum])
                                  ]
                                : '#9AB4E3',
                        }"
                    >
                        {{ item[itemInner.prop] }}
                    </div>
                </div>
            </div>
        </div>
        <SnNoData
            :noDataState="true"
            :noDataText="noDataText"
            fontSize="20px"
            v-else
        ></SnNoData>
        <!-- 提示箭头 -->
        <!-- <img
            src="@image/bigScreen/icon/arrowBottom.png"
            class="tipArrow pointer"
            @click="handleScroll()"
            v-if="state"
            /> -->
    </div>
</template>
<script>
export default {
    name: "",
    data() {
        return {
            activeNum: null,
            currentRow: null,
        };
    },
    props: {
        tableColums: {
            type: Array,
            default: () => [
                // {
                //   propColorNum: "ZT",
                //   prop: "ZTMC",
                //   label: "现状评估",
                //   width: 80,
                //   colorArr: ["#00ff90", "#f6ff00", "#ffae00", "#ff2400"],
                // },
            ],
        },
        tableData: {
            type: Array,
            default: () => [],
        },
        maxHeight: {
            type: Number,
            default: () => {
                return 250;
            },
        },
        state: {
            type: Boolean,
            default: () => {
                return true;
            },
        },
        noDataText: {
            type: String,
            default: () => {
                return "暂无数据";
            },
        },
    },
    computed: {},
    watch: {
        tableData: {
            immediate: true,
            handler() {
                // console.log(
                //     "%c 🥥 this.tableData: ",
                //     "font-size:20px;background-color: #4b4b4b;color:#fff;",
                //     this.tableData
                // );
            },
        },
    },
    mounted() {},
    methods: {
        //滚动事件 加载数据
        handleScroll() {
            if (this.state) {
                this.$emit("loadData");
            }
        },
        handleTableRowClick(item, index) {
            if (this.activeNum === index) {
                this.activeNum = null;
                this.$emit("tableItemDetail", null, index);
            } else {
                this.activeNum = index;
                this.$emit("tableItemDetail", item, index);
            }
        },
        // 复位
        reSetState(index) {
            this.activeNum = index;
        },
    },
};
</script>

<style lang="scss" scoped>
.tablePart {
    width: 100%;
    height: 100%;
    position: relative;

    .tableList {
        width: 100%;
        position: relative;

        .tableHeader {
            width: 100%;
            height: 30px;
            padding: 0 15px;
            background: rgba(53, 80, 124, 0.3);

            .headerItem {
                height: 30px;
                line-height: 30px;
                font-size: 16px;
                color: rgba(189, 215, 236, 1);
            }
        }

        .tableCtn {
            width: 100%;
            height: calc(100% - 30px);
            position: relative;

            .ctnItem {
                padding: 0 15px;
                font-size: 14px;
                color: rgba(154, 180, 227, 1);
                height: 30px;
                line-height: 30px;
                .ellipsis:hover {
                    color: #d8eaf6;
                }

                &:nth-child(even) {
                    background: rgba(53, 80, 124, 0.2);
                }

                &:hover {
                    background: rgba(53, 80, 124, 0.1);
                }
            }
        }
    }

    .tipArrow {
        position: absolute;
        left: 50%;
        bottom: -20px;
        transform: translateX(-50%);
        z-index: 100;
        width: 20px;
        animation: tipArrow 2.4s ease-in-out 0s infinite;
    }

    @keyframes tipArrow {
        0% {
            bottom: -20px;
        }

        100% {
            bottom: -10px;
        }
    }
}
</style>
