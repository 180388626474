<template>
    <div class="screenItem">
        <!-- 项目列表 -->
        <basic-module :text="common.getFyName(fany.xmlb)">
            <div slot="basic-module" class="screenCtn">
                <div class="tableList flex-between">
                    <div
                        v-for="(item, index) in topList"
                        :key="index"
                        class="tableItem flex"
                    >
                        <div class="titlePart flex-both-center">
                            <div class="name">{{ item.name }}</div>
                        </div>
                        <div class="numPart flex-both-center">
                            <div
                                class="num"
                                :style="{
                                    color:
                                        index === 1 ? 'rgba(219,56,68,1)' : '',
                                }"
                            >
                                {{ item.num }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tableWrap">
                    <TableList
                        :maxHeight="312"
                        :tableColums="tableColums"
                        :tableData="tableData"
                        :state="state"
                        @loadData="loadData"
                        @tableItemDetail="tableItemDetail"
                    ></TableList>
                </div>
            </div>
        </basic-module>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import TableList from "@views/big_screen/comp/TableList";

export default {
    components: {
        TableList,
    },
    name: "",
    data() {
        return {
            topList: [
                {
                    name: "进行中",
                    num: 0,
                },
                {
                    name: "存在问题",
                    num: 0,
                },
            ],
            tableColums: [
                {
                    prop: "XMMC",
                    label: "项目名称",
                    width: 240,
                },
                {
                    prop: "KGRQ",
                    label: "开工日期",
                    width: 100,
                    align: "center",
                    headerAlign: "center",
                },
                {
                    prop: "ZDMJ",
                    label: "占地面积㎡",
                    width: 80,
                },
                {
                    // 0 未实施  2 已终止 有异常的红色
                    propColorNum: "ZT",
                    colorArr: [
                        "rgba(255,252,0,1)",
                        "rgba(65,218,236,1)",
                        "rgba(72,255,0,1)",
                    ],
                    prop: "SGZT",
                    label: "状态",
                    width: 80,
                    align: "center",
                    headerAlign: "center",
                },
            ],
            tableData: [],
            state: true, //true 还有未加载的数据 false 已经加载完毕
            pageIndex: 1,
            pageSize: 10,
            totalNum: 0,
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {},
    watch: {},
    mounted() {
        this.getTopData();
        this.getXmlbData();
    },
    methods: {
        ...mapActions(["GetNewProjectTotal", "GetNewProjectList"]),
        ...mapMutations({
            setStateJskz: "setStateJskz",
        }),
        // 获取 项目列表 数据
        async getTopData() {
            let result = await this.GetNewProjectTotal({});
            this.topList[0].num = result.SGZ;
            this.topList[1].num = result.CZWT;
        },
        // 获取 项目列表 数据
        async getXmlbData() {
            let result = await this.GetNewProjectList({
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            });
            this.totalNum = result.TOTAL;
            result.DATA.map((item) => {
                item.KGRQ = item.KGRQ.length ? item.KGRQ.slice(0, 10) : "";
                switch (item.SGZT) {
                    case "未实施":
                    case "未开工":
                        item.ZT = 0;
                        break;
                    case "施工中":
                        item.ZT = 1;
                        break;
                    case "已完成":
                        item.ZT = 2;
                        break;
                }
            });
            this.tableData = [...this.tableData, ...result.DATA];
            if (this.tableData.length < this.totalNum) {
                this.state = true;
            } else {
                this.state = false;
            }
        },
        loadData() {
            if (this.state) {
                if (this.tableData.length < this.totalNum) {
                    this.pageIndex++;
                    this.getXmlbData();
                } else {
                    this.state = false;
                }
            }
        },
        tableItemDetail(item, index) {
            this.setStateJskz({
                jskzPanelNum: 1,
                jskzProjectItem: item,
            });
        },
    },
};
</script>
<style scoped lang="scss">
.screenItem {
    width: 100%;
    height: 100%;
    .screenCtn {
        width: 100%;
        height: 100%;
        .tableList {
            width: 100%;
            height: 40px;
            .tableItem {
                width: calc(50% - 5px);
                height: 100%;

                .titlePart {
                    width: calc(50% - 5px);
                    height: 100%;
                    background: url("~@image/bigScreen/icon/titleBg.png")
                        no-repeat;
                    background-size: 100% 100%;
                    .name {
                        font-size: 18px;
                        color: #a8c8ff;
                    }
                }
                .numPart {
                    width: calc(50% - 5px);
                    height: 100%;
                    border: 1px solid #35507c;
                    margin-left: 5px;
                    .num {
                        font-size: 34px;
                        color: #ffcd80;
                        font-family: "DBPM";
                    }
                }
            }
        }
        .tableWrap {
            margin-top: 10px;
            width: 100%;
            height: calc(100% - 50px);
        }
    }
}
</style>
