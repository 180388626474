<template>
    <div class="bczkBox">
        <div class="chartPart">
            <div class="chart" :id="chartData.chartID"></div>
            <div class="num">{{chartData.value}}</div>
        </div>
        <div class="descPart">{{chartData.name}}</div>
    </div>
</template>
<script>
export default {
    name: "bczkBox",
    props: {
        chartData: Object,
        allNum: Number
    },
    data() {
        return {
            chart: null
        }
    },
    watch: {
        chartData: {
            deep: true,
            immediate: true,
            handler() {
                this.$nextTick(() => {
                    this.initChart();
                });
            }
        }
    },
    methods: {
        initChart() {
            let option = {
                polar: {
                    radius: ['90%', '100%'],
                    center: ['50%', '50%'],
                },
                angleAxis: {
                    max: this.allNum,
                    show: false,
                },
                radiusAxis: {
                    type: 'category',
                    show: true,
                    axisLabel: {
                        show: false,
                    },
                    axisLine: {
                        show: false,

                    },
                    axisTick: {
                        show: false
                    },
                },
                series: [{
                        name: '',
                        type: 'bar',
                        roundCap: true,
                        barWidth: 5,
                        showBackground: true,
                        backgroundStyle: {
                            color: '#2b3d59',
                        },
                        data: [this.chartData.value],
                        coordinateSystem: 'polar',
                        itemStyle: {
                            normal: {
                                color: '#AAC6FC'
                            }
                        }
                    },
                ]
            };
            if (this.chart) {
                this.chart = this.$echarts.dispose(document.getElementById(this.chartData.chartID));
            }
            this.chart = this.$echarts.init(document.getElementById(this.chartData.chartID));
            this.chart.setOption(option);
        }
    }
}
</script>
<style scoped lang="scss">
$stateLineColor: #35507C;
.bczkBox {
    height: 100%;
    .chartPart {
        width: 92px;
        height: 92px;
        position: relative;
        margin: 0 auto;
        &::before,
        &::after {
            content: "";
            display: block;
            width: 15px;
            height: 2px;
            background-color: #2b3d59;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        &::before {
            left: -15px;
        }
        &::after {
            right: -15px;
        }
        .chart {
            width: 92px;
            height: 92px;
            position: absolute;
            top: 0;
            left: 0;
        }
        .num {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 75px;
            height: 75px;
            line-height: 75px;
            text-align: center;
            border-radius: 50%;
            background: linear-gradient(180deg,rgba(14,30,53,1) 0%, rgba(57,76,106,1) 100%);
            box-shadow: 0px -1px 3px 0px #43597a inset;
            font-size: 45px;
            font-family: DBPM;
            color: #AAC6FC;
        }
    }
    .descPart {
        height: 20px;
        line-height: 20px;
        text-align: center;
        color: #AAC6FC;
    }
}
</style>