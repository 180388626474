<template>
    <div class="rightPart protect-project-right-1">
        <BoxTitle
            :title="bhgcItem ? bhgcItem.GCMC : '暂无工程名称'"
            @handleClose="handleClose()"
        />
        <div class="jbxxAndGzxx scrollbar-hidden">
            <BasicInfo class="animated bounceInLeft" />
            <FollowInfo class="follow-info animated bounceInRight" />
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import BoxTitle from "@views/big_screen/comp/BoxTitle.vue"; //标题
import BasicInfo from "./comp/basic_info";
import FollowInfo from "./comp/follow_info";
export default {
    name: "",
    data() {
        return {};
    },
    components: {
        BoxTitle,
        BasicInfo,
        FollowInfo,
    },
    computed: {
        ...mapGetters(["bhgcItem"]),
    },
    methods: {
        ...mapActions([""]),
        ...mapMutations({
            setStateBhgc: "setStateBhgc",
        }),
        handleClose() {
            this.setStateBhgc({
                bhgcJcwzItem: "",
                bhgcYcysItem: null,
                bhgcItem: null, //保护工程 某一个
                bhgcPanelNum: 0,
                bhgcJcdwItem: null, // 监测点位 某一个
                bhgcActiveNum: null, // 保护工程 某一个
            });
        },
    },
};
</script>
<style scoped lang="scss">
.rightPart {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 15px 0;
    .jbxxAndGzxx {
        width: 100%;
        height: calc(100% - 36px);
    }
}
</style>
