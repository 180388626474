<template>
    <div class="big_screen screenMain"
         :class="{ scrollbar: screen1920not }">
        <!-- 顶部 -->
        <OverviewHeader class="screenHeader"></OverviewHeader>
        <div class="screenCtnBase flex">
            <!-- 左侧 -->
            <div class="screenLeft">
                <LeftMenu @leftActiveItem="leftActiveItem"
                          @rightActiveItem="rightActiveItem"
                          @allActiveItem="allActiveItem"></LeftMenu>
            </div>
            <!-- 监测总览 -->
            <div class="screenRightJczl flex-1"
                 v-if="leftActiveNum === 0">
                <MonitorAll></MonitorAll>
            </div>
            <!-- 非监测总览 -->
            <div class="screenRight flex-1 flex-between"
                 v-else>
                <div class="routerItem">
                    <div class="direction">L</div>
                    <div class="decorateTop flex-between">
                        <div v-for="i in 4"
                             :key="i"
                             class="decorateItem"></div>
                    </div>
                    <div class="decorateBottom flex-between">
                        <div v-for="i in 4"
                             :key="i"
                             class="decorateItem"></div>
                    </div>
                    <RightCtn :activeNum="leftActiveNum"
                              positionStr="left"></RightCtn>
                </div>
                <div class="routerItem">
                    <div class="direction">R</div>
                    <RightCtn :activeNum="rightActiveNum"
                              positionStr="right"></RightCtn>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// 消息推送;
import "@libs/mySignalr.js";
import OverviewHeader from "./comp/OverviewHeader";
import LeftMenu from "./LeftMenu";
import RightCtn from "./RightCtn";
import MonitorAll from "./monitor_all";

export default {
    name: "big_screen_new",

    components: {
        OverviewHeader,
        LeftMenu,
        RightCtn,
        MonitorAll,
    },
    data () {
        return {
            screen1920not: false,
            leftActiveNum: null,
            rightActiveNum: null,
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler () {
                this.route = this.$route;
            },
        },
    },
    mounted () {
        document.title = "普洱景迈山古茶林遗产监测预警平台";
        this.reset();
        $(window).resize(() => {
            this.reset();
        });
    },
    methods: {
        //1920 全屏 非全屏 判断
        reset () {
            let obj = this.common.screenReset();
            this.screen1920not = obj.height1920not;
        },
        leftActiveItem (index) {
            this.leftActiveNum = index;
        },
        rightActiveItem (index) {
            this.rightActiveNum = index;
        },
        allActiveItem (index) {
            this.leftActiveNum = index;
            this.rightActiveNum = index;
        },
    },
};
</script>

<style lang="scss" scoped>
/* 主体 */
.screenMain {
    width: 5760px;
    height: 100%;
    transform-origin: 0 0;
    background: url('~@image/bigScreen/icon/bg.png');
    background-size: 100% 100%;
    padding-bottom: 55px;
    position: relative;
    .screenHeader {
        width: 100%;
        height: 55px;
    }
    .screenCtnBase {
        width: calc(100% - 50px);
        height: calc(100% - 55px);
        padding-left: 50px;
        position: relative;
        .screenLeft {
            width: 657px;
            height: 100%;
            background-image: linear-gradient(
                to left,
                rgba(26, 56, 89, 0.3),
                rgba(26, 56, 89, 0)
            );
        }
        .screenRight {
            height: 100%;
            .routerItem {
                min-width: 2510px;
                height: 100%;
                position: relative;
                // border: 1px solid #fff;
                &:nth-child(1) {
                    margin-right: 20px;
                }
                .direction {
                    position: absolute;
                    left: 40px;
                    top: 16px;
                    background: rgba(255, 205, 128, 1);
                    color: rgba(4, 14, 30, 1);
                    width: 22px;
                    height: 22px;
                    line-height: 22px;
                    font-size: 16px;
                    text-align: center;
                }
                .decorateTop {
                    position: absolute;
                    left: 742px;
                    top: 50px;
                    z-index: 10;
                    .decorateItem {
                        width: 8px;
                        height: 8px;
                        border: 2px solid rgba(255, 205, 128, 1);
                        border-radius: 50%;
                        margin-right: 200px;
                        &:nth-last-child(1) {
                            margin-right: 0;
                        }
                    }
                }
                .decorateBottom {
                    position: absolute;
                    left: 1165px;
                    bottom: -4px;
                    z-index: 10;
                    .decorateItem {
                        width: 8px;
                        height: 8px;
                        border: 2px solid rgba(255, 205, 128, 1);
                        border-radius: 50%;
                        margin-right: 200px;
                        &:nth-last-child(1) {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        .screenRightJczl {
            // width: calc(100% - 480px);
            height: 100%;
            // position: absolute;
            // right: 0;
            // top: 0;
            z-index: 1;
        }
    }
}
</style>
