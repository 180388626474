import DialogSxt from "./comp/DialogSxtIframe.vue"; //摄像头 iframe 公司 live-player 厂家提供的插件 第三方的监控 

import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    components: {
        DialogSxt
    },
    props: {

    },
    computed: {
        ...mapGetters({
        }),
    },
    watch: {

    },
    data() {
        return {

        };
    },
    created() {
    },
    mounted() {
    },
    methods: {

    }

};