<template>
    <div class="screenCenter">
        <!-- 地图 -->
        <div class="mapPart">
            <MyMap ref="myMap" :myMap.sync="myMap" :mapData="mapData"></MyMap>
        </div>
        <!-- 图层 -->
        <div class="mapChangePart">
            <div
                class="layer pointer flex-between-y-center"
                @click="layerState = !layerState"
            >
                <div class="desc">图层</div>
                <img src="@image/bigScreen/icon/layer.png" />
            </div>
            <div v-show="layerState" class="layerCtn">
                <LayerList
                    :listAry="onOffList"
                    keyValue="sthj"
                    @switchStateChange="switchStateChange"
                ></LayerList>

                <LayerList
                    :listAry="customLayerList"
                    keyValue="sthj"
                    @switchStateChange="switchStateCustomChange"
                ></LayerList>

                <!-- <LayerList :listAry="testLayerList"
                           keyValue="test"
                           @switchStateChange="switchStateTestChange"></LayerList> -->
            </div>
        </div>
        <!-- 图例 -->
        <div class="legendPart">
            <LegendList
                :listAry="onOffList"
                keyValue="ecological_environment"
                screenName="ecological_environment"
            ></LegendList>
        </div>
        <!-- marker 弹出框-->
        <div v-show="commonDialog.dialog" class="commonDialogPart">
            <div class="select-part" v-if="commonDialog.clyfDialog">
                <SelectTimeTab
                    :selectData="selectDataTime"
                    @getSelectItem="getSelectItemTime"
                >
                </SelectTimeTab>
            </div>
            <div class="popupTitle flex-between">
                <div style="flex: 1; border-bottom: 1px #84acc2 solid"></div>
                <div class="titleCenter" :title="commonDialog.title">
                    <img src="@image/bigScreen/titleBgleft.png" />
                    <span class="titleContent">{{ commonDialog.title }}</span>
                    <img src="@image/bigScreen/titleBgright.png" />
                </div>
                <div style="flex: 1; border-bottom: 1px #84acc2 solid"></div>
                <i
                    class="el-icon-close titleIcon pointer"
                    @click="closeComDialog()"
                ></i>
            </div>
            <div class="popupCtn">
                <DialogZwdyx
                    style="width: 660px"
                    :detail="commonDialog.detail"
                    v-if="commonDialog.zwdyxDialog"
                ></DialogZwdyx>
                <DialogDwdyx
                    style="width: 660px"
                    :detail="commonDialog.detail"
                    v-if="commonDialog.dwdyxDialog"
                ></DialogDwdyx>
                <DialogTeaQuadrat
                    style="width: 660px"
                    :detail="commonDialog.detail"
                    ref="DialogTeaQuadrat"
                    v-if="commonDialog.clyfDialog"
                    @changeCarouseTime="changeCarouseTime"
                ></DialogTeaQuadrat>
                <DialogGclbch
                    style="width: 660px"
                    :detail="commonDialog.detail"
                    v-if="commonDialog.gclbchDialog"
                ></DialogGclbch>

                <DialogGsmm
                    style="width: 660px"
                    :detail="commonDialog.detail"
                    v-if="commonDialog.gsmmDialog"
                ></DialogGsmm>
            </div>
        </div>
        <!-- pdf阅读 -->
        <div class="pdfPart" v-if="pdf.dialog">
            <PdfRead :pdf="pdf" @closePdf="closePdf"></PdfRead>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import MyMap from "@views/big_screen/comp/map/BaseMap.vue";
import OnOff from "@views/big_screen/comp/OnOff.vue"; //开关
import DialogZwdyx from "./comp/DialogZwdyx.vue"; //植物多样性
import DialogTeaQuadrat from "./comp/DialogTeaQuadrat.vue"; //茶林样方
import DialogDwdyx from "./comp/DialogDwdyx.vue"; //动物多样性
import DialogGclbch from "./comp/DialogGclbch.vue"; //古茶林病虫害
import DialogGsmm from "./comp/DialogGsmm.vue"; //古树名木
import PdfRead from "@views/big_screen/comp/PdfRead.vue";
import LegendList from "@views/big_screen/comp/LegendList.vue"; //图例
import LayerList from "@views/big_screen/comp/LayerList.vue"; //图层
import SelectTimeTab from "@views/big_screen/comp/SelectTimeTab";
export default {
    components: {
        MyMap,
        OnOff,
        DialogZwdyx,
        DialogDwdyx,
        DialogTeaQuadrat,
        DialogGclbch,
        DialogGsmm,
        PdfRead,
        LegendList,
        LayerList,
        SelectTimeTab,
    },
    name: "",
    data() {
        return {
            pdf: {
                dialog: false,
                title: "",
                src: "",
                page: 1,
            },
            myMap: null,
            mapData: {
                baseMapNum: 0,
                mapTypeBtn: true,
                showTool: true,
            },
            layerState: false,
            onOffList: [
                {
                    name: "土壤墒情监测站",
                    state: false,
                    list: [],
                    group: null,
                    bgColor: "#ff9966",
                    radius: "50%",
                },
                {
                    name: "水质五参数监测器",
                    state: false,
                    list: [],
                    group: null,
                    bgColor: "#ccff99",
                    radius: "50%",
                },
                {
                    name: "动物多样性监测器",
                    state: false,
                    list: [],
                    group: null,
                    bgColor: "#33cccc",
                    radius: "50%",
                },
                {
                    name: "物联网自动虫情信息采集设备",
                    state: false,
                    list: [],
                    group: null,
                    bgColor: "#9933ff",
                    radius: "50%",
                },
                {
                    name: "生态监测球机",
                    state: false,
                    list: [],
                    group: null,
                    bgColor: "#ff0033",
                    radius: "50%",
                },
                {
                    name: "一体式气象站",
                    state: false,
                    list: [],
                    group: null,
                    bgColor: "#660000",
                    radius: "50%",
                },
                {
                    name: "古树名木点位",
                    state: false,
                    list: [],
                    group: null,
                    bgColor: "#00ff00",
                    radius: "50%",
                },
                {
                    name: "植物多样性",
                    state: false,
                    group: null,
                    bgColor: "#0033cc",
                    radius: "50%",
                    list: [],
                },
                {
                    name: "动物多样性",
                    state: false,
                    list: [],
                    group: null,
                    bgColor: "#ffff00",
                    radius: "50%",
                },
                {
                    name: "古茶林病虫害调查",
                    state: false,
                    list: [],
                    group: null,
                    bgColor: "#ffcc66",
                    mulList: [
                        {
                            name: "古茶林病虫害重度危害",
                            bgColor: "#ff4c4c",
                            radius: "50%",
                        },
                        {
                            name: "古茶林病虫害中度危害",
                            bgColor: "#ffb508",
                            radius: "50%",
                        },
                        {
                            name: "古茶林病虫害轻度危害",
                            bgColor: "#ffff00",
                            radius: "50%",
                        },
                        {
                            name: "古茶林病虫害正常",
                            bgColor: "#00bd8d",
                            radius: "50%",
                        },
                    ],
                },
                {
                    name: "微环境空气质量",
                    state: false,
                    list: [],
                    group: null,
                    bgColor: "#3399cc",
                    radius: "50%",
                },
            ],
            customLayerList: [
                {
                    name: "病虫害遥感影像",
                    state: false,
                    group: null,
                },
                {
                    name: "病害图斑分布图",
                    state: false,
                    group: null,
                },
            ],
            testLayerList: [
                {
                    name: "何垚",
                    state: false,
                    group: null,
                    list: [],
                    group: null,
                    bgColor: "#009999",
                    radius: "50%",
                },
                {
                    name: "周家仪",
                    state: false,
                    group: null,
                    list: [],
                    group: null,
                    bgColor: "#dd283a",
                    radius: "50%",
                },
                {
                    name: "李其坤",
                    state: false,
                    group: null,
                    list: [],
                    group: null,
                    bgColor: "#e08c18",
                    radius: "50%",
                },
                {
                    name: "杨虹",
                    state: false,
                    group: null,
                    list: [],
                    group: null,
                    bgColor: "#01c4fc",
                    radius: "50%",
                },
            ],
            markerPopup: null,
            // marker弹出框
            commonDialog: {
                title: "",
                detail: {},
                dialog: false,
                zwdyxDialog: false, //植物多样性
                clyfDialog: false, //茶林样方
                dwdyxDialog: false, //动物多样性
                gclbchDialog: false, //古茶林病虫害
                gsmmDialog: false, //古树名木
            },
            selectDataTime: {
                clearable: false,
                state: true,
                width: "100px",
                defaultValue: "",
                list: [],
                label: "time",
                value: "src",
            },
            dataState: false,
        };
    },
    props: {
        positionStr: {
            type: String,
            default: "",
        },
    },
    computed: {
        ...mapGetters({
            sthjJcnrSelect: TYPES.sthjJcnrSelect,
            sthjJlfxxqSelect: TYPES.sthjJlfxxqSelect,
            sthjGsmmTypeName: TYPES.sthjGsmmTypeName,
            sthjDeviceItem: TYPES.sthjDeviceItem,
        }),
    },
    watch: {
        sthjDeviceItem: {
            immediate: false,
            handler() {
                if (this.sthjDeviceItem) {
                    this.myMap.flyTo(
                        [this.sthjDeviceItem.wd, this.sthjDeviceItem.jd],
                        this.sthjDeviceItem.level
                    );
                }
            },
        },
        // 打开pdf 文件
        sthjJlfxxqSelect: {
            immediate: false,
            handler() {
                this.openPdfDialog();
            },
        },
        // 监测内容
        sthjJcnrSelect: {
            immediate: false,
            deep: true,
            handler() {
                switch (this.sthjJcnrSelect.id) {
                    case "7": //古树名木监测
                    case "3": //森林病虫害遥感监测
                        this.myMap.flyTo(
                            [22.188994487142455, 100.00339508056642],
                            13.1
                        );
                        break;
                    case "1": //病虫害虫情测报
                    case "0": //微环境气象监测
                    case "4": //微环境空气质量监测
                    case "5": //微环境土壤监测
                        this.myMap.flyTo(
                            [22.179007257319875, 100.00500746324239],
                            14.3
                        );
                        break;
                    case "6": //微环境水质监测
                        this.myMap.flyTo(
                            [22.20556353647718, 100.0222058043648],
                            13.8
                        );
                        break;
                    case "2": //古茶林病虫害调查
                        this.myMap.flyTo(
                            [22.20420919655148, 100.02130091734783],
                            16
                        );
                        break;
                    case "8": //植物多样性监测
                        this.myMap.flyTo(
                            [22.182159527853653, 100.0154972076416],
                            14
                        );
                        break;
                    case "10": //动物多样性监测
                        this.myMap.flyTo(
                            [22.19217483527974, 100.0183483118763],
                            14.6
                        );
                        break;
                    default:
                        this.myMap.flyTo(
                            [22.188994487142455, 100.00339508056642],
                            14.3
                        );
                        break;
                }
                if (!this.dataState) {
                    this.getMapPoint(); // 获取设备点位信息
                } else {
                    this.changeJcnr(this.sthjJcnrSelect.id);
                }
            },
        },
        positionStr: {
            immediate: true,
            handler() {
                if (this.positionStr == "left") {
                    this.mapData.baseMapNum = 0;
                } else if (this.positionStr == "right") {
                    this.mapData.baseMapNum = 1;
                }
            },
        },
        // 古树名木类型名称
        sthjGsmmTypeName: {
            immediate: false,
            handler() {
                if (this.sthjGsmmTypeName) {
                    let obj = {
                        group: null,
                        list: [],
                        bgColor: "",
                    };
                    this.onOffList.map((item) => {
                        if (item.name == "古树名木点位") {
                            obj.group = item.group;
                            obj.bgColor = item.bgColor;
                            item.list.map((itemInner) => {
                                if (itemInner.SZKM == this.sthjGsmmTypeName) {
                                    obj.list.push(itemInner);
                                }
                            });
                            this.drawMarkerGsmm(obj);
                        }
                    });
                } else {
                    this.onOffList.map((item) => {
                        if (item.name == "古树名木点位") {
                            this.drawMarkerGsmm(item);
                        }
                    });
                }
            },
        },
    },
    created() {},
    mounted() {
        this.onOffList.map((item) => {
            item.group = window.L.layerGroup().addTo(this.myMap);
        });
        this.customLayerList.map((item) => {
            item.group = window.L.layerGroup().addTo(this.myMap);
        });
        // this.testLayerList.map((item) => {
        //     item.group = window.L.layerGroup().addTo(this.myMap);
        // });
        this.getGsmmTestData();

        //marker 弹出框
        this.markerPopup = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, 0],
            className: "markerPopup",
        });
    },
    methods: {
        ...mapActions([
            "getVectorStyle", // 获取底图背景
            "GetMapPoint", // 获取设备点位信息
            "GetGsmmNewLayer",
        ]),
        ...mapMutations({
            SET_STHJJLFXXQSELECT: TYPES.SET_STHJJLFXXQSELECT,
        }),
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        // 监测内容修改
        changeJcnr(id) {
            this.onOffList.forEach((item) => {
                item.state = false;
                this.clearLayersGroup(item.group);
            });
            this.customLayerList.forEach((item) => {
                item.state = false;
                this.clearLayersGroup(item.group);
            });
            switch (id) {
                case "0": // 微环境 气象监测
                    this.onOffList.map((item) => {
                        if (item.name == "一体式气象站") {
                            item.state = true;
                            this.drawMarkerQxz(item);
                        }
                    });
                    break;
                case "1": // 病虫害 虫情测报
                    this.onOffList.map((item) => {
                        if (item.name == "物联网自动虫情信息采集设备") {
                            item.state = true;
                            this.drawMarkerCqxx(item);
                        }
                    });
                    break;
                case "2": // 古茶林 病虫害调查
                    this.onOffList.map((item) => {
                        if (item.name == "古茶林病虫害调查") {
                            item.state = true;
                            this.drawGclbchdc(item);
                        }
                    });
                    break;
                case "4": // 微环境 空气质量 监测
                    this.onOffList.map((item) => {
                        if (item.name == "微环境空气质量") {
                            item.state = true;
                            this.drawWhjkqzl(item);
                        }
                    });
                    break;
                case "5": // 微环境 土壤监测
                    this.onOffList.map((item) => {
                        if (item.name == "土壤墒情监测站") {
                            item.state = true;
                            this.drawMarkerTrsq(item);
                        }
                    });
                    break;
                case "6": // 微环境 水文水质 监测
                    this.onOffList.map((item) => {
                        if (item.name == "水质五参数监测器") {
                            item.state = true;
                            this.drawMarkerSzjc(item);
                        }
                    });
                    break;
                case "7": // 古树名木 监测
                    // this.onOffList[6].state = true;
                    // if (
                    //     this.gsmmGroup &&
                    //     this.gsmmList &&
                    //     this.gsmmList.length > 0
                    // ) {
                    //     this.drawMarkerGsmm(this.gsmmGroup, this.gsmmList);
                    // }
                    this.onOffList.map((item) => {
                        if (item.name == "古树名木点位") {
                            item.state = true;
                            this.drawMarkerGsmm(item);
                        }
                    });
                    break;
                case "8":
                    this.onOffList.map((item) => {
                        if (item.name == "植物多样性") {
                            item.state = true;
                            this.clearLayersGroup(item.group);
                            this.drawZwdyx(item);
                            // this.drawTeaQuadrat(item);
                        }
                    });
                    break;
                case "9": // 物候监测
                    this.onOffList.map((item) => {
                        if (item.name == "生态监测球机") {
                            item.state = true;
                            this.drawMarkerStjc(item);
                        }
                    });
                    break;
                case "10": // 动物多样性监测器
                    this.onOffList.map((item) => {
                        if (item.name == "动物多样性监测器") {
                            item.state = true;
                            this.drawMarkerDwjc(item);
                        }
                        if (item.name == "动物多样性") {
                            item.state = true;
                            this.drawDwdyx(item);
                        }
                    });
                    break;
                case "3": // 森林病虫害 遥感监测
                    this.customLayerList.map((item) => {
                        if (item.name == "病害图斑分布图") {
                            item.state = true;
                            this.getBchmjStyle(item.group);
                        }
                    });
                    break;
            }
        },
        // 获取古树名木测试数据
        async getGsmmTestData() {
            let result = await this.GetGsmmNewLayer({});
            // this.testLayerList.map((item) => {
            //     result.map((item1) => {
            //         if (item.name == item1.LX) {
            //             item.list = item1.SB
            //         }
            //     })
            // });
        },
        // 获取设备点位信息
        async getMapPoint() {
            this.dataState = true;
            const result = await this.GetMapPoint({
                sblx: "",
            });
            if (result && result.length > 0) {
                result.forEach((item) => {
                    switch (item.LX) {
                        case "0778fe4f-cf12-444c-b66f-6cf84acb0df5": // 土壤墒情监测站
                            this.onOffList.map((itemInner) => {
                                if (itemInner.name == "土壤墒情监测站") {
                                    itemInner.list = item.SB;
                                    // this.drawMarkerTrsq(itemInner);
                                }
                            });
                            break;
                        case "1db446ea-793b-4bef-b885-0f893d4608c3":
                            this.onOffList.map((itemInner) => {
                                if (itemInner.name == "水质五参数监测器") {
                                    itemInner.list = item.SB;
                                    // this.drawMarkerSzjc(itemInner);
                                }
                            });
                            break;
                        case "351ee3bb-9c3c-473b-a9e4-2a6218749a97":
                            this.onOffList.map((itemInner) => {
                                if (itemInner.name == "动物多样性监测器") {
                                    itemInner.list = item.SB;
                                    // this.drawMarkerDwjc(itemInner);
                                }
                            });
                            break;
                        case "7cb5eda5-bc0f-48c3-8dce-df3cb3489fac":
                            this.onOffList.map((itemInner) => {
                                if (
                                    itemInner.name ==
                                    "物联网自动虫情信息采集设备"
                                ) {
                                    itemInner.list = item.SB;
                                    // this.drawMarkerCqxx(itemInner);
                                }
                            });
                            break;
                        case "956a05bd-b31b-41d7-b436-b03505369645":
                            this.onOffList.map((itemInner) => {
                                if (itemInner.name == "生态监测球机") {
                                    itemInner.list = item.SB;
                                    // this.drawMarkerStjc(itemInner);
                                }
                            });
                            break;
                        case "c815ce51-bf70-4297-8e80-de4fbc32d131":
                            this.onOffList.map((itemInner) => {
                                if (itemInner.name == "一体式气象站") {
                                    itemInner.list = item.SB;
                                    // this.drawMarkerQxz(itemInner);
                                }
                            });
                            break;
                        case "gsmm":
                            this.onOffList.map((itemInner) => {
                                if (itemInner.name == "古树名木点位") {
                                    itemInner.list = item.SB;
                                    // this.drawMarkerGsmm(itemInner);
                                }
                            });
                            break;
                        case "dwdyx":
                            this.onOffList.map((itemInner) => {
                                if (itemInner.name == "动物多样性") {
                                    itemInner.list = item.SB;
                                    // this.drawDwdyx(itemInner);
                                }
                            });
                            break;
                        case "zwdyx":
                            this.onOffList.map((itemInner) => {
                                if (itemInner.name == "植物多样性") {
                                    itemInner.list = item.SB;
                                    // this.drawZwdyx(itemInner);
                                }
                            });
                            break;
                        case "gcych":
                            this.onOffList.map((itemInner) => {
                                if (itemInner.name == "古茶林病虫害调查") {
                                    itemInner.list = item.SB;
                                    // this.drawGclbchdc(itemInner);
                                }
                            });
                            break;
                        case "50002592-c52a-4286-b6cd-8fb4065bf222":
                            this.onOffList.map((itemInner) => {
                                if (itemInner.name == "微环境空气质量") {
                                    itemInner.list = item.SB;
                                    // this.drawWhjkqzl(itemInner);
                                }
                            });
                            break;
                    }
                });
            }
            this.changeJcnr(this.sthjJcnrSelect.id);
        },
        // 土壤墒情监测站
        drawMarkerTrsq(obj) {
            let group = obj.group;
            let list = obj.list;
            let bgColor = obj.bgColor;
            this.clearLayersGroup(group);
            if (!list || list.length === 0) return;
            list.map((item) => {
                if (!item.JD || !item.WD) return;
                let lat = item.WD;
                let lng = item.JD;

                let divIcon = window.L.divIcon({
                    className: "my-div-icon",
                    html: `<div style="width:10px;height:10px;border-radius:50%;background:${bgColor}; box-shadow: 0 0 3px #808080"></div>`,
                });
                let layer = window.L.marker([lat, lng], {
                    icon: divIcon,
                }).addTo(group);

                layer.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([lat, lng])
                        .setContent(`<div class="tip">${item.SBMC}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
            });
        },
        // 水质五参数监测器
        drawMarkerSzjc(obj) {
            let group = obj.group;
            let list = obj.list;
            let bgColor = obj.bgColor;
            if (!list || list.length === 0) return;
            this.clearLayersGroup(group);

            list.map((item) => {
                if (!item.JD || !item.WD) return;
                let lat = item.WD;
                let lng = item.JD;

                let divIcon = window.L.divIcon({
                    className: "my-div-icon",
                    html: `<div style="width:10px;height:10px;border-radius:50%;background:${bgColor}; box-shadow: 0 0 3px #808080"></div>`,
                });
                let layer = window.L.marker([lat, lng], {
                    icon: divIcon,
                }).addTo(group);

                layer.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([lat, lng])
                        .setContent(`<div class="tip">${item.SBMC}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
            });
        },
        // 动物多样性监测器
        drawMarkerDwjc(obj) {
            let group = obj.group;
            let list = obj.list;
            let bgColor = obj.bgColor;
            if (!list || list.length === 0) return;
            this.clearLayersGroup(group);

            list.map((item) => {
                if (!item.JD || !item.WD) return;
                let lat = item.WD;
                let lng = item.JD;

                let divIcon = window.L.divIcon({
                    className: "my-div-icon",
                    html: `<div style="width:10px;height:10px;border-radius:50%;background:${bgColor}; box-shadow: 0 0 3px #808080"></div>`,
                });
                let layer = window.L.marker([lat, lng], {
                    icon: divIcon,
                }).addTo(group);

                layer.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([lat, lng])
                        .setContent(`<div class="tip">${item.SBMC}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
            });
        },
        // 物联网自动虫情信息采集设备
        drawMarkerCqxx(obj) {
            let group = obj.group;
            let list = obj.list;
            let bgColor = obj.bgColor;
            if (!list || list.length === 0) return;
            this.clearLayersGroup(group);

            list.map((item) => {
                if (!item.JD || !item.WD) return;
                let lat = item.WD;
                let lng = item.JD;

                let divIcon = window.L.divIcon({
                    className: "my-div-icon",
                    html: `<div style="width:10px;height:10px;border-radius:50%;background:${bgColor}; box-shadow: 0 0 3px #808080"></div>`,
                });
                let layer = window.L.marker([lat, lng], {
                    icon: divIcon,
                }).addTo(group);

                layer.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([lat, lng])
                        .setContent(`<div class="tip">${item.SBMC}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
            });
        },
        // 生态监测球机
        drawMarkerStjc(obj) {
            let group = obj.group;
            let list = obj.list;
            let bgColor = obj.bgColor;
            if (!list || list.length === 0) return;
            this.clearLayersGroup(group);

            list.map((item) => {
                if (!item.JD || !item.WD) return;
                let lat = item.WD;
                let lng = item.JD;

                let divIcon = window.L.divIcon({
                    className: "my-div-icon",
                    html: `<div style="width:10px;height:10px;border-radius:50%;background:${bgColor}; box-shadow: 0 0 3px #808080"></div>`,
                });
                let layer = window.L.marker([lat, lng], {
                    icon: divIcon,
                }).addTo(group);

                layer.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([lat, lng])
                        .setContent(`<div class="tip">${item.SBMC}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
            });
        },
        // 一体式气象站
        drawMarkerQxz(obj) {
            let group = obj.group;
            let list = obj.list;
            let bgColor = obj.bgColor;
            if (!list || list.length === 0) return;
            this.clearLayersGroup(group);

            list.map((item) => {
                if (!item.JD || !item.WD) return;
                let lat = item.WD;
                let lng = item.JD;

                let divIcon = window.L.divIcon({
                    className: "my-div-icon",
                    html: `<div style="width:10px;height:10px;border-radius:50%;background:${bgColor}; box-shadow: 0 0 3px #808080"></div>`,
                });
                let layer = window.L.marker([lat, lng], {
                    icon: divIcon,
                }).addTo(group);

                layer.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([lat, lng])
                        .setContent(`<div class="tip">${item.SBMC}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
            });
        },
        // 古树名木点位
        drawMarkerGsmm(obj) {
            let group = obj.group;
            let list = obj.list;
            let bgColor = obj.bgColor;
            this.clearLayersGroup(group);
            if (!list || list.length === 0) return;
            list.map((item) => {
                if (!item.JD || !item.WD) return;
                let lat = item.WD;
                let lng = item.JD;

                let divIcon = window.L.divIcon({
                    className: "my-div-icon",
                    html: `<div style="width:10px;height:10px;border-radius:50%;background:${bgColor}; box-shadow: 0 0 3px #808080"></div>`,
                });
                let layer = window.L.marker([lat, lng], {
                    icon: divIcon,
                }).addTo(group);
                layer.on("click", () => {
                    this.closeComDialog();
                    this.commonDialog.dialog = true;
                    this.commonDialog.gsmmDialog = true;
                    this.commonDialog.title = `${item.SBMC}`;
                    this.commonDialog.detail = item;
                });
                layer.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([lat, lng])
                        .setContent(`<div class="tip">${item.SBMC}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
            });
        },
        // 植物多样性
        drawZwdyx(obj) {
            let group = obj.group;
            let list = obj.list;
            let bgColor = obj.bgColor;
            let icon = window.L.divIcon({
                html: `<div style="width:10px;height:10px;border-radius:50%;background:${bgColor}; "  /></div>`,
                iconSize: [10, 10],
                iconAnchor: [5, 5],
            });
            list.map((item, index) => {
                let marker = window.L.marker([item.WD, item.JD], {
                    icon: icon,
                    data: item,
                }).addTo(group);
                marker.on("click", () => {
                    this.closeComDialog();
                    this.commonDialog.dialog = true;
                    this.commonDialog.zwdyxDialog = true;
                    this.commonDialog.title = `${item.SBMC}`;
                    this.commonDialog.detail = item;
                });
                marker.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([item.WD, item.JD])
                        .setContent(`<div class="tip">${item.SBMC}</div>`)
                        .openOn(this.myMap);
                });
                marker.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
                if (item.GEO) {
                    let json = JSON.parse(item.GEO);
                    window.L.geoJSON(json, {
                        style: function() {
                            return {
                                color: bgColor,
                            };
                        },
                        onEachFeature: (feature, layer) => {
                            switch (layer.feature.geometry.type) {
                                case "Point":
                                    layer.setIcon(icon);
                                    layer.on("mouseover", () => {
                                        let lng =
                                            layer.feature.geometry
                                                .coordinates[0];
                                        let lat =
                                            layer.feature.geometry
                                                .coordinates[1];
                                        this.markerPopup
                                            .setLatLng([lat, lng])
                                            .setContent(
                                                `<div class="tip">${item.SBMC}</div>`
                                            )
                                            .openOn(this.myMap);
                                    });
                                    layer.on("mouseout", () => {
                                        this.markerPopup.removeFrom(this.myMap);
                                    });
                                    break;
                                // 线
                                case "Polygon":
                                case "MultiPolygon":
                                    layer.on("mouseover", () => {
                                        let center1 = window.turf.centerOfMass(
                                            JSON.parse(item.GEO)
                                        );
                                        let lat =
                                            center1.geometry.coordinates[1];
                                        let lng =
                                            center1.geometry.coordinates[0];
                                        this.markerPopup
                                            .setLatLng([lat, lng])
                                            .setContent(
                                                `<div class="tip">${item.SBMC}</div>`
                                            )
                                            .openOn(this.myMap);
                                    });
                                    layer.on("mouseout", () => {
                                        this.markerPopup.removeFrom(this.myMap);
                                    });
                                    break;
                            }
                            layer.on("click", () => {
                                this.closeComDialog();
                                this.commonDialog.dialog = true;
                                this.commonDialog.zwdyxDialog = true;
                                this.commonDialog.title = `${item.SBMC}`;
                                this.commonDialog.detail = item;
                            });
                        },
                    }).addTo(group);
                }
            });
        },
        // 植物多样性
        drawTeaQuadrat(obj) {
            let list = [
                {
                    NAME: "糯岗古茶林样方",
                    WD: 22.217944040329726,
                    JD: 99.98952802870798,
                    imgList: [
                        {
                            time: 2022,
                            src: require("@image/bigScreen/ecological_environment/tea_quadrat/2022/nuogang.png"),
                        },
                        {
                            time: 2012,
                            src: require("@image/bigScreen/ecological_environment/tea_quadrat/2021/nuogang.png"),
                        },
                    ],
                    rightImg: require("@image/bigScreen/ecological_environment/tea_quadrat/2022/nuogang.png"),
                    json: {
                        type: "Feature",
                        geometry: {
                            type: "Polygon",
                            coordinates: [
                                [
                                    [99.99015555555565, 22.218136111111164],
                                    [99.98931111111114, 22.218502777777758],
                                    [99.98888333333332, 22.217724999999973],
                                    [99.98979166666669, 22.217411111111232],
                                    [99.99015555555565, 22.218136111111164],
                                ],
                            ],
                        },
                        properties: {
                            Id: 2,
                            name: "糯岗古茶林样方",
                            ename: "",
                            mj: 0.905598625087,
                        },
                    },
                },
                {
                    NAME: "芒景上下寨-芒洪古茶林样方",
                    WD: 22.156630386197357,
                    JD: 100.01937458720109,
                    imgList: [
                        {
                            time: 2022,
                            src: require("@image/bigScreen/ecological_environment/tea_quadrat/2022/mangjingmanghong.png"),
                        },
                        {
                            time: 2012,
                            src: require("@image/bigScreen/ecological_environment/tea_quadrat/2021/mangjingmanghong.png"),
                        },
                    ],
                    rightImg: require("@image/bigScreen/ecological_environment/tea_quadrat/2022/mangjingmanghong.png"),
                    json: {
                        type: "Feature",
                        geometry: {
                            type: "Polygon",
                            coordinates: [
                                [
                                    [100.02003107848691, 22.15682385227069],
                                    [100.0191644118205, 22.157251630048336],
                                    [100.01871718959802, 22.156440518937245],
                                    [100.01958479325253, 22.15600721967695],
                                    [100.02003107848691, 22.15682385227069],
                                ],
                            ],
                        },
                        properties: {
                            Id: 3,
                            name: "芒景上下寨-芒洪古茶林样方",
                            ename: "",
                            mj: 1.02577673173,
                        },
                    },
                },
                {
                    NAME: "景迈大寨古茶林样方",
                    WD: 22.203354027861533,
                    JD: 100.02025573180025,
                    imgList: [
                        {
                            time: 2022,
                            src: require("@image/bigScreen/ecological_environment/tea_quadrat/2022/jingmaidazhai.png"),
                        },
                        {
                            time: 2012,
                            src: require("@image/bigScreen/ecological_environment/tea_quadrat/2021/jingmaidazhai.png"),
                        },
                    ],
                    rightImg: require("@image/bigScreen/ecological_environment/tea_quadrat/2022/jingmaidazhai.png"),
                    json: {
                        type: "Feature",
                        geometry: {
                            type: "Polygon",
                            coordinates: [
                                [
                                    [100.0209333333334, 22.203505555555637],
                                    [100.02005555555564, 22.203980555555688],
                                    [100.01957777777778, 22.20323055555565],
                                    [100.02044444444402, 22.20271111111117],
                                    [100.0209333333334, 22.203505555555637],
                                ],
                            ],
                        },
                        properties: {
                            Id: 1,
                            name: "景迈大寨古茶林样方",
                            ename: "",
                            mj: 1.04347499708,
                        },
                    },
                },
            ];

            let group = obj.group;
            let bgColor = obj.bgColor;
            let icon = window.L.divIcon({
                html: `<div style="width:10px;height:10px;border-radius:50%;background:${bgColor}; "  /></div>`,
                iconSize: [10, 10],
                iconAnchor: [5, 5],
            });

            list.map((item) => {
                let marker = window.L.marker([item.WD, item.JD], {
                    icon: icon,
                    data: item,
                }).addTo(group);
                marker.on("click", () => {
                    this.closeComDialog();
                    this.commonDialog.dialog = true;
                    this.commonDialog.clyfDialog = true;
                    this.commonDialog.title = item.NAME;

                    this.selectDataTime.list = item.imgList;
                    this.selectDataTime.defaultValue = item.imgList[0].time;
                    this.commonDialog.detail = item.imgList;
                    this.$nextTick(() => {
                        this.getSelectItemTime(item.imgList[0]);
                    });
                });
                marker.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([item.WD, item.JD])
                        .setContent(`<div class="tip">${item.NAME}</div>`)
                        .openOn(this.myMap);
                });
                marker.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
                window.L.geoJSON(item.json, {
                    style: function() {
                        return {
                            color: bgColor,
                        };
                    },
                    onEachFeature: (feature, layer) => {
                        switch (layer.feature.geometry.type) {
                            case "Polygon": //面
                                layer.on("mouseover", () => {
                                    this.markerPopup
                                        .setLatLng([item.WD, item.JD])
                                        .setContent(
                                            `<div class="tip">${item.NAME}</div>`
                                        )
                                        .openOn(this.myMap);
                                });
                                layer.on("mouseout", () => {
                                    this.markerPopup.removeFrom(this.myMap);
                                });
                                break;
                        }
                        layer.on("click", () => {
                            this.closeComDialog();
                            this.commonDialog.dialog = true;
                            this.commonDialog.clyfDialog = true;
                            this.commonDialog.title = item.NAME;

                            this.selectDataTime.list = item.imgList;
                            this.selectDataTime.defaultValue =
                                item.imgList[0].time;
                            this.commonDialog.detail = item.imgList;
                            this.$nextTick(() => {
                                this.getSelectItemTime(item.imgList[0]);
                            });
                        });
                    },
                }).addTo(group);
            });
        },
        // 植物多样性样方 选择时间
        getSelectItemTime(item) {
            let num = "";
            switch (item.time) {
                case 2022:
                    num = 0;
                    break;
                case 2012:
                    num = 1;
                    break;
            }
            this.$refs.DialogTeaQuadrat.scrollTo(num);
        },
        // 修改轮播图 改变时间
        changeCarouseTime(num) {
            let time = "";
            switch (num) {
                case 0:
                    time = 2022;
                    break;
                case 1:
                    time = 2012;
                    break;
            }
            this.selectDataTime.defaultValue = time;
        },
        // 动物多样性
        drawDwdyx(obj) {
            let group = obj.group;
            let list = obj.list;
            let bgColor = obj.bgColor;
            this.clearLayersGroup(group);
            list.map((item) => {
                let icon = window.L.divIcon({
                    html: `<div style="width:10px;height:10px;border-radius:50%;background:${bgColor}; "  /></div>`,
                    iconSize: [10, 10],
                    iconAnchor: [5, 5],
                });
                let marker = window.L.marker([item.WD, item.JD], {
                    icon: icon,
                    data: item,
                }).addTo(group);
                marker.on("click", () => {
                    this.closeComDialog();
                    this.commonDialog.dialog = true;
                    this.commonDialog.dwdyxDialog = true;
                    this.commonDialog.title = `${item.SBMC}`;
                    this.commonDialog.detail = item;
                });
                marker.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([item.WD, item.JD])
                        .setContent(`<div class="tip">${item.SBMC}</div>`)
                        .openOn(this.myMap);
                });
                marker.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
                if (item.GEO) {
                    window.L.geoJSON(JSON.parse(item.GEO), {
                        style: function() {
                            return {
                                color: bgColor,
                            };
                        },
                        onEachFeature: (feature, layer) => {
                            switch (layer.feature.geometry.type) {
                                case "Point":
                                    layer.setIcon(icon);
                                    layer.on("mouseover", () => {
                                        let lng =
                                            layer.feature.geometry
                                                .coordinates[0];
                                        let lat =
                                            layer.feature.geometry
                                                .coordinates[1];
                                        this.markerPopup
                                            .setLatLng([lat, lng])
                                            .setContent(
                                                `<div class="tip">${item.SBMC}</div>`
                                            )
                                            .openOn(this.myMap);
                                    });
                                    layer.on("mouseout", () => {
                                        this.markerPopup.removeFrom(this.myMap);
                                    });
                                    break;
                                // 线
                                case "Polygon":
                                    layer.on("mouseover", () => {
                                        let center1 = window.turf.centerOfMass(
                                            JSON.parse(item.GEO)
                                        );
                                        let lat =
                                            center1.geometry.coordinates[1];
                                        let lng =
                                            center1.geometry.coordinates[0];
                                        this.markerPopup
                                            .setLatLng([lat, lng])
                                            .setContent(
                                                `<div class="tip">${item.SBMC}</div>`
                                            )
                                            .openOn(this.myMap);
                                    });
                                    layer.on("mouseout", () => {
                                        this.markerPopup.removeFrom(this.myMap);
                                    });
                                    break;
                            }
                            layer.on("click", () => {
                                this.commonDialog.dialog = true;
                                this.commonDialog.dwdyxDialog = true;
                                this.commonDialog.title = `${item.SBMC}`;
                                this.commonDialog.detail = item;
                            });
                        },
                    }).addTo(group);
                }
            });
        },
        // 古茶林病虫害调查
        drawGclbchdc(obj) {
            let group = obj.group;
            let list = obj.list;
            this.clearLayersGroup(group);
            list.map((item) => {
                // 0-无危害，1-轻度 2-中度，3-重度
                let bgColor = obj.mulList[3 - item.SHCD].bgColor;
                let icon = window.L.divIcon({
                    html: `<div style="width:10px;height:10px;border-radius:50%;background:${bgColor}; "  /></div>`,
                    iconSize: [10, 10],
                    iconAnchor: [5, 5],
                });
                let marker = window.L.marker([item.WD, item.JD], {
                    icon: icon,
                    data: item,
                }).addTo(group);
                marker.on("click", () => {
                    this.closeComDialog();
                    this.commonDialog.dialog = true;
                    this.commonDialog.gclbchDialog = true;
                    this.commonDialog.title = `${item.SBMC}`;
                    this.commonDialog.detail = item;
                });
                marker.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([item.WD, item.JD])
                        .setContent(`<div class="tip">${item.SBMC}</div>`)
                        .openOn(this.myMap);
                });
                marker.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
                window.L.geoJSON(JSON.parse(item.GEO), {
                    style: () => {
                        return {
                            color: bgColor,
                        };
                    },
                    onEachFeature: (feature, layer) => {
                        switch (layer.feature.geometry.type) {
                            case "Point":
                                layer.setIcon(icon);
                                layer.on("mouseover", () => {
                                    let lng =
                                        layer.feature.geometry.coordinates[0];
                                    let lat =
                                        layer.feature.geometry.coordinates[1];
                                    this.markerPopup
                                        .setLatLng([lat, lng])
                                        .setContent(
                                            `<div class="tip">${item.SBMC}</div>`
                                        )
                                        .openOn(this.myMap);
                                });
                                layer.on("mouseout", () => {
                                    this.markerPopup.removeFrom(this.myMap);
                                });
                                break;
                            // 线
                            case "Polygon":
                                layer.on("mouseover", () => {
                                    let center1 = window.turf.centerOfMass(
                                        JSON.parse(item.GEO)
                                    );
                                    let lat = center1.geometry.coordinates[1];
                                    let lng = center1.geometry.coordinates[0];
                                    this.markerPopup
                                        .setLatLng([lat, lng])
                                        .setContent(
                                            `<div class="tip">${item.SBMC}</div>`
                                        )
                                        .openOn(this.myMap);
                                });
                                layer.on("mouseout", () => {
                                    this.markerPopup.removeFrom(this.myMap);
                                });
                                break;
                        }
                        layer.on("click", () => {});
                    },
                }).addTo(group);
            });
        },
        // 微环境空气质量
        drawWhjkqzl(obj) {
            let group = obj.group;
            let list = obj.list;
            let bgColor = obj.bgColor;
            this.clearLayersGroup(group);
            list.map((item) => {
                let icon = window.L.divIcon({
                    html: `<div style="width:10px;height:10px;border-radius:50%;background:${bgColor}; "  /></div>`,
                    iconSize: [10, 10],
                    iconAnchor: [5, 5],
                });
                let marker = window.L.marker([item.WD, item.JD], {
                    icon: icon,
                    data: item,
                }).addTo(group);
                marker.on("click", () => {});
                marker.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([item.WD, item.JD])
                        .setContent(`<div class="tip">${item.SBMC}</div>`)
                        .openOn(this.myMap);
                });
                marker.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
            });
        },
        closeComDialog() {
            this.commonDialog.dialog = false;
            this.commonDialog.zwdyxDialog = false;
            this.commonDialog.clyfDialog = false;
            this.commonDialog.dwdyxDialog = false;
            this.commonDialog.gclbchDialog = false;
            this.commonDialog.gsmmDialog = false;
        },
        // 更改图层 index
        switchStateChange(state, item) {
            item.state = state;
            if (!state) {
                this.clearLayersGroup(item.group);
                return;
            }
            switch (item.name) {
                case "土壤墒情监测站":
                    this.drawMarkerTrsq(item);
                    break;
                case "水质五参数监测器":
                    this.drawMarkerSzjc(item);
                    break;
                case "动物多样性监测器":
                    this.drawMarkerDwjc(item);
                    break;
                case "物联网自动虫情信息采集设备":
                    this.drawMarkerCqxx(item);
                    break;
                case "生态监测球机":
                    this.drawMarkerStjc(item);
                    break;
                case "一体式气象站":
                    this.drawMarkerQxz(item);
                    break;
                case "古树名木点位":
                    this.drawMarkerGsmm(item);
                    break;
                case "植物多样性":
                    this.clearLayersGroup(item.group);
                    this.drawZwdyx(item);
                    this.drawTeaQuadrat(item);
                    break;
                case "动物多样性":
                    this.drawDwdyx(item);
                    break;
                case "古茶林病虫害调查":
                    this.drawGclbchdc(item);
                    break;
            }
        },
        switchStateCustomChange(state, item) {
            item.state = state;
            if (!state) {
                this.clearLayersGroup(item.group);
                return;
            }
            switch (item.name) {
                case "病虫害遥感影像":
                    new window.L.tileLayer(window.MAP_GQXY_BCH, {
                        zIndex: 6,
                        minZoom: 0,
                        maxZoom: 22,
                    }).addTo(item.group);
                    break;
                case "病害图斑分布图":
                    this.getBchmjStyle(item.group);
                    break;
            }
        },
        // 更改图层 古树名木 假数据
        switchStateTestChange(state, item) {
            item.state = state;
            if (!state) {
                this.clearLayersGroup(item.group);
                return;
            }
            this.drawMarkerGsmm(item);
        },
        async getBchmjStyle(group) {
            let layerStyle = await this.getVectorStyle({
                url: window.MAP_BHTB,
            });
            let layer = window.L.mapboxGL({
                accessToken: window.MAPBOX_TOKEN,
                style: layerStyle,
            }).addTo(group);
            this.mapboxMap = layer.getMapboxMap();
            this.mapboxMap.on("load", () => {
                this.$nextTick(() => {
                    let myContainer = layer._glMap.getContainer();
                    myContainer.className = `leaflet-gl-layer mapboxgl-map bchtbfbt`;
                    // myContainer.style.zIndex = 10;
                    // myContainer.style.position = "relative";
                });
            });
        },
        openPdfDialog() {
            this.pdf.title = this.sthjJlfxxqSelect.title;
            this.pdf.src = this.sthjJlfxxqSelect.src;
            this.pdf.page = this.sthjJlfxxqSelect.page;
            this.pdf.dialog = true;
        },
        closePdf() {
            this.pdf.dialog = false;
            this.SET_STHJJLFXXQSELECT(null);
        },
    },
};
</script>
<style scoped lang="scss">
.screenCenter {
    position: relative;
    width: 100%;
    height: 100%;
    .mapPart {
        width: 100%;
        height: 100%;
        position: relative;
    }
    // 图层控制
    .mapChangePart {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 10;
        .layer {
            position: absolute;
            top: 0;
            left: 0;
            width: 130px;
            height: 40px;
            background: #1a3059;
            border: 1px solid #35507c;
            border-radius: 10px;
            box-shadow: 5px 9px 30px 0px rgba(0, 44, 75, 0.2);
            padding: 0 20px;

            .desc {
                color: #fff;
                font-size: 18px;
            }
        }

        .layerCtn {
            min-width: 130px;
            margin-top: 50px;
            padding: 10px;
            background: #1a3059;
            border-radius: 5px;
        }
    }
    // 图例
    .legendPart {
        position: absolute;
        left: 10px;
        bottom: 10px;
        z-index: 10;
    }
    .commonDialogPart {
        position: absolute;
        top: 4px;
        right: -711px;
        z-index: 999;
        background: #040e1e;
        padding: 10px;
        height: 100%;
        .select-part {
            position: absolute;
            top: 12px;
            left: 26px;
            z-index: 1;
        }
        .popupTitle {
            width: 100%;
            height: 36px;
            line-height: 35px;
            display: flex;
            position: relative;
            padding: 0 15px;
            // 标题内容
            .titleCenter {
                width: fit-content;
                height: 100%;
                margin: 0 auto;
                font-size: 18px;
                color: rgb(210, 234, 246);
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                .titleContent {
                    padding: 0 30px;
                    min-width: 200px;
                    display: inline-block;
                    border-top: 1px solid #84acc2;
                    background: linear-gradient(
                        rgba(48, 80, 97, 1),
                        rgba(48, 80, 97, 0)
                    );
                }
            }
            // 标题按钮
            .titleIcon {
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
                color: #ffffff;
                font-size: 24px;
            }
        }
        .popupCtn {
            width: 100%;
            padding: 20px 14px 10px 14px;
            height: calc(100% - 30px);
        }
    }
    .pdfPart {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1000;
        width: 100%;
        height: 100%;
    }
}
</style>
