<template>
    <div class="basicYcys bigScreen">
        <!-- 遗产要素 -->
        <basic-module :text="common.getFyName(fany.ycys)" :fontSize="fontSize">
            <div slot="basic-module" class="ycysPart flex-between">
                <el-input
                    class="keyword"
                    :class="[jczlFyNum == 0 ? '' : 'enKeyword']"
                    placeholder="请输入遗产要素名称"
                    v-model="keyWord"
                    clearable
                    @input="changeKeyWord()"
                >
                </el-input>
                <div class="ycysLeft">
                    <div class="decorate">
                        <div class="icon"></div>
                        <div class="ycysTotal">价值载体</div>
                        <div class="num">{{ ycysTotal }}</div>
                    </div>
                    <!-- 遗产要素类型 -->
                    <div class="ycysType scrollbar-hidden">
                        <div
                            v-for="(item, index) in ycysTypeList"
                            :key="index"
                            class="animated bounceInRight ycysTypeItem"
                            :style="{ animationDelay: 0.2 + index * 0.2 + 's' }"
                            @click="changeYcysType(item, index)"
                        >
                            <el-image
                                :src="
                                    index == ycysTypeActiveNum
                                        ? item.srcActive
                                        : item.srcDefault
                                "
                                fit="cover"
                                class="imgObj pointer"
                            >
                            </el-image>
                        </div>
                    </div>
                </div>
                <!-- 有遗产组成 模块 -->
                <div v-if="yczcLState" class="ycysRight flex-1">
                    <div class="ycysRightItem">
                        <div class="label">遗产要素</div>
                        <div class="ycysRightList scrollbar-hidden flex-wrap">
                            <div
                                v-for="(item, index) in yczcInnerList"
                                :key="index"
                                class="
                                    animated
                                    bounceInRight
                                    ycysItem
                                    flex-between
                                "
                                :style="{
                                    animationDelay: 0.2 + index * 0.2 + 's',
                                }"
                            >
                                <div
                                    class="ycysItemInner flex-between pointer"
                                    v-for="(itemInner, indexInner) in item"
                                    :key="indexInner"
                                    @click="getYczcDetail(itemInner)"
                                >
                                    <div class="state flex-both-center">
                                        <div
                                            class="steteIcon stateLeftTop"
                                        ></div>
                                        <div
                                            class="steteIcon stateRightTop"
                                        ></div>
                                        <div
                                            class="steteIcon stateLeftBottom"
                                        ></div>
                                        <div
                                            class="steteIcon stateRightBottom"
                                        ></div>
                                        <div
                                            class="circle"
                                            :style="{
                                                borderColor:
                                                    common.getYcysStateColor(
                                                        itemInner.ZTNAME
                                                    ),
                                            }"
                                        ></div>
                                    </div>
                                    <div
                                        class="name pointer flex-1 ellipsis"
                                        :class="[
                                            yczcActiveNum === itemInner.index
                                                ? 'nameActive'
                                                : 'nameDefault',
                                        ]"
                                        :title="itemInner[ycysKey.yczcName]"
                                    >
                                        {{ itemInner[ycysKey.yczcName] }}
                                    </div>
                                </div>
                            </div>
                            <div class="noData" v-if="!yczcInnerList.length">
                                <SnNoData
                                    :noDataState="true"
                                    noDataText="暂无数据"
                                ></SnNoData>
                            </div>
                        </div>
                    </div>
                    <div class="linePart">
                        <div class="lineTop flex">
                            <div class="line1"></div>
                            <div class="line2"></div>
                            <div class="line3"></div>
                            <div class="line4"></div>
                        </div>
                    </div>
                    <div class="ycysRightItem">
                        <div class="label">遗产要素单体</div>
                        <div class="ycysRightList scrollbar-hidden flex-wrap">
                            <div
                                v-for="(item, index) in ycysInnerList"
                                :key="index"
                                class="
                                    animated
                                    bounceInRight
                                    ycysItem
                                    flex-between
                                "
                                :style="{
                                    animationDelay: 0.2 + index * 0.2 + 's',
                                }"
                            >
                                <div
                                    class="ycysItemInner flex-between pointer"
                                    v-for="(itemInner, indexInner) in item"
                                    :key="indexInner"
                                    @click="getYcysDetail(itemInner)"
                                >
                                    <div class="state flex-both-center">
                                        <div
                                            class="steteIcon stateLeftTop"
                                        ></div>
                                        <div
                                            class="steteIcon stateRightTop"
                                        ></div>
                                        <div
                                            class="steteIcon stateLeftBottom"
                                        ></div>
                                        <div
                                            class="steteIcon stateRightBottom"
                                        ></div>
                                        <div
                                            class="circle"
                                            :style="{
                                                borderColor:
                                                    common.getYcysStateColor(
                                                        itemInner.ZTNAME
                                                    ),
                                            }"
                                        ></div>
                                    </div>
                                    <div
                                        class="name pointer flex-1 ellipsis"
                                        :class="[
                                            ycysActiveNum === itemInner.index
                                                ? 'nameActive'
                                                : 'nameDefault',
                                        ]"
                                        :title="itemInner[ycysKey.name]"
                                    >
                                        {{ itemInner[ycysKey.name] }}
                                    </div>
                                </div>
                            </div>
                            <div class="noData" v-if="!ycysInnerList.length">
                                <SnNoData
                                    :noDataState="true"
                                    noDataText="暂无数据"
                                    v-if="!ycysList.length"
                                ></SnNoData>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 没有遗产组成模块 -->
                <div
                    v-else
                    class="ycysRightNoYczc flex-1 scrollbar-hidden flex-wrap"
                >
                    <div
                        v-for="(item, index) in ycysInnerList"
                        :key="index"
                        class="animated bounceInRight ycysItem flex-between"
                        :style="{
                            animationDelay: 0.2 + index * 0.2 + 's',
                        }"
                    >
                        <div
                            class="ycysItemInner flex-between"
                            v-for="(itemInner, indexInner) in item"
                            :key="indexInner"
                            @click="getYcysDetail(itemInner)"
                        >
                            <div class="state flex-both-center">
                                <div class="steteIcon stateLeftTop"></div>
                                <div class="steteIcon stateRightTop"></div>
                                <div class="steteIcon stateLeftBottom"></div>
                                <div class="steteIcon stateRightBottom"></div>
                                <div
                                    class="circle"
                                    :style="{
                                        borderColor: common.getYcysStateColor(
                                            itemInner.ZTNAME
                                        ),
                                    }"
                                ></div>
                            </div>
                            <div
                                class="name pointer flex-1 ellipsis"
                                :class="[
                                    ycysActiveNum === itemInner.index
                                        ? 'nameActive'
                                        : 'nameDefault',
                                ]"
                                :title="itemInner[ycysKey.name]"
                            >
                                {{ itemInner[ycysKey.name] }}
                            </div>
                        </div>
                    </div>

                    <NoMonitor
                        v-if="ycysInnerList.length == 0"
                        text="暂无数据"
                    ></NoMonitor>
                </div>
            </div>
        </basic-module>
        <!-- 图例 -->
        <div class="legendPart flex-between-y-center" v-if="legendState">
            <div
                v-for="(item, index) in legendColorList"
                :key="index"
                class="flex-y-center animated bounceInRight"
                :style="{ animationDelay: 0.2 + index * 0.2 + 's' }"
            >
                <div class="triangle" :style="{ background: item.color }"></div>
                <div class="name">
                    {{ item[ycysKey.legendType == 0 ? "label" : "desc"] }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import NoMonitor from "@views/big_screen/comp/NoMonitor";
export default {
    components: {
        NoMonitor,
    },
    name: "",
    data() {
        return {
            keyWord: "",
            ycysTypeActiveNum: null, //要素类型
            ycysActiveNum: null, //遗产要素
            yczcActiveNum: null, //遗产组成
            noImg: require("@image/bigScreen/noImg.png"),
            legendColorList: [],
            yczcInnerList: [], //遗产组成列表
            ycysInnerList: [], //遗产要素列表
            ycysTypeList: [
                {
                    name: "古茶林",
                    srcActive: require("@image/bigScreen/ycysType/gclActive.png"),
                    srcDefault: require("@image/bigScreen/ycysType/gclDefault.png"),
                },
                {
                    name: "传统村落",
                    srcActive: require("@image/bigScreen/ycysType/ctclActive.png"),
                    srcDefault: require("@image/bigScreen/ycysType/ctclDefault.png"),
                },
                {
                    name: "分隔防护林",
                    srcActive: require("@image/bigScreen/ycysType/fgfhlActive.png"),
                    srcDefault: require("@image/bigScreen/ycysType/fgfhlDefault.png"),
                },
            ],
        };
    },
    props: {
        // 遗产总数
        ycysTotal: {
            type: Number,
            default: () => {
                return 0;
            },
        },
        // 是否存在遗产组成模块
        yczcLState: {
            type: Boolean,
            default: () => {
                return true;
            },
        },
        // 遗产组成列表
        yczcList: {
            type: Array,
            default: () => {
                return [];
            },
        },
        // 遗产要素列表
        ycysList: {
            type: Array,
            default: () => {
                return [];
            },
        },
        // 图例 显示 隐藏
        legendState: {
            type: Boolean,
            default: () => {
                return true;
            },
        },
        // 遗产要素的key值
        ycysKey: {
            type: Object,
            default: () => {
                let obj = {
                    legendType: 1, //0可控  轻微 严重  非常严重 1 好 良好  一般 差
                    name: "MC", //名称
                    yczcName: "YCYSFL_2", //遗产组成名称
                    stateNum: "ZT", // 状态 0 可控/好 1 轻微/良好 2 严重/一般 3 非常严重/差   ------- 空
                };
                return obj;
            },
        },
        fontSize: {
            type: String,
            default: () => {
                return "18px";
            },
        },
    },
    computed: {
        ...mapGetters({
            jczlFyNum: TYPES.jczlFyNum,
        }),
    },
    watch: {
        ycysList: {
            immediate: true,
            handler() {
                let list = Array.from(this.ycysList);
                list.map((item, index) => {
                    item.index = index;
                });
                this.ycysInnerList = this.common.arrayDevide(list, 2);
            },
        },
        yczcList: {
            immediate: true,
            handler() {
                let list = Array.from(this.yczcList);
                list.map((item, index) => {
                    item.index = index;
                });
                this.yczcInnerList = this.common.arrayDevide(list, 2);
            },
        },
    },
    mounted() {
        let ary = Array.from(window.YCYS_COLOR_LIST.slice(0, -1));
        this.legendColorList = ary.reverse();
    },
    methods: {
        // 遗产要素类型切换
        changeYcysType(item, index) {
            this.yczcActiveNum = null;
            this.ycysActiveNum = null;
            if (index === this.ycysTypeActiveNum) {
                this.ycysTypeActiveNum = null;
                this.$emit("ycysTypeItem", null);
            } else {
                this.ycysTypeActiveNum = index;
                this.$emit("ycysTypeItem", item, index);
            }
        },
        // 获取遗产组成详情
        getYczcDetail(item) {
            if (this.yczcActiveNum == item.index) {
                this.yczcActiveNum = null;
                this.$emit("getYczcItem", null);
            } else {
                this.yczcActiveNum = item.index;
                this.$emit("getYczcItem", item);
            }
        },
        // 获取遗产要素详情
        getYcysDetail(item) {
            if (this.ycysActiveNum == item.index) {
                this.ycysActiveNum = null;
                this.$emit("getYcysItem", null);
            } else {
                this.ycysActiveNum = item.index;
                this.$emit("getYcysItem", item);
            }
        },
        // 重置遗产要素类型
        reSetYcysType() {
            this.ycysTypeActiveNum = null;
        },
        // 重置遗产组成
        reSetYczc(num) {
            this.yczcActiveNum = num;
        },
        // 重置遗产要素
        reSetYcys(num) {
            this.ycysActiveNum = num;
        },
        // 关键字
        changeKeyWord() {
            this.$emit("getKeyWord", this.keyWord);
        },
    },
};
</script>
<style scoped lang="scss">
.basicYcys {
    width: 100%;
    height: 100%;
    position: relative;
    // 遗产要素部分
    .ycysPart {
        width: 100%;
        height: 100%;
        position: relative;
        .keyword {
            position: absolute;
            top: -55px;
            left: 127px;
            width: 200px;
        }
        .enKeyword {
            top: -48px;
            left: 185px;
        }
        .ycysLeft {
            width: 110px;
            height: 100%;
            background: rgba(26, 48, 89, 0.5);
            margin-right: 15px;
            text-align: center;
            .decorate {
                width: 100%;
                height: 100px;
                padding-top: 10px;
                .icon {
                    position: relative;
                    width: 90px;
                    height: 10px;
                    margin: 0 auto;
                    &::before {
                        content: ""; /* 用伪元素来生成一个矩形 */
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: -1;
                        background: rgba(53, 80, 124, 1);
                        transform: perspective(10px) rotateX(-10deg);
                    }
                }

                .ycysTotal {
                    font-size: 16px;
                    color: rgba(255, 170, 91, 1);
                    padding-top: 10px;
                }
                .num {
                    font-size: 40px;
                    color: rgba(189, 215, 236, 1);
                    font-family: DBPM;
                    position: relative;
                }
            }
            .ycysType {
                width: 100%;
                height: calc(100% - 100px);
                .ycysTypeItem {
                    margin: 0 auto;
                    width: 90px;
                    height: 90px;
                    margin-bottom: 10px;
                }
            }
        }
        .ycysRight {
            height: 100%;
            .ycysRightItem {
                height: calc(50% - 10px);
                &:nth-child(1) {
                    margin-bottom: 10px;
                }
                .label {
                    font-size: 14px;
                    color: #35507c;
                }
                .ycysRightList {
                    margin-top: 4px;
                    height: calc(100% - 30px);
                    position: relative;
                    .noData {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                    .ycysItem {
                        width: 100%;
                        height: 45px;
                        margin-bottom: 10px;
                        .ycysItemInner {
                            width: 270px;
                            height: 45px;
                        }
                        .state {
                            width: 45px;
                            height: 45px;
                            margin-right: 10px;
                            border: 1px solid rgba(103, 159, 192, 0.3);
                            position: relative;
                            background: rgba(26, 48, 89, 0.4);
                            .steteIcon {
                                width: 2px;
                                height: 2px;
                                background: rgba(168, 200, 255, 1);
                            }
                            .stateLeftTop {
                                position: absolute;
                                left: 0;
                                top: 0;
                            }
                            .stateRightTop {
                                position: absolute;
                                right: 0;
                                top: 0;
                            }
                            .stateLeftBottom {
                                position: absolute;
                                left: 0;
                                bottom: 0;
                            }
                            .stateRightBottom {
                                position: absolute;
                                right: 0;
                                bottom: 0;
                            }
                            .circle {
                                width: 20px;
                                height: 20px;
                                border-radius: 50%;
                                margin: 0 auto;
                                border: 2px solid #fff;
                            }
                        }
                        .name {
                            width: 200px;
                            height: 45px;
                            line-height: 45px;
                            padding-left: 27px;
                        }
                        .nameDefault {
                            background: rgba(26, 48, 89, 0.4);
                            color: rgba(154, 180, 227, 1);
                        }
                        .nameActive {
                            background: rgba(53, 80, 124, 0.9);
                            color: rgba(255, 255, 255, 1);
                        }
                    }
                }
            }
            .linePart {
                margin-top: 10px;
                width: 100%;
                height: 12px;
                border-top: 2px solid rgba(53, 80, 124, 0.4);
                border-bottom: 2px solid rgba(53, 80, 124, 0.4);
                position: relative;
                .lineTop {
                    position: absolute;
                    left: 0;
                    top: -2px;
                    width: 100%;
                    height: 2px;
                    .line1 {
                        width: 100px;
                        margin-right: 40px;
                        background: rgba(154, 180, 227, 0.8);
                    }
                    .line2 {
                        width: 167px;
                        margin-right: 40px;
                        background: rgba(154, 180, 227, 0.8);
                    }
                    .line3 {
                        width: 40px;
                        margin-right: 40px;
                        background: rgba(154, 180, 227, 0.8);
                    }
                    .line4 {
                        width: 40px;
                        background: rgba(154, 180, 227, 0.8);
                    }
                }
            }
        }
        .ycysRightNoYczc {
            height: 100%;
            position: relative;
            .ycysItem {
                width: 100%;
                height: 45px;
                margin-bottom: 10px;
                .ycysItemInner {
                    width: 270px;
                    height: 45px;
                }
                .state {
                    width: 45px;
                    height: 45px;
                    margin-right: 10px;
                    border: 1px solid rgba(103, 159, 192, 0.3);
                    position: relative;
                    background: rgba(26, 48, 89, 0.4);
                    .steteIcon {
                        width: 2px;
                        height: 2px;
                        background: rgba(168, 200, 255, 1);
                    }
                    .stateLeftTop {
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    .stateRightTop {
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                    .stateLeftBottom {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                    }
                    .stateRightBottom {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                    .circle {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        margin: 0 auto;
                        border: 2px solid #fff;
                    }
                }
                .name {
                    width: 200px;
                    height: 45px;
                    line-height: 45px;
                    padding-left: 27px;
                }
                .nameDefault {
                    background: rgba(26, 48, 89, 0.4);
                    color: rgba(154, 180, 227, 1);
                }
                .nameActive {
                    background: rgba(53, 80, 124, 0.9);
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
    }
    // 图例部分
    .legendPart {
        position: absolute;
        right: 20px;
        top: 30px;
        width: 225px;
        height: 20px;
        z-index: 3;
        .name {
            font-weight: 400;
            font-size: 16px;
            color: rgba(53, 80, 124, 1);
        }
        .triangle {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 5px;
        }
    }
}
</style>
