<template>
    <div class="screenItem">
        <!-- 防火监测 -->
        <BasicMonitor :text="common.getFyName(fany.fhjc)">
            <div slot="basic-module" class="screenCtn scrollbar-hidden">
                <div class="fhTop flex-between">
                    <div class="fire">
                        <ScanAnimate text="火警" :num="fireNum"></ScanAnimate>
                        <img
                            src="@image/bigScreen/monitor_all/fhjc/fire.gif"
                            class="bg"
                            v-if="fireNum > 0"
                        />
                    </div>
                    <div class="listPart flex-1">
                        <div
                            v-for="(item, index) in topList"
                            :key="index"
                            class="listItem flex"
                        >
                            <div class="title flex-both-center">
                                {{ item.name }}
                            </div>
                            <div class="num flex-1 flex-both-center">
                                <div v-if="index < 2" class="numDetail">
                                    {{ item.num }}
                                </div>
                                <div
                                    v-else
                                    class="numDesc"
                                    :style="{ color: getYcyccdColor(item.num) }"
                                >
                                    {{ item.num }}
                                </div>
                                <div class="dw">
                                    {{ item.dw }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="fhCenter flex-between">
                    <div
                        v-for="(item, index) in centerList"
                        :key="index"
                        class="fhItem"
                    >
                        <div class="dotLeft"></div>
                        <div class="dotRight"></div>
                        <div class="topPart flex-between-y-center">
                            <img
                                :src="item.src"
                                :style="{ width: item.width }"
                            />
                            <div class="name">
                                {{ item.name }}
                            </div>
                        </div>
                        <div class="bottomPart flex-both-center">
                            <span class="num">{{ item.num }}</span>
                            <span class="dw">{{ item.dw }}</span>
                        </div>
                    </div>
                </div>
                <div class="fhBottom">
                    <!-- <TableList
                        :maxHeight="140"
                        :tableColums="tableColums"
                        :tableData="tableData"
                        noDataText="暂无消防预警数据"
                    ></TableList> -->
                    <div class="echart" id="fhjcEchart"></div>
                    <div class="decorate flex-between">
                        <div class="bg1"></div>
                        <div class="bg2"></div>
                        <div class="bg3"></div>
                        <div class="bg4"></div>
                    </div>
                </div>
            </div>
        </BasicMonitor>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import BasicMonitor from "@views/big_screen/monitor_all/comp/BasicModule";
import ScanAnimate from "@views/big_screen/comp/ScanAnimate";
import TableList from "@views/big_screen/comp/TableList";
export default {
    components: {
        BasicMonitor,
        ScanAnimate,
        TableList,
    },
    name: "",
    data() {
        return {
            fireNum: 0,
            topList: [
                {
                    name: "消防水池",
                    num: 0,
                    dw: "m³",
                },
                {
                    name: "历史火警次数",
                    num: 0,
                    dw: "次",
                },
                {
                    name: "遗产影响程度",
                    num: "无",
                    dw: "",
                },
            ],
            centerList: [
                {
                    src: require("@image/bigScreen/monitor_all/fhjc/sxt.png"),
                    name: "摄像头",
                    num: 0,
                    width: "28px",
                    dw: "个",
                },
                {
                    src: require("@image/bigScreen/monitor_all/fhjc/bjsb.png"),
                    name: "报警设备",
                    num: 0,
                    width: "28px",
                    dw: "个",
                },
                {
                    src: require("@image/bigScreen/monitor_all/fhjc/xfs.png"),
                    name: "消防栓",
                    num: 0,
                    width: "28px",
                    dw: "套",
                },
                {
                    src: require("@image/bigScreen/monitor_all/fhjc/mhq.png"),
                    name: "灭火器",
                    num: 0,
                    width: "28px",
                    dw: "套",
                },
            ],
            tableColums: [
                {
                    prop: "FSSJ",
                    label: "时间",
                    width: 140,
                },
                {
                    prop: "FSDD",
                    label: "地点",
                    width: 140,
                    align: "center",
                    headerAlign: "center",
                },
                {
                    // 0 未解除  1 已终止
                    propColorNum: "JCZT",
                    colorArr: ["#fe2d15", "#78e437"],
                    prop: "JCZTMC",
                    label: "状态",
                    width: 50,
                    align: "center",
                    headerAlign: "center",
                },
            ],
            tableData: [
                // {
                //     CJSJ: "2021-01-01 11:11:11",
                //     SGZT: "未解除",
                //     ZT: 0,
                //     DD: "芒景下寨拥汀寨心",
                // },
            ],
        };
    },
    computed: {
        ...mapGetters({}),
    },
    props: {},
    watch: {},
    mounted() {
        this.getMainInfo();
        this.getTableList();
    },
    methods: {
        ...mapActions(["GetFhjcInfo", "GetHjDataList", "GetHjDataStatistics"]),
        ...mapMutations({}),
        async getMainInfo() {
            let result = await this.GetFhjcInfo({});
            this.fireNum = result.UNDISWARNCOUNT; //未解除火警数量
            this.topList[0].num = result.EQUIPINFOS[0].RJ;
            this.topList[1].num = result.HISTORYWARNCOUNT; //历史火警次数
            this.topList[2].num = result.YXCD ? result.YXCD : "无"; //遗产影响程度
            this.topList[2].numCode = result.YXCDCODE; //遗产影响程度

            this.centerList[0].num = result.EQUIPINFOS[3].COUNT; //RL
            this.centerList[1].num = result.EQUIPINFOS[2].COUNT; //RL
            this.centerList[2].num = result.EQUIPINFOS[4].COUNT; //RL
            this.centerList[3].num = result.EQUIPINFOS[1].COUNT; //RL
        },
        async getTableList() {
            // let result = await this.GetHjDataList({
            //     pageIndex: 1,
            //     pageSize: 10000,
            // });
            let result = await this.GetHjDataStatistics({
                pageIndex: 1,
                pageSize: 10000,
            });
            // this.tableData = result ? result.RESULT : [];
            this.initEchartFhjc(result);
        },
        initEchartFhjc(list) {
            let listAry = [
                {
                    name: "灭火器",
                    data: [],
                    color: "rgba(204, 51, 51, 1)",
                    colorP0: "rgba(204, 51, 51, 0)",
                    yAxisIndex: 1,
                },
                {
                    name: "摄像头",
                    data: [],
                    color: "rgba(110, 152, 194, 1)",
                    colorP0: "rgba(110, 152, 194, 0)",
                    yAxisIndex: 0,
                },
                {
                    name: "报警设备",
                    data: [],
                    color: "rgba(255, 204, 102, 1)",
                    colorP0: "rgba(255, 204, 102,0)",
                    yAxisIndex: 0,
                },
                {
                    name: "消防栓",
                    data: [],
                    color: "rgba(255, 126, 40, 1)",
                    colorP0: "rgba(255, 204, 102, 0)",
                    yAxisIndex: 1,
                },
            ];
            let xAxisData = [];
            let colorList = [];
            list.map((item) => {
                xAxisData.push(item.AZDMC);
                item.CHILDREN.map((item1) => {
                    switch (item1.EQUIPNAME) {
                        case "消防栓":
                            listAry[3].data.push(item1.COUNT);
                            break;
                        case "灭火器":
                            listAry[0].data.push(item1.COUNT);
                            break;
                        case "消防预警设备":
                            listAry[2].data.push(item1.COUNT);
                            break;
                        case "消防摄像头":
                            listAry[1].data.push(item1.COUNT);
                            break;
                    }
                });
            });
            let seriesData = [];
            listAry.map((item, index) => {
                colorList.push(item.color);
                let obj = {
                    yAxisIndex: item.yAxisIndex,
                    name: item.name,
                    type: "bar",
                    showAllSymbol: true,
                    symbol: "circle",
                    symbolSize: 5,
                    data: item.data,
                    barWidth: 12,
                    itemStyle: {
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    {
                                        offset: 0,
                                        color: item.color,
                                    },
                                    {
                                        offset: 1,
                                        color: item.colorP0,
                                    },
                                ],
                                false
                            ),
                            // shadowColor: "rgba(105,196,254, 0.9)",
                            // shadowBlur: 20,
                        },
                    },
                };
                seriesData.push(obj);
            });
            let option = {
                color: colorList,
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "none",
                        lineStyle: {
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "rgba(255, 255, 255,1)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                ],
                                global: false,
                            },
                        },
                    },
                    formatter(para) {
                        let str = `${para[0].axisValue}<br>`;
                        para.map((item) => {
                            let dw = "";
                            switch (item.seriesName) {
                                case "灭火器":
                                case "消防栓":
                                    dw = "套";
                                    break;
                                case "报警设备":
                                case "摄像头":
                                    dw = "个";
                                    break;
                            }
                            str += `${item.marker} ${item.seriesName} : ${item.value} ${dw} <br>`;
                        });
                        return str;
                    },
                },
                legend: {
                    top: "10px",
                    textStyle: {
                        color: "#fff",
                    },
                    icon: "roundRect", //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
                    itemWidth: 10, // 设置宽度
                    itemHeight: 10, // 设置高度
                },
                grid: {
                    left: 10,
                    right: 10,
                    top: 50,
                    bottom: 10,
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        data: xAxisData,
                        axisLine: {
                            lineStyle: {
                                color: "rgba(255,255,255,0.12)",
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(255,255,255,0.1)",
                            },
                        },
                        axisLabel: {
                            color: "#fff",
                            fontSize: 14,
                        },
                    },
                ],
                yAxis: [
                    {
                        minInterval: 10,
                        position: "left",
                        type: "value",
                        name: "个",
                        nameTextStyle: {
                            color: "rgba(168,200,255,1)",
                        },
                        axisLabel: {
                            fontSize: 14,
                            textStyle: {
                                color: "#e2e9ff",
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(255,255,255,0.1)",
                            },
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(255,255,255,0.12)",
                                width: 2,
                            },
                        },
                    },
                    {
                        minInterval: 100,
                        type: "value",
                        name: "套",
                        position: "right",
                        // offset: 50,
                        nameTextStyle: {
                            color: "rgba(168,200,255,1)",
                        },
                        axisLabel: {
                            fontSize: 14,
                            textStyle: {
                                color: "#e2e9ff",
                            },
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(255,255,255,0.1)",
                            },
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(255,255,255,0.12)",
                                width: 2,
                            },
                        },
                    },
                ],
                series: seriesData,
            };
            let echart = this.$echarts.dispose(
                document.getElementById("fhjcEchart")
            );
            echart = this.$echarts.init(document.getElementById("fhjcEchart"));
            echart.setOption(option);
        },
        getYcyccdColor(desc) {
            let color = "";
            switch (desc) {
                case "无影响":
                    color = "#00bd8d";
                    break;
                case "轻微":
                    color = "#ffff00";
                    break;
                case "严重":
                    color = "#ffb508";
                    break;
                case "非常严重":
                    color = "#ff4c4c";
                    break;
            }
            return color;
        },
    },
};
</script>
<style scoped lang="scss">
.screenItem {
    position: relative;
    width: 100%;
    height: 100%;
    .screenCtn {
        width: 100%;
        height: 100%;
        padding: 12px 0;
        .fhTop {
            position: relative;
            .fire {
                width: 139px;
                height: 139px;
                position: relative;
                overflow: hidden;
                .bg {
                    width: 500px;
                    height: 375px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-45%, -50%);
                    z-index: -1;
                }
            }
            .listPart {
                margin-left: 60px;
                height: 108px;
                .listItem {
                    width: 100%;
                    height: 40px;
                    margin-top: 4px;
                    .title {
                        width: 140px;
                        height: 40px;
                        background: url("~@image/bigScreen/icon/titleBg.png")
                            no-repeat;
                        background-size: 100% 100%;
                        font-size: 18px;
                        color: rgba(168, 200, 255, 1);
                    }
                    .num {
                        height: 40px;
                        border: 1px solid rgba(53, 80, 124, 0.4);
                        color: rgba(189, 215, 236, 1);
                        .numDetail {
                            font-size: 48px;
                            font-family: DBPM;
                            color: #ffcd80;
                        }
                        .numDesc {
                            font-size: 24px;
                            color: #ffcd80;
                        }
                        .dw {
                            font-size: 12px;
                            color: #ffcd80;
                            position: relative;
                            top: 4px;
                            left: 4px;
                        }
                    }
                }
            }
        }
        .fhCenter {
            width: 100%;
            height: 98px;
            margin-top: 10px;
            .fhItem {
                width: calc(25% - 10px);
                height: 100%;
                position: relative;
                .dotLeft {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    width: 4px;
                    height: 4px;
                    background: rgba(168, 200, 255, 1);
                }
                .dotRight {
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    width: 4px;
                    height: 4px;
                    background: rgba(168, 200, 255, 1);
                }
                .topPart {
                    width: 100%;
                    height: 50px;
                    border-top: 1px solid rgba(168, 200, 255, 0.4);
                    border-left: 1px solid rgba(168, 200, 255, 0.4);
                    border-right: 1px solid rgba(168, 200, 255, 0.4);
                    background: url("~@image/bigScreen/monitor_all/yj/cellBg.png")
                        no-repeat;
                    background-position: 0px 100%;
                    background-size: 100% auto;
                    padding: 0 10px 0 14px;
                    .name {
                        font-size: 18px;
                        color: #a8c8ff;
                    }
                }
                .bottomPart {
                    background: url("~@image/bigScreen/monitor_all/btbh/ctnBg.png")
                        no-repeat;
                    background-size: 100% 100%;
                    width: 100%;
                    height: calc(100% - 50px);
                    margin-top: 4px;
                    .num {
                        font-size: 28px;
                        color: #ffcd80;
                    }
                    .dw {
                        font-size: 12px;
                        color: #ffcd80;
                        position: relative;
                        top: 4px;
                        left: 4px;
                    }
                }
            }
        }
        .fhBottom {
            margin-top: 10px;
            width: 100%;
            height: 144px;
            position: relative;
            .echart {
                width: 100%;
                height: 100%;
            }
            .decorate {
                position: absolute;
                left: 0;
                top: 40px;
                width: 100%;
                height: calc(100% - 70px);
                padding: 0 10px 0 20px;
                background: rgba(53, 80, 124, 0.15);
                z-index: -1;
                .bg1 {
                    width: 55px;
                    height: 100%;
                    position: absolute;
                    left: 117px;
                    top: 0;
                    background: rgba(53, 80, 124, 0.15);
                }
                .bg2 {
                    width: 55px;
                    height: 100%;
                    background: rgba(53, 80, 124, 0.3);
                    position: absolute;
                    left: 172px;
                    top: 0;
                }
                .bg3 {
                    width: 55px;
                    height: 100%;
                    background: rgba(53, 80, 124, 0.15);
                    position: absolute;
                    left: 376px;
                    top: 0;
                }
                .bg4 {
                    width: 55px;
                    height: 100%;
                    background: rgba(53, 80, 124, 0.3);
                    position: absolute;
                    left: 430px;
                    top: 0;
                }
            }
        }
    }
}
</style>
