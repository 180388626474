<template>
    <div class="leftPart" style="background: rgba(26, 56, 89, 0.3)">
        <!-- 巡查片区 -->
        <basic-module :text="common.getFyName(fany.xcpq)">
            <div slot="basic-module" class="screenCtn scrollbar-hidden">
                <div class="leftTop flex-between">
                    <div class="mapPart">
                        <img
                            src="@image/bigScreen/monitor_all/rcxc/mapBg.png"
                            class="mapBg"
                        />
                        <MyMap
                            ref="myMap"
                            :myMap.sync="myMap"
                            :mapData="mapData"
                        ></MyMap>
                        <!-- <img
                            src="@image/bigScreen/monitor_all/rcxc/map.png"
                            class="map"
                        /> -->
                    </div>
                    <div class="tableList scrollbar-hidden">
                        <div
                            v-for="(item, index) in areaList"
                            :key="index"
                            class="tableItem flex-between pointer"
                            :class="[
                                rcxcAreaActiveNum == index
                                    ? 'tableItemActive'
                                    : '',
                            ]"
                            @click="getAreaDetail(item, index)"
                        >
                            <div class="name ellipsis" :title="item.QYMC">
                                {{ item.QYMC }}
                            </div>
                            <div
                                :title="
                                    `采集点：${item.CJDCON} 打卡点：${item.DKDCON}`
                                "
                            >
                                <span class="label">巡查点</span>
                                <span class="num">{{ item.XCDCON }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="leftBottom">
                    <div class="bgPart">
                        <img
                            src="@image/bigScreen/daily_inspect/leftBottom/bg.png"
                            class="bg"
                        />
                    </div>
                    <div
                        v-for="(item, index) in bottomList"
                        :key="index"
                        class="leftBottomItem flex"
                        :style="{ height: index == 2 ? '183px' : '' }"
                    >
                        <div class="leftPart">
                            <div class="leftName">{{ item.leftName }}</div>
                            <div class="numAndDw">
                                <span class="leftNum">{{ item.leftNum }}</span>
                                <span class="leftDw">{{ item.dw }}</span>
                            </div>
                            <img
                                src="@image/bigScreen/daily_inspect/leftBottom/net.png"
                                class="net"
                            />
                            <img
                                src="@image/bigScreen/daily_inspect/leftBottom/lineTop.png"
                                class="lineTop"
                            />
                            <img
                                src="@image/bigScreen/daily_inspect/leftBottom/lineBottom.png"
                                class="lineBottom"
                            />
                            <div class="lineCenter" v-if="index == 2"></div>
                        </div>
                        <div class="rightPart flex-column flex-around-y-center">
                            <div
                                class="rightItem flex-between"
                                v-for="(itemInner, indexInner) in item.list"
                                :key="indexInner"
                            >
                                <div class="name flex-both-center">
                                    {{ itemInner.name }}
                                </div>
                                <div class="numPart flex-both-center">
                                    <span class="num">{{ itemInner.num }}</span>
                                    <span class="dw">{{ itemInner.dw }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </basic-module>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { derectionData } from "@libs/dailyInspectAreaList";
import MyMap from "@views/big_screen/comp/map/BaseMap.vue";
export default {
    name: "",
    components: {
        MyMap,
    },
    data() {
        return {
            derectionData,
            areaGroup: null,
            myMap: null,
            mapData: {
                lng: 100.01575469970705,
                lat: 22.18549757276757,
                zoom: 12,
                baseMapNum: null,
                mapTypeBtn: false,
                resetBtnRight: "50px",
                dxtState: false,
                ycqHcqState: false,
            },
            areaList: [], //片区列表
            bottomList: [
                {
                    leftName: "总面积",
                    leftNum: 19095.74,
                    dw: "公顷",
                    list: [
                        {
                            name: "遗产区",
                            num: "7167.89",
                            dw: "公顷",
                        },
                        {
                            name: "缓冲区",
                            num: "11927.85",
                            dw: "公顷",
                        },
                    ],
                },
                {
                    leftName: "巡查点总数",
                    leftNum: 0,
                    dw: "个",
                    list: [
                        {
                            name: "打卡点",
                            num: 0,
                            dw: "个",
                        },
                        {
                            name: "采集点",
                            num: 0,
                            dw: "个",
                        },
                    ],
                },
                {
                    leftName: "巡查方式",
                    leftNum: 3,
                    dw: "种",
                    list: [
                        {
                            name: "人工巡查",
                            num: 0,
                            dw: "次",
                        },
                        {
                            name: "无人机巡查",
                            num: 22,
                            dw: "架次",
                        },
                        {
                            name: "摄像机巡查",
                            num: 150,
                            dw: "台",
                        },
                    ],
                },
            ],
            markerPopup: null,
        };
    },
    computed: {
        ...mapGetters({
            rcxcAreaItem: TYPES.rcxcAreaItem,
            rcxcAreaActiveNum: TYPES.rcxcAreaActiveNum,
            rxcxRgsbNum: TYPES.rxcxRgsbNum,
        }),
    },
    props: {},
    watch: {
        rcxcAreaItem: {
            immediate: true,
            handler() {
                if (!this.rcxcAreaItem) {
                    this.SET_RCXCAREAACTIVENUM(null);
                }
            },
        },
        rxcxRgsbNum: {
            immediate: true,
            handler() {
                this.bottomList[2].list[0].num = this.rxcxRgsbNum;
            },
        },
    },
    mounted() {
        this.areaGroup = window.L.layerGroup().addTo(this.myMap);
        this.getAreaInfo();
        this.getDkdCjd();

        //marker 弹出框
        this.markerPopup = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, 0],
            className: "markerPopup",
        });
    },
    methods: {
        ...mapActions(["GetRcxcXcpq", "GetSpotStatistic"]),
        ...mapMutations({
            SET_RCXCAREALIST: TYPES.SET_RCXCAREALIST,
            SET_RCXCAREAITEM: TYPES.SET_RCXCAREAITEM,
            SET_RCXCPANELNUM: TYPES.SET_RCXCPANELNUM,
            SET_RCXCAREAACTIVENUM: TYPES.SET_RCXCAREAACTIVENUM,
        }),
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        //获取片区数据
        async getAreaInfo() {
            let result = await this.GetRcxcXcpq({});
            result.map((item) => {
                this.derectionData.map((itemInner) => {
                    if (item.ID == itemInner.ID) {
                        Object.assign(item, itemInner);
                    }
                });
            });
            this.areaList = result;
            this.SET_RCXCAREALIST(result);
            this.drawXcq(this.areaGroup, result);
        },
        // 画 巡查区
        drawXcq(group, list) {
            this.clearLayersGroup(group);
            let areaColorList = [
                "#5a91fb",
                "#5ad9a7",
                "#5c7091",
                "#f7bd16",
                "#e86452",
                "#6ec9eb",
                "#955eb9",
                "#ff9944",
                "#1e9593",
                "#ff99c3",
                "#81511c",
                "#7d0022",
                "#556fb5",
                "#009944",
                "#0068b7",
                "#d31247",
                "#f260ce",
                "#7148f5",
                "#94b750",
                "#0b3da9",
                "#f4da80",
                "#863803",
                "#d2c7b6",
            ];

            list.map((item, index) => {
                if (!item.QYZB) {
                    return;
                }
                let areaItem = JSON.parse(item.QYZB);
                // 巡查区
                let layer = window.L.geoJSON(areaItem, {
                    style: () => {
                        return { color: item.YS };
                    },
                }).addTo(group);

                layer.on("mouseover", () => {
                    let lng = 0;
                    let lat = 0;
                    if (item.JD) {
                        lng = item.JD;
                        lat = item.WD;
                    } else {
                        let center = window.turf.centerOfMass(areaItem);
                        lat = center.geometry.coordinates[1];
                        lng = center.geometry.coordinates[0];
                    }
                    this.markerPopup
                        .setLatLng([lat, lng])
                        .setContent(`<div class="tip">${item.QYMC}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
                layer.on("click", () => {
                    this.SET_RCXCAREAACTIVENUM(index);
                    this.SET_RCXCAREAITEM(item);
                    this.SET_RCXCPANELNUM(1);
                });
            });
        },
        // 巡查区详情
        getAreaDetail(item, index) {
            this.SET_RCXCAREAACTIVENUM(index);
            this.SET_RCXCAREAITEM(item);
            this.SET_RCXCPANELNUM(1);
        },
        // 获取打卡点 采集点
        async getDkdCjd() {
            let result = await this.GetSpotStatistic({});
            this.bottomList[1].leftNum = result.XCD;
            this.bottomList[1].list[0].num = result.DKD;
            this.bottomList[1].list[1].num = result.CJD;
        },
    },
};
</script>
<style scoped lang="scss">
.leftPart {
    width: 100%;
    height: 100%;

    .screenCtn {
        width: 100%;
        height: 100%;
        .leftTop {
            width: 100%;
            height: 365px;
            .mapPart {
                width: 384px;
                height: 100%;
                position: relative;
                margin-right: 10px;
                .mapBg {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
                .map {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .tableList {
                width: calc(100% - 394px);
                height: 100%;
                .tableItem {
                    height: 45px;
                    line-height: 45px;
                    background: rgba(26, 48, 89, 0.4);
                    margin-bottom: 10px;
                    padding: 0 20px;
                    .name {
                        color: rgba(154, 180, 227, 1);
                        font-size: 18px;
                        width: 150px;
                    }
                    .label {
                        color: rgba(53, 80, 124, 1);
                        font-size: 14px;
                        margin-right: 10px;
                    }
                    .num {
                        color: rgba(255, 205, 128, 1);
                        font-size: 36px;
                        font-family: DBPM;
                    }
                }
                .tableItemActive {
                    background: #1a3059;
                    .name {
                        color: #d7eaf5;
                    }
                }
            }
        }
        .leftBottom {
            width: 100%;
            margin-top: 10px;
            height: 436px;
            position: relative;
            // background: url("~@image/bigScreen/daily_inspect/leftBg.png")
            //     no-repeat;
            // background-size: 100% 100%;
            .bgPart {
                position: absolute;
                left: 10px;
                top: 0;
                width: 350px;
                height: 433px;
            }
            .leftBottomItem {
                width: 100%;
                height: 124px;
                .leftPart {
                    margin-left: 20px;
                    position: relative;
                    .net {
                        position: absolute;
                        left: 0;
                        top: 43px;
                    }
                    .lineTop {
                        width: 68px;
                        height: 19px;
                        position: absolute;
                        right: 0;
                        top: 20%;
                    }
                    .lineBottom {
                        width: 68px;
                        height: 19px;
                        position: absolute;
                        right: 0;
                        bottom: 20%;
                    }
                    .lineCenter {
                        width: 68px;
                        height: 1px;
                        position: absolute;
                        background: #99ccff;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                    }
                    .leftName {
                        margin-top: 12%;
                        font-size: 16px;
                        color: rgba(168, 200, 255, 1);
                    }
                    .numAndDw {
                        position: relative;
                        top: -20px;
                        .leftNum {
                            margin-right: 10px;
                            font-size: 48px;
                            color: rgba(189, 215, 236, 1);
                            font-family: "DBPM";
                        }
                        .leftDw {
                            font-size: 18px;
                            color: rgba(189, 215, 236, 1);
                        }
                    }
                }
                .rightPart {
                    .rightItem {
                        width: 327px;
                        height: 40px;
                        position: relative;
                        .name {
                            width: calc(50% - 5px);
                            height: 40px;
                            font-size: 18px;
                            color: rgba(168, 200, 255, 1);
                            background: url("~@image/bigScreen/daily_inspect/leftBottom/cell.png")
                                no-repeat;
                            background-size: 100% 100%;
                        }
                        .numPart {
                            width: calc(50% - 5px);
                            height: 40px;
                            background: url("~@image/bigScreen/daily_inspect/leftBottom/cellEmpty.png")
                                no-repeat;
                            background-size: 100% 100%;
                            .num {
                                font-size: 34px;
                                color: rgba(255, 205, 128, 1);
                                font-family: "DBPM";
                            }
                            .dw {
                                font-size: 12px;
                                color: #a8c8ff;
                                position: relative;
                                left: 10px;
                                top: 4px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
