<template>
    <div class="rightPart scrollbar-hidden">
        <BoxTitle
            :title="titleName"
            :backBtn="false"
            :downloadBtn="true"
            @handleDownloadClick="handleDownloadClick"
        ></BoxTitle>
        <div class="jcsb-border">
            <div
                class="jcsb-item"
                :class="currentJcsbId === item.id ? 'current-jcsb' : ''"
                v-for="item in jcsbList"
                :key="'jcsb' + item.id"
                @click="handleJcsbClick(item)"
            >
                {{ item.label }}
            </div>
        </div>
        <saveState
            class="ztbcztPart"
            :stateName="stateName"
            :stateType="stateType"
            :stateTypeList="stateTypeList"
        ></saveState>
        <div class="sssj-module">
            <!-- 实时数据 -->
            <basic-module :text="common.getFyName(fany.sssj)">
                <div slot="basic-module" class="sssj-border">
                    <div
                        class="sssj-item"
                        v-for="item in sssjList"
                        :key="'sssj' + item.id"
                    >
                        <div class="sssj-top">{{ item.label }}</div>
                        <div class="sssj-bottom">
                            <div class="sssj-bottomleft">
                                <span class="sssj-value">{{
                                    item.value | strictNull
                                }}</span>
                                <span>{{ item.dw }}</span>
                            </div>
                            <div class="sssj-bottomright">
                                <span class="sssj-label2">{{
                                    item.label2
                                }}</span>
                                <span class="sssj-value2">{{
                                    item.value2 | strictNull
                                }}</span>
                                <span>{{ item.dw2 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </basic-module>
        </div>
        <div class="jcsj-module">
            <!-- 监测数据 -->
            <basic-module :text="common.getFyName(fany.jcsj)">
                <div slot="basic-module" class="jcsj-bar-chart">
                    <NoMonitor
                        v-show="!isChartShow"
                        text="暂无数据"
                    ></NoMonitor>
                    <lineChart
                        v-show="isChartShow"
                        :chartData="jcsjChartData"
                    ></lineChart>
                    <!-- </div> -->
                    <!-- <div class="jcsj-right">
                        <NoMonitor
                            v-show="!isChartShow2"
                            text="暂无数据"
                        ></NoMonitor>
                        <rosePieChartWind
                            v-show="isChartShow2"
                            :chartData="fbqkChartData"
                        ></rosePieChartWind>
                    </div> -->
                    <div class="bs-datepicker jcsj-search">
                        <el-date-picker
                            v-if="currentJcsjBtnId === '1'"
                            size="mini"
                            style="width: 100px"
                            v-model="nfValue"
                            type="year"
                            value-format="yyyy"
                            popper-class="bs-select-panel"
                            placeholder="选择年"
                            :clearable="false"
                            @change="handleDyxtjNfChanged"
                        >
                        </el-date-picker>
                        <el-date-picker
                            v-if="currentJcsjBtnId === '2'"
                            size="mini"
                            style="width: 120px"
                            v-model="nfValue"
                            type="month"
                            value-format="yyyy-MM"
                            popper-class="bs-select-panel"
                            placeholder="选择月"
                            :clearable="false"
                            @change="handleDyxtjNfChanged"
                        >
                        </el-date-picker>
                        <div
                            :class="
                                currentJcsjBtnId === item.id
                                    ? 'jscj-btn-group-current'
                                    : 'jscj-btn-group'
                            "
                            v-for="item in jcsjBtnList"
                            :key="'jcsjbtn' + item.id"
                            @click="handleJscjBtnClick(item)"
                        >
                            {{ item.label }}
                        </div>
                    </div>
                    <!-- <div class="bs-datepicker jcsj-search2">
                        <el-date-picker
                            class=""
                            size="mini"
                            style="width: 100px"
                            v-model="nfValue2"
                            type="year"
                            value-format="yyyy"
                            popper-class="bs-select-panel"
                            placeholder="选择年"
                            :clearable="false"
                            @change="getFxfsMgt"
                        >
                        </el-date-picker>
                    </div> -->
                </div>
            </basic-module>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapGetters, mapMutations } from "vuex";
import BoxTitle from "@views/big_screen/comp/BoxTitle.vue"; //标题
import NoMonitor from "@views/big_screen/comp/NoMonitor.vue"; //标题
import saveState from "../comp/saveState";
import lineChart from "../comp/line_chart.vue";
import rosePieChartWind from "../comp/rose_pie_chart_wind.vue";

export default {
    name: "",
    components: {
        BoxTitle,
        NoMonitor,
        saveState,
        lineChart,
        rosePieChartWind,
    },
    data() {
        return {
            titleName: "微环境空气质量监测服务",
            stateTypeList: [
                {
                    label: "优",
                    color: "#008000",
                    desc: null,
                },
                {
                    label: "优",
                    color: "#008000",
                    desc: 0,
                },
                {
                    label: "良",
                    color: "#FFFF00",
                    desc: 1,
                },
                {
                    label: "轻度污染",
                    color: "#FFA500",
                    desc: 2,
                },
                {
                    label: "中度污染",
                    color: "#FF0000",
                    desc: 3,
                },
                {
                    label: "重度污染",
                    color: "#800080",
                    desc: 4,
                },
                {
                    label: "严重污染",
                    color: "#A52A2A",
                    desc: 5,
                },
            ],
            sssjList: [
                // {
                //     id: "0",
                //     label: "环境温度",
                //     key: "WD",
                //     value: 0,
                //     dw: "℃",
                //     label2: "平均温度",
                //     key2: "AVGWD",
                //     value2: 0,
                //     dw2: "℃",
                // },
                // {
                //     id: "1",
                //     label: "相对湿度",
                //     key: "SD",
                //     value: 0,
                //     dw: "%RH",
                //     label2: "平均值",
                //     key2: "AVGSD",
                //     value2: 0,
                //     dw2: "%RH",
                // },
                // {
                //     id: "2",
                //     label: "风向风速",
                //     key: "FXFS",
                //     value: 0,
                //     dw: "m/s",
                //     label2: "主导风向",
                //     key2: "AVGFXFS",
                //     value2: 0,
                //     dw2: "",
                // },
                {
                    id: "3",
                    label: "二氧化氮",
                    key: "NO2",
                    value: 0,
                    dw: "ppb",
                    label2: "平均值",
                    key2: "AVGNO2",
                    value2: 0,
                    dw2: "ppb",
                },
                {
                    id: "4",
                    label: "臭氧",
                    key: "O3",
                    value: 0,
                    dw: "ppb",
                    label2: "平均值",
                    key2: "AVGO3",
                    value2: 0,
                    dw2: "ppb",
                },
                {
                    id: "5",
                    label: "一氧化碳",
                    key: "CO",
                    value: 0,
                    dw: "ppb",
                    label2: "平均值",
                    key2: "AVGCO",
                    value2: 0,
                    dw2: "ppb",
                },
                {
                    id: "6",
                    label: "二氧化硫",
                    key: "SO2",
                    value: 0,
                    dw: "ppb",
                    label2: "平均值",
                    key2: "AVGSO2",
                    value2: "0",
                    dw2: "ppb",
                },
                {
                    id: "9",
                    label: "PM10",
                    key: "PM10",
                    value: 0,
                    dw: "μg/m³",
                    label2: "平均值",
                    key2: "AVGPM10",
                    value2: 0,
                    dw2: "μg/m³",
                },

                {
                    id: "8",
                    label: "PM2.5",
                    key: "PM25",
                    value: 0,
                    dw: "μg/m³",
                    label2: "平均值",
                    key2: "AVGPM25",
                    value2: 0,
                    dw2: "μg/m³",
                },
                {
                    id: "7",
                    label: "总悬浮颗粒物",
                    key: "TSP",
                    value: 0,
                    dw: "μg/m³",
                    label2: "平均值",
                    key2: "AVGTSP",
                    value2: 0,
                    dw2: "μg/m³",
                },
                {
                    id: "10",
                    label: "负氧离子",
                    key: "FYLZ",
                    value: 0,
                    dw: "个/cm³",
                    label2: "平均值",
                    key2: "AVGFYLZ",
                    value2: 0,
                    dw2: "个/cm³",
                },

                // {
                //     id: "11",
                //     label: "气压",
                //     key: 'QY',
                //     value: 0,
                //     label2: "平均值",
                //     key2: 'AVGQY',
                //     value2: 0"
                // },
                // {
                //     id: "12",
                //     label: "太阳辐射",
                //     key: 'TYFS',
                //     value: 0,
                //     label2: "平均值",
                //     key2: 'AVGTYFS',
                //     value2: 0"
                // },
            ],
            jcsbList: [
                {
                    id: "f3106f03-824d-11eb-b3ce-000c2977b7fd",
                    label: "芒景上下寨-芒洪古茶林气象环境监测站",
                    jd: 100.019518,
                    wd: 22.156684,
                    level: 16,
                },
                {
                    id: "f3106daa-824d-11eb-b3ce-000c2977b7fd",
                    label: "大平掌古茶林气象环境监测站",
                    jd: 100.013239,
                    wd: 22.197667,
                    level: 16,
                },
            ],
            currentJcsbId: "f3106f03-824d-11eb-b3ce-000c2977b7fd",
            // 总体保存状况
            stateName: "环境空气质量评估",
            stateType: 0,
            nfValue: "",
            nfValue2: this.common.getTimeY(),
            jcsjBtnList: [
                {
                    id: "1",
                    label: "月",
                },
                {
                    id: "2",
                    label: "日",
                },
            ],
            currentJcsjBtnId: "2",
            bgxzXdlj: "",
            fbqkChartData: [], // 古树名木科属统计-分布情况
            jcsjChartData: {
                xAxisData: [],
                legendData: [],
                selectedObj: {},
                seriesData: [],
                xAxisName: "",
                dwArr: [],
            },
            isChartShow: false,
            isChartShow2: false,
        };
    },
    filters: {
        strictNull(value) {
            if (value === null || value === undefined || value === "") {
                return "暂无数据";
            }
            return value;
        },
    },
    computed: {
        ...mapGetters({
            jczlFyNum: TYPES.jczlFyNum,
        }),
    },
    watch: {
        jczlFyNum: {
            immediate: true,
            handler() {
                this.stateName = this.common.getFyName(this.fany.hjkqzlpg);
            },
        },
    },
    mounted() {
        let today = this.common.getTimeYmd();
        if (Number(today.split("-")[2]) <= 3) {
            this.nfValue = this.common.someMonthsAgo(1);
        } else {
            this.nfValue = this.common.getTimeYm();
        }
        this.getWhjKqzlSssj();
        this.getSwjcTjt();
        this.getJlbg();
        this.getFxfsMgt();
    },
    methods: {
        ...mapActions([
            "GetWhjKqzlSssj", // 微环境空气质量实时数据
            "GetWhjKqzlTjt", // 微环境空气质量统计图
            "GetJlbg", // 获取结论报告
            "GetFxfsMgt", // 风向玫瑰图
        ]),
        ...mapMutations({
            SET_STHJDEVICEITEM: TYPES.SET_STHJDEVICEITEM,
        }),
        handleJscjBtnClick(val) {
            if (val.id === this.currentJcsjBtnId) return;
            this.SET_STHJDEVICEITEM(val);
            this.currentJcsjBtnId = val.id;
            if (this.currentJcsjBtnId === "1") {
                this.nfValue = this.common.getTimeY();
            } else {
                this.nfValue = this.common.getTimeYm();
            }
            this.getSwjcTjt();
        },
        async getWhjKqzlSssj() {
            const result = await this.GetWhjKqzlSssj({
                sb: this.currentJcsbId,
            });
            if (result) {
                this.stateType = result.KQZL;
                for (let i in result) {
                    this.sssjList.forEach((item) => {
                        if (item.key === i) {
                            item.value = result[i];
                        }
                        if (item.key2 === i) {
                            item.value2 = result[i];
                        }
                    });
                }
            }
        },
        handleDyxtjNfChanged() {
            this.getSwjcTjt();
        },
        async getSwjcTjt() {
            let sum = 0;
            this.jcsjChartData = {
                xAxisData: [],
                legendData: [],
                selectedObj: {},
                seriesData: [],
                xAxisName: "",
                dwArr: [],
            };
            const result = await this.GetWhjKqzlTjt({
                ny: this.nfValue,
                sbid: this.currentJcsbId,
            });
            if (result && result.length > 0) {
                let xAxisData = [];
                let WD = [];
                let SD = [];
                let NO2 = [];
                let O3 = [];
                let CO = [];
                let SO2 = [];
                let TSP = [];
                let PM25 = [];
                let PM10 = [];
                let FYLZ = [];
                result.forEach((item) => {
                    if (!item.WD) {
                        return;
                    }
                    xAxisData.push(item.RQ);
                    WD.push(item.WD);
                    SD.push(item.SD);
                    NO2.push(item.NO2);
                    O3.push(item.O3);
                    CO.push(item.CO);
                    SO2.push(item.SO2);
                    TSP.push(item.TSP);
                    PM25.push(item.PM25);
                    PM10.push(item.PM10);
                    FYLZ.push(item.FYLZ);
                    sum +=
                        Number(item.WD) +
                        Number(item.SD) +
                        Number(item.NO2) +
                        Number(item.O3) +
                        Number(item.CO) +
                        Number(item.SO2) +
                        Number(item.TSP) +
                        Number(item.PM25) +
                        Number(item.PM10) +
                        Number(item.FYLZ);
                });
                this.jcsjChartData = {
                    xAxisData,
                    legendData: [
                        "PM2.5",
                        "PM10",
                        "环境温度",
                        "相对湿度",
                        "NO₂",
                        "O₃",
                        "CO",
                        "SO₂",
                        "TSP",
                        "负氧离子",
                    ],
                    selectedObj: {
                        环境温度: false,
                        相对湿度: false,
                        "NO₂": false,
                        "O₃": false,
                        CO: false,
                        "SO₂": false,
                        TSP: false,
                        "PM2.5": true,
                        PM10: true,
                        负氧离子: false,
                    },
                    xAxisName: this.currentJcsjBtnId == "1" ? "月" : "日",
                    // dwArr: ['℃','%RH','ppb','ppb','ppb','ppb','μg/m³','μg/m³','μg/m³','个/cm³'],
                    dwArr: {
                        环境温度: "℃",
                        相对湿度: "%RH",
                        "NO₂": "ppb",
                        "O₃": "ppb",
                        CO: "ppb",
                        "SO₂": "ppb",
                        TSP: "μg/m³",
                        "PM2.5": "μg/m³",
                        PM10: "μg/m³",
                        负氧离子: "个/cm³",
                    },
                    seriesData: [
                        {
                            name: "PM2.5",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: PM25,
                        },
                        {
                            name: "PM10",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: PM10,
                        },
                        {
                            name: "环境温度",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: WD,
                        },
                        {
                            name: "相对湿度",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: SD,
                        },
                        {
                            name: "NO₂",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: NO2,
                        },
                        {
                            name: "O₃",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: O3,
                        },
                        {
                            name: "CO",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: CO,
                        },
                        {
                            name: "SO₂",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: SO2,
                        },
                        {
                            name: "TSP",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: TSP,
                        },
                        {
                            name: "负氧离子",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: FYLZ,
                        },
                    ],
                };
            }
            if (sum === 0) {
                this.isChartShow = false;
            } else {
                this.isChartShow = true;
            }
        },
        async getFxfsMgt() {
            let sum = 0;
            this.fbqkChartData = [];
            const result = await this.GetFxfsMgt({
                lx: 0,
                sb: this.currentJcsbId,
                nf: this.nfValue2,
            });
            if (result && result.length > 0) {
                result.forEach((item) => {
                    sum += Number(item.FS) + Number(item.CS);
                });
            }
            if (sum === 0) {
                this.isChartShow2 = false;
            } else {
                this.isChartShow2 = true;
            }
            this.fbqkChartData = [...result];
        },

        async getJlbg() {
            this.bgxzXdlj = "";
            const result = await this.GetJlbg({
                lx: "10",
            });
            if (!result) return;
            this.bgxzXdlj = result.BGLJ;
        },
        // 报告下载
        handleDownloadClick() {
            if (!this.bgxzXdlj) {
                this.common.showMsg("暂无报告", "warning");
                return;
            }
            window.open("http://" + this.bgxzXdlj);
        },
        handleJcsbClick(val) {
            if (val.id === this.currentJcsbId) return;
            this.SET_STHJDEVICEITEM(val);
            this.currentJcsbId = val.id;
            this.getWhjKqzlSssj();
            this.getSwjcTjt();
            this.getFxfsMgt();
        },
    },
};
</script>
<style scoped lang="scss">
.rightPart {
    width: 100%;
    height: 100%;
    padding: 15px 0;
    // 总体保存状态
    .ztbcztPart {
        height: 92px;
        padding: 0 24px;
        margin-top: 15px;
    }
    .jcsb-border {
        display: flex;
        padding-left: 8px;
        .jcsb-item {
            // width: 120px;
            height: 35px;
            line-height: 35px;
            padding: 0 10px;
            border-radius: 5px;
            text-align: center;
            font-size: 16px;
            color: #a8c8ff;
            background: #35507c;
            margin-left: 15px;
            margin-top: 14px;
            cursor: pointer;
        }
        .current-jcsb {
            color: #0b1b30;
            background: #ffcd80;
            cursor: default;
        }
    }
    .sssj-module {
        width: 100%;
        .sssj-border {
            display: flex;
            flex-flow: row wrap;
            .sssj-item {
                width: 320px;
                height: 65px;
                .sssj-top {
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    color: #5673a2;
                    font-weight: bold;
                    font-size: 16px;
                }
                .sssj-bottom {
                    display: flex;
                    width: 100%;
                    height: 35px;
                    line-height: 35px;
                    padding-left: 10px;
                    color: #bdd7ec;
                    background: rgba(26, 48, 89, 0.3);
                    .sssj-bottomleft {
                        width: 48%;
                        font-size: 16px;
                        .sssj-value {
                            font-size: 22px;
                        }
                        .sssj-dw {
                        }
                    }
                    .sssj-bottomright {
                        font-size: 16px;
                        .sssj-label2 {
                            color: #9ab4e3;
                        }
                        .sssj-value2 {
                            padding-left: 10px;
                        }
                        .sssj-dw2 {
                        }
                    }
                }
            }
            .sssj-item:nth-child(odd) {
                margin-right: 15px;
            }
        }
    }
    .jcsj-module {
        width: 100%;
        height: 320px;
        .jcsj-bar-chart {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            padding-top: 30px;
            .jcsj-left {
                position: relative;
                width: 60%;
            }
            .jcsj-right {
                position: relative;
                width: calc(40% - 10px);
                margin-left: 10px;
            }
            .jcsj-search {
                display: flex;
                position: absolute;
                right: 0;
                top: 0px;
                .jscj-btn-group {
                    width: 40px;
                    height: 20px;
                    line-height: 20px;
                    margin-left: 15px;
                    border-radius: 5px;
                    text-align: center;
                    color: #a8c8ff;
                    background: #35507c;
                    cursor: pointer;
                }
                .jscj-btn-group-current {
                    width: 40px;
                    height: 20px;
                    line-height: 20px;
                    margin-left: 15px;
                    border-radius: 5px;
                    text-align: center;
                    color: #040e1e;
                    background: #ffcd80;
                }
            }
            .jcsj-search2 {
                position: absolute;
                right: 120px;
                top: 0px;
                .jscj-btn-group {
                    width: 40px;
                    height: 20px;
                    line-height: 20px;
                    margin-left: 15px;
                    border-radius: 5px;
                    text-align: center;
                    color: #a8c8ff;
                    background: #35507c;
                    cursor: pointer;
                }
                .jscj-btn-group-current {
                    width: 40px;
                    height: 20px;
                    line-height: 20px;
                    margin-left: 15px;
                    border-radius: 5px;
                    text-align: center;
                    color: #040e1e;
                    background: #ffcd80;
                }
            }
        }
    }
}
</style>
