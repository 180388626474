<template>
    <div class="legendCom flex scrollbar-hidden">
        <div class="title flex-y-center">图例</div>
        <div class="flex flex-wrap list">
            <!-- 传统村落-古茶林-分隔防护林 -->
            <div
                v-for="(item, index) in comLegendList"
                :key="`${index}_com`"
                class="flex-y-center legendItem"
            >
                <img
                    :src="item.src"
                    class="img"
                    :style="{
                        width: getCtclFgfhlGclWidth(),
                        height: getCtclFgfhlGclWidth(),
                    }"
                />
                <div class="name">{{ item.name }}</div>
            </div>
            <template v-if="screenName != 'ecological_environment'">
                <div
                    v-for="(item, index) in listAry"
                    :key="`${index}inner`"
                    class="flex-y-center"
                >
                    <div v-if="item.state" class="legendItem flex-y-center">
                        <img
                            :src="item.src"
                            class="img"
                            :style="{
                                width: item.width ? item.width : '',
                                height: item.height ? item.height : '',
                            }"
                        />
                        <div class="name">{{ item.name }}</div>
                    </div>
                </div>
            </template>
            <template v-else>
                <!-- 生态环境  -->
                <div
                    v-for="(item, index) in listAry"
                    :key="index"
                    class="flex-y-center"
                >
                    <div v-if="item.state" class="legendItem">
                        <div v-if="item.mulList" class="flex-y-center">
                            <div
                                v-for="(itemInner, indexInner) in item.mulList"
                                :key="indexInner"
                                class="flex-y-center legendItem pointer"
                            >
                                <div
                                    class="img"
                                    :style="{
                                        background: itemInner.bgColor,
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: item.radius,
                                    }"
                                ></div>
                                <div class="name">{{ itemInner.name }}</div>
                            </div>
                        </div>
                        <div v-else class="flex-y-center legendItem">
                            <div
                                class="img"
                                :style="{
                                    background: item.bgColor,
                                    width: '12px',
                                    height: '12px',
                                    borderRadius: item.radius,
                                }"
                            ></div>
                            <div class="name">{{ item.name }}</div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import OnOff from "@views/big_screen/comp/OnOff.vue"; //开关
import comMixin from "@views/big_screen/com_mixin.js";
export default {
    components: {
        OnOff,
    },
    mixins: [comMixin],
    name: "",
    data() {
        return {};
    },
    computed: {
        ...mapGetters({}),
    },
    props: {
        listAry: {
            type: Array,
            default() {
                return [];
            },
        },
        keyValue: {
            type: String,
            default() {
                return "";
            },
        },
        screenName: {
            type: String,
            default() {
                return "";
            },
        },
    },
    watch: {},
    mounted() {},
    methods: {
        ...mapActions([]),
        ...mapMutations({}),
        // 传统村落-古茶林-分隔防护林 图片宽度
        getCtclFgfhlGclWidth() {
            let width = "";
            switch (this.screenName) {
                case "ontological_disease": //本体病害
                case "protect_project": //保护工程
                case "build_control": //建设控制
                    width = "20px";
                    break;
                case "ecological_environment": //生态环境
                    width = "12px";
                    break;
                default:
                    width = "24px";
                    break;
            }
            return width;
        },
    },
};
</script>
<style scoped lang="scss">
// 图例
.legendCom {
    min-height: 56px;
    max-height: 100px;
    max-width: 900px;
    .title {
        width: 20px;
        height: 56px;
        line-height: 23px;
        background: rgba(4, 28, 67, 0.8);
        font-weight: 400;
        font-size: 12px;
        color: #d8eaf6;
        text-align: center;
        padding-top: 5px;
    }
    .list {
        padding-left: 10px;
        background: rgba(4, 28, 67, 0.6);
        .legendItem {
            margin-right: 10px;
            .img {
                margin-right: 5px;
            }
            .name {
                font-weight: 400;
                font-size: 12px;
                color: #d8eaf6;
            }
        }
    }
}
</style>
