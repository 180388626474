var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"leftMenu",class:[_vm.jczlFyNum == 0 ? '' : 'leftEnglish']},[_c('img',{staticClass:"bg",attrs:{"src":require("@image/bigScreen/icon/sideBarBg.png")}}),_c('div',{staticClass:"topDesc"},[_c('img',{staticClass:"title animated bounceInLeft",style:({ animationDelay: 0.2 + 0 * 0.2 + 's' }),attrs:{"src":require("@image/bigScreen/icon/title.png")}}),_c('div',{staticClass:"desc animated bounceInLeft pointer",on:{"click":function($event){return _vm.changeScale()}}},[(_vm.jczlFyNum == 0)?_c('div',[_vm._v("遗产监测预警平台")]):_c('div',{staticClass:"en"},[_c('div',{staticClass:"en-item"},[_vm._v("Heritage monitoring")]),_c('div',{staticClass:"en-item"},[_vm._v("and early warning platform")])])]),_c('img',{staticClass:"logo animated bounceInLeft",style:({ animationDelay: 0.2 + 0 * 0.2 + 's' }),attrs:{"src":require("@image/bigScreen/icon/logo.png")}})]),_c('div',{staticClass:"menuPart"},_vm._l((_vm.menuList),function(item,index){return _c('div',{key:("menu" + index),staticClass:"menuItem pointer animated bounceInLeft",class:[
                _vm.leftActiveNum === index || _vm.rightActiveNum === index
                    ? 'menuItemActive'
                    : '' ],style:({ animationDelay: 0.2 + index * 0.2 + 's' }),on:{"mouseenter":function($event){_vm.hoverActiveNum = index},"mouseleave":function($event){_vm.hoverActiveNum = null},"click":function($event){return _vm.nameActiveItem(index)}}},[_c('div',{staticClass:"pageBack",class:[("menuItem_" + index)]},[_c('img',{staticClass:"bgImg",attrs:{"src":require("@image/bigScreen/icon/menuActive.png")}}),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.common.getFyName(item))+" ")])]),_c('div',{staticClass:"pageFront",class:[("menuItem_" + index)]},[_c('img',{staticClass:"bgImg",attrs:{"src":require("@image/bigScreen/icon/menuDefault.png")}}),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.common.getFyName(item))+" ")])]),(
                    (_vm.hoverActiveNum === index ||
                        _vm.leftActiveNum === index ||
                        _vm.rightActiveNum === index) &&
                    index != 0 &&
                    index != _vm.menuList.length - 1
                )?_c('div',{staticClass:"rectangle flex-y-center"},[_c('div',{staticClass:"rectItem flex-both-center",class:[
                        _vm.leftActiveNum === index
                            ? 'rectItemActive'
                            : 'rectItemDefault' ],on:{"click":function($event){$event.stopPropagation();return _vm.getLeftActiveItem(index)}}},[_vm._v(" L ")]),_c('div',{staticClass:"right rectItem flex-both-center",class:[
                        _vm.rightActiveNum === index
                            ? 'rectItemActive'
                            : 'rectItemDefault' ],on:{"click":function($event){$event.stopPropagation();return _vm.getRightActiveItem(index)}}},[_vm._v(" R ")])]):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }