<template>
    <div class="sthj-line-chart" :id="chartId"></div>
</template>
<script>
export default {
    name: "",
    data() {
        return {
            chartId: "sthjLineChart" + Math.random(),
            myChart: null,
        };
    },
    props: {
        chartData: {
            type: Object,
            default: () => {},
        },
    },
    mounted() {
        // this.initChart();
        $(window).resize(() => {
            this.initChart();
        });
    },
    watch: {
        chartData: {
            handler(newVal, oldVal) {
                this.initChart();
            },
        },
    },
    methods: {
        initChart() {
            // 基于准备好的dom，初始化echarts实例
            this.myChart = this.$echarts.dispose(
                document.getElementById(this.chartId)
            );
            this.myChart = this.$echarts.init(
                document.getElementById(this.chartId)
            );

            // 指定图表的配置项和数据
            let option = {
                grid: {
                    top: 35,
                    right: 40,
                    bottom: 30,
                    left: 50,
                },
                tooltip: {
                    trigger: "item",
                    formatter: "{a0}年<br />{b0}",
                    // trigger: 'axis',
                    // axisPointer: 'none',
                    // axis: 'x',
                    // formatter: (params)=>{
                    //     var relVal = '';
                    //     for (var i = 0, l = params.length; i < l; i++) {
                    //         relVal = params[i].marker + params[i].name;
                    //     }
                    //     return relVal;
                    // }
                },
                legend: {
                    show: true,
                    right: 0,
                    textStyle: {
                        color: "#C4D6E1",
                    },
                    icon: "rect",
                    itemWidth: 15,
                    itemHeight: 5,
                    itemGap: 37,
                    data: this.chartData.legendData,
                },
                color: [
                    "#E76452",
                    "#5AD9A6",
                    "#5AC9D9",
                    "#5A5DD9",
                    "#7E5AD9",
                    "#D95A93",
                    "#D9AE5A",
                ],
                xAxis: [
                    {
                        type: "value",
                        interval: 1,
                        min: 1,
                        max: 12,
                        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                        axisLabel: {
                            color: "#A8C8FF",
                            fontSize: 16,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#35507C",
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(53,80,124,.5)",
                            },
                        },
                        name: "月",
                        nameTextStyle: {
                            color: "#A8C8FF",
                        },
                    },
                    {
                        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                        position: "top",
                        axisLabel: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#35507C",
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "rgba(53,80,124,.5)",
                            },
                        },
                    },
                ],
                yAxis: {
                    type: "category",
                    axisLabel: {
                        color: "#A8C8FF",
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#35507C",
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(53,80,124,.5)",
                        },
                    },
                    data: [
                        "发芽期",
                        "展叶期",
                        "开花期",
                        "果期",
                        "落叶期",
                        "休眠期",
                    ],
                },
                series: this.chartData.seriesData,
                // series: [
                //     {
                //         name: '2020',
                //         type: 'line',
                //         smooth: true,
                //         showSymbol: true,
                //         showAllSymbol: true,
                //         symbol: 'rect',
                //         symbolSize: 8,
                //         lineStyle: {
                //             width: 3
                //         },
                //         data: [
                //             [1.5,'发芽期'],
                //             [3.9,'展叶期'],
                //             [6.3,'开花期'],
                //             [8.5,'果期'],
                //             [10.5,'落叶期'],
                //             [11.5,'休眠期']
                //         ],
                //     },
                //     {
                //         name: '2021',
                //         type: 'line',
                //         smooth: true,
                //         showSymbol: true,
                //         showAllSymbol: true,
                //         symbol: 'rect',
                //         symbolSize: 8,
                //         lineStyle: {
                //             width: 3
                //         },
                //         data: [
                //             [1.2,'发芽期'],
                //             [4.0,'展叶期'],
                //             [6.5,'开花期'],
                //             [8.8,'果期'],
                //             [10.6,'落叶期'],
                //             [12,'休眠期']
                //         ]
                //     }
                // ]
            };

            // 使用刚指定的配置项和数据显示图表。
            this.myChart.setOption(option);
        },
    },
};
</script>
<style lang="scss" scoped>
.sthj-line-chart {
    width: 100%;
    height: 100%;
}
</style>