<template>
    <div class="screenCtn">
        <!-- 左侧面板 -->
        <div class="topView flex-between">
            <div style="width: 689px; height: 100%">
                <Rcxc></Rcxc>
            </div>
            <div style="width: 1194px; height: 100%">
                <Yj></Yj>
            </div>
            <div style="width: 436px; height: 100%">
                <Btbh></Btbh>
            </div>
            <div style="width: 646px; height: 100%">
                <Zrhj></Zrhj>
            </div>
        </div>
        <!-- 右侧面板 -->
        <div class="bottomView flex-between">
            <div style="width: 453px; height: 100%">
                <!-- <Xmgc></Xmgc> -->
                <Ytfz></Ytfz>
            </div>
            <div style="width: 703px; height: 100%">
                <Shhj ref="shhj"></Shhj>
            </div>
            <div style="width: 725px; height: 100%">
                <Lyyk ref="lyyk"></Lyyk>
            </div>
            <div style="width: 437px; height: 100%">
                <Jgjc></Jgjc>
            </div>
            <div style="width: 626.5px; height: 100%">
                <Fhjc></Fhjc>
            </div>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapMutations, mapGetters } from "vuex";
import Yj from "./yj/index.vue"; // 预警
import Rcxc from "./rcxc/index"; //日常巡查
import Btbh from "./btbh/index"; //本体病害
import Zrhj from "./zrhj/index"; //自然环境
import Ytfz from "./ytfz/index"; //业态发展
// import Xmgc from "./xmgc/index.vue"; // 项目工程
import Shhj from "./shhj/index"; //社会环境
import Lyyk from "./lyyk/index"; //旅游游客
import Jgjc from "./jgjc/index"; //景观监测
import Fhjc from "./fhjc/index"; //防火监测
export default {
    name: "",
    components: {
        Yj,
        Rcxc,
        Btbh,
        Zrhj,
        Ytfz,
        // Xmgc,
        Shhj,
        Lyyk,
        Jgjc,
        Fhjc,
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({}),
    },
    watch: {},
    mounted() {
        window.onresize = () => {
            this.$refs.shhj.initEchart();
            this.$refs.lyyk.initEchartLyyk();
        };
    },
    methods: {
        ...mapMutations({}),
    },
};
</script>
<style scoped lang="scss">
/* 中间主体内容 */
.screenCtn {
    position: relative;
    height: 100%;
    /* 上边部分 */
    .topView {
        position: relative;
        width: 100%;
        height: calc(50% - 19px);
        // border: 1px solid #fff;
    }

    /* 下边部分 */
    .bottomView {
        position: relative;
        height: calc(50% - 19px);
        margin-top: 38px;
        // border: 1px solid #fff;
    }
}
</style>
