<template>
    <div class="title">
        <div style="flex: 1; border-bottom: 1px #84acc2 solid"></div>
        <div class="titleCenter flex-y-center" :title="title">
            <img src="@image/bigScreen/titleBgleft.png" />
            <span class="titleContent flex-1 ellipsis">{{ title }}</span>
            <img src="@image/bigScreen/titleBgright.png" />
        </div>
        <div style="flex: 1; border-bottom: 1px #84acc2 solid"></div>
        <i
            v-if="backBtn"
            class="titleIcon pointer el-icon-back"
            alt="标题按钮"
            @click="handleClose"
        >
        </i>
        <div
            v-if="downloadBtn"
            class="report-download"
            @click="handleDownloadClick"
        >
            <div class="download-img"></div>
            <div class="download-label">报告下载</div>
        </div>
    </div>
</template>
<script>
export default {
    name: "",
    components: {},
    props: {
        title: {
            type: String,
            default: "",
        },
        backBtn: {
            type: Boolean,
            default: true,
        },
        downloadBtn: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    mounted() {},
    methods: {
        // 关闭面板
        handleClose() {
            this.$emit("handleClose");
        },
        handleDownloadClick() {
            this.$emit("handleDownloadClick");
        },
    },
};
</script>
<style scoped lang="scss">
.title {
    width: 100%;
    height: 36px;
    line-height: 35px;
    display: flex;
    position: relative;
    padding: 0 24px;
    // 标题内容
    .titleCenter {
        width: fit-content;
        height: 100%;
        margin: 0 auto;
        font-size: 18px;
        color: rgb(210, 234, 246);
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .titleContent {
            padding: 0 30px;
            min-width: 200px;
            display: inline-block;
            border-top: 1px solid #84acc2;
            background: linear-gradient(
                rgba(48, 80, 97, 1),
                rgba(48, 80, 97, 0)
            );
        }
    }
    // 标题按钮
    .titleIcon {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        color: #ffffff;
        font-size: 24px;
    }
    // 下载报告按钮
    .report-download {
        position: absolute;
        top: 10px;
        right: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 94px;
        height: 20px;
        background: #35507c;
        border-radius: 5px;
        cursor: pointer;
        .download-img {
            width: 14px;
            height: 14px;
            background: url("~@image/bigScreen/ecological_environment/Right/reportDownload.png")
                no-repeat;
        }
        .download-label {
            margin-left: 8px;
            color: #a8c8ff;
        }
    }
}
</style>
