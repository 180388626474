<template>
    <div
        class="jcsj-chart1"
        id="gclch"
        style="{width: '331.5', height: '240px'}"
    ></div>
</template>
<script>
import echarts from "echarts";
export default {
    name: "gclch",
    data() {
        return {};
    },
    watch: {
        chartData: {
            immediate: true,
            handler() {
                this.$nextTick(() => {
                    this.fetchData();
                });
            },
        },
    },
    mounted() {},
    methods: {
        fetchData() {
            var myChart = echarts.init(document.getElementById("gclch"));
            let imgSrc = require("@image/bigScreen/ecological_environment/bug.png");
            var colorList = [
                "#324F7D",
                "#9BB5E4",
                "#FFCE81",
                "#FFAC5B",
                "#BDFEA2",
                "#65D56B",
            ];
            var legendData = [
                "同翅目",
                "鳞翅目",
                "缨翅目",
                "双翅目",
                "半翅目",
                "蜱螨目",
            ];
            var seriesData = [
                {
                    value: 41,
                    name: "同翅目",
                },
                {
                    value: 29,
                    name: "鳞翅目",
                },
                {
                    value: 12,
                    name: "缨翅目",
                },
                {
                    value: 6,
                    name: "双翅目",
                },
                {
                    value: 6,
                    name: "半翅目",
                },
                {
                    value: 6,
                    name: "蜱螨目",
                },
            ];
            var option = {
                legend: {
                    //图例
                    type: "plain",
                    orient: "vertical",
                    icon: "rect",
                    y: "center",
                    right: 10,
                    itemWidth: 15,
                    itemHeight: 15,
                    itemGap: 17,
                    textStyle: {
                        color: "#BDD7EC",
                    },
                    data: legendData,
                },
                color: colorList,
                graphic: {
                    elements: [
                        {
                            type: "image",
                            z: 6,
                            style: {
                                image: imgSrc,
                                width: 30,
                                shadowBlur: 0,
                                shadowColor: "#000",
                                shadowOffsetX: "1",
                                shadowOffsetY: "1",
                            },
                            left: "28%",
                            top: "43%",
                            position: [100, 100],
                        },
                    ],
                },
                title: [
                    {
                        text: "",
                        textAlign: "center",
                        x: "34%",
                        y: "48%",
                        textStyle: {
                            color: "#8c949a",
                            fontSize: 20,
                            fontWeight: "normal",
                        },
                    },
                ],
                series: [
                    {
                        //主圆环
                        name: "title",
                        type: "pie",
                        radius: ["30%", "80%"],
                        center: ["33%", "50%"],
                        label: {
                            z: 2,
                            normal: {
                                position: "inside",
                                formatter: "{d}%",
                                function(params) {
                                    return (
                                        params.percent +
                                        "%" +
                                        "\n" +
                                        "\n" +
                                        params.name
                                    );
                                },
                                textStyle: {
                                    color: "#FFFFFF",
                                },
                            },
                        },
                        itemStyle: {
                            normal: {
                                borderColor: "#2b3d59",
                                borderWidth: 3,
                            },
                        },
                        data: seriesData,
                    },
                    {
                        //圆环 虫子
                        type: "pie",
                        radius: ["25%", "0%"],
                        center: ["33%", "50%"],
                        z: 1,
                        data: [
                            {
                                hoverOffset: 1,
                                value: 100,
                                itemStyle: {
                                    color: "rgba(155,181,228,0.8)",
                                },
                                label: {
                                    show: false,
                                },
                                labelLine: {
                                    normal: {
                                        smooth: true,
                                        lineStyle: {
                                            width: 0,
                                        },
                                    },
                                },
                                hoverAnimation: false,
                            },
                        ],
                    },
                    {
                        //圆环 阴影部分
                        radius: ["30%", "75%"],
                        center: ["33%", "50%"],
                        type: "pie",
                        label: {
                            normal: {
                                show: false,
                            },
                            emphasis: {
                                show: false,
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                            emphasis: {
                                show: false,
                            },
                        },
                        animation: false,
                        tooltip: {
                            show: false,
                        },
                        itemStyle: {
                            normal: {
                                color: "rgba(3,20,44,0.5)",
                            },
                        },
                        data: [
                            {
                                value: 1,
                            },
                        ],
                    },
                ],
            };
            myChart.setOption(option);
        },
    },
};
</script>
<style lang="scss" scoped>
.jcsj-chart1 {
    width: 100%;
    height: 100%;
}
</style>