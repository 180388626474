// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@image/bigScreen/icon/titleBg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".cqxxPart[data-v-15b954b8]{width:100%}.cqxxPart .cqxxCtn[data-v-15b954b8]{width:100%;height:100%}.cqxxPart .cqxxCtn .tableList[data-v-15b954b8]{width:100%;height:40px}.cqxxPart .cqxxCtn .tableList .tableItem[data-v-15b954b8]{height:100%}.cqxxPart .cqxxCtn .tableList .tableItem .name[data-v-15b954b8]{width:102px;height:100%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;font-size:18px;color:#a8c8ff;margin-right:5px}.cqxxPart .cqxxCtn .tableList .tableItem .numPart[data-v-15b954b8]{width:104px;height:100%;border:1px solid #35507c}.cqxxPart .cqxxCtn .tableList .tableItem .numPart .num[data-v-15b954b8]{font-size:50px;font-family:DBPM}.cqxxPart .cqxxCtn .tableList .tableItem .numPart .dw[data-v-15b954b8]{color:#d6eeff;font-size:14px;position:relative;left:4px;top:2px}.cqxxPart .cqxxCtn .cqxxCtn[data-v-15b954b8]{min-height:110px;margin-bottom:10px}", ""]);
// Exports
module.exports = exports;
