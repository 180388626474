<template>
    <div class="middlePart scrollbar-hidden">
        <!-- 茶叶加工技艺 -->
        <basic-module :text="common.getFyName(fany.cyjgjy)">
            <div slot="basic-module" class="cyjgjyCtn">
                <div class="processing-technology">
                    <div class="title">
                        <div
                            v-for="(item, index) in technologyList"
                            :key="index"
                            class="technology pointer"
                            @click="getTechnologyDetail(item,index)"
                        >
                            <img
                                v-if="index == activeNum"
                                :src="require(`@image/bigScreen/tea_culture/technology_bg_selected.png`)"
                                class="bg"
                            />
                            <img
                                v-else
                                :src="require(`@image/bigScreen/tea_culture/technology_bg.png`)"
                                class="bg"
                            />
                            <div v-if="item.JGGY" class="type">{{item.JGGY}}</div>
                            <sn-no-data
                                v-else
                                :noDataState="true"
                            ></sn-no-data>
                        </div>
                    </div>
                    <div class="content">
                        {{text}}
                        <sn-no-data
                            v-if="!text"
                            :noDataState="true"
                            style="top: -15%;"
                        ></sn-no-data>
                    </div>
                </div>
                <div class="tea-processing-photo scrollbar-hidden">
                    <div class="box">
                        <carousel-3d
                            v-if="teaPhotoList.length > 0"
                            :autoplay="true"
                            :loop="true"
                            :autoplayTimeout="10000"
                            perspective="140"
                            :display="5"
                            :animationSpeed="1000"
                            :width="280"
                            :height="210"
                            :arrow="teaPhotoList.length > 1 ? 'hover' : 'never'"
                            controlVisible
                            :controlsHeight="60"
                            space="400"
                            bias="right"
                        >
                            <slide
                                v-for="(item,i) in teaPhotoList"
                                :key="i"
                                :index="i"
                            >
                                <template slot-scope="obj">
                                    <img 
                                        :src="item.CCLJ" 
                                        class="pointer"
                                        style="height: 100%; padding: 20px 1px 20px 5px;" 
                                        @click="imgClick(obj, item)"
                                    />
                                </template>
                            </slide>
                        </carousel-3d>
                        <sn-no-data
                            v-if="!teaPhotoList.length"
                            style="height: 250px; width: 250px; left: 38%; top: 55%;"
                        ></sn-no-data>
                    </div>
                </div>
                <div class="tea-type scrollbar-hidden">
                    <div
                        v-for="item in teaTypeList"
                        :key="item.CODE"
                        class="typeItem pointer"
                        :class="[item.CODE == typeNum?'typeItemActive':'']"
                        @click="getTeaDetail(item)"
                    >
                        <img
                            v-if="item.CODE == typeNum"
                            :src="item.srcActive"
                            class="bg"
                        />
                        <img
                            :src="item.src"
                            class="bg"
                        />
                        <div class="name">{{item.LABEL}}</div>
                    </div>
                </div>
                <div class="bottom-background"></div>
                <ViewerAlert ref="myImgList" :imgList="imgList"></ViewerAlert>
            </div>
        </basic-module>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { Carousel3d, Slide } from 'vue-carousel-3d';
import ViewerAlert from "@comp/ViewerAlert.vue"; //大图查看
export default {
    name: "",
    components: {
        Carousel3d,
        Slide,
        ViewerAlert,
    },
    data() {
        return {
            activeNum: "0", //加工技艺
            typeNum: "1", //茶叶种类
            technologyList: [],
            text: "", //工艺说明
            technologyID: "", //工艺id
            teaPhotoList: [],
            teaTypeList: [],
            imgList: [],
        };
    },
    props: {},
    computed: {
        ...mapGetters({
            
        }),
    },
    watch: {
        typeNum: {
            immediate: true,
            handler() {
                this.getWorkPart();
            },
        },
        technologyID: {
            immediate: true,
            handler() {
                if(this.technologyID) {
                    this.GetTeaWorkPicList();
                }
            },
        }
    },
    mounted() {
        this.getEnum();
    },
    methods: {
        ...mapActions([
            "getAllEnumInfobytableID",
            "GetTeaWork",
            "GetTeaWorkPic",
        ]),
        ...mapMutations({
            
        }),
        getTechnologyDetail(item, index) {
            this.activeNum = index;
            this.text = item.GYSM;
            this.technologyID = item.ID;
        },
        getTeaDetail(item) {
            this.typeNum = item.CODE;
            this.activeNum = "0";
        },
        imgClick(obj, item, index) {
            let ary = [];
            let list = [];
            list.push(item);
            list.map((ele) => {
                let obj = {
                    src: ele.CCLJ,
                    thumbnail: this.common.getThumbnailImg(ele.CCLJ),
                };
                ary.push(obj);
            });
            this.imgList = Array.from(ary);
            if (this.imgList.length) {
                this.$refs.myImgList.showViewer(index);
            }
        },
        async getEnum() {
            this.teaTypeList = [];
            let result = await this.getAllEnumInfobytableID({
                bid: "500104"
            });
            let enumList = result || [];
            enumList.forEach((v) => {
                if (v.COLUMNID === "CYLX") {
                    let array = [];
                    let obj = {};
                    array = v.dataItems;
                    array.forEach((ele) => {
                        obj = ele;
                        let index = array.indexOf(ele);
                        if(index == array.length - 1) {
                            obj.src = require(`@image/bigScreen/tea_culture/tea_type_right.png`);
                            obj.srcActive = require(`@image/bigScreen/tea_culture/tea_type_right_selected.png`);
                        } else if(index == 0) {
                            obj.src = require(`@image/bigScreen/tea_culture/tea_type_left.png`);
                            obj.srcActive = require(`@image/bigScreen/tea_culture/tea_type_left_selected.png`);
                        } else {
                            obj.src = require(`@image/bigScreen/tea_culture/tea_type.png`);
                            obj.srcActive = require(`@image/bigScreen/tea_culture/tea_type_selected.png`);
                        }
                        this.teaTypeList.push(obj);
                    });
                }
            });
        },
        async getWorkPart() {
            let result = await this.GetTeaWork({
                cylx: this.typeNum,
            });
            if(result.ISSUCCESS) {
                this.technologyList = result.RESULTVALUE;
                this.technologyList.forEach((ele) => {
                    if(this.activeNum && this.activeNum == this.technologyList.indexOf(ele)) {
                        this.text = ele.GYSM;
                        this.technologyID = ele.ID;
                        if(!ele.GYSM) {
                            this.text = null;
                        }
                    }
                })
            }
        },
        async GetTeaWorkPicList() {
            this.teaPhotoList = [];
            let result = await this.GetTeaWorkPic({
                id: this.technologyID,
                pageSize: "",
                pageIndex: "",
            });
            if(result.ISSUCCESS) {
                this.teaPhotoList = result.RESULTVALUE.DATA;
            }
        }
    },
}
</script>
<style scoped lang="scss">
.middlePart {
    width: 100%;
    height: 100%;
    padding: 15px 0;
    overflow-x: auto;
    .cyjgjyCtn {
        width: 100%;
        height: 100%;
        background: url("~@image/bigScreen/tea_culture/Tea_Processing_bg.png");
        background-size: 100% 100%;
        .processing-technology {
            width: 100%;
            height: 49%;
            .title {
                width: 80%;
                height: 100px;
                margin: 20px 10% 20px 10%;
                display: flex;
                justify-content: space-around;
                .technology {
                    width: 77px;
                    height: 90px;
                    margin: 0 auto;
                    padding-top: 10px;
                    position: relative;
                    box-sizing: border-box;
                    text-align: center;
                    .bg {
                        position: absolute;
                        top: 55%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                    .type {
                        position: absolute;
                        top: 56%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 60px;
                        height: 60px;
                        line-height: 40px;
                        border-radius: 50%;
                        color: #D7EAF5;
                        text-align: center;
                        font-size: 16px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        word-break: break-all;
                        font-weight: bold;
                    }
                }
            }
            .content {
                width: 80%;
                max-height: 40%;
                margin: 20px 10% 20px 10%;
                color: #D7EAF5;
                line-height: 30px;
                font-size: 16px;
                font-weight: 400;
            }
        }
        .tea-processing-photo {
            width: calc(100% - 20px);
            height: calc(51% - 100px);
            margin: 0 10px 20px 10px;
            .carousel-3d-container {
                margin: 0 auto;
            }
            .carousel-3d-slide {
                visibility: visible;
                opacity: 1;
                background:url("~@image/bigScreen/tea_culture/photo_box.png");
                background-size: 100% 100%;
                border: 0px;
            }
            .el-carousel_item h3 {
                color: #475669;
                font-size: 14px;
                opacity: 0.75;
                line-height: 200px;
                margin: 0;
            }
            .el-carousel_item:nth-child(2n) {
                background-color: #99a9bf;
            }
            .el-carousel_item:nth-child(2n+1) {
                background-color: #d3dce6;
            }
        }
        .tea-type {
            width: 80%;
            margin: 35px 10% 0 10%;
            display: flex;
            overflow-x: auto;
            .typeItem {
                width: 20%;
                height: 40px;
                font-size: 20px;
                position: relative;
                text-align: center;
                z-index: 2;
                .bg {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 55%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                .name {
                    position: absolute;
                    top: 56%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 152px;
                    height: 40px;
                    line-height: 35px;
                    color: #E2EFF7;
                    text-align: center;
                    font-size: 16px;
                }
            }
            .typeItemActive {
                .name {
                    color: #FCD9BB;
                }
            }
        }
        .bottom-background {
            width: 100%;
            height: 40px;
            position: absolute;
            bottom: -12px;
            background: url("~@image/bigScreen/tea_culture/tea_type_bg.png");
            background-size: 100% 100%;
            z-index:1;
        }
    }
}
</style>
