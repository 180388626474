<template>
    <div class="screenItem">
        <!-- 本体病害 -->
        <BasicMonitor :text="common.getFyName(fany.btbh)">
            <div slot="basic-module" class="screenCtn">
                <div class="topPart flex-between">
                    <div class="echartWarp flex-1 flex-both-center">
                        <div class="rectangleBg"></div>
                        <img
                            src="@image/bigScreen/monitor_all/btbh/circle.png"
                            class="imgRotate"
                        />
                        <div
                            class="infoPart flex-y-center"
                            :style="{ animationDelay: 0.2 + 0 * 0.2 + 's' }"
                        >
                            <div class="label">病害状态评估</div>
                            <div
                                class="num"
                                :style="{
                                    color: common.getYcysStateColor(bHstate),
                                }"
                            >
                                {{ bHstate }}
                            </div>
                        </div>
                    </div>
                    <div class="descPart">
                        <div class="descItem">
                            <div class="label">监测病害</div>
                            <div class="num">
                                <span class="numWz">{{ jcbhNum }}</span>
                            </div>
                        </div>
                        <div class="descItem">
                            <div class="label">涉及遗产</div>
                            <div class="num">
                                <span class="numWz">{{ sjycNum }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottomPart flex">
                    <div class="ycsjPart flex-1">
                        <div class="label">病虫害监测</div>
                        <!-- <div class="desc" v-if="jczlYcysItem || jczlYczcItem">
                            暂无数据
                        </div>
                        <div class="desc" v-else>
                            <span>病虫害面积</span>
                            <span>{{ bchmjNum }}m²，</span>
                            <span>占比</span>
                            <span>{{ bchmjZbNum }}%</span>
                        </div> -->

                        <div class="desc">
                            物联网自动虫情测报灯，遥感监控，现场调查
                        </div>
                    </div>
                    <!--
                        <div class="echartPart">
                         <div class="halfCircle"></div>
                        <div class="noData" v-if="jczlYcysItem || jczlYczcItem">
                            暂无数据
                        </div>
                        <div id="btbhEchart"></div> 
                    </div>
                    -->
                </div>
            </div>
        </BasicMonitor>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import BasicMonitor from "@views/big_screen/monitor_all/comp/BasicModule";
export default {
    components: {
        BasicMonitor,
    },
    name: "",
    data() {
        return {
            bHstate: "可控", // 设备状态
            jcbhNum: 0,
            sjycNum: 0,
            bchmjNum: 0, //病虫害面积
            bchmjZbNum: "", //病虫害面积占比
        };
    },
    computed: {
        ...mapGetters({
            jczlYcysItem: TYPES.jczlYcysItem,
            jczlYczcItem: TYPES.jczlYczcItem,
        }),
    },
    props: {},
    watch: {
        jczlYcysItem: {
            immediate: false,
            handler() {
                // if (!this.jczlYcysItem && !this.jczlYczcItem) {
                //     this.$nextTick(() => {
                //         this.initEchart();
                //     });
                // } else {
                //     this.echart = this.$echarts.dispose(
                //         document.getElementById("btbhEchart")
                //     );
                // }
            },
        },
        jczlYczcItem: {
            immediate: false,
            handler() {
                // if (!this.jczlYcysItem && !this.jczlYczcItem) {
                //     this.$nextTick(() => {
                //         this.initEchart();
                //     });
                // } else {
                //     this.echart = this.$echarts.dispose(
                //         document.getElementById("btbhEchart")
                //     );
                // }
            },
        },
    },
    mounted() {
        this.getMainInfo();
    },
    methods: {
        ...mapActions(["GetJCZLBTBH", "GetBchjcData"]),
        ...mapMutations({}),
        async getMainInfo() {
            let result = await this.GetJCZLBTBH({
                // ycysbm: this.jczlYcysItem ? this.jczlYcysItem.BM : "",
                // ycysfl2: this.jczlYczcItem ? this.jczlYczcItem.YCYSFL_2 : "",
            });
            this.bHstate = result.NAME; // 设备状态
            this.jcbhNum = result.JCBH;
            this.sjycNum = result.SJYC;
        },
        async getYcsjInfo() {
            let result = await this.GetBchjcData({
                // ycysbm: this.jczlYcysItem ? this.jczlYcysItem.BM : "",
                // ycysfl2: this.jczlYczcItem ? this.jczlYczcItem.YCYSFL_2 : "",
            });
            this.bchmjNum = result.MJ; //病虫害面积
            this.bchmjTotalNum = result.ZMJ; //总面积
            this.bchmjZbNum = (result.MJ / result.ZMJ) * 100; //病虫害面积占比
            this.$nextTick(() => {
                this.initEchart();
            });
        },
        initEchart() {
            let nowNum = this.bchmjNum;
            let leftnum = this.bchmjTotalNum - this.bchmjNum;
            let option = {
                title: [
                    {
                        text: `占比\n${this.bchmjZbNum}%`,
                        y: "30%",
                        x: "center",
                        textAlign: "left",
                        textStyle: {
                            fontSize: 14,
                            color: "#a8c8ff",
                        },
                    },
                ],
                series: [
                    {
                        type: "pie",
                        startAngle: 90,
                        radius: ["74%", "80%"],
                        center: ["50%", "50%"],
                        data: [
                            {
                                hoverOffset: 1,
                                value: nowNum,
                                name: "",
                                // label: {
                                //     show: false,
                                // },
                                labelLine: {
                                    normal: {
                                        smooth: true,
                                        lineStyle: {
                                            width: 0,
                                        },
                                    },
                                },
                                itemStyle: {
                                    color: "#bdfea2",
                                },
                                hoverAnimation: false,
                            },
                            {
                                label: {
                                    show: false,
                                },
                                labelLine: {
                                    normal: {
                                        smooth: true,
                                        lineStyle: {
                                            width: 0,
                                        },
                                    },
                                },
                                value: leftnum,
                                hoverAnimation: false,
                                itemStyle: {
                                    color: "rgba(251, 200, 79, 0)",
                                },
                            },
                        ],
                    },
                ],
            };
            this.echart = this.$echarts.dispose(
                document.getElementById("btbhEchart")
            );
            this.echart = this.$echarts.init(
                document.getElementById("btbhEchart")
            );
            this.echart.setOption(option);
        },
    },
};
</script>
<style scoped lang="scss">
.screenItem {
    position: relative;
    width: 100%;
    height: 100%;
    .screenCtn {
        width: 100%;
        height: 100%;
        padding: 12px 0;
        .topPart {
            width: 100%;
            height: calc(100% - 105px);
            .echartWarp {
                background: rgba(26, 48, 89, 0.22);
                position: relative;
                .rectangleBg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: calc(100% - 16px);
                    height: calc(100% - 16px);
                    background: url("~@image/bigScreen/monitor_all/btbh/rectangleBg.png")
                        no-repeat;
                    background-size: 100% 100%;
                }
                .imgRotate {
                    // animation: rotatePic 5s linear 0s infinite;
                    height: 85%;
                }
                .infoPart {
                    position: absolute;
                    top: 50%;
                    left: 0%;
                    transform: translateY(-50%);
                    width: 100%;
                    height: 26px;
                    background: linear-gradient(
                        to right,
                        rgba(53, 80, 124, 1),
                        rgba(53, 80, 124, 0)
                    );
                    .label {
                        font-size: 16px;
                        color: rgba(168, 200, 255, 1);
                        margin-right: 20px;
                        margin-left: 10px;
                    }
                    .num {
                        font-size: 24px;
                    }
                }
            }
            .descPart {
                width: 100px;
                height: 100%;
                margin-left: 10px;

                .descItem {
                    width: 100px;
                    height: calc(50% - 3px);
                    text-align: center;
                    position: relative;
                    &:nth-child(1) {
                        margin-bottom: 6px;
                    }
                    .label {
                        color: #a8c8ff;
                        font-size: 16px;
                        height: 50px;
                        line-height: 46px;
                        background: url("~@image/bigScreen/monitor_all/btbh/titleBg.png")
                            no-repeat;
                        background-size: 100% 100%;
                    }
                    .num {
                        font-size: 48px;
                        color: rgba(189, 215, 236, 1);
                        font-family: DBPM;
                        position: absolute;
                        left: 50%;
                        bottom: 0%;
                        transform: translateX(-50%);
                        width: 100%;
                        height: calc(100% - 60px);
                        background: url("~@image/bigScreen/monitor_all/btbh/ctnBg.png")
                            no-repeat;
                        background-size: 100% 100%;
                        .numWz {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }
        }
        .bottomPart {
            width: 100%;
            margin-top: 25px;
            .ycsjPart {
                .label {
                    width: 100%;
                    height: 28px;
                    line-height: 28px;
                    padding-left: 10px;
                    background: rgba(53, 80, 124, 0.5);
                    color: rgba(168, 200, 255, 1);
                    font-size: 16px;
                    border-left: 2px solid rgba(168, 200, 255, 1);
                    text-align: center;
                    padding-right: 10px;
                }
                .desc {
                    margin-top: 6px;
                    color: rgba(168, 200, 255, 1);
                    font-size: 14px;
                }
            }
            .echartPart {
                margin-left: 10px;
                width: 90px;
                height: 90px;
                border: 2px solid rgba(173, 194, 247, 0.2);
                border-radius: 50%;
                position: relative;
                top: -4px;
                .halfCircle {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(-45deg);
                    width: calc(100% - 10px);
                    height: calc(100% - 10px);
                    border: 2px solid rgba(173, 194, 247, 0.6);
                    border-radius: 50%;
                    clip-path: polygon(50% 0%, 100% 0%, 100% 3600%, 50% 50%);
                }
                #btbhEchart {
                    width: 100%;
                    height: 100%;
                }
                .noData {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: #a8c8ff;
                    width: 30px;
                }
            }
        }
    }
}

@keyframes rotatePic {
    0% {
        transform: rotate(0deg);
        transform-origin: 50% 50%;
    }
    100% {
        transform: rotate(360deg);
        transform-origin: 50% 50%;
    }
}
</style>
