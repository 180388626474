<template>
    <div class="basicStatistics">
        <!-- 保护工程统计 -->
        <basic-module :text="common.getFyName(fany.bhgctj)">
            <div slot="basic-module" class="mainPart">
                <div class="centerPart flex-between-y-center">
                    <div
                        class="centerItem animated bounceInRight"
                        v-for="(item, index) in listAry"
                        :key="index"
                        :style="{ animationDelay: 0.2 + index * 0.2 + 's' }"
                    >
                        <div class="name">
                            {{ item.name }}
                        </div>
                        <div class="count flex-x-center flex-y-center">
                            <span class="num">{{ item.num }}</span>
                            <span class="dw">{{ item.dw }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </basic-module>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "",
    data() {
        return {
            listAry: [
                {
                    name: "经费总金额",
                    num: 0,
                    dw: "万元",
                },
                {
                    name: "保护工程总数量",
                    num: 0,
                    dw: "个",
                },
                {
                    name: "今年工程数量",
                    num: 0,
                    dw: "个",
                },
                {
                    name: "进行中工程数量",
                    num: 0,
                    dw: "个",
                },
            ],
        };
    },
    filters: {
        toFixed(value) {
            return Number(value);
        },
    },
    mounted() {
        this.getData();
    },
    methods: {
        ...mapActions(["getBhgcStatistics"]),
        async getData() {
            const res = await this.getBhgcStatistics();
            const keysMap = {
                TRJFZE: 0,
                GCZS: 1,
                JNGCSL: 2,
                JXZGCSL: 3,
            };

            if (res && typeof res === "object") {
                for (const key in keysMap) {
                    if (this.listAry[keysMap[key]]) {
                        this.listAry[keysMap[key]].num = Number(
                            res[key]
                        ).toFixed(0);
                        if (key === "TRJFZE" && res[key] > 9999) {
                            this.listAry[keysMap[key]].dw = "亿元";
                            this.listAry[keysMap[key]].num = (
                                res[key] / 10000
                            ).toFixed(1);
                        }
                    }
                }
            }
            // this.listAry = [...this.listAry]
        },
    },
};
</script>
<style scoped lang="scss">
.basicStatistics {
    width: 100%;
    height: 100%;
    position: relative;
    // 结果统计
    .mainPart {
        .centerItem {
            width: 162px;
            .name {
                width: 100%;
                height: 40px;
                background: url("~@image/bigScreen/icon/titleBg.png") no-repeat;
                background-size: 100% 100%;
                font-size: 18px;
                font-weight: 500;
                color: rgba(168, 200, 255, 1);
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
            .count {
                margin-top: 5px;
                height: 65px;
                border: 1px solid rgba(53, 80, 124, 0.4);
                color: #bdd7ec;
            }
            .num {
                font-size: 48px;
                font-family: DBPM;
            }
            .dw {
                font-size: 20px;
            }
        }
    }
}
</style>
