<template>
	<div class="screenItem">
		<!-- 业态发展 -->
		<BasicMonitor :text="common.getFyName(fany.ytfz)">
			<div slot="basic-module" class="screenCtn scrollbar-translucent">
				<div class="business-type flex-end">
					<div
						v-for="(item, index) in businessType"
						:key="index"
						class="business"
						:class="[
							index == 0 ? 'business-cyhzs' : 'business',
							currentType == item.bh
								? 'business-light'
								: 'business',
						]"
						@click="changeTab(item)"
					>
						{{ item.type }}
					</div>
				</div>
				<div class="echartPart">
					<div class="decorate flex-between">
						<div class="bg1"></div>
						<div class="bg2"></div>
						<div class="bg3"></div>
						<!-- <div class="bg4"></div> -->
						<div class="bg5"></div>
						<div class="bg6"></div>
					</div>
					<div class="echart" id="echartYtfz"></div>
				</div>
			</div>
		</BasicMonitor>
	</div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import BasicMonitor from "@views/big_screen/monitor_all/comp/BasicModule";
import NoMonitor from "@views/big_screen/comp/NoMonitor";
export default {
	components: {
		BasicMonitor,
		NoMonitor,
	},
	name: "",
	data() {
		return {
			listAry: [],
			echart: null,
			businessType: [
				{
					type: "茶叶合作社",
					bh: "1",
					list: [],
				},
				// {
				//     type: '餐饮',
				//     bh: '2',
				//     list:[]
				// },
				// {
				//     type: '住宿',
				//     bh: '3',
				//     list:[]
				// },
			],
			currentType: "1",
		};
	},
	computed: {
		...mapGetters({}),
	},
	props: {},
	watch: {},
	mounted() {
		this.getMainData();
	},
	methods: {
		...mapActions(["GetYtfz"]),
		...mapMutations({}),
		changeTab(item) {
			this.currentType = item.bh;
			if (item.list.length) {
				this.initEchart(item.list);
			} else {
				this.echart = this.$echarts.dispose(
					document.getElementById("echartYtfz")
				);
			}
		},
		async getMainData() {
			let result = await this.GetYtfz();
			result.map((item) => {
				switch (item.TYPE) {
					case 1:
						this.businessType[0].list.push(item);
						break;
					// case 2:
					// 	this.businessType[1].list.push(item);
					// 	break;
					// case 3:
					// 	this.businessType[2].list.push(item);
					// 	break;
				}
			});
			this.initEchart(this.businessType[0].list);
		},
		initEchart(list) {
			let timeList = [];
			let xAxisData = [];
			let seriesData = [];
			let seriesZc = {
				name: `年度注册数量`,
				type: "line",
				smooth: true,
				showSymbol: true,
				symbol: "circle",
				symbolSize: 5,
				data: [],
				color: "rgba(83,183,255,1)",
			};
			let seriesZl = {
				name: "年度总量",
				type: "bar",
				barWidth: 10,
				data: [],
				markPoint: {
					data: [
						{ type: "max", name: "最大值", symbolSize: 40 },
						{ type: "min", name: "最小值", symbolSize: 40 },
					],
				},
				barWidth: "10px",
				itemStyle: {
					normal: {
						barBorderRadius: [30, 30, 0, 0],
						color: new this.$echarts.graphic.LinearGradient(
							0,
							0,
							0,
							1,
							// [
							//     {
							//         offset: 0,
							//         color: "rgba(90,217,166,1)" // 0% 处的颜色
							//     },
							//     {
							//         offset: 1,
							//         color: "rgba(83,183,255,1)" // 100% 处的颜色
							//     }
							// ],
							[
								{
									offset: 0,
									color: "rgba(90,217,166,1)",
								},
								{
									offset: 1,
									color: "rgba(90,217,166,0)",
								},
							],
							false
						),
					},
					// emphasis: {
					//     color: new this.$echarts.graphic.LinearGradient(
					//         0,
					//         0,
					//         0,
					//         1,
					//         [
					//             {
					//                 offset: 0,
					//                 color: "rgba(83,183,255,1)"
					//             },
					//             {
					//                 offset: 1,
					//                 color: "rgba(90,217,166,1)"
					//             }
					//         ]
					//     )
					// }
				},
			};

			list.map((item) => {
				seriesZl.data.push(item.TOTAL); //年度总量
				seriesZc.data.push(item.INCREASE); //年度注册数量
				timeList.push(item.NF);
			});
			xAxisData = [...new Set(timeList)].sort();
			seriesData = [seriesZl, seriesZc];
			let options = {
				// color: [
				//     "rgba(90,217,166,1)",
				//     "rgba(83,183,255,1)",
				// ],
				tooltip: {
					trigger: "axis",
					padding: [2, 10],
					textStyle: {
						fontSize: 16,
						align: "left",
					},
					axisPointer: {
						lineStyle: {
							color: {
								type: "linear",
								x: 0,
								y: 0,
								x2: 0,
								y2: 1,
								colorStops: [
									{
										offset: 0,
										color: "rgba(0, 255, 233,0)",
									},
									{
										offset: 0.5,
										color: "rgba(255, 255, 255,1)",
									},
									{
										offset: 1,
										color: "rgba(0, 255, 233,0)",
									},
								],
								global: false,
							},
						},
					},
				},
				legend: {
					icon: "roundRect",
					itemWidth: 18,
					itemHeight: 10,
					itemGap: 13,
					textStyle: {
						color: "rgba(196,214,225,1)",
					},
					left: "center",
					top: "5%",
				},
				grid: {
					top: 60,
					bottom: 20,
					left: 50,
					right: 20,
				},
				xAxis: [
					{
						name: "",
						nameTextStyle: {
							fontSize: 12,
							color: "#9AB4E3",
						},
						boundaryGap: false, // 默认，坐标轴留白策略
						type: "category",
						axisLabel: {
							// interval: 0,
							// rotate: 45,
							textStyle: {
								color: "#A8C8FF",
							},
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: "#0f5378",
							},
						},
						splitArea: {
							color: "#f00",
							lineStyle: {
								color: "#e2e9ff",
							},
						},
						minorTick: {
							show: false,
						},
						data: xAxisData,
					},
				],
				yAxis: {
					name: "",
					nameTextStyle: {
						fontSize: 12,
						color: "#9AB4E3",
						padding: [0, 0, 0, -70],
					},
					splitLine: {
						show: true,
						lineStyle: {
							color: "rgba(53,80,124,0.2)",
						},
					},
					axisLabel: {
						margin: 20,
						fontSize: 12,
						textStyle: {
							color: "#9AB4E3",
							// color: "rgba(53,80,124,1)",
						},
					},
					axisLine: {
						show: false,
						lineStyle: {
							color: "rgba(53,80,124,0.2)",
							width: 2,
						},
					},
				},
				series: seriesData,
			};
			this.echart = this.$echarts.dispose(
				document.getElementById("echartYtfz")
			);
			this.echart = this.$echarts.init(
				document.getElementById("echartYtfz")
			);
			this.echart.setOption(options);
		},
	},
};
</script>
<style scoped lang="scss">
.screenItem {
	position: relative;
	width: 100%;
	height: 100%;
	.screenCtn {
		width: 100%;
		height: 100%;
		padding-top: 15px;
		.business-type {
			width: auto;
			height: 20px;
			margin-left: 50%;
			display: flex;
			.business {
				width: 60px;
				height: 100%;
				margin-left: 10px;
				text-align: center;
				color: #ffffff;
				background: url("~@image/bigScreen/monitor_all/ytfz/bg_other.png")
					no-repeat;
				cursor: pointer;
			}
			.business-light {
				color: #ffcd80;
				background: url("~@image/bigScreen/monitor_all/ytfz/bg_other_light.png")
					no-repeat;
			}
			.business-cyhzs {
				width: 90px;
				background: url("~@image/bigScreen/monitor_all/ytfz/bg_cyhzs.png")
					no-repeat;
			}
			.business-cyhzs-light {
				color: #ffcd80;
				background: url("~@image/bigScreen/monitor_all/ytfz/bg_cyhzs_light.png")
					no-repeat;
			}
		}
		.echartPart {
			width: 100%;
			height: calc(100% - 20px);
			position: relative;
			.decorate {
				position: absolute;
				left: 0;
				top: 40px;
				width: 100%;
				height: calc(100% - 40px);
				padding: 0 10px 0 20px;
				.bg1 {
					width: 248px;
					height: calc(100% - 30px);
					background: rgba(53, 80, 124, 0.15);
					position: absolute;
					left: 0;
					top: 0;
				}
				.bg2 {
					width: 55px;
					height: calc(100% - 30px);
					background: rgba(53, 80, 124, 0.15);
					position: absolute;
					left: 137px;
					top: 0;
				}
				.bg3 {
					width: 55px;
					height: calc(100% - 30px);
					background: rgba(53, 80, 124, 0.3);
					position: absolute;
					left: 248px;
					top: 0;
				}
				.bg4 {
					width: 55px;
					height: calc(100% - 30px);
					background: rgba(53, 80, 124, 0.3);
					position: absolute;
					left: 340px;
					top: 0;
				}
				.bg5 {
					width: 55px;
					height: calc(100% - 30px);
					background: rgba(53, 80, 124, 0.15);
					position: absolute;
					right: 55px;
					top: 0;
				}
				.bg6 {
					width: 55px;
					height: calc(100% - 30px);
					background: rgba(53, 80, 124, 0.3);
					position: absolute;
					right: 0;
					top: 0;
				}
			}
			.echart {
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0px;
			}
		}
	}
}
</style>
