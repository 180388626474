// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@image/bigScreen/ecological_environment/Right/reportDownload.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".title[data-v-0ee2b55e]{width:100%;height:36px;line-height:35px;display:flex;position:relative;padding:0 24px}.title .titleCenter[data-v-0ee2b55e]{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;height:100%;margin:0 auto;font-size:18px;color:#d2eaf6;text-align:center;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.title .titleCenter .titleContent[data-v-0ee2b55e]{padding:0 30px;min-width:200px;display:inline-block;border-top:1px solid #84acc2;background:linear-gradient(#305061,rgba(48,80,97,0))}.title .titleIcon[data-v-0ee2b55e]{position:absolute;top:50%;right:20px;transform:translateY(-50%);color:#fff;font-size:24px}.title .report-download[data-v-0ee2b55e]{position:absolute;top:10px;right:22px;display:flex;justify-content:center;align-items:center;width:94px;height:20px;background:#35507c;border-radius:5px;cursor:pointer}.title .report-download .download-img[data-v-0ee2b55e]{width:14px;height:14px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat}.title .report-download .download-label[data-v-0ee2b55e]{margin-left:8px;color:#a8c8ff}", ""]);
// Exports
module.exports = exports;
