<template>
    <div class="ccltjPart">
        <!-- 巡查统计 -->
        <basic-module :text="common.getFyName(fany.ccltj)">
            <div slot="basic-module" class="ccltjCtn">
                <div class="condition flex-end">
                    <div class="line"></div>
                    <SelectTimeTab
                        :selectData="cqData"
                        @getSelectItem="getSelectTeaEnter"
                    >
                    </SelectTimeTab>
                    <SelectTimeTab
                        :selectData="seasonData"
                        @getSelectItem="getSelectSeason"
                    >
                    </SelectTimeTab>
                </div>
                <div class="echart" :id="'echartXctj' + echartId"></div>

                <div class="circleEchartPart flex-between">
                    <div class="echartItem">
                        <div class="echartTitle">产量统计</div>
                        <div class="echartBox">
                            <EchartCircle
                                :colorList="yjtjPart.leftPart.colorList"
                                :listAry="yjtjPart.leftPart.listAry"
                            ></EchartCircle>
                        </div>
                    </div>
                    <div class="echartItem">
                        <div class="echartTitle">出售量统计</div>
                        <div class="echartBox">
                            <EchartCircle
                                :colorList="yjtjPart.rightPart.colorList"
                                :listAry="yjtjPart.rightPart.listAry"
                            ></EchartCircle>
                        </div>
                    </div>
                </div>
            </div>
        </basic-module>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import SelectTimeTab from "@views/big_screen/comp/SelectTimeTab";
import EchartCircle from "./EchartCircle.vue"; //圆形echart
export default {
    components: {
        SelectTimeTab,
        EchartCircle,
    },
    name: "",
    data() {
        return {
            echartId: Math.random(),
            cqData: {
                clearable: true,
                state: true,
                width: "150px",
                defaultValue: "",
                placeholder: "请选择茶企",
                list: [],
                label: "name",
                value: "name",
            },
            seasonData: {
                clearable: true,
                state: true,
                width: "150px",
                defaultValue: "",
                placeholder: "请选择季节",
                list: [],
                label: "label",
                value: "value",
            },
            ccltjObj: [],
            state: true, //true 还有未加载的数据 false 已经加载完毕
            sntqState: true,
            yjtjPart: {
                leftPart: {
                    colorList: ["#41DAEC", "#48FF00", "#FFFC00", "#FF7E00"],
                    listAry: [],
                },
                rightPart: {
                    colorList: [
                        "#41DAEC",
                        "#48FF00",
                        "#FFFC00",
                        "#FF7E00",
                        "#6A66FF",
                    ],
                    listAry: [],
                },
            },
            teaEnter: '',
            season: '',
        };
    },
    computed: {
        ...mapGetters({
            
        }),
    },
    props: {},
    watch: {},
    mounted() {
        this.getCqList();
        this.getSeasonList();
        this.getTeaOutPutLineData();
        this.getYctjLeft();
        this.getYctjRight();
    },
    methods: {
        ...mapActions([
            "GetTeaEnterpriseName",
            "GetEnumItem",
            "GetTeaOutput",
            "GetOutPutStatistic",
            "GetTeaSale",
        ]),
        ...mapMutations({
            
        }),
        //获取茶企筛选列表
        async getCqList() {
            let result = await this.GetTeaEnterpriseName();
            if(result.ISSUCCESS) {
                this.cqData.list = [];
                result.RESULTVALUE.map((item) => {
                    let obj = {
                        name: item,
                    };
                    this.cqData.list.push(obj);
                });
                if (this.cqData.list.length) {
                    this.getTeaOutPutLineData();
                } else {
                    this.ccltjObj = [];
                    let id = `echartXctj${this.echartId}`;
                    this.$echarts.dispose(document.getElementById(id));
                }
            }
        },
        //获取季节筛选列表
        async getSeasonList() {
            let result = await this.GetEnumItem({
                Enumid: "290bbeac-f0ff-11ec-a716-000c2977b7fd",
            });
            this.seasonData.list = [];
            result.map((item) => {
                let obj = {
                    label: item.Name,
                    value: item.Code,
                };
                this.seasonData.list.push(obj);
            });
            if (this.seasonData.list.length) {
                this.getTeaOutPutLineData();
            } else {
                this.ccltjObj = [];
                let id = `echartXctj${this.echartId}`;
                this.$echarts.dispose(document.getElementById(id));
            }
        },
        //获取茶产量折线图统计
        async getTeaOutPutLineData() {
            let result = await this.GetTeaOutput({
                teaEnter: this.teaEnter,
                season: this.season,
            });
            if(result.ISSUCCESS) {
                this.ccltjObj = result.RESULTVALUE;
                this.initEchartXctjYear(result.RESULTVALUE);
            }
            
        },
        // 茶企业切换
        getSelectTeaEnter(item) {
            if(item) {
                this.teaEnter = item.name;
            } else {
                this.teaEnter = "";
            }
            this.getTeaOutPutLineData();
        },
        //季节切换
        getSelectSeason(item) {
            if(item) {
                this.season = item.value;
            } else {
                this.season = "";
            }
            this.getTeaOutPutLineData();
        },
        // 茶产量统计 
        initEchartXctjYear(result) {
            let timeList = [];
            let xAxisData = [];
            let seriesData = [];
            let seriesZcl = {
                name: `总产量`,
                type: "line",
                smooth: true,
                showSymbol: true,
                symbol: "circle",
                symbolSize: 5,
                areaStyle: {
                    color: new this.$echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                            {
                                offset: 0,
                                color: "rgba(116,181,221,0.8)",
                            },
                            {
                                offset: 1,
                                color: "rgba(116,181,221,0)",
                            },
                        ],
                        false
                    ),
                },
                data: [],
            };
            let seriesGcs = {
                name: `古茶树`,
                type: "line",
                smooth: true,
                showSymbol: true,
                symbol: "circle",
                symbolSize: 5,
                areaStyle: {
                    color: new this.$echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                            {
                                offset: 0,
                                color: "rgba(255,159,0,0.8)",
                            },
                            {
                                offset: 1,
                                color: "rgba(255,159,0,0)",
                            },
                        ],
                        false
                    ),
                },
                data: [],
            };
            let seriesStc = {
                name: `生态茶`,
                type: "line",
                smooth: true,
                showSymbol: true,
                symbol: "circle",
                symbolSize: 5,
                areaStyle: {
                    color: new this.$echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [
                            {
                                offset: 0,
                                color: "rgba(90,217,166,0.8)",
                            },
                            {
                                offset: 1,
                                color: "rgba(90,217,166,0)",
                            },
                        ],
                        false
                    ),
                },
                data: [],
            };
            //总产量
            result.map((item) => {
                seriesZcl.data.push(item.CYZCL);
            });
            //古树茶产量
            result.map((item) => {
                seriesGcs.data.push(item.GSCCL);
            });
            // 生态茶产量
            result.map((item) => {
                seriesStc.data.push(item.STCCL);
            });
            //年份
            result.map((item) => {
                timeList.push(item.ND);
            })
            xAxisData = [...new Set(timeList)].sort();
            seriesData = [seriesZcl, seriesGcs, seriesStc];
            let options = {
                color: [
                    "rgba(116,181,2212,1)",
                    "rgba(255,159,0,1)",
                    "rgba(90,217,166,1)",
                ],
                tooltip: {
                    trigger: "axis",
                    padding: [2, 10],
                    textStyle: {
                        fontSize: 16,
                        align: "left",
                    },
                    axisPointer: {
                        lineStyle: {
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "rgba(255, 255, 255,1)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                ],
                                global: false,
                            },
                        },
                    },
                },
                legend: {
                    icon: "rect",
                    itemWidth: 14,
                    itemHeight: 5,
                    itemGap: 13,
                    textStyle: {
                        color: "rgba(196,214,225,1)",
                    },
                    left: "center",
                    top: "5%",
                },
                grid: {
                    top: 60,
                    bottom: 20,
                    left: 70,
                    right: 20,
                },
                xAxis: [
                    {
                        name: "",
                        nameTextStyle: {
                            fontSize: 12,
                            color: "#9AB4E3",
                        },
                        boundaryGap: false, // 默认，坐标轴留白策略
                        type: "category",
                        axisLabel: {
                            // interval: 0,
                            // rotate: 45,
                            textStyle: {
                                color: "#A8C8FF",
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#0f5378",
                            },
                        },
                        splitArea: {
                            color: "#f00",
                            lineStyle: {
                                color: "#e2e9ff",
                            },
                        },
                        data: xAxisData,
                    },
                ],
                yAxis: {
                    name: "单位kg",
                    nameTextStyle: {
                        fontSize: 12,
                        color: "#9AB4E3",
                        padding: [0, 0, 0, -70],
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(53,80,124,0.2)",
                        },
                    },
                    axisLabel: {
                        margin: 20,
                        fontSize: 12,
                        textStyle: {
                            color: "#9AB4E3",
                            // color: "rgba(53,80,124,1)",
                        },
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: "rgba(53,80,124,0.2)",
                            width: 2,
                        },
                    },
                },
                series: seriesData,
            };
            let id = `echartXctj${this.echartId}`;
            let echart = null;
            echart = this.$echarts.dispose(document.getElementById(id));
            echart = this.$echarts.init(document.getElementById(id));
            echart.setOption(options);
        },
        // 获取茶叶产量 左侧echart
        async getYctjLeft() {
            let result = await this.GetOutPutStatistic({
                nf: "",
            });
            if(result.ISSUCCESS) {
                let array = [];
                result.RESULTVALUE.forEach((ele) => {
                    let obj = {};
                    obj.NAME = ele.NAME;
                    obj.CODE = ele.CODE;
                    obj.COUNT = ele.COUNT;
                    array.push(obj);
                });
                this.yjtjPart.leftPart.listAry = array;
            }
        },
        // 获取茶叶出售量 右侧echart
        async getYctjRight() {
            let result = await this.GetTeaSale({
                nf: "",
            });
            if(result.ISSUCCESS) {
                let array = [];
                result.RESULTVALUE.forEach((ele) => {
                    let obj = {};
                    obj.NAME = ele.NAME;
                    obj.CODE = ele.CODE;
                    obj.COUNT = ele.COUNT;
                    array.push(obj);
                });
                this.yjtjPart.rightPart.listAry = array;
            };
        },
        tableItemDetail(item) {
            
        },
        handleClose() {
            
        },
    },
};
</script>
<style scoped lang="scss">
// 茶产量统计
.ccltjPart {
    width: 100%;
    height: 100%;
    .ccltjCtn {
        width: 100%;
        height: 100%;
        position: relative;
        .condition {
            position: absolute;
            right: 0;
            top: -38px;
            .line {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 522px;
                height: 1px;
                background: rgba(53, 80, 124, 0.4);
                z-index: -1;
            }
        }
        .sntq {
            position: absolute;
            right: 0;
            top: 0;
            .sntqActive {
                .circle {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #ffcd80;
                    margin-right: 10px;
                }
                .name {
                    color: #afc9e1;
                    font-size: 14px;
                }
            }
            .sntqDefault {
                .circle {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #bdd7ec;
                    margin-right: 10px;
                }
                .name {
                    color: #bdd7ec;
                    font-size: 14px;
                }
            }
        }

        .echart {
            width: 100%;
            height: calc(100% - 208px);
        }
        .dw {
            position: absolute;
            right: 0;
            bottom: -20px;
            color: #35507c;
        }
        .circleEchartPart {
            width: 100%;
            height: 188px;
            margin-top: 20px;
            .echartItem {
                width: 50%;
                height: 100%;
                display: flex;
                flex-direction: row;
                .echartTitle {
                    width: 50px;
                    height: 100%;
                    background: rgba(26, 56, 89, 0.3);
                    writing-mode: vertical-lr;
                    text-align: center;
                    font-size: 18px;
                    padding-left: 15px;
                    color: #bdd7ec;
                }
                .echartBox {
                    width: calc(100% - 50px);
                    background: url("~@image/bigScreen/ecological_environment/Right/background.png")
                    no-repeat;
                    background-size: 95%;
                    background-position: center;
                }
            }
        }
    }
}
</style>
