<template>
    <el-calendar class="bigScreen-calendar" :value="dateValue">
        <template v-slot:dateCell="{ date, data }">
            <p class="calendar-day" :class="setIsBigHeight(date, data)">
                {{data.type !== "current-month" ? "" : new Date(date).getDate()}}
            </p>
            <div :class="isBigHeight ? 'bigHeight' : 'smallHeight'">
                <slot
                    v-if="data.type === 'current-month'"
                    v-bind:cellData="{ date, data }"
                    name="content"
                ></slot>
            </div>
        </template>
    </el-calendar>
</template>
<script>
let preAndCurrentDays = 0;
export default {
    props: {
        dateValue: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            // 当上月天数加本月天数>35时，日历中显示六个星期
            preAndCurrentDays: 0,
            isBigHeight: true,
        };
    },
    computed: {},
    watch: {
        dateValue() {
            preAndCurrentDays = 0;
            this.isBigHeight = true;
        },
    },
    methods: {
        setIsBigHeight(date, data) {
            let day = new Date(date).getDate();
            let weekDay = new Date(date).getDay();
            if (data.type === "current-month" || data.type === "prev-month") {
                preAndCurrentDays++;
                if (preAndCurrentDays > 35) {
                    this.isBigHeight = false;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.bigScreen-calendar {
    height: 100%;
    width: 100%;
    overflow: hidden;
    .bigHeight {
        height: 22px;
    }
    .smallHeight {
        height: 13px;
    }
}
</style>
