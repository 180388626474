<template>
    <div class="rightPart scrollbar-hidden">
        <BoxTitle
            :title="titleName"
            :backBtn="false"
            :downloadBtn="true"
            @handleDownloadClick="handleDownloadClick"
        ></BoxTitle>
        <div class="jcsb-border">
            <div
                class="jcsb-item"
                :class="currentJcsbId === item.id ? 'current-jcsb' : ''"
                v-for="item in jcsbList"
                :key="'jcsb' + item.id"
                @click="handleJcsbClick(item)"
            >
                {{ item.label }}
            </div>
        </div>
        <div class="sssj-module">
            <!-- 实时数据 -->
            <basic-module :text="common.getFyName(fany.sssj)">
                <div slot="basic-module" class="sssj-border">
                    <div
                        class="sssj-item"
                        v-for="item in sssjList"
                        :key="'sssj' + item.id"
                    >
                        <div class="sssj-top">
                            <div class="sssj-topleft">
                                <img
                                    class="sssj-img"
                                    :src="
                                        require(`@image/bigScreen/ecological_environment/1WhjQxjc/${item.label}.png`)
                                    "
                                    alt=""
                                />
                                <div class="border-common border-topleft"></div>
                                <div
                                    class="border-common border-topright"
                                ></div>
                                <div
                                    class="border-common border-bottomleft"
                                ></div>
                                <div
                                    class="border-common border-bottomright"
                                ></div>
                            </div>
                            <div class="sssj-topright">
                                {{ item.value | strictNull
                                }}<span class="sssj-dw">{{ item.dw }}</span>
                                <div class="line-common line-left"></div>
                                <div class="line-common line-center"></div>
                                <div class="line-common line-right"></div>
                            </div>
                        </div>
                        <div class="sssj-bottom">
                            <div class="sssj-bottomleft">{{ item.label }}</div>
                            <div class="sssj-bottomright">
                                {{ item.value2 | strictNull
                                }}<span>{{ item.dw2 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </basic-module>
        </div>
        <div class="jcsj-module">
            <!-- 监测数据 -->
            <basic-module :text="common.getFyName(fany.jcsj)">
                <div slot="basic-module" class="jcsj-bar-chart">
                    <div class="jcsj-left">
                        <NoMonitor
                            v-show="!isChartShow"
                            text="暂无数据"
                        ></NoMonitor>
                        <lineChart
                            v-show="isChartShow"
                            :chartData="jcsjChartData"
                        ></lineChart>
                    </div>
                    <div class="jcsj-right">
                        <NoMonitor
                            v-show="!isChartShow2"
                            text="暂无数据"
                        ></NoMonitor>
                        <rosePieChartWind
                            v-show="isChartShow2"
                            :chartData="fbqkChartData"
                        ></rosePieChartWind>
                    </div>
                    <div class="bs-datepicker jcsj-search">
                        <el-date-picker
                            v-if="currentJcsjBtnId === '1'"
                            size="mini"
                            style="width: 100px"
                            v-model="nfValue"
                            type="year"
                            value-format="yyyy"
                            popper-class="bs-select-panel"
                            placeholder="选择年"
                            :clearable="false"
                            @change="handleDyxtjNfChanged"
                        >
                        </el-date-picker>
                        <el-date-picker
                            v-if="currentJcsjBtnId === '2'"
                            size="mini"
                            style="width: 120px"
                            v-model="nfValue"
                            type="month"
                            value-format="yyyy-MM"
                            popper-class="bs-select-panel"
                            placeholder="选择月"
                            :clearable="false"
                            @change="handleDyxtjNfChanged"
                        >
                        </el-date-picker>
                        <div
                            :class="
                                currentJcsjBtnId === item.id
                                    ? 'jscj-btn-group-current'
                                    : 'jscj-btn-group'
                            "
                            v-for="item in jcsjBtnList"
                            :key="'jcsjbtn' + item.id"
                            @click="handleJscjBtnClick(item)"
                        >
                            {{ item.label }}
                        </div>
                    </div>
                    <div class="bs-datepicker jcsj-search2">
                        <el-date-picker
                            size="mini"
                            style="width: 100px"
                            v-model="nfValue2"
                            type="year"
                            value-format="yyyy"
                            popper-class="bs-select-panel"
                            placeholder="选择年"
                            :clearable="false"
                            @change="getFxfsMgt"
                        >
                        </el-date-picker>
                    </div>
                </div>
            </basic-module>
        </div>
        <div class="jlfx-module">
            <!-- 监测报告 -->
            <basic-module :text="common.getFyName(fany.jcbg)">
                <div slot="basic-module" class="jlfx-border">
                    <div class="jlfx-label">监测结果</div>
                    <div class="jlfx-text">{{ zhpjText | strictNull }}</div>
                </div>
            </basic-module>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import BoxTitle from "@views/big_screen/comp/BoxTitle.vue"; //标题
import NoMonitor from "@views/big_screen/comp/NoMonitor.vue"; //标题
import lineChart from "../comp/line_chart.vue";
import rosePieChartWind from "../comp/rose_pie_chart_wind.vue";
export default {
    name: "",
    components: {
        BoxTitle,
        NoMonitor,
        lineChart,
        rosePieChartWind,
    },
    data() {
        return {
            titleName: "微环境气象监测服务",
            sssjList: [
                {
                    id: "0",
                    value: 0,
                    key: "WD",
                    dw: "℃",
                    label: "平均温度",
                    value2: 0,
                    key2: "AVGWD",
                    dw2: "℃",
                },
                {
                    id: "1",
                    value: 0,
                    key: "SD",
                    dw: "%RH",
                    label: "平均湿度",
                    value2: 0,
                    key2: "AVGSD",
                    dw2: "%RH",
                },
                // {
                //     id: "3",
                //     value: 0,
                //     key: 'FXFS',
                //     // dw: "",
                //     label: "主导风向",
                //     value2: 0,
                //     key2: 'AVGFXFS',
                //     // dw2: "",
                // },
                {
                    id: "5",
                    value: 0,
                    key: "YL",
                    dw: "mm",
                    label: "年降雨量",
                    value2: 0,
                    key2: "AVGYL",
                    dw2: "mm",
                },
                {
                    id: "2",
                    value: 0,
                    key: "QY",
                    dw: "hPa",
                    label: "平均气压",
                    value2: 0,
                    key2: "AVGQY",
                    dw2: "hPa",
                },
                {
                    id: "6",
                    value: 0,
                    key: "TYFS",
                    dw: "w/m²",
                    label: "平均辐射",
                    value2: 0,
                    key2: "AVGTYFS",
                    dw2: "w/m²",
                },
                {
                    id: "4",
                    value: 0,
                    key: "FXFS",
                    dw: "m/s",
                    label: "平均风速",
                    value2: 0,
                    key2: "AVGFS",
                    dw2: "m/s",
                },
            ],
            jcsbList: [
                {
                    id: "6691f316-a772-4ad8-b7d7-eb404e6041ab",
                    label: "芒景上下寨-芒洪古茶林气象站",
                },
                {
                    id: "9478d05f-eec1-48a5-8b30-c6b5eda1a23e",
                    label: "大平掌古茶林气象站",
                },
            ],
            currentJcsbId: "9478d05f-eec1-48a5-8b30-c6b5eda1a23e",
            nfValue: "",
            nfValue2: this.common.getTimeY(),
            jcsjBtnList: [
                {
                    id: "1",
                    label: "月",
                },
                {
                    id: "2",
                    label: "日",
                },
            ],
            currentJcsjBtnId: "2",
            zhpjText: "",
            bgxzXdlj: "",
            fbqkChartData: [], // 古树名木科属统计-分布情况
            jcsjChartData: {
                xAxisData: [],
                legendData: [],
                selectedObj: {},
                seriesData: [],
                xAxisName: "",
                dwArr: [],
            },
            isChartShow: false,
            isChartShow2: false,
        };
    },
    filters: {
        strictNull(value) {
            if (value === null || value === undefined || value === "") {
                return "暂无数据";
            }
            return value;
        },
    },
    mounted() {
        let today = this.common.getTimeYmd();
        if (Number(today.split("-")[2]) <= 3) {
            this.nfValue = this.common.someMonthsAgo(1);
        } else {
            this.nfValue = this.common.getTimeYm();
        }
        this.getWhjQxSssj();
        this.getSwjcTjt();
        this.getJlbg();
        this.getFxfsMgt();
    },
    methods: {
        ...mapActions([
            "GetWhjQxSssj", // 微环境-气象实时数据
            "GetWhjQxTjt", // 微环境-气象统计图
            "GetJlbg", // 获取结论报告
            "GetFxfsMgt", // 风向玫瑰图
        ]),
        handleJscjBtnClick(val) {
            if (val.id === this.currentJcsjBtnId) return;
            this.currentJcsjBtnId = val.id;
            if (this.currentJcsjBtnId === "1") {
                this.nfValue = this.common.getTimeY();
            } else {
                this.nfValue = this.common.getTimeYm();
            }
            this.getSwjcTjt();
        },
        handleJcsbClick(val) {
            if (val.id === this.currentJcsbId) return;
            this.currentJcsbId = val.id;
            this.getWhjQxSssj();
            this.getSwjcTjt();
            this.getFxfsMgt();
        },
        async getWhjQxSssj() {
            const result = await this.GetWhjQxSssj({
                sbid: this.currentJcsbId,
            });
            if (result) {
                for (let i in result) {
                    this.sssjList.forEach((item) => {
                        if (item.key === i) {
                            item.value = result[i];
                        }
                        if (item.key2 === i) {
                            item.value2 = result[i];
                        }
                    });
                }
            }
        },
        handleDyxtjNfChanged() {
            this.getSwjcTjt();
        },
        async getSwjcTjt() {
            let sum = 0;
            this.jcsjChartData = {
                xAxisData: [],
                legendData: [],
                selectedObj: {},
                seriesData: [],
                xAxisName: "",
                dwArr: [],
            };
            const result = await this.GetWhjQxTjt({
                ny: this.nfValue,
                sbid: this.currentJcsbId,
            });
            if (result && result.length > 0) {
                let xAxisData = [];
                let QY = [];
                let WD = [];
                let SD = [];
                let YL = [];
                let TYFS = [];
                result.forEach((item) => {
                    if (!item.QY) {
                        return;
                    }
                    xAxisData.push(item.JCSJ);
                    QY.push(item.QY);
                    WD.push(item.WD);
                    SD.push(item.SD);
                    YL.push(item.YL);
                    TYFS.push(item.TYFS);
                    sum +=
                        Number(item.QY) +
                        Number(item.WD) +
                        Number(item.SD) +
                        Number(item.YL) +
                        Number(item.TYFS);
                });
                this.jcsjChartData = {
                    xAxisData,
                    legendData: ["气压", "温度", "湿度", "雨量", "太阳辐射"],
                    selectedObj: {
                        气压: false,
                        温度: true,
                        湿度: true,
                        雨量: false,
                        太阳辐射: false,
                    },
                    xAxisName: this.currentJcsjBtnId == "1" ? "月" : "日",
                    // dwArr: ['hPa','℃','%RH','mm','w/m²'],
                    dwArr: {
                        气压: "hPa",
                        温度: "℃",
                        湿度: "%RH",
                        雨量: "mm",
                        太阳辐射: "w/m²",
                    },
                    seriesData: [
                        {
                            name: "气压",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: QY,
                        },
                        {
                            name: "温度",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: WD,
                        },
                        {
                            name: "湿度",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: SD,
                        },
                        {
                            name: "雨量",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: YL,
                        },
                        {
                            name: "太阳辐射",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            lineStyle: {
                                width: 3,
                            },
                            data: TYFS,
                        },
                    ],
                };
            }
            if (sum === 0) {
                this.isChartShow = false;
            } else {
                this.isChartShow = true;
            }
        },
        async getFxfsMgt() {
            let sum = 0;
            const result = await this.GetFxfsMgt({
                lx: 1,
                sb: this.currentJcsbId,
                nf: this.nfValue2,
            });
            if (result && result.length > 0) {
                result.forEach((item) => {
                    sum += Number(item.FS) + Number(item.CS);
                });
            }
            if (sum === 0) {
                this.isChartShow2 = false;
            } else {
                this.isChartShow2 = true;
            }
            this.fbqkChartData = [...result];
        },

        async getJlbg() {
            this.zhpjText = "";
            this.bgxzXdlj = "";
            const result = await this.GetJlbg({
                lx: "1",
            });
            if (!result) return;
            this.zhpjText = result.ZHPJ;
            this.bgxzXdlj = result.BGLJ;
        },
        // 报告下载
        handleDownloadClick() {
            if (!this.bgxzXdlj) {
                this.common.showMsg("暂无报告", "warning");
                return;
            }
            window.open("http://" + this.bgxzXdlj);
        },
    },
};
</script>
<style scoped lang="scss">
.rightPart {
    width: 100%;
    height: 100%;
    padding: 15px 0;
    .jcsb-border {
        display: flex;
        padding-left: 8px;
        .jcsb-item {
            // width: 120px;
            height: 35px;
            line-height: 35px;
            padding: 0 10px;
            border-radius: 5px;
            text-align: center;
            font-size: 16px;
            color: #a8c8ff;
            background: #35507c;
            margin-left: 15px;
            margin-top: 14px;
            cursor: pointer;
        }
        .current-jcsb {
            color: #0b1b30;
            background: #ffcd80;
            cursor: default;
        }
    }
    .sssj-module {
        width: 100%;
        .sssj-border {
            display: flex;
            flex-flow: row wrap;
            .sssj-item {
                width: 150px;
                height: 77px;
                margin-right: 18px;
                background: rgba(26, 48, 89, 0.3);
                .sssj-top {
                    display: flex;
                    width: 100%;
                    height: 50px;
                    .sssj-topleft {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 50px;
                        height: 50px;
                        background: rgba(53, 80, 124, 0.3);
                        .sssj-img {
                        }
                        .border-common {
                            position: absolute;
                            width: 2px;
                            height: 2px;
                            background: #a8c8ff;
                        }
                        .border-topleft {
                            top: 0;
                            left: 0;
                        }
                        .border-topright {
                            top: 0;
                            right: 0;
                        }
                        .border-bottomleft {
                            bottom: 0;
                            left: 0;
                        }
                        .border-bottomright {
                            bottom: 0;
                            right: 0;
                        }
                    }
                    .sssj-topright {
                        position: relative;
                        width: calc(100% - 50px);
                        height: 50px;
                        line-height: 50px;
                        text-align: center;
                        font-size: 22px;
                        color: #a8c8ff;
                        .sssj-dw {
                            margin-left: 1px;
                            font-size: 16px;
                            color: #a8c8ff;
                        }
                        .line-common {
                            position: absolute;
                            width: 22px;
                            height: 3px;
                            background: rgba(53, 80, 124, 0.3);
                        }
                        .line-left {
                            bottom: 0;
                            left: 8px;
                        }
                        .line-center {
                            bottom: 0;
                            left: calc(50% - 7px);
                        }
                        .line-right {
                            bottom: 0;
                            right: 0;
                        }
                    }
                }
                .sssj-bottom {
                    display: flex;
                    height: 27px;
                    line-height: 27px;
                    padding: 0 0 0 6px;
                    color: #a8c8ff;
                    .sssj-bottomleft {
                        flex: none;
                        margin-right: 8px;
                        font-size: 16px;
                    }
                    .sssj-bottomright {
                        font-size: 16px;
                    }
                }
            }
            .sssj-item:nth-child(4) {
                margin-bottom: 15px;
                margin-right: 0;
            }
            .sssj-item:last-child {
                width: 218px;
            }
        }
    }
    .jcsj-module {
        width: 100%;
        height: 360px;
        .jcsj-bar-chart {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            padding-top: 30px;
            .jcsj-left {
                position: relative;
                width: 60%;
            }
            .jcsj-right {
                position: relative;
                width: calc(40% - 10px);
                margin-left: 10px;
            }
            .jcsj-search {
                display: flex;
                position: absolute;
                right: calc(60% - 129px);
                top: 0px;
                .jscj-btn-group {
                    width: 40px;
                    height: 20px;
                    line-height: 20px;
                    margin-left: 15px;
                    border-radius: 5px;
                    text-align: center;
                    color: #a8c8ff;
                    background: #35507c;
                    cursor: pointer;
                }
                .jscj-btn-group-current {
                    width: 40px;
                    height: 20px;
                    line-height: 20px;
                    margin-left: 15px;
                    border-radius: 5px;
                    text-align: center;
                    color: #040e1e;
                    background: #ffcd80;
                }
            }
            .jcsj-search2 {
                display: flex;
                position: absolute;
                right: 0;
                top: 0px;
                .jscj-btn-group {
                    width: 40px;
                    height: 20px;
                    line-height: 20px;
                    margin-left: 15px;
                    border-radius: 5px;
                    text-align: center;
                    color: #a8c8ff;
                    background: #35507c;
                    cursor: pointer;
                }
                .jscj-btn-group-current {
                    width: 40px;
                    height: 20px;
                    line-height: 20px;
                    margin-left: 15px;
                    border-radius: 5px;
                    text-align: center;
                    color: #040e1e;
                    background: #ffcd80;
                }
            }
        }
    }
    .jlfx-module {
        width: 100%;
        .jlfx-border {
            width: 100%;
            .jlfx-label {
                width: 100%;
                color: #5673a2;
            }
            .jlfx-text {
                width: 100%;
                line-height: 24px;
                margin: 5px 0;
                padding: 9px;
                text-align: justify;
                font-size: 16px;
                font-weight: 500;
                color: #bdd7ec;
                background: rgba(26, 48, 89, 0.3);
            }
        }
    }
}
</style>
