<template>
    <div class="mudule">
        <img :src="baseLeft" class="leftSideImg" v-if="!ycysState" />
        <img :src="baseRight" class="rightSideImg" v-if="!ycysState" />
        <div class="dotTop">
            <div class="dot1 dot"></div>
            <div class="dot2 dot"></div>
            <div class="dot3 dot"></div>
        </div>
        <div class="dotBottom">
            <div class="dot1 dot">
                <div class="dot1_1"></div>
            </div>
            <div class="dot2 dot">
                <div class="dot2_1"></div>
            </div>
            <div class="dot3 dot"></div>
            <div class="dot4 dot">
                <div class="dot4_1"></div>
            </div>
            <div class="dot5 dot">
                <div class="dot5_1 dot"></div>
            </div>
            <div class="dot6 dot">
                <div class="dot6_1"></div>
                <div class="dot6_2"></div>
            </div>
        </div>
        <div class="muduleBottomLine flex-between" v-if="!ycysState">
            <div class="muduleBottomItem flex-between-y-center">
                <div class="dot"></div>
                <div class="line flex-1"></div>
            </div>
            <div class="muduleBottomItem flex-between-y-center">
                <div class="line flex-1"></div>
                <div class="dot"></div>
            </div>
        </div>
        <div class="muduleTitle" v-if="text">
            <div class="lineYellow animated bounceInRight"></div>
            <div class="text animated bounceInRight">
                {{ text }}
            </div>
            <div class="decorate flex-between">
                <div class="decorate_1"></div>
                <div class="decorate_2"></div>
                <div class="decorate_3"></div>
                <div class="decorate_4"></div>
                <div class="decorate_5 flex-1"></div>
                <div class="decorate_6"></div>
            </div>
        </div>

        <div
            v-if="dataState"
            class="muduleCtn"
            :style="{
                height: text ? 'calc(100% - 45px)' : '100%',
            }"
        >
            <slot name="basic-module"></slot>
        </div>

        <div v-else class="noMonitor">{{ noDataText }}</div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    name: "",
    data() {
        return {
            baseLeft: require("@image/bigScreen/monitor_all/base_left.png"),
            baseRight: require("@image/bigScreen/monitor_all/base_right.png"),
        };
    },
    components: {},
    computed: {},
    props: {
        ycysState: {
            type: Boolean,
            default: false,
        },
        text: {
            type: String,
            default: "",
        },
        noDataText: {
            type: String,
            default: "暂未监测",
        },
        dataState: {
            type: Boolean,
            default: true,
            // true 有数据 false 没有数据 显示暂未监测
        },
    },
    mounted() {},
    methods: {},
};
</script>
<style scoped lang="scss">
.mudule {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 20px;
    .leftSideImg {
        position: absolute;
        left: -3px;
        top: 0;
        height: 100%;
    }
    .rightSideImg {
        position: absolute;
        right: -3px;
        top: 0;
        height: 100%;
    }
    .dotTop {
        position: absolute;
        left: 6px;
        top: 80px;
        .dot {
            width: 3px;
            height: 3px;
            background: rgba(154, 180, 227, 1);
            border-radius: 50%;
            margin-bottom: 13px;
        }
    }
    .dotBottom {
        position: absolute;
        left: 6px;
        bottom: 0;
        .dot {
            width: 3px;
            background: rgba(154, 180, 227, 0.5);
        }
        .dot1 {
            height: 6px;
            margin-bottom: 12px;
            position: relative;
            .dot1_1 {
                width: 100%;
                height: 4px;
                background: rgba(154, 180, 227, 1);
                margin-top: 2px;
            }
        }
        .dot2 {
            height: 9px;
            margin-bottom: 6px;
            position: relative;
            .dot2_1 {
                position: absolute;
                top: 4px;
                width: 100%;
                height: 2px;
                background: rgba(154, 180, 227, 1);
            }
        }
        .dot3 {
            height: 4px;
            margin-bottom: 12px;
        }
        .dot4 {
            height: 12px;
            margin-bottom: 3px;
            position: relative;
            .dot4_1 {
                position: absolute;
                top: 4px;
                width: 100%;
                height: 2px;
                background: rgba(154, 180, 227, 1);
                margin-top: 2px;
            }
        }
        .dot5 {
            height: 17px;
            margin-bottom: 18px;
            position: relative;
            .dot5_1 {
                position: absolute;
                top: 4px;
                width: 100%;
                height: 2px;
                background: rgba(154, 180, 227, 1);
                margin-top: 2px;
            }
        }
        .dot6 {
            height: 23px;
            margin-bottom: 18px;
            position: relative;
            .dot6_1 {
                position: absolute;
                top: 0px;
                width: 100%;
                height: 4px;
                background: rgba(154, 180, 227, 1);
            }
            .dot6_2 {
                position: absolute;
                top: 10px;
                width: 100%;
                height: 2px;
                background: rgba(154, 180, 227, 1);
            }
        }
    }

    .muduleBottomLine {
        position: absolute;
        bottom: 0px;
        left: 20px;
        width: calc(100% - 40px);
        .muduleBottomItem {
            width: calc(50% - 5px);
            margin-right: 10px;
            &:nth-last-child(1) {
                margin-right: 0px;
            }
            .dot {
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background: rgba(168, 200, 255, 1);
            }
            .line {
                height: 2px;
                background: rgba(53, 80, 124, 0.5);
            }
        }
    }

    .muduleTitle {
        width: 100%;
        height: 45px;
        position: relative;

        .lineYellow {
            width: 50px;
            height: 3px;
            background: rgba(255, 205, 128, 1);
        }
        .text {
            font-weight: bold;
            font-size: 24px;
            color: rgba(168, 200, 255, 1);
            padding: 4px 0;
        }
        .decorate {
            height: 3px;
            .decorate_1 {
                width: 76px;
                height: 100%;
                background: rgba(154, 180, 227, 1);
            }
            .decorate_2 {
                width: 28px;
                height: 100%;
                background: rgba(53, 80, 124, 0.5);
                border: 1px solid rgba(154, 180, 227, 1);
                margin: 0 4px;
            }
            .decorate_3 {
                width: 30px;
                height: 100%;
                background: rgba(154, 180, 227, 1);
            }
            .decorate_4 {
                width: 4px;
                height: 4px;
                background: rgba(154, 180, 227, 1);
                border-radius: 50%;
                margin: 0 4px 0 8px;
            }
            .decorate_5 {
                height: 100%;
                background: rgba(53, 80, 124, 0.5);
            }
            .decorate_6 {
                width: 4px;
                height: 4px;
                background: rgba(154, 180, 227, 1);
                border-radius: 50%;
            }
        }
    }
    .muduleCtn {
        position: relative;
        z-index: 2;
    }
    .noMonitor {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-30deg);
        font-size: 24px;
        color: rgb(69, 112, 141);
        z-index: 2000;
    }
}
</style>
