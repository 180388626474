<template>
    <div class="yjczItemPart">
        <div v-if="ztNum != 4" class="noData">尚未处置完成</div>
        <div v-else>
            <div
                class="tableItem"
                v-for="(item, index) in listAry"
                :key="index"
            >
                <div class="label">{{ item.name }}</div>
                <div
                    class="detail pointer flex-1 flex-y-center"
                    :title="item.desc"
                >
                    {{ item.desc }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "",
    props: {
        id: {
            type: String,
            default: "",
        },
        yjztNum: {
            type: Number,
            default: -1,
        },
        ztNum: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            id: {
                type: String,
                default: "",
            },
            // 预警处置-4.处置工作完成 ----------------
            formData: {
                warnState: "0",
                messageState: "0",
            },
            yesNoList: [
                {
                    ID: "1",
                    LABEL: "是",
                },
                {
                    ID: "0",
                    LABEL: "否",
                },
            ],
            listAry: [
                {
                    name: "是否解除预警",
                    desc: "否",
                },
                {
                    name: "是否给预警信息接收人发送预警解除短信提示",
                    desc: "否",
                },
            ],
        };
    },
    watch: {},
    mounted() {
        this.getYjczjgValue();
    },
    methods: {
        ...mapActions([
            "GetYjczjgValue", // 获取预警处置结果
        ]),
        // 获取处置工作完成情况
        async getYjczjgValue() {
            let result = await this.GetYjczjgValue({
                yjsjId: this.id,
            });
            if (result) {
                this.listAry[0].desc = this.common.aryListFindOne(
                    this.yesNoList,
                    "ID",
                    Number(result.YJZT) === 3 ? "1" : "0"
                ).LABEL; //是否解除预警
                this.listAry[1].desc = this.common.aryListFindOne(
                    this.yesNoList,
                    "ID",
                    result.SFFSDX.toString()
                ).LABEL; //是否给预警信息接收人发送预警解除短信提示
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.yjczItemPart {
    width: 100%;
    height: 100%;
    position: relative;
    .noData {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-30deg);
        color: #5673a2;
        font-size: 18px;
    }
    .tableItem {
        margin-bottom: 10px;
        .label {
            color: #5673a2;
            font-size: 14px;
            margin-bottom: 6px;
        }
        .detail {
            min-height: 26px;
            color: #a8c8ff;
            font-size: 14px;
            background: rgba(53, 80, 124, 0.2);
            text-indent: 5px;
        }
    }
}
</style>
