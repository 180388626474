<template>
    <div class="basicInfo">
        <!-- 基本信息 -->
        <basic-module :text="common.getFyName(fany.jbxx)">
            <div slot="basic-module" class="basicCtn">
                <div>
                    <div
                        class="basicItem animated bounceInRight"
                        :style="{ animationDelay: 0.2 + 0 * 0.2 + 's' }"
                    >
                        <div class="name">涉及到的遗产要素:</div>
                        <div
                            class="desc ellipsis"
                            :class="{ empty: !BhgcDetail.SJDDYCGCYS_BM }"
                            :title="BhgcDetail.SJDDYCGCYS_BM"
                        >
                            {{ BhgcDetail.SJDDYCGCYS_BM | join }}
                        </div>
                    </div>
                    <div class="smallBox">
                        <div
                            class="basicItem animated bounceInRight"
                            :style="{ animationDelay: 0.2 + 1 * 0.2 + 's' }"
                        >
                            <div class="name">项目进度:</div>
                            <div
                                class="desc ellipsis"
                                :class="{ empty: !BhgcDetail.BHGCJZQK }"
                            >
                                {{ BhgcDetail.BHGCJZQK | strictNull }}
                            </div>
                        </div>
                        <div
                            class="basicItem animated bounceInRight"
                            :style="{ animationDelay: 0.2 + 2 * 0.2 + 's' }"
                        >
                            <div class="name">监理单位:</div>
                            <div
                                class="desc ellipsis"
                                :class="{ empty: !BhgcDetail.JLDW }"
                            >
                                {{ BhgcDetail.JLDW | strictNull }}
                            </div>
                        </div>
                    </div>
                    <div class="smallBox">
                        <div
                            class="basicItem animated bounceInRight"
                            :style="{ animationDelay: 0.2 + 3 * 0.2 + 's' }"
                        >
                            <div class="name">开工时间:</div>
                            <div
                                class="desc ellipsis"
                                :class="{ empty: !BhgcDetail.KGSJ }"
                            >
                                {{ BhgcDetail.KGSJ | strictNull }}
                            </div>
                        </div>
                        <div
                            class="basicItem animated bounceInRight"
                            :style="{ animationDelay: 0.2 + 4 * 0.2 + 's' }"
                        >
                            <div class="name">竣工时间:</div>
                            <div
                                class="desc ellipsis"
                                :class="{ empty: !BhgcDetail.JGSJ }"
                            >
                                {{ BhgcDetail.JGSJ | strictNull }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="basicItem animated bounceInRight"
                        :style="{ animationDelay: 0.2 + 5 * 0.2 + 's' }"
                    >
                        <div class="name">方案:</div>
                        <div
                            class="desc ellipsis"
                            :class="{
                                empty: !BhgcDetail.FA || !BhgcDetail.FA.length,
                            }"
                            :style="{
                                color:
                                    !BhgcDetail.FA || !BhgcDetail.FA.length
                                        ? ''
                                        : '#3580EA',
                                cursor: faCursor,
                            }"
                            @click="downloadFile(BhgcDetail.FA)"
                        >
                            {{ BhgcDetail.FA | showFileName }}
                        </div>
                    </div>
                    <div class="smallBox">
                        <div
                            class="basicItem animated bounceInRight"
                            :style="{ animationDelay: 0.2 + 6 * 0.2 + 's' }"
                        >
                            <div class="name">经费投入总额:</div>
                            <div
                                class="desc ellipsis"
                                :class="{ empty: !BhgcDetail.GJTRZJF }"
                            >
                                {{ BhgcDetail.GJTRZJF | strictNull | addUnit }}
                            </div>
                        </div>
                        <div
                            class="basicItem animated bounceInRight"
                            :style="{ animationDelay: 0.2 + 7 * 0.2 + 's' }"
                        >
                            <div class="name">中央财政经费:</div>
                            <div
                                class="desc ellipsis"
                                :class="{ empty: !BhgcDetail.ZYCZJF }"
                            >
                                {{ BhgcDetail.ZYCZJF | strictNull | addUnit }}
                            </div>
                        </div>
                    </div>
                    <div class="smallBox">
                        <div
                            class="basicItem animated bounceInRight"
                            :style="{ animationDelay: 0.2 + 8 * 0.2 + 's' }"
                        >
                            <div class="name">省财政经费:</div>
                            <div
                                class="desc ellipsis"
                                :class="{ empty: !BhgcDetail.SHENGCZJF }"
                            >
                                {{
                                    BhgcDetail.SHENGCZJF | strictNull | addUnit
                                }}
                            </div>
                        </div>
                        <div
                            class="basicItem animated bounceInRight"
                            :style="{ animationDelay: 0.2 + 9 * 0.2 + 's' }"
                        >
                            <div class="name">市财政经费:</div>
                            <div
                                class="desc ellipsis"
                                :class="{ empty: !BhgcDetail.SHICZJF }"
                            >
                                {{ BhgcDetail.SHICZJF | strictNull | addUnit }}
                            </div>
                        </div>
                    </div>
                    <div class="smallBox">
                        <div
                            class="basicItem animated bounceInRight"
                            :style="{ animationDelay: 0.2 + 10 * 0.2 + 's' }"
                        >
                            <div class="name">市以下财政经费:</div>
                            <div
                                class="desc ellipsis"
                                :class="{ empty: !BhgcDetail.SYXCZJF }"
                            >
                                {{ BhgcDetail.SYXCZJF | strictNull | addUnit }}
                            </div>
                        </div>
                        <div
                            class="basicItem animated bounceInRight"
                            :style="{ animationDelay: 0.2 + 11 * 0.2 + 's' }"
                        >
                            <div class="name">自筹经费:</div>
                            <div
                                class="desc ellipsis"
                                :class="{ empty: !BhgcDetail.ZCJF }"
                            >
                                {{ BhgcDetail.ZCJF | strictNull | addUnit }}
                            </div>
                        </div>
                    </div>

                    <div
                        class="errorBtn"
                        @click="errorPart.dialog = !errorPart.dialog"
                    >
                        <i
                            class="el-icon-warning-outline"
                            style="margin-right: 4px"
                        ></i>
                        <span>异常事件</span>
                    </div>

                    <div class="errorPartWrap" v-if="errorPart.dialog">
                        <div class="errorItem flex">
                            <div>情况描述：</div>
                            <div>{{ errorPart.qkms }}</div>
                        </div>
                        <div class="errorItem flex">
                            <div>遗产要素：</div>
                            <div>{{ errorPart.ycys }}</div>
                        </div>
                        <div class="errorItem flex">
                            <div>位置：</div>
                            <div>{{ errorPart.position }}</div>
                        </div>
                        <div class="errorItem flex">
                            <div>时间：</div>
                            <div>{{ errorPart.time }}</div>
                        </div>
                        <div class="errorItem flex">
                            <div>评估：</div>
                            <div
                                :style="{
                                    color: errorPart.color,
                                    border: `1px solid ${errorPart.color}`,
                                    padding: `2px 4px`,
                                }"
                            >
                                {{ errorPart.assess }}
                            </div>
                        </div>

                        <div class="carouselPart carouselArrowShow">
                            <el-carousel
                                indicator-position="none"
                                height="112px"
                                :autoplay="false"
                                :arrow="
                                    errorPart.imgList.length > 1
                                        ? 'hover'
                                        : 'never'
                                "
                                class="imgPart"
                            >
                                <el-carousel-item
                                    v-for="(item, index) in errorPart.imgList"
                                    :key="index"
                                    class="pointer"
                                    v-viewer
                                >
                                    <div class="flex imgItem">
                                        <el-image
                                            v-for="(item1, index1) in item"
                                            :key="index1"
                                            :src="item1.SYT"
                                            fit="cover"
                                            class="pointer"
                                            style="
                                                width: 24%;
                                                height: 100%;
                                                border-radius: 4px;
                                                margin-right: 10px;
                                            "
                                        >
                                            <div
                                                slot="error"
                                                style="
                                                    width: 100%;
                                                    height: 100%;
                                                "
                                            >
                                                <SnNoData
                                                    width="150px"
                                                ></SnNoData>
                                            </div>
                                        </el-image>
                                    </div>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </div>
                </div>
            </div>
        </basic-module>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    data() {
        return {
            BhgcDetail: {
                SJDDYCGCYS_BM: "",
                BHGCJZQK: "",
                KGSJ: "",
                JGSJ: "",
                FA: "",
                GJTRZJF: "",
                ZYCZJF: "",
                SHENGCZJF: "",
                SHICZJF: "",
                SYXCZJF: "",
                ZCJF: "",
            },
            errorPart: {
                dialog: false,
                qkms: "",
                ycys: "",
                position: "",
                time: "",
                assess: "",
                imgList: "",
            },
        };
    },
    props: {},
    computed: {
        ...mapGetters(["bhgcItem"]),
        faCursor() {
            const list = this.BhgcDetail.FA;
            if (Array.isArray(list) && list.length > 0) {
                const href = list[0] && list[0].FILEPATH_EX;
                if (href) {
                    return "pointer";
                }
            }
            return "auto";
        },
    },
    watch: {
        bhgcItem: {
            immediate: true,
            handler() {
                this.getBasicData();
            },
        },
    },
    filters: {
        join(value) {
            if (Array.isArray(value) && value.length > 0) {
                return value.join(",");
            }
            return "暂无数据";
        },
        strictNull(value) {
            if (value === null || value === undefined || value === "") {
                return "暂无数据";
            }
            return value;
        },
        addUnit(value) {
            if (value !== "暂无数据") {
                return value + " 万元";
            }
            return value;
        },
        showFileName(list) {
            if (Array.isArray(list) && list.length > 0) {
                const temp = list[0];
                return temp && temp.FILENAME;
            }
            return "暂无数据";
        },
    },
    methods: {
        ...mapActions(["getBhgcDetail", "GetRcxcycjlDetail"]),
        async getBasicData() {
            let result = await this.getBhgcDetail({ bhgcID: this.bhgcItem.ID });
            this.BhgcDetail = result;
            this.getErrorDetail();
        },
        // 获取异常记录详情
        async getErrorDetail() {
            let result = await this.GetRcxcycjlDetail({
                ycjlid: this.BhgcDetail.YCJLID,
            });
            if (result.length) {
                this.errorPart.qkms = result[0].SJMS;
                this.errorPart.ycys = result[0].MC ? result[0].MC : "无";
                this.errorPart.position = result[0].YCFSWZ;
                this.errorPart.time = result[0].CJSJ;
                this.errorPart.assess = result[0].PG_DESC;
                this.errorPart.color = this.common.getYcysStateColor(
                    result[0].PG_DESC
                );
                result[0].PIC = [...result[0].PIC, ...result[0].PIC];
                if (result[0].PIC.length) {
                    this.errorPart.imgList = this.common.arrayDevide(
                        result[0].PIC,
                        4
                    );
                } else {
                    this.errorPart.imgList = [];
                }
            } else {
                this.errorPart.qkms = "";
                this.errorPart.ycys = "";
                this.errorPart.position = "";
                this.errorPart.time = "";
                this.errorPart.assess = "";
                this.errorPart.imgList = [];
            }
        },

        downloadFile(list) {
            if (Array.isArray(list) && list.length > 0) {
                const href = list[0] && list[0].FILEPATH_EX;
                window.open(href);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
$main_color: #679fc0;
.basicInfo {
    width: 100%;
    height: 560px;
    .basicCtn {
        width: 100%;
        height: 100%;
        position: relative;
        .basicItem {
            width: 100%;
            flex: 1;
            .name {
                height: 35px;
                line-height: 35px;
                text-align: left;
                color: #5673a2;
                font-size: 16px;
            }
            .desc {
                min-height: 35px;
                line-height: 35px;
                text-align: left;
                background: rgba(26, 48, 89, 0.3);
                color: #d7eaf5;
                padding-left: 10px;
                font-size: 16px;
            }
            .empty {
                color: #a2a8ab;
                font-size: 14px;
            }
            .check {
                overflow: hidden;
                line-height: 30px;
                font-size: 14px;
                height: 113px;
            }
        }
        .smallBox {
            display: flex;
            justify-content: space-between;
            .smallInfoItem {
                width: 50%;
            }
        }

        .errorBtn {
            position: absolute;
            right: 0;
            top: -52px;
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
            color: #a9c9ff;
            line-height: 28px;
            background-color: rgba(53, 80, 124, 1);
            padding: 0 10px;
            border-radius: 5px;
            cursor: pointer;
        }
        .errorPartWrap {
            position: absolute;
            left: -1px;
            top: -26px;
            z-index: 1;
            width: 663px;
            height: 358px;
            background: url("~@image/bigScreen/protect_project/errorBg.png")
                no-repeat;
            background-size: 100% 100%;
            padding: 20px 20px;
            color: #a9c9ff;
            font-size: 14px;
            .errorItem {
                margin-bottom: 10px;
            }
            .carouselPart {
                .imgPart {
                    .imgItem {
                        height: 112px;
                    }
                }
            }
        }
    }
}
</style>
