<template>
    <div class="screenItem">
        <!-- 预警 -->
        <BasicMonitor :text="common.getFyName(fany.yj)">
            <div slot="basic-module" class="screenCtn flex-between">
                <div class="yjLeftPart scrollbar-hidden">
                    <div class="echartWrap">
                        <div class="echart" id="echartYj"></div>
                    </div>
                    <div class="tableList">
                        <TableList
                            :maxHeight="130"
                            :tableColums="tableColums"
                            :tableData="tableData"
                            :state="state"
                            @loadData="loadData"
                            @tableItemDetail="tableItemDetail"
                        ></TableList>
                    </div>
                </div>
                <div
                    class="
                        yjRightPart
                        flex-between-y-center flex-wrap flex-column
                    "
                >
                    <div
                        v-for="(item, index) in yjModuleList"
                        :key="index"
                        class="yjModuleItem animated bounceIn"
                        :style="{ animationDelay: 0.1 + index * 0.1 + 's' }"
                    >
                        <div class="dotLeft"></div>
                        <div class="dotRight"></div>
                        <div class="title flex-between-y-center">
                            <img :src="item.src" class="img" />
                            <div class="name">{{ item.name }}</div>
                        </div>
                        <div class="list">
                            <div class="listItem flex-between-y-center">
                                <div class="label">今日预警</div>
                                <div class="num">{{ item.jryjNum }}</div>
                            </div>
                            <div class="listItem flex-between-y-center">
                                <div class="label">总预警</div>
                                <div class="num">{{ item.zyjNum }}</div>
                            </div>
                            <div class="listItem flex-between-y-center">
                                <div class="label">未解除</div>
                                <div class="numError">
                                    {{ item.wjcNum }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BasicMonitor>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import BasicMonitor from "@views/big_screen/monitor_all/comp/BasicModule";
import TableList from "@views/big_screen/comp/TableList";
export default {
    components: {
        BasicMonitor,
        TableList,
    },
    name: "",
    data() {
        return {
            echartYj: null,
            tableColums: [
                {
                    prop: "FSYJSJ",
                    label: "时间",
                    width: 160,
                },
                {
                    prop: "YJSJ",
                    label: "预警事件",
                    width: 160,
                    align: "center",
                    headerAlign: "center",
                },
                {
                    // 0 未实施  2 已终止
                    propColorNum: "YJDJ",
                    colorArr: this.common.getYjdjStateColor(),
                    prop: "YJDJNAME",
                    label: "预警等级",
                    width: 80,
                },
                {
                    prop: "YJZTNAME",
                    label: "预警状态",
                    width: 80,
                },
            ],
            tableData: [],
            state: true, //true 还有未加载的数据 false 已经加载完毕
            pageIndex: 1,
            pageSize: 30,
            totalNum: 0,
            yjModuleList: [
                {
                    name: "要素单体",
                    bm: "14",
                    src: require("@image/bigScreen/monitor_all/yj/ysdt.png"),
                    jryjNum: 0, // 今日预警
                    zyjNum: 0, //总预警
                    wjcNum: 0,
                },
                {
                    name: "本体病害",
                    bm: "12",
                    src: require("@image/bigScreen/monitor_all/yj/btbh.png"),
                    jryjNum: 0, // 今日预警
                    zyjNum: 0, //总预警
                    wjcNum: 0,
                },
                {
                    name: "日常巡查",
                    bm: "9999",
                    src: require("@image/bigScreen/monitor_all/yj/rcxc.png"),
                    jryjNum: 0, // 今日预警
                    zyjNum: 0, //总预警
                    wjcNum: 0,
                },
                {
                    name: "建设控制",
                    bm: "90201",
                    src: require("@image/bigScreen/monitor_all/yj/jskz.png"),
                    jryjNum: 0, // 今日预警
                    zyjNum: 0, //总预警
                    wjcNum: 0,
                },
                {
                    name: "自然环境",
                    bm: "801",
                    src: require("@image/bigScreen/monitor_all/yj/zrhj.png"),
                    jryjNum: 0, // 今日预警
                    zyjNum: 0, //总预警
                    wjcNum: 0,
                },
                {
                    name: "其他预警",
                    bm: "",
                    src: require("@image/bigScreen/monitor_all/yj/qtyj.png"),
                    jryjNum: 0, // 今日预警
                    zyjNum: 0, //总预警
                    wjcNum: 0,
                },
            ],
            yjEchartList: [
                {
                    NAME: "一级预警",
                    COUNT: 0,
                },
                {
                    NAME: "二级预警",
                    COUNT: 0,
                },
                {
                    NAME: "三级预警",
                    COUNT: 0,
                },
                {
                    NAME: "四级预警",
                    COUNT: 0,
                },
            ],
            // 消息推送报警
            iconLevel1: require("@image/bigScreen/monitor_all/yj/alerm1.gif"),
            iconLevel2: require("@image/bigScreen/monitor_all/yj/alerm2.gif"),
            iconLevel3: require("@image/bigScreen/monitor_all/yj/alerm3.gif"),
            iconLevel4: require("@image/bigScreen/monitor_all/yj/alerm4.gif"),
        };
    },
    computed: {
        ...mapGetters({
            jczlYcysItem: TYPES.jczlYcysItem,
            jczlYczcItem: TYPES.jczlYczcItem,
        }),
    },
    props: {},
    watch: {
        jczlYcysItem: {
            immediate: false,
            handler() {
                this.getYjTableList();
            },
        },
        jczlYczcItem: {
            immediate: false,
            handler() {
                this.getYjTableList();
            },
        },
    },
    mounted() {
        this.getYjTableList();
        this.monitorYjTableList();
    },
    methods: {
        ...mapActions(["GetWarnTotal", "GetWarnInfoList"]),
        ...mapMutations({
            SET_JCZLYJITEM: TYPES.SET_JCZLYJITEM,
        }),
        // 获取预警table数据
        async getYjTableList() {
            let result = await this.GetWarnTotal({
                // ycysbm: this.jczlYcysItem ? this.jczlYcysItem.BM : "",
                // ycysfl2: this.jczlYczcItem ? this.jczlYczcItem.YCYSFL_2 : "",
            });
            this.yjEchartList[0].COUNT = result.ONEWARN;
            this.yjEchartList[1].COUNT = result.TWOWARN;
            this.yjEchartList[2].COUNT = result.THREEWARN;
            this.yjEchartList[3].COUNT = result.FOURWARN;
            this.initEchartYj(this.yjEchartList);
            this.yjModuleList.map((item, index) => {
                result.WARNTOTAL.map((itemInner, indexInner) => {
                    if (item.bm === itemInner.YJDXCODE) {
                        item.jryjNum = itemInner.TODAYWARN; // 今日预警
                        item.zyjNum = itemInner.TOTALWARN; // 总预警
                        item.wjcNum = itemInner.NOTRELEASED; // 未解除
                    }
                });
            });

            let resultTableList = await this.GetWarnInfoList({
                // ycysbm: this.jczlYcysItem ? this.jczlYcysItem.BM : "",
                // ycysfl2: this.jczlYczcItem ? this.jczlYczcItem.YCYSFL_2 : "",
                pageIndex: 1,
                pageSize: 5,
            });
            // console.log("获取预警table数据", resultTableList);
            this.tableData = resultTableList.DATA;
            // this.tableData = [...this.tableData, ...result.RESULT.XJXMLIST];
            // if (this.tableData.length < this.totalNum) {
            //     this.state = true;
            // } else {
            //     this.state = false;
            // }
        },
        // 监听预警数据
        monitorYjTableList() {
            window.signalr_connection.on("MonitoringOverview", (data) => {
                let result = JSON.parse(data).WARN;
                this.yjEchartList[0].COUNT = result.ONEWARN;
                this.yjEchartList[1].COUNT = result.TWOWARN;
                this.yjEchartList[2].COUNT = result.THREEWARN;
                this.yjEchartList[3].COUNT = result.FOURWARN;
                this.initEchartYj(this.yjEchartList);
                this.yjModuleList.map((item, index) => {
                    result.WARNTOTAL.map((itemInner, indexInner) => {
                        if (item.bm === itemInner.YJDXCODE) {
                            item.jryjNum = itemInner.TODAYWARN; // 今日预警
                            item.zyjNum = itemInner.TOTALWARN; // 总预警
                            item.wjcNum = itemInner.NOTRELEASED; // 未解除
                        }
                    });
                });
            });
        },
        loadData() {
            // if (this.state) {
            //     if (this.tableData.length < this.totalNum) {
            //         this.pageIndex++;
            //         this.getYjTableList();
            //     } else {
            //         this.state = false;
            //     }
            // }
        },
        tableItemDetail(item) {
            this.SET_JCZLYJITEM(item);
        },
        initEchartYj(list) {
            // 过滤掉 非0的数值
            let resultList = [
                {
                    NAME: "一级预警",
                    COUNT: 2,
                },
                {
                    NAME: "二级预警",
                    COUNT: 7,
                },
                {
                    NAME: "三级预警",
                    COUNT: 15,
                },
                {
                    NAME: "四级预警",
                    COUNT: 40,
                },
            ]; //后台只返回 数据部位0的数据
            resultList = list;
            // 颜色数组
            let colorList = [
                "rgba(217,56,56,1)",
                "rgba(255,159,0,1)",
                "rgba(255,229,0,1)",
                "rgba(46,167,224,1)",
            ];
            let animateBgColor = "#09354d";
            let noDataColor = "rgba(9,53,77,0.4)";
            let listOut = []; //最外层数据
            let listCenter = []; //中间层数据
            let listAnimate = []; //动画层数据
            let legendState = false; // 图例显示隐藏

            let innerRadius = ["0", "30%"]; //内层半径
            let centerRadius = ["50%", "74%"]; //中间层半径
            let outRadius = ["74%", "80%"]; //最外层半径
            let animateRadius = ["74%", "76%"]; //动画半径
            let animateSplitNum = 4; //动画拆分数据
            let animateState = true; //打开 关闭动画
            let total = 0; //数据总数
            let labelLineState = false;
            let labelLength = 10; //label长度
            let labelLength2 = 20; //label长度
            let legendData = [];
            for (let i in resultList) {
                total += resultList[i].COUNT;
            }

            // 空白间隔
            let placeHolderStyle = {
                normal: {
                    label: {
                        show: false,
                    },
                    labelLine: {
                        show: false,
                    },
                    color: "rgba(0, 0, 0, 0)",
                    borderColor: "rgba(0, 0, 0, 0)",
                    borderWidth: 0,
                },
            };
            // 最外层label 样式
            let rich = {
                white: {
                    align: "center",
                    padding: [3, 0],
                },
            };

            let splitNum = 100; //拆分数目
            if (resultList.length == 1) {
                splitNum = 10000000000;
            }

            // 饼状图 数据
            if (total == 0) {
                // 最外层 数值为0
                let aryOut0 = [
                    {
                        value: 0,
                        name: "",
                        itemStyle: {
                            normal: {
                                // borderWidth: 5,
                                // shadowBlur: 20,
                                // borderColor: color[i],
                                // shadowColor: color[i],
                                color: noDataColor,
                            },
                        },
                    },
                ];
                listOut = [...listOut, ...aryOut0];
                listCenter = [...listCenter, ...aryOut0];
            } else {
                for (let i in resultList) {
                    legendData.push(resultList[i].NAME);
                    // 最外层 数值为0
                    let aryOut0 = [
                        {
                            value: resultList[i].COUNT,
                            name: resultList[i].NAME,
                            itemStyle: {
                                normal: {
                                    // borderWidth: 5,
                                    // shadowBlur: 20,
                                    // borderColor: color[i],
                                    // shadowColor: color[i],
                                    color: colorList[i],
                                },
                            },
                        },
                    ];
                    // 最外层 数值为不为0
                    let aryOut1 = [
                        {
                            value: resultList[i].COUNT,
                            name: resultList[i].NAME,
                            itemStyle: {
                                normal: {
                                    // borderWidth: 5,
                                    // shadowBlur: 20,
                                    // borderColor: color[i],
                                    // shadowColor: color[i],
                                    color: colorList[i],
                                },
                            },
                        },
                        {
                            value: total / splitNum,
                            name: "",
                            itemStyle: placeHolderStyle,
                        },
                    ];

                    // 最外层 数值为0
                    let aryCenter0 = [
                        {
                            value: resultList[i].COUNT,
                            name: resultList[i].NAME,
                            itemStyle: {
                                normal: {
                                    // borderWidth: 5,
                                    // shadowBlur: 20,
                                    // borderColor: color[i],
                                    // shadowColor: color[i],
                                    color: colorList[i],
                                    opacity: 0.2,
                                },
                            },
                        },
                    ];
                    // 最外层 数值为不为0
                    let aryCenter1 = [
                        {
                            value: resultList[i].COUNT,
                            name: resultList[i].NAME,
                            itemStyle: {
                                normal: {
                                    // borderWidth: 5,
                                    // shadowBlur: 20,
                                    // borderColor: color[i],
                                    // shadowColor: color[i],
                                    color: colorList[i],
                                    opacity: 0.2,
                                },
                            },
                        },
                        {
                            value: total / splitNum,
                            name: "",
                            itemStyle: placeHolderStyle,
                        },
                    ];

                    if (resultList[i].COUNT == 0) {
                        // 外层数据
                        listOut = [...listOut, ...aryOut0];
                        // // 中间层数据
                        listCenter = [...listCenter, ...aryCenter0];
                    } else {
                        // 外层数据
                        listOut = [...listOut, ...aryOut1];
                        // 中间层数据
                        listCenter = [...listCenter, ...aryCenter1];
                    }
                }
            }

            // 动画部分 数据
            for (let i = 0; i < animateSplitNum; i++) {
                listAnimate.push(
                    {
                        value: 20,
                        name: i,
                        itemStyle: {
                            normal: {
                                color: animateBgColor,
                            },
                        },
                    },
                    {
                        value: 1,
                        name: "",
                        itemStyle: placeHolderStyle,
                    }
                );
            }

            // 最外层的label
            let func = (params) => {
                let percent = ((params.value / total) * 100).toFixed(1);
                let name = params.name.replace(/\n/g, "");
                if (params.name !== "") {
                    return name + params.value + "\n{white|" + percent + "%}";
                } else {
                    return "";
                }
            };

            let option = {
                tooltip: {
                    show: true,
                    trigger: "item",
                    formatter: (e) => {
                        if (
                            e.data.name == "" ||
                            e.seriesName == "最内层" ||
                            e.seriesName == "动画部分" ||
                            total == 0
                        ) {
                            return "";
                        } else {
                            let bgColor = "";
                            resultList.map((item, index) => {
                                if (item.NAME == e.name) {
                                    bgColor = colorList[index];
                                }
                            });
                            let percent = (e.value / total) * 100;
                            if (parseInt(percent) != parseFloat(percent)) {
                                percent = percent.toFixed(2);
                            }
                            let marker = `<div style='display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${bgColor};'></div>`;
                            let str = `${marker}${e.name}<br>${e.value}(${percent}%)`;
                            return str;
                        }
                    },
                },
                legend: {
                    show: legendState,
                    orient: "vertical",
                    data: legendData,
                    icon: "circle",
                    left: "5px",
                    top: "10px",
                    textStyle: {
                        fontSize: 20,
                        color: "#fff",
                    },
                },
                series: [
                    // {
                    //     name: "动画部分",
                    //     type: "pie",
                    //     label: {
                    //         normal: {
                    //             show: false,
                    //         },
                    //     },
                    //     labelLine: {
                    //         normal: {
                    //             show: false,
                    //         },
                    //     },
                    //     center: ["50%", "50%"],
                    //     radius: animateRadius,
                    //     hoverAnimation: false,
                    //     itemStyle: {
                    //         normal: {
                    //             label: {
                    //                 show: false,
                    //             },
                    //             labelLine: {
                    //                 show: false,
                    //             },
                    //         },
                    //     },
                    //     data: animateState ? listAnimate : [],
                    // },
                    {
                        name: "最外层",
                        type: "pie",
                        clockWise: false,
                        zlevel: 3,
                        center: ["50%", "50%"],
                        radius: outRadius,
                        hoverAnimation: false,
                        itemStyle: {
                            normal: {
                                label: {
                                    show: labelLineState,
                                    position: "outside",
                                    formatter: func,
                                    rich: rich,
                                },
                                labelLine: {
                                    length: labelLength,
                                    length2: labelLength2,
                                    show: labelLineState,
                                },
                            },
                        },
                        data: listOut,
                        animationType: "scale",
                        animationEasing: "elasticOut",
                        animationDelay: function (idx) {
                            return idx * 50;
                        },
                    },
                    {
                        name: "中间层",
                        type: "pie",
                        clockWise: false,
                        center: ["50%", "50%"],
                        radius: centerRadius,
                        hoverAnimation: false,
                        itemStyle: {
                            normal: {
                                label: {
                                    show: false,
                                },
                                labelLine: {
                                    show: false,
                                },
                            },
                        },
                        data: listCenter,
                    },
                    {
                        name: "最内层",
                        type: "pie",
                        hoverAnimation: false,
                        radius: innerRadius,
                        center: ["50%", "50%"],
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        data: [
                            {
                                // 总数
                                value: total,
                                name: "",
                                label: {
                                    normal: {
                                        show: true,
                                        position: "center",
                                        color: "#fff",
                                        formatter: (params) => {
                                            return params.value;
                                        },
                                        fontSize: 48,
                                        fontFamily: "DBPM",
                                    },
                                },
                                itemStyle: {
                                    normal: {
                                        color: new this.$echarts.graphic.RadialGradient(
                                            0.5,
                                            0.5,
                                            0.99,
                                            [
                                                {
                                                    offset: 0,
                                                    color: "rgb(0, 0, 0, 0)",
                                                },
                                                {
                                                    offset: 1,
                                                    color: "#d7eaf5",
                                                },
                                            ],
                                            false
                                        ),
                                    },
                                },
                            },
                        ],
                    },
                ],
            };

            // 网站加载缓慢问题
            // setTimeout(loop, 1000);

            function loop() {
                let option = myChart.getOption();
                option.series[0].startAngle = option.series[0].startAngle - 1;
                myChart.setOption(option);
                setTimeout(function () {
                    window.requestAnimationFrame(loop);
                }, 50);
            }
            this.echartYj = this.$echarts.dispose(
                document.getElementById("echartYj")
            );
            this.echartYj = this.$echarts.init(
                document.getElementById("echartYj")
            );
            this.echartYj.setOption(option);
        },
    },
};
</script>
<style scoped lang="scss">
.screenItem {
    width: 100%;
    height: 100%;
    // 预警
    .screenCtn {
        position: relative;
        width: 100%;
        height: 100%;
        .yjLeftPart {
            width: calc(50% - 5px);
            height: 100%;
            .echartWrap {
                width: 100%;
                height: 239px;
                margin-top: 10px;
                position: relative;
                background: url("~@image/bigScreen/monitor_all/yj/bg.png")
                    no-repeat;
                background-size: 100% 100%;
                .echart {
                    width: 243px;
                    height: 243px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .tableList {
                margin-top: 20px;
                width: 100%;
                height: 110px;
            }
        }
        .yjRightPart {
            margin-top: 12px;
            margin-bottom: 17px;
            align-content: space-between;
            width: calc(50% - 5px);
            .yjModuleItem {
                width: calc(33% - 6px);
                height: calc(50% - 6px);
                position: relative;
                .dotLeft {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    width: 4px;
                    height: 4px;
                    background: rgba(168, 200, 255, 1);
                }
                .dotRight {
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    width: 4px;
                    height: 4px;
                    background: rgba(168, 200, 255, 1);
                }

                .title {
                    width: 100%;
                    height: 60px;
                    border-top: 1px solid rgba(168, 200, 255, 0.4);
                    border-left: 1px solid rgba(168, 200, 255, 0.4);
                    border-right: 1px solid rgba(168, 200, 255, 0.4);
                    background: url("~@image/bigScreen/monitor_all/yj/cellBg.png")
                        no-repeat;
                    background-position: 0px 100%;
                    background-size: 100% auto;
                    padding: 0 10px;
                    .img {
                        width: 40px;
                        height: 37px;
                    }
                    .name {
                        font-size: 18px;
                        color: rgba(168, 200, 255, 1);
                    }
                }
                .list {
                    border: 1px solid rgba(168, 200, 255, 0.4);
                    width: 100%;
                    height: calc(100% - 60px);
                    margin-top: 5px;
                    .listItem {
                        padding: 0 10px;
                        width: 100%;
                        height: 33%;

                        &:nth-child(2) {
                            border-top: 1px dashed rgba(168, 200, 255, 1);
                            border-bottom: 1px dashed rgba(168, 200, 255, 1);
                        }
                        .label {
                            font-size: 14px;
                            color: rgba(168, 200, 255, 1);
                        }
                        .num {
                            font-size: 24px;
                            color: rgba(255, 205, 128, 1);
                            font-family: DBPM;
                        }
                        .numError {
                            font-size: 24px;
                            color: rgba(219, 56, 68, 1);
                            font-family: DBPM;
                        }
                    }
                }
            }
        }
    }
}
</style>
